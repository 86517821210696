import { styled } from '@material-ui/core/styles';

import MyNewButton from 'components/generals/buttons/MyNewButton';

import { ReactComponent as CongratsIconSVG } from 'assets/img/svg/icon_congratulations.svg';
import { ReactComponent as CopyIconSVG } from 'assets/img/svg/icon_copy.svg';

import { Colors } from 'styles/colors';

export const CongratsIcon = styled(CongratsIconSVG)({
  marginTop: '5em',
  marginBottom: '3em',
});

export const CopyIcon = styled(CopyIconSVG)({
  marginLeft: '1em',
  cursor: 'pointer',
});

const Wrapper = styled('div')({
  display: 'flex',
  flexDirection: 'column',
});

export const TextWrapper = styled(Wrapper)({
  maxWidth: '536px',
});

export const PageWrapper = styled(Wrapper)(theme => ({
  width: '100%',
  height: '100%',
  alignItems: 'center',
  color: theme.theme.palette.grey['800'],
  backgroundColor: Colors.WHITE,
  fontWeight: 500,
  fontSize: 16,
}));

export const BoldText = styled('span')({
  fontWeight: 'bold',
});

export const RedText = styled(BoldText)({
  color: Colors.RED,
});

export const Title = styled(BoldText)({
  fontSize: 32,
});

export const SubTitle = styled('span')({
  fontSize: 18,
  marginTop: '1em',
  width: '90%',
});

export const CheckoutInfo = styled(Wrapper)({
  marginTop: '2em',
});

export const Line = styled('div')({});

export const PaymentCodeWrapper = styled('div')(theme => ({
  display: 'flex',
  backgroundColor: theme.theme.palette.grey['50'],
  padding: '25px 20px',
  marginTop: '1.6em',
  borderRadius: '8px',
  width: '100%',
}));

export const TextArea = styled('textarea')({
  border: 'none',
  overflow: 'hidden',
  outline: 'none',
  boxShadow: 'none',
  resize: 'none',
  fontFamily: 'inherit',
  fontSize: 'inherit',
  width: '100%',
  height: '24px',
  backgroundColor: 'inherit',
});

export const ShipmentInfo = styled('div')(theme => ({
  color: theme.theme.palette.grey['500'],
  fontSize: 14,
  margin: '1.6em 0 3em 0',
}));

export const MyAccountButton = styled(MyNewButton)({
  alignSelf: 'flex-end',
  fontSize: 'inherit',
  fontWeight: 'inherit',
  padding: '12px 32px',
});
