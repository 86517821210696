import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { last, orderBy } from 'lodash';
import { Signature, SIGNATURE_STATUS, Order } from 'types/generals';
import {
  format,
  differenceInCalendarMonths,
  isBefore,
  startOfToday,
} from 'date-fns';
import { separateDateWithTrace } from 'helpers/validateDate';

export const useRedirectInactiveSubscription = (
  signature: Signature,
  orders: Order[],
  to: string,
  isLoading = true,
) => {
  const history = useHistory();
  useEffect(() => {
    if (signature) {
      const lastOrder = last(orderBy(orders, 'paymentDate', 'asc'));
      if (!isLoading && lastOrder) {
        const diferenceMonths = differenceInCalendarMonths(
          new Date(),
          separateDateWithTrace(
            lastOrder?.paymentDate || format(new Date(), 'yyyy-MM-dd'),
          ),
        );

        if (
          (signature?.status === SIGNATURE_STATUS.INACTIVE ||
            signature?.status === SIGNATURE_STATUS.CANCELED) &&
          diferenceMonths > 0 &&
          isBefore(separateDateWithTrace(signature.renewDate), startOfToday())
        ) {
          history.push(to);
        }
      }
    }
  }, [history, signature, to, orders, isLoading]);
};
