export const USER_CREATE_SUCCESS = 'Usuário cadastrado com sucesso';
export const FIXED_TRANSPORT_SUCCESS =
  'Método de envio fixo atualizado com sucesso';
export const FIXED_TRANSPORT_ERROR =
  'Falha ao atualizar o método de envio fixo';
export const USER_ALREADY_CREATED_MSG_1 =
  'Verificamos que o e-mail que você digitou já está cadastrado em nosso clube.';
export const USER_ALREADY_CREATED_MSG_2 = 'Acesse o painel e faça seu login.';
export const USER_ALREADY_CREATED_MSG_3 =
  'Se você não se lembra da sua senha, poderá solicitar uma nova.';
export const USER_ALREADY_CREATED_TITLE = 'Você já possui um cadastro!';
export const USER_ALREADY_CREATED_BUTTON_TITLE = 'Acessar minha conta';
export const USER_IDENTIFICATION_INVALID =
  'CPF inválido, tente digitar o cpf correto e enviar novamente.';
//user lead
export const CREATE_USER_LEAD_ERROR = 'Erro ao salvar os dados do lead.';
export const UPDATE_USER_LEAD_ERROR = 'Erro ao atualizar dados do lead.';
export const USER_UNAUTHORIZED = 'Usuário sem permissão.';

export const USER_UPDATED_SUCCESS = 'Usuário atualizado.';
export const USER_UPDATED_ERROR = 'Erro ao tentar atualizar usuário.';

export const USER_UPDATED_NOTIFICATIONS_ERROR =
  'Erro ao tentar atualizar notificações do usuário.';

export const REMOVE_ADDRESS_MSG = `Tem certeza que deseja excluir o endereço? Ao confirmar essa ação não é possível reverter.`;
export const REMOVE_ADDRESS_SUCCESS = 'Endereço removido com sucesso';
export const REMOVE_ADDRESS_ERROR = 'Houve uma falha ao remover o endereço';
