import { CreditCardPayment } from 'types/CreditCard';
import { BankSlipPayment } from 'types/generals/bankSlip';
import { RECURRENCES_MONTHS } from './plan';
import { moneyFormat } from 'pages/client/signature/utils';

export enum PAYMENT_STATUS {
  OPEN = 'Aberto',
  PENDING = 'Pendente',
  PROCESSING = 'Processando',
  CONFIRMED = 'Confirmado',
  FAILED = 'Não Autorizado',
  CANCELED = 'Cancelado',
}

export enum PAYMENT_TYPE {
  BANKSLIP = 'boleto',
  CARD = 'cartao',
  BANKSLIP_CARD = 'boleto e cartao',
}

export interface RefundReason {
  reason: string;
  requester: string;
}
export interface Payment {
  id: number;
  createdAt: string;
  updatedAt: string;
  token?: string;
  transactionId?: string;
  providerName?: string;
  description: string;
  message?: string;
  status: PAYMENT_STATUS;
  cardTransaction?: CreditCardPayment;
  bankSlipTransaction?: BankSlipPayment;
  refundReason?: RefundReason;
}

export interface InstallmentsPayload {
  installment: number;
}

export interface PaymentInstallment {
  installments: number;
  installmentPrice: number;
}

const dividerPrice = (recurrence: number, price: number) => {
  if (recurrence === 0 || price === 0) {
    return 0;
  }
  return price / recurrence;
};

const formatPaymentRecurrence = (recurrence: number, planPrice: number) => {
  switch (recurrence) {
    case RECURRENCES_MONTHS.MONTHLY:
      return `${moneyFormat(planPrice)}`;
    default:
      return `${recurrence}x ${moneyFormat(
        dividerPrice(recurrence, planPrice),
      )}`;
  }
};

export const formatPaymentType = (
  recurrence: number,
  paymentType: string,
  planPrice: number,
) => {
  switch (paymentType) {
    case PAYMENT_TYPE.BANKSLIP:
      if (recurrence === RECURRENCES_MONTHS.MONTHLY) {
        return `${moneyFormat(planPrice)}`;
      }
      return `1x ${moneyFormat(planPrice)}`;
    default:
      return formatPaymentRecurrence(recurrence, planPrice);
  }
};

export const getPaymentTypeName = (paymentType: PAYMENT_TYPE) => {
  switch (paymentType) {
    case PAYMENT_TYPE.BANKSLIP_CARD:
      return 'Boleto ou Cartão de crédito';
    case PAYMENT_TYPE.BANKSLIP:
      return 'Boleto';
    case PAYMENT_TYPE.CARD:
      return 'Cartão de crédito';
  }
};

export const getUnitPrice = (recurrence: number, price: number) => {
  return dividerPrice(recurrence, price);
};

export const getRecurrenceText = (recurrence: number) => {
  switch (recurrence) {
    case RECURRENCES_MONTHS.MONTHLY:
      return '/mês';
    default:
      return `/${recurrence} meses`;
  }
};
