import {
  ACTIVE_SIGNATURE_STATUS,
  INACTIVE_SIGNATURE_STATUS,
  UNMAPPED_SIGNATURE_STATUS,
} from 'types/generals';

export const SIGNATURES_STATUS = [
  ...Object.values(ACTIVE_SIGNATURE_STATUS),
  ...Object.values(INACTIVE_SIGNATURE_STATUS),
  ...Object.values(UNMAPPED_SIGNATURE_STATUS),
];

export const RECURRENCES_PLAN = [
  {
    name: 'Mensal',
    value: 1,
  },
  {
    name: 'Trimestral',
    value: 3,
  },
  {
    name: 'Semestral',
    value: 6,
  },
  {
    name: 'Anual',
    value: 12,
  },
];

export const MONTHS_QUANTITY = Array.from({ length: 12 }, (_, i) => i + 1);

export const CALLBACK_PAYMENT = [
  { name: 'Confirmado', value: 'Confirmado' },
  { name: 'Devolvido', value: 'Devolvido' },
  { name: 'Não Autorizado', value: 'Não Autorizado' },
  { name: 'Pendente', value: 'Pendente' },
  { name: 'Inexistente', value: null },
];
