import { CreateQueryParams } from '@nestjsx/crud-request';
import {
  UpdateRenewDateSeveralPayload,
  UpdateRetryPaymentSeveralPayload,
} from 'store/ducks/views/types';
import {
  apiQueueSchedulers,
  useGetFiletWithSaga,
  getWithSaga,
} from './apiService';
import { PAYMENT_TYPE } from 'types/generals';

const BULK_ACTION_URL = 'view-user-signature-last-order';
const BULK_ACTION_REACTIVATION_URL = 'view-user-signature-reactivation';
const BULK_ACTION_TURBINE_URL = 'view-user-signature-turbine';

export default class ViewsService {
  static async getViewSubscribers(query: CreateQueryParams) {
    try {
      const data = getWithSaga(`subscriber-view`, query);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async getViewSubscribersFile(query: CreateQueryParams) {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const data = useGetFiletWithSaga(`subscriber-view/export-file`, query);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  // ##########################################
  //                BULK ACTION               #
  // ##########################################
  static async getViewUserSignatureLastOrder(query: CreateQueryParams) {
    try {
      const data = getWithSaga(`${BULK_ACTION_URL}`, query);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async updateRenewDateSeveral(body: UpdateRenewDateSeveralPayload) {
    try {
      const data = await apiQueueSchedulers.post(
        `${BULK_ACTION_URL}/change-renew-date`,
        body,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async retryPaymentSeveral(body: UpdateRetryPaymentSeveralPayload) {
    try {
      const data = await apiQueueSchedulers.post(
        `${BULK_ACTION_URL}/retry-payment`,
        body,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async generateBankslipOrdersCsv(
    body: UpdateRetryPaymentSeveralPayload,
  ) {
    try {
      const { data } = await apiQueueSchedulers.post(
        `${BULK_ACTION_URL}/bankslip-orders-link-report`,
        body,
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `linkboletos.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      return error;
    }
  }

  static async anticipateRenew(body: UpdateRetryPaymentSeveralPayload) {
    try {
      const data = await apiQueueSchedulers.post(
        `${BULK_ACTION_URL}/anticipate-renew`,
        body,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async bulkSendBankslipNotifications(
    body: UpdateRetryPaymentSeveralPayload,
  ) {
    try {
      const { data } = await apiQueueSchedulers.post(
        `${BULK_ACTION_URL}/send-bankslip-notifications`,
        body,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  // ##########################################
  //                REACTIVATION              #
  // ##########################################
  static async getViewUserReactivation(query: CreateQueryParams) {
    try {
      const data = getWithSaga(`${BULK_ACTION_REACTIVATION_URL}`, query);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async bulkChangePlan(
    sigIds: number[],
    planId: number,
    paymentType: PAYMENT_TYPE,
  ) {
    try {
      const data = await apiQueueSchedulers.post(
        `${BULK_ACTION_REACTIVATION_URL}/change-plan`,
        {
          planId,
          paymentType,
          sigIds,
        },
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async bulkCreateOrder(
    sigIds: number[],
    startDate: string,
    boxId: number,
  ) {
    try {
      const data = await apiQueueSchedulers.post(
        `${BULK_ACTION_REACTIVATION_URL}/create-orders`,
        {
          sigIds,
          startDate,
          boxId,
        },
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  // ##########################################
  //            BULK ACTION TURBINE           #
  // ##########################################
  static async getViewUserSignatureTurbine(query: CreateQueryParams) {
    try {
      const data = getWithSaga(`${BULK_ACTION_TURBINE_URL}`, query);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async retryTurbinePaymentSeveral(body: { orderIds: number[] }) {
    try {
      const data = await apiQueueSchedulers.post(
        `${BULK_ACTION_TURBINE_URL}/retry-payment`,
        body,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async generateBankslipOrdersTurbineCsv(body: { orderIds: number[] }) {
    try {
      const { data } = await apiQueueSchedulers.post(
        `${BULK_ACTION_TURBINE_URL}/bankslip-orders-link-report`,
        body,
      );
      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `linkboletos.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      return error;
    }
  }
}
