import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Button, IconButton } from '@material-ui/core';
import { closeModal } from 'store/ducks/nav/actions';

import { ReactComponent as CloseIcon } from 'assets/img/svg/icon_default_close.svg';
import { Title, IconWrapper, Message, Wrapper, Icon } from './styles';
import { FeedBackWithButtonDialogProps } from '../dialogTypes';
import { useDialogStyles } from '../useStyles';

const DialogWithCustomButtons: React.FC<FeedBackWithButtonDialogProps> = ({
  title,
  message,
  icon,
  buttonActions,
}) => {
  const dispatch = useDispatch();
  const classes = useDialogStyles();

  const handleClose = () => dispatch(closeModal());

  return (
    <Wrapper container direction="column" className={classes.wrapper}>
      <IconWrapper container justify="flex-end">
        <IconButton onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </IconWrapper>
      <Grid container direction="column" justify="center" alignItems="center">
        {icon && <Icon component={icon} />}
        <Title variant="h1" color="textPrimary">
          {title}
        </Title>
        <Grid item xs={12} sm={12} md={12}>
          <Message variant="h3" color="textPrimary" className={classes.info_mb}>
            {message}
          </Message>
        </Grid>
        {buttonActions?.length ? (
          <Grid item xs={12} spacing={2} container justify="center">
            {buttonActions.map(action => (
              <Grid item>{action}</Grid>
            ))}
          </Grid>
        ) : null}
      </Grid>
    </Wrapper>
  );
};

export default DialogWithCustomButtons;
