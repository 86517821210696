import { Reducer } from 'redux';
import { OrderState, OrderTypes } from './types';

const INITIAL_STATE: OrderState = {
  data: [],
  total: 0,
  hasError: false,
  isLoading: true,
};

const ordersListReducer: Reducer<OrderState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case OrderTypes.CLEAN_ORDERS:
      return { ...state, data: [], isLoading: false };
    case OrderTypes.LOAD_ORDERS_REQUEST:
      return { ...state, isLoading: true };
    case OrderTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, hasError: true };
    case OrderTypes.LOAD_ORDERS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload.data,
        total: action.payload.total,
      };
    default:
      return state;
  }
};

export default ordersListReducer;
