import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { SignaturePayload, SignatureTypes } from '../types';

export const loadOneSignature = (
  signatureId: string,
  query?: CreateQueryParams,
) => {
  return action(SignatureTypes.LOAD_SIGNATURE_REQUEST, { signatureId, query });
};

export const loadUserSignature = (userId: number) => {
  return action(SignatureTypes.LOAD_USER_SIGNATURE_REQUEST, userId);
};

export const loadOneSignatureSuccess = (payload: SignaturePayload) => {
  return action(SignatureTypes.LOAD_SIGNATURE_SUCCESS, payload);
};

export const loadOneSignatureFail = () => {
  return action(SignatureTypes.LOAD_SIGNATURE_FAILURE);
};

export const clearSignature = () => {
  return action(SignatureTypes.CLEAR_SIGNATURE);
};

export const requestCancelSignature = (
  signatureId: number,
  message?: string,
) => {
  return action(SignatureTypes.REQUEST_CANCEL_SIGNATURE, {
    signatureId,
    message,
  });
};

export const reactivateSignature = (signatureId: number) => {
  return action(SignatureTypes.REACTIVATE_SIGNATURE, { signatureId });
};

export const cancelSignature = (signatureId: number) => {
  return action(SignatureTypes.CANCELED_SIGNATURE, { signatureId });
};
