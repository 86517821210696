import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import MyTypography from 'components/generals/labels/MyTypography';

import BoxCard from './BoxCard';
import { useStyles } from '../styles';
import { OrderDetailsProps } from '../types';
import { OrderDetailProduct } from 'types/generals';

const UserOrderItems: FC<Partial<OrderDetailsProps>> = ({ order }) => {
  const styles = useStyles();

  return (
    <Grid>
      <MyTypography variant="h3" className={styles.infoTitle}>
        Itens do pedido
      </MyTypography>
      <Grid container justify="space-between" direction="column" spacing={3}>
        {order?.orderDetail?.products.map((box: OrderDetailProduct) => (
          <Grid item xs key={order.id}>
            <BoxCard box={box} order={order} />
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};

export default UserOrderItems;
