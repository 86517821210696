import { makeStyles } from '@material-ui/core';

import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  actionButton: {
    color: Colors.RED,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  ownedProductImage: {
    display: 'block',
    width: '100%',
    height: 'auto',
    borderRadius: '16px',
    margin: theme.spacing(2, 0, 1, 0),
    maxHeight: '200px',
    minHeight: '200px',
    objectFit: 'contain',
  },
  overlay: {
    position: 'absolute',
    bottom: 0,
    background:
      'linear-gradient(160deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2))',
    width: '100%',
    height: '100%',
    opacity: 0.7,
    color: Colors.WHITE,
    borderRadius: '16px',
  },
  iconContainer: {
    backgroundColor: Colors.WHITE,
    color: Colors.RED,
    position: 'absolute',
    top: 0,
    margin: '15px',
    borderRadius: '50%',
    padding: '4.5px 7px',
  },
  paperTable: {
    marginTop: 18,
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
    minHeight: '355px',
    maxHeight: '355px',
    height: '100%',
  },
  cardContent: {
    padding: 0,
  },
  boxSubtitle: {
    color: Colors.MEDIUM_GRAY,
    fontWeight: 500,
  },
  boldText: {
    fontWeight: 700,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
  },
  mTop: {
    marginTop: 32,
  },
  mTopButton: {
    marginTop: 20,
  },
  img: {
    borderRadius: '16px',
    margin: theme.spacing(2, 0, 1, 0),
    maxHeight: '200px',
    minHeight: '200px',
    objectFit: 'contain',
  },
  paddingLeft: {
    paddingLeft: 0,
  },
  stockContainer: {
    color: Colors.WHITE,
    position: 'absolute',
    top: 0,
    margin: '15px',
    borderRadius: '50%',
    padding: '4.5px 7px',
    fontWeight: 600,
  },
  stockOverlay: {
    position: 'absolute',
    bottom: 0,
    top: 15,
    background:
      'linear-gradient(160deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2))',
    maxHeight: '200px',
    minHeight: '200px',
    width: '100%',
    height: '100%',
    opacity: 0.7,
    color: Colors.WHITE,
    borderRadius: '16px',
  },
});
