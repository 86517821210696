import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { Grid, Typography, useMediaQuery, useTheme } from '@material-ui/core';

import MyDropdown from 'components/generals/dropdown/MyDropdown';

import { logoutRequest } from 'store/ducks/auth/actions';

import { useStyles, Wrapper, UserProfile, BoldText } from './styles';

import { MyHeaderBarProps } from './types';
import { AppState } from 'store';
import { UserState } from 'store/ducks/user/types';

const MyHeaderBar: React.FC<MyHeaderBarProps> = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { data: userData } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const userName = userData?.name.split(' ')[0];
  // const createdAt = userData?.createdAt
  //   ? new Date(userData?.createdAt)
  //   : new Date();

  // const formatLocale = format(createdAt, 'MMMM/yyyy', {
  //   locale: ptLocale,
  // });

  // const createdAtFormat = `${formatLocale
  //   .charAt(0)
  //   .toUpperCase()}${formatLocale.slice(1)}`;

  const handleDispatch = () => {
    dispatch(logoutRequest());
    history.replace('/');
  };

  return (
    <Wrapper
      container
      spacing={1}
      style={{
        padding: isMobile ? '24px 0' : '30px 0 0 24px',
      }}
    >
      <Grid item container spacing={1}>
        <Grid item>
          <UserProfile
            letter={userName?.charAt(0)}
            photo={userData?.metadata?.imageLink}
          />
        </Grid>
        <Grid item xs container direction="column" justify="center">
          <Typography variant="h3">Olá,</Typography>
          <MyDropdown
            buttonStyle={classes.dropdownButton}
            expandMoreIconStyle={classes.expandIcon}
            menuProps={{
              classes: {
                list: classes.list,
              },
            }}
            menuItems={[
              {
                action: () => history.push('/my-account/profile'),
                title: 'Perfil',
              },
              {
                action: () => handleDispatch(),
                title: 'Sair',
              },
            ]}
          >
            <BoldText color="textPrimary" variant="h3">
              {userName || 'Desconhecido'}
            </BoldText>
          </MyDropdown>
        </Grid>
      </Grid>
      {/* <Grid item>
        <GreyText variant="h5">Membro desde {createdAtFormat}</GreyText>
      </Grid> */}
    </Wrapper>
  );
};

export default MyHeaderBar;
