import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from '../styles';

import { useCommonStyles } from 'styles/common';
import { NO_REFERRAL_MESSAGE, NO_REFERRAL_SUB_MESSAGE } from '../utils';
import ReferralBlock from './ReferralBlock';
import UserScoreRanking from './UserScoreRanking';

interface ReferredUsersProps {
  userRanking: any;
}

const PartilBlock: FC<ReferredUsersProps> = ({ userRanking }) => {
  const styles = useStyles({});
  const commnStyles = useCommonStyles();

  return (
    <Grid
      container
      direction="row"
      spacing={1}
      alignContent="flex-start"
      justify="center"
      className={styles.marginTop15}
    >
      <Grid item xs={8} className={commnStyles.textAlignCenter}>
        <Typography variant="h1" color="textPrimary">
          {NO_REFERRAL_MESSAGE}
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
          className={styles.marginTop15}
        >
          {NO_REFERRAL_SUB_MESSAGE}
        </Typography>
      </Grid>

      <Grid item xs={8}>
        <ReferralBlock userRanking={userRanking} />
      </Grid>
      <Grid item xs={8}>
        <UserScoreRanking userRanking={userRanking} />
      </Grid>
    </Grid>
  );
};

export default PartilBlock;
