import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Typography } from '@material-ui/core';

import { AppState } from 'store';
import { loadCurrentBox } from 'store/ducks/client/home/actions';
import { HomeState } from 'store/ducks/client/home/types';
import BoxCardData from '../BoxCardData';
import { useStyles } from './styles';
import { NO_CURRENT_BOX } from 'utils/messages';
import { MonthBoxProps } from './types';

const MonthBox: FC<MonthBoxProps> = ({ order, renewDate, isEnhanced }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { currentBox, isLoading } = useSelector<AppState, HomeState>(
    ({ home }: AppState) => home,
  );

  const loadMonthBox = useCallback(() => {
    dispatch(loadCurrentBox());
  }, [dispatch]);

  useEffect(() => {
    loadMonthBox();
  }, [loadMonthBox]);

  return (
    <>
      <Typography variant="h1" className={classes.cardTitle}>
        Box do mês
      </Typography>
      <Paper
        className={classes.paperTable}
        elevation={0}
        style={{ height: '92%', maxHeight: '92%' }}
      >
        {!isLoading ? (
          <Grid container justify="center">
            {Object.keys(currentBox).length ? (
              <BoxCardData
                boxTitle={currentBox.name}
                description={currentBox.description}
                imgPath={currentBox.image}
                deliveryDate={currentBox.boxDate}
                order={order}
                renewDate={renewDate}
                isEnhanced={isEnhanced}
              />
            ) : (
              <Grid item xs>
                <Typography variant="h3" className={classes.disabledText}>
                  {NO_CURRENT_BOX}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : null}
      </Paper>
    </>
  );
};

export default MonthBox;
