import { Reducer } from 'redux';
import { LabelState, LabelTypes } from './types';

const INITIAL_STATE: LabelState = {
  total: 0,
  data: [],
  hasError: false,
  isLoading: false,
};

const labelReducer: Reducer<LabelState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LabelTypes.LOAD_REQUEST:
      return { ...state, isLoading: true };
    case LabelTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload.data,
        total: action.payload.total,
      };
    case LabelTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

export default labelReducer;
