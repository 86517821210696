import { makeStyles, createStyles } from '@material-ui/core';

import Colors from 'styles/colors';

export const useStyles = makeStyles(() =>
  createStyles({
    wrapperHeader: {
      display: 'flex',
      width: '100%',
      justifyContent: 'center',
      backgroundColor: Colors.RED,
      padding: '11px 0',
    },
    logo: {
      height: 46,
      width: 98,
      fill: Colors.WHITE,
    },
  }),
);

export default useStyles;
