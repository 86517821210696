import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { StatusCodes } from 'http-status-codes';
import { Button, Divider, Grid, Typography } from '@material-ui/core';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { COUPON_TYPE } from 'components/generals/dialog/CouponDialog/types';
import CartProduct from '../../CartProduct';
import DetailHeader from '../../CartHeader';
import CartAmount from '../../CartAmount';

import { AppState } from 'store';
import { CartState } from 'store/ducks/cart/types';
import { applyPercentDiscount } from 'store/ducks/cart/utils';
import {
  applyCartDiscount,
  deleteCartCoupon,
  setCartCoupon,
  updateCartAmount,
  handleApplyFreightDataFrete,
} from 'store/ducks/cart/actions';
import { UserState } from 'store/ducks/user/types';
import { notifySuccess } from 'store/ducks/notification/actions';
import CouponsService from 'services/couponsService';
import { defaultValues, validationSchema } from './utils';
import { useStyles } from '../../styles';
import { ShopStepsProps } from '../../types';
import { ACTIVE_COUPON } from 'utils/messages';
import { getDiscountValue } from 'store/ducks/cart';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import { DataFreteTransportState } from 'store/ducks/dataFreteTransport/types';
import StoreScoreSlider from './StoreScoreSlider';

const CartDataControl: FC<ShopStepsProps> = ({
  handleChangeStep,
  setOpened,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();

  const {
    data: cartItems,
    cartAmount,
    subTotal,
    freight,
    productsDiscount,
    discount,
    cartCoupon,
    selectedTransport,
    isFirstTransport,
    scoreData,
  } = useSelector<AppState, CartState>(
    ({ cartReducer }: AppState) => cartReducer,
  );

  const { control, errors, setError, register, reset, watch } = useForm({
    validationSchema,
    defaultValues: defaultValues(cartCoupon),
  });

  const { data: user } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const { data: transportData } = useSelector<
    AppState,
    DataFreteTransportState
  >(({ dataFreteTransport }: AppState) => dataFreteTransport);

  const { activeSettings } = useSelector<AppState, StoreSettingsState>(
    ({ storeSettings }: AppState) => storeSettings,
  );

  const setErrorCoupon = (message: string) => {
    setError('coupon', 'manual', message);
    dispatch(
      applyCartDiscount({ discount: discount - (cartCoupon.value || 0) }),
    );
    dispatch(deleteCartCoupon());
  };

  const handleValidateCoupon = async (coupon: string) => {
    try {
      if (coupon.length) {
        const { data, status } = await CouponsService.validateCoupon(
          coupon.trim(),
          COUPON_TYPE.Store,
          user?.email,
        );

        if (status === StatusCodes.CREATED) {
          dispatch(setCartCoupon({ coupon: data }));

          const couponDiscount = getDiscountValue(
            productsDiscount,
            subTotal,
            data,
          );
          dispatch(
            applyCartDiscount({
              discount: couponDiscount,
              shouldResetScore: true,
            }),
          );
          dispatch(updateCartAmount());

          dispatch(notifySuccess(ACTIVE_COUPON(data.name)));
        } else if (status === StatusCodes.BAD_REQUEST) {
          setErrorCoupon(data.message);
        }
      }
    } catch (error) {
      setErrorCoupon('Cupom Inválido');
    } finally {
      applyFreightWithDiscount();
    }
  };

  const handleRedirectStore = () => {
    setOpened(false);
    history.push('/store');
  };

  const handleRemoveCoupon = () => {
    reset({ coupon: '' });
    dispatch(
      applyCartDiscount({
        discount: cartCoupon?.isPercent
          ? discount -
            Number(applyPercentDiscount(subTotal, cartCoupon?.percent | 0))
          : discount - (cartCoupon.value | 0),
      }),
    );
    dispatch(deleteCartCoupon());
    dispatch(updateCartAmount());
    applyFreightWithDiscount();
  };

  const applyFreightWithDiscount = () => {
    if (transportData?.data) {
      const transport = transportData.data.find(
        transport => transport.nome_transportador === selectedTransport,
      );

      if (transport) {
        dispatch(
          handleApplyFreightDataFrete({
            activeSettings: {
              ...activeSettings,
              isPremium: !!user?.isPremium,
            },
            freight: transport.valor_frete_exibicao,
            isFirstTransport,
          }),
        );
      }
    }
  };

  return (
    <Grid
      container
      item
      justify="space-between"
      direction="column"
      alignItems="center"
      style={{ padding: '0px 55px' }}
    >
      <Grid container direction="row" style={{ marginBottom: '50px' }}>
        <DetailHeader
          title="Carrinho"
          action={() => setOpened(false)}
          step={1}
          totalSteps={2}
        />
      </Grid>
      <Grid className={styles.cartItemsArea}>
        {cartItems.length > 0
          ? cartItems.map(product => (
              <CartProduct key={product.id} product={product} />
            ))
          : null}
      </Grid>
      <Divider className={styles.divider} />
      <Grid container justify="space-between" direction="column" spacing={1}>
        {/* TODO: Refatorar componente com collapse tendo 
            botões de Aplicar / Cancelar Cupom */}
        <Grid item xs={12}>
          <Controller
            name="coupon"
            control={control}
            as={({ onChange, value }) => (
              <MyOutlinedTextField
                fullWidth
                id="coupon-field"
                disabled={Boolean(Object.keys(cartCoupon).length)}
                label="Cupom"
                name="coupon"
                type="text"
                onChange={onChange}
                inputRef={register}
                value={value}
                onBlur={e => handleValidateCoupon(e.target.value)}
                error={Boolean(errors.coupon)}
                helperText={errors.coupon ? errors.coupon.message : null}
              />
            )}
          />
        </Grid>
        <Grid container item justify="flex-end">
          <Button
            onClick={() => handleRemoveCoupon()}
            style={{ paddingRight: 1, paddingLeft: 1 }}
            disabled={!Object.keys(cartCoupon).length}
          >
            <Typography
              variant="h4"
              color={
                Object.keys(cartCoupon).length ? 'primary' : 'textSecondary'
              }
              className={styles.keepBtnText}
            >
              Remover cupom
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <StoreScoreSlider />

      <Grid container direction="column">
        <CartAmount
          subtotal={subTotal.toString()}
          total={cartAmount.toString()}
          discount={discount}
          freight={freight.toString()}
          scoreData={scoreData}
        />
      </Grid>
      <Grid container justify="space-between" direction="column" spacing={3}>
        <Grid item xs={12} style={{ marginTop: '48px' }}>
          <Button
            className={styles.confirmButton}
            disabled={cartItems.length === 0}
            onClick={() => handleChangeStep(1)}
          >
            <Typography variant="h3" className={styles.confirmBtnText}>
              Confirmar pedido
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={12}>
          <Button
            className={styles.keepButtonLabel}
            onClick={handleRedirectStore}
          >
            <Typography
              variant="h3"
              color="primary"
              className={styles.keepBtnText}
            >
              Continuar comprando
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CartDataControl;
