import React, { FC, useCallback, useEffect, KeyboardEvent } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Grid, useTheme, useMediaQuery } from '@material-ui/core';
import ArrowBackOutlinedIcon from '@material-ui/icons/ArrowBackOutlined';

import { useStyles, Logo } from './styles';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import ShopCart from 'components/client/ShopCart';
import IconLabelButton from 'components/generals/buttons/IconLabelButton';
import { CondOperator } from '@nestjsx/crud-request';
import { useFilters } from 'hooks/filters';
import { useDispatch, useSelector } from 'react-redux';
import { listStoreRequest } from 'store/ducks/store/actions';
import { AppState } from 'store';
import { StoreState } from 'store/ducks/store/types';
import {
  HEADER_BACK_BUTTON_PATH,
  HEADER_LOGO_PATH,
} from 'types/generals/storePaths';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';

const StoreHeader: FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { isMaintenance } = useSelector<AppState, StoreSettingsState>(
    ({ storeSettings }: AppState) => storeSettings,
  );

  const redirectTo = (path: string) => history.push(path);

  const handleSearchInStore = () => {
    const lastPath = location.pathname.split('/').pop();

    if (lastPath !== 'store') history.push('/store');
  };

  const { rowsPerPage, page } = useSelector<AppState, StoreState>(
    ({ storeReducer }: AppState) => storeReducer,
  );

  const searchObject = [
    {
      field: 'name',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'keyword',
      operator: CondOperator.CONTAINS_LOW,
    },
  ];
  const { genericFilterFields, handleGenericSearch } = useFilters({});

  const loadStore = useCallback(() => {
    dispatch(
      listStoreRequest({
        page: page + 1,
        sort: [{ field: 'updatedAt', order: 'DESC' }],
        limit: rowsPerPage,
        search: {
          $and: [{ $or: [...genericFilterFields] }],
        },
      }),
    );
  }, [dispatch, page, genericFilterFields, rowsPerPage]);

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  useEffect(() => {
    loadStore();
  }, [loadStore]);

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.nav}
    >
      <Grid container item xs="auto" className={styles.backButtonArea}>
        <IconLabelButton
          action={() => redirectTo(HEADER_BACK_BUTTON_PATH(isMaintenance))}
          label={isMobile ? 'Voltar' : 'Voltar ao painel'}
          Icon={ArrowBackOutlinedIcon}
        />
      </Grid>
      <Grid item xs={4} sm={4} className={styles.headerLogoContainer}>
        <Logo
          className={styles.headerLogo}
          onClick={() => redirectTo(HEADER_LOGO_PATH(isMaintenance))}
        />
      </Grid>

      <Grid
        container
        xs={2}
        sm={5}
        justify={isMobile ? 'space-between' : 'flex-end'}
        alignItems="center"
      >
        {!isMobile && (
          <Grid item xs={5}>
            <MyInputFilter
              asFilter
              placeholder={'O que você está procurando?'}
              onKeyDown={(e: KeyboardEvent) => {
                if (e.keyCode === 13) {
                  handleSearchInStore();
                }
              }}
              onKeyUp={e => handleSearch(e)}
              className={styles.search}
            />
          </Grid>
        )}
        <Grid
          item
          xs="auto"
          className={isMobile ? styles.smShopCartArea : styles.shopCartArea}
        >
          <ShopCart />
        </Grid>
      </Grid>
      {isMobile && (
        <Grid item xs={12}>
          <MyInputFilter
            asFilter
            noPlaceholder
            onKeyUp={e => handleSearch(e)}
            className={clsx(styles.search, styles.smInputMargin)}
          />
        </Grid>
      )}
    </Grid>
  );
};

export default StoreHeader;
