/**
 * Action types
 */
export enum NavTypes {
  UPDATE_PAGE_TITLE = '@nav/UPDATE_PAGE_TITLE',
  OPEN_MODAL = '@nav/OPEN_MODAL',
  CLOSE_MODAL = '@nav/CLOSE_MODAL',
}

export interface Modal {
  open: boolean;
  maxWidth: 'xs' | 'sm' | 'md' | 'lg' | 'xl';
  props?: any;
  component?: any;
}

export interface NavState {
  readonly pageTitle: string;
  readonly modal: Modal;
}
