import theme from 'styles/theme';
import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  paperTable: {
    marginTop: 18,
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  headerMargin: {
    marginBottom: theme.spacing(3),
  },
  containerMargin: {
    marginTop: theme.spacing(2),
  },
  boldText: {
    fontWeight: 'bold',
  },
  menuOption: {
    textTransform: 'capitalize',
  },
  colorRed: {
    color: Colors.RED,
  },
  colorWhite: {
    color: Colors.WHITE,
  },
  textFilter: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
  },
  mBottom: {
    marginBottom: 10,
  },
  filterText: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
  },
  clearFilterButton: {
    color: Colors.RED,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
});

export { useStyles };
