import { makeStyles } from '@material-ui/core';

import Colors from 'styles/colors';

export const useStyles = makeStyles({
  actionButton: {
    color: Colors.RED,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  semiBoldText: {
    fontWeight: 600,
  },
  noPaddingBottom: {
    marginBottom: '-6px',
  },
  paperTable: {
    marginTop: 18,
    padding: '8px 30px 30px 30px',
    borderRadius: 5,
    height: '100%',
  },
  img: {
    borderRadius: 5,
    margin: '22px 0px 28px 0px',
    cursor: 'pointer',
    height: '100%',
    objectFit: 'cover',
  },
  boldText: {
    fontWeight: 700,
    height: 36,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    WebkitLineClamp: 2,
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
  },
  productCard: {
    height: '100%',
    position: 'relative',
  },
  descriptionWrapper: {
    marginTop: '10px',
    marginBottom: '30px',
    height: '66px',
  },
  description: {
    display: '-webkit-box',
    color: Colors.MEDIUM_GRAY,
    fontSize: 15,
    maxWidth: '125ch',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-wrap',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  actionsWrapper: {
    height: 50,
    padding: '8px 0px',
  },
  discountOriginal: {
    color: Colors.MEDIUM_GRAY,
    fontWeight: 500,
    textDecoration: 'line-through',
  },
  price: {
    fontWeight: 'bold',
    marginLeft: 2,
  },
  confirmButton: {
    fontSize: '1.5rem',
    height: '16px',
    borderRadius: 5,
    fontWeight: 'bold',
    padding: '20px 10px',
    width: '100%',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: Colors.LIGHT_GREEN,
    color: Colors.WHITE,
    '&:hover': {
      backgroundColor: Colors.DARK_GREEN,
    },
  },
  disabledButton: {
    fontSize: '1.5rem',
    height: '16px',
    borderRadius: 5,
    fontWeight: 'bold',
    padding: '20px 10px',
    width: '100%',
    maxWidth: '100%',
    alignItems: 'center',
    backgroundColor: Colors.LIGHT_GRAY,
    color: Colors.WHITE,
  },
  tagArea: {
    height: 26,
    marginBottom: 9,
  },
});
