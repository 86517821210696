import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

import { Colors } from 'styles/colors';

import BackgroundImage from 'assets/img/jpg/client_login_backgroung_6_columns.jpg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    wrapperLogo: {
      display: 'flex',
      justifyContent: 'center',
      backgroundImage: `url(${BackgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',
      height: '100vh',
      [theme.breakpoints.down('sm')]: {
        height: 'auto',
      },
    },
    logo: {
      width: '246px',
      height: '120px',
      marginTop: '30vh',
      fill: Colors.RED,
      [theme.breakpoints.down('sm')]: {
        width: '131px',
        height: '67px',
        margin: '67px 0 43px 0',
        fill: Colors.WHITE,
      },
    },
  }),
);

export default useStyles;
