import { FileWithPath } from 'react-dropzone';
import { FormController } from 'types';

export interface ImageZone {
  id?: number;
  image: string;
  imageName?: string;
  isCover: boolean;
  type?: string;
  name?: string;
  size?: number;
}

export interface CustomFile extends Partial<FileWithPath> {
  id?: number;
  image: string;
  imageName?: string;
  isCover: boolean;
}

export interface ImagesZoneProps extends Partial<FormController> {
  files?: ImageZone[];
  deleteImageFn?: (imageId: number) => void;
  multiple?: boolean;
  showTitle?: boolean;
  maxFiles?: number;
  handleDropFile?: (file: any) => void;
  handleRemoveFile?: (files: any[], imageId: number) => void;
  removeTitle?: boolean;
}

export interface FilesThumbsProps {
  files: CustomFile[];
  handleDeleteFile: (file: string) => void;
}

export enum IMAGES_ZONE_ERROR {
  TOO_LARGE_FILE = 'file-too-large',
  TOO_SMALL_FILE = 'file-too-small',
  TOO_MANY_FILES = 'too-many-files',
  INVALID_FILE_FORMAT = 'file-invalid-type',
}
