import React, { createContext, useState, useContext, useCallback } from 'react';
import OpenEndpointsService from 'services/openEndpointsService';
import { Plan, PAYMENT_TYPE } from 'types/generals';
import { StatusCodes } from 'http-status-codes';
import { EnhancedProduct } from 'types/generals/enhancedProduct';
interface SignatureContextProps {
  plan: Plan;
  loadPlan(hashPlan: string): Promise<any>;
  loading: boolean;
  loadingRequest: boolean;
  createSignatureRequest(loading: boolean): Promise<any>;
  handleSetPaymentType(paymentType: PAYMENT_TYPE): Promise<any>;
  paymentType: PAYMENT_TYPE;
  showInitialStep: boolean;
  showFinishStep: boolean;
  enhancedProducts: EnhancedProduct[];
  setShowInitialStep: (showInitialStep: boolean) => void;
  setEnhancedProducts: (val: EnhancedProduct[]) => void;
  setShowFinishStep: (showFinishStep: boolean) => void;
  planStepsDisabled: boolean;
}

const SignatureContext = createContext<SignatureContextProps>(
  {} as SignatureContextProps,
);
export const SignatureProvider: React.FC = ({ children }) => {
  const [plan, setPlan] = useState<Plan>({} as Plan);
  const [loading, setLoading] = useState(true);
  const [loadingRequest, setLoadingRequest] = useState(false);
  const [showInitialStep, setShowInitialStep] = useState(true);
  const [showFinishStep, setShowFinishStep] = useState(false);
  const [planStepsDisabled, setPlanStepsDisabled] = useState(false);
  const [enhancedProducts, setEnhancedProducts] = useState<EnhancedProduct[]>(
    [],
  );
  const [paymentType, setPaymentType] = useState<PAYMENT_TYPE>(
    {} as PAYMENT_TYPE,
  );
  const loadPlan = useCallback(async (hashPlan: string) => {
    const data = await OpenEndpointsService.getPlanFromSignature(hashPlan);
    if (data) {
      if (data.status === StatusCodes.NOT_FOUND) {
        window.location.href = 'https://assine.bibliotecacatolica.com.br';
      } else {
        setPlan({ ...data });
        setPlanStepsDisabled(data.stepsDisabled);
        setLoading(false);
      }
    }
  }, []);
  const createSignatureRequest = useCallback(async (loading: boolean) => {
    setLoadingRequest(loading);
  }, []);
  const handleSetPaymentType = useCallback(
    async (paymentType: PAYMENT_TYPE) => {
      setPaymentType(paymentType);
    },
    [],
  );
  return (
    <SignatureContext.Provider
      value={{
        loadPlan,
        loading,
        plan,
        loadingRequest,
        createSignatureRequest,
        handleSetPaymentType,
        paymentType,
        showInitialStep,
        showFinishStep,
        setShowInitialStep,
        setShowFinishStep,
        planStepsDisabled,
        enhancedProducts,
        setEnhancedProducts,
      }}
    >
      {children}
    </SignatureContext.Provider>
  );
};
export function useSignature() {
  const context = useContext(SignatureContext);
  return context;
}
