import React, { FC, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Card,
  CardActions,
  CardMedia,
  Grid,
  Paper,
  Typography,
  Divider,
} from '@material-ui/core';
import { head, sortBy } from 'lodash';

import CustomTag from 'components/generals/tag/CustomTag';
import NoAccessStoreModalInfo from 'components/generals/dialog/NoAccessStoreModalInfo';

import { notifyError } from 'store/ducks/notification/actions';
import { AppState } from 'store';
import { CartState } from 'store/ducks/cart/types';
import { verifyCartRules } from 'store/ducks/cart/utils';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import { getPropsToOpenModalNoAccessStore } from 'utils/functions/generals';
import { OrderOneState } from 'store/ducks/order/types';
import { openModal } from 'store/ducks/nav/actions';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { addCartProduct, handleChangeCartOpen } from 'store/ducks/cart/actions';

import { ProductTag } from 'types/generals';
import { IShopProductItem } from './types';
import { FormatOptions } from 'types';
import { formatColumnValue } from 'helpers/formatters';
import { useStyles } from './styles';

const ShopProductItem: FC<IShopProductItem> = memo(
  ({ product, callbackLink }) => {
    const dispatch = useDispatch();
    const styles = useStyles();
    const {
      name,
      smallDescription,
      images,
      price,
      discount,
      stock,
      productTag,
    } = product;
    const productCover = head(sortBy(images, [({ fileName }) => fileName]));
    const tag = head<ProductTag>(productTag);

    const { data: signature } = useSelector<AppState, SingleSignatureState>(
      ({ oneSignature }: AppState) => oneSignature,
    );

    const { data: order } = useSelector<AppState, OrderOneState>(
      ({ orderReducer }: AppState) => orderReducer,
    );

    const { cartLength, cartProducts } = useSelector<AppState, CartState>(
      ({ cartReducer }: AppState) => cartReducer,
    );

    const { hasPromotion, activeSettings } = useSelector<
      AppState,
      StoreSettingsState
    >(({ storeSettings }: AppState) => storeSettings);

    const handleFormatMoney = useCallback((price: string) => {
      return formatColumnValue(FormatOptions.MONEY, price);
    }, []);

    const verifyIfHaveAccesOnStore = () => {
      if (hasPromotion || signature.user.isPremium) {
        addProductToCart();
      } else {
        const modalProps = getPropsToOpenModalNoAccessStore(signature, order);
        dispatch(openModal(NoAccessStoreModalInfo, modalProps));
      }
    };

    const addProductToCart = () => {
      try {
        if (verifyCartRules(cartLength, cartProducts, product)) {
          dispatch(
            addCartProduct(
              { ...product, amount: 1 },
              { ...activeSettings, isPremium: signature.user.isPremium },
            ),
          );
          dispatch(handleChangeCartOpen());
        }
      } catch (error) {
        dispatch(notifyError(error.message));
      }
    };

    return (
      <Paper className={styles.paperTable} elevation={0}>
        <Card elevation={0} className={styles.productCard}>
          <Grid
            style={{
              height: '235px',
              margin: '22px 0px 28px 0px',
              borderRadius: 5,
            }}
          >
            <CardMedia
              className={styles.img}
              image={productCover?.image}
              component="img"
              onClick={() => callbackLink()}
              alt={`Imagem do produto ${name}`}
            />
          </Grid>
          <Grid item xs className={styles.tagArea}>
            {stock && tag?.tagName ? (
              <CustomTag
                text={tag.tagName}
                background={tag?.tagColor}
                color={tag?.textColor}
              />
            ) : null}
          </Grid>
          <Grid item xs>
            <Typography
              variant="h3"
              color="textPrimary"
              className={styles.boldText}
            >
              {name}
            </Typography>
          </Grid>
          <Grid item xs className={styles.descriptionWrapper}>
            <Typography className={styles.description}>
              {smallDescription}
            </Typography>
          </Grid>
          <Divider />
          <CardActions>
            <Grid
              container
              item
              xs
              justify="space-between"
              alignItems="flex-end"
              className={styles.actionsWrapper}
            >
              <Grid item xs={7}>
                {Number(discount) > 0 ? (
                  <>
                    <Grid container item xs>
                      <Typography
                        variant="h4"
                        className={styles.discountOriginal}
                      >
                        DE: {handleFormatMoney(price)}
                      </Typography>
                    </Grid>
                    <Grid container item xs>
                      <Typography variant="h3" color="textPrimary">
                        POR:
                      </Typography>
                      <Typography
                        variant="h3"
                        color="textPrimary"
                        className={styles.price}
                      >
                        {handleFormatMoney(
                          (Number(price) - Number(discount)).toString(),
                        )}
                      </Typography>
                    </Grid>
                  </>
                ) : (
                  <Grid container item xs>
                    <Typography
                      variant="h3"
                      color="textPrimary"
                      className={styles.semiBoldText}
                    >
                      {handleFormatMoney(price.toString())}
                    </Typography>
                  </Grid>
                )}
              </Grid>
              <Grid container item xs={5} justify={'flex-end'}>
                <Button
                  onClick={() => callbackLink()}
                  className={styles.noPaddingBottom}
                >
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    className={styles.actionButton}
                  >
                    Ver detalhes
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </CardActions>
          <Grid item xs={12} style={{ marginTop: '13px' }}>
            {stock ? (
              <Button
                onClick={() => verifyIfHaveAccesOnStore()}
                className={styles.confirmButton}
              >
                Adicionar ao carrinho
              </Button>
            ) : (
              <Button disabled className={styles.disabledButton}>
                Esgotado
              </Button>
            )}
          </Grid>
        </Card>
      </Paper>
    );
  },
);

export default ShopProductItem;
