import { combineReducers } from 'redux';
import user from './user';
import auth from './auth';
import pageFilter from './page-filters';
import layout from './layout';
import notification from './notification';
import nav from './nav';
import invoice from './invoice';
import { plpReducer, plpListReducer } from './sigep/plp';
import { orderReducer, ordersListReducer, orderMonthReducer } from './order';
import label from './sigep/label';
import tracking from './sigep/tracking';
import creditCard from './payment/creditCard';
import signature from './generals/signature';
import oneSignature from './generals/signature/oneSignature';
import plans from './generals/plan';
import videos from './generals/video';
import file from './generals/file';
import category from './categories';
import onePlan from './generals/plan/onePlan';
import couponReducer from './coupons';
import { shippingListReducer, shippingReducer } from './shipping';
import cartReducer from './cart';
import storeReducer from './store';
import boxes from './generals/boxes';
import { addressReducer, address } from './generals/address';
import home from './client/home';
import transport from './transport';
import ranking from './ranking';
import storeSettings from './store/storeSettings';
import storeNotification from './store/storeNotification';
import banner from './generals/banner';
import viewState from './views';
import changePlan from './change-plan';
import recurrences from './recurrences';
import leads from './leads';
import benefits from './benefits';
import storeProductReducer from './store/oneProduct';
import dataFreteTransport from './dataFreteTransport';
import preShippimentOrderReducer from './preShippimentOrders';
import enhancedProduct from './enhancedProduct';
import viewUserSignatureLastOrder from './bulk-actions/view-user-last-order';
import viewUserReactivation from './bulk-actions/view-user-reactivation';
import viewUserTurbine from './bulk-actions/view-user-turbine';

export default combineReducers({
  auth,
  pageFilter,
  user,
  layout,
  notification,
  nav,
  invoice,
  plpListReducer,
  plpReducer,
  label,
  ordersListReducer,
  orderReducer,
  orderMonthReducer,
  tracking,
  creditCard,
  signature,
  oneSignature,
  plans,
  onePlan,
  videos,
  couponReducer,
  shippingListReducer,
  shippingReducer,
  cartReducer,
  storeReducer,
  storeProductReducer,
  boxes,
  address,
  addressReducer,
  home,
  transport,
  category,
  file,
  storeNotification,
  banner,
  ranking,
  enhancedProduct,
  storeSettings,
  viewState,
  changePlan,
  recurrences,
  leads,
  benefits,
  dataFreteTransport,
  preShippimentOrderReducer,
  viewUserSignatureLastOrder,
  viewUserReactivation,
  viewUserTurbine,
});
