import { createStyles, makeStyles, Theme } from '@material-ui/core';
import Colors from 'styles/colors';

const useStyles = makeStyles((_theme: Theme) =>
  createStyles({
    menuItemText: {
      fontSize: '1.6rem',
      fontWeight: 600,
      color: Colors.GRAY,
    },
    arrowIcon: {
      fontSize: '3.5rem',
      paddingBottom: '0.2rem',
      marginLeft: '0.2rem',
    },
  }),
);

export { useStyles };
