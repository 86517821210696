import React, { FC, useRef, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Grid,
  Paper,
  Typography,
  FormControlLabel,
} from '@material-ui/core';

import useFormStyles from 'components/generals/forms/register/styles';
import { profileStyles } from '../styles';
import MASK, { UNMASK } from 'helpers/masks';
import { InputRef } from 'types';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { SubscriberPageProps, profileValidationSchema } from '../types';
import UserService from 'services/userService';
import SignatureService from 'services/signatureService';
import { StatusCodes } from 'http-status-codes';
import {
  notifyError,
  notifySuccess,
  enableButton,
  disableButton,
} from 'store/ducks/notification/actions';
import { loadUserRequest } from 'store/ducks/user/actions';
import { openModal, closeModal } from 'store/ducks/nav/actions';
import {
  CHANGE_PROFILE_ERROR,
  CHANGE_PROFILE_SUCCESS,
  REMOVE_PROFILE_PREMIUM_ASK,
  ADD_USER_PREMIUM_ASK,
  ORDER_DETAIL_USER_CHANGE_INFO_CONFIRM,
  ORDER_DETAIL_USER_CHANGE_SUCCESS,
  ORDER_DETAIL_USER_CHANGE_ERROR,
} from 'utils/messages';
import MySwitch from 'components/generals/input/MySwitch';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';

const SubscriberProfilePage: FC<SubscriberPageProps> = ({ signature }) => {
  const dispatch = useDispatch();
  const style = profileStyles();
  const formStyles = useFormStyles();
  const [userPremium, setUserPremium] = useState<boolean>(false);
  const [identification, setIdentifcation] = useState('');
  const { errors, register, control, handleSubmit } = useForm({
    validationSchema: profileValidationSchema,
    defaultValues: {
      name: signature.user?.name,
      email: signature.user?.email,
      phone: signature.user?.phone,
      identification: signature.user?.identification,
    },
  });

  const emailInput = useRef() as InputRef;
  const phoneInput = useRef() as InputRef;
  const identificationInput = useRef() as InputRef;

  const handleIdentificationChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    setIdentifcation(event.target.value);
    return unmasked;
  };

  const handlePhoneChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    return unmasked;
  };

  const handleUptadeInfosOnOrders = async (id: number) => {
    try {
      dispatch(disableButton());
      const response = await SignatureService.changeSignatureOrderAddress(id);
      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ORDER_DETAIL_USER_CHANGE_SUCCESS));
        dispatch(closeModal());
      } else {
        throw new Error(ORDER_DETAIL_USER_CHANGE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const onSubmit = handleSubmit(
    async ({ name, email, phone, identification }) => {
      const response = await UserService.updateProfile(signature.user?.id, {
        name,
        email,
        phone,
        identification,
      });

      if (response.status !== StatusCodes.OK)
        dispatch(notifyError(CHANGE_PROFILE_ERROR));
      else {
        dispatch(notifySuccess(CHANGE_PROFILE_SUCCESS));
        dispatch(loadUserRequest());

        const modalProps: BaseDialogProps = {
          title: 'Alterar informações nos pedidos futuros',
          confirmText: 'Sim',
          cancelText: 'Não',
          message: ORDER_DETAIL_USER_CHANGE_INFO_CONFIRM,
          actionFn: () => handleUptadeInfosOnOrders(signature.id),
        };

        dispatch(openModal(ConfirmationDialog, modalProps));
      }
    },
  );

  const handleUpdateUserPremium = async (e: any, userId: number) => {
    setUserPremium(e.target.checked);
    handleOpenConfirmDialog(userId);
  };

  const handleOpenConfirmDialog = (userId: number) => {
    const modalProps: BaseDialogProps = {
      title: 'Ativar/desativar premium',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: userPremium ? REMOVE_PROFILE_PREMIUM_ASK : ADD_USER_PREMIUM_ASK,
      actionFn: () => handleConfirmUpdateUserPremium(userId),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleConfirmUpdateUserPremium = async (userId: number) => {
    try {
      dispatch(disableButton());
      const response = await UserService.updateUserPremium(
        userId,
        !userPremium,
      );
      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(CHANGE_PROFILE_SUCCESS));
        dispatch(loadUserRequest());
        dispatch(closeModal());
      } else {
        throw new Error(CHANGE_PROFILE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  useEffect(() => {
    setUserPremium(signature.user.isPremium);
  }, [signature]);

  return (
    <Paper className={style.rootPaper} elevation={0}>
      <Grid container direction="row" justify="space-between">
        <Grid item xs>
          <Typography
            variant="h1"
            color="textPrimary"
            style={{ fontWeight: 'normal' }}
          >
            Informações do <strong>perfil</strong>
          </Typography>
        </Grid>
        <Grid container item xs justify="flex-end">
          <FormControlLabel
            control={
              <MySwitch
                name="userPremium"
                color="primary"
                checked={userPremium}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleUpdateUserPremium(e, signature.user.id);
                }}
              />
            }
            label="Premium"
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${formStyles.buttonLabel} ${formStyles.buttonWrapper}`}
            onClick={() => onSubmit()}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} md={8} spacing={3} className={style.mTop}>
        <Grid item xs={12}>
          <MyOutlinedTextField
            fullWidth
            label="Nome completo"
            id="name"
            name="name"
            type="text"
            changeFocusOnEnter={() => emailInput.current.focus()}
            error={Boolean(errors.name)}
            helperText={errors.name ? errors.name.message : null}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12}>
          <MyOutlinedTextField
            fullWidth
            label="Email"
            id="email"
            name="email"
            inputProps={{ ref: emailInput }}
            changeFocusOnEnter={() => phoneInput.current.focus()}
            error={Boolean(errors.email)}
            helperText={errors.email ? errors.email.message : null}
            inputRef={register}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={InputMask}
            mask={MASK.CELLPHONE}
            name="phone"
            control={control}
            maskChar={null}
            onChange={handlePhoneChange}
            defaultValue={''}
          >
            {() => (
              <MyOutlinedTextField
                fullWidth
                label="Telefone"
                type="text"
                name="phone"
                inputProps={{ ref: phoneInput, inputMode: 'numeric' }}
                changeFocusOnEnter={() => identificationInput.current.focus()}
                error={Boolean(errors.phone)}
                helperText={errors.phone ? errors.phone.message : null}
                inputRef={register}
              />
            )}
          </Controller>
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            as={InputMask}
            mask={MASK.CPF}
            name="identification"
            control={control}
            maskChar={null}
            onChange={handleIdentificationChange}
            defaultValue={''}
          >
            {() => (
              <MyOutlinedTextField
                fullWidth
                label="CPF"
                type="text"
                inputProps={{
                  ref: identificationInput,
                  inputMode: 'numeric',
                }}
                changeFocusOnEnter={() => phoneInput.current.focus()}
                error={Boolean(errors.identification)}
                helperText={
                  errors.identification ? errors.identification.message : null
                }
              />
            )}
          </Controller>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SubscriberProfilePage;
