import React, { FC } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { OrderItemProps } from './types';
import { ORDER_STATUS_LIST } from 'helpers/orderConstants';
import OrderInfo from './OrderInfo';
import MyStepper from 'components/generals/stepper/MyStepper';

const OrderStatus: FC<OrderItemProps> = ({ order }) => {
  const styles = useStyles();
  const STATUS_HISTORY = order.statusHistory;

  return (
    <Grid item xs={12} style={{ marginTop: '25px', marginBottom: '25px' }}>
      <Paper className={styles.paperTable} elevation={0}>
        <Typography variant="h3" className={styles.paperInfoTitle}>
          Status do Pedido
        </Typography>
        <Grid container item md={4} sm={6} justify="flex-start">
          <OrderInfo order={order} />
        </Grid>
        <Grid item xs>
          <MyStepper
            order={order}
            steps={ORDER_STATUS_LIST}
            statusHistory={STATUS_HISTORY}
          />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default OrderStatus;
