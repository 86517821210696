import React from 'react';
import { Grid } from '@material-ui/core';

import { UserImageZoneProps } from './types';
import ImageZone from 'components/admin/ImageZone';

const UserImageZone: React.FC<UserImageZoneProps> = ({
  title = '',
  photo,
  photoData,
  handleUpdatePhoto,
  handleUpdatePhotoData,
  errors,
  register,
  setValue,
}) => {
  const handleDropFile = (file: any) => {
    if (file?.length) {
      const _file = file[0];
      handleUpdatePhoto([_file]);
      handleUpdatePhotoData({
        imageLink: _file.image,
      });
    }
  };

  const handleRemoveFromDropzone = () => {
    handleUpdatePhoto([]);
    handleUpdatePhotoData({ imageLink: '' });
  };

  return (
    <Grid item xs={12}>
      <ImageZone
        title={title}
        subtitle="Alterar foto de perfil"
        register={register}
        setValue={setValue}
        errors={errors}
        multiple={false}
        files={photo}
        filesData={photoData}
        handleDropFile={handleDropFile}
        handleRemoveFile={handleRemoveFromDropzone}
      />
    </Grid>
  );
};

export default UserImageZone;
