import { Dispatch } from 'redux';
import { logoutRequest } from 'store/ducks/auth/actions';

import { ActionsType } from '../types';

export const handleAction = (dispatch: Dispatch) => (action: ActionsType) => {
  const actions = {
    logout: () => dispatch(logoutRequest()),
  };

  actions[action]();
};
