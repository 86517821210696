import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { closeModal } from 'store/ducks/nav/actions';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import MyNumberFormat from 'components/generals/input/MyNumberFormat';
import { validationSchema } from './schema';
import { useStyles } from './styles';
import { ChargebackDialogProps } from '../dialogTypes';
import { ORDER_REFUND_ERROR, PAYMENT_NOT_FOUND_ORDER } from 'utils/messages';
import { notifyError } from 'store/ducks/notification/actions';

const ChargebackDialog: FC<ChargebackDialogProps> = ({
  actionFn,
  title,
  message,
  cancelText,
  confirmText,
  order,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { control, errors, handleSubmit, register } = useForm({
    validationSchema,
  });

  const onSubmit = (data: any) => {
    const orderAmount = order?.orderDetail?.amount;
    let { chargebackAmount, refundReason } = data;

    chargebackAmount = Number.parseFloat(chargebackAmount);

    if (!orderAmount) {
      dispatch(notifyError(ORDER_REFUND_ERROR(PAYMENT_NOT_FOUND_ORDER)));
      dispatch(closeModal());
    } else {
      actionFn(order.id, refundReason, chargebackAmount);
      dispatch(closeModal());
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ marginTop: 8, marginBottom: 24 }} spacing={2}>
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h3" color="textPrimary">
              {message}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="chargebackAmount"
              control={control}
              as={({ onChange, value }) => (
                <MyOutlinedTextField
                  fullWidth
                  label="Valor para o estorno parcial"
                  name="chargebackAmount"
                  type="text"
                  placeholder="R$"
                  InputProps={{
                    inputComponent: MyNumberFormat as any,
                  }}
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors.chargebackAmount)}
                  helperText={errors.chargebackAmount?.message || null}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <MyOutlinedTextField
              id="refundReason"
              name="refundReason"
              label="Motivo"
              multiline
              fullWidth
              rows={3}
              inputRef={register}
              error={Boolean(errors.refundReason)}
              helperText={
                errors.refundReason ? errors.refundReason.message : null
              }
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={styles.buttonLabel}
          style={{ marginRight: 30 }}
          onClick={() => dispatch(closeModal())}
        >
          {cancelText ?? 'Cancelar'}
        </Button>
        <Button
          variant="contained"
          color="primary"
          className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
          onClick={handleSubmit(onSubmit) ?? (() => dispatch(closeModal()))}
        >
          {confirmText ?? 'Confirmar'}
        </Button>
      </DialogActions>
    </Grid>
  );
};

export default ChargebackDialog;
