import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  buttonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
  },
  buttonWrapper: {
    padding: '0.5em 3em',
  },
});
