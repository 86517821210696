import React, { useEffect, useState, useCallback } from 'react';
import {
  Grid,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { CondOperator } from '@nestjsx/crud-request';
import clsx from 'clsx';
import { StatusCodes } from 'http-status-codes';
import { useHistory } from 'react-router-dom';

import { AppState } from 'store';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import { RecurrenceState } from 'store/ducks/recurrences/types';
import { PlanState } from 'store/ducks/generals/plan/types';
import { loadAllPlans } from 'store/ducks/generals/plan/actions';
import {
  notifyError,
  notifySuccess,
  disableButton,
  enableButton,
} from 'store/ducks/notification/actions';

import MySelectForm from '../input/MySelectForm';
import ConfirmationDialog from './ConfirmationDialog';

import SignatureService from 'services/signatureService';

import useStyles from 'components/generals/forms/register/styles';
import { ChangePlan } from './types';
import { changePlanValidationSchema } from './utils';
import { BaseDialogProps, SignatureTypeDialogProps } from './dialogTypes';
import { Plan, PAYMENT_TYPE } from 'types/generals';
import {
  CHANGE_PLAN_SIGNATURE_ERROR,
  CHANGE_PLAN_SIGNATURE_SUCCESS,
  CHANGE_PLAN_SIGNATURE_ASK,
  CREDIT_CARD_NOT_FOUND,
} from 'utils/messages';
import { moneyFormat } from 'pages/client/signature/utils';
import { sleep } from 'utils/functions/generals';

const ChangePlanDialog: React.FC<SignatureTypeDialogProps> = ({
  modalTitle,
  submitText,
  subscriber,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();

  const [selectedRecurrence, setSelectedRecurrence] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<Plan>();
  const [subscriberHasCreditCard, setSubscriberHasCreditCard] = useState(false);
  const [showCardNotExistMessage, setShowCardNotExistMessage] = useState(false);

  const { handleSubmit, setValue, errors, control, getValues } = useForm({
    validationSchema: changePlanValidationSchema,
  });
  const paymentTypeStyle = {
    display: subscriber.isDonation ? 'none' : 'block',
  };

  const { data: recurrences, isLoading } = useSelector<
    AppState,
    RecurrenceState
  >(({ recurrences }: AppState) => recurrences);

  const { data: plans } = useSelector<AppState, PlanState>(
    ({ plans }: AppState) => plans,
  );

  const handleSelectPlan = (id: number) => {
    const currentPlan = plans.find(plan => plan.id === id);
    if (currentPlan) {
      setSelectedPlan(currentPlan);
    }
  };

  const onSubmit = async (data: ChangePlan) => {
    try {
      dispatch(disableButton());
      const { isDonation = false } = subscriber;
      const response = await SignatureService.changePlan(
        subscriber.id,
        data.planId,
        data.paymentType,
        isDonation,
      );

      if (response.status === StatusCodes.CREATED) {
        await sleep(3000);
        dispatch(notifySuccess(CHANGE_PLAN_SIGNATURE_SUCCESS));
        const response = await SignatureService.handleGetNextSignatureByCurrentSignatureId(
          subscriber.id,
        );
        if (response.data) {
          history.push(`/admin/subscriptions/${response.data.id}`);
        } else {
          history.go(0);
        }
      } else {
        throw new Error(CHANGE_PLAN_SIGNATURE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
      dispatch(closeModal());
    }
  };

  const handleOpenConfirmDialog = (data: ChangePlan) => {
    const modalProps: BaseDialogProps = {
      title: 'Alterando Assinatura',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: CHANGE_PLAN_SIGNATURE_ASK,
      actionFn: () => onSubmit(data),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleVerifySubscriberHasCreditCard = () => {
    const paymentType = getValues('paymentType');
    if (paymentType === PAYMENT_TYPE.CARD && !subscriberHasCreditCard) {
      setShowCardNotExistMessage(true);
    } else {
      setShowCardNotExistMessage(false);
    }
  };

  const handleChangeRecurrences = (e: any) => {
    setSelectedRecurrence(e.target.value);
    setSelectedPlan(undefined);
    setValue('planId', '');
    setValue('paymentType', '');
    handleVerifySubscriberHasCreditCard();
  };

  const handleChangePlan = (e: any) => {
    handleSelectPlan(e.target.value);
    const { isDonation = false, paymentType } = subscriber;
    if (isDonation) {
      const donationPaymentType =
        paymentType === PAYMENT_TYPE.BANKSLIP
          ? PAYMENT_TYPE.BANKSLIP
          : PAYMENT_TYPE.CARD;
      setValue('paymentType', donationPaymentType);
    } else {
      setValue('paymentType', '');
    }
  };

  const loadAvailablePlans = useCallback(() => {
    dispatch(
      loadAllPlans({
        join: [{ field: 'recurrence', select: ['id', 'name'] }],
        filter: [
          {
            field: 'recurrence.id',
            operator: CondOperator.EQUALS,
            value: selectedRecurrence,
          },
          {
            field: 'isDonation',
            value: subscriber.isDonation || false,
            operator: CondOperator.EQUALS,
          },
        ],
        sort: { field: 'createdAt', order: 'DESC' },
      }),
    );
  }, [dispatch, selectedRecurrence, subscriber.isDonation]);

  const verifySubscriberHasCreditCard = useCallback(async () => {
    try {
      const response = await SignatureService.changePlanVerifyIfHasCardRegistered(
        subscriber.user.id,
      );

      if (response.status === StatusCodes.OK) {
        setSubscriberHasCreditCard(true);
      } else {
        throw new Error(CREDIT_CARD_NOT_FOUND);
      }
    } catch (error) {
      setSubscriberHasCreditCard(false);
    }
  }, [subscriber.user.id]);

  useEffect(() => {
    loadAvailablePlans();
  }, [loadAvailablePlans]);

  useEffect(() => {
    verifySubscriberHasCreditCard();
  }, [verifySubscriberHasCreditCard]);

  return (
    <>
      {!isLoading && recurrences.length ? (
        <Grid container direction="column">
          <DialogTitle>
            <Grid container justify="space-between">
              <Typography variant="h1">{modalTitle}</Typography>
              <IconButton onClick={() => dispatch(closeModal())}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>

          <DialogContent>
            <Grid container direction="column" spacing={1}>
              <Grid item xs>
                <Controller
                  name="recurrenceId"
                  control={control}
                  defaultValue=""
                  as={({ onChange, onBlur, value }) => (
                    <MySelectForm
                      contracted
                      title="Recorrência da cobrança"
                      itens={recurrences.map(recurrence => ({
                        title: recurrence.name,
                        value: recurrence.id,
                      }))}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        handleChangeRecurrences(e);
                      }}
                      onBlur={onBlur}
                      value={value}
                      error={Boolean(errors.recurrenceId)}
                      helperText={
                        errors.recurrenceId ? errors.recurrenceId.message : null
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs>
                <Controller
                  name="planId"
                  control={control}
                  defaultValue=""
                  as={({ onChange, onBlur, value }) => (
                    <MySelectForm
                      contracted
                      title="Planos"
                      itens={
                        plans.length
                          ? plans.map(plan => ({
                              title: plan.name,
                              value: plan.id,
                            }))
                          : [{ title: 'Selecione uma recorrência', value: '' }]
                      }
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        handleChangePlan(e);
                      }}
                      onBlur={onBlur}
                      value={value}
                      error={Boolean(errors.planId)}
                      helperText={errors.planId ? errors.planId.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs style={paymentTypeStyle}>
                <Controller
                  name="paymentType"
                  control={control}
                  as={({ value, onChange }) => (
                    <RadioGroup
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        handleVerifySubscriberHasCreditCard();
                      }}
                      value={value || ''}
                      aria-label="payment-option"
                    >
                      {selectedPlan?.paymentType === PAYMENT_TYPE.BANKSLIP ||
                      selectedPlan?.paymentType ===
                        PAYMENT_TYPE.BANKSLIP_CARD ? (
                        <>
                          <FormControlLabel
                            value="boleto"
                            control={<Radio />}
                            label={`Boleto - ${moneyFormat(
                              selectedPlan.prices.bankslipPrice,
                            )}`}
                            className={styles.radioWrapper}
                          />
                        </>
                      ) : null}

                      {selectedPlan?.paymentType === PAYMENT_TYPE.CARD ||
                      selectedPlan?.paymentType ===
                        PAYMENT_TYPE.BANKSLIP_CARD ? (
                        <FormControlLabel
                          value="cartao"
                          control={<Radio />}
                          label={`Cartão de crédito - ${moneyFormat(
                            selectedPlan.prices.cardPrice,
                          )}`}
                          className={styles.radioWrapper}
                        />
                      ) : null}
                    </RadioGroup>
                  )}
                />
                {Boolean(errors.paymentType) && selectedPlan ? (
                  <FormHelperText className={styles.paymentAlert}>
                    Selecione um metodo de pagamento
                  </FormHelperText>
                ) : null}

                {showCardNotExistMessage ? (
                  <FormHelperText className={styles.paymentAlert}>
                    Assinante não possui um cartão cadastrado
                  </FormHelperText>
                ) : null}
              </Grid>

              <Grid container item justify="flex-end">
                <Button
                  className={clsx(styles.buttonLabel, styles.buttonWrapper)}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(handleOpenConfirmDialog as any)}
                >
                  {submitText}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
      ) : null}
    </>
  );
};

export default ChangePlanDialog;
