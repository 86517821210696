import { RequestQueryBuilder, CreateQueryParams } from '@nestjsx/crud-request';
import axios, { AxiosRequestConfig } from 'axios';
import useSWR from 'swr';
import { StatusCodes } from 'http-status-codes';
import { useLocation } from 'react-router-dom';
import {
  BAD_REQUEST,
  UNAUTHORIZED,
  BLOB_FILE_PARSE_ERROR,
} from 'utils/messages';
import { ADMIN_LOGIN_URL, LOGIN_URL } from 'routes/types';
import { persistor } from 'store';
import { IServiceRequestResponse } from 'types/client';

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export const apiQueue = axios.create({
  baseURL: process.env.REACT_APP_API_QUEUE_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

export const apiQueueSchedulers = axios.create({
  baseURL: process.env.REACT_APP_API_QUEUE_SCHEDULER_URL,
  headers: {
    Accept: 'application/json',
    'Content-type': 'application/json',
  },
});

// instance to run requests in JSON server
export const serverApi = axios.create({
  baseURL: 'http://localhost:3004',
});

/**
 *
 * Método utilizado para converter o objeto de retorno de um request com
 * `responseType: Blob` para o tipo `JSON`.
 *
 * @param file O arquivo (Blob) de resposta do request a ser convertido para JSON.
 */
export const transformBlobResponseInJSON = async (
  file: any,
): Promise<IServiceRequestResponse> => {
  const fileReader = new FileReader();

  const result = await new Promise((resolve, reject) => {
    fileReader.onerror = () => {
      fileReader.abort();
      reject(new Error(BLOB_FILE_PARSE_ERROR));
    };

    fileReader.onload = () => {
      resolve(fileReader.result);
    };

    fileReader.readAsText(file);
  });

  return JSON.parse(result as string);
};

export const useInterceptors = () => {
  const { pathname } = useLocation();
  const handleGoToLogin = (errorMessage: string, path: string) => {
    persistor.purge();
    localStorage.clear();
    localStorage.setItem('errorMessage', errorMessage);
    // if (path.includes('admin')) {
    //   window.location.replace('/admin/login');
    // } else {
    //   window.location.replace('/');
    // }
  };

  api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (pathname !== ADMIN_LOGIN_URL && pathname !== LOGIN_URL) {
        if (error.message === 'Network Error' && !error.reponse) {
          return handleGoToLogin(BAD_REQUEST, pathname);
        } else if (error.response.status === StatusCodes.UNAUTHORIZED) {
          return handleGoToLogin(UNAUTHORIZED, pathname);
        }
      }
      return Promise.reject(error);
    },
  );
};

// Service to request with Redux
export async function getWithSaga(
  url: string,
  queryParams?: CreateQueryParams,
) {
  const queryString = RequestQueryBuilder.create({ ...queryParams }).query();

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  try {
    const { data } = await api.get(url);
    return data;
  } catch (err) {
    return err;
  }
}

// Service to request with Redux
export async function useGetFiletWithSaga(
  url: string,
  queryParams?: CreateQueryParams,
  config?: AxiosRequestConfig,
) {
  const queryString = RequestQueryBuilder.create({ ...queryParams }).query();
  const _config: AxiosRequestConfig = {
    method: 'GET',
    responseType: 'blob',
    ...config,
  };

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  try {
    const { data } = await apiQueue.get(url, { ..._config });
    return data;
  } catch (err) {
    return err;
  }
}

// Service to request without Redux
export function useGetWithSWR(
  url: string,
  queryParams?: CreateQueryParams,
  revalidateOnFocus = false,
) {
  const queryString = RequestQueryBuilder.create({
    ...queryParams,
  }).query();

  let AXIOS_API = api;
  if (url === 'queues/report') AXIOS_API = apiQueue;

  if (url === 'queues/report-scheduler') AXIOS_API = apiQueueSchedulers;

  if (queryString) {
    url = `${url}?${queryString}`;
  }

  const { data, error, isValidating, revalidate } = useSWR(
    url,
    async () => {
      const response = await AXIOS_API.get(url);
      return response.data;
    },
    { revalidateOnFocus },
  );
  return { data, error, isValidating, revalidate };
}

export interface GetProps {
  data: [];
  statusCode: number;
  message: string;
  error: string;
  count: number;
  total: number;
  page: number;
  pageCount: number;
}
