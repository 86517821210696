import React, {
  ChangeEvent,
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { StatusCodes } from 'http-status-codes';
import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import { Create } from '@material-ui/icons';
import { first } from 'lodash';

import InputField from 'components/generals/input/MyOutlinedTextField';
import MySwitch from 'components/generals/input/MySwitch';
import MyNumberFormat from 'components/generals/input/MyNumberFormat';
import MyDatePicker from 'components/generals/input/MyDatePicker';
import ImagesZone from 'components/admin/ImagesZone';
import { ImageZone } from 'components/admin/ImagesZone/types';

import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import StoreService from 'services/storeService';
import { useStyles } from '../styles';
import { SIGNATURE_STATUS, StoreCategory, StoreProduct } from 'types/generals';
import { ProductState } from './types';
import { validationSchema, defaultValues } from './schema';
import {
  DELETE_PRODUCT_IMAGE_FAIL,
  PRODUCT_REGISTER_FAIL,
  PRODUCT_REGISTER_SUCCESS,
  PRODUCT_UPDATE_FAIL,
  PRODUCT_UPDATE_SUCCESS,
} from 'utils/messages';
import PickColorDialog from 'components/generals/dialog/PickColorDialog';
import { setLineBreak } from '../utils';
import MySelectForm from 'components/generals/input/MySelectForm';
import { AppState } from 'store';
import { CategoriesState } from 'store/ducks/categories/types';
import { loadCategoryRequest } from 'store/ducks/categories/actions';

const NewStoreProduct: FC = memo(() => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const locationProduct = useLocation<ProductState>().state.product;
  const [enabledStore, setEnabledStore] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);
  const [existingFiles, setExistingFiles] = useState<ImageZone[]>([]);
  // fixme
  const [categories, setCategories] = useState<number[]>([]);

  const {
    control,
    errors,
    register,
    setValue,
    handleSubmit,
    reset,
    watch,
  } = useForm({
    validationSchema,
    defaultValues: defaultValues(locationProduct),
  });

  const { categories: storeCategories } = useSelector<
    AppState,
    CategoriesState
  >(({ category }: AppState) => category);

  const loadAllCategories = useCallback(() => {
    dispatch(
      loadCategoryRequest({
        page: 0,
        limit: 100,
        search: {
          $and: [{ isActive: { $eq: true } }],
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    loadAllCategories();
  }, [loadAllCategories]);

  useEffect(() => {
    if (locationProduct) {
      const productLocalFiles: ImageZone[] = locationProduct.images.map(
        ({ id, image, isCover }) => ({
          id,
          image,
          isCover,
        }),
      );

      const productTag = first(locationProduct.productTag);
      if (productTag) {
        setValue('tagName', productTag.tagName);
        setValue('tagColor', productTag.tagColor);
      }
      if (locationProduct.categories) {
        // setCategories(locationProduct.categories)
        setCategories(() =>
          locationProduct.categories.map((cat: StoreCategory) => {
            return cat.id;
          }),
        );
      }

      setExistingFiles(productLocalFiles);
      setValue('price', Number(locationProduct.price));
      setValue('discount', Number(locationProduct.discount));
      setEnabledStore(locationProduct.isVisible);
    }
  }, [locationProduct, setValue]);

  const icon = (
    <div className={styles.createIconCircle} onClick={() => setOpen(true)}>
      <Create className={styles.createIcon} />
    </div>
  );

  const handleProductsStore = () => history.push('/admin/storeProducts');

  const handleChangeCategories = (event: any) => {
    const value = event?.target?.value;
    setCategories(value);
  };

  const onSubmit = async (data: any) => {
    try {
      setIsDisabled(true);
      const images = watch('images');
      let product: StoreProduct = {
        ...data,
        name: data.productName,
        startDate: new Date(data.startDate).toISOString(),
        isVisible: enabledStore,
        price: data.price,
        discount: data.discount,
        specifications: setLineBreak(data.specifications),
        about: setLineBreak(data.about),
        items: setLineBreak(data.items),
        categories: storeCategories.filter(category =>
          categories.includes(category.id),
        ),
      };
      let response: any;

      if (data.endDate) {
        product.endDate = new Date(data.endDate).toISOString();
      } else {
        delete product.endDate;
      }

      if (locationProduct) {
        product.id = locationProduct.id;
        response = await StoreService.editStoreProduct(product, images);
      } else {
        response = await StoreService.createStoreProduct(product, images);
      }

      if (
        response.status === StatusCodes.CREATED ||
        response.status === StatusCodes.OK
      ) {
        const successMessage =
          response.status === StatusCodes.CREATED
            ? PRODUCT_REGISTER_SUCCESS
            : PRODUCT_UPDATE_SUCCESS;

        reset();
        dispatch(notifySuccess(successMessage));
        history.push('/admin/storeProducts');
      } else {
        const errMessage = locationProduct
          ? PRODUCT_UPDATE_FAIL
          : PRODUCT_REGISTER_FAIL;
        throw new Error(errMessage);
      }
    } catch (err) {
      dispatch(notifyError(err.message));
    } finally {
      setIsDisabled(false);
    }
  };

  const deleteProductImage = async (imageId: number) => {
    try {
      await StoreService.deleteStoreImage(locationProduct.id, imageId);
    } catch (error) {
      dispatch(notifyError(DELETE_PRODUCT_IMAGE_FAIL));
    }
  };

  const HeaderInfo = () => (
    <Fragment>
      <Grid item xs>
        <Typography
          variant="h1"
          color="textPrimary"
          style={{ fontWeight: 'bold' }}
        >
          {locationProduct
            ? `Editar - ${locationProduct.name}`
            : 'Informações principais'}
        </Typography>
      </Grid>
      <Grid item xs={false}>
        <Button
          id="cancel-new-boxStore"
          name="cancelSubmit"
          color="primary"
          className={clsx(styles.buttonLabel, styles.mRight)}
          onClick={() => handleProductsStore()}
        >
          Cancelar
        </Button>
        <Button
          id="save-boxStore"
          name="submitSave"
          type="submit"
          variant="contained"
          color="primary"
          className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
          onClick={handleSubmit(onSubmit)}
          disabled={isDisabled}
        >
          Salvar
        </Button>
      </Grid>
    </Fragment>
  );

  return (
    <Grid
      container
      item
      justify="space-between"
      direction="column"
      spacing={5}
      style={{ marginBottom: 20 }}
    >
      <Grid
        container
        item
        justify="space-between"
        direction="row"
        alignItems="center"
        style={{ marginTop: '24px' }}
      >
        <HeaderInfo />
      </Grid>
      <Grid item xs>
        <Paper className={styles.rootPaper} elevation={0}>
          <Grid
            container
            item
            justify="space-between"
            direction="row"
            alignItems="flex-start"
            spacing={3}
          >
            <Grid item xs={6}>
              <InputField
                fullWidth
                id="store-product-name"
                label="Nome do produto"
                name="productName"
                error={Boolean(errors.productName)}
                helperText={
                  errors.productName ? errors.productName.message : null
                }
                inputRef={register}
              />
            </Grid>
            <Grid item xs={4}>
              <InputField
                fullWidth
                id="store-product-sku"
                label="SKU"
                name="sku"
                error={Boolean(errors.sku)}
                helperText={errors.sku ? errors.sku.message : null}
                inputRef={register}
              />
            </Grid>

            <Grid item xs>
              <Grid container justify="flex-end" direction="row">
                <FormControlLabel
                  name="enabledStore"
                  control={
                    <MySwitch
                      name="enabledStore"
                      color="primary"
                      checked={enabledStore}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setEnabledStore(e.target.checked)
                      }
                    />
                  }
                  label="Habilitar na loja"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="startDate"
                control={control}
                as={({ onChange, value }) => (
                  <MyDatePicker
                    id="boxStore-start-date"
                    value={value}
                    onChange={onChange}
                    disablePast
                    name="startDate"
                    label="Data de início"
                    error={Boolean(errors.startDate)}
                    helperText={
                      errors.startDate ? errors.startDate.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="endDate"
                control={control}
                as={({ onChange, value }) => (
                  <MyDatePicker
                    id="boxStore-end-date"
                    value={value}
                    onChange={onChange}
                    minDate={
                      watch('startDate')
                        ? new Date(watch('startDate'))
                        : new Date()
                    }
                    name="endDate"
                    label="Data de término"
                    error={Boolean(errors.endDate)}
                    helperText={errors.endDate ? errors.endDate.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6}>
              <InputField
                fullWidth
                id="store-tag-name"
                label="Nome da tag"
                name="tagName"
                error={Boolean(errors.tagName)}
                helperText={errors.tagName ? errors.tagName.message : null}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <InputField
                fullWidth
                id="store-tag-color"
                label="Cor da tag (#FFFFFF)"
                name="tagColor"
                error={Boolean(errors.tagColor)}
                helperText={errors.tagColor ? errors.tagColor.message : null}
                inputRef={register}
                icon={icon}
              />
            </Grid>
            <PickColorDialog open={open} setModalIsOpen={setOpen} />

            <Grid item xs={6}>
              <MySelectForm
                contracted
                itens={storeCategories?.map(category => ({
                  title: category.name,
                  value: category.id,
                }))}
                onChange={handleChangeCategories}
                value={categories}
                helperText={null}
                title="Categorias"
                error={false}
                multiple={true}
                menuProps={{
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left',
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left',
                  },
                  getContentAnchorEl: null,
                }}
              />
            </Grid>

            <Grid item xs={3}>
              <InputField
                fullWidth
                id="store-keyword"
                label="Palavras chave"
                name="keyword"
                error={Boolean(errors.keyword)}
                helperText={errors.keyword ? errors.keyword.message : null}
                inputRef={register}
              />
            </Grid>

            <Grid item xs={3}>
              <InputField
                fullWidth
                id="store-box-weight"
                label="Peso"
                name="weight"
                error={Boolean(errors.weight)}
                helperText={errors.weight ? errors.weight.message : null}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="price"
                control={control}
                as={({ value, onChange }) => (
                  <InputField
                    fullWidth
                    id="store-box-price"
                    label="Valor"
                    name="price"
                    value={value}
                    onChange={onChange}
                    placeholder={'R$'}
                    InputProps={{
                      inputComponent: MyNumberFormat as any,
                    }}
                    error={Boolean(errors.price)}
                    helperText={errors.price ? errors.price.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="discount"
                control={control}
                as={({ value, onChange }) => (
                  <InputField
                    fullWidth
                    id="discount"
                    label="Desconto"
                    name="discount"
                    value={value}
                    onChange={onChange}
                    placeholder={'R$'}
                    InputProps={{
                      inputComponent: MyNumberFormat as any,
                    }}
                    error={Boolean(errors.discount)}
                    helperText={
                      errors.discount ? errors.discount.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <Controller
                name="stock"
                control={control}
                as={({ value, onChange }) => (
                  <InputField
                    fullWidth
                    id="store-box-stock"
                    label="Quantidade"
                    name="stock"
                    value={value}
                    onChange={onChange}
                    placeholder={''}
                    error={Boolean(errors.stock)}
                    helperText={errors.stock ? errors.stock.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <InputField
                fullWidth
                rows={8}
                multiline
                id="store-box-about"
                label="Sobre"
                name="about"
                error={Boolean(errors.about)}
                helperText={errors.about ? errors.about.message : null}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputField
                fullWidth
                rows={8}
                multiline
                id="store-box-specifications"
                label="Especificações"
                name="specifications"
                error={Boolean(errors.specifications)}
                helperText={
                  errors.specifications ? errors.specifications.message : null
                }
                inputRef={register}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <InputField
                fullWidth
                rows={8}
                multiline
                id="store-box-smallDescription"
                label="Descrição"
                name="smallDescription"
                error={Boolean(errors.smallDescription)}
                helperText={
                  errors.smallDescription
                    ? errors.smallDescription.message
                    : null
                }
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <InputField
                fullWidth
                rows={8}
                multiline
                id="store-box-items"
                label="Itens"
                name="items"
                error={Boolean(errors.items)}
                helperText={errors.items ? errors.items.message : null}
                inputRef={register}
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid item xs>
        <ImagesZone
          deleteImageFn={deleteProductImage}
          files={existingFiles}
          errors={errors}
          register={register}
          setValue={setValue}
        />
      </Grid>
    </Grid>
  );
});

export default NewStoreProduct;
