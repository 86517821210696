import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  closeButton: {
    cursor: 'pointer',
  },
  root: {
    '& .MuiSnackbarContent-root': {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      padding: '18px 24px',
      backgroundColor: '#3c3c3b',
      borderRadius: '10px',
      fontSize: 14,
      maxWidth: '550px',
      color: '#e6e6e6',
    },
    '& .MuiSnackbarContent-message': {
      width: '80%',
      padding: 0,
      lineHeight: '18.5px',
      '& svg': {
        width: 32,
        minWidth: 32,
        marginRight: 8,
      },
    },
    '& .MuiSnackbarContent-action': {
      marginRight: 0,
    },
  },
}));
