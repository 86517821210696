import { CreateQueryParams } from '@nestjsx/crud-request';
import { ROOT_PATH } from 'services/fileservice';
import { action } from 'typesafe-actions';
import { VideoPayload, VideosTypes } from './types';

export const loadAllVideos = (query?: CreateQueryParams) => {
  return action(VideosTypes.LOAD_VIDEO_LIST_REQUEST, query);
};
export const loadAllVideosSuccess = (payload: VideoPayload) => {
  return action(VideosTypes.LOAD_VIDEO_LIST_SUCCESS, payload);
};
export const loadAllVideosFailure = () => {
  return action(VideosTypes.LOAD_VIDEO_LIST_FAILURE);
};

/* export const loadPlanFile = (query: CreateQueryParams, rootPath: ROOT_PATH) => {
  return action(VideosTypes.LOAD_PLAN_FILE_REQUEST, { query, rootPath });
};
 */
