import React from 'react';
import {
  Grid,
  Typography,
  Button,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import MyDropdown from 'components/generals/dropdown/MyDropdown';
import FeedbackDialog from 'components/generals/dialog/FeedbackDialog';
import {
  FeedbackDialogPropsBase,
  BaseDialogProps,
} from 'components/generals/dialog/dialogTypes';
import { ReactComponent as ErrorIcon } from 'assets/img/svg/icon_active_error.svg';

import { openModal, closeModal } from 'store/ducks/nav/actions';

import { FormatOptions } from 'types';
import { PlanCardInfoProps } from './types';
import { PAYMENT_TYPE, getUnitPrice, RECURRENCES_MONTHS } from 'types/generals';
import { moneyFormat } from './utils';
import { formatColumnValue } from 'helpers/formatters';
import { useStyles } from './styles';
import {
  CANCEL_SIGNATURE_SUPPORT_MESSAGE,
  NEXT_SIGNATURE_NOT_EXISTS,
  FORCE_CANCEL_NEXT_SIGNATURE_SUCCESS,
  CANCEL_NEXT_SIGNATURE_USER_INFO,
} from 'utils/messages';
import SignatureService from 'services/signatureService';
import { StatusCodes } from 'http-status-codes';
import {
  disableButton,
  notifySuccess,
  notifyError,
  enableButton,
} from 'store/ducks/notification/actions';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { goToExternalLink } from 'utils/functions/generals';
import { CANCELED_SIGNATURE_LINK } from 'utils/links/common';
import DialogWithCustomButtons from 'components/generals/dialog/FeedBackWithButtonDialog/DialogWithCustomButons';

const PlanCardInfo: React.FC<PlanCardInfoProps> = ({ plan, signature }) => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.between('xs', 'sm'));

  const FRETE = 15;
  const PLAN_CARD_PRICE_CUSTOM =
    plan.prices.cardPrice - FRETE * plan.recurrence.months;
  const PLAN_BANKSLIP_PRICE_CUSTOM =
    plan.prices.bankslipPrice - FRETE * plan.recurrence.months;

  const goToChangePlan = () => {
    history.push('/my-account/signature/change-plan');
  };

  const goToCreditCardPage = () => {
    history.push('/my-account/cards');
  };

  const handleRequestCanceled = () => {
    const handleClose = () => dispatch(closeModal());
    const goToLink = () => goToExternalLink(CANCELED_SIGNATURE_LINK);
    const modalProps: FeedbackDialogPropsBase = {
      title: 'Solicitação de cancelamento',
      message: CANCEL_SIGNATURE_SUPPORT_MESSAGE,
      icon: ErrorIcon,
      buttonActions: [
        <Button
          onClick={() => goToLink()}
          variant="outlined"
          color="primary"
          className={styles.buttonDialog}
        >
          Seguir com o cancelamento
        </Button>,
        <Button
          onClick={() => handleClose()}
          variant="contained"
          color="primary"
          className={styles.buttonDialog}
        >
          Vou permanecer no clube
        </Button>,
      ],
    };

    dispatch(openModal(DialogWithCustomButtons, modalProps));
  };

  const handleOpenModalConfirmCanceNextSignature = () => {
    const modalProps: BaseDialogProps = {
      title: 'Deseja cancelar a troca de plano?',
      message: CANCEL_NEXT_SIGNATURE_USER_INFO(
        signature.nextSignaturePlan?.newPlanName || '',
      ),
      cancelText: 'Cancelar',
      confirmText: 'Confirmar alteração',
      actionFn: handleCancelNextSignature,
    };

    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleCancelNextSignature = async () => {
    try {
      if (!signature.nextSignaturePlan) {
        throw new Error(NEXT_SIGNATURE_NOT_EXISTS);
      }

      dispatch(disableButton());
      const response = await SignatureService.requestCancelChangePlan(
        signature.id,
      );
      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(FORCE_CANCEL_NEXT_SIGNATURE_SUCCESS));
        dispatch(closeModal());
        history.go(0);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  return (
    <Grid item xs={12} md={6}>
      <Grid className={styles.cardContainer} container item alignItems="center">
        <Grid container item justify="space-between" alignItems="center">
          <Grid item>
            <Typography
              className={
                smallSize ? styles.signatureCardMobileTitle : styles.cardTitle
              }
            >
              Sua assinatura atual
            </Typography>
          </Grid>
          <Grid item>
            <MyDropdown
              expandMoreIconStyle={styles.expandIcon}
              menuItems={[
                {
                  title: 'Atualizar cartão',
                  action: () => goToCreditCardPage(),
                },
                {
                  title: 'Cancelar assinatura e benefícios',
                  action: () => handleRequestCanceled(),
                },
              ]}
            />
          </Grid>
        </Grid>
        <Grid container item direction="column">
          <Typography className={styles.planName}>
            PLANO {plan.recurrence.name}
          </Typography>
          <Typography className={styles.planPrice}>
            {moneyFormat(
              getUnitPrice(
                plan.recurrence.months,
                signature.paymentType === PAYMENT_TYPE.BANKSLIP
                  ? PLAN_BANKSLIP_PRICE_CUSTOM
                  : PLAN_CARD_PRICE_CUSTOM,
              ),
            )}
            <span className={styles.planRecurrenceText}>/mês + Frete</span>
          </Typography>
        </Grid>

        <Grid
          container
          item
          justify={signature.nextSignaturePlan ? 'flex-start' : 'space-between'}
          alignItems="center"
          className={styles.renewGrid}
        >
          {!signature.nextSignaturePlan ? (
            <Typography className={styles.renewDateText}>
              Renova em &nbsp;
              {formatColumnValue(
                FormatOptions.SIMPLE_DATE_WITH_TRACE,
                signature.renewDate,
              )}
            </Typography>
          ) : null}

          {signature.nextSignaturePlan ? (
            <>
              <Button
                variant="contained"
                className={
                  smallSize
                    ? styles.upgradePlanButtonMobile
                    : styles.nextSignatureButton
                }
                disabled={true}
              >
                <Typography className={styles.nextSignatureText}>
                  Upgrade programado para{' '}
                  {formatColumnValue(
                    FormatOptions.DAY_AND_MONTH_WITH_TRACE,
                    signature.nextSignaturePlan.startDate,
                  )}
                </Typography>
              </Button>
              <Button
                onClick={() => handleOpenModalConfirmCanceNextSignature()}
                variant="text"
                color="primary"
                className={
                  smallSize
                    ? styles.cancelNextSignatureButtonMobile
                    : styles.cancelNextSignatureButton
                }
              >
                <Typography className={styles.nextSignatureText}>
                  Cancelar upgrade
                </Typography>
              </Button>
            </>
          ) : null}

          {!signature.nextSignaturePlan &&
          plan.recurrence.months !== RECURRENCES_MONTHS.YEARLY ? (
            <Button
              variant="contained"
              className={
                smallSize
                  ? styles.upgradePlanButtonMobile
                  : styles.upgradePlanButton
              }
              onClick={() => goToChangePlan()}
            >
              <Typography className={styles.upgradeButtonText}>
                Fazer upgrade
              </Typography>
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlanCardInfo;
