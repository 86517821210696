import { ColumnProps, FormatOptions } from 'types/TableTypes';
import { format as dateFormat } from 'date-fns';
import ptLocale from 'date-fns/locale/pt';
import {
  separateDateWithTrace,
  separateViewDateToStringDate,
} from './validateDate';

export const formatColumnValue = (
  format: ((v: any) => string) | FormatOptions,
  value: any,
) => {
  if (typeof format === 'function') {
    return format(value);
  }

  if (format === FormatOptions.MONEY) {
    return parseFloat(value || 0).toLocaleString('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    });
  }

  if (format === FormatOptions.CREDIT_CARD) {
    const formattedValue = value.replace(/\d(?=\d{4})/g, '*');
    return formattedValue
      .split('')
      .map((element: string, index: number) =>
        index !== 0 && index % 4 === 0 ? ' ' + element : element,
      )
      .join('');
  }

  if (format === FormatOptions.DATE) {
    return dateFormat(new Date(value), 'dd/MM/yyyy');
  }

  if (format === FormatOptions.MONTH_YEAR_DATE) {
    return dateFormat(new Date(value), 'MM/yyyy');
  }

  if (format === FormatOptions.SIMPLE_DATE) {
    return dateFormat(new Date(`${value} 00:00:00`), 'dd/MM/yyyy');
  }

  if (format === FormatOptions.SIMPLE_DATE_WITH_TRACE) {
    const formattedDate = separateDateWithTrace(value);
    return dateFormat(formattedDate, 'dd/MM/yyyy');
  }

  if (format === FormatOptions.IN_FULL_DATE) {
    const formattedDate = new Date(`${value}`);
    return dateFormat(formattedDate, 'MMMM/yyyy', {
      locale: ptLocale,
    });
  }

  if (format === FormatOptions.START_DATE) {
    const formattedDate = separateDateWithTrace(value);
    return dateFormat(formattedDate, 'MMMM/yyyy', {
      locale: ptLocale,
    });
  }

  if (format === FormatOptions.DATETIME) {
    return dateFormat(new Date(value), 'dd/MM/yyyy kk:mm:ss');
  }

  if (format === FormatOptions.TIME) {
    return dateFormat(new Date(value), 'kk:mm');
  }

  if (format === FormatOptions.ZIPCODE) {
    return `${value.substr(0, 5)}-${value.substr(5)}`;
  }

  // TODO: validate formatters to phone
  if (format === FormatOptions.PHONE) {
    return value.replace(/^\([0-9]{2}\)[0-9]{5}-[0-9]{4}$/);
  }

  if (format === FormatOptions.DAY_AND_MONTH_WITH_TRACE) {
    const formattedDate = separateDateWithTrace(value);
    return dateFormat(formattedDate, 'dd/MM');
  }

  if (format === FormatOptions.VIEW_DATE) {
    return separateViewDateToStringDate(value);
  }
};

export const formatDateWithNoTime = (date: string) =>
  new Date(`${date} 00:00:00`);

export const getColumnValue = (column: ColumnProps, item: any) => {
  const { getter, format } = column;

  if (!getter) {
    throw Error(`column getter attribute was not provided.`);
  }

  let value: any = '';

  if (typeof getter === 'string') {
    // Iterates over an object recursively
    // E.g: 'plan.recurrence.name' should return recurrence's name value
    const recursiveGetter: any = getter.split('.');
    if (recursiveGetter.length > 1) {
      const nextItem = recursiveGetter.splice(0, 1);
      value = getColumnValue(
        {
          ...column,
          getter: recursiveGetter.join('.'),
        },
        item[nextItem],
      );
    } else {
      value = item[getter];
    }
  }

  if (format) {
    return formatColumnValue(format, value);
  }

  return value;
};

export const getDueDateString = (data: {
  dueMonth: number;
  dueYear: number;
}) => {
  return data.dueMonth > 9
    ? `${data.dueMonth}/${data.dueYear}`
    : `0${data.dueMonth}/${data.dueYear}`;
};
