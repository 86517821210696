import { Reducer } from 'redux';
import { FileTypes, FileState } from './types';

const INITIAL_STATE: FileState = {
  isLoading: false,
  hasError: false,
};

const fileReducer: Reducer<FileState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FileTypes.LOAD_FILE_REQUEST:
      return {
        isLoading: true,
        hasError: false,
      };
    case FileTypes.LOAD_FILE_REQUEST_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
      };
    case FileTypes.LOAD_FILE_REQUEST_FAILURE:
      return {
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

export default fileReducer;
