import { call, put } from 'redux-saga/effects';
import ViewsService from 'services/viewsService';
import {
  loadViewUsersTurbineFailure,
  loadViewUsersTurbineSuccess,
} from './actions';

export function* loadViewUserTurbineSaga(action: any) {
  try {
    const { data, total } = yield call(
      ViewsService.getViewUserSignatureTurbine,
      action.payload.query,
    );
    yield put(loadViewUsersTurbineSuccess({ data, total }));
  } catch (error) {
    yield put(loadViewUsersTurbineFailure());
  }
}
