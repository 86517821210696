import React, { FC, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StatusCodes } from 'http-status-codes';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { DonationDialogProps } from '../dialogTypes';

import { closeModal } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';

import { getDefaultValues, validationSchema } from './utils';
import PasswordInput from 'components/generals/input/PasswordInput';
// import useRootStyles from 'styles/general';
import { useStyles } from './styles';
import MySelectForm from 'components/generals/input/MySelectForm';
import clsx from 'clsx';
import { DonationPayload } from 'types/generals';
import SignatureService from 'services/signatureService';
import { NEW_DONATION_ERROR, NEW_DONATION_SUCCESS } from 'utils/messages';
import { useHistory } from 'react-router-dom';
import MASK, { UNMASK } from 'helpers/masks';
import ReactInputMask from 'react-input-mask';
import OpenEndpointsService from 'services/openEndpointsService';
import { useCommonStyles } from 'styles/common';

const DonationDialog: FC<DonationDialogProps> = ({ title, plans = [] }) => {
  const inputLabelprops = { shrink: true };
  //fixme move to commons
  /// const rootStyles = useRootStyles();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { handleSubmit, errors, register, control, setValue } = useForm({
    validationSchema,
    defaultValues: getDefaultValues(),
  });

  const [disabled, setDisabled] = useState(false);
  const [loading, setLoading] = useState(false);

  const onSubmit = async (data: any) => {
    try {
      const payload: DonationPayload = {
        user: {
          name: data.name,
          email: data.email,
          repeatEmail: data.repeatEmail,
          identification: data.identification,
          phone: data.phone,
          password: data.password,
          repeatPassword: data.repeatPassword,
        },
        address: {
          zipcode: data.zipcode,
          street: data.street,
          number: data.number,
          neighborhood: data.neighborhood,
          city: data.city,
          state: data.state,
          complement: data.complement,
        },
        hashPlan: data.hashPlan,
      };
      setLoading(true);
      const response = await SignatureService.saveDonation(payload);

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(NEW_DONATION_SUCCESS));
      } else {
        throw new Error(NEW_DONATION_ERROR);
      }
      setLoading(false);
      history.go(0);
      dispatch(closeModal());
    } catch (error) {
      setLoading(false);
      dispatch(notifyError(error.message));
    }
  };

  const lookupAddress = async (zipcode: string) => {
    if (zipcode.length === 8) {
      setDisabled(true);
      try {
        const data = await OpenEndpointsService.userLookupAddress(zipcode);
        setValue('neighborhood', data.bairro);
        setValue('complement', data.complemento2);
        setValue('city', data.cidade);
        setValue('street', data.end);
        setValue('state', data.uf);
      } catch (error) {
        setValue('neighborhood', '');
        setValue('complement', '');
        setValue('city', '');
        setValue('street', '');
        setValue('state', '');
      } finally {
        setDisabled(false);
      }
      return zipcode;
    }
  };

  const handleZipcodeChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    lookupAddress(unmasked);
    return unmasked;
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography
            variant="h1"
            color="textPrimary"
            className={commonStyles.boldText}
          >
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={1}
          component="form"
          style={{ overflow: 'hidden', padding: '10px 30px' }}
        >
          <Typography
            variant="h4"
            color="textPrimary"
            className={styles.sectionTitle}
          >
            Informações para cadastro
          </Typography>
          <Grid item xs className={styles.singleRowPadding}>
            <MyOutlinedTextField
              name="name"
              label="Nome completo"
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid container direction="row" item xs spacing={1}>
            <Grid item xs={6}>
              <MyOutlinedTextField
                name="email"
                label="E-mail"
                fullWidth
                error={Boolean(errors.email)}
                helperText={errors.email ? errors.email.message : null}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <MyOutlinedTextField
                name="repeatEmail"
                label="Confirmar e-mail"
                fullWidth
                error={Boolean(errors.repeatEmail)}
                helperText={
                  errors.repeatEmail ? errors.repeatEmail.message : null
                }
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid container direction="row" item xs spacing={1}>
            <Grid item xs={6}>
              <Controller
                as={ReactInputMask}
                mask={MASK.CPF}
                name="identification"
                control={control}
                maskChar={null}
              >
                {() => (
                  <MyOutlinedTextField
                    name="identification"
                    fullWidth
                    label="CPF"
                    type="text"
                    inputProps={{
                      ref: register,
                      inputMode: 'numeric',
                    }}
                    error={Boolean(errors.identification)}
                    helperText={
                      errors.identification
                        ? errors.identification.message
                        : null
                    }
                  />
                )}
              </Controller>
            </Grid>
            <Grid item xs={6}>
              <Controller
                as={ReactInputMask}
                mask={MASK.CELLPHONE}
                name="phone"
                control={control}
                maskChar={null}
              >
                {() => (
                  <MyOutlinedTextField
                    name="phone"
                    fullWidth
                    label="Telefone"
                    type="text"
                    inputProps={{
                      ref: register,
                      inputMode: 'numeric',
                    }}
                    error={Boolean(errors.phone)}
                    helperText={errors.phone ? errors.phone.message : null}
                  />
                )}
              </Controller>
            </Grid>
          </Grid>
          <Typography
            variant="h4"
            color="textPrimary"
            className={styles.sectionTitle}
          >
            Segurança
          </Typography>
          <Grid container direction="row" item xs spacing={1}>
            <Grid item xs={6}>
              <PasswordInput
                fullWidth
                name="password"
                error={Boolean(errors.password)}
                helperText={errors?.password?.message || ''}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6}>
              <PasswordInput
                id="repeatPassword"
                inputLabel="Confirmar a senha"
                fullWidth
                name="repeatPassword"
                error={Boolean(errors.repeatPassword)}
                helperText={errors?.repeatPassword?.message || ''}
                inputRef={register}
              />
            </Grid>
          </Grid>

          <Typography
            variant="h4"
            color="textPrimary"
            className={styles.sectionTitle}
          >
            Endereço para cobrança
          </Typography>

          <Grid container direction="row" item xs spacing={1}>
            <Grid item xs={6}>
              <Controller
                as={ReactInputMask}
                mask={MASK.ZIPCODE}
                name="zipcode"
                control={control}
                maskChar={null}
                onChange={handleZipcodeChange}
              >
                {() => (
                  <MyOutlinedTextField
                    name="zipcode"
                    fullWidth
                    label="CEP"
                    type="text"
                    inputProps={{
                      ref: register,
                      inputMode: 'numeric',
                    }}
                    error={Boolean(errors.zipcode)}
                    helperText={errors.zipcode ? errors.zipcode.message : null}
                  />
                )}
              </Controller>
            </Grid>
            <Grid item xs={6}>
              <MyOutlinedTextField
                name="street"
                label="Endereço"
                fullWidth
                error={Boolean(errors.street)}
                helperText={errors.street ? errors.street.message : null}
                inputRef={register}
                InputLabelProps={inputLabelprops}
                disabled={disabled}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" item xs spacing={1}>
            <Grid item xs={3}>
              <MyOutlinedTextField
                name="number"
                label="Nº"
                fullWidth
                error={Boolean(errors.number)}
                helperText={errors.number ? errors.number.message : null}
                inputRef={register}
                InputLabelProps={inputLabelprops}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={4}>
              <MyOutlinedTextField
                name="neighborhood"
                label="Bairro"
                fullWidth
                error={Boolean(errors.neighborhood)}
                helperText={
                  errors.neighborhood ? errors.neighborhood.message : null
                }
                inputRef={register}
                InputLabelProps={inputLabelprops}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={5}>
              <MyOutlinedTextField
                name="complement"
                label="Complemento"
                fullWidth
                error={Boolean(errors.complement)}
                helperText={
                  errors.complement ? errors.complement.message : null
                }
                inputRef={register}
              />
            </Grid>
          </Grid>

          <Grid container direction="row" item xs spacing={1}>
            <Grid item xs={8}>
              <MyOutlinedTextField
                name="city"
                label="Cidade"
                fullWidth
                error={Boolean(errors.city)}
                helperText={errors.city ? errors.city.message : null}
                inputRef={register}
                InputLabelProps={inputLabelprops}
                disabled={disabled}
              />
            </Grid>
            <Grid item xs={4}>
              <MyOutlinedTextField
                name="state"
                label="Estado"
                fullWidth
                error={Boolean(errors.state)}
                helperText={errors.state ? errors.state.message : null}
                inputRef={register}
                InputLabelProps={inputLabelprops}
                disabled={disabled}
              />
            </Grid>
          </Grid>
          <Typography
            variant="h4"
            color="textPrimary"
            className={styles.sectionTitle}
          >
            Tempo de assinatura
          </Typography>
          <Grid item xs className={styles.singleRowPadding}>
            <Controller
              name="hashPlan"
              control={control}
              as={({ onChange, onBlur, value }) => (
                <MySelectForm
                  title="Plano"
                  contracted
                  itens={plans.map(plan => ({
                    title: plan.name,
                    value: plan.hash,
                  }))}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={Boolean(errors.hashPlan)}
                  helperText={null}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          item
          spacing={2}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Button
            color="primary"
            className={commonStyles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => dispatch(closeModal())}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${commonStyles.buttonLabel} ${commonStyles.buttonWrapper}`}
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            {loading ? (
              <>
                {'Salvando'}
                <CircularProgress className={commonStyles.progress} size={15} />
              </>
            ) : (
              'Salvar'
            )}
          </Button>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default DonationDialog;
