import React, { useEffect } from 'react';
import { Typography, CircularProgress, Box, Backdrop } from '@material-ui/core';
import { useStyles } from './styles';

const CircularLoading: React.FC<any> = (props: {
  isLoading: boolean;
  title?: string;
}) => {
  const { isLoading, title } = props;
  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  useEffect(() => {
    setOpen(isLoading);
  }, [isLoading]);

  return (
    <Backdrop className={classes.loader} open={open}>
      <Box position="relative" display="inline-flex">
        <CircularProgress size={140} color="primary" />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="h5"
            component="div"
            className={classes.typography}
          >
            {title || 'Carregando'}
          </Typography>
        </Box>
      </Box>
    </Backdrop>
  );
};

export default CircularLoading;
