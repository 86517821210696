import React from 'react';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: any) => void;
  name: string;
}

const MyNumberFormat = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={values => {
        onChange(values.value);
      }}
      decimalSeparator=","
      decimalScale={2}
      isNumericString
      prefix="R$"
    />
  );
};

export default MyNumberFormat;
