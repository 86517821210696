import React, {
  FC,
  ChangeEvent,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { UserNotificationConfigProps } from './types';
import MySwitch from 'components/generals/input/MySwitch';

import { Grid, Typography, Paper, FormControlLabel } from '@material-ui/core';
import { useCommonStyles } from 'styles/common';
import NotificationService from 'services/notificationService';
import { NOTIFICATION_GROUP } from 'types/generals/notification';
import { useDispatch, useSelector } from 'react-redux';
import { loadNotifications } from 'store/ducks/store/storeNotification/actions';
import { AppState } from 'store';
import { StoreNotificationState } from 'store/ducks/store/storeNotification/types';

const UserNotificationConfig: FC<UserNotificationConfigProps> = ({
  setNotificationConfigId,
  register,
  user,
}) => {
  const dispatch = useDispatch();
  const styles = useCommonStyles();
  const [isLogistics, setIsLogistics] = useState(true);
  const [isPaymentSignature, setIsPaymentSignature] = useState(true);
  const [isPaymentStore, setIsPaymentStore] = useState(true);
  const [isSchedule, setIsSchedule] = useState(true);

  const { data: storeNotifications } = useSelector<
    AppState,
    StoreNotificationState
  >(({ storeNotification }: AppState) => storeNotification);

  const getNotifications = useCallback(async () => {
    dispatch(
      loadNotifications({
        sort: [
          {
            field: 'id',
            order: 'ASC',
          },
        ],
      }),
    );
  }, [dispatch]);

  const loadUserConfig = useCallback(async () => {
    const data = await NotificationService.getUserNotificationConfig(user.id);
    if (data) {
      setIsLogistics(data.groups.includes(NOTIFICATION_GROUP.LOGISTICS));
      setIsPaymentStore(data.groups.includes(NOTIFICATION_GROUP.PAYMENT_STORE));
      setIsPaymentSignature(
        data.groups.includes(NOTIFICATION_GROUP.PAYMENT_SIGNATURE),
      );
      setIsSchedule(data.groups.includes(NOTIFICATION_GROUP.SCHEDULE));
      setNotificationConfigId(data.id);
    }
  }, [setNotificationConfigId, user.id]);

  useEffect(() => {
    loadUserConfig();
  }, [loadUserConfig, user.id]);

  useEffect(() => {
    getNotifications();
  }, [dispatch, getNotifications]);

  return (
    <>
      {storeNotifications?.length ? (
        <Paper elevation={0} className={styles.rootPaper}>
          <Grid container item spacing={1} direction="column">
            <Grid item xs>
              <Typography
                variant="h1"
                color="textPrimary"
                style={{ fontWeight: 'normal' }}
              >
                Receber <strong>notificações</strong>
              </Typography>
            </Grid>
            <Grid container item xs>
              <Grid item xs={12}>
                <FormControlLabel
                  name="isLogistics"
                  control={
                    <MySwitch
                      name="isLogistics"
                      color="primary"
                      checked={isLogistics}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setIsLogistics(e.target.checked)
                      }
                      inputRef={register}
                    />
                  }
                  label="Notificações de logística"
                  labelPlacement="start"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  name="isPaymentStore"
                  control={
                    <MySwitch
                      name="isPaymentStore"
                      color="primary"
                      checked={isPaymentStore}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setIsPaymentStore(e.target.checked)
                      }
                      inputRef={register}
                    />
                  }
                  label="Notificações de pagamento da Loja"
                  labelPlacement="start"
                />
              </Grid>

              <Grid item xs={12}>
                <FormControlLabel
                  name="isPaymentSignature"
                  control={
                    <MySwitch
                      name="isPaymentSignature"
                      color="primary"
                      checked={isPaymentSignature}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setIsPaymentSignature(e.target.checked)
                      }
                      inputRef={register}
                    />
                  }
                  label="Notificações de pagamento da Assinatura"
                  labelPlacement="start"
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  name="isSchedule"
                  control={
                    <MySwitch
                      name="isSchedule"
                      color="primary"
                      checked={isSchedule}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setIsSchedule(e.target.checked)
                      }
                      inputRef={register}
                    />
                  }
                  label="Notificações recorrentes"
                  labelPlacement="start"
                />
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      ) : null}
    </>
  );
};

export default UserNotificationConfig;
