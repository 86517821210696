import React, { useCallback } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import MyTableCell from 'components/generals/table/MyTableCell';
import MyTooltip from 'components/generals/tolltip';

import { FormatOptions } from 'types';
import { formatColumnValue } from 'helpers/formatters';
import { useStyles } from '../useStyles';
import { IShippingLogs } from 'types/generals';

interface ShippingLogsProps {
  logs: IShippingLogs[];
}

const ShippingLogs: React.FC<ShippingLogsProps> = ({ logs }) => {
  const styles = useStyles();

  const handleFormatDateTime = useCallback((date: string) => {
    return formatColumnValue(FormatOptions.DATETIME, date);
  }, []);

  return (
    <>
      <Grid item container alignItems="center" spacing={3}>
        <Grid item>
          <Typography
            variant="h2"
            color="textPrimary"
            className={styles.boldText}
          >
            Logs
          </Typography>
        </Grid>
      </Grid>
      <Grid className={styles.mTop}>
        <Grid container direction="column">
          <Paper className={styles.paperTable} elevation={0}>
            <TableContainer>
              <Table className={styles.table} aria-label="filesLabel">
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Descrição</TableCell>
                    <TableCell>Usuário</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {logs.length ? (
                    logs.map((log, index: number) => (
                      <TableRow key={index}>
                        <TableCell>
                          {handleFormatDateTime(
                            log.createdAt?.toString() || '',
                          )}
                        </TableCell>
                        <MyTooltip
                          title={log.message || '-'}
                          enterDelay={500}
                          placement="top"
                        >
                          <TableCell>{log.message || '-'}</TableCell>
                        </MyTooltip>
                        <TableCell>{log.requester || '-'}</TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <MyTableCell colSpan={3} align="center">
                        Nenhuma log registrado até o momento
                      </MyTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default ShippingLogs;
