import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  changePlanText: {
    maxWidth: 190,
    fontWeight: 'bold',
  },
  changePlanButton: {
    height: 48,
    fontSize: 16,
    fontWeight: 'bold',
    color: Colors.DARK_GRAY,
    '&:hover': {
      border: '1px solid',
      borderColor: Colors.ORANGE,
      color: Colors.ORANGE,
    },
  },
  changePlanButtonActive: {
    height: 48,
    fontSize: 16,
    backgroundColor: Colors.WHITE_GREEN,
    border: '1px solid ',
    borderColor: Colors.BRIGHT_DARK_GREEN,
    '&:hover': {
      backgroundColor: Colors.WHITE_GREEN,
    },
  },
  changePLanButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  changePLanButtonTextActive: {
    fontSize: 16,
    fontWeight: 'bold',
    color: Colors.BRIGHT_DARK_GREEN,
  },
  boldText: {
    fontWeight: 'bold',
  },
  planGrid: {
    border: '1px solid',
    borderColor: Colors.LIGHT_GRAY,
    borderRadius: 10,
    // marginTop: 20,
  },
  planGridContainer: {
    padding: 30,
  },
  planNameText: {
    fontWeight: 'bold',
    color: Colors.ORANGE,
  },
  planPriceText: {
    fontSize: 20,
    fontWeight: 'bold',
  },
  planRecurrenceText: {
    fontSize: 15,
    color: Colors.MEDIUM_GRAY,
    fontWeight: 'bold',
  },
  list: {
    backgroundColor: 'transparent',
    margin: 0,
    padding: 0,
  },
  listItem: {
    padding: 0,
  },
  listItemIcon: {
    minWidth: 20,
  },
  continueButton: {
    width: 160,
    height: 45,
    backgroundColor: Colors.BRIGHT_DARK_GREEN,
    marginTop: 10,
    '&:hover': {
      backgroundColor: Colors.BRIGHT_DARK_GREEN,
    },
  },
  continueButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: Colors.WHITE,
  },
  infoCard: {
    fontSize: 13,
  },
  checkIcon: {
    color: Colors.BRIGHT_DARK_GREEN,
  },
  cancelIcon: {
    color: Colors.LIGHT_GRAY,
  },
  listTextChecked: {
    fontSize: 12,
    fontWeight: 500,
  },
  listTextClose: {
    fontSize: 12,
    fontWeight: 500,
    color: Colors.MEDIUM_GRAY,
  },
  benefitImage: {
    height: 22,
    width: 100,
  },
});
