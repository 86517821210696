import React, { useState } from 'react';
import { Grid } from '@material-ui/core';

import Menu from './Menu';

import { ReactComponent as MenuButton } from 'assets/img/svg/menu-icons/menu_header_button.svg';

import { Wrapper, Logo } from './styles';

import { HeaderPropsType } from './types';

const Header: React.FC<HeaderPropsType> = ({ data }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  return (
    <Grid item>
      <Wrapper container justify="space-between" alignItems="center">
        <Logo />
        <MenuButton onClick={() => setIsMenuOpen(true)} />
        <Menu open={isMenuOpen} setOpen={setIsMenuOpen} data={data} />
      </Wrapper>
    </Grid>
  );
};

export default Header;
