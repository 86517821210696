import React, { useState, useEffect } from 'react';
import {
  Grid,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';

import ImagesZone from 'components/admin/ImagesZone';
import { ImageZone } from 'components/admin/ImagesZone/types';
import MyOutlinedTextField from '../input/MyOutlinedTextField';
import MySelectForm from '../input/MySelectForm';

import { AppState } from 'store';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import { RecurrenceState } from 'store/ducks/recurrences/types';
import {
  notifySuccess,
  notifyError,
  enableButton,
  disableButton,
} from 'store/ducks/notification/actions';

import BenefitsService from 'services/benefitsService';

import { benefitsValidationSchema } from './utils';
import { useStylesBenefitsDialog } from './useStyles';
import { BenefitsDialogProps, BaseDialogProps } from './dialogTypes';
import { BenefitsPayload } from './types';
import { Benefits } from 'types/generals/benefits';
import ConfirmationDialog from './ConfirmationDialog';
import {
  CREATE_BENEFIT_SUCCESS,
  CREATE_BENEFIT_ERROR,
  REMOVE_BENEFIT_SUCCESS,
  REMOVE_BENEFIT_ERROR,
  REMOVE_BENEFIT_ASK,
  UPDATE_BENEFIT_SUCCESS,
  UPDATE_BENEFIT_ERROR,
} from 'utils/messages';
import { loadBenefits } from 'store/ducks/benefits/action';

const BenefitsFormDialog: React.FC<BenefitsDialogProps> = ({ benefit }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const style = useStylesBenefitsDialog();
  const [files, setFiles] = useState<ImageZone[]>([]);
  const [recurrenceIds, setRecurrenceIds] = useState<any>([]);
  const [disableModalButton, setDisableModalButton] = useState(false);

  const {
    handleSubmit,
    setValue,
    errors,
    control,
    register,
    reset,
    watch,
  } = useForm({
    validationSchema: benefitsValidationSchema,
  });

  const { data: recurrences, isLoading } = useSelector<
    AppState,
    RecurrenceState
  >(({ recurrences }: AppState) => recurrences);

  useEffect(() => {
    if (benefit) {
      const benefitRecurrences = benefit.recurrences.map(r => r.id);

      setRecurrenceIds(benefitRecurrences);
      if (benefit.image) {
        setFiles([{ image: benefit.image, isCover: false }]);
      }
      reset({ ...benefit, recurrenceIds: benefitRecurrences });
    }
  }, [benefit, reset, setValue, setRecurrenceIds]);

  const handleCloseDialog = () => {
    reset();
    dispatch(
      loadBenefits({
        sort: { field: 'priority', order: 'ASC' },
        join: ['recurrences'],
      }),
    );
    dispatch(closeModal());
  };

  const handleChangeRecurrences = (event: any) => {
    const value = event?.target?.value;
    setRecurrenceIds(value);
    setValue('recurrenceIds', value);
  };

  const onSubmit = (data: BenefitsPayload) => {
    if (benefit) {
      updateBenefit(benefit.id, data);
    } else {
      createBenefit(data);
    }
  };

  const updateBenefit = async (id: number, data: BenefitsPayload) => {
    try {
      setDisableModalButton(true);
      const image = watch('images');
      const response = await BenefitsService.updateBenefit(id, data, image);

      if (response.status === StatusCodes.OK) {
        handleCloseDialog();
        dispatch(notifySuccess(UPDATE_BENEFIT_SUCCESS));
      } else {
        throw new Error(UPDATE_BENEFIT_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      setDisableModalButton(false);
    }
  };

  const createBenefit = async (data: BenefitsPayload) => {
    try {
      setDisableModalButton(true);
      const image = watch('images');
      const response = await BenefitsService.createBenefit(data, image);

      if (response.status === StatusCodes.CREATED) {
        handleCloseDialog();
        dispatch(notifySuccess(CREATE_BENEFIT_SUCCESS));
      } else {
        throw new Error(CREATE_BENEFIT_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      setDisableModalButton(false);
    }
  };

  const deleteBenefit = async (id: number) => {
    try {
      dispatch(disableButton());
      const response = await BenefitsService.deleteBenefit(id);
      if (response.status === StatusCodes.OK) {
        handleCloseDialog();
        dispatch(notifySuccess(REMOVE_BENEFIT_SUCCESS));
      } else {
        throw new Error(REMOVE_BENEFIT_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleOnpenDeleteBenefitDialog = (benefit: Benefits) => {
    const modalProps: BaseDialogProps = {
      title: 'Excluindo benefício',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: REMOVE_BENEFIT_ASK(benefit.title),
      actionFn: () => deleteBenefit(benefit.id),
    };

    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  return (
    <>
      {!isLoading && recurrences.length ? (
        <Grid container direction="column">
          <DialogTitle>
            <Grid container justify="space-between" alignItems="center">
              <Typography variant="h1">
                {benefit ? 'Atualizar benefício' : 'Adicionar benefício'}
              </Typography>
              <IconButton onClick={() => dispatch(closeModal())}>
                <CloseIcon fontSize="large" />
              </IconButton>
            </Grid>
          </DialogTitle>

          <DialogContent>
            <Grid container direction="column">
              <Grid item xs>
                <ImagesZone
                  showTitle={false}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  multiple={false}
                  files={files}
                  maxFiles={1}
                />
              </Grid>

              <Grid item xs className={style.gridMargin}>
                <MyOutlinedTextField
                  id="titleId"
                  name="title"
                  label="Título"
                  fullWidth
                  error={Boolean(errors.title)}
                  helperText={errors.title ? errors.title.message : null}
                  inputRef={register}
                />

                <Grid item xs className={style.gridMargin}>
                  <MyOutlinedTextField
                    id="plan-description"
                    name="description"
                    label="Descrição"
                    multiline
                    fullWidth
                    rows={3}
                    inputRef={register}
                    error={Boolean(errors.description)}
                    helperText={
                      errors.description ? errors.description.message : null
                    }
                  />
                </Grid>

                <Grid item xs className={style.gridMargin}>
                  <Controller
                    name="recurrenceIds"
                    control={control}
                    defaultValue=""
                    as={({ onBlur, onChange }) => (
                      <MySelectForm
                        multiple={true}
                        contracted
                        title="Recorrência da cobrança"
                        itens={recurrences.map(recurrence => ({
                          title: recurrence.name,
                          value: recurrence.id,
                        }))}
                        onChange={(e: any) => {
                          handleChangeRecurrences(e);
                          onChange(e.target.value);
                        }}
                        onBlur={onBlur}
                        value={recurrenceIds}
                        error={Boolean(errors.recurrenceIds)}
                        helperText={
                          errors.recurrenceIds
                            ? errors.recurrenceIds.message
                            : null
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs className={style.gridMargin}>
                  <MyOutlinedTextField
                    id="priorityId"
                    name="priority"
                    label="Prioridade"
                    type="number"
                    inputRef={register}
                    fullWidth
                    error={Boolean(errors.priority)}
                    helperText={
                      errors.priority ? errors.priority.message : null
                    }
                  />
                </Grid>
              </Grid>
            </Grid>

            <Grid
              container
              item
              justify={benefit ? 'space-between' : 'flex-end'}
            >
              {benefit ? (
                <Button
                  variant="text"
                  className={clsx(style.buttonLabel)}
                  color="primary"
                  onClick={() => handleOnpenDeleteBenefitDialog(benefit)}
                  disabled={disableModalButton}
                >
                  Excluir benefício
                </Button>
              ) : null}
              <Button
                className={clsx(style.buttonLabel, style.buttonWrapper)}
                variant="contained"
                color="primary"
                disabled={disableModalButton}
                onClick={handleSubmit(onSubmit as any)}
              >
                {benefit ? 'Atualizar' : 'Adicionar'}
              </Button>
            </Grid>
          </DialogContent>
        </Grid>
      ) : null}
    </>
  );
};

export default BenefitsFormDialog;
