import * as Yup from 'yup';
import OpenEndpointsService from 'services/openEndpointsService';
import { verifyValidateCard } from 'helpers/creditCards';
import { CREDIT_CARD_MAX_LENGTH_NAME } from 'utils/messages';
import {
  identification,
  cardNumber,
  cardName,
  dueMonth,
  dueYear,
  securityCode,
} from 'utils/formYupFields';

const email_regex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validationSchema = Yup.object({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .trim()
    .lowercase()
    .email('E-mail inválido')
    .required('E-mail obrigatório')
    .test('email-validate', 'E-mail já cadastrado', function (value) {
      return (
        value &&
        new Promise<boolean>(async (resolve, reject) => {
          try {
            await OpenEndpointsService.validateEmail(value);
            resolve(true);
          } catch (error) {
            reject(this.createError());
          }
        })
      );
    })
    .matches(
      email_regex,
      'Email inválido, digite um email válido para continuar.',
    ),
  repeatEmail: Yup.string()
    .trim()
    .lowercase()
    .required('Confirme o email')
    .test('email-match', 'Os emails devem ser iguais', function (value) {
      return this.parent.email === value;
    }),
  identification,
  phone: Yup.string().required('Telefone obrigatório'),
  password: Yup.string().required('Senha obrigatória'),
  repeatPassword: Yup.string()
    .required('Confirme a senha')
    .test('password-match', 'As senhas devem ser iguais', function (value) {
      return this.parent.password === value;
    }),
  formStep: Yup.number().required(),
  zipcode: Yup.string().when('formStep', {
    is: 2,
    then: (rule: any) => rule.required('CEP obrigatório'),
  }),
  street: Yup.string()
    .min(2, 'Endereço esta incompleto')
    .when('formStep', {
      is: 2,
      then: (rule: any) => rule.required('Rua obrigatória'),
    }),
  number: Yup.string().when('formStep', {
    is: 2,
    then: (rule: any) => rule.required('Número obrigatório'),
  }),
  neighborhood: Yup.string()
    .min(2, 'Nome da bairro esta incompleto')
    .when('formStep', {
      is: 2,
      then: (rule: any) => rule.required('Bairro obrigatório'),
    }),
  city: Yup.string().when('formStep', {
    is: 2,
    then: (rule: any) => rule.required('Cidade obrigatória'),
  }),
  state: Yup.string()
    .when('formStep', {
      is: 2,
      then: (rule: any) => rule.required('Estado obrigatório'),
    })
    .max(2, 'Só é permitido 2 dígitos, ex: RS'),
  complement: Yup.string().max(30, 'Limite de caracteres é de 30'),
  coupon: Yup.string(),
  couponId: Yup.number(),
  referralCode: Yup.string(),
  // Card validation
  paymentType: Yup.string().required(),
  ccNumber: Yup.string().when(['paymentType', 'formStep'], {
    is: (paymentType, formStep) => paymentType === 'cartao' && formStep === 2,
    then: cardNumber,
  }),
  cardName: Yup.string().when(['paymentType', 'formStep'], {
    is: (paymentType, formStep) => paymentType === 'cartao' && formStep === 2,
    then: cardName,
  }),
  ccBrand: Yup.string(),
  dueMonth: Yup.string().when(['paymentType', 'formStep'], {
    is: (paymentType, formStep) => paymentType === 'cartao' && formStep === 2,
    then: dueMonth,
  }),
  dueYear: Yup.string().when(['paymentType', 'formStep'], {
    is: (paymentType, formStep) => paymentType === 'cartao' && formStep === 2,
    then: dueYear,
  }),
  cvv: Yup.string().when(['paymentType', 'formStep'], {
    is: (paymentType, formStep) => paymentType === 'cartao' && formStep === 2,
    then: securityCode,
  }),
});
