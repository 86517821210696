import * as Yup from 'yup';
import { Coupon } from 'types/generals/coupon';

export const validationSchema = Yup.object().shape({
  coupon: Yup.string(),
});

export const defaultValues = (coupon: Coupon) => {
  return {
    coupon: coupon.name,
    scorePoints: 0,
  };
};
