import { call, put } from 'redux-saga/effects';
import FileService from 'services/fileservice';
import { orderBy } from 'lodash';

import PlanService from 'services/planService';
import { createAndDownloadFile } from 'utils/functions/generals';
import { loadFile, loadFileFailure, loadFileSuccess } from '../file/actions';
import { loadAllPlansFailure, loadAllPlansSuccess } from './actions';

export function* loadPlansListSaga(action: any) {
  try {
    const { data, total } = yield call(PlanService.getAllPlans, action.payload);
    yield put(loadAllPlansSuccess({ data, total }));
  } catch (err) {
    yield put(loadAllPlansFailure());
  }
}

export function* loadPlansFileSaga(action: any) {
  try {
    yield put(loadFile());
    const file = yield call(
      FileService.getFile,
      action.payload.query,
      action.payload.rootPath,
    );
    if (file?.response?.status) {
      yield put(loadFileFailure());
    } else {
      yield put(loadFileSuccess());
      createAndDownloadFile(file, 'Planos.xls');
    }
  } catch (error) {
    yield put(loadFileFailure());
  }
}

export function* loadPlansToChangeSaga(action: any) {
  try {
    const { data, total } = yield call(PlanService.getAllPlans, action.payload);

    const availablePlans = orderBy(
      data.filter((plan: any) => plan.pages.includes(action.payload)),
      'recurrence.months',
      'asc',
    );

    yield put(loadAllPlansSuccess({ data: availablePlans, total }));
  } catch (err) {
    yield put(loadAllPlansFailure());
  }
}
