import React from 'react';
import { Grid, Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { AddressCardProps } from './types';
import { useStyles } from './styles';

const AddressCard: React.FC<AddressCardProps> = ({ address }) => {
  const history = useHistory();
  const styles = useStyles();

  const goToAddress = () => {
    history.push('/my-account/profile');
  };

  return (
    <>
      <Grid item xs={12} md={6}>
        <Grid
          className={styles.cardContainer}
          container
          item
          alignItems="center"
        >
          {address ? (
            <>
              <Grid container item justify="space-between" alignItems="center">
                <Typography className={styles.cardTitle}>Endereço</Typography>
              </Grid>
              <Grid container item direction="column">
                <Typography className={styles.addressText}>
                  {address.street}, {address.number}
                </Typography>
                <Typography className={styles.addressText}>
                  {address.neighborhood}
                </Typography>
                <Typography className={styles.addressText}>
                  {address.city} - {address.state}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => goToAddress()}
                  variant="text"
                  className={styles.addressUpdateButton}
                >
                  <Typography className={styles.addressUpdateText}>
                    Atualizar endereço
                  </Typography>
                </Button>
              </Grid>
            </>
          ) : (
            <>
              <Typography variant="subtitle1">
                Você não possui um endereço cadastrado.
              </Typography>
              <Button onClick={() => goToAddress()} variant="text">
                <Typography className={styles.addressUpdateText}>
                  Adicionar um endereço
                </Typography>
              </Button>
            </>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default AddressCard;
