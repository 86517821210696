import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, GetProps, getWithSaga } from '../apiService';
import FileSaver from 'file-saver';

const SIGEP_PLP_DOMAIN = 'sigep-plp';

export default class PlpService {
  static async getAll(queryParams: CreateQueryParams): Promise<GetProps> {
    try {
      const data = await getWithSaga(SIGEP_PLP_DOMAIN, queryParams);
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async getOnePLP(plpId: number): Promise<GetProps> {
    try {
      const { data } = await api.get(`${SIGEP_PLP_DOMAIN}/${plpId}`);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async createPLP(name: string, invoiceIds: number[]) {
    try {
      const { data } = await api.post(SIGEP_PLP_DOMAIN, {
        invoiceIds,
        name,
      });
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async updatePLP(plpId: number, invoiceIds: number[]) {
    try {
      const data = await api.patch(`${SIGEP_PLP_DOMAIN}/${plpId}`, {
        invoiceIds,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  static async addMultiOrderInPLP(plpId: number, invoiceIds: number[]) {
    try {
      const data = await api.post(`${SIGEP_PLP_DOMAIN}/${plpId}/add-order`, {
        invoiceIds,
      });
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async addOrderInPLP(plpId: number, orderId: number) {
    try {
      const data = await api.post(
        `${SIGEP_PLP_DOMAIN}/${plpId}/add-order/${orderId}`,
        {},
      );
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async deletePLP(plpId: number): Promise<GetProps> {
    try {
      const { data } = await api.delete(`${SIGEP_PLP_DOMAIN}/${plpId}`);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async closePLP(plpId: number) {
    try {
      const { data } = await api.post(
        `${SIGEP_PLP_DOMAIN}/${plpId}/close-plp`,
        {},
      );
      return data;
    } catch (err) {
      return err;
    }
  }

  static async removeMultiOrderFromPLP(plpId: number) {
    try {
      const orderDeleted = await api.delete(
        `${SIGEP_PLP_DOMAIN}/${plpId}/orders`,
      );
      return orderDeleted;
    } catch (err) {
      return err;
    }
  }

  static async removeOrderFromPLP(plpId: number, orderId: number) {
    try {
      const orderDeleted = await api.delete(
        `${SIGEP_PLP_DOMAIN}/${plpId}/orders/${orderId}`,
      );
      return orderDeleted;
    } catch (err) {
      return err;
    }
  }

  static async exportXml(plpId: number, boxWeight: number) {
    try {
      const { data } = await api.post(
        `${SIGEP_PLP_DOMAIN}/${plpId}/export-xml/${boxWeight}`,
        {},
        {
          responseType: 'blob',
        },
      );
      FileSaver.saveAs(data, 'Remessa.xml');
      return data;
    } catch (err) {
      return err;
    }
  }

  static async printLabels(plpId: number, boxWeight: number) {
    try {
      // TODO: Apos refatorar o PDF vai passar para o SIGEP Module
      const { data } = await api.post(
        `invoices/${plpId}/print_labels/${boxWeight}`,
        {},
        {
          responseType: 'blob',
        },
      );
      FileSaver.saveAs(data, 'Etiquetas.pdf');
      return data;
    } catch (error) {
      return error;
    }
  }
}
