import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import { useHistory } from 'react-router-dom';
import {
  Button,
  FormControlLabel,
  Grid,
  InputAdornment,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import InputMask from 'react-input-mask';

import { ReactComponent as CardIcon } from 'assets/img/svg/icon_card.svg';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import MySwitch from 'components/generals/input/MySwitch';

import { notifyError, notifySuccess } from 'store/ducks/notification/actions';

import MASK, { UNMASK } from 'helpers/masks';
import { discoverCardBrand } from 'helpers/creditCards';

import { useStyles } from './styles';
import { validationSchema } from './util';
import { CreditCard } from 'types/CreditCard';
import CreditCardService from 'services/creditCardService';
import theme from 'styles/theme';
import {
  ADD_NEW_CARD_ERROR,
  ADD_NEW_CARD_SUCCESS,
  ORDER_PAYMENT_SUCCESS,
  DUE_DATE_ERROR,
} from 'utils/messages';
import { separateDate } from 'helpers/validateDate';
import CircularLoading from 'components/generals/loading/CircularLoading';
import SignatureService from 'services/signatureService';
import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { head } from 'lodash';
import { Order } from 'types/generals';
import { openModal } from 'store/ducks/nav/actions';
import FeedbackWithLinkDialog from 'components/generals/dialog/FeedBackWithLinkDialog';
import { FeedbackDialogPropsBase } from 'components/generals/dialog/dialogTypes';
import { HomeState } from 'store/ducks/client/home/types';
import MonthYearSelector from 'components/generals/MonthYearSelector';
import { compareAsc } from 'date-fns';
import { getDueDateString } from 'helpers/formatters';

const NewCard: FC = () => {
  const style = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [isDefault, setIsDefault] = useState<boolean>(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const mobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  const { handleSubmit, control, register, reset, errors, setValue } = useForm({
    validationSchema,
  });

  const { data: signature } = useSelector<AppState, SingleSignatureState>(
    ({ oneSignature }: AppState) => oneSignature,
  );

  const { currentBox } = useSelector<AppState, HomeState>(
    ({ home }: AppState) => home,
  );

  const submitNewCard = async (card: CreditCard) => {
    setIsLoading(true);
    const response = await CreditCardService.addNewCreditCard(card);
    setIsLoading(false);
    if (response?.status === StatusCodes.CREATED) {
      dispatch(notifySuccess(ADD_NEW_CARD_SUCCESS));
      // Em situações que o assinante tenha pedido em aberto ou com pagamento
      // recusado, logo apos ele criar um novo cartão e marcar ele como
      // principal um pagamento vai ser executado sobre o pedido em questão.
      if (card.isDefault) {
        const orders = await SignatureService.getOrdersWithoutPaymentBySignature(
          signature.id,
        );

        if (orders.status === StatusCodes.OK && orders.data.length) {
          handleFeedbackModal(
            'Aviso',
            ORDER_PAYMENT_SUCCESS(currentBox?.name || 'do mês'),
          );
          const order = head(orders.data) as Order;
          await SignatureService.hasOrderToPayment(signature.id, order?.id);
        }
      }
    } else if (response.status === StatusCodes.BAD_REQUEST) {
      dispatch(notifyError(response.data.message));
    } else {
      dispatch(notifyError(ADD_NEW_CARD_ERROR));
    }
    redirectCardsList();
  };

  const handleFormSubmit = (data: any) => {
    const cardData: CreditCard = {
      number: data.cardNumber.toString().replace(/ /g, ''),
      cardName: data.cardName,
      dueDate: getDueDateString(data),
      brand: discoverCardBrand(data.cardNumber.toString()),
      cvv: data.securityCode,
      isDefault,
    };
    const dueDate = new Date(`${data.dueYear}/${data.dueMonth}/01 00:00:00`);
    const validateDueDate = compareAsc(dueDate, new Date());
    if (validateDueDate < 0) {
      dispatch(notifyError(DUE_DATE_ERROR));
      return;
    }

    submitNewCard(cardData);
  };

  const redirectCardsList = () => {
    reset();
    history.push('/my-account/cards');
  };

  const handleFeedbackModal = (title: string, message: string) => {
    const props: FeedbackDialogPropsBase = {
      title,
      message: message,
    };
    dispatch(openModal(FeedbackWithLinkDialog, props));
  };

  return (
    <Grid container className={style.rootPanel}>
      <Grid item xs={12}>
        <Paper className={style.rootPaper} elevation={0}>
          <Typography
            variant="h1"
            color="textPrimary"
            style={{ fontWeight: 'normal' }}
          >
            Novo <strong>cartão</strong>
          </Typography>
          <Grid className={style.rootCenter}>
            <form onSubmit={handleSubmit(handleFormSubmit)}>
              <Grid
                container
                direction="column"
                spacing={3}
                justify="space-between"
                className={style.mTop}
              >
                <Grid item>
                  <Controller
                    as={ReactInputMask}
                    mask={MASK.CREDIT_CARD}
                    control={control}
                    name="cardNumberController"
                    maskChar={null}
                    onChange={([event]: any) =>
                      UNMASK.onlyDigits(event.target.value)
                    }
                  >
                    {() => (
                      <MyOutlinedTextField
                        fullWidth
                        label="Número do cartão"
                        type="text"
                        name="cardNumber"
                        inputRef={register}
                        inputProps={{
                          inputMode: 'numeric',
                        }}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <CardIcon />
                            </InputAdornment>
                          ),
                        }}
                        error={Boolean(errors.cardNumber)}
                        helperText={
                          errors.cardNumber ? errors.cardNumber.message : null
                        }
                      />
                    )}
                  </Controller>
                </Grid>
                <Grid item>
                  <MyOutlinedTextField
                    fullWidth
                    label="Nome impresso no cartão"
                    name="cardName"
                    type="text"
                    inputRef={register}
                    error={Boolean(errors.cardName)}
                    helperText={
                      errors.cardName ? errors.cardName.message : null
                    }
                    inputProps={{
                      maxLength: 25,
                    }}
                  />
                </Grid>
                <Grid container item direction="row" spacing={3}>
                  <Grid item xs={12} sm={6}>
                    <MonthYearSelector
                      register={register}
                      setValue={setValue}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <Controller
                      as={InputMask}
                      mask={MASK.CARD_CODE}
                      control={control}
                      name="securityCode"
                      maskChar={null}
                      onChange={([event]: any) =>
                        UNMASK.onlyDigits(event.target.value)
                      }
                    >
                      {() => (
                        <MyOutlinedTextField
                          fullWidth
                          label="Cód. de Segurança"
                          name="securityCode"
                          type="text"
                          inputRef={register}
                          error={Boolean(errors.securityCode)}
                          helperText={
                            errors.securityCode
                              ? errors.securityCode.message
                              : null
                          }
                          inputProps={{
                            maxLength: 4,
                          }}
                        />
                      )}
                    </Controller>
                  </Grid>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    control={
                      <MySwitch
                        name="defaultCardSwitch"
                        color="primary"
                        checked={isDefault}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          setIsDefault(e.target.checked);
                        }}
                      />
                    }
                    label="Definir como padrão"
                  />
                </Grid>
                <Grid
                  container
                  item
                  justify="flex-end"
                  direction={mobileSize ? 'column-reverse' : 'row'}
                >
                  <Button
                    color="primary"
                    size="large"
                    className={style.cancelButton}
                    onClick={() => redirectCardsList()}
                  >
                    Cancelar
                  </Button>

                  {isLoading ? (
                    <CircularLoading
                      isLoading={isLoading}
                      title={'Tokenizando cartão'}
                    />
                  ) : (
                    <Button
                      disableElevation
                      type="submit"
                      variant="contained"
                      color="primary"
                      className={style.buttonLabel}
                    >
                      Salvar Cartão
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default NewCard;
