import React, { FC, useCallback, useState } from 'react';
import {
  Button,
  Grid,
  IconButton,
  TableRow,
  Typography,
} from '@material-ui/core';
import MyTableCell from 'components/generals/table/MyTableCell';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

import { StoreProduct } from 'types/generals';
import { ProductCountRowProps } from './types';
import Colors from 'styles/colors';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { useStyles } from './styles';

const ProductCountRow: FC<ProductCountRowProps> = ({
  appendProductsHandler,
  product,
}) => {
  const INITIAL_QUANTITY = 1;
  const [productQuantity, setProductQuantity] = useState(INITIAL_QUANTITY);
  const styles = useStyles();

  const productAmount = useCallback(
    (amount: string) => formatColumnValue(FormatOptions.MONEY, amount),
    [],
  );

  const handleAddProduct = (product: StoreProduct) => {
    appendProductsHandler(state => [
      ...state,
      {
        id: product.id,
        name: product.name,
        quantity: productQuantity,
        unitPrice: product.price,
      },
    ]);
    setProductQuantity(INITIAL_QUANTITY);
  };

  return (
    <TableRow>
      <MyTableCell>{product.name}</MyTableCell>
      <MyTableCell>{product.sku || '-'}</MyTableCell>
      <MyTableCell>
        <Grid
          container
          direction="row"
          justify="flex-start"
          alignItems="center"
          style={{ flexFlow: 'row' }}
        >
          <Grid item>
            <IconButton
              onClick={() => {
                if (productQuantity > 1)
                  setProductQuantity(productQuantity => productQuantity - 1);
              }}
            >
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography
              variant="h4"
              color="textPrimary"
              className={styles.countNumber}
            >
              {productQuantity}
            </Typography>
          </Grid>
          <Grid item>
            <IconButton
              onClick={() =>
                setProductQuantity(productQuantity => productQuantity + 1)
              }
            >
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </MyTableCell>
      <MyTableCell>{productAmount(product.price)}</MyTableCell>
      <MyTableCell>
        <Button onClick={() => handleAddProduct(product)}>
          <Typography
            variant="h4"
            color="textPrimary"
            style={{
              color: Colors.RED,
              fontWeight: 'bold',
            }}
          >
            Adicionar
          </Typography>
        </Button>
      </MyTableCell>
    </TableRow>
  );
};

export default ProductCountRow;
