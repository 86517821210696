export const SELECT_ORDER_CARD =
  'Selecione o cartão de crédito desejado para o pedido';
export const ADD_NEW_CARD_SUCCESS = 'Cartão adicionado com sucesso';
export const ADD_NEW_CARD_ERROR = 'Houve uma falha ao adicionar o novo cartão';
export const UPDATE_CREDIT_CARD = (cardName: string) =>
  `Deseja definir o cartão ${cardName} como padrão?`;
export const DUE_DATE_ERROR = 'Data de validade do cartão vencida';

export const EDIT_CARD_SUCCESS = (cardName: string) =>
  `Cartão ${cardName} editado com sucesso`;
export const EDIT_CARD_FAIL = (cardName: string) =>
  `Houve uma falha ao editar o cartão ${cardName}`;
export const REGISTER_CREDIT_CARD = 'Cadastre seu cartão de crédito';
export const REMOVE_CREDIT_CART_CONFIRM = 'Deseja remover o cartão?';
export const NONE_CREDIT_CART_REGISTERED = 'Você não possui cartão cadastrado.';
export const CREDIT_CART_REQUIRED_IN_SIGNATURE =
  'A assinatura com cartão precisa ter cadastrado pelo menos um cartão de crédito.';
export const CREDIT_CARD_NOT_REGISTERED_MESSAGE = (name?: string) =>
  `${name} você ainda não tem um cartão cadastrado. Clique em Adicionar para inserir um novo cartão de crédito`;
export const CREDIT_CARD_PAYMENT_ERROR_MESSAGE_MODAL =
  'Seu box ainda não está garantido! Renove agora com um cartão de crédito válido.';
export const CREDIT_CARD_UPDATE_LINK_TITLE = 'Atualizar cartão';
export const AUTO_PAYMENT_TO_ORDER = (boxName: string, orderId: number) =>
  `Obrigado! Será efetuada nova tentativa de cobrança com o novo cartão cadastrado referente ao box ${boxName} e pedido ${orderId}`;
export const CREDIT_CARD_MAX_LENGTH_NAME =
  'O nome do cartão deve ter no máximo 25 caracteres';
export const CREDIT_CARD_NOT_FOUND = 'Cartão não encontrado';
