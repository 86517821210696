import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import { useStyles } from './styles';
import AppStoreBadge from 'assets/png/app-store-badge.png';
import PlayStoreBadge from 'assets/png/play-store-badge.png';

export interface MobileAppCardProps {
  mobileAppData: any;
}
const defaultLinkGoogle =
  'https://play.google.com/store/apps/details?id=com.peregrinoapp';
const defaultLinkApple =
  'https://apps.apple.com/br/app/peregrino-audiolivro-cat%C3%B3lico/id1534936436';

const MobileAppCard: FC<MobileAppCardProps> = ({ mobileAppData }) => {
  const linkGoogle = mobileAppData?.linkGoogle || defaultLinkGoogle;
  const linkApple = mobileAppData?.linkApple || defaultLinkApple;
  const cardBackGround = mobileAppData?.imagePath || '';
  const bannerStyle = {
    backgroundImage: `url('${cardBackGround}')`,
  };
  const classes = useStyles();

  return (
    <Grid className={classes.paperAppBg} style={bannerStyle}>
      <Grid container direction="row" justify="space-between">
        <Grid item xs={8} sm={9} md={5}></Grid>
        <Grid
          className={classes.alignEnd}
          container
          item
          direction="row"
          justify="center"
          alignItems="flex-end"
          wrap="nowrap"
          xs={12}
          sm={12}
          md={12}
          lg={5}
        >
          <Link
            to={{
              pathname: linkApple,
            }}
            target="_blank"
          >
            <img
              className={classes.badgesStore}
              src={AppStoreBadge}
              alt="App store badge"
            />
          </Link>
          <Link
            to={{
              pathname: linkGoogle,
            }}
            target="_blank"
          >
            <img
              className={classes.badgesStore}
              src={PlayStoreBadge}
              alt="Play store badge"
            />
          </Link>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MobileAppCard;
