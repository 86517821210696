import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, TablePagination, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';

import { useStyles } from './styles';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import ShopProductItem from 'components/client/ShopProductItem';
import MySlider from 'components/client/slider';
import { MySliderProps } from 'components/client/slider/types';
import StoreEmptyState from 'components/client/layout/LayoutStore/EmptyState';

import { useFilters } from 'hooks/filters';

import { AppState } from 'store';
import {
  listStoreRequest,
  setStorePage,
  setStoreRowsPerPage,
} from 'store/ducks/store/actions';
import { StoreState } from 'store/ducks/store/types';
import { UserState } from 'store/ducks/user/types';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { loadVerifyHasPromotion } from 'store/ducks/store/storeSettings/actions';
import { loadOrderMonthSignature } from 'store/ducks/order/actions';

import { StoreProduct } from 'types/generals';
import { IStoreBanner } from 'types/client';
import { STORE_PRODUCT_PATH } from 'types/generals/storePaths';
import StoreFilters from './StoreFilters';
import { CondOperator, QuerySort } from '@nestjsx/crud-request';
import CircularLoading from 'components/generals/loading/CircularLoading';

const VirtualStore: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const theme = useTheme();
  const [banners, setBanners] = useState<MySliderProps>({ steps: [] });
  const [selectedCategories, setSelectedCategories] = useState<number[]>([]);
  const [mobileBanners, setMobileBanners] = useState<MySliderProps>({
    steps: [],
  });
  const smallMobile = useMediaQuery(theme.breakpoints.down(415));

  const { genericFilterFields } = useFilters({});

  const defaultSort: QuerySort = { field: 'startDate', order: 'DESC' };

  const [sort, setSort] = useState<QuerySort>(defaultSort);

  const { data: user } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const { data: signature, isLoading: isLoadingSignature } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  const {
    data: products,
    total,
    isLoading,
    hasError,
    rowsPerPage,
    page,
  } = useSelector<AppState, StoreState>(
    ({ storeReducer }: AppState) => storeReducer,
  );

  const { isPromotionalPeriod, isMaintenance, activeSettings } = useSelector<
    AppState,
    StoreSettingsState
  >(({ storeSettings }: AppState) => storeSettings);

  const handleSetStoreSettings = useCallback(() => {
    let actBanners: MySliderProps = { steps: [] };
    let actMobileBanners: MySliderProps = { steps: [] };

    const mapAndPushBanners = (
      storeBanners: IStoreBanner[],
      sliderData: MySliderProps,
    ) => {
      storeBanners.map(banner => {
        sliderData.steps.push({
          path: banner.imageLink || banner.link || '',
          label: 'Banner da loja',
        });
      });
    };

    if (activeSettings.banners) {
      mapAndPushBanners(activeSettings.banners, actBanners);
      setBanners(actBanners);
    }

    if (activeSettings.mobileBanners) {
      mapAndPushBanners(activeSettings.mobileBanners, actMobileBanners);
      setMobileBanners(actMobileBanners);
    }
  }, [activeSettings]);

  const loadStore = useCallback(() => {
    let search: any = {
      $and: [{ $or: [...genericFilterFields] }],
    };
    if (selectedCategories.length) {
      search = {
        $and: [
          { $or: [...genericFilterFields] },
          {
            'categories.id': { [CondOperator.IN]: selectedCategories },
          },
        ],
      };
    }
    dispatch(
      listStoreRequest({
        join: [['categories']],
        page: page + 1,
        sort: [sort],
        limit: rowsPerPage,
        search: search,
      }),
    );
  }, [
    dispatch,
    page,
    rowsPerPage,
    sort,
    genericFilterFields,
    selectedCategories,
  ]);

  const loadOrdersMonth = useCallback(() => {
    if (signature.id && !isLoadingSignature) {
      dispatch(loadOrderMonthSignature(signature.id));
    }
  }, [dispatch, signature.id, isLoadingSignature]);

  useEffect(() => {
    loadStore();
  }, [loadStore]);

  useEffect(() => {
    loadOrdersMonth();
  }, [loadOrdersMonth]);

  useEffect(() => {
    if (activeSettings && activeSettings.banners) {
      handleSetStoreSettings();
    }
  }, [activeSettings, handleSetStoreSettings]);

  const redirectProductPage = (product: StoreProduct) => {
    history.push(`${STORE_PRODUCT_PATH(isMaintenance)}/${product.id}`, {
      product,
    });
  };

  const handleUpdatePage = (page: number) => {
    dispatch(setStorePage({ page }));
  };

  const handleUpdateRowsPerPage = (rowsPerPage: number) => {
    dispatch(setStoreRowsPerPage({ rowsPerPage }));
  };

  useEffect(() => {
    if (isPromotionalPeriod && signature.id) {
      dispatch(loadVerifyHasPromotion(signature.id));
    }
  }, [dispatch, isLoadingSignature, isPromotionalPeriod, signature.id]);

  const handleFilter = (selectedCategories: number[]) => {
    setSelectedCategories(selectedCategories);
  };

  const handleSortProduct = (sortData: QuerySort) => {
    setSort(sortData);
  };

  return (
    <Grid container direction="column">
      {user ? (
        <>
          <Grid container direction="row" justify="space-between">
            <Grid item xs={true}>
              <MySlider
                storeSlider
                steps={
                  smallMobile && mobileBanners.steps
                    ? mobileBanners.steps
                    : banners.steps
                }
              />
            </Grid>
          </Grid>
          <Grid>
            <StoreFilters
              handleFilter={handleFilter}
              handleSort={handleSortProduct}
            />
          </Grid>
          {!isLoadingSignature &&
          !isLoading &&
          !hasError &&
          products?.length ? (
            <>
              <Grid
                container
                justify="flex-start"
                spacing={3}
                direction="row"
                className={styles.mBottom}
              >
                {products?.map((product: StoreProduct) => (
                  <Grid item xs={12} sm={6} md={4} xl={3} key={product.id}>
                    <ShopProductItem
                      product={product}
                      callbackLink={() => redirectProductPage(product)}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          ) : !isLoading && !products?.length ? (
            <StoreEmptyState />
          ) : (
            <CircularLoading
              isLoading={isLoadingSignature}
              title={'Carregando assinatura'}
            />
          )}
          <TablePagination
            ActionsComponent={MyTablePaginationActions}
            component="div"
            hidden={!total}
            count={total}
            page={page}
            labelRowsPerPage="Itens por página"
            onChangePage={(_, newPage: number) => handleUpdatePage(newPage)}
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
              handleUpdatePage(0);
              handleUpdateRowsPerPage(parseInt(event.target.value, 10));
            }}
            rowsPerPageOptions={ROWS_PER_PAGE}
          />
        </>
      ) : null}
    </Grid>
  );
};

export default VirtualStore;
