import React, { useRef } from 'react';
import MyOutlinedTextField from './MyOutlinedTextField';
import { InputRef } from 'types';
import { Controller } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import MASK from 'helpers/masks';

interface MyDateHourInputProps {
  control: any;
  label: string;
  register: any;
  errors: any;
  helperText: any;
  name: any;
  setValue: any;
}

const MyDateHourInput: React.FC<MyDateHourInputProps> = ({
  name,
  helperText,
  label,
  control,
  register,
  errors,
  setValue,
}) => {
  const hourInput = useRef() as InputRef;

  return (
    <Controller
      as={ReactInputMask}
      mask={MASK.HOUR}
      name={name}
      control={control}
      maskChar={null}
    >
      {() => (
        <MyOutlinedTextField
          fullWidth
          label={label}
          type="text"
          name={name}
          inputProps={{
            ref: hourInput,
            inputMode: 'numeric',
          }}
          inputLabelprops={{ shrink: true }}
          error={errors}
          helperText={helperText}
          inputRef={register}
        />
      )}
    </Controller>
  );
};

export default MyDateHourInput;
