import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

const useStyles = makeStyles({
  container: {
    marginTop: 20,
  },
  paddingContainer: {
    padding: theme.spacing(2, 0),
  },
  actionWrapper: {
    float: 'right',
    alignItems: 'flex-end',
    placeContent: 'flex-end',
    paddingRight: '1em',
    width: 'auto',
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 32,
    cursor: 'pointer',
  },
  list: {
    backgroundColor: Colors.WHITE,
  },
  rootPaper: {
    marginTop: '1em',
    padding: '4em',
    borderRadius: '2em',
    marginBottom: '3em',
  },
  table: {
    minWidth: 650,
  },
  paperTable: {
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  gridAction: {
    marginBottom: 10,
  },
  tableCellBold: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  menuOption: {
    textTransform: 'capitalize',
  },
  mTop: {
    marginTop: '1em',
  },
  circle: {
    borderRadius: '50%',
  },
  boldText: {
    fontWeight: 'bold',
  },
  linkTextDecoration: {
    textDecoration: 'none',
  },
});

export { useStyles };
