import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import {
  BaseRankingSeason,
  RankingSeasonReferral,
  RankingSeasonReferred,
} from 'types/generals/rankingSeason';
import * as Yup from 'yup';
import { separateDateWithTrace } from 'helpers/validateDate';

// fixme check
export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  startDate: Yup.string().required('Data inicial obrigatório'),
  endDate: Yup.string()
    .required('Data final obrigatório')
    .when('startDate', {
      is: (startDate, value) => startDate < value,
      then: (rule: any) =>
        rule.required('A data inicial não pode ser maior do que a final'),
    }),
  referreds: Yup.array()
    .of(
      Yup.object().shape({
        score: Yup.number().required('Campo é required'),
      }),
    )
    .compact(v => !v?.score),
});

export const defaultValues = (
  season: BaseRankingSeason,
  referralConfig: RankingSeasonReferral[],
  referredConfig: RankingSeasonReferred[],
) => {
  return {
    name: season?.name || '',
    startDate: season?.startDate
      ? separateDateWithTrace(season.startDate.toString())
      : '',
    endDate: season?.endDate
      ? separateDateWithTrace(season.endDate.toString())
      : '',
    isActive: season?.isActive || false,
    expireInMonths: season?.expireInMonths || 0,
    referrals: referralConfig,
    referreds: referredConfig,
  };
};

export const recurrenceList = [
  {
    label: 'Mensal',
    value: 1,
  },
  {
    label: 'Semestral',
    value: 6,
  },
  {
    label: 'Anual',
    value: 12,
  },
];

export const defaultReferredList: RankingSeasonReferred[] = [
  {
    score: 0,
    recurrence: 1,
  },
  {
    score: 0,
    recurrence: 6,
  },
  {
    score: 0,
    recurrence: 12,
  },
];

export const defaultReferralList: RankingSeasonReferral[] = [
  {
    score: 0,
    recurrence: 1,
    startPoint: 0,
    endPoint: 10,
  },
  {
    score: 0,
    recurrence: 6,
    startPoint: 0,
    endPoint: 10,
  },
  {
    score: 0,
    recurrence: 12,
    startPoint: 0,
    endPoint: 10,
  },
];

export const validateReferralField = (
  referral: RankingSeasonReferral,
  value: any,
  field: string,
) => {
  const START_POINT = 'startPoint';
  const END_POINT = 'endPoint';
  let error: any = {
    ...referral.error,
  };
  if (!value) {
    error[field] = 'Campo obrigatório';
  } else {
    error[field] = '';
    if (field === START_POINT && Number(value) >= Number(referral.endPoint)) {
      error[field] = 'Faixa inicial não pode ser superior ou igual à final';
    } else if (
      field === END_POINT &&
      Number(value) <= Number(referral.startPoint)
    ) {
      error[field] = 'Faixa final não pode ser inferior ou igual à nicial';
    }

    if (!error[field]) {
      error[START_POINT] = '';
      error[END_POINT] = '';
    }
  }
  return error;
};
