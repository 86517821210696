import { makeStyles, createStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>
  createStyles({
    formControl: {
      maxWidth: 528,
    },
  }),
);

export default useStyles;
