import React from 'react';
import { Grid, makeStyles, styled, Typography } from '@material-ui/core';

import MyUserProfileImage from 'components/generals/header/MyUserProfileImage';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  dropdownButton: {
    padding: '0',
    justifyContent: 'flex-start',
    margin: theme.spacing(0, 1, 0, 0),
  },
  expandIcon: {
    color: theme.palette.grey['500'],
    fontSize: '22px',
    height: '30px',
  },
  list: {
    background: Colors.WHITE,
  },
}));

export const Wrapper = styled(Grid)({});

export const UserProfile = styled(({ ...props }) => (
  <MyUserProfileImage {...props} style={{ width: '72px', height: '72px' }} />
))({});

export const BoldText = styled(Typography)({
  fontWeight: 'bold',
});

export const GreyText = styled(Typography)(({ theme }) => ({
  color: theme.palette.grey['500'],
}));
