export const Colors = {
  WHITE: '#FFFFFF',
  BLACK: '#000000',
  ORANGE: '#FF460B',
  RED: '#ed5240',
  LIGHT_RED: '#FF6262',
  LIGHTER_RED: '#ffdada',
  SALMON: '#ed6c5d',
  YELLOW: '#ffb910',
  DARK_YELLOW: '#efc27f',
  GREEN: '#82ccbc',
  LIGHT_GREEN: '#28c397',
  DARK_GREEN: '#1fab83',
  DARKER_GREEN: '#247C4D',
  BRIGHT_GREEN: '#00df7e',
  BRIGHT_DARK_GREEN: '#35BF75',
  WHITE_GREEN: '#EEFFF6',
  LIGHTER_GRAY: '#f2f2f2',
  WHITE_GRAY: '#EAEAEA',
  WHITE_SMOKE: '#F4F4F4',
  LIGHT_GRAY: '#d8d8d8',
  MEDIUM_GRAY: '#979797',
  GRAY: '#acacab',
  DARK_GRAY: '#716f73',
  DARKER_GRAY: '#3c3c3b',
  SILVER: '#C3C3C3',
  DARK_BLUE: '#85B2CC',
  LIGHTER_BLUE: '#D6EDF8',
  BLUE: '#1976D2',
};

export default Colors;
