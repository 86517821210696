import Layout from 'components/client/layout';
import React, { FC, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { AuthState } from 'store/ducks/auth/types';
import { loadUserRequest } from 'store/ducks/user/actions';
import { UserState } from 'store/ducks/user/types';
import AdminAuthRoutes from './admin/AdminAuthRoutes';
import ClientAuthRoutes from './client/ClientAuthRoutes';
import {
  useInterceptors,
  api,
  apiQueue,
  apiQueueSchedulers,
} from 'services/apiService';
import { useLocation, useHistory } from 'react-router-dom';
import { handleReturnUrl, USER_PAGES } from './utils';
import { SubscriberConfigProvider } from 'contexts/subscriberConfigContext';

export const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const AuthRoutes: FC = () => {
  useInterceptors();
  const dispatch = useDispatch();
  const query = useQuery();
  const { pathname } = useLocation();
  const history = useHistory();
  const { isAdmin, accessToken } = useSelector<AppState, AuthState>(
    ({ auth }: AppState) => auth,
  );

  api.defaults.headers.Authorization = `Bearer ${accessToken}`;
  apiQueue.defaults.headers.Authorization = `Bearer ${accessToken}`;
  apiQueueSchedulers.defaults.headers.Authorization = `Bearer ${accessToken}`;

  const { data: userData } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  // FIXME: Regra crescendo, se tiver mais uma situação de redirect por
  // params aconselho refatorar essa função
  const urlAction = query.get('action');
  const hashPlan = query.get('plan');
  const storeProductId = query.get('storeProduct');
  const customPage = query.get('page');
  const url = `/login?action=${urlAction}&plan=${hashPlan}`;

  const moveSubscriverToPage = useCallback(() => {
    if (!urlAction && customPage === USER_PAGES.userRanking) {
      history.push(USER_PAGES.userRanking);
    }
  }, [customPage, history, urlAction]);

  useEffect(() => {
    if (!userData) {
      dispatch(loadUserRequest());
    }
    if (urlAction && !isAdmin) {
      const urlToPush = handleReturnUrl(urlAction);
      if (hashPlan) {
        history.push(urlToPush.url, { hashPlan });
      } else if (storeProductId) {
        history.push(`${urlToPush.url}/product/${storeProductId}`);
      } else {
        history.push(urlToPush.url);
      }
    }

    moveSubscriverToPage();

    if (isAdmin && !pathname.includes('/admin')) {
      history.push('/admin/panel');
    } else if (!isAdmin && pathname.includes('/admin')) {
      history.push('/home');
    }
  }, [
    dispatch,
    userData,
    pathname,
    history,
    isAdmin,
    url,
    urlAction,
    hashPlan,
    storeProductId,
    customPage,
    moveSubscriverToPage,
  ]);

  return isAdmin ? (
    <AdminAuthRoutes />
  ) : (
    <Layout>
      <SubscriberConfigProvider>
        <ClientAuthRoutes />
      </SubscriberConfigProvider>
    </Layout>
  );
};

export default AuthRoutes;
