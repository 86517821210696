import React, { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Paper,
  Button,
  FormControlLabel,
} from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import clsx from 'clsx';

import useFormStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { Video } from 'types/generals';
import { validationSchema } from './utils';
import MySwitch from 'components/generals/input/MySwitch';
import VideoService from 'services/videoService';

const VideoPage = (props: any) => {
  const dispatch = useDispatch();
  const formStyles = useFormStyles();
  const active = props.location.state
    ? props.location.state.video.isActive
    : false;
  const [videoId, setVideoId] = useState();
  const [isActive, setIsActive] = useState<boolean>(active);
  const history = useHistory();

  useEffect(() => {
    dispatch(actUpdatePageTitle('Novo Vídeo'));
  }, [dispatch]);

  const { handleSubmit, register, errors, reset, control } = useForm({
    validationSchema,
  });

  useEffect(() => {
    const { video } = props.location.state || {};
    if (video) {
      dispatch(actUpdatePageTitle(`Editar Vídeo - ${video.title}`));
      setVideoId(video.id);

      reset({
        ...video,
      });
    }
  }, [dispatch, reset, props.location, history]);

  const handleRedirectVideos = () => {
    reset();
    history.push('/admin/videos');
  };

  const handleOnSubmit = ({ recurrenceId, ...data }: any) => {
    const video: Video = {
      ...data,
      isActive,
    };

    if (videoId) {
      updateVideo(video);
    } else {
      createVideo(video);
    }
  };

  const updateVideo = async (video: object) => {
    const data = await VideoService.updateVideo(video, videoId);

    if (data?.status === StatusCodes.OK) {
      dispatch(notifySuccess('Video editado com sucesso'));
      handleRedirectVideos();
    } else {
      dispatch(notifyError('Falha ao editar o video desejado'));
    }
  };

  const createVideo = async (video: object) => {
    const data = await VideoService.createVideo(video);

    if (data?.status === StatusCodes.CREATED) {
      dispatch(notifySuccess('Vídeo criado com sucesso'));
      handleRedirectVideos();
    } else {
      dispatch(notifyError('Falha ao criar o video'));
    }
  };

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ fontWeight: 'bold' }}
        >
          Informações principais
        </Typography>

        <Paper elevation={0} className={formStyles.rootPaper}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={6}>
              <MyOutlinedTextField
                id="video-title"
                name="title"
                label="Título"
                fullWidth
                error={Boolean(errors.title)}
                helperText={errors.title ? errors.name.message : null}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <MyOutlinedTextField
                id="video-author"
                name="author"
                label="Autor"
                fullWidth
                error={Boolean(errors.author)}
                helperText={errors.author ? errors.author.message : null}
                inputRef={register}
              />
            </Grid>

            <Grid item xs={12} lg={6}>
              <MyOutlinedTextField
                id="video-url"
                name="url"
                label="Link do video no vimeo"
                fullWidth
                error={Boolean(errors.url)}
                helperText={errors.url ? errors.url.message : null}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <MyOutlinedTextField
                id="video-keyWord"
                name="keyWord"
                label="Palavra-chave"
                fullWidth
                error={Boolean(errors.keyWord)}
                helperText={errors.keyWord ? errors.keyWord.message : null}
                inputRef={register}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ marginTop: 20 }}
              name="isActive"
              control={
                <MySwitch
                  name="isActive"
                  color="primary"
                  checked={isActive}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setIsActive(e.target.checked)
                  }
                />
              }
              label="Disponível"
              labelPlacement="start"
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={formStyles.actionsWrapper}
          >
            <Button
              color="primary"
              className={formStyles.buttonLabel}
              style={{ marginRight: 30 }}
              onClick={() => handleRedirectVideos()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={clsx(formStyles.buttonLabel, formStyles.buttonWrapper)}
            >
              Salvar
            </Button>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default VideoPage;
