import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithSaga, useGetWithSWR, apiQueue } from './apiService';
import { PlanForm } from 'types/generals';

export default class PlanService {
  static formData(plan: PlanForm, planImages?: any[]) {
    const formData = new FormData();

    const prices = {
      bankslipPrice: plan.bankslipPrice,
      cardPrice: plan.cardPrice,
    };

    const discounts = {
      bankslipDiscount: plan.bankslipDiscount,
      cardDiscount: plan.cardDiscount,
    };

    formData.append('name', plan.name);
    formData.append('description', plan.description);
    formData.append('recurrence', plan.recurrence.toString());
    formData.append('message', plan.message ? plan.message : '');
    formData.append('paymentType', plan.paymentType);
    formData.append('sku', plan.sku);
    formData.append('successPage', plan.successPage ? plan.successPage : '');
    formData.append('actionPage', plan.actionPage ? plan.actionPage : '');
    formData.append('isPrivate', JSON.stringify(plan.isPrivate));
    formData.append('stepsDisabled', JSON.stringify(plan.stepsDisabled));
    formData.append('prices', JSON.stringify(prices));
    formData.append('isDonation', JSON.stringify(plan.isDonation));
    formData.append('discounts', JSON.stringify(discounts));

    if (planImages?.length) {
      planImages.forEach(file => {
        formData.append('image', file.name ? file : file.image);
      });
    } else {
      formData.append('image', '');
    }

    return formData;
  }

  static async getAllPlans(query?: CreateQueryParams) {
    try {
      const data = await getWithSaga('plans', query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getOnePlan(planId: number) {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const data = useGetWithSWR(`plans/${planId}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async createPlan(plan: PlanForm, planImages?: File[]) {
    try {
      const data = await api.post('/plans', this.formData(plan, planImages));
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updatePlan(plan: PlanForm, id: number, planImages?: File[]) {
    try {
      const data = await api.patch(
        `plans/${id}`,
        this.formData(plan, planImages),
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async changePlan(signatureId: number, planId: number) {
    try {
      const data = await apiQueue.post(
        `signature/${signatureId}/change-plan/${planId}`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }
}
