export const STORE_SETTINGS_TITLE = 'Configurações gerais';
export const SAVE_STORE_SETTINGS_SUCCESS =
  'Configurações da loja cadastradas com sucesso';
export const SAVE_STORE_SETTINGS_ERROR =
  'Não foi possivel salvar as configurações de loja';

export const STORE_SETTINGS_DESKPTOP_BANNER_TITLE = 'Upload banner desktop';
export const STORE_SETTINGS_MOBILE_BANNER_TITLE = 'Upload banner mobile';
export const STORE_SETTINGS_SEASON_BANNER_TITLE = 'Upload banner indicação';
// export const SAVE_STORE_SETTINGS_SUCCESS = 'Configurações da loja cadastradas com sucesso';
/* export const TOO_SMALL_FILE_ERROR = 'O arquivo desejado é muito pequeno';
export const TOO_MANY_FILES_ERROR = (limit?: number) =>
  `Os arquivos selecionados ultrapassam o limite de ${
    limit || ''
  } arquivo(s) aceito(s).`;
export const INVALID_FILE_FORMAT_ERROR =
  'O arquivo desejado possui um formato não aceito';
export const DEFAULT_FILE_ERROR =
  'Não foi possível encontrar o arquivo desejado';
export const BLOB_FILE_PARSE_ERROR =
  'Houve uma falha ao converter o arquivo de resposta'; */

// Configurações da loja cadastradas com sucesso
export const QUOTE_FREIGHT_ERROR = 'Erro ao cotar os pedidos, tente novamente.';
export const QUOTE_FREIGHT_SUCCESS = 'Cotação iniciada com sucesso.';
export const QUOTE_FREIGHT_NOT_HAS_TRANSPORT =
  'Nenhuma transportadora nos pedidos';
