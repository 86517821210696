import { makeStyles } from '@material-ui/core';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  ordersList: {
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  clickableRow: {
    cursor: 'pointer',
  },
});
