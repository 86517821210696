import React, { FC, useCallback, useEffect, useState } from 'react';
import { CondOperator } from '@nestjsx/crud-request';
import { Grid, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { useStyles } from '../styles';
import { AppState } from 'store';
import { useFilters } from 'hooks/filters';
import { UserState } from 'store/ducks/user/types';
import CircularLoading from 'components/generals/loading/CircularLoading';
import RankingSeasonService from 'services/rankingSeasonService';
import { defaultUserData, NO_RANKING_SEASON_MESSAGE } from '../utils';
import {
  UserRankingSeasonType,
  UserScoreData,
} from 'types/generals/rankingSeason';
import { useCommonStyles } from 'styles/common';
import { RankingState } from 'store/ducks/ranking/types';
import { loadUserRankingSeasonRequest } from 'store/ducks/ranking/actions';
import RankingSeasonClient from '..';
import ReferredUsers from './ReferredUsers';
import PartilBlock from './PartilBlock';
import ReferralBlock from './ReferralBlock';
import UserScoreRanking from './UserScoreRanking';
import { MySliderProps } from 'components/client/slider/types';
import { IStoreBanner } from 'types/client';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import MySlider from 'components/client/slider';

const UserRanking: FC = () => {
  const { sort } = useFilters({});
  const styles = useStyles({});
  const commnStyles = useCommonStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [userRanking, setUserRanking] = useState<UserScoreData>(
    defaultUserData,
  );

  const { data: user } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const {
    activeRanking,
    isLoading: isLoadingRanking,
    userRankings: referredUsers,
  } = useSelector<AppState, RankingState>(({ ranking }: AppState) => ranking);

  const [banners, setBanners] = useState<MySliderProps>({ steps: [] });

  const { activeSettings } = useSelector<AppState, StoreSettingsState>(
    ({ storeSettings }: AppState) => storeSettings,
  );

  const loadUserRankingData = useCallback(async () => {
    const id = activeRanking?.id || 0;
    if (user) {
      const response = await RankingSeasonService.fetchUserRankingData(
        user.id,
        id,
      );
      if (response) {
        setUserRanking(response);
      }
      setIsLoading(false);
    }
  }, [activeRanking, user]);

  const getUserRankingSeason = useCallback(() => {
    if (user && activeRanking) {
      dispatch(
        loadUserRankingSeasonRequest({
          sort,
          join: [{ field: 'referralUser', select: ['name', 'email'] }],
          filter: [
            {
              field: 'type',
              operator: CondOperator.NOT_EQUALS,
              value: UserRankingSeasonType.referred,
            },
            {
              field: 'type',
              operator: CondOperator.NOT_EQUALS,
              value: UserRankingSeasonType.manualAddition,
            },
            {
              field: 'type',
              operator: CondOperator.NOT_EQUALS,
              value: UserRankingSeasonType.manualDeduction,
            },
            {
              field: 'userId',
              operator: CondOperator.EQUALS,
              value: user.id,
            },
            {
              field: 'rankingSeasonId',
              operator: CondOperator.EQUALS,
              value: activeRanking.id,
            },
          ],
        }),
      );
    }
  }, [activeRanking, dispatch, sort, user]);

  useEffect(() => {
    if (user && user.id && isLoading) {
      loadUserRankingData();
    }
  }, [user, loadUserRankingData, isLoadingRanking, isLoading, activeRanking]);

  useEffect(() => {
    if (userRanking.referralCode) {
      getUserRankingSeason();
    }
  }, [getUserRankingSeason, userRanking.referralCode]);

  const handleSetStoreSettings = useCallback(() => {
    let actBanners: MySliderProps = { steps: [] };

    const mapAndPushBanners = (
      storeBanners: IStoreBanner[],
      sliderData: MySliderProps,
    ) => {
      storeBanners.forEach(banner => {
        sliderData.steps.push({
          path: banner.imageLink || banner.link || '',
          label: 'Banner da indicação',
          link: banner.redirectLink || '',
        });
      });
    };

    if (activeSettings.seasonBanners) {
      mapAndPushBanners(activeSettings.seasonBanners, actBanners);
      setBanners(actBanners);
    }
  }, [activeSettings]);

  useEffect(() => {
    if (activeSettings && activeSettings.seasonBanners) {
      handleSetStoreSettings();
    }
  }, [activeSettings, handleSetStoreSettings]);

  const ActiveBlock = () => (
    <>
      {isLoadingRanking ? (
        <CircularLoading isLoading={isLoading} title={'Carregando ranking'} />
      ) : referredUsers.length ? (
        <FullBlock />
      ) : (
        <PartilBlock userRanking={userRanking} />
      )}
    </>
  );

  const FullBlock = () => (
    <Grid container alignContent="flex-start">
      <Grid container spacing={1} item xs>
        <Grid item xs={12} md={6}>
          <RankingSeasonClient />
        </Grid>
        <Grid
          container
          item
          xs={12}
          md={6}
          spacing={1}
          alignContent="flex-start"
        >
          <Grid item xs={12}>
            <UserScoreRanking
              userRanking={userRanking}
              user={user}
              showHistoryButton={true}
            />
          </Grid>
          <Grid item xs={12}>
            <ReferralBlock userRanking={userRanking} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <ReferredUsers userRanking={userRanking} />
      </Grid>
    </Grid>
  );

  const NoRankingBlock = () => (
    <>
      <Grid
        container
        direction="row"
        spacing={1}
        alignContent="flex-start"
        justify="center"
        className={styles.marginTop15}
      >
        <Grid item xs={8} className={commnStyles.textAlignCenter}>
          <Typography variant="h1" color="textPrimary">
            {NO_RANKING_SEASON_MESSAGE}
          </Typography>
        </Grid>
        <Grid item xs={8} className={commnStyles.marginTop10}>
          <UserScoreRanking userRanking={userRanking} />
        </Grid>
      </Grid>
    </>
  );

  return (
    <>
      <Grid container className={styles.rootMargin}>
        <Grid
          container
          direction="row"
          justify="space-between"
          className={styles.mTop}
        >
          <Grid item xs={true}>
            <MySlider storeSlider steps={banners.steps} />
          </Grid>
        </Grid>
        {isLoading ? (
          <CircularLoading isLoading={isLoading} title={'Carregando ranking'} />
        ) : !activeRanking ? (
          <NoRankingBlock />
        ) : (
          <ActiveBlock />
        )}
      </Grid>
    </>
  );
};

export default UserRanking;
