import { CondOperator } from '@nestjsx/crud-request';

export const getOneSignatureQuery = () => {
  return {
    join: [
      {
        field: 'user',
        select: [
          'name',
          'email',
          'phone',
          'identification',
          'createdAt',
          'address',
          'fixedTransportMethod',
          'isPremium',
        ],
      },
      {
        field: 'user.address',
      },
    ],
    filter: {
      field: 'user.address.isDefault',
      operator: CondOperator.EQUALS,
      value: true,
    },
  };
};
