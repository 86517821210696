import React, { KeyboardEvent, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { Grid, Typography, Paper, Button } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import clsx from 'clsx';

import MySelectForm from 'components/generals/input/MySelectForm';
import useStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import MyDatePicker from 'components/generals/input/MyDatePicker';
import { BOX_ALL_PLANS, validationSchema } from './utils';

import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import BoxService from 'services/boxService';
import OrdersService from 'services/ordersService';
import { BoxItem } from 'types/generals';
import ItemsList from 'components/generals/lists/ItemsList';
import Colors from 'styles/colors';
import { PlanBoxSelect, LocationBox, BoxDTO } from './types';
import ImagesZone from 'components/admin/ImagesZone/index';
import { ImageZone } from 'components/admin/ImagesZone/types';
import { head } from 'lodash';
import {
  BOX_REVEAL,
  BOX_NOT_REVEAL,
  GENERIC_MESSAGE,
  ERROR_BOX_ITEM_DELETE,
} from 'utils/messages';
import { format } from 'date-fns';

const BoxPage = () => {
  const styles = useStyles();
  const history = useHistory();
  const location = useLocation<LocationBox>();
  const dispatch = useDispatch();
  const [plans] = useState<PlanBoxSelect[]>([
    {
      title: 'Todos',
      value: BOX_ALL_PLANS,
    },
  ]);
  const [boxId, setBoxId] = useState();
  const [boxImage, setBoxImage] = useState<ImageZone[]>([]);
  const [items, setItems] = useState<BoxItem[]>([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  useEffect(() => {
    dispatch(actUpdatePageTitle('Novo box'));
  }, [dispatch]);

  const {
    handleSubmit,
    register,
    errors,
    reset,
    control,
    setValue,
    getValues,
    setError,
  } = useForm({
    validationSchema,
  });

  const _saveBox = async (data: any, reveal?: boolean) => {
    try {
      if (!startDate) setError('startDate', 'Error');
      if (!endDate) setError('endDate', 'Error');

      const { plans } = data;
      const box: BoxDTO = {
        ...data,
        image: head(data.images),
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        itens: items as BoxItem[],
      };

      let responseBox;

      boxId
        ? (responseBox = await BoxService.updateBox(box, plans, boxId))
        : (responseBox = await BoxService.createBox(box, plans));

      if (responseBox.status === StatusCodes.BAD_REQUEST) throw new Error();
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = async (data: any) => {
    try {
      await _saveBox(data);
      reset();
      dispatch(notifySuccess('Box cadastrado com sucesso'));
      history.push('/admin/boxes');
    } catch (error) {
      dispatch(notifyError('Não foi possivel concluir o cadastro'));
    }
  };

  const handleRevealBox = async (data: any) => {
    try {
      await _saveBox(data, true);
      await OrdersService.revealBox(Number(boxId));
      dispatch(notifySuccess(BOX_REVEAL));
      history.push('/admin/boxes');
    } catch (error) {
      dispatch(notifyError(BOX_NOT_REVEAL));
    }
  };

  const handleAddNewItem = () => {
    const itemLabel = getValues('item');
    setItems(items => [...items, { name: itemLabel }]);
    setValue('item', '');
  };

  const handleEnterItem = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleAddNewItem();
  };

  const deleteItem = async (itemToDelete: string) => {
    const updateItemsStatus = () => {
      updatedItems.splice(itemIndex, 1);
      if (itemIndex >= 0) setItems(updatedItems);
    };

    const updatedItems = [...items];
    const itemIndex = items.findIndex(item => item?.name === itemToDelete);
    const boxItem = items[itemIndex];

    if (boxItem?.id) {
      const response = await handleDeleteBoxItem(boxItem.id);
      if (response.status !== StatusCodes.OK) {
        dispatch(notifyError(ERROR_BOX_ITEM_DELETE));
        return;
      }
    }
    updateItemsStatus();
  };

  const handleDeleteBoxItem = async (boxItemId: number) => {
    try {
      return await BoxService.deleteBoxItem(boxItemId);
    } catch (error) {
      dispatch(notifyError(ERROR_BOX_ITEM_DELETE));
    }
  };

  useEffect(() => {
    const { box } = location.state || {};
    if (box) {
      const formatDate = (date: string) => new Date(`${date} 00:00:00`);

      dispatch(actUpdatePageTitle(`Editar Box - ${box.name}`));
      setBoxImage([{ image: box.image, isCover: true }]);
      setBoxId(box.id);
      setItems(box.itens);
      setStartDate(formatDate(box.startDate));
      setEndDate(formatDate(box.endDate));
      reset({ ...box, plans: plans[0].value });
    }
  }, [dispatch, location.state, reset, plans]);

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
      >
        <Grid item xs>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ fontWeight: 'bold' }}
          >
            Informações principais
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={styles.actionsWrapper}
          >
            <Button
              id="cancel-new-box"
              color="primary"
              className={styles.buttonLabel}
              style={{ marginRight: 30 }}
              onClick={() => history.push('/admin/boxes')}
            >
              Cancelar
            </Button>
            <Button
              id="save-box"
              type="submit"
              variant="contained"
              color="primary"
              style={{ marginRight: 15 }}
              className={clsx(styles.buttonLabel, styles.buttonWrapper)}
              onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
            <Button
              variant="contained"
              color="primary"
              className={clsx(styles.buttonLabel, styles.buttonWrapper)}
              onClick={handleSubmit(handleRevealBox)}
            >
              Salvar e Revelar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Paper elevation={0} className={styles.rootPaper}>
        <Grid container spacing={3}>
          <Grid item xs={6} lg={4}>
            <MyOutlinedTextField
              id="box-name"
              name="name"
              label="Nome do box"
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs={6} lg={2}>
            <MyOutlinedTextField
              id="box-sku"
              name="sku"
              label="SKU"
              fullWidth
              error={Boolean(errors.sku)}
              helperText={errors.sku ? errors.sku.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12} lg={6}>
            <Controller
              name="plans"
              control={control}
              as={({ onChange, onBlur, value }) => (
                <MySelectForm
                  contracted
                  title="Meus Planos"
                  itens={plans}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={Boolean(errors.plans)}
                  helperText={errors.plans ? errors.plans.message : ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={6} style={{ marginTop: -5 }}>
            <MyDatePicker
              id="box-start-date"
              onChange={(date: Date) => setStartDate(date)}
              value={startDate}
              name="startDate"
              label="Data lançamento"
              error={Boolean(errors.startDate)}
              helperText={errors.startDate ? GENERIC_MESSAGE : null}
            />
          </Grid>
          <Grid item xs={12} lg={6} style={{ marginTop: -5 }}>
            <MyDatePicker
              id="box-end-date"
              onChange={(date: Date) => setEndDate(date)}
              value={endDate}
              name="endDate"
              label="Data fim"
              error={Boolean(errors.endDate)}
              helperText={errors.endDate ? GENERIC_MESSAGE : null}
            />
          </Grid>
          <Grid item md={12} lg={6}>
            <Grid item xs={12} lg={12} direction={'column'} spacing={2}>
              <MyOutlinedTextField
                id="box-weight"
                name="weight"
                label="Peso"
                fullWidth
                inputRef={register}
                error={Boolean(errors.weight)}
                helperText={errors.weight ? errors.weight.message : null}
              />
            </Grid>
            <MyOutlinedTextField
              id="box-item"
              name="item"
              label="Item"
              inputRef={register}
              fullWidth
              onKeyUp={handleEnterItem}
              style={{ marginTop: '2.0em' }}
            />
            <Grid item xs>
              <ItemsList items={items} deleteAction={deleteItem} />
              <Button
                id="add-new-box"
                onClick={() => handleAddNewItem()}
                className={styles.buttonLabel}
              >
                <Typography
                  variant="h4"
                  style={{
                    color: Colors.RED,
                    fontWeight: 'bold',
                    whiteSpace: 'nowrap',
                  }}
                >
                  Adicionar novo item
                </Typography>
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MyOutlinedTextField
              id="box-description"
              name="description"
              label="Descrição"
              multiline
              fullWidth
              rows={8}
              inputRef={register}
              error={Boolean(errors.description)}
              helperText={
                errors.description ? errors.description.message : null
              }
            />
          </Grid>
        </Grid>
      </Paper>
      <ImagesZone
        register={register}
        setValue={setValue}
        errors={errors}
        multiple={false}
        files={boxImage}
        maxFiles={1}
      />
    </Grid>
  );
};

export default BoxPage;
