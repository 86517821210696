/**
 * @description
 * Este arquivo é util pra centralizar validações YUP, em situação de crescimento
 * exponencial favor começar a separar em pastas com responsabilidades
 * especificas.
 * */
import * as Yup from 'yup';
import { USER_IDENTIFICATION_INVALID } from 'utils/messages/user';
import { validateCpfOrCnpj } from 'utils/functions/generals';
import { CREDIT_CARD_MAX_LENGTH_NAME } from './messages';
import { format } from 'date-fns';
import { validateDate } from 'helpers/validateDate';

export const identification = Yup.string()
  .required('CPF obrigatório')
  .test(
    'invalid-identification',
    USER_IDENTIFICATION_INVALID,
    function (value) {
      if (!value) return this.createError();
      const validate = validateCpfOrCnpj(value);
      if (!validate) return this.createError();
      return true;
    },
  );

// Util para formularios de cartão de credito
export const cardNumber = Yup.string()
  .required('Número do cartão obrigatório')
  .min(14, 'Número do cartão deve conter no mínimo 14 dígitos');
export const cardName = Yup.string()
  .required('Nome do cartão obrigatório')
  .max(25, CREDIT_CARD_MAX_LENGTH_NAME);
export const dueMonth = Yup.string().required('Mês de validade obrigatório');
export const dueYear = Yup.string().required('Ano de validade obrigatório');
export const securityCode = Yup.string()
  .required('Código de segurança obrigatório')
  .min(3, 'O Código de Segurança deve conter no mínimo 3 dígitos')
  .max(4, 'O Código de Segurança deve conter no máximo 4 dígitos');

// EndDate com proteção contra save no passado, para a regra funcionar o yup
// precisa de um StartDate do tipo date e required
export const endDate = Yup.date()
  .typeError('Campo obrigatório')
  .required('Campo obrigatório')
  .test(
    'invalid-end-date',
    'Data de termino anterior ao momento presente',
    function (value) {
      if (!value) return this.createError({ message: 'Campo obrigatório' });

      const formatedEndDate = format(value, 'dd/MM/yyyy');
      if (!this.parent.startDate) {
        const validate = validateDate(formatedEndDate);
        if (validate && validate < 0) return this.createError();
      } else {
        const formatedStartDate = format(this.parent.startDate, 'dd/MM/yyyy');
        const validate = validateDate(formatedStartDate, formatedEndDate);
        if (validate && validate < 0) {
          return this.createError({
            message: 'Data de termino menor que a data de início',
          });
        }
      }

      return true;
    },
  );
