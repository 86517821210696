import { CreateQueryParams } from '@nestjsx/crud-request';

import { api, getWithSaga, apiQueue } from './apiService';
import { StoreProduct, STORE_TYPES } from 'types/generals';
import { CartPaymentStore } from 'types/client';

export default class StoreService {
  static formData(product: StoreProduct, productImages: File[] = []) {
    const formData = new FormData();

    const productTag = [
      {
        tagName: product.tagName,
        tagColor: product.tagColor,
        createdAt: new Date(),
      },
    ];

    formData.append('name', product.name);
    formData.append('smallDescription', product.smallDescription);
    formData.append('weight', product.weight);
    formData.append('startDate', product.startDate);
    formData.append('price', product.price.toString());
    formData.append(
      'discount',
      product.discount ? product.discount.toString() : '0',
    );
    formData.append('isVisible', product.isVisible ? 'true' : 'false');
    formData.append('storeType', STORE_TYPES.PREMIUM);
    formData.append('about', product.about ? product.about : '');
    formData.append('keyword', product.keyword ? product.keyword : '');
    formData.append('items', product.items ? product.items : '');
    formData.append('categories', JSON.stringify(product.categories));
    formData.append(
      'specifications',
      product.specifications ? product.specifications : '',
    );
    formData.append('stock', product.stock.toString());
    formData.append('productTag', JSON.stringify(productTag));

    if (product.endDate) formData.append('endDate', product.endDate);

    if (product.sku) formData.append('sku', product.sku);

    productImages.forEach(file => formData.append('images', file));

    return formData;
  }

  static async getStoreProduct(id: number, query: CreateQueryParams) {
    try {
      const data = await getWithSaga(`/store/${id}`, query);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async getStoreProducts(query: CreateQueryParams) {
    try {
      const data = await getWithSaga('/store', query);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async verifyStock(data: { id: number; amount: number }[]) {
    try {
      const response = await api.post('/store/verify-stock', {
        productsData: data,
      });
      return response.data;
    } catch (error) {
      return error.response;
    }
  }

  static async submitStoreOrder(cartPaymentPayload: CartPaymentStore) {
    try {
      const data = await apiQueue.post(
        '/store/create-order',
        cartPaymentPayload,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async createStoreProduct(
    product: StoreProduct,
    productImages: File[] = [],
  ) {
    try {
      const data = await api.post(
        '/store',
        this.formData(product, productImages),
      );
      return data;
    } catch (err) {
      return err;
    }
  }

  static async editStoreProduct(product: StoreProduct, productImages: File[]) {
    try {
      const formData = new FormData();

      const productTag = [
        {
          tagName: product.tagName,
          tagColor: product.tagColor,
          createdAt: new Date(),
        },
      ];

      formData.append('name', product.name);
      formData.append('smallDescription', product.smallDescription);
      formData.append('weight', product.weight);
      formData.append('startDate', product.startDate);
      formData.append('price', product.price.toString());
      formData.append(
        'discount',
        product.discount ? product.discount.toString() : '0',
      );
      formData.append('isVisible', product.isVisible ? 'true' : 'false');
      formData.append('storeType', STORE_TYPES.PREMIUM);
      formData.append('stock', product.stock.toString());
      formData.append('productTag', JSON.stringify(productTag));
      formData.append('about', product.about ? product.about : '');
      formData.append('keyword', product.keyword ? product.keyword : '');
      formData.append('items', product.items ? product.items : '');
      formData.append('categories', JSON.stringify(product.categories));
      formData.append(
        'specifications',
        product.specifications ? product.specifications : '',
      );

      if (product.endDate) formData.append('endDate', product.endDate);

      if (product.sku) formData.append('sku', product.sku);

      if (productImages.length > 0)
        productImages.forEach(file => formData.append('images', file));

      const data = await api.put(`/store/${product.id}`, formData);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async deleteStoreProduct(productId: number) {
    try {
      const data = await api.delete(`/store/${productId}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async deleteProductItem(productId: number, itemId: number) {
    try {
      const data = await api.delete(`/store/${productId}/item/${itemId}`);
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async deleteStoreImage(productId: number, imageId: number) {
    try {
      const data = await api.delete(`/store/${productId}/image/${imageId}`);
      return data;
    } catch (error) {
      return error;
    }
  }
}
