import React from 'react';
import DatePicker from 'react-datepicker';
import pt from 'date-fns/locale/pt';

import 'react-datepicker/dist/react-datepicker.css';
import { createStyles, makeStyles, Theme } from '@material-ui/core';
import { Colors } from 'styles/colors';

interface MyDateRangeFilterProps {
  value: Date[];
  onChange: any;
  buttonProps?: any;
  placeholder?: string;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    filter: {
      width: '100%',
      fontSize: '1.6rem',
      fontWeight: 600,
      backgroundColor: Colors.WHITE,
      outline: 'none',
      border: 'none',
      borderRadius: 13,
      padding: 15,
      '&::placeholder': {
        color: Colors.BLACK,
      },
    },
    calendar: {
      '& .react-datepicker__day-name, .react-datepicker__day': {
        width: '2.6em',
        height: '2.6em',
        fontSize: 16,
        padding: 10,
        color: Colors.BLACK,
      },
      '& .react-datepicker__day--today': {
        color: Colors.BLACK,
        fontWeight: 'bold',
        backgroundColor: 'transparent',
      },
      '& .react-datepicker__day:hover, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range': {
        backgroundColor: Colors.SALMON,
        color: Colors.WHITE,
        borderRadius: '50%',
      },
      '& .react-datepicker__current-month': {
        fontSize: 16,
      },
    },
    wrapper: {
      width: '100%',
    },
  }),
);

const MyDateRangeFilter: React.FC<MyDateRangeFilterProps> = ({
  value,
  onChange,
  placeholder = 'Período',
}) => {
  const classes = useStyles();
  const [startDate, endDate] = value;

  return (
    <DatePicker
      className={classes.filter}
      calendarClassName={classes.calendar}
      wrapperClassName={classes.wrapper}
      placeholderText={placeholder}
      locale={pt}
      selectsRange={true}
      startDate={startDate}
      endDate={endDate}
      dateFormat="dd-MM-yyyy"
      onChange={onChange}
      monthsShown={2}
      isClearable={true}
    />
  );
};

export default MyDateRangeFilter;
