import {
  Signature,
  SIGNATURE_STATUS,
  Order,
  ORDER_STATUS,
  RECURRENCES_MONTHS,
} from 'types/generals';

export const verifyStatusSignature = (signature: Signature) => {
  return (
    signature.status === SIGNATURE_STATUS.INACTIVE ||
    signature.status === SIGNATURE_STATUS.CANCELED
  );
};

export const verifyStatusOrder = (order: Order) => {
  return (
    order.status === ORDER_STATUS.FAILED ||
    order.status === ORDER_STATUS.PENDING
  );
};

export const verifyRecurrence = (recurrence: RECURRENCES_MONTHS) => {
  return recurrence === RECURRENCES_MONTHS.MONTHLY;
};
