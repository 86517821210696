import React, { FC, useEffect, FunctionComponent } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Paper, Typography } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import { orderBy } from 'lodash';

import MyDropdown from 'components/generals/dropdown/MyDropdown';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { AppState } from 'store';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import {
  actUpdatePageTitle,
  closeModal,
  openModal,
} from 'store/ducks/nav/actions';

import Colors from 'styles/colors';
import { useStyles } from '../useStyles';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types/TableTypes';

import ShippingService from 'services/shippingService';
import { ShippingOneState } from 'store/ducks/shipping/types';
import { loadShippingOneRequest } from 'store/ducks/shipping/actions';
import { TRANSPORT_COMPANIES, SHIPPING_TYPE } from 'types/generals';
import { RouteProps, GridTitleProps } from './types';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import LoadingDialog from 'components/generals/dialog/LoadingDialog';
import OrdersTable from './OrdersTable';
import { FeedbackDialogPropsBase } from 'components/generals/dialog/dialogTypes';
import FeedbackWithLinkDialog from 'components/generals/dialog/FeedBackWithLinkDialog';
import {
  SHIPPING_LABELS_SUCCESS,
  SHIPPING_PROCCESS_LIST,
  SHIPPING_SET_SIGEP_LABELS_SUCCESS,
  SHIPPING_SET_SIGEP_LABELS_ERROR,
  SHIPPING_GENERATE_INVOICES_SUCCESS,
  SHIPPING_GENERATE_INVOICES_ERROR,
  SHIPPING_EXPORT_LABELS_SUCCESS,
  SHIPPING_EXPORT_LABELS_ERROR,
  REMOVE_SHIPPING_ERROR,
  REMOVE_SHIPPING_MSG,
} from 'utils/messages';
import { ReactComponent as SuccessIcon } from 'assets/img/svg/icon_active_sucess.svg';
import { ReactComponent as ErrorIcon } from 'assets/img/svg/icon_active_error.svg';
import ShippingDialog, {
  EditShippingDialogPops,
} from 'components/generals/dialog/ShippingDialog';
import ShippingStoreService from 'services/shippingStoreService';
import FilesTable from './FilesTable';
import ShippingLogs from './ShippingLogsTable';
import { UpdateInvoiceOrderInShippingKeyDialogProps } from 'components/generals/dialog/invoicesDialog/types';
import UpdateInvoiceKeyOrderInShippingDialog from 'components/generals/dialog/invoicesDialog/UpdateInvoiceKeyOrderInShippingDialog';

const ShippingDetail: FC = () => {
  const routeParams = useParams<RouteProps>();

  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();

  const { data: shipping, isLoading } = useSelector<AppState, ShippingOneState>(
    ({ shippingReducer }: AppState) => shippingReducer,
  );

  useEffect(() => {
    dispatch(actUpdatePageTitle(`Remessa > ${shipping.name || 'Detalhes'}`));
    dispatch(loadShippingOneRequest(routeParams.id));
  }, [dispatch, shipping.name, routeParams.id]);

  const handleFeedbackModal = (
    title: string,
    message: string,
    icon: FunctionComponent,
  ) => {
    const props: FeedbackDialogPropsBase = {
      icon,
      title,
      message: message,
    };
    dispatch(openModal(FeedbackWithLinkDialog, props));
  };

  const handleExportPdf = async () => {
    const response = await ShippingService.exportLabels(shipping.id);

    if (typeof response === 'object') {
      handleFeedbackModal(
        'Etiquetas em geração',
        SHIPPING_LABELS_SUCCESS,
        SuccessIcon,
      );
    } else {
      handleFeedbackModal(SHIPPING_EXPORT_LABELS_ERROR, response, ErrorIcon);
    }
  };

  const handleExporStoretPdf = async () => {
    dispatch(notifySuccess(SHIPPING_EXPORT_LABELS_SUCCESS(shipping.id)));
    const response = await ShippingStoreService.exportLabels(shipping.id);

    if (typeof response === 'object') {
      handleFeedbackModal(
        'Etiquetas geradas',
        SHIPPING_LABELS_SUCCESS,
        SuccessIcon,
      );
    } else {
      handleFeedbackModal(SHIPPING_EXPORT_LABELS_ERROR, response, ErrorIcon);
    }
  };

  const handleExportXml = () => {
    const createFormated = formatColumnValue(
      FormatOptions.DATETIME,
      new Date(),
    );
    try {
      ShippingService.generateCsvFile(
        shipping.id,
        `${shipping.name}-${createFormated}`,
      );
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleExportStoreXml = () => {
    const createFormated = formatColumnValue(
      FormatOptions.DATETIME,
      new Date(),
    );
    try {
      ShippingStoreService.generateCsvFile(
        shipping.id,
        `${shipping.name}-${createFormated}`,
      );
    } catch (err) {
      throw new Error(err);
    }
  };

  const delShipping = async () => {
    try {
      await ShippingService.removeMultiOrderFromShipping(shipping.id);
      await ShippingService.deleteShipping(shipping.id);

      dispatch(notifySuccess(`${shipping?.name} excluída com sucesso`));
      history.push('/admin/shipping');
    } catch (error) {
      dispatch(notifyError(`Não foi possível fechar a ${shipping?.name}`));
      throw new Error(error);
    }
  };

  const handleDelShipping = () => {
    try {
      dispatch(
        openModal(ConfirmationDialog, {
          title: 'Excluir Remessa',
          message: REMOVE_SHIPPING_MSG(shipping.name),
          actionFn: () => {
            delShipping();
            dispatch(closeModal());
          },
        }),
      );
    } catch (error) {
      dispatch(notifyError(REMOVE_SHIPPING_ERROR));
    }
  };

  const handleCloseShipping = async () => {
    const shippingResponse = await ShippingService.closeShipping(shipping.id);
    if (shippingResponse.statusCode === StatusCodes.BAD_REQUEST) {
      dispatch(
        notifyError(
          `Não foi possivel fechar a remessa. Error: ${shippingResponse.message}`,
        ),
      );
    } else {
      dispatch(
        notifySuccess(`Fechamento da Remessa ${shipping.id} foi inicializado.`),
      );
    }
  };

  const handleGenerateInvoiceShipping = async () => {
    const shippingResponse = await ShippingService.generateInvoicesShipping(
      shipping.id,
    );
    if (shippingResponse.statusCode === StatusCodes.BAD_REQUEST) {
      dispatch(
        notifyError(SHIPPING_GENERATE_INVOICES_ERROR(shippingResponse.message)),
      );
    } else {
      dispatch(notifySuccess(SHIPPING_GENERATE_INVOICES_SUCCESS(shipping.id)));
    }
  };

  const handleGenerateInvoiceStoreShipping = async () => {
    const shippingResponse = await ShippingStoreService.generateInvoicesShipping(
      shipping.id,
    );
    if (shippingResponse.statusCode === StatusCodes.BAD_REQUEST) {
      dispatch(
        notifyError(SHIPPING_GENERATE_INVOICES_ERROR(shippingResponse.message)),
      );
    } else {
      dispatch(notifySuccess(SHIPPING_GENERATE_INVOICES_SUCCESS(shipping.id)));
    }
  };

  const handleSetSigepLabelsOrders = async () => {
    const shippingResponse = await ShippingService.setSigepLabelsOrders(
      shipping.id,
    );
    if (shippingResponse.statusCode === StatusCodes.BAD_REQUEST) {
      dispatch(
        notifyError(SHIPPING_SET_SIGEP_LABELS_ERROR(shippingResponse.message)),
      );
    } else {
      dispatch(notifySuccess(SHIPPING_SET_SIGEP_LABELS_SUCCESS(shipping.id)));
    }
  };

  const handleSetSigepLabelsOrdersStore = async () => {
    const shippingResponse = await ShippingStoreService.setSigepLabelsOrders(
      shipping.id,
    );
    if (shippingResponse.statusCode === StatusCodes.BAD_REQUEST) {
      dispatch(
        notifyError(SHIPPING_SET_SIGEP_LABELS_ERROR(shippingResponse.message)),
      );
    } else {
      dispatch(notifySuccess(SHIPPING_SET_SIGEP_LABELS_SUCCESS(shipping.id)));
    }
  };

  const handlePackingListExport = async () => {
    // Open loading modal
    dispatch(
      openModal(LoadingDialog, {
        title: 'Romaneio',
        message: SHIPPING_PROCCESS_LIST(shipping.id),
      }),
    );
    // Send request to generate a new packing list file
    await ShippingService.exportPackingList(shipping.id);

    // Close modal
    dispatch(closeModal());
  };

  const handlePackingListStoreExport = async () => {
    // Open loading modal
    dispatch(
      openModal(LoadingDialog, {
        title: 'Romaneio',
        message: SHIPPING_PROCCESS_LIST(shipping.id),
      }),
    );
    // Send request to generate a new packing list file
    await ShippingStoreService.exportPackingList(shipping.id);

    // Close modal
    dispatch(closeModal());
  };

  const handleUpdateShippingData = async () => {
    const modalProps: EditShippingDialogPops = {
      title: 'Editar Remessa',
      message: 'Edite as informações desejadas da remessa',
      transport: shipping.transport,
    };

    dispatch(openModal(ShippingDialog, modalProps));
  };

  const handleUpdateInvoiceAccessKey = () => {
    const modalProps: UpdateInvoiceOrderInShippingKeyDialogProps = {
      shippingId: Number(routeParams.id),
    };

    dispatch(openModal(UpdateInvoiceKeyOrderInShippingDialog, modalProps));
  };

  const PageTitle = (props: GridTitleProps) => (
    <Typography variant="h2" color="textPrimary" style={{ fontWeight: 'bold' }}>
      {props.title}
    </Typography>
  );

  const GridActions = () => (
    <Grid item>
      <MyDropdown
        buttonProps={{
          variant: 'contained',
          style: {
            width: '100%',
            borderRadius: 13,
            backgroundColor: Colors.WHITE,
          },
        }}
        menuProps={{
          classes: {
            list: classes.list,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          PaperProps: {
            style: {
              marginTop: '1rem',
              borderRadius: 13,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
        menuItems={[
          {
            title: 'Editar Remessa',
            action: () => handleUpdateShippingData(),
            className: classes.menuOption,
          },
          {
            title: 'Gerar rastreio',
            action: () =>
              shipping.shippingType === SHIPPING_TYPE.SIGNATURE
                ? handleSetSigepLabelsOrders()
                : handleSetSigepLabelsOrdersStore(),
            hidden: shipping.transport !== TRANSPORT_COMPANIES.CORREIOS,
            className: classes.menuOption,
          },
          {
            title: 'Gerar CSV',
            action: () =>
              shipping.shippingType === SHIPPING_TYPE.SIGNATURE
                ? handleExportXml()
                : handleExportStoreXml(),
            className: classes.menuOption,
          },
          {
            title: 'Gerar Etiquetas',
            action: () => handleExportPdf(),
            className: classes.menuOption,
          },
          {
            title: 'Gerar Romaneio',
            action: () =>
              shipping.shippingType === SHIPPING_TYPE.SIGNATURE
                ? handlePackingListExport()
                : handlePackingListStoreExport(),
            className: classes.menuOption,
          },
          {
            title: 'Gerar Notas',
            action: () =>
              shipping.shippingType === SHIPPING_TYPE.SIGNATURE
                ? handleGenerateInvoiceShipping()
                : handleGenerateInvoiceStoreShipping(),
            className: classes.menuOption,
          },
          {
            title: 'Fechar Remessa',
            action: () => handleCloseShipping(),
            className: classes.menuOption,
          },
          {
            title: 'Atualizar chaves NF',
            action: () => handleUpdateInvoiceAccessKey(),
            className: classes.menuOption,
          },
          {
            title: 'Excluir',
            action: () => handleDelShipping(),
            className: classes.menuOption,
          },
        ]}
      >
        <Typography
          variant="h3"
          style={{
            fontSize: '1.6rem',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            width: '100%',
            textAlign: 'left',
          }}
        >
          Ações
        </Typography>
      </MyDropdown>
    </Grid>
  );

  return (
    <Grid container direction="column" className={classes.mTop}>
      <Grid item container alignItems="center" justify="space-between">
        <Grid item md={4} sm={6} xs={4}>
          <PageTitle title="Informações da Remessa" />
        </Grid>
        <Grid container item sm={6} spacing={3} justify="flex-end">
          <GridActions />
        </Grid>
      </Grid>
      {!isLoading && (
        <>
          <Grid container direction="column">
            <Paper className={classes.rootPaper} elevation={0}>
              <Grid container spacing={3}>
                <Grid item sm={4} md={3}>
                  <MyOutlinedTextField
                    isLoading={isLoading}
                    id="shipping-date"
                    name="date"
                    label="Data"
                    disabled
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="standard"
                    value={
                      !isLoading
                        ? formatColumnValue(
                            FormatOptions.DATE,
                            shipping.createdAt,
                          )
                        : undefined
                    }
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid item sm={4} md={3}>
                  <MyOutlinedTextField
                    isLoading={isLoading}
                    id="shipping-status"
                    name="status"
                    label="Status"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="standard"
                    value={!isLoading ? shipping.status : undefined}
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid item sm={4} md={6}>
                  <MyOutlinedTextField
                    isLoading={isLoading}
                    id="shipping-name"
                    name="name"
                    label="Nome"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="standard"
                    value={!isLoading ? shipping.name : undefined}
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid item sm={4} md={3}>
                  <MyOutlinedTextField
                    isLoading={isLoading}
                    id="shipping-transport"
                    name="transport"
                    label="Transportadora"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="standard"
                    value={!isLoading ? shipping.transport : undefined}
                    style={{ width: '100%' }}
                  />
                </Grid>
                <Grid item sm={4} md={3}>
                  <MyOutlinedTextField
                    isLoading={isLoading}
                    id="shipping-type"
                    name="shippingType"
                    label="Tipo de remessa"
                    InputProps={{
                      readOnly: true,
                    }}
                    variant="standard"
                    value={!isLoading ? shipping.shippingType : undefined}
                    style={{ width: '100%' }}
                  />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid className={classes.paddingContainer}>
            <OrdersTable />
          </Grid>
          {/* <Grid className={classes.paddingContainer}>
            <ProductsTable />
          </Grid> */}
          <Grid className={classes.paddingContainer}>
            <FilesTable
              files={orderBy(shipping.files, 'firstOrderId', 'desc')}
            />
          </Grid>
          <Grid className={classes.paddingContainer}>
            <ShippingLogs logs={orderBy(shipping.logs, 'createdAt', 'desc')} />
          </Grid>
        </>
      )}
    </Grid>
  );
};

export default ShippingDetail;
