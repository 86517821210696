import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useSelector } from 'react-redux';

import { useStyles } from '../styles';
import { AppState } from 'store';
import { useFilters } from 'hooks/filters';
import { FormatOptions } from 'types';
import { formatColumnValue } from 'helpers/formatters';
import MyTableCell from 'components/generals/table/MyTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import { RankingState } from 'store/ducks/ranking/types';
import RankingSeasonService from 'services/rankingSeasonService';
import { UserState } from 'store/ducks/user/types';

interface ReferredUsersProps {
  userRanking: any;
}

const ReferredUsers: FC<ReferredUsersProps> = ({ userRanking }) => {
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const { page, handleSetValuePage } = useFilters({});
  const styles = useStyles({});

  const { data: user } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );
  const {
    userRankings: referredUsers,
    activeRanking,
    userRankingTotal: total,
  } = useSelector<AppState, RankingState>(({ ranking }: AppState) => ranking);
  const defaultCount = {
    pendingCount: 0,
    activeCount: 0,
  };
  const [countReferral, setCountReferral] = useState(defaultCount);

  const getPendingReferralCount = useCallback(async () => {
    try {
      if (user && activeRanking) {
        const response = await RankingSeasonService.countReferral(
          user.id,
          activeRanking.id,
        );
        if (response) {
          setCountReferral(response?.data);
        }
      }
    } catch (error) {
      console.error({ error });
      throw error;
    }
  }, [activeRanking, user]);

  useEffect(() => {
    if (userRanking.referralCode) {
      getPendingReferralCount();
    }
  }, [getPendingReferralCount, userRanking.referralCode]);

  return (
    <Grid container direction="column" item xs className={styles.marginTop15}>
      <Grid item xs>
        <Paper component={Grid} className={styles.paperTable} elevation={0}>
          <Grid item xs>
            <Typography variant="h3" color="textPrimary">
              Suas indicações
            </Typography>
            <Grid container direction="row">
              <Typography
                variant="h4"
                color="textSecondary"
                className={styles.activeCounter}
              >
                Confirmadas {countReferral.activeCount}
              </Typography>
              <Typography
                variant="h4"
                color="primary"
                className={styles.pendingCounter}
              >
                Pendentes {countReferral.pendingCount}
              </Typography>
            </Grid>
          </Grid>
          <Grid container direction="column">
            <TableContainer>
              <Table aria-label="subscriptions list">
                <TableHead>
                  <TableRow>
                    <TableCell>Nome</TableCell>
                    <TableCell>E-mail</TableCell>
                    <TableCell>Plano</TableCell>
                    <TableCell>Data</TableCell>
                    <TableCell align="center">Pontuação</TableCell>
                    <TableCell>Status</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {referredUsers && referredUsers.length
                    ? referredUsers.map(referred => (
                        <TableRow key={referred.id}>
                          <MyTableCell>
                            {referred.isActive
                              ? referred?.referralUser?.name
                              : '-'}
                          </MyTableCell>
                          <MyTableCell>
                            {referred.isActive
                              ? referred?.referralUser?.email
                              : '-'}
                          </MyTableCell>
                          <MyTableCell>
                            {referred?.planRecurrence || '-'}
                          </MyTableCell>
                          <MyTableCell>
                            {formatColumnValue(
                              FormatOptions.DATE,
                              referred.createdAt,
                            )}
                          </MyTableCell>
                          <MyTableCell align="center">
                            {referred?.score || '-'}
                          </MyTableCell>
                          <MyTableCell>
                            {referred.isActive ? 'Confirmada' : 'Pendente'}
                          </MyTableCell>
                        </TableRow>
                      ))
                    : ''}
                </TableBody>
              </Table>
              <TablePagination
                ActionsComponent={MyTablePaginationActions}
                component="div"
                count={total}
                page={page}
                labelRowsPerPage="Itens por página"
                onChangePage={(_event: unknown, newPage: number) =>
                  handleSetValuePage(newPage)
                }
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
                  handleSetValuePage(0);
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </TableContainer>
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ReferredUsers;
