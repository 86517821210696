import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';

import { ViewTypes, ViewPayload } from './types';

export const loadViewUsersRequest = (query?: CreateQueryParams) =>
  action(ViewTypes.LOAD_VIEW_USERS_REQUEST, { query });
export const loadViewUsersFailure = () =>
  action(ViewTypes.LOAD_VIEW_USERS_FAILURE);
export const loadViewUsersSuccess = (payload: ViewPayload) =>
  action(ViewTypes.LOAD_VIEW_USERS_SUCCESS, payload);

export const loadViewUsersXlsRequest = (query?: CreateQueryParams) =>
  action(ViewTypes.LOAD_VIEW_USERS_FILE_REQUEST, { query });
export const loadViewUsersXlsRequestSuccess = (payload?: ViewPayload) =>
  action(ViewTypes.LOAD_VIEW_USERS_FILE_REQUEST_SUCCESS, payload);
export const loadViewUsersXlsRequestFailure = (payload?: ViewPayload) =>
  action(ViewTypes.LOAD_VIEW_USERS_FILE_REQUEST_FAILURE, payload);
