import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  dialogSpacing: {
    margin: theme.spacing(2, 0),
  },
  progress: {
    color: Colors.WHITE,
    marginLeft: theme.spacing(1),
  },
});
