import { CondOperator, CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { endOfMonth, startOfMonth, subMonths } from 'date-fns';
import { BoxHome, HomeTypes } from './types';
import { MAX_PREVIOUS_BOXES_HOME } from 'types/generals';

export const loadCurrentBox = () => {
  const currentDate = new Date();
  const initialMonthDay = startOfMonth(currentDate);
  const lastMonthDay = endOfMonth(currentDate);

  const query: CreateQueryParams = {
    filter: [
      {
        field: 'startDate',
        value: [
          initialMonthDay.toLocaleDateString('en-CA'),
          lastMonthDay.toLocaleDateString('en-CA'),
        ],
        operator: CondOperator.BETWEEN,
      },
    ],
    fields: [
      'productId',
      'image',
      'boxDate',
      'name',
      'startDate',
      'isReveal',
      'description',
    ],
    sort: {
      field: 'id',
      order: 'DESC',
    },
    limit: 1,
  };

  return action(HomeTypes.LOAD_CURRENT_BOX, { query });
};

export const loadCurrentBoxDone = (payload: { box: BoxHome }) =>
  action(HomeTypes.LOAD_CURRENT_BOX_DONE, payload);

export const loadCurrentBoxesFail = () =>
  action(HomeTypes.LOAD_CURRENT_BOX_FAIL);

export const loadPreviousBoxes = (currentSubscription: number) => {
  const currentDate = new Date();
  const previousDate = endOfMonth(subMonths(currentDate, 1));

  const previousBoxesQuery: CreateQueryParams = {
    filter: [
      {
        field: 'startDate',
        value: [previousDate.toLocaleDateString('en-CA')],
        operator: CondOperator.LOWER_THAN_EQUALS,
      },
    ],
    fields: ['productId', 'image', 'boxDate', 'name', 'startDate'],
    sort: { field: 'startDate', order: 'DESC' },
    limit: MAX_PREVIOUS_BOXES_HOME,
  };
  return action(HomeTypes.LOAD_PREVIOUS_BOXES, {
    currentSubscription,
    previousBoxesQuery,
  });
};

export const loadPreviousBoxesDone = (payload: { boxes: BoxHome[] }) =>
  action(HomeTypes.LOAD_PREVIOUS_BOXES_DONE, payload);

export const loadPreviousBoxesFail = () =>
  action(HomeTypes.LOAD_PREVIOUS_BOXES_FAIL);
