import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  buttonWrapper: {
    padding: '0.5em 3em',
  },
  buttonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
  },
  clickableArea: {
    cursor: 'pointer',
  },
  headerMargin: {
    marginBottom: theme.spacing(3),
  },
  containerMargin: {
    marginTop: theme.spacing(3),
  },
  menuOption: {
    textTransform: 'capitalize',
  },
  filterOptionsList: {
    backgroundColor: Colors.WHITE,
  },
  mRight: {
    marginRight: '30px',
  },
  table: {
    minWidth: 650,
  },
  paperTable: {
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  rootPaper: {
    padding: theme.spacing(4, 3),
    borderRadius: '2em',
  },
  searchFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  circle: {
    borderRadius: '50%',
  },
  icon: {
    fontSize: 25,
    cursor: 'pointer',
    color: Colors.DARK_GRAY,
  },
});
