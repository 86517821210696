import { StoreSettings } from 'types/client';

export enum StoreSettingsTypes {
  REQUEST_STORE_SETTINGS = '@storeSettings/REQUEST_STORE_SETTINGS',
  REQUEST_ACTIVE_STORE_SETTINGS = '@store/REQUEST_ACTIVE_STORE_SETTINGS',
  LOAD_STORE_SETTINGS_SUCCESS = '@storeSettings/LOAD_STORE_SETTINGS_SUCCESS',
  LOAD_ACTIVE_STORE_SETTINGS_SUCCESS = '@storeSettings/LOAD_ACTIVE_STORE_SETTINGS_SUCCESS',
  FAIL_LOAD_STORE_SETTINGS = '@storeSettings/FAIL_LOAD_STORE_SETTINGS',
  SET_HAS_PROMOTION = '@storeSettings/SET_HAS_PROMOTION',
  SET_IS_MAINTENANCE = '@storeSettings/SET_IS_MAINTENANCE',
  SET_IS_PROMOTIONAL_PERIOD = '@storeSettings/SET_IS_PROMOTIONAL_PERIOD',
  LOAD_VERIFY_HAS_PROMOTION = '@storeSettings/LOAD_VERIFY_HAS_PROMOTION',
  INIT_REQUEST = '@storeSettings/INIT_REQUEST',
  END_REQUEST = '@storeSettings/END_REQUEST',
}

export interface StoreSettingsState {
  readonly settings: StoreSettings[];
  readonly activeSettings: StoreSettings;
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly hasPromotion: boolean;
  readonly isPromotionalPeriod: boolean;
  readonly isMaintenance: boolean;
}
