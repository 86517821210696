import { CreditCard } from 'types/CreditCard';
import { Address } from 'types/generals/address';
import { Roles } from './roles';
import { TRANSPORT_COMPANIES } from './shipping';
import {
  ACTIVE_SIGNATURE_STATUS,
  INACTIVE_SIGNATURE_STATUS,
} from './signature';

export enum BULK_ACTIONS_STATUS {
  noAction = 'Sem ação',
  isChangingRenewDate = 'Trocar data renovação',
  isRetryingPayment = 'Cobrar pedido',
  isCreateOrder = 'Criar pedido',
  isChangePlan = 'Trocar plano',
  isGenerateReportBankslip = 'Gerar link boleto',
  isAnticipateRenew = 'Antecipar renovação',
  isSendingBankSlip = 'Enviar notificações de boleto',
}

export const USER_TYPE = [
  { title: 'Pessoa física', value: 'PF' },
  { title: 'Pessoa jurídica', value: 'PJ' },
];
export interface UserMetadata {
  imageLink: string;
}
export interface User {
  id: number;
  name: string;
  email: string;
  createdAt: string;
  updatedAt: string;
  isAdmin: boolean;
  isPremium: boolean;
  identification: string;
  type: string;
  phone: string;
  address: Address[];
  defaultAddress: Address;
  defaultCreditCard: CreditCard;
  countPaidMonths: number;
  status: ACTIVE_SIGNATURE_STATUS | INACTIVE_SIGNATURE_STATUS;
  hasPlanner: boolean;
  hasGift: boolean;
  hasMonthBox: boolean;
  currentSubscription?: number;
  fixedTransportMethod: TRANSPORT_COMPANIES | null;
  roles: Roles[];
  metadata: UserMetadata;
}

export interface UserLead extends User {
  step: number;
  registrationCompleted: boolean;
  plan: string;
  planName: string;
  planRecurrence: string;
}

export interface StaffPayload {
  name: string;
  email: string;
  password: string;
  roles: Partial<Roles>[];
}
export interface UserUpdate {
  name: string;
  email: string;
  phone: string;
  identification: string;
  photo: any[];
  metadata: UserMetadata;
}
