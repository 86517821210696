import { action } from 'typesafe-actions';
import { NotificationTypes } from './types';
import { SnackbarKey } from 'notistack';

export const notifyClose = (key: SnackbarKey) =>
  action(NotificationTypes.REMOVE, { key });

export const notifyError = (message: string) =>
  action(NotificationTypes.ENQUEUE, {
    message,
    key: new Date().getTime() + Math.random(),
    options: {
      variant: 'error',
    },
  });

export const notifySuccess = (message: string) =>
  action(NotificationTypes.ENQUEUE, {
    message,
    key: new Date().getTime() + Math.random(),
    options: {
      variant: 'success',
    },
  });

export const notifySignature = (isNotified: boolean) => {
  return action(NotificationTypes.IS_NOTIFIED, { isNotified });
};

export const disableButton = () => {
  return action(NotificationTypes.DISABLED_BUTTON);
};

export const enableButton = () => {
  return action(NotificationTypes.ENABLE_BUTTON);
};

export const setBankslipIsProcessing = () => {
  return action(NotificationTypes.BANKSLIP_IS_PROCESSING);
};

export const setBankslipNotIsProcessing = () => {
  return action(NotificationTypes.BANKSLIP_IS_NOT_PROCESSING);
};
