import { call, put } from 'redux-saga/effects';
import VideoService from 'services/videoService';
import { loadAllVideosFailure, loadAllVideosSuccess } from './actions';

export function* loadVideoListSaga(action: any) {
  try {
    const { data, total } = yield call(
      VideoService.getAllVideos,
      action.payload,
    );
    yield put(loadAllVideosSuccess({ data, total }));
  } catch (err) {
    yield put(loadAllVideosFailure());
  }
}
