import { Reducer } from 'redux';
import { Signature } from 'types/generals';
import { SignatureTypes, SingleSignatureState } from '../types';

const INITIAL_STATE: SingleSignatureState = {
  isLoading: true,
  hasError: false,
  data: {} as Signature,
};

const reducer: Reducer<SingleSignatureState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case SignatureTypes.LOAD_USER_SIGNATURE_REQUEST:
    case SignatureTypes.LOAD_SIGNATURE_REQUEST:
      return {
        isLoading: true,
        hasError: false,
        data: {} as Signature,
      };
    case SignatureTypes.LOAD_SIGNATURE_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload.data,
      };
    case SignatureTypes.LOAD_SIGNATURE_FAILURE:
      return {
        isLoading: false,
        hasError: true,
        data: {} as Signature,
      };
    case SignatureTypes.CLEAR_SIGNATURE:
      return {
        ...state,
        data: {} as Signature,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default reducer;
