import { Reducer } from 'redux';
import produce from 'immer';
import { BoxHome, HomeState, HomeTypes } from './types';

const INITIAL_STATE: HomeState = {
  currentBox: {} as BoxHome,
  previousBoxes: [],
  isLoading: true,
  hasError: false,
};

const reducer: Reducer<HomeState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case HomeTypes.LOAD_CURRENT_BOX:
      return produce(state, draft => {
        draft.currentBox = {} as BoxHome;
        draft.isLoading = true;
        draft.hasError = false;
      });
    case HomeTypes.LOAD_PREVIOUS_BOXES:
      return produce(state, draft => {
        draft.previousBoxes = [];
        draft.isLoading = true;
        draft.hasError = false;
      });
    case HomeTypes.LOAD_CURRENT_BOX_DONE:
      return produce(state, draft => {
        draft.currentBox = action.payload.box;
        draft.hasError = draft.isLoading = false;
      });
    case HomeTypes.LOAD_PREVIOUS_BOXES_DONE:
      return produce(state, draft => {
        draft.previousBoxes = action.payload.boxes;
        draft.hasError = draft.isLoading = false;
      });
    case HomeTypes.LOAD_CURRENT_BOX_FAIL:
      return produce(state, draft => {
        draft.hasError = true;
        draft.isLoading = false;
        draft.currentBox = {} as BoxHome;
      });
    case HomeTypes.LOAD_PREVIOUS_BOXES_FAIL:
      return produce(state, draft => {
        draft.hasError = true;
        draft.isLoading = false;
        draft.previousBoxes = [];
      });
    default:
      return state;
  }
};

export default reducer;
