import { makeStyles } from '@material-ui/core';

const drawerWidth = 441;

export const useStyles = makeStyles(theme => ({
  drawer: {
    width: drawerWidth,
  },
  drawerPaper: {
    [theme.breakpoints.down('xs')]: {
      width: '90%',
    },
    width: drawerWidth,
    padding: '50px 0px',
    borderRadius: '25px 0px',
  },
}));
