export const PRODUCT_ADDED = (productName: string) =>
  `${productName} foi adicionado ao seu carrinho com sucesso.`;
export const DELETE_PRODUCT_FAIL =
  'Houve uma falha ao excluir o produto desejado';
export const PRODUCT_REGISTER_SUCCESS = 'Produto cadastrado com sucesso';
export const PRODUCT_REGISTER_FAIL =
  'Não foi possível cadastrar o produto na loja';
export const PRODUCT_UPDATE_SUCCESS = 'Produto atualizado com sucesso';
export const PRODUCT_UPDATE_FAIL =
  'Não foi possível atualizar o produto desejado';
export const ORDER_SUBMIT_SUCCESS = 'O seu pedido foi emitido com sucesso';
export const ORDER_SUBMIT_SUCCESS_BANKSLIP =
  'Pedido realizado com sucesso, em instantes você receberá um email com o boleto de pagamento.';
export const ORDER_SUBMIT_FAIL =
  'Houve uma falha ao emitir o seu pedido. Por favor, tente novamente';
export const DELETE_PRODUCT_ITEM_SUCCESS = 'Item removido com sucesso';
export const DELETE_PRODUCT_ITEM_FAIL =
  'Houve uma falha ao deletar o item do produto';
export const DELETE_PRODUCT_IMAGE_FAIL =
  'Houve uma falha ao deletar a imagem do produto';
export const PRODUCT_STORE_NOT_STOCK =
  'A quantidade que você adicionou não está disponível no estoque. Tente novamente.';
export const CHANGE_PLAN_STORE_ACCESS =
  'Para ter acesso a loja premium agora, migre seu plano para o plano anual por aqui.';
export const SUBSCRIBER_BANKSLIP_NO_ACCESS_STORE =
  'É necessário que você efetue o pagamento do seu boleto para ter acesso aos descontos da Black Week. Obs.: Se você já realizou o pagamento do seu boleto, aguarde até 72h. A Loja será liberada assim que recebermos a compensação.';
export const SUBSCRIBER_CARD_NO_ACCESS_STORE =
  'É necessário que sua assinatura esteja ativa para ter acesso à loja premium.';
export const SUBSCRIBER_INACTIVE_NO_ACCESS_STORE =
  'É necessário que você reative a sua assinatura para ter acesso aos descontos da Black Week';
export const NEW_CATEGORY_ERROR =
  'Não foi possível adicionar uma nova categoria';
export const NEW_CATEGORY_SUCCESS = 'Sua categoria foi cadastrada com sucesso';
export const SAVE_CATEGORY_ERROR = 'Não foi possível salvar a categoria';
export const SAVE_CATEGORY_SUCCESS = 'Sua categoria foi salva com sucesso';
export const REMOVE_CATEGORY_SUCCESS = 'Categoria removida com sucesso';
export const REMOVE_CATEGORY_ERROR = 'Não foi possível remover a categoria';

export const REMOVE_CATEGORY_MSG = (value: string) =>
  `Tem certeza que deseja excluir a categoria "${value}"? Ao confirmar essa ação não é possível reverter.`;
