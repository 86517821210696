import { action } from 'typesafe-actions';

import { CreditCardTypes } from './types';
import { CreditCard } from 'types/CreditCard';

export const loadCreditCards = () => {
  return action(CreditCardTypes.LOAD_CREDIT_CARD_REQUEST);
};

export const loadCreditCardsDone = (payload: any) => {
  return action(CreditCardTypes.LOAD_CREDIT_CARD_SUCCESS, payload);
};

export const loadCreditCardsFail = () => {
  return action(CreditCardTypes.LOAD_CREDIT_CARD_FAIL);
};

export const updateCreditCard = (cardData: CreditCard) => {
  return action(CreditCardTypes.LOAD_CREDIT_CARD_PATCH, cardData);
};
