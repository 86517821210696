import { makeStyles, Switch, styled, Typography } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

export const useStyles = makeStyles({
  gridHeader: {
    padding: '0px 30px 0 35px',
  },
  notificationBlock: {
    marginTop: '24px',
  },
  notificationBlockTitle: {
    margin: '12px',
    fontWeight: 700,
  },
  notificationRowTitle: {
    fontWeight: 600,
  },
  notificationRowOptionTitle: {
    fontWeight: 400,
  },
  notificationRowOptionSwitch: {
    color: Colors.RED,
  },
  rootPaper: {
    padding: theme.spacing(4, 3),
    borderRadius: '2em',
  },
  marginRoot: {
    marginTop: 40,
    marginBottom: 10,
  },
  buttonWrapper: {
    padding: '0.5em 3em',
  },
  buttonEditText: {
    color: Colors.RED,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'unset',
    },
  },
  buttonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
  },
  buttonText: {
    color: Colors.RED,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  bannerTitle: {
    marginTop: 40,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  footerGridContainer: {
    marginTop: 20,
    textAlign: 'right',
  },
});

export const MySwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: Colors.LIGHT_GRAY,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
