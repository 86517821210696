import React from 'react';
import {
  Grid,
  DialogContent,
  DialogTitle,
  Typography,
  CircularProgress,
} from '@material-ui/core';

import { useDialogStyles } from './useStyles';
import { BaseDialogProps } from './dialogTypes';

const LoadingDialog: React.FC<BaseDialogProps> = props => {
  const classes = useDialogStyles();
  const { title, message } = props;

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="center" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ marginTop: 6 }} spacing={2}>
          <Grid container justify="center">
            <CircularProgress size={50} />
          </Grid>
          <Grid container justify="center" style={{ marginTop: 40 }}>
            <Typography
              variant="h3"
              color="textPrimary"
              className={classes.info_mb}
            >
              {message}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
    </Grid>
  );
};

export default LoadingDialog;
