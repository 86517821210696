import React, { FC } from 'react';

import { Controller } from 'react-hook-form';
import SubscriberColumn from 'pages/admin/manager/subscribers/SubscriberDetail/ClientForm/SubscriberColumn';
import { Container, SectionName, StyledPaper } from '../styles';
import { ResendAddressProps } from '../types';
import { FormControlLabel, Grid } from '@material-ui/core';
import MySwitch from 'components/generals/input/MySwitch';
import AddressForm from 'components/generals/addressForm';
import InputField from 'components/generals/input/MyOutlinedTextField';
import MySelectForm from 'components/generals/input/MySelectForm';
import { TRANSPORT_COMPANIES } from 'types/generals';

const ResendAddress: FC<ResendAddressProps> = ({
  control,
  errors,
  register,
  reset,
  canSaveAddress,
  handleSaveAddress,
}) => (
  <StyledPaper elevation={0}>
    <SectionName>Dados do Cliente</SectionName>
    <Container container spacing={2}>
      <SubscriberColumn control={control} errors={errors} register={register} />
      <AddressForm
        xsValue={6}
        control={control}
        errors={errors}
        reset={reset}
        register={register}
      />
      <Container spacing={2} container item xs={12} direction="row">
        <Grid item xs>
          <Controller
            name="resendReason"
            control={control}
            as={({ value, onChange }) => (
              <InputField
                fullWidth
                rows={8}
                multiline
                id="resend-reason"
                label="Motivo"
                name="resendReason"
                onChange={onChange}
                value={value || ''}
                inputRef={register}
                error={Boolean(errors.resendReason)}
                helperText={
                  errors.resendReason ? errors.resendReason.message : null
                }
              />
            )}
          />
        </Grid>
        <Grid item xs>
          <Controller
            name="transport"
            control={control}
            as={({ onChange, onBlur, value }) => (
              <MySelectForm
                contracted
                title="Transportadora"
                itens={Object.values(TRANSPORT_COMPANIES).map(transport => ({
                  title: transport,
                  value: transport,
                }))}
                onChange={onChange}
                onBlur={onBlur}
                value={value || TRANSPORT_COMPANIES.CORREIOS}
                error={Boolean(errors.transport)}
                helperText={errors.transport ? errors.transport.message : null}
              />
            )}
          />
        </Grid>
      </Container>

      <Container
        container
        spacing={2}
        item
        xs
        direction="row"
        justify="flex-end"
      >
        <FormControlLabel
          name="canSaveAddress"
          control={
            <MySwitch
              name="canSaveAddress"
              color="primary"
              checked={canSaveAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                handleSaveAddress(e.target.checked)
              }
            />
          }
          label="Salvar endereço"
          labelPlacement="start"
        />
      </Container>
    </Container>
  </StyledPaper>
);

export default ResendAddress;
