import { Grid, makeStyles, Paper, styled, Typography } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  circularProgressContainer: {
    overflow: 'hidden',
    textAlign: 'center',
    padding: 3,
  },
});

export const PageTitle = styled(Typography)({
  marginTop: '35px',
  marginBottom: '35px',
  fontSize: '25px',
  color: Colors.DARKER_GRAY,
  fontWeight: 600,
});

export const StyledPaper = styled(Paper)({
  padding: '24px 12px',
  borderRadius: 30,
  marginBottom: '18px',
});

export const Container = styled(Grid)({
  padding: '24px 12px',
});

export const SectionName = styled(Typography)({
  fontSize: 18,
  color: Colors.MEDIUM_GRAY,
  padding: '0 12px',
});
