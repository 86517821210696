import { call, put } from 'redux-saga/effects';
import SignatureService from 'services/signatureService';
import { getOneSignatureQuery } from 'utils/queries/signature';
import {
  loadOneSignatureFail,
  loadOneSignatureSuccess,
  loadOneSignature,
} from './actions';

export function* loadSignatureSaga(action: any) {
  try {
    const response = yield call(
      SignatureService.getSignature,
      action.payload.signatureId,
      action.payload.query,
    );
    yield put(
      loadOneSignatureSuccess({
        data: {
          ...response,
        },
      }),
    );
  } catch (err) {
    yield put(loadOneSignatureFail());
  }
}

export function* loadUserSignatureSaga(action: any) {
  try {
    const response = yield call(
      SignatureService.currentSignatureUser,
      action.payload,
    );
    yield put(
      loadOneSignatureSuccess({
        data: {
          ...response,
        },
      }),
    );
  } catch (err) {
    yield put(loadOneSignatureFail());
  }
}

export function* requestCancelSignatureSaga(action: any) {
  try {
    const { signatureId, message } = action.payload;
    yield call(SignatureService.requestCancelSignature, signatureId, message);
    yield put(loadOneSignature(signatureId, getOneSignatureQuery()));
  } catch (error) {
    yield put(loadOneSignatureFail());
  }
}

export function* reactivateSignatureSaga(action: any) {
  try {
    const { signatureId } = action.payload;
    yield call(SignatureService.reactivateSignature, signatureId);
    yield put(loadOneSignature(signatureId, getOneSignatureQuery()));
  } catch (error) {
    yield put(loadOneSignatureFail());
  }
}

export function* cancelSignatureSaga(action: any) {
  try {
    const { signatureId } = action.payload;
    yield call(SignatureService.cancelSignature, signatureId);
    yield put(loadOneSignature(signatureId, getOneSignatureQuery()));
  } catch (error) {
    yield put(loadOneSignatureFail());
  }
}
