import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Colors from 'styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageWrapper: {
      maxWidth: '100%',
      padding: 0,
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        height: '100%',
        backgroundColor: theme.palette.grey['100'],
      },
      backgroundColor: Colors.WHITE_SMOKE,
    },
    formWrapper: {
      padding: '12px 20px',
      [theme.breakpoints.up('md')]: {
        maxWidth: '1200px',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: 24,
        overflow: 'auto',
        backgroundColor: theme.palette.grey['100'],
      },
    },
  }),
);

export const commonStyles = makeStyles(theme =>
  createStyles({
    stepWrapper: {
      height: 'auto',
      backgroundColor: Colors.WHITE,
      marginBottom: 20,
      padding: '12px 20px',
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        padding: 16,
        borderRadius: 16,
      },
    },
    stepWrapperDisabled: {
      height: 'auto',
      backgroundColor: Colors.WHITE_SMOKE,
      marginBottom: 20,
      padding: '12px 20px',
      [theme.breakpoints.up('md')]: {
        marginBottom: 0,
        padding: 16,
        borderRadius: 16,
      },
    },
    wrapperTitle: {
      margin: '4px 0 12px 0',
      backgroundColor: theme.palette.grey['100'],
      borderRadius: 16,
      padding: '11px 20px',
      [theme.breakpoints.up('md')]: {
        backgroundColor: Colors.WHITE_SMOKE,
        padding: '11px 4px',
      },
    },
    titleButton: {
      borderRadius: 20,
      height: 24,
      width: 24,
      fontSize: 16,
      fontWeight: 500,
      backgroundColor: theme.palette.primary.main,
      color: Colors.WHITE,
      textAlign: 'center',
      marginRight: 12,
    },
    title: {
      fontSize: 14,
      color: theme.palette.grey['500'],
      textTransform: 'uppercase',
      fontWeight: 'bold',
    },
    inputWrapper: {
      marginBottom: 8,
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 17px)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(0.75)',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 8,
        height: 48,
      },
    },
    selectTitleWrapper: {
      paddingTop: 0,
    },
    selectTitle: {
      color: theme.palette.grey['500'],
      fontSize: 14,
      fontWeight: 700,
      marginLeft: 4,
      marginBottom: 8,
    },
    rootBottom: {
      marginBottom: 30,
    },
    input: {
      padding: '20px 12px 10px',
    },
    label: {
      color: Colors.DARK_GRAY,
    },
    buttonSubmitUserLead: {
      marginTop: 15,
      height: 44,
    },
    buttonSubmitUserLeadUpdate: {
      border: `1px solid ${Colors.WHITE_GRAY}`,
      '&:hover': {
        backgroundColor: Colors.WHITE_SMOKE,
      },
    },
    buttonSubmitUserLeadInit: {
      background: Colors.BRIGHT_DARK_GREEN,
      '&:hover': {
        backgroundColor: Colors.BRIGHT_DARK_GREEN,
      },
    },
    textSubmitUserLeadUpdate: {
      fontSize: 16,
      fontWeight: 700,
      color: Colors.ORANGE,
    },
    textSubmitUserLeadInit: {
      fontSize: 16,
      fontWeight: 700,
      color: Colors.WHITE,
    },
    termsAndConditionsDetail: {
      color: Colors.BLACK,
      fontWeight: 800,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    termsAndConditionsText: {
      textAlign: 'center',
      fontWeight: 600,
      fontSize: 12,
      lineHeight: '18px',
    },
  }),
);

export default commonStyles;
