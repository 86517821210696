import { CreateQueryParams } from '@nestjsx/crud-request';
import { ROOT_PATH } from 'services/fileservice';
import { StoreCategory } from 'types/generals';
import { action } from 'typesafe-actions';
import { CategoriesTypes } from './types';

export const loadCategoryRequest = (query: CreateQueryParams) =>
  action(CategoriesTypes.LOAD_REQUEST, { query });

export const loadCategoryFile = (
  query: CreateQueryParams,
  rootPath: ROOT_PATH,
) => {
  return action(CategoriesTypes.LOAD_FILE_REQUEST, {
    query,
    rootPath,
  });
};

export const loadCategoryFailure = () => action(CategoriesTypes.LOAD_FAILURE);

export const loadCategorySuccess = (payload: {
  total: number;
  data: StoreCategory[];
}) => action(CategoriesTypes.LOAD_SUCCESS, payload);
