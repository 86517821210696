import { Grid, makeStyles, styled } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';
import { TagProps } from './types';

export const Tag = styled(Grid)((props: TagProps) => ({
  padding: theme.spacing(0.5, 1.6),
  margin: theme.spacing(1, 1, 1, 0),
  width: props.fullwidth ? '100%' : 'min-content',
  minWidth: '50px',
  maxWidth: props.fullwidth ? '100%' : '180px',
  whiteSpace: 'nowrap',
  borderRadius: '20px',
  backgroundColor: `${props.config.tagBackground || Colors.SALMON}`,
  color: `${props.config.tagColor || Colors.WHITE}`,
  fontSize: '13px',
  fontWeight: 600,
  justifyContent: 'space-around',
  alignItems: 'center',
}));

export const useStyles = makeStyles({
  inlineTag: {
    display: 'inline-flex',
  },
});
