import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { ItemLabelField } from './types';

const ItemLabel: FC<ItemLabelField> = ({ name, subtitle }) => {
  return (
    <Grid container item direction="column">
      <Typography variant="h4">{name}</Typography>
      {subtitle ? (
        <Typography style={{ fontSize: '13px' }}>{subtitle}</Typography>
      ) : null}
    </Grid>
  );
};

export default ItemLabel;
