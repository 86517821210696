import { call, put } from 'redux-saga/effects';
import BannerService from 'services/bannerService';
import { loadBannersFailure, updateBanners } from './actions';

export function* loadBannersSaga(action: any) {
  try {
    const { data } = yield call(
      BannerService.getAllBanners,
      action.payload.query,
    );
    yield put(updateBanners(data));
  } catch (err) {
    yield put(loadBannersFailure());
  }
}
