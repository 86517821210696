import * as Yup from 'yup';
import { head } from 'lodash';
import { ItemLabelField } from 'components/generals/labels/ItemLabel/types';
import { TRANSPORT_COMPANIES, User } from 'types/generals';
import { FormatOptions } from 'types';
import { formatColumnValue } from 'helpers/formatters';
import { identification } from 'utils/formYupFields';

export const fixedTransports = (): ItemLabelField[] => {
  const transportMethods: string[] = [
    'Nenhuma',
    ...Object.values(TRANSPORT_COMPANIES),
  ];

  return transportMethods.map<ItemLabelField>((transport, id) => ({
    id,
    name: transport,
  }));
};

export const validationSchema = Yup.object({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  memberSince: Yup.string().required(),
  phone: Yup.string().required('Telefone obrigatório'),
  identification,
  address: Yup.object({
    zipcode: Yup.string().required('Cep obrigatório'),
    street: Yup.string().required('Endereço obrigatório'),
    number: Yup.string().required('Número obrigatório'),
    neighborhood: Yup.string().required('Bairro obrigatório'),
    city: Yup.string().required('Cidade obrigatório'),
    state: Yup.string()
      .max(2, 'Só é permitido 2 dígitos, ex: RS')
      .required('Estado obrigatório'),
    complement: Yup.string(),
  }),
  fixedTransport: Yup.string(),
});

export const defaultValue = (user: User) => {
  return {
    name: user?.name || '',
    email: user?.email || '',
    memberSince: user?.createdAt
      ? formatColumnValue(FormatOptions.DATE, user.createdAt)
      : '',
    phone: user?.phone || '',
    identification: user?.identification || '',
    address: {
      zipcode: user?.defaultAddress?.zipcode || '',
      street: user?.defaultAddress?.street || '',
      number: user?.defaultAddress?.number || '',
      neighborhood: user?.defaultAddress?.neighborhood || '',
      city: user?.defaultAddress?.city || '',
      state: user?.defaultAddress?.state || '',
      complement: user?.defaultAddress?.complement || '',
    },
    fixedTransport: user?.fixedTransportMethod || head(fixedTransports())?.name,
  };
};
