import React, { FC, useMemo, memo } from 'react';
import { Grid, Paper, Button } from '@material-ui/core';

import MyTypography from 'components/generals/labels/MyTypography';
import { useStyles } from './styles';
import { OrderItemProps } from './types';
import { PAYMENT_TYPE, PAYMENT_STATUS } from 'types/generals/payment';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { PAYMENT_NOT_FOUND_ORDER } from 'utils/messages';

const PaymentData: FC<OrderItemProps> = memo(({ order }) => {
  const styles = useStyles();
  const DUE_DATE_BOLETO =
    order.orderDetail.paymentType === PAYMENT_TYPE.BANKSLIP;

  const lastPayment = useMemo(() => order.orderLastPayment, [order]);

  const formatPaymentDescription = lastPayment?.description?.split('-') || [
    PAYMENT_NOT_FOUND_ORDER,
  ];

  const orderDetail = useMemo(() => order.orderDetail, [order.orderDetail]);

  let paymentType: string;

  switch (orderDetail.paymentType) {
    case PAYMENT_TYPE.BANKSLIP:
      paymentType = 'Boleto';
      break;
    case PAYMENT_TYPE.CARD:
      paymentType = 'Cartão';
      break;
  }

  const formatInstallments = (amount: number, installments: number) => {
    if (installments > 1) {
      return formatColumnValue(
        FormatOptions.MONEY,
        Number((amount / installments).toFixed(2)),
      );
    }

    return formatColumnValue(FormatOptions.MONEY, amount);
  };

  const handleGoToBankSlip = () => {
    const { orderLastPayment } = order;
    const { bankSlipTransaction } = orderLastPayment;

    const win = window.open(bankSlipTransaction?.url, '_blank');
    if (win) win.focus();
  };

  const PaymentGrid = () => (
    <>
      <Grid className={styles.mInfo}>
        <MyTypography className={styles.orderInfoTitle}>
          Forma de Pagamento
        </MyTypography>
        {DUE_DATE_BOLETO ? (
          <MyTypography className={styles.mRight}>
            {paymentType}

            <Button
              className={styles.actionButtonBankSlip}
              onClick={() => handleGoToBankSlip()}
            >
              <MyTypography
                variant="subtitle1"
                className={styles.trackingInfoBankSlip}
              >
                Ver boleto
              </MyTypography>
            </Button>
          </MyTypography>
        ) : (
          <MyTypography className={styles.mRight}>{paymentType}</MyTypography>
        )}
      </Grid>
      {DUE_DATE_BOLETO ? (
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>
            Data de Vencimento
          </MyTypography>
          <MyTypography className={styles.mRight}>
            {lastPayment?.bankSlipTransaction?.dueDate || '-'}
          </MyTypography>
        </Grid>
      ) : (
        <>
          <Grid className={styles.mInfo}>
            <MyTypography className={styles.orderInfoTitle}>
              Status Operadora
            </MyTypography>
            <MyTypography className={styles.mRight}>
              {lastPayment.status}
            </MyTypography>
          </Grid>
          <Grid className={styles.mInfo}>
            <MyTypography
              variant="h4"
              color="textPrimary"
              className={styles.orderInfoTitle}
            >
              {lastPayment.status === PAYMENT_STATUS.FAILED
                ? 'Motivo'
                : 'Cartão'}
            </MyTypography>
            {lastPayment.status === PAYMENT_STATUS.FAILED ? (
              <>
                {formatPaymentDescription.map(description => (
                  <MyTypography className={styles.mRight}>
                    {description}
                  </MyTypography>
                ))}
              </>
            ) : (
              <>
                <MyTypography className={styles.mRight}>
                  {lastPayment?.cardTransaction?.cardNumber || ''}
                </MyTypography>

                <MyTypography className={styles.mRight}>
                  {`${lastPayment?.cardTransaction?.installments || 1}x `}
                  <strong>
                    {formatInstallments(
                      orderDetail?.amount || 0,
                      lastPayment?.cardTransaction?.installments || 1,
                    )}
                  </strong>
                </MyTypography>
              </>
            )}
          </Grid>
        </>
      )}
    </>
  );

  const ClientGrid = () => (
    <Grid container direction="row" justify="space-between">
      <Grid item xs>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>Nome</MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.userName}
          </MyTypography>
        </Grid>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>E-mail</MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.userEmail}
          </MyTypography>
        </Grid>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>CPF</MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.userIdentification}
          </MyTypography>
        </Grid>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>
            Telefone
          </MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.userPhone}
          </MyTypography>
        </Grid>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>
            Transporte
          </MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.transport}
          </MyTypography>
        </Grid>
      </Grid>
      <Grid item xs>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>
            Endereço
          </MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.street}
          </MyTypography>
        </Grid>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>Bairro</MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.neighborhood}
          </MyTypography>
        </Grid>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>
            Complemento
          </MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.complement}
          </MyTypography>
        </Grid>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>Cidade</MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.city}
          </MyTypography>
        </Grid>
        <Grid className={styles.mInfo}>
          <MyTypography className={styles.orderInfoTitle}>CEP</MyTypography>
          <MyTypography className={styles.mRight}>
            {orderDetail.zipcode}
          </MyTypography>
        </Grid>
      </Grid>
    </Grid>
  );

  return (
    <Grid item xs={12} style={{ marginTop: '25px' }}>
      <Paper className={styles.paperTable} elevation={0}>
        {orderDetail ? (
          <Grid container direction="row">
            <Grid container item direction="column" xs={12} md={6}>
              <MyTypography variant="h3" className={styles.paperInfoTitle}>
                Dados do Pagamento
              </MyTypography>
              {lastPayment ? (
                <Grid item xs>
                  <PaymentGrid />
                </Grid>
              ) : null}
            </Grid>
            <Grid container item direction="column" xs={12} md={6}>
              <MyTypography variant="h3" className={styles.paperInfoTitle}>
                Dados do Cliente
              </MyTypography>
              <Grid item xs>
                <ClientGrid />
              </Grid>
            </Grid>
          </Grid>
        ) : null}
      </Paper>
    </Grid>
  );
});

export default PaymentData;
