import { action } from 'typesafe-actions';
import { BenefitsTypes } from './types';
import { Benefits } from 'types/generals/benefits';
import { CreateQueryParams } from '@nestjsx/crud-request';

export const loadBenefits = (query?: CreateQueryParams) => {
  return action(BenefitsTypes.LOAD_BENEFITS_REQUEST, { query });
};

export const loadBenefitsSuccess = (payload: {
  total: number;
  data: Benefits[];
}) => {
  return action(BenefitsTypes.LOAD_BENEFITS_SUCCESS, payload);
};

export const loadBenefitsFailure = () => {
  return action(BenefitsTypes.LOAD_BENEFITS_ERROR);
};
