import { CreateQueryParams } from '@nestjsx/crud-request';
import { ROOT_PATH } from 'services/fileservice';
import { Coupon } from 'types/generals/coupon';
import { action } from 'typesafe-actions';
import { CouponsTypes } from './types';

export const loadCouponRequest = (query: CreateQueryParams) =>
  action(CouponsTypes.LOAD_REQUEST, { query });

export const loadCouponFile = (
  query: CreateQueryParams,
  rootPath: ROOT_PATH,
) => {
  return action(CouponsTypes.LOAD_COUPON_FILE_REQUEST, { query, rootPath });
};

export const loadCouponFailure = () => action(CouponsTypes.LOAD_FAILURE);

export const loadCouponSuccess = (payload: { total: number; data: Coupon[] }) =>
  action(CouponsTypes.LOAD_SUCCESS, payload);
