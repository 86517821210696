import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import useRegisterStyles from 'components/generals/forms/register/styles';
import { useStyles } from '../styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { StatusCodes } from 'http-status-codes';
import React, { ChangeEvent, FC, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { defaultValues, validationSchema } from '../utils';

import { EnhancedProduct } from 'types/generals/enhancedProduct';
import MyDatePicker from 'components/generals/input/MyDatePicker';
import MySwitch from 'components/generals/input/MySwitch';
import MyTypography from 'components/generals/labels/MyTypography';
import AutoCompleteSearch from 'components/generals/autoCompleteProducts';
import EnhancedProductService from 'services/enhancedProductService';
import {
  ENHANCED_PRODUCT_UPDATE_SUCCESS,
  NEW_ENHANCED_PRODUCT_ERROR,
  NEW_ENHANCED_PRODUCT_SUCCESS,
} from 'utils/messages/enhancedProduct';
import MyNumberFormat from 'components/generals/input/MyNumberFormat';
import { format } from 'date-fns';

const NewEnhancedProducts: FC = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useRegisterStyles();
  const localStyles = useStyles();
  const _enhancedProduct: EnhancedProduct =
    props.location.state?.enhancedProduct;
  const [product, setProduct] = useState();
  const [displayDashboard, setDisplayDashBoard] = useState<boolean>(
    _enhancedProduct?.displayConfig?.dashboard || false,
  );
  const [displayNewSignature, setDisplayNewSignature] = useState<boolean>(
    _enhancedProduct?.displayConfig?.newSignature || false,
  );
  const [enhancedProduct, setEnhancedProduct] = useState<EnhancedProduct>(
    _enhancedProduct,
  );

  useEffect(() => {
    dispatch(actUpdatePageTitle('Turbinar produto'));
  }, [dispatch]);

  const { handleSubmit, control, errors, register, watch, reset } = useForm({
    validationSchema,
    defaultValues: defaultValues(enhancedProduct),
  });

  const handleRedirect = () => {
    reset();
    history.push('/admin/enhanced-products');
  };

  const onSubmit = handleSubmit(async data => {
    const payload = {
      description: data.description,
      buttonText: data.buttonText,
      startDate: format(new Date(data.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(data.endDate), 'yyyy-MM-dd'),
      price: Number(data.price),
      displayConfig: {
        email: true,
        dashboard: displayDashboard,
        newSignature: displayNewSignature,
      },
      product: { id: product || _enhancedProduct.product },
    };

    let response: any;
    if (enhancedProduct) {
      response = await EnhancedProductService.update(
        enhancedProduct?.id,
        payload,
      );
    } else {
      response = await EnhancedProductService.create(payload);
    }

    if (![StatusCodes.CREATED, StatusCodes.OK].includes(response.status)) {
      dispatch(notifyError(NEW_ENHANCED_PRODUCT_ERROR));
    } else {
      if (StatusCodes.CREATED === response.status) {
        dispatch(notifySuccess(NEW_ENHANCED_PRODUCT_SUCCESS));
        setEnhancedProduct(response.data);
      }
      if (StatusCodes.OK === response.status) {
        dispatch(notifySuccess(ENHANCED_PRODUCT_UPDATE_SUCCESS));
        setEnhancedProduct(response.data);
      }
      handleRedirect();
    }
  });

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
      >
        <Grid item xs>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ fontWeight: 'bold' }}
          >
            Informações principais
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={styles.actionsWrapper}
          >
            <Button
              id="cancel-new-eproduct"
              color="primary"
              className={styles.buttonLabel}
              style={{ marginRight: 30 }}
              onClick={() => history.push('/admin/enhanced-products')}
            >
              Cancelar
            </Button>
            <Button
              id="save-eproduct"
              type="submit"
              variant="contained"
              color="primary"
              className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
              onClick={() => onSubmit()}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Paper elevation={0} className={styles.rootPaper}>
        <Grid container spacing={1}>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs>
              <AutoCompleteSearch
                selectAction={setProduct}
                initialValue={enhancedProduct?.product}
                register={register}
                errors={errors}
              />
            </Grid>

            <Grid item xs={12}>
              <Controller
                name="price"
                control={control}
                as={({ value, onChange }) => (
                  <MyOutlinedTextField
                    id="price"
                    label="Valor da turbinação"
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      inputComponent: MyNumberFormat as any,
                    }}
                    fullWidth
                    error={Boolean(errors.price)}
                    helperText={errors.price ? errors.price.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <MyOutlinedTextField
                id="eproduct-description"
                name="description"
                label="Mensagem"
                multiline
                fullWidth
                inputRef={register}
                rows={4}
                error={Boolean(errors.description)}
                helperText={
                  errors.description ? errors.description.message : null
                }
              />
            </Grid>
          </Grid>

          <Grid container item xs={12} md={6}>
            <Grid item xs={12}>
              <Controller
                name="startDate"
                control={control}
                as={({ onChange, value }) => (
                  <MyDatePicker
                    id="start-date"
                    value={value}
                    onChange={onChange}
                    disablePast
                    name="startDate"
                    label="Data de início"
                    error={Boolean(errors.startDate)}
                    helperText={
                      errors.startDate ? errors.startDate.message : null
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} className={localStyles.marginBottom10}>
              <Controller
                name="endDate"
                control={control}
                as={({ onChange, value }) => (
                  <MyDatePicker
                    minDate={
                      watch('startDate')
                        ? new Date(watch('startDate'))
                        : new Date()
                    }
                    id="end-date"
                    value={value}
                    onChange={onChange}
                    disablePast
                    name="endDate"
                    label="Data de término"
                    error={Boolean(errors.endDate)}
                    helperText={errors.endDate ? errors.endDate.message : null}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} className={localStyles.marginLeft}>
              <MyTypography>
                <b>Ativar em:</b>
              </MyTypography>

              <Grid container justify="flex-start" direction="row">
                <FormControlLabel
                  name="displayDashboard"
                  control={
                    <MySwitch
                      name="displayDashboard"
                      color="primary"
                      checked={displayDashboard}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDisplayDashBoard(e.target.checked)
                      }
                    />
                  }
                  label="Tela do assinante"
                  labelPlacement="end"
                />
              </Grid>

              <Grid container justify="flex-start" direction="row">
                <FormControlLabel
                  name="newSignature"
                  control={
                    <MySwitch
                      name="newSignature"
                      color="primary"
                      checked={displayNewSignature}
                      onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setDisplayNewSignature(e.target.checked)
                      }
                    />
                  }
                  label="Nova assinatura"
                  labelPlacement="end"
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container item xs={12} md={6} spacing={2}>
            <Grid item xs={12}>
              <MyOutlinedTextField
                id="eproduct-buttonText"
                name="buttonText"
                label="Texto do botão"
                multiline
                fullWidth
                inputRef={register}
                error={Boolean(errors.buttonText)}
                helperText={
                  errors.buttonText ? errors.buttonText.message : null
                }
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default NewEnhancedProducts;
