import { NavTypes, NavState } from './types';
import { Reducer } from 'redux';

const INITIAL_STATE: NavState = {
  pageTitle: '',
  modal: {
    open: false,
    maxWidth: 'md',
    component: null,
    props: null,
  },
};

const reducer: Reducer<NavState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case NavTypes.UPDATE_PAGE_TITLE: {
      return { ...state, pageTitle: action.payload.pageTitle };
    }
    case NavTypes.OPEN_MODAL: {
      return {
        ...state,
        modal: { open: true, ...action.payload.modal },
      };
    }
    case NavTypes.CLOSE_MODAL: {
      return {
        ...state,
        modal: { open: false, component: null, props: null },
      };
    }
    default:
      return state;
  }
};

export default reducer;
