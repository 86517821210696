import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import SectionLogo from './components/SectionLogo';
import SectionLogin from './components/SectionLogin';
import { Storage } from 'services/storage';
import { notifyError } from 'store/ducks/notification/actions';

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const errorMessage = Storage.get('errorMessage');

  useEffect(() => {
    if (errorMessage) {
      dispatch(notifyError(errorMessage));
      Storage.remove('errorMessage');
    }
  }, [dispatch, errorMessage]);

  return (
    <Grid container>
      <SectionLogo />
      <SectionLogin />
    </Grid>
  );
};

export default Login;
