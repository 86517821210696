import React, { useRef, useState, useEffect } from 'react';
import {
  Grid,
  Typography,
  Divider,
  Button,
  CircularProgress,
  CardMedia,
} from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';
import clsx from 'clsx';

import MyTerms from 'components/generals/terms';

import { useSignature } from 'contexts/signatureContext';
import commomStyles from '../../styles';
import useStyles from './styles';
import { FinishStepProps } from './types';
import OpenEndpointsService from 'services/openEndpointsService';
import { moneyFormat } from 'pages/client/signature/utils';
import {
  getUnitPrice,
  PAYMENT_TYPE,
  formatPaymentType,
  RECURRENCES_MONTHS,
} from 'types/generals';
import { Controller } from 'react-hook-form';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { InputRef } from 'types/FormTypes';

const FinishStep = ({
  handleActionFinishForm,
  control,
  errors,
}: FinishStepProps) => {
  const classes = commomStyles();
  const styles = useStyles();
  const {
    plan,
    loadingRequest,
    paymentType,
    showFinishStep,
    planStepsDisabled,
    enhancedProducts,
  } = useSignature();
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);
  const referralCodeInput = useRef() as InputRef;

  const PLAN_CARD_PRICE = plan.prices.cardPrice;
  const PLAN_BANKSLIP_PRICE = plan.prices.bankslipPrice;

  const FRETE = 15;
  const PLAN_CARD_PRICE_CUSTOM =
    plan.prices.cardPrice - FRETE * plan.recurrence.months;
  const PLAN_BANKSLIP_PRICE_CUSTOM =
    plan.prices.bankslipPrice - FRETE * plan.recurrence.months;

  const PLAN_CARD_DISCOUNT = plan.discounts.cardDiscount;
  const PLAN_BANKSLIP_DISCOUNT = plan.discounts.bankslipDiscount;
  const kitPrice = Number(
    enhancedProducts.reduce((acc, curr) => acc + curr.price, 0),
  );

  const onSubmit = async () => {
    try {
      const token = await recaptchaRef.current?.executeAsync();
      const response = await OpenEndpointsService.verifyUserRequest(token);
      const { success, score } = response.data;
      if (!success && score <= 0.5) {
        throw new Error('Ação inválida');
      }
      handleActionFinishForm();
    } catch (error) {
      console.log(error);
    }
  };

  const renderPricePlan = (
    recurrence: number,
    paymentType: PAYMENT_TYPE,
    price: number,
    hasDiscount?: boolean,
  ) => {
    return (
      <Typography
        className={clsx(
          styles.priceText,
          verifyStepAccess() ? styles.disabledColor : '',
        )}
      >
        {hasDiscount ? 'Por ' : null}
        {formatPaymentType(recurrence, paymentType, price)}
        {plan.recurrence?.months === RECURRENCES_MONTHS.MONTHLY ? (
          <span
            className={clsx(
              styles.monthlyText,
              verifyStepAccess() ? styles.disabledColor : '',
            )}
          >
            /mês
          </span>
        ) : null}
        &nbsp;
        {paymentType === PAYMENT_TYPE.CARD &&
        recurrence > RECURRENCES_MONTHS.MONTHLY ? (
          <span
            className={clsx(
              styles.smallPrice,
              verifyStepAccess() ? styles.disabledColor : styles.mediumGray,
            )}
          >
            ({moneyFormat(price)})
          </span>
        ) : null}
      </Typography>
    );
  };

  const rendePreviousPrice = (price: number) => {
    return (
      <Typography
        className={clsx(
          styles.discountText,
          verifyStepAccess() ? styles.disabledColor : styles.darkGray,
        )}
      >
        De: {moneyFormat(price)}
      </Typography>
    );
  };

  const verifyStepAccess = () => {
    return !showFinishStep && planStepsDisabled;
  };

  return (
    <>
      <Grid
        container
        className={
          verifyStepAccess() ? classes.stepWrapperDisabled : classes.stepWrapper
        }
      >
        <Grid
          container
          direction="row"
          alignItems="center"
          className={classes.wrapperTitle}
        >
          <Typography className={classes.titleButton}>3</Typography>
          <Typography className={classes.title}>Finalizar</Typography>
        </Grid>
        <Grid container>
          <Grid container className={styles.wrapperInfosBox}>
            <Grid item xs={8}>
              <Typography
                className={clsx(
                  styles.boxTitle,
                  verifyStepAccess() ? styles.disabledColor : '',
                )}
              >
                Plano {plan?.recurrence?.name}
              </Typography>
              <Grid container item alignItems="center">
                <Typography
                  className={clsx(
                    styles.totalPlanText,
                    verifyStepAccess() ? styles.disabledColor : '',
                  )}
                >
                  {paymentType === PAYMENT_TYPE.CARD
                    ? formatPaymentType(
                        plan?.recurrence?.months,
                        PAYMENT_TYPE.CARD,
                        PLAN_CARD_PRICE_CUSTOM,
                      )
                    : formatPaymentType(
                        plan?.recurrence?.months,
                        PAYMENT_TYPE.BANKSLIP,
                        PLAN_BANKSLIP_PRICE_CUSTOM,
                      )}
                </Typography>
                {plan.recurrence?.months === RECURRENCES_MONTHS.MONTHLY ? (
                  <Typography
                    className={clsx(
                      styles.monthlyText,
                      verifyStepAccess()
                        ? styles.disabledColor
                        : styles.mediumGray,
                    )}
                  >
                    /mês
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            {plan.image ? (
              <Grid item xs={4}>
                <CardMedia
                  component="img"
                  className={styles.imageWrapper}
                  image={plan.image}
                  title="Imagem do plano"
                />
              </Grid>
            ) : null}
          </Grid>
          <Divider className={styles.divider} />
          <Grid container item justify="space-between">
            <Grid item>
              <Typography
                className={clsx(
                  styles.infoPlanText,
                  verifyStepAccess() ? styles.disabledColor : '',
                )}
              >
                Frete
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={clsx(
                  styles.infoPlanText,
                  verifyStepAccess() ? styles.disabledColor : '',
                )}
              >
                R$ 15,00
              </Typography>
            </Grid>
          </Grid>
          <Grid container item justify="space-between">
            <Grid item>
              <Typography
                className={clsx(
                  styles.infoPlanText,
                  verifyStepAccess() ? styles.disabledColor : '',
                )}
              >
                Valor unitário
              </Typography>
            </Grid>
            <Grid item>
              <Typography
                className={clsx(
                  styles.infoPlanText,
                  verifyStepAccess() ? styles.disabledColor : '',
                )}
              >
                {paymentType === PAYMENT_TYPE.CARD
                  ? moneyFormat(
                      getUnitPrice(
                        plan?.recurrence?.months,
                        PLAN_CARD_PRICE_CUSTOM,
                      ),
                    )
                  : moneyFormat(
                      getUnitPrice(
                        plan?.recurrence?.months,
                        PLAN_BANKSLIP_PRICE_CUSTOM,
                      ),
                    )}
              </Typography>
            </Grid>
          </Grid>
          {kitPrice ? (
            <Grid container item justify="space-between">
              <Grid item>
                <Typography
                  className={clsx(
                    styles.infoPlanText,
                    verifyStepAccess() ? styles.disabledColor : '',
                  )}
                >
                  Produto turbinado
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  className={clsx(
                    styles.infoPlanText,
                    verifyStepAccess() ? styles.disabledColor : '',
                  )}
                >
                  {formatColumnValue(FormatOptions.MONEY, kitPrice)}
                </Typography>
              </Grid>
            </Grid>
          ) : (
            ''
          )}
          <Divider className={styles.divider} />
          {plan.message ? (
            <Grid container item xs>
              <Typography
                className={clsx(
                  styles.messageText,
                  verifyStepAccess() ? styles.disabledColor : '',
                )}
              >
                {plan.message}
              </Typography>
              <Divider className={styles.divider} />
            </Grid>
          ) : null}
          <Grid
            container
            direction="column"
            className={styles.discountBoxWrapper}
          ></Grid>
          <Grid container direction="row" justify="space-between">
            <Grid item>
              <Typography
                className={clsx(
                  styles.totalPlanText,
                  verifyStepAccess() ? styles.disabledColor : '',
                )}
              >
                Total
              </Typography>
            </Grid>

            <Grid item>
              {paymentType === PAYMENT_TYPE.CARD ? (
                <>
                  {PLAN_CARD_DISCOUNT > 0 ? (
                    <>
                      {rendePreviousPrice(PLAN_CARD_DISCOUNT)}
                      {renderPricePlan(
                        plan?.recurrence?.months,
                        PAYMENT_TYPE.CARD,
                        PLAN_CARD_PRICE,
                        true,
                      )}
                    </>
                  ) : (
                    renderPricePlan(
                      plan?.recurrence?.months,
                      PAYMENT_TYPE.CARD,
                      PLAN_CARD_PRICE,
                    )
                  )}
                </>
              ) : (
                <>
                  {PLAN_BANKSLIP_DISCOUNT > 0 ? (
                    <>
                      {rendePreviousPrice(PLAN_BANKSLIP_DISCOUNT)}
                      {renderPricePlan(
                        plan?.recurrence?.months,
                        PAYMENT_TYPE.BANKSLIP,
                        PLAN_BANKSLIP_PRICE,
                        true,
                      )}
                    </>
                  ) : (
                    renderPricePlan(
                      plan?.recurrence?.months,
                      PAYMENT_TYPE.BANKSLIP,
                      PLAN_BANKSLIP_PRICE,
                    )
                  )}
                </>
              )}
            </Grid>
          </Grid>{' '}
          {kitPrice ? (
            <Grid container direction="row" justify="space-between">
              <Grid item></Grid>
              <Grid item>
                <Typography className={styles.kitPriceText}>
                  + 1x de {moneyFormat(kitPrice)}
                </Typography>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
          size="invisible"
        />
        <Grid item className={styles.gridButton} xs={12}>
          {showFinishStep || !planStepsDisabled ? (
            <Button
              fullWidth
              variant="contained"
              type="submit"
              className={styles.checkoutButton}
              onClick={onSubmit}
              disabled={loadingRequest}
            >
              {loadingRequest ? (
                <CircularProgress className={styles.progress} size={20} />
              ) : (
                'Finalizar assinatura'
              )}
            </Button>
          ) : null}
        </Grid>
        {!planStepsDisabled ? (
          <Grid item xs={12}>
            <Typography className={classes.termsAndConditionsText}>
              Ao clicar em FINALIZAR ASSINATURA, confirmo que li e concordo com
              os &nbsp;
              <MyTerms className={classes.termsAndConditionsDetail} />
            </Typography>
          </Grid>
        ) : null}
        {plan.recurrence.months === RECURRENCES_MONTHS.MONTHLY &&
        planStepsDisabled ? (
          <Typography
            className={clsx(
              styles.renewMonthlyMessage,
              verifyStepAccess() ? styles.disabledColor : styles.mediumGray,
            )}
          >
            Renovação mensal automática.
          </Typography>
        ) : null}
      </Grid>
    </>
  );
};

export default FinishStep;
