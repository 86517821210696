import * as Yup from 'yup';
import { OrderDetail, TRANSPORT_COMPANIES } from 'types/generals';
import { identification } from 'utils/formYupFields';

export const validationSchema = Yup.object({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  memberSince: Yup.string(),
  phone: Yup.string().required('Telefone obrigatório'),
  identification,
  address: Yup.object({
    zipcode: Yup.string().required('Cep obrigatório'),
    street: Yup.string().required('Endereço obrigatório'),
    number: Yup.string().required('Número obrigatório'),
    neighborhood: Yup.string().required('Bairro obrigatório'),
    city: Yup.string().required('Cidade obrigatório'),
    state: Yup.string()
      .max(2, 'Só é permitido 2 dígitos, ex: RS')
      .required('Estado obrigatório'),
    complement: Yup.string(),
  }),
  resendReason: Yup.string().required('Motivo do reenvio obrigatório'),
  transport: Yup.mixed<TRANSPORT_COMPANIES>()
    .typeError(
      `A tranportadora escolhida não confere com as transportadoras suportadas`,
    )
    .oneOf(Object.values(TRANSPORT_COMPANIES))
    .required('Campo obrigatório'),
});

export const defaultUserValues = (orderDetail: OrderDetail) => {
  const {
    city,
    complement,
    zipcode,
    street,
    state,
    neighborhood,
    number,
    userName,
    userEmail,
    userIdentification,
    userPhone,
    resendReason,
    transport,
  } = orderDetail;

  return {
    name: userName,
    email: userEmail,
    phone: userPhone,
    identification: userIdentification,
    resendReason,
    transport,
    address: {
      zipcode,
      street,
      number,
      neighborhood,
      city,
      state,
      complement,
    },
  };
};
