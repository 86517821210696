import React, {
  FC,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  useMemo,
} from 'react';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import { lastDayOfMonth, format, addDays } from 'date-fns';
import { CondOperator } from '@nestjsx/crud-request';

import useStyles from 'components/generals/forms/register/styles';
import { useOrdersStyles } from './styles';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import Colors from 'styles/colors';
import { AppState } from 'store';
import { OrderState } from 'store/ducks/order/types';
import { loadOrders } from 'store/ducks/order/actions';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import TagWrapper, { DEFINED_TAGS } from 'components/generals/tag';
import { PAYMENT_TYPE, ORDER_TYPE } from 'types/generals';
import { useFilters } from 'hooks/filters';
import { useRedirectInactiveSubscription } from 'hooks/redirectInactiveSubscription';
import CircularLoading from 'components/generals/loading/CircularLoading';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { StatusTag } from 'components/generals/tag/StatusTag';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';

const Orders: FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const ordersStyles = useOrdersStyles();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const dispatch = useDispatch();
  const {
    sort,
    page,
    handleSort,
    handleSetValuePage,
    setInitialSort,
  } = useFilters({});

  const _lastDayOfMonth = format(
    addDays(lastDayOfMonth(new Date()), 1),
    'yyyy-MM-dd',
  );
  const { data: orders, total, isLoading } = useSelector<AppState, OrderState>(
    ({ ordersListReducer }: AppState) => ordersListReducer,
  );

  const { data: signature, isLoading: isLoadingSignature } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  const signatureTypeOrder = useMemo(() => {
    return orders?.filter(
      order => order.orderDetail.orderType === ORDER_TYPE.SIGNATURE,
    );
  }, [orders]);

  useRedirectInactiveSubscription(
    signature,
    signatureTypeOrder,
    '/my-account/signature/reativar-assinatura',
    isLoading,
  );

  const loadAllOrder = useCallback(() => {
    if (!isLoadingSignature) {
      dispatch(
        loadOrders({
          join: [
            {
              field: 'orderDetail',
              select: [
                'id',
                'paymentType',
                'orderType',
                'products',
                'freight',
                'subtotal',
                'discount',
              ],
            },
          ],
          fields: ['id', 'status', 'paymentDate', 'signatureId'],
          page: page + 1,
          limit: rowsPerPage,
          sort,
          filter: [
            {
              field: 'paymentDate',
              operator: CondOperator.LOWER_THAN_EQUALS,
              value: _lastDayOfMonth,
            },
            {
              field: 'isEnhanced',
              operator: CondOperator.EQUALS,
              value: false,
            },
          ],
        }),
      );
    }
  }, [dispatch, page, rowsPerPage, isLoadingSignature, sort, _lastDayOfMonth]);

  useEffect(() => {
    loadAllOrder();
  }, [loadAllOrder]);

  useEffect(() => {
    setInitialSort({ field: 'paymentDate', order: 'DESC' });
  }, [setInitialSort]);

  const paymentTypeFormat = (paymentType: PAYMENT_TYPE) => {
    switch (paymentType) {
      case PAYMENT_TYPE.CARD:
        return 'Cartão';
      case PAYMENT_TYPE.BANKSLIP:
        return 'Boleto';
    }
  };

  const dateFormatted = useCallback((dateValue: string) => {
    return formatColumnValue(FormatOptions.SIMPLE_DATE_WITH_TRACE, dateValue);
  }, []);

  const handlePushDetails = (orderId: number) => {
    history.push(`/my-account/orders/${orderId}`, {
      orderId,
    });
  };

  return (
    <Paper elevation={0} className={clsx(styles.rootPaper)}>
      <Grid container className={ordersStyles.paperTitle}>
        <Typography variant="h1" color="textPrimary">
          Meus
        </Typography>
        <Typography
          variant="h1"
          color="textPrimary"
          className={ordersStyles.paperTitleBold}
        >
          pedidos
        </Typography>
      </Grid>
      <TableContainer>
        <Table>
          <TableHead className={ordersStyles.tableItem}>
            <TableRow>
              <MySortableTableCell
                onClick={() => handleSort('id')}
                direction={sort.field === 'id' && sort.order}
              >
                Nº do Pedido
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('orderDetail.orderType')}
                direction={sort.field === 'orderDetail.orderType' && sort.order}
              >
                Tipo
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('orderDetail.paymentType')}
                direction={
                  sort.field === 'orderDetail.paymentType' && sort.order
                }
              >
                Pagamento
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('paymentDate')}
                direction={sort.field === 'paymentDate' && sort.order}
              >
                Data
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('status')}
                direction={sort.field === 'status' && sort.order}
              >
                Status
              </MySortableTableCell>
            </TableRow>
          </TableHead>
          {isLoading ? (
            <CircularLoading
              isLoading={isLoading}
              title={'Carregando pedidos'}
            />
          ) : (
            <TableBody>
              {orders?.map(order => (
                <TableRow key={order.id}>
                  <TableCell>{order.id || '-'}</TableCell>
                  <TableCell>
                    {order.orderDetail.orderType}
                    {order.isResend ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.STATUS}
                          value={'Reenvio'}
                        />
                      </Grid>
                    ) : null}
                  </TableCell>
                  <TableCell>
                    {paymentTypeFormat(order.orderDetail.paymentType)}
                  </TableCell>
                  <TableCell>{dateFormatted(order.paymentDate)}</TableCell>
                  <TableCell className={ordersStyles.overflowCell}>
                    <StatusTag status={order.status} />
                  </TableCell>
                  <TableCell className={ordersStyles.alignEnd}>
                    {order.orderDetail.products && (
                      <Button onClick={() => handlePushDetails(order.id)}>
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          style={{
                            color: Colors.RED,
                            fontWeight: 'bold',
                          }}
                        >
                          Ver detalhes
                        </Typography>
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      <Grid container item xs justify="flex-end">
        <TablePagination
          ActionsComponent={MyTablePaginationActions}
          component="div"
          count={total}
          page={page}
          labelRowsPerPage="Itens por página"
          onChangePage={(_event: unknown, newPage: number) =>
            handleSetValuePage(newPage)
          }
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
            handleSetValuePage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
          }}
          rowsPerPageOptions={ROWS_PER_PAGE}
        />
      </Grid>
    </Paper>
  );
};
export default Orders;
