export enum PlanPages {
  REACTIVATE_PAGE = 'reactivate',
  UPGRADE_PAGE = 'upgrade',
}

export interface ChangePlanProps {
  page: PlanPages;
}

export interface ChangePlanAction {
  hashPlan?: string;
}
