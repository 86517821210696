export enum BannerLocation {
  HOME = 'home',
}
export enum BannerType {
  PRIMARY = 'primary',
  SECONDARY = 'secondary',
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
}

export interface IStoreLinks {
  linkGoogle: string;
  linkApple: string;
}

export enum BannerImageLocation {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}
export interface IBannerData {
  id: string;
  imageLink?: string;
  redirectLink: string;
  name?: string;
  type?: BannerType;
  location: BannerImageLocation;
  order?: number;
}

export interface ISecondaryBannerData {
  id?: string;
  imageLink?: string;
  linkGoogle: string;
  linkApple: string;
  name?: string;
  type?: BannerType;
  location: BannerImageLocation;
  order?: number;
}

export interface IBannerData {
  id: string;
  imageLink?: string;
  redirectLink: string;
  name?: string;
  type?: BannerType;
  location: BannerImageLocation;
  order?: number;
}

export interface ISecondaryBanner {
  mobile: ISecondaryBannerData[];
  desktop: ISecondaryBannerData[];
  tablet: ISecondaryBannerData[];
}
export interface IBanner {
  mobile: IBannerData[];
  desktop: IBannerData[];
  tablet: IBannerData[];
}

export interface Banner {
  id?: number;
  createdAtd?: string;
  updatedAt?: string;
  banner: IBanner;
  secondaryBanner: ISecondaryBanner;
  isActive?: boolean;
  type: BannerLocation;
}

export interface BannerPayload {
  id?: any;
  createdAtd?: string;
  updatedAt?: string;
  files: any[];
  banner: IBannerData[];
  secondaryBanner: IBannerData[];
  isActive?: boolean;
  type: BannerLocation;
}
export interface MultiPartRequestConfig {
  headers: { 'Content-Type': string };
}
