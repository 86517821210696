import React, { FC, Fragment } from 'react';
import { Controller } from 'react-hook-form';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';

import { useStyles } from '../ShopCart/ShopCartDetail/styles';
import { PaymentTypeControlProps } from './types';
import ItemLabel from 'components/generals/labels/ItemLabel';
import { PAYMENT_TYPE } from 'types/generals';

const PaymentTypeControl: FC<PaymentTypeControlProps> = ({
  control,
  errors,
  cardName,
  controlField,
}) => {
  const styles = useStyles();

  return (
    <Fragment>
      <Controller
        name={controlField}
        control={control}
        as={({ value, onChange }) => (
          <RadioGroup
            onChange={event => onChange(event.target.value)}
            value={value || ''}
            aria-label="payment-options"
          >
            <FormControlLabel
              value={PAYMENT_TYPE.BANKSLIP}
              control={<Radio />}
              label="Boleto"
              className={styles.radioWrapper}
            />
            {cardName ? (
              <FormControlLabel
                value={PAYMENT_TYPE.CARD}
                control={<Radio />}
                disabled={cardName === undefined}
                label={<ItemLabel name={'Cartão'} subtitle={cardName} />}
                className={styles.radioWrapper}
              />
            ) : null}
          </RadioGroup>
        )}
      />
      {errors && errors[controlField] ? (
        <Typography id="payment-type-required-error" color="error">
          {errors[controlField].message}
        </Typography>
      ) : null}
    </Fragment>
  );
};

export default PaymentTypeControl;
