import { Button, Grid, Paper, Typography } from '@material-ui/core';
import useStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import MyTable from 'components/generals/table/MyTable';
import { StatusCodes } from 'http-status-codes';
import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RolesService from 'services/RolesService';
import { actUpdatePageTitle, openModal } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { ColumnProps, ColumnTypes } from 'types';
import { Roles, RolesPermission } from 'types/generals';
import {
  NEW_ROLES_ERROR,
  NEW_ROLES_SUCCESS,
  ROLES_UPDATE_ERROR,
  ROLES_UPDATE_SUCCESS,
} from 'utils/messages';
import { defaultValues, validationSchema } from '../utils';

import PermissionsDialog from 'components/generals/dialog/PermissionsDialog';

const RolesRegister: FC = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const [role, setRole] = useState<Roles>(props.location.state?.role);
  const [permissions, setPermissions] = useState<RolesPermission[]>();

  useEffect(() => {
    dispatch(actUpdatePageTitle('Permissões'));
  }, [dispatch]);

  useEffect(() => {
    const load = async () => {
      const response = await RolesService.findPermissions();
      setPermissions(response?.data || []);
    };
    load();
  }, []);

  const { handleSubmit, control, errors, register } = useForm({
    validationSchema,
    defaultValues: defaultValues(role),
  });

  const submitNewRole = handleSubmit(async data => {
    let response: any;
    if (role) {
      response = await RolesService.updateRole(role?.id, data);
    } else {
      response = await RolesService.createRole(data);
    }

    if (![StatusCodes.CREATED, StatusCodes.OK].includes(response.status)) {
      dispatch(notifyError(NEW_ROLES_ERROR));
    } else {
      if (StatusCodes.CREATED === response.status) {
        dispatch(notifySuccess(NEW_ROLES_SUCCESS));
        setRole(response.data);
      }
      if (StatusCodes.OK === response.status) {
        dispatch(notifySuccess(ROLES_UPDATE_SUCCESS));
        setRole(response.data);
      }
      // eslint-disable-next-line no-restricted-globals
    }
  });

  const onDelete = async (permission: RolesPermission) => {
    const permissions = role.permissions.filter(
      perm => perm.id !== permission.id,
    ) as any;

    const response: any = await RolesService.updateRole(role?.id, {
      permissions,
    });

    if (![StatusCodes.OK].includes(response.status)) {
      dispatch(notifyError(ROLES_UPDATE_ERROR));
    } else {
      if (StatusCodes.OK === response.status) {
        dispatch(notifySuccess(ROLES_UPDATE_SUCCESS));
        setRole(response.data);
      }
    }
  };

  const onAddButton = () => {
    const modalProps = {
      title: 'Adicionar permissão',
      role,
      permissions,
      onSubmitSuccess: (role: Roles) => {
        console.log('role', role);
        setRole(role);
      },
    };
    dispatch(openModal(PermissionsDialog, modalProps));
  };

  const columns: ColumnProps[] = [
    { label: 'NOME', getter: 'displayName' },
    { label: 'AÇÕES', sortable: true, onDelete, type: ColumnTypes.DELETE },
  ];

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
      >
        <Grid item xs>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ fontWeight: 'bold' }}
          >
            Cadastro de grupos de permissão
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={styles.actionsWrapper}
          >
            <Button
              id="cancel-new-box"
              color="primary"
              className={styles.buttonLabel}
              style={{ marginRight: 30 }}
              onClick={() => history.push('/admin/roles')}
            >
              Cancelar
            </Button>
            <Button
              id="save-box"
              type="submit"
              variant="contained"
              color="primary"
              className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
              onClick={() => submitNewRole()}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Paper elevation={0} className={styles.rootPaper}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MyOutlinedTextField
              id="roles-name"
              name="name"
              label="Nome"
              fullWidth
              inputRef={register}
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
            />
          </Grid>
          <Grid item xs={6}>
            <MyOutlinedTextField
              id="box-description"
              name="description"
              label="Descrição"
              multiline
              fullWidth
              inputRef={register}
              rows={8}
              error={Boolean(errors.description)}
              helperText={
                errors.description ? errors.description.message : null
              }
            />
          </Grid>
        </Grid>
      </Paper>
      {role && (
        <Paper elevation={0} className={styles.rootPaper}>
          <MyTable
            rootContainerStyle={{
              marginTop: 5,
              marginBottom: 10,
            }}
            columns={columns}
            data={role?.permissions}
            total={role?.permissions?.length}
            page={1}
            onChangePage={() => {}}
            selectRowsPerPage={role?.permissions?.length}
            title="Permissões disponíveis"
            addButtonLabel="Adicionar permissão"
            onAddButton={onAddButton}
          />
        </Paper>
      )}
    </Grid>
  );
};

export default RolesRegister;
