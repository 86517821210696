import { call, put } from 'redux-saga/effects';
import AddressService from 'services/addressService';
import {
  loadAllAddressesSuccess,
  loadAllAddressesFail,
  loadDefaultAddressFail,
  loadDefaultAddressSuccess,
} from './actions';

export function* loadAddressesSaga(action: any) {
  try {
    const { data, total } = yield call(
      AddressService.getAllAddress,
      action.payload.query,
    );
    yield put(loadAllAddressesSuccess({ data, total }));
  } catch (error) {
    yield put(loadAllAddressesFail());
  }
}

export function* loadDefaultAddressSaga(action: any) {
  try {
    const { data } = yield call(
      AddressService.getAllAddress,
      action.payload.query,
    );

    const defaultAddress = data.find(
      (address: any) => address.isDefault === true,
    );

    yield put(loadDefaultAddressSuccess({ data: defaultAddress, total: 0 }));
  } catch (error) {
    yield put(loadDefaultAddressFail());
  }
}
