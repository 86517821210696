import { CreateQueryParams } from '@nestjsx/crud-request';
import { ResendOrder } from 'types/generals';
import { api, apiQueue, GetProps, serverApi, getWithSaga } from './apiService';

export default class OrdersService {
  static async getAllOrders(query: CreateQueryParams) {
    try {
      const data = await getWithSaga('orders', query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getOrder(id: string, query?: CreateQueryParams) {
    try {
      const data = await getWithSaga(`orders/${id}`, query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getSignatureOrderMonth(id: string) {
    try {
      const data = await getWithSaga(`signature/${id}/get-month-order`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async markOrderAsRemoved(id: number) {
    try {
      const data = await api.delete(`orders/${id}/mark-as-removed`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getClientAllOrders() {
    try {
      const { data } = await api.get('signature');
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getClientOrder(id: string) {
    try {
      // TODO: finish endpoint call passing id
      const { data } = await serverApi.get('order');
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getOrderInSignature(orderId: number, signatureId: number) {
    try {
      const { data } = await api.get(
        `signature/${signatureId}/orders/${orderId}`,
      );
      return data;
    } catch (err) {
      return err;
    }
  }

  static async updateOrder(id: string): Promise<GetProps> {
    try {
      const { data } = await api.patch(`orders/${id}`, {
        isActive: false,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  static async cancelOrder(id: string, message?: string) {
    try {
      const { data } = await apiQueue.put(`orders/${id}/cancel`, {
        message: message,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  static async createResendOrder(orderData: ResendOrder) {
    try {
      const data = await apiQueue.post('orders/resend', orderData);
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async reactivateOrder(id: string) {
    try {
      const { data } = await api.put(`orders/${id}/reactivate`, {});
      return data;
    } catch (error) {
      return error;
    }
  }

  static async tryingChargeOrder(id: string) {
    try {
      const response = await apiQueue.post(
        `orders/${id}/trying-card-payment`,
        {},
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static async tryingChangeOrderStatusToPaid(orderId: number) {
    try {
      const response = await api.post(`orders/update-order-status-to-paid`, {
        orderId,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async tryingBankSlipOrderPayment(id: string) {
    try {
      const data = await apiQueue.post(
        `orders/${id}/trying-bankslip-payment`,
        {},
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async refundPayment(orderId: number, refundReason: string) {
    try {
      const data = await apiQueue.post(`orders/${orderId}/refund-payment`, {
        refundReason,
      });
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async partialRefundPayment(
    orderId: number,
    refundReason: string,
    amount: number,
  ) {
    try {
      const data = await apiQueue.post(`orders/${orderId}/refund-payment`, {
        amount,
        refundReason,
      });
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async revealBox(boxId: number) {
    try {
      const data = await apiQueue.post(`orders/reveal-box/${boxId}`, {});
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getSmartLinkBankslip(orderId: number) {
    try {
      const data = await api.get(`orders/smart-link-bankslip/${orderId}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updateAccessKey(orderIds: number[], serie: string) {
    try {
      const response = await apiQueue.post(`orders/update-access-keys`, {
        orderIds,
        serie,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async quoteTransportOrders(orderIds: number[]) {
    try {
      const response = await apiQueue.post(`orders/quote-freight-orders`, {
        orderIds,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async handleAddProductInSeveralOrders(
    orderIds: number[],
    productId: number,
  ) {
    try {
      const response = await apiQueue.post(
        `orders/add-product-several-orders`,
        {
          orderIds,
          productId,
        },
      );
      return response;
    } catch (error) {
      return error;
    }
  }
}
