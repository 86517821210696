import { makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  expandIcon: {
    color: theme.palette.grey['500'],
    fontSize: '20px',
  },
}));

export const IconWrapper = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  paddingRight: '1em',
});

export const Icon = styled('img')({
  height: '2.9rem',
  width: '2.9rem',
});

export const ItemText = styled('span')({
  fontSize: '16px',
  maxWidth: '170px',
});

export const List = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: '24px 0',
});

export const ListItem = styled('div')({
  cursor: 'pointer',
  padding: '12px 0',
  display: 'flex',
  alignItems: 'center',
});

export const Wrapper = styled('div')({});
