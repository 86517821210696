import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  buttonWrapper: {
    padding: '0.5em 3em',
  },
  buttonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
  },
  clickable: {
    cursor: 'pointer',
    fontWeight: 700,
  },
  circle: {
    borderRadius: '50%',
  },
  inputWrapper: {
    borderRadius: 4,
    border: `1px solid ${Colors.GRAY}`,
    padding: '0.8em 0.7em',
    '& label': {
      fontSize: '16px',
    },
    '& div': {
      '& input': {
        fontSize: '16px',
        height: '1.25em',
        paddingLeft: '5px',
      },
      '& fieldset': {
        border: 'none',
      },
    },
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 32,
    cursor: 'pointer',
  },
  list: {
    listStyleType: 'none',
    paddingInline: '16px',
    maxHeight: '190px',
    overflow: 'overlay',
  },
  listItem: {
    padding: theme.spacing(1.5),
  },
  menuOption: {
    textTransform: 'capitalize',
  },
  filterOptionsList: {
    backgroundColor: Colors.WHITE,
  },
  mRight: {
    marginRight: '30px',
  },
  table: {
    minWidth: 650,
  },
  paperTable: {
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  rootPaper: {
    padding: theme.spacing(4, 3),
    borderRadius: '2em',
  },
  selectOption: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
  },
  createIconCircle: {
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    cursor: 'pointer',
    '&:hover': {
      background: Colors.LIGHTER_GRAY,
    },
  },
  createIcon: {
    fontSize: 20,
  },
});
