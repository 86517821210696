import { CreateQueryParams } from '@nestjsx/crud-request';
import { useGetFiletWithSaga } from './apiService';

export enum ROOT_PATH {
  SUBSCRIBER_VIEW = 'subscriber-view',
  PLANS = 'plans',
  BOXES = 'boxes',
  STORE = 'store',
  COUPONS = 'coupons',
  STAFFS = 'staffs',
  SHIPPING = 'shipping',
  STORE_CATEGORIES = 'store-categories',
  USER_RANKING_SEASON = 'user-ranking-season',
  USER_RANKING_SEASON_HISTORY = 'history-report',
  USER_RANKING_SEASON_REPORT = 'ranking-report',
  LEADS = 'leads',
}

export default class FileService {
  static async getFile(
    query: CreateQueryParams,
    rootPath: ROOT_PATH,
    path = 'export-file',
  ) {
    try {
      if (rootPath) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const data = useGetFiletWithSaga(`${rootPath}/${path}`, query);
        return data;
      }
      throw new Error('rootPath is required ');
    } catch (error) {
      return error.response;
    }
  }
}
