import React, { FC, useCallback, useEffect, useState } from 'react';
import { CondOperator } from '@nestjsx/crud-request';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import { useStyles } from './styles';
import { AppState } from 'store';
import { OrderState } from 'store/ducks/order/types';
import { loadOrders } from 'store/ducks/order/actions';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { ORDER_TYPE } from 'types/generals';
import { useFilters } from 'hooks/filters';
import { useRedirectInactiveSubscription } from 'hooks/redirectInactiveSubscription';
import PlanCardInfo from './PlanCardInfo';
import AddressCard from './AddressCard';
import { UserState } from 'store/ducks/user/types';
import { loadDefaultAddress } from 'store/ducks/generals/address/actions';
import { OneAddressState } from 'store/ducks/generals/address/types';
import BenefitsCard from './BenefitsCard';
import { BenefitsState } from 'store/ducks/benefits/types';
import { loadBenefits } from 'store/ducks/benefits/action';
import CircularLoading from 'components/generals/loading/CircularLoading';

const Signature: FC = () => {
  const styles = useStyles({});
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const { sort, page, setInitialSort } = useFilters({});

  const rowsPerPage = ROWS_PER_PAGE[0];

  const { data: user, isLoading: isLoadingUser } = useSelector<
    AppState,
    UserState
  >(({ user }: AppState) => user);

  const { data: orders, isLoading: isLoadingOrders } = useSelector<
    AppState,
    OrderState
  >(({ ordersListReducer }: AppState) => ordersListReducer);

  const { data: signature, isLoading: isLoadingSignature } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  const { data: address, isLoading: isLoadingAddress } = useSelector<
    AppState,
    OneAddressState
  >(({ addressReducer }: AppState) => addressReducer);

  const { data: benefits, isLoading: isLoadingBenefits } = useSelector<
    AppState,
    BenefitsState
  >(({ benefits }: AppState) => benefits);

  useRedirectInactiveSubscription(
    signature,
    orders,
    '/my-account/signature/reativar-assinatura',
    isLoadingOrders,
  );
  const loadAllOrder = useCallback(() => {
    if (!isLoadingSignature) {
      dispatch(
        loadOrders({
          join: [
            {
              field: 'orderDetail',
              select: [
                'orderType',
                'amount',
                'subtotal',
                'discount',
                'products',
                'freight',
              ],
            },
          ],
          fields: ['id', 'status', 'paymentDate'],
          page: page + 1,
          limit: rowsPerPage,
          filter: [
            {
              field: 'orderDetail.orderType',
              operator: CondOperator.EQUALS,
              value: ORDER_TYPE.SIGNATURE,
            },
            {
              field: 'isEnhanced',
              operator: CondOperator.EQUALS,
              value: false,
            },
            {
              field: 'signatureId',
              operator: CondOperator.EQUALS,
              value: signature.id,
            },
          ],
          sort,
        }),
      );
    }
  }, [dispatch, page, rowsPerPage, signature, isLoadingSignature, sort]);

  const loadAddress = useCallback(() => {
    if (!isLoadingUser && user) {
      dispatch(loadDefaultAddress(user.id));
    }
  }, [dispatch, user, isLoadingUser]);

  const loadAllBenefits = useCallback(() => {
    dispatch(
      loadBenefits({
        sort: { field: 'priority', order: 'ASC' },
        join: [{ field: 'recurrences', select: ['id'] }],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    loadAllOrder();
  }, [loadAllOrder]);

  useEffect(() => {
    loadAddress();
  }, [loadAddress]);

  useEffect(() => {
    loadAllBenefits();
  }, [loadAllBenefits]);

  useEffect(() => {
    setInitialSort({ field: 'paymentDate', order: 'DESC' });
  }, [setInitialSort]);

  useEffect(() => {
    if (
      !isLoadingOrders &&
      !isLoadingSignature &&
      !isLoadingBenefits &&
      !isLoadingAddress
    ) {
      setIsLoading(false);
    }
  }, [
    isLoadingOrders,
    isLoadingSignature,
    isLoadingBenefits,
    isLoadingAddress,
  ]);

  return (
    <Grid container direction="column" className={styles.rootMargin}>
      {isLoading ? (
        <CircularLoading
          isLoading={isLoading}
          title={'Carregando assinatura'}
        />
      ) : (
        <Grid item xs={12}>
          <Paper component={Grid} className={styles.paperTable} elevation={0}>
            <Typography variant="h1" color="textPrimary">
              Minha <strong>assinatura</strong>
            </Typography>
            {!isLoadingOrders ? (
              <Grid container item spacing={2} className={styles.root}>
                {!isLoadingSignature ? (
                  <PlanCardInfo signature={signature} plan={signature.plan} />
                ) : null}
                {!isLoadingAddress ? <AddressCard address={address} /> : null}
                {!isLoadingBenefits && !isLoadingSignature ? (
                  <BenefitsCard
                    benefits={benefits}
                    plan={signature.plan}
                    signature={signature}
                  />
                ) : null}
              </Grid>
            ) : null}
          </Paper>
        </Grid>
      )}
    </Grid>
  );
};

export default Signature;
