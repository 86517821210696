export const NEW_ENHANCED_PRODUCT_ERROR = `Erro ao tentar criar produto turbinado`;
export const NEW_ENHANCED_PRODUCT_SUCCESS = `Produto turbinado criado com sucesso.`;
export const ENHANCED_PRODUCT_UPDATE_SUCCESS = `Produto turbinado atualizado com sucesso.`;

export const DELETE_ENHANCED_PRODUCT_SUCCESS = `Produto turbinado deletado com sucesso.`;
export const DELETE_ENHANCED_PRODUCT_ERROR = `Erro ao tentar deletar produto turbinado`;

export const SIGNATURE_ENHANCED_SUCCESS = `Assinatura turbinada com sucesso`;
export const SIGNATURE_ENHANCED_ERROR = `Não foi possível turbinar a assinatura`;
export const SIGNATURE_ENHANCE_MESSAGE =
  'Deseja turbinar o pedido desse mês? O cartão padrão será utilizado para realizar o pagamento';
/* 
export const ENHANCED_PRODUCT_UPDATE_ERROR = `Erro ao tentar atualizar grupo.`;
 */
