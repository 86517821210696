import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { StoreNotificationTypes } from './types';

export const loadNotifications = (query: CreateQueryParams) => {
  return action(StoreNotificationTypes.LOAD_NOTIFICATION_REQUEST, { query });
};
export const initNotificationRequest = () => {
  return action(StoreNotificationTypes.INIT_NOTIFICATION_REQUEST);
};
export const endNotificationRequest = () => {
  return action(StoreNotificationTypes.END_NOTIFICATION_REQUEST);
};

export const loadNotificationsSuccess = (data: any) => {
  return action(StoreNotificationTypes.LOAD_NOTIFICATION_SUCCESS, { data });
};
export const loadNotificationsFailure = () => {
  return action(StoreNotificationTypes.LOAD_NOTIFICATION_FAILURE);
};
