import produce from 'immer';
import { Reducer } from 'redux';
import { StoreProduct } from 'types/generals';
import { StoreProductState, StoreTypes } from '../types';

const INITIAL_STATE = {
  data: {} as StoreProduct,
  isLoading: true,
  hasError: false,
};

const storeProductReducer: Reducer<StoreProductState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case StoreTypes.RESET_STATE_ONE_STORE_PRODUCT_AFTER_FAILED:
    case StoreTypes.LOAD_ONE_STORE_PRODUCT_REQUEST:
      return produce(state, draft => {
        draft.isLoading = true;
        draft.hasError = false;
      });
    case StoreTypes.LOAD_ONE_STORE_PRODUCT_SUCCESS:
      return produce(state, draft => {
        draft.isLoading = draft.hasError = false;
        draft.data = action.payload;
      });
    case StoreTypes.LOAD_ONE_STORE_PRODUCT_FAILED:
      return produce(state, draft => {
        draft.hasError = true;
        draft.isLoading = false;
        draft.data = {} as StoreProduct;
      });
    default:
      return state;
  }
};

export default storeProductReducer;
