import {
  Button,
  FormControlLabel,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import useStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { StatusCodes } from 'http-status-codes';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import {
  defaultReferralList,
  defaultReferredList,
  defaultValues,
  recurrenceList,
  validateReferralField,
  validationSchema,
} from '../utils';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import {
  BaseRankingSeason,
  RankingSeason,
  RankingSeasonReferral,
  RankingSeasonReferred,
} from 'types/generals/rankingSeason';
import MySwitch from 'components/generals/input/MySwitch';
import StartEndDatePicker from 'components/generals/startEndDatePicker/startEndDatePicker';
import FormTitle from 'components/generals/formTitle';
import RankingSeasonService from 'services/rankingSeasonService';
import { useCommonStyles } from 'styles/common';
import MySelectForm from 'components/generals/input/MySelectForm';
import {
  NEW_RANKING_SEASON_ERROR,
  NEW_RANKING_SEASON_SUCCESS,
  RANKING_SEASON_DUPLICATE_ERROR,
  RANKING_SEASON_DUPLICATE_SUCCESS,
  RANKING_SEASON_UPDATE_SUCCESS,
} from 'utils/messages/rankingSeason';
import { addHours, format } from 'date-fns';
import { useHistory } from 'react-router-dom';
import { UNMASK } from 'helpers/masks';
import { CondOperator } from '@nestjsx/crud-request';
import { MONTHS_QUANTITY } from 'pages/admin/manager/subscriptions/utils';

const newReferralRow: RankingSeasonReferral = {
  recurrence: 1,
  startPoint: 0,
  endPoint: 0,
  score: 0,
};

const RankingSeasonNew: FC = (props: any) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const styles = useStyles();
  const commonStyles = useCommonStyles();
  const [season, setSeason] = useState<RankingSeason>(
    props.location.state?.season,
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [isActive, setIsActive] = useState(season?.isActive || false);

  const [referralConfig, setReferralConfig] = useState<RankingSeasonReferral[]>(
    defaultReferralList,
  );
  const [referredConfig, setReferredConfig] = useState<RankingSeasonReferred[]>(
    defaultReferredList,
  );

  const { handleSubmit, control, errors, register, watch } = useForm({
    validationSchema,
    defaultValues: defaultValues(season, defaultReferralList, referredConfig),
  });

  const getReferralConfig = useCallback(async () => {
    if (!season) return;
    const response = await RankingSeasonService.fetchReferralConfiguration({
      filter: [
        {
          field: 'rankingSeasonId',
          operator: CondOperator.EQUALS,
          value: season.id,
        },
      ],
      sort: { field: 'recurrence', order: 'ASC' },
    });
    if (response.data?.length) {
      const config = response.data.map((referral: RankingSeasonReferral) => {
        return {
          ...referral,
          deleted: false,
        };
      });
      setReferralConfig(config);
    }
  }, [season]);

  const getReferredConfig = useCallback(async () => {
    if (!season) return;
    const response = await RankingSeasonService.fetchReferredConfiguration({
      filter: [
        {
          field: 'rankingSeasonId',
          operator: CondOperator.EQUALS,
          value: season.id,
        },
      ],
      sort: { field: 'recurrence', order: 'ASC' },
    });
    if (response.data?.length) {
      setReferredConfig(response.data);
    }
  }, [season]);

  useEffect(() => {
    const title = season ? 'Editar campanha' : 'Nova campanha';
    dispatch(actUpdatePageTitle(title));
  }, [dispatch, season]);

  useEffect(() => {
    getReferralConfig();
    getReferredConfig();
  }, [dispatch, getReferralConfig, getReferredConfig]);

  const onSubmit = handleSubmit(async data => {
    try {
      console.log(data.expireInMonths);
      let response: any;
      const startDate = addHours(new Date(data.startDate), 3);
      const endDate = addHours(new Date(data.endDate), 3);
      const payload: BaseRankingSeason = {
        name: data.name,
        startDate: format(startDate, 'yyyy-MM-dd'),
        endDate: format(endDate, 'yyyy-MM-dd'),
        isActive: data.isActive,
        referral: referralConfig.map(ref => {
          delete ref.error;
          return { ...ref };
        }),
        referred: referredConfig.map(ref => {
          delete ref.error;
          return { ...ref };
        }),
        expireInMonths: Number(data.expireInMonths),
      };
      setIsLoading(true);
      if (season) {
        response = await RankingSeasonService.update(season?.id, payload);
      } else {
        response = await RankingSeasonService.create(payload);
      }
      console.log({ response });
      setIsLoading(false);
      if (![StatusCodes.CREATED, StatusCodes.OK].includes(response.status)) {
        dispatch(notifyError(NEW_RANKING_SEASON_ERROR));
      } else {
        if (StatusCodes.CREATED === response.status) {
          dispatch(notifySuccess(NEW_RANKING_SEASON_SUCCESS));
          setSeason(response.data);
        }
        if (StatusCodes.OK === response.status) {
          dispatch(notifySuccess(RANKING_SEASON_UPDATE_SUCCESS));
          setSeason(response.data);
        }
        history.push(`/admin/ranking-seasons`);
      }
    } catch (error) {
      const message = error?.response?.data.message || error?.message;
      dispatch(notifyError(message));
      setIsLoading(false);
    }
  });

  const handleDuplicate = async () => {
    if (season) {
      const response = await RankingSeasonService.duplicate(season.id);

      if ([StatusCodes.CREATED, StatusCodes.OK].includes(response.status)) {
        dispatch(notifySuccess(RANKING_SEASON_DUPLICATE_SUCCESS));
        history.push(`/admin/ranking-seasons`);
      } else {
        dispatch(notifyError(RANKING_SEASON_DUPLICATE_ERROR));
      }
    }
  };

  const handleChangeReferredData = (
    referred: RankingSeasonReferred,
    value: any,
    field: string,
    index: number,
  ) => {
    const config = referredConfig.map((ref, _index) => {
      let error;
      if (!value) {
        error = 'Campo obrigatório';
        setIsDisabled(true);
      } else {
        error = '';
        setIsDisabled(false);
      }
      return _index === index ? { ...referred, [field]: value, error } : ref;
    });
    setReferredConfig(config);
  };

  const handleChangeReferralData = (
    referral: RankingSeasonReferral,
    value: any,
    field: string,
    index: number,
  ) => {
    const config: any[] = (referralConfig as any[]).map((ref, _index) => {
      let error = validateReferralField(referral, value, field);
      if (error[field]) {
        setIsDisabled(true);
      } else {
        setIsDisabled(false);
      }
      return _index === index ? { ...referral, [field]: value, error } : ref;
    });
    setReferralConfig(config);
  };

  const handleChangeReferredScore = (
    e: any,
    referred: RankingSeasonReferred,
    index: any,
  ) => {
    const score = UNMASK.onlyDigits(e.target.value);
    handleChangeReferredData(referred, score, 'score', index);
  };

  const handleChangeReferredRecurrence = (
    e: any,
    referred: RankingSeasonReferred,
    index: number,
  ) => {
    const recurrence = e.target.value;
    handleChangeReferredData(referred, recurrence, 'recurrence', index);
  };

  const handleChangeReferralScore = (
    e: any,
    referral: RankingSeasonReferral,
    index: number,
  ) => {
    const score = UNMASK.onlyDigits(e.target.value);
    handleChangeReferralData(referral, score, 'score', index);
  };

  const handleChangeReferralStartPoint = (
    e: any,
    referral: RankingSeasonReferral,
    index: number,
  ) => {
    const score = UNMASK.onlyDigits(e.target.value);
    handleChangeReferralData(referral, score, 'startPoint', index);
  };
  const handleChangeReferralEndPoint = (
    e: any,
    referral: RankingSeasonReferral,
    index: number,
  ) => {
    const score = UNMASK.onlyDigits(e.target.value);
    handleChangeReferralData(referral, score, 'endPoint', index);
  };

  const handleChangeReferralRecurrence = (
    e: any,
    referral: RankingSeasonReferral,
    index: number,
  ) => {
    const recurrence = e.target.value;
    handleChangeReferralData(referral, recurrence, 'recurrence', index);
  };

  const handleAddReferralConfig = () => {
    setReferralConfig(array => [...array, newReferralRow]);
  };
  const handleRemoveReferralConfig = (
    referral: RankingSeasonReferral,
    index: number,
  ) => {
    handleChangeReferralData(referral, true, 'deleted', index);
  };

  const menuItems = season?.id
    ? [
        {
          title: 'Duplicar campanha',
          action: () => handleDuplicate(),
        },
      ]
    : undefined;

  const handleChangeRecurrence = () => {};
  const recurrence = null;

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      <FormTitle
        cancelRedirectPath="/admin/ranking-seasons"
        onSubmit={onSubmit}
        isLoading={isLoading}
        isDisabled={isDisabled}
        menuItems={menuItems}
      />

      <Paper elevation={0} className={styles.rootPaper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MyOutlinedTextField
              id="name"
              name="name"
              label="Nome"
              fullWidth
              inputRef={register}
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
            />
          </Grid>
          <Grid item xs={12}>
            <StartEndDatePicker
              register={register}
              control={control}
              errors={errors}
              watch={watch}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="expireInMonths"
              control={control}
              as={({ value, onChange, onBlur }) => (
                <MySelectForm
                  contracted
                  title="Quantidade de meses para expirar os pontos"
                  itens={MONTHS_QUANTITY.map(month => ({
                    title: `${month}`,
                    value: month,
                  }))}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={Boolean(errors.expireInMonths)}
                  helperText={errors?.expireInMonths?.message || null}
                />
              )}
            />
          </Grid>
          <Grid item xs>
            <FormControlLabel
              control={
                <MySwitch
                  inputRef={register}
                  name="isActive"
                  color="primary"
                  checked={isActive}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setIsActive(e.target.checked);
                  }}
                />
              }
              label="Campanha ativa?"
              labelPlacement="start"
            />
          </Grid>
        </Grid>
      </Paper>

      {/*  indicador */}
      <Grid item xs>
        <Typography
          variant="h2"
          color="textPrimary"
          className={commonStyles.textBold}
        >
          Pontuação do indicador
        </Typography>
      </Grid>
      <Paper elevation={0} className={styles.rootPaper}>
        {referralConfig &&
          referralConfig
            .filter(ref => !ref.deleted)
            .map((referral, index) => {
              return (
                <Grid container spacing={1} key={index}>
                  <Grid item xs={6} md={3}>
                    <MyOutlinedTextField
                      id="referral-start-point"
                      name="referral-start-point"
                      label="Faixa inicial"
                      fullWidth
                      value={referral.startPoint}
                      onChange={e =>
                        handleChangeReferralStartPoint(e, referral, index)
                      }
                      error={Boolean(referral.error?.startPoint)}
                      helperText={
                        referral.error?.startPoint
                          ? referral.error?.startPoint
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <MyOutlinedTextField
                      id="referral-end-point"
                      name="referral-end-point"
                      label="Faixa final"
                      fullWidth
                      value={referral.endPoint}
                      onChange={e =>
                        handleChangeReferralEndPoint(e, referral, index)
                      }
                      error={Boolean(referral.error?.endPoint)}
                      helperText={
                        referral.error?.endPoint
                          ? referral.error?.endPoint
                          : null
                      }
                    />
                  </Grid>
                  <Grid item xs={6} md={2}>
                    <MyOutlinedTextField
                      id="referral-score"
                      name="referral-score"
                      label="Pontuação"
                      fullWidth
                      value={referral.score}
                      onChange={e =>
                        handleChangeReferralScore(e, referral, index)
                      }
                      error={Boolean(referral.error?.score)}
                      helperText={
                        referral.error?.score ? referral.error?.score : null
                      }
                    />
                  </Grid>

                  <Grid item xs={6} md={3}>
                    <MySelectForm
                      contracted
                      itens={recurrenceList?.map(recurrence => ({
                        title: recurrence.label,
                        value: recurrence.value,
                      }))}
                      onChange={(e: any) =>
                        handleChangeReferralRecurrence(e, referral, index)
                      }
                      value={referral.recurrence}
                      helperText={null}
                      title="Recorrência"
                      error={false}
                      multiple={false}
                      menuProps={{
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'left',
                        },
                        transformOrigin: {
                          vertical: 'top',
                          horizontal: 'left',
                        },
                        getContentAnchorEl: null,
                      }}
                    />
                  </Grid>
                  <Grid item xs container alignItems="center">
                    <Button
                      onClick={() =>
                        handleRemoveReferralConfig(referral, index)
                      }
                    >
                      <DeleteOutlinedIcon />
                    </Button>
                  </Grid>
                </Grid>
              );
            })}
        <Grid item xs>
          <Button
            id="btn-cancel"
            color="primary"
            onClick={handleAddReferralConfig}
          >
            Adicionar
          </Button>
        </Grid>
      </Paper>
      {/*  indicado */}

      {/* make it works <FormReferredConfiguration /> */}
      <Grid item xs>
        <Typography
          variant="h2"
          color="textPrimary"
          className={commonStyles.textBold}
        >
          Pontuação do indicado
        </Typography>
      </Grid>
      <Paper elevation={0} className={styles.rootPaper}>
        {referredConfig &&
          referredConfig.map((referred, index) => {
            return (
              <Grid container spacing={3} key={index}>
                <Grid item xs={12} md={6}>
                  <MyOutlinedTextField
                    id="referred-score"
                    name="referred-score"
                    label="Pontuação"
                    fullWidth
                    value={referred.score}
                    onChange={e =>
                      handleChangeReferredScore(e, referred, index)
                    }
                    error={Boolean(referred.error)}
                    helperText={referred.error ? referred.error : null}
                  />
                </Grid>

                <Grid item xs={6}>
                  <MySelectForm
                    contracted
                    itens={recurrenceList?.map(recurrence => ({
                      title: recurrence.label,
                      value: recurrence.value,
                    }))}
                    onChange={(e: any) =>
                      handleChangeReferredRecurrence(e, referred, index)
                    }
                    value={referred.recurrence}
                    helperText={null}
                    title="Recorrência"
                    error={false}
                    multiple={false}
                    menuProps={{
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                      },
                      transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                      },
                      getContentAnchorEl: null,
                    }}
                  />
                </Grid>
              </Grid>
            );
          })}
      </Paper>
    </Grid>
  );
};

export default RankingSeasonNew;
