import React from 'react';
import {
  TextField,
  InputAdornment,
  CircularProgress,
  StandardTextFieldProps,
} from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';

interface MyOutlinedTextFieldProps extends StandardTextFieldProps {
  isLoading?: boolean;
  inputLabelprops?: any;
  changeFocusOnEnter?: () => void;
  // isDisabled is here only to allow us to control
  // this property whe using InputMasks
  isDisabled?: boolean;
  className?: string;
  icon?: any;
}

const MyOutlinedTextField: React.FC<MyOutlinedTextFieldProps> = props => {
  const {
    variant,
    InputProps,
    isLoading,
    isDisabled,
    changeFocusOnEnter,
    className,
    icon,
    ...rest
  } = props;

  const onKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const isEnterKey = e?.keyCode && e.keyCode.toString() === '13';
    changeFocusOnEnter && isEnterKey && changeFocusOnEnter();
  };

  return (
    <TextField
      {...rest}
      variant="outlined"
      disabled={isDisabled || props.disabled}
      InputLabelProps={props.InputLabelProps}
      InputProps={{
        className: props.className ? props.className : '',
        ...InputProps,
        notched: false,
        onKeyDown: onKeyDown,
        endAdornment: isLoading ? (
          <InputAdornment position="end">
            <CircularProgress size="1em" />
          </InputAdornment>
        ) : props.error ? (
          <InputAdornment position="end">
            <ErrorIcon
              color="primary"
              style={{ width: '1em', height: '1em' }}
            />
          </InputAdornment>
        ) : (
          icon
        ),
      }}
    />
  );
};

export default MyOutlinedTextField;
