export enum HomeTypes {
  LOAD_CURRENT_BOX = '@home/LOAD_CURRENT_BOX',
  LOAD_PREVIOUS_BOXES = '@home/LOAD_PREVIOUS_BOXES',
  LOAD_CURRENT_BOX_DONE = '@home/LOAD_CURRENT_BOX_DONE',
  LOAD_PREVIOUS_BOXES_DONE = '@home/LOAD_PREVIOUS_BOXES_DONE',
  LOAD_CURRENT_BOX_FAIL = '@home/LOAD_CURRENT_BOX_FAIL',
  LOAD_PREVIOUS_BOXES_FAIL = '@home/LOAD_PREVIOUS_BOXES_FAIL',
}

export interface BoxHome {
  productId: number;
  name: string;
  description: string;
  image: string;
  boxDate: string;
  owned?: boolean;
}

export interface HomeState {
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly currentBox: BoxHome;
  readonly previousBoxes: BoxHome[];
}
