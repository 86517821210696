import { call, put } from 'redux-saga/effects';
import CreditCardService from 'services/creditCardService';
import { loadCreditCardsDone, loadCreditCardsFail } from './actions';

export function* loadCreditCardsSaga() {
  try {
    const { data, total } = yield call(CreditCardService.getCreditCards);
    yield put(loadCreditCardsDone({ data, total }));
  } catch (err) {
    yield put(loadCreditCardsFail());
  }
}
