import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  Grid,
  Typography,
} from '@material-ui/core';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import FeedbackDialog from 'components/generals/dialog/FeedbackDialog';
import { FeedbackDialogPropsBase } from 'components/generals/dialog/dialogTypes';
import { ReactComponent as SuccessIcon } from 'assets/img/svg/icon_active_sucess.svg';
import { ReactComponent as ErrorIcon } from 'assets/img/svg/icon_active_error.svg';

import AuthService from 'services/authService';

import { AppState } from 'store';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import { Modal } from 'store/ducks/nav/types';

import { validationSchema } from './utils';

import useStyles from './styles';

const RecoverClientForm = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  const { open, maxWidth, component: Component, props } = useSelector<
    AppState,
    Modal
  >(({ nav }: AppState) => nav.modal);

  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onSubmit',
    validationSchema,
  });

  const onSubmit = async ({ email }: Record<string, string>) => {
    setIsLoading(true);

    if (email) {
      try {
        await AuthService.recoverPassword(email);

        const modalProps: FeedbackDialogPropsBase = {
          title: 'Sua solicitação foi enviada com sucesso!',
          message: `Um e-mail foi enviado para ${email}, siga as instruções para recuperar sua senha.`,
          icon: SuccessIcon,
        };

        dispatch(openModal(FeedbackDialog, modalProps));
      } catch (error) {
        const modalProps: FeedbackDialogPropsBase = {
          title: 'Algo de errado aconteceu',
          message: (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                dispatch(closeModal());
                history.push('/login');
              }}
              classes={{ root: classes.feedbackButton }}
            >
              Voltar ao login
            </Button>
          ),
          disableCloseButton: true,
          icon: ErrorIcon,
        };

        dispatch(openModal(FeedbackDialog, modalProps));
      }
    }
    reset();
  };

  return (
    <Grid item xs={12} md={6} className={classes.containerWrapper}>
      <Typography className={classes.titleText}>Redefinir senha</Typography>
      <Typography className={classes.subtitleText}>
        Para redefinir sua senha, insira seu e-mail. Você receberá um e-mail com
        as instruções para prosseguir.
      </Typography>
      <Box className={classes.containerForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MyOutlinedTextField
            fullWidth
            name="email"
            label="Email"
            className={classes.inputText}
            classes={{ root: classes.inputWrapper }}
            inputRef={register}
            error={Boolean(errors.email)}
            helperText={errors.email ? errors.email.message : null}
          />
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress className={classes.progress} size={20} />
            ) : (
              'Enviar'
            )}
          </Button>
        </form>
      </Box>

      <Dialog
        maxWidth={maxWidth}
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        {Component ? <Component {...props} /> : null}
      </Dialog>
    </Grid>
  );
};

export default RecoverClientForm;
