import * as Yup from 'yup';
import { CondOperator } from '@nestjsx/crud-request';

/* onseProperty
title
description
author
url
urlThumbnail
keyWord
// */

export const validationSchema = Yup.object({
  title: Yup.string().required(),
  author: Yup.string().required(),
  url: Yup.string().required(),
  // urlThumbnail: Yup.string().required(),
  keyWord: Yup.string().required(),
});

export const searchObject = [
  {
    field: 'title',
    operator: CondOperator.CONTAINS_LOW,
  },
];

export const VideoFilters = {
  title: { filterTitle: 'Titulo do video', value: undefined },
};
