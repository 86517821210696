import { makeStyles, createStyles } from '@material-ui/core/styles';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme =>
  createStyles({
    radioWrapper: {
      border: `1px solid ${theme.palette.grey['500']}`,
      padding: '6px',
      borderRadius: 8,
      margin: '0 0 8px 0',
      '& .MuiTypography-root': {
        fontSize: 16,
        color: theme.palette.grey['800'],
        fontWeight: 500,
      },
    },
    radioDisbaledWrapper: {
      border: `1px solid ${Colors.SILVER}`,
      padding: '6px',
      borderRadius: 8,
      margin: '0 0 8px 0',
      '& .MuiTypography-root': {
        fontSize: 16,
        color: theme.palette.grey['800'],
        fontWeight: 500,
      },
      '& .MuiTypography-body1': {
        color: Colors.SILVER,
        fontWeight: 'bold',
      },
      cursor: 'default',
    },
    radioPayment: {
      '&$checked': {
        color: Colors.RED,
      },
    },
    radioDisabledPayment: {
      cursor: 'default',
      '&:hover': {
        backgroundColor: Colors.SILVER,
      },
      '&$checked': {
        color: Colors.SILVER,
      },
    },
    checked: {},
    paymentTypeLabel: {
      color: Colors.BLACK,
    },
    itemWrapper: {
      [theme.breakpoints.up('md')]: {
        marginRight: 8,
      },
    },
    radioGroupWrapper: {
      width: '100%',
      marginTop: 16,
    },
    radioButtonIcon: {
      height: 18,
      width: 18,
    },
    paymentAlert: {
      marginTop: 0,
      marginLeft: 4,
      fontSize: 12,
      color: theme.palette.primary.main,
    },
    input: {
      padding: '20px 12px 10px',
    },
    label: {
      color: Colors.DARK_GRAY,
    },
    bankslipText: {
      fontSize: 14,
      color: Colors.DARK_GRAY,
      fontWeight: 400,
    },
    bankslipTextDisabled: {
      fontSize: 14,
      color: Colors.SILVER,
      fontWeight: 400,
    },
    bottom: {
      marginBottom: 12,
    },
  }),
);

export default useStyles;
