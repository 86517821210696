import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithSaga } from './apiService';
import { Video } from 'types/generals';

export default class VideoService {
  static async getAllVideos(query?: CreateQueryParams) {
    try {
      const data = await getWithSaga('videos', query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async createVideo(video: Partial<Video>) {
    try {
      const data = await api.post('videos', video);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updateVideo(video: Partial<Video>, id?: number) {
    try {
      const data = await api.patch(`videos/${id}`, video);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async deleteVideo(id?: number) {
    try {
      const data = await api.delete(`videos/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  }
}
