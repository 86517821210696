import React from 'react';
import { Grid } from '@material-ui/core';

import useStyles from './styles';

import { ReactComponent as Logo } from 'assets/img/svg/logo_mbc_text.svg';

const SectionLogo: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid item xs={12} md={6} className={classes.wrapperLogo}>
      <Logo className={classes.logo} />
    </Grid>
  );
};

export default SectionLogo;
