import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import formUseStyles from 'components/generals/forms/register/styles';

import { FormTitleProps } from './types';
import { useCommonStyles } from 'styles/common';
import { useStyles } from './styles';
import clsx from 'clsx';
import ActionsButton from '../buttons/actionsButton/actionsButton';

const FormTitle: FC<FormTitleProps> = ({
  title = 'Informações principais',
  onSubmit,
  cancelRedirectPath,
  isLoading,
  isDisabled,
  menuItems,
}) => {
  const formStyles = formUseStyles();
  const styles = useStyles();
  const history = useHistory();
  const commonStyles = useCommonStyles();

  const Actions = () => (
    <>
      {menuItems ? (
        <Grid item xs={12} sm={6} md={2}>
          <Grid
            container
            alignItems="center"
            className={formStyles.actionsWrapper}
          >
            <ActionsButton
              hasErrorFile={false}
              menuItems={menuItems}
              buttonText="Ações"
            />
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </>
  );

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      className={styles.rootGrid}
    >
      <Grid item xs={12} sm={6} md={5}>
        <Typography
          variant="h2"
          color="textPrimary"
          className={commonStyles.textBold}
        >
          {title}
        </Typography>
      </Grid>
      <Grid item xs={12} sm={6} md={5}>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          spacing={2}
          className={formStyles.actionsWrapper}
        >
          <Button
            id="btn-cancel"
            color="primary"
            className={clsx(formStyles.buttonLabel, styles.btnCancel)}
            onClick={() => history.push(cancelRedirectPath)}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${formStyles.buttonLabel} ${formStyles.buttonWrapper}`}
            onClick={onSubmit}
            disabled={isDisabled || isLoading}
          >
            {isLoading ? (
              <CircularProgress className={formStyles.progress} size={20} />
            ) : (
              'Salvar'
            )}
          </Button>
        </Grid>
      </Grid>
      <Actions />
    </Grid>
  );
};

export default FormTitle;
