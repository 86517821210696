import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  planInfoGrid: {
    height: 380,
    border: '1px solid',
    borderColor: Colors.GRAY,
    borderRadius: 8,
    padding: 20,
  },
  selectedPlanText: {
    color: Colors.MEDIUM_GRAY,
    fontWeight: 'bold',
  },
  boldText: {
    fontWeight: 'bold',
  },
  planMessage: {
    color: Colors.ORANGE,
    fontWeight: 'bold',
  },
  oldPrice: {
    textDecoration: 'line-through',
    fontWeight: 'bold',
    textAlign: 'right',
    color: Colors.DARK_GRAY,
  },
  termsText: {
    lineHeight: '18px',
    fontSize: 12,
    fontWeight: 500,
    color: Colors.GRAY,
  },
  planImage: {
    width: 52,
    height: 52,
  },
  planPrice: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
  },
  planPriceInfo: {
    color: Colors.MEDIUM_GRAY,
    fontWeight: 'bold',
    fontSize: 12,
  },
  gridPadding: {
    padding: 0,
  },
});
