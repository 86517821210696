import { CondOperator } from '@nestjsx/crud-request';
import * as Yup from 'yup';

export const BOX_ALL_PLANS = 'all';

export const validationSchema = Yup.object({
  name: Yup.string().required('Campo obrigatório'),
  plans: Yup.string().required('Campo obrigatório'),
  startDate: Yup.string(),
  endDate: Yup.string(),
  weight: Yup.string().required('Campo obrigatório'),
  description: Yup.string().required('Campo obrigatório'),
  images: Yup.array().required('Campo obrigatório'),
  item: Yup.string(),
  sku: Yup.string().required('Campo obrigatório'),
});

export const searchObject = [
  {
    field: 'name',
    operator: CondOperator.CONTAINS_LOW,
  },
  {
    field: 'sku',
    operator: CondOperator.CONTAINS_LOW,
  },
];

export const boxesFilters = {
  name: { filterTitle: 'Nome do box', value: undefined },
  startDate: { filterTitle: 'Data de início', value: undefined },
  endDate: { filterTitle: 'Data fim', value: undefined },
};
