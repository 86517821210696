import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { closeModal } from 'store/ducks/nav/actions';
import { Range } from 'store/ducks/sigep/label/types';
import { TrackingRangeProps } from './dialogTypes';
import { useDialogStyles } from './useStyles';
import { trackingSchema } from './utils';

export enum TRANSPORT_ALIAS {
  SEDEX = 'vipp-sedex',
  PAC = 'vipp-pac',
  MODICO = 'vipp-impresso',
}

export enum SERVICE_NUMBER {
  SEDEX = '03220',
  PAC = '03298',
  MODICO = '20125',
}

export const selectNameTransport = (serviceNumber: string) => {
  switch (serviceNumber) {
    case SERVICE_NUMBER.MODICO:
      return TRANSPORT_ALIAS.MODICO;
    case SERVICE_NUMBER.PAC:
      return TRANSPORT_ALIAS.SEDEX;
    default:
      return TRANSPORT_ALIAS.PAC;
  }
};

const TrackingRangeDialog: FC<TrackingRangeProps> = ({
  serviceName,
  serviceNumber,
  submitAction,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const classes = useDialogStyles();
  const { control, handleSubmit, errors } = useForm({
    validationSchema: trackingSchema,
  });

  const onSubmit = async (data: any) => {
    const alias = selectNameTransport(data.serviceNumber);
    try {
      const { rangeStart, rangeEnd } = data;
      const submitData: Range = {
        serviceName,
        serviceNumber,
        rangeStart,
        rangeEnd,
        alias,
      };
      const result = await submitAction(submitData);

      if (result.name && result.name.toLowerCase() === 'error') {
        dispatch(notifyError('Houve uma falha na operação'));
      } else {
        dispatch(notifySuccess('Sucesso ao adicionar as faixas de códigos'));
      }
    } catch (err) {
      dispatch(notifyError('Houve um erro ao adicionar faixas de códigos'));
      throw new Error(err);
    } finally {
      dispatch(closeModal());
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid
          container
          className={classes.infoBoxSection}
          justify="space-between"
          alignItems="center"
        >
          Adicionar faixa de códigos
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Typography variant="h4" className={classes.paddingInfo}>
          Para inserir uma faixa de código a sequência deve corresponder ao
          seguinte padrão: AB12345678 BR.
        </Typography>
      </DialogTitle>
      <DialogContent className={classes.trackDialogMBottom}>
        <Grid container direction="column" spacing={3} component="form">
          <Grid item xs>
            <Controller
              as={MyOutlinedTextField}
              required
              fullWidth
              control={control}
              name="rangeStart"
              label="Início da sequência numérica"
              error={Boolean(errors.rangeStart)}
            />
            {errors.rangeStart && (
              <FormHelperText id="helper-range-start">
                <Typography variant="h5" color="primary">
                  {errors.rangeStart.message}
                </Typography>
              </FormHelperText>
            )}
          </Grid>
          <Grid item xs>
            <Controller
              as={MyOutlinedTextField}
              required
              fullWidth
              control={control}
              name="rangeEnd"
              label="Fim da sequência numérica"
              error={Boolean(errors.rangeEnd)}
            />
            {errors.rangeEnd && (
              <FormHelperText id="helper-range-start">
                <Typography variant="h5" color="primary">
                  {errors.rangeEnd.message}
                </Typography>
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.infoBoxSection}>
        <Button
          color="primary"
          size="large"
          className={styles.buttonLabel}
          style={{ marginRight: 30 }}
          onClick={() => dispatch(closeModal())}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          disableElevation
          color="primary"
          className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
          onClick={handleSubmit(onSubmit)}
        >
          Adicionar
        </Button>
      </DialogActions>
    </Grid>
  );
};

export default TrackingRangeDialog;
