import { Reducer } from 'redux';
import { CreditCardState, CreditCardTypes } from './types';

const INITIAL_STATE: CreditCardState = {
  data: [],
  total: 0,
  hasError: false,
  isLoading: true,
};

const CreditCardReducer: Reducer<CreditCardState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case CreditCardTypes.LOAD_CREDIT_CARD_REQUEST:
      return { ...state, isLoading: true };
    case CreditCardTypes.LOAD_CREDIT_CARD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        total: action.payload.total,
        data: action.payload.data,
      };
    case CreditCardTypes.LOAD_CREDIT_CARD_FAIL:
      return {
        ...state,
        hasError: true,
        isLoading: false,
      };
    default:
      return state;
  }
};

export default CreditCardReducer;
