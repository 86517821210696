import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useSecurityStyles = makeStyles((theme: Theme) =>
  createStyles({
    paperContainer: { height: '100vh' },
    paperTitle: {
      marginBottom: 40,
    },
    paperTitleBold: { fontWeight: 'bold', marginLeft: 5 },
    mb24: { marginBottom: 24 },
    mt32: { marginTop: 32 },
  }),
);
