import CrudList from 'components/generals/lists/CrudList';
import React, { FC } from 'react';
import { ColumnProps } from 'types';

const QueueList: FC = () => {
  const columns: ColumnProps[] = [
    { label: 'Processo', getter: 'queueDisplayName' },
    { label: 'Processando', getter: 'countProcessing' },
    { label: 'Finalizados', getter: 'countCompleted' },
    { label: 'Falhas', getter: 'countFailed' },
  ];

  return (
    <>
      <CrudList
        title="Relatório de processamento"
        url="queues/report"
        columns={columns}
        showSearch={false}
        rowsPerPage={50}
      />

      <CrudList
        title="Relatório de processamento"
        url="queues/report-scheduler"
        columns={columns}
        showSearch={false}
        rowsPerPage={50}
      />
    </>
  );
};

export default QueueList;
