import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithSaga } from './apiService';
import { StoreCategory } from 'types/generals';

export default class StoreCategoriesService {
  static async getAllCategories(query?: CreateQueryParams) {
    try {
      const data = await getWithSaga('store-categories', query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async createStoreCategories(object: Partial<StoreCategory>) {
    try {
      const data = await api.post('store-categories', object);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updateStoreCategories(
    id: number,
    object: Partial<StoreCategory>,
  ) {
    try {
      const data = await api.put(`store-categories/${id}`, object);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async deleteStoreCategory(id: number) {
    try {
      const data = await api.delete(`store-categories/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  }
}
