import React, { useEffect, useState } from 'react';
import { FormControlLabel, Grid, Paper, Typography } from '@material-ui/core';

import { Controller } from 'react-hook-form';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { useStyles } from './styles';
import MySwitch from 'components/generals/input/MySwitch';
import { StoreSettingsProps } from './types';
import MyNumberFormat from 'components/generals/input/MyNumberFormat';

const StoreSettingsFormShipping: React.FC<StoreSettingsProps> = ({
  register,
  control,
  errors,
  storeSettings,
}) => {
  const styles = useStyles();

  const [isActive, setIsActive] = useState<boolean>(
    storeSettings.freightConfig.isActive,
  );

  useEffect(() => {
    setIsActive(storeSettings.freightConfig.isActive);
  }, [storeSettings.freightConfig.isActive]);

  return (
    <>
      <Grid className={styles.marginTop}>
        <Typography className={styles.bannerTitle} variant="h2">
          Configurações de frete
        </Typography>
        <Paper elevation={0} className={styles.rootPaper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="freeFreightFromThisValue"
                control={control}
                as={({ value, onChange }) => (
                  <MyOutlinedTextField
                    id="freeFreightFromThisValue"
                    label="Zerar frete a partir de"
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      inputComponent: MyNumberFormat as any,
                    }}
                    fullWidth
                    error={Boolean(errors.freeFreightFromThisValue)}
                    helperText={
                      errors.freeFreightFromThisValue
                        ? errors.freeFreightFromThisValue.message
                        : null
                    }
                    disabled={!isActive}
                  />
                )}
              />
            </Grid>

            <Grid item xs>
              <FormControlLabel
                control={
                  <MySwitch
                    inputRef={register}
                    name="isShippingActive"
                    checked={isActive}
                    color="primary"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsActive(e.target.checked);
                    }}
                  />
                }
                label="Configuração ativa?"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default StoreSettingsFormShipping;
