import { ORDER_TYPE, PAYMENT_TYPE, User } from '.';

export enum NOTIFICATION_GROUP {
  LOGISTICS = 'logistics',
  PAYMENT_STORE = 'payment_store',
  PAYMENT_SIGNATURE = 'payment_signature',
  SCHEDULE = 'schedule',
  BULK_ACTION = 'bulk action',
}

export enum NOTIFICATION_TYPE {
  // PEDIDO
  SUBMITTED = 'Pedido efetuado',
  // EM SEPARAÇÃO
  IN_DIVISION = 'Em separação',
  // ENVIO
  IN_SHIPPING = 'Em trânsito',
  DELIVERY_FAILURE = 'Falha na entrega',
  WAITING_REMOVE = 'Aguardando retirada',
  RETURNED_SENDER = 'Devolvido ao remetente',
  DELIVERY = 'Saiu para entrega',
  // CONCLUIDO
  DELIVERED = 'Entregue',
  // CANCELADO
  CANCELED = 'Cancelado',
  RESEND = 'Reenvio',
  // PAYMENT STORE
  PENDING_STORE = 'Pagamento pendente para cartão na loja',
  PAID_STORE = 'Pagamento confirmado para cartão na loja',
  FAILED_STORE = 'Pagamento recusado para cartão na loja',
  PENDING_CANCELED_STORE = 'Cancelamento pendente para cartão na loja',
  PAYMENT_REFUND_STORE = 'Pagamento devolvido para cartão na loja',
  // BANKSLIP
  PENDING_BANKSLIP_STORE = 'Pagamento pendente para boleto na loja',
  PAID_BANKSLIP_STORE = 'Pagamento confirmado para boleto na loja',
  FAILED_BANKSLIP_STORE = 'Pagamento recusado para boleto na loja',
  PENDING_CANCELED_BANKSLIP_STORE = 'Cancelamento pendente para boleto na loja',
  PAYMENT_REFUND_BANKSLIP_STORE = 'Pagamento devolvido para boleto na loja',
  //PAYMENT SIGNATURE
  PENDING_SIGNATURE = 'Pagamento pendente para cartão na assinatura',
  NEW_PENDING_SIGNATURE = 'Bem vindo, pagamento pendente para cartão na assinatura',
  PAID_SIGNATURE = 'Pagamento confirmado para cartão na assinatura',
  FAILED_SIGNATURE = 'Pagamento recusado para cartão na assinatura',
  PENDING_CANCELED_SIGNATURE = 'Cancelamento pendente para cartão na assinatura',
  PAYMENT_REFUND_SIGNATURE = 'Pagamento devolvido para cartão na assinatura',
  // BANKSLIP
  PENDING_BANKSLIP_SIGNATURE = 'Pagamento pendente para boleto na assinatura',
  NEW_PENDING_BANKSLIP_SIGNATURE = 'Pagamento pendente para boleto da nova assinatura',
  PAID_BANKSLIP_SIGNATURE = 'Pagamento confirmado para boleto na assinatura',
  FAILED_BANKSLIP_SIGNATURE = 'Pagamento recusado para boleto na assinatura',
  PENDING_CANCELED_BANKSLIP_SIGNATURE = 'Cancelamento pendente para boleto na assinatura',
  PAYMENT_REFUND_BANKSLIP_SIGNATURE = 'Pagamento devolvido para boleto na assinatura',
  // SCHEDULE
  MONTHLY_SIGNATURE_BANKSLIP = 'Boleto mensal da assinatura',
  // BULK ACTION
  SEND_SIGNATURE_BANKSLIP = 'Envio de boleto',
}

export interface INotificationPermission {
  sendEmail: boolean;
  sendSms: boolean;
  sendPopup: boolean;
}

export interface BaseEntity {
  id?: number;
  createdAtd?: string;
  updatedAt?: string;
}

export interface StoreNotification extends BaseEntity {
  type: NOTIFICATION_TYPE;
  group: NOTIFICATION_GROUP;
  paymentType?: PAYMENT_TYPE;
  orderType?: ORDER_TYPE;
  permissions: INotificationPermission;
  emailMessage: string;
  smsMessage: string;
  popupMessage: string;
}

export interface UserNotificationConfig extends BaseEntity {
  user: User;
  groups: NOTIFICATION_GROUP[];
}
