import { Signature, Address, Plan } from 'types/generals';
import { CreditCard } from 'types/CreditCard';

export enum ChangePlanTypes {
  LOAD_STEP_PLAN = '@change-plan/LOAD_STEP_PLAN',
  NEXT_STEP_PLAN = '@change-plan/NEXT_STEP_PLAN',
  PREVIOUS_STEP_PLAN = '@change-plan/PREVIOUS_STEP_PLAN',
  RESET_STEP_PLAN = '@change-plan/RESET_STEP_PLAN',
  SET_ADDRESS_PAYLOAD = '@change-plan/SET_ADDRESS_PAYLOAD',
  SET_SIGNATURE_PAYLOAD = '@change-plan/SET_SIGNATURE_PAYLOAD',
  SET_PLAN_PAYLOAD = '@change-plan/SET_PLAN_PAYLOAD',
  SET_PAYMENT_TYPE = '@change-plan/SET_PAYMENT_TYPE',
}

export interface ChangePlanState {
  readonly step: number;
  readonly totalSteps: number;
  readonly signature: Signature;
  readonly address: Address;
  readonly card: CreditCard;
  readonly plan: Plan;
  readonly paymentType: string;
}
