import { makeStyles, styled } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';
import { ReactComponent as IconWaiting } from 'assets/img/svg/waiting_order.svg';
import { ReactComponent as IconDone } from 'assets/img/svg/done_order.svg';
import { ReactComponent as IconError } from 'assets/img/svg/error_order.svg';

export const useStyles = makeStyles({
  root: {
    width: '100%',
    margin: theme.spacing(4, 0),
  },
  rootStatusCanceled: {
    width: '100%',
    margin: 0,
  },
  label: {
    margin: theme.spacing(3, 0, 0),
    color: Colors.DARK_GRAY,
  },
  actionButton: {
    color: Colors.RED,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  lastUpdate: {
    fontWeight: 'bold',
  },
  lastUpdateDate: {
    fontWeight: 'normal',
  },
  paperLastStatus: {
    margin: theme.spacing(3, 8),
    padding: theme.spacing(5),
    borderRadius: '8px',
    textAlign: 'center',
    fontSize: 16,
  },
  footerPaperLastStatus: {
    padding: theme.spacing(0, 8),
  },
  waitingStatusOrder: {
    color: Colors.DARK_GRAY,
  },
  doneStatusOrder: {
    color: Colors.BRIGHT_DARK_GREEN,
  },
  failedStatusOrder: {
    color: Colors.LIGHT_RED,
  },
});

export const useIconStyles = makeStyles({
  root: {
    color: Colors.LIGHTER_GRAY,
    display: 'flex',
    width: 'auto',
    height: 22,
    alignItems: 'center',
  },
  circle: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: Colors.LIGHT_GRAY,
  },
  activedCircle: {
    borderRadius: '50%',
    backgroundColor: Colors.RED,
    color: Colors.LIGHTER_GRAY,
    justify: 'center',
    alignItems: 'center',
  },
  completed: {
    width: 32,
    height: 32,
    borderRadius: '50%',
    backgroundColor: Colors.RED,
    color: Colors.WHITE,
    zIndex: 1,
    fontSize: 18,
  },
  iconCompleted: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1),
  },
});

export const IconErrorOrder = styled(IconError)({
  height: 21,
  width: 21,
  marginRight: 10,
});

export const IconDoneOrder = styled(IconDone)({
  height: 21,
  width: 21,
  marginRight: 10,
});

export const IconWaitingOrder = styled(IconWaiting)({
  height: 21,
  width: 21,
  marginRight: 10,
});
