import { createStore, Store, applyMiddleware, compose } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import createSagaMiddleware from 'redux-saga';
import { UserState } from './ducks/user/types';
import { PageFilterState } from './ducks/page-filters/types';
import { AuthState } from './ducks/auth/types';
import { LayoutState } from './ducks/layout/types';
import { NotificationState } from './ducks/notification/types';
import { NavState } from './ducks/nav/types';
import { InvoiceState } from './ducks/invoice/types';
import { PlpOneState, PlpState } from './ducks/sigep/plp/types';

import rootReducer from './ducks/rootReducer';
import rootSaga from './ducks/rootSaga';
import { OrderOneState, OrderState } from './ducks/order/types';
import { LabelState } from './ducks/sigep/label/types';
import { TrackingState } from './ducks/sigep/tracking/types';
import { CreditCardState } from './ducks/payment/creditCard/types';
import {
  SignatureState,
  SingleSignatureState,
} from './ducks/generals/signature/types';
import { OnePlanState, PlanState } from './ducks/generals/plan/types';
import { VideoState } from './ducks/generals/video/types';
import { FileState } from './ducks/generals/file/types';
import { CouponsState } from './ducks/coupons/types';
import { CategoriesState } from './ducks/categories/types';
import { ShippingState, ShippingOneState } from './ducks/shipping/types';
import { CartState } from './ducks/cart/types';
import { StoreState, StoreProductState } from './ducks/store/types';
import { BoxesState } from './ducks/generals/boxes/types';
import { AddressState, OneAddressState } from './ducks/generals/address/types';
import { HomeState } from './ducks/client/home/types';
import { TransportState } from './ducks/transport/types';
import { StoreSettingsState } from './ducks/store/storeSettings/types';
import { BannerState } from './ducks/generals/banner/types';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { ViewState } from './ducks/views/types';
import { ChangePlanState } from './ducks/change-plan/types';
import { RecurrenceState } from './ducks/recurrences/types';
import { LeadsState } from './ducks/leads/types';
import { BenefitsState } from './ducks/benefits/types';
import { DataFreteTransportState } from './ducks/dataFreteTransport/types';
import { PreShippimentOrderState } from './ducks/preShippimentOrders/types';
import { StoreNotificationState } from './ducks/store/storeNotification/types';
import { RankingState } from './ducks/ranking/types';
import { EnhancedProductState } from './ducks/enhancedProduct/types';
import { ViewUserLastOrderState } from './ducks/bulk-actions/view-user-last-order/types';
import { ViewUserReactivationState } from './ducks/bulk-actions/view-user-reactivation/types';
import { ViewUserTurbineState } from './ducks/bulk-actions/view-user-turbine/types';

export interface AppState {
  user: UserState;
  auth: AuthState;
  pageFilter: PageFilterState;
  layout: LayoutState;
  notification: NotificationState;
  nav: NavState;
  invoice: InvoiceState;
  plpListReducer: PlpState;
  plpReducer: PlpOneState;
  ordersListReducer: OrderState;
  orderReducer: OrderOneState;
  orderMonthReducer: OrderOneState;
  label: LabelState;
  tracking: TrackingState;
  creditCard: CreditCardState;
  signature: SignatureState;
  oneSignature: SingleSignatureState;
  videos: VideoState;
  plans: PlanState;
  onePlan: OnePlanState;
  file: FileState;
  couponReducer: CouponsState;
  category: CategoriesState;
  shippingListReducer: ShippingState;
  shippingReducer: ShippingOneState;
  cartReducer: CartState;
  storeReducer: StoreState;
  storeProductReducer: StoreProductState;
  enhancedProduct: EnhancedProductState;
  boxes: BoxesState;
  address: AddressState;
  addressReducer: OneAddressState;
  home: HomeState;
  transport: TransportState;
  viewState: ViewState;
  storeNotification: StoreNotificationState;
  banner: BannerState;
  ranking: RankingState;
  storeSettings: StoreSettingsState;
  changePlan: ChangePlanState;
  recurrences: RecurrenceState;
  leads: LeadsState;
  benefits: BenefitsState;
  dataFreteTransport: DataFreteTransportState;
  preShippimentOrderReducer: PreShippimentOrderState;
  viewUserSignatureLastOrder: ViewUserLastOrderState;
  viewUserReactivation: ViewUserReactivationState;
  viewUserTurbine: ViewUserTurbineState;
}

const persisteReducer = persistReducer(
  {
    key: 'mbc',
    storage,
    whitelist: ['auth', 'pageFilter'],
  },
  rootReducer,
);

const sagaMiddleware = createSagaMiddleware();

const enhancer = composeWithDevTools(applyMiddleware(sagaMiddleware));

const store: Store<AppState> = createStore(persisteReducer, enhancer);

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;
