import { Button, Grid, Paper, Typography } from '@material-ui/core';
import useStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { StatusCodes } from 'http-status-codes';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RolesService from 'services/RolesService';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { Roles, User } from 'types/generals';
import { defaultValues, validationSchema } from '../utils';

import UserService from 'services/userService';
import {
  USER_UPDATED_ERROR,
  USER_UPDATED_NOTIFICATIONS_ERROR,
  USER_UPDATED_SUCCESS,
} from 'utils/messages/user';
import MySelectForm from 'components/generals/input/MySelectForm';
import UserNotificationConfig from 'components/generals/UserNotificationConfig';
import NotificationService from 'services/notificationService';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import { UserDataProps } from 'components/generals/dialog/dialogTypes';
import AddRakingScoreDialog from 'components/generals/dialog/AddRakingScoreDialog';
import {
  loadActiveRankingRequest,
  loadRankingRequest,
} from 'store/ducks/ranking/actions';
import { CondOperator } from '@nestjsx/crud-request';
import { RankingState } from 'store/ducks/ranking/types';
import { AppState } from 'store';
import UserScoreRanking from 'pages/client/ranking/userRanking/UserScoreRanking';
import RankingSeasonService from 'services/rankingSeasonService';
import { defaultUserData } from 'pages/client/ranking/utils';
import { UserScoreData } from 'types/generals/rankingSeason';
import EditUserActions from './EditUserActions';
import UserScoreHistory from 'pages/client/ranking/userRanking/UserScoreHistory';

const EditUser: FC = (props: any) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const [user] = useState<User>(props.location.state?.user);
  const [userRanking, setUserRanking] = useState<UserScoreData>(
    defaultUserData,
  );
  const [isLoading, setIsLoading] = useState(true);
  const [activeSeasonId, setActiveSeasonId] = useState(0);

  const [roles, setRoles] = useState<Roles[]>([]);
  const [notificationConfigId, setNotificationConfigId] = useState<number>(0);
  const [userRoles, setUserRoles] = useState<number[]>(
    user.roles.map(r => r.id) || [],
  );

  const {
    activeRanking,
    isLoading: isLoadingRanking,
    data: rankings,
  } = useSelector<AppState, RankingState>(({ ranking }: AppState) => ranking);

  const loadRankings = useCallback(() => {
    dispatch(
      loadRankingRequest({
        limit: 10,
      }),
    );
  }, [dispatch]);

  const loadUserRankingData = useCallback(async () => {
    if (user) {
      const response = await RankingSeasonService.fetchUserRankingData(
        user.id,
        activeSeasonId,
      );
      if (response) {
        setUserRanking(response);
      }
    }
  }, [activeSeasonId, user]);

  const loadActiveRankingSeason = useCallback(() => {
    const currentDate = new Date().toISOString();
    dispatch(
      loadActiveRankingRequest({
        limit: 1,
        filter: [
          {
            field: 'isActive',
            operator: CondOperator.EQUALS,
            value: true,
          },
          {
            field: 'startDate',
            value: currentDate,
            operator: CondOperator.LOWER_THAN_EQUALS,
          },
          {
            field: 'endDate',
            value: currentDate,
            operator: CondOperator.GREATER_THAN_EQUALS,
          },
        ],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Usuários'));
  }, [dispatch]);

  useEffect(() => {
    if (!rankings.length && isLoadingRanking) {
      loadRankings();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (rankings?.length && !activeRanking && isLoading) {
      loadActiveRankingSeason();
      setIsLoading(false);
    }
  }, [activeRanking, isLoading, loadActiveRankingSeason, rankings.length]);

  useEffect(() => {
    if (activeRanking?.id && !activeSeasonId) {
      setActiveSeasonId(activeRanking.id);
    }
  }, [activeRanking, activeSeasonId]);

  useEffect(() => {
    if (rankings.length && user?.id) {
      loadUserRankingData();
    }
  }, [loadUserRankingData, rankings.length, user]);

  useEffect(() => {
    const load = async () => {
      const response = await RolesService.findRoles();
      setRoles(response?.data || []);
    };
    load();
  }, []);

  const { handleSubmit, errors, register, control } = useForm({
    validationSchema,
    defaultValues: defaultValues(user),
  });

  const handleUpdateUserNotificationConfig = async (data: any) => {
    const response: any = await NotificationService.saveUserNotificationConfig(
      data,
      notificationConfigId,
      user,
    );
    if (![StatusCodes.OK, StatusCodes.CREATED].includes(response.status)) {
      dispatch(notifyError(USER_UPDATED_NOTIFICATIONS_ERROR));
    }
  };

  const updateUser = handleSubmit(async data => {
    try {
      const response = await UserService.updateStaff(user?.id, {
        name: data.name,
        email: data.email,
        roles: userRoles.map(r => {
          return { id: r };
        }),
      } as any);

      if (!user.isAdmin) {
        await handleUpdateUserNotificationConfig(data);
      }

      if (![StatusCodes.OK].includes(response.status)) {
        dispatch(notifyError(USER_UPDATED_ERROR));
      } else if (StatusCodes.OK === response.status) {
        dispatch(notifySuccess(USER_UPDATED_SUCCESS));
        history.push('/admin/users');
      }
    } catch (error) {
      dispatch(notifyError(USER_UPDATED_ERROR));
    }
  });

  const handleOnChangeRole = (event: any) => {
    setUserRoles(event.target.value);
  };

  const UserRankingBlock = () => (
    <>
      <Typography
        variant="h2"
        color="textPrimary"
        style={{ fontWeight: 'bold', margin: '15px 0' }}
      >
        Dados de indicação
      </Typography>
      <UserScoreRanking userRanking={userRanking} />
      <UserScoreHistory user={user} showAdminColumns={true} />
    </>
  );

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 20 }}
      >
        <Grid item xs>
          <Typography
            variant="h2"
            color="textPrimary"
            style={{ fontWeight: 'bold' }}
          >
            Dados gerais
          </Typography>
        </Grid>
        <Grid item xs>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={styles.actionsWrapper}
          >
            {rankings?.length ? (
              <EditUserActions user={user} rankings={rankings} />
            ) : (
              ''
            )}

            <Button
              id="cancel-new-box"
              color="primary"
              className={styles.buttonLabel}
              style={{ marginRight: 30 }}
              onClick={() => history.push('/admin/roles')}
            >
              Cancelar
            </Button>
            <Button
              id="save-box"
              type="submit"
              variant="contained"
              color="primary"
              className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
              onClick={() => updateUser()}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Paper elevation={0} className={styles.rootPaper}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <MyOutlinedTextField
              id="roles-name"
              name="name"
              label="Nome"
              fullWidth
              inputRef={register}
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
            />
          </Grid>
          <Grid item xs={6}>
            <MyOutlinedTextField
              id="email"
              name="email"
              label="Email"
              fullWidth
              inputRef={register}
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email.message : null}
            />
          </Grid>
          <Grid item xs={6}>
            <MySelectForm
              contracted
              itens={roles.map(role => ({
                title: role.name,
                value: role.id,
              }))}
              onChange={handleOnChangeRole}
              value={userRoles}
              helperText={null}
              title="Permissionamento"
              error={false}
              multiple={true}
              menuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            />
          </Grid>
        </Grid>
      </Paper>
      {!user.isAdmin ? (
        <UserNotificationConfig
          setNotificationConfigId={setNotificationConfigId}
          register={register}
          control={control}
          user={user}
        />
      ) : (
        ''
      )}
      {!user.isAdmin && userRanking?.referralCode ? <UserRankingBlock /> : null}
    </Grid>
  );
};

export default EditUser;
