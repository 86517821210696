import React, { useEffect, useCallback, ChangeEvent, useState } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TablePagination,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';

import MyTableCell from 'components/generals/table/MyTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { BenefitsDialogProps } from 'components/generals/dialog/dialogTypes';
import BenefitsFormDialog from 'components/generals/dialog/BenefitsFormDialog';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';

import { AppState } from 'store';
import { loadRecurrences } from 'store/ducks/recurrences/actions';
import { BenefitsState } from 'store/ducks/benefits/types';
import { actUpdatePageTitle, openModal } from 'store/ducks/nav/actions';
import { loadBenefits } from 'store/ducks/benefits/action';

import { useFilters } from 'hooks/filters';
import { useStyles } from './styles';
import { Benefits } from 'types/generals/benefits';
import { CondOperator } from '@nestjsx/crud-request';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MySelectFilter from 'components/generals/input/MySelectFilter';
import { RECURRENCES_PLAN } from '../subscriptions/utils';
import { PageFilterState } from 'store/ducks/page-filters/types';
import { PageNames } from 'types/pageFilter';

const BenefitsPage: React.FC = () => {
  const dispatch = useDispatch();
  const style = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);

  const {
    setInitialSort,
    handleSort,
    sort,
    page,
    handleSetValuePage,
    genericFilterFields,
    pageFilters,
    handleGenericSearch,
    handleUpdateFilters,
    handleClearPageFilter,
    filterFields,
  } = useFilters({
    'recurrences.months': {
      filterTitle: 'Recorrência',
      value: undefined,
    },
  });

  const { data: benefits = [], hasError, isLoading, total } = useSelector<
    AppState,
    BenefitsState
  >(({ benefits }: AppState) => benefits);

  const loadAllBenefits = useCallback(() => {
    dispatch(
      loadBenefits({
        sort,
        join: ['recurrences'],
        search: {
          $and: [...filterFields, { $or: [...genericFilterFields] }],
        },
      }),
    );
  }, [dispatch, sort, filterFields, genericFilterFields]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Cadastro de benefícios'));
  }, [dispatch]);

  useEffect(() => {
    loadAllBenefits();
  }, [loadAllBenefits]);

  useEffect(() => {
    setInitialSort({ field: 'priority', order: 'ASC' });
  }, [setInitialSort]);

  const handleOpenModalBenefitForm = (benefit?: Benefits) => {
    const modalProps: BenefitsDialogProps = {
      benefit: benefit,
    };
    dispatch(loadRecurrences());
    dispatch(openModal(BenefitsFormDialog, modalProps));
  };

  const searchObject = [
    {
      field: 'title',
      operator: CondOperator.CONTAINS_LOW,
    },
  ];

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  return (
    <Grid container direction="column" className={style.containerMargin}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3} sm={3} md={3}>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('recurrences.months')}
            menuItems={RECURRENCES_PLAN.map(recurrence => ({
              title: recurrence.name,
              action: () =>
                handleUpdateFilters(
                  'recurrences.months',
                  {
                    filterTitle: recurrence.name,
                    value: recurrence.value,
                  },
                  CondOperator.EQUALS,
                ),
              className: style.menuOption,
            }))}
            buttonProps={{
              className: pageFilters['recurrences.months'].value
                ? style.colorRed
                : style.colorWhite,
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              className={style.textFilter}
            >
              {pageFilters['recurrences.months'].filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
        <Grid container item xs justify="flex-end" alignItems="flex-end">
          <Grid item xs={4} md={2}>
            <ActionsButton
              hasErrorFile={false}
              menuItems={[
                {
                  title: 'Adicionar novo',
                  action: () => handleOpenModalBenefitForm(),
                },
              ]}
              buttonText="Ações"
            />
          </Grid>
        </Grid>
      </Grid>
      <Paper className={style.paperTable} elevation={0}>
        <TableContainer>
          <Table className={style.table} aria-label="subscriptions list">
            <TableHead>
              <TableRow>
                <MySortableTableCell
                  onClick={() => handleSort('title')}
                  direction={sort.field === 'title' && sort.order}
                >
                  NOME DO BENEFÍCIO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('recurrences.name')}
                  direction={sort.field === 'recurrences.name' && sort.order}
                >
                  RECORRÊNCIA
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('priority')}
                  direction={sort.field === 'priority' && sort.order}
                >
                  PRIORIDADE
                </MySortableTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!hasError && !isLoading && benefits.length ? (
                benefits.map(benefit => (
                  <TableRow
                    key={benefit.id}
                    className={style.clickableRow}
                    onClick={() => {
                      handleOpenModalBenefitForm(benefit);
                    }}
                  >
                    <MyTableCell>{benefit.title || '-'}</MyTableCell>
                    <MyTableCell>
                      {benefit.recurrences.map(b => b.name).join(', ') || ''}
                    </MyTableCell>
                    <MyTableCell>{benefit.priority || '-'}</MyTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className={style.clickableRow}>
                  <MyTableCell colSpan={3} align="center">
                    Nenhum benefício encontrado
                  </MyTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
          <TablePagination
            ActionsComponent={MyTablePaginationActions}
            component="div"
            count={total}
            page={page}
            labelRowsPerPage="Itens por página"
            onChangePage={(_event: unknown, newPage: number) =>
              handleSetValuePage(newPage)
            }
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
              handleSetValuePage(0);
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
            rowsPerPageOptions={ROWS_PER_PAGE}
          />
        </TableContainer>
      </Paper>
    </Grid>
  );
};
export default BenefitsPage;
