import { api, getWithSaga } from './apiService';
import { CreditCard } from 'types/CreditCard';

export default class CreditCardService {
  static async getCreditCards() {
    try {
      const data = await getWithSaga('credit-cards');
      return data;
    } catch (err) {
      return err;
    }
  }

  static async getOneCreditCard(id: string) {
    try {
      const data = await getWithSaga(`credit-cards/${id}`);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async addNewCreditCard(cardData: CreditCard) {
    try {
      const data = await api.post('/credit-cards', {
        ...cardData,
      });
      return data;
    } catch (err) {
      return err.response;
    }
  }

  static async updateCreditCard(
    cardData: Partial<CreditCard>,
    cardId: number | undefined,
  ) {
    try {
      if (cardId) {
        const data = await api.patch(`credit-cards/${cardId}`, {
          ...cardData,
        });
        return data;
      } else throw new Error('Valor inválido para o identificador do cartão');
    } catch (error) {
      return error;
    }
  }

  static async deleteOneCreditCard(id: string) {
    try {
      const data = await api.delete(`credit-cards/${id}`);
      return data;
    } catch (err) {
      return err;
    }
  }
}
