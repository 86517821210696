import React, { FC } from 'react';
import { Badge, Grid } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { useStyles } from './styles';
import { FilesThumbsProps } from './types';

const FilesThumbs: FC<FilesThumbsProps> = ({ files, handleDeleteFile }) => {
  const styles = useStyles();

  return (
    <>
      {files.map(file => (
        <Grid
          container
          item
          justify="center"
          xs={6}
          lg={4}
          alignItems="center"
          key={file.image}
        >
          <Grid>
            <Badge
              color="secondary"
              overlap="circle"
              badgeContent={
                <CloseIcon onClick={() => handleDeleteFile(file.image)} />
              }
            >
              <img
                src={file.image}
                className={styles.thumb}
                alt="Prévia da imagem"
              />
            </Badge>
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default FilesThumbs;
