import { getWithSaga, api } from './apiService';
import { CreateQueryParams } from '@nestjsx/crud-request';
import { BenefitsPayload } from 'components/generals/dialog/types';

export default class BenefitsService {
  static formData(benefit: BenefitsPayload, benefitImages?: any[]) {
    const formData = new FormData();

    if (benefitImages?.length) {
      benefitImages.forEach(file => {
        formData.append('image', file.name ? file : file.image);
      });
    } else {
      formData.append('image', '');
    }

    formData.append('title', benefit.title);
    formData.append('description', benefit.description);
    formData.append('priority', benefit.priority.toString());
    formData.append('recurrenceIds', JSON.stringify(benefit.recurrenceIds));

    return formData;
  }

  static async getAllBenefits(query?: CreateQueryParams) {
    try {
      const response = await getWithSaga('/benefits', query);
      return response;
    } catch (error) {
      return error;
    }
  }

  static async createBenefit(benefit: BenefitsPayload, benefitImages?: File[]) {
    try {
      const data = await api.post(
        `/benefits`,
        this.formData(benefit, benefitImages),
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updateBenefit(
    benefitId: number,
    benefit: BenefitsPayload,
    benefitImages?: File[],
  ) {
    try {
      const data = await api.put(
        `/benefits/${benefitId}`,
        this.formData(benefit, benefitImages),
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async deleteBenefit(benefitId: number) {
    try {
      const data = await api.delete(`/benefits/${benefitId}`);
      return data;
    } catch (error) {
      return error;
    }
  }
}
