import React, { MouseEventHandler } from 'react';
import { Grid, TableCellProps } from '@material-ui/core';
import MyTableCell from './MyTableCell';
import MyTableSortLabel from './MyTableSortLabel';

interface MySortableTableCellProps extends TableCellProps {
  direction: 'ASC' | 'DESC' | false;
  onClick: MouseEventHandler;
}

const MySortableTableCell: React.FC<MySortableTableCellProps> = ({
  direction,
  onClick,
  children,
  ...rest
}) => {
  return (
    <MyTableCell {...rest}>
      <Grid
        container
        alignItems="center"
        wrap="nowrap"
        style={{ justifyContent: 'space-between', paddingRight: 3 }}
      >
        {children}
        <MyTableSortLabel onClick={onClick} direction={direction} />
      </Grid>
    </MyTableCell>
  );
};
export default MySortableTableCell;
