import { Reducer } from 'redux';
import { EnhancedProductState, EnhancedProductTypes } from './types';

const INIITIAL_STATE: EnhancedProductState = {
  data: [],
  total: 0,
  hasError: false,
  isLoading: false,
};

const reducer: Reducer<EnhancedProductState> = (
  state = INIITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case EnhancedProductTypes.LOAD_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case EnhancedProductTypes.LOAD_REQUEST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload.data,
      };

    case EnhancedProductTypes.LOAD_REQUEST_ERROR:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        data: [],
      };

    default:
      return state;
  }
};

export default reducer;
