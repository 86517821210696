import { Item } from './LayoutClient/List/types';

const MyAccount = () => {
  return {
    id: 'menu-item-my-account',
    name: 'Minha Conta',
    icon: 'icon_account',
    group: '/my-account',
    subItems: [
      {
        id: 'menu-item-subscription',
        name: 'Assinatura',
        route: '/my-account/signature',
      },
      {
        id: 'menu-item-orders',
        name: 'Pedidos',
        route: '/my-account/orders',
      },
      {
        id: 'menu-item-profile',
        name: 'Perfil',
        route: '/my-account/profile',
      },
      {
        id: 'menu-item-cards',
        name: 'Pagamento',
        route: '/my-account/cards',
      },
      {
        id: 'menu-item-security',
        name: 'Segurança',
        route: '/my-account/security',
      },
    ],
  };
};

export const getMenuItems = (showRanking: boolean): Item[] => {
  return [
    {
      id: 'menu-item-panel',
      name: 'Painel',
      route: '/home',
      icon: 'icon_home',
    },
    MyAccount(),
    {
      id: 'menu-item-ranking',
      name: 'Indique e ganhe',
      route: '/user-ranking',
      icon: 'ranking_icon',
      isDisabled: !showRanking,
    },
    {
      id: 'menu-item-virtual-store',
      name: 'Loja Premium',
      route: '/store',
      icon: 'icon_cart',
      group: '/store',
      // onlyPremium: true,
    },
    {
      id: 'menu-item-study-area',
      name: 'Área de Formação',
      route: '/study',
      icon: 'icon_study',
    },
    {
      id: 'menu-item-logout',
      name: 'Sair',
      action: 'logout',
      icon: 'icon_logout',
      route: '/',
    },
  ];
};
