import React, { MouseEventHandler } from 'react';
import { Grid, makeStyles } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp';
import { Colors } from 'styles/colors';

const useStyles = makeStyles(theme => ({
  arrowUp: {
    marginBottom: '-0.25em',
  },
  arrowDown: {
    marginTop: '-0.25em',
  },
  active: {
    color: Colors.DARKER_GRAY,
  },
}));

interface MyTableSortLabelProps {
  direction: 'ASC' | 'DESC' | false;
  onClick: MouseEventHandler;
}

const MyTableSortLabel: React.FC<MyTableSortLabelProps> = ({
  direction,
  onClick,
}) => {
  const classes = useStyles();
  return (
    <Grid
      container
      direction="column"
      style={{
        cursor: 'pointer',
        width: 'auto',
      }}
      onClick={onClick}
    >
      <KeyboardArrowUp
        className={classes.arrowUp}
        style={{
          color: direction === 'ASC' ? Colors.DARKER_GRAY : Colors.GRAY,
        }}
      />
      <KeyboardArrowDown
        className={classes.arrowDown}
        style={{
          color: direction === 'DESC' ? Colors.DARKER_GRAY : Colors.GRAY,
        }}
      />
    </Grid>
  );
};

export default MyTableSortLabel;
