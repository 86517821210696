import * as Yup from 'yup';

const setLocale = () =>
  Yup.setLocale({
    mixed: {
      default: 'Não é válido',
      required: 'Campo obrigatório',
      notType: 'Formato inválido',
    },
    string: {
      // eslint-disable-next-line no-template-curly-in-string
      max: 'Limite de ${max} caracteres excedido',
      min: 'Formato inválido',
    },
    number: {
      positive: 'Valor deve ser positivo',
      integer: 'Valor deve ser inteiro',
    },
  });

export default setLocale;
