/**
 * Action types
 */
export enum NotificationTypes {
  ENQUEUE = '@notification/ENQUEUE',
  REMOVE = '@notification/REMOVE',
  IS_NOTIFIED = '@notification/IS_NOTIFIED',
  DISABLED_BUTTON = '@notification/DISABLED_BUTTON',
  ENABLE_BUTTON = '@notification/ENABLE_BUTTON',
  BANKSLIP_IS_PROCESSING = '@notification/BANKSLIP_IS_PROCESSING',
  BANKSLIP_IS_NOT_PROCESSING = '@notification/BANKSLIP_IS_NOT_PROCESSING',
}

/** Data types */
export interface Notification {
  key: string;
  message: string;
  options?: any;
}

/**
 * State types
 */
export interface NotificationState {
  notifications: Notification[];
  isNotified: boolean;
  buttonIsDisabled: boolean;
  bankSlipIsProcessing: boolean;
}
