import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { Grid } from '@material-ui/core';

import { ReactComponent as ArrowRight } from 'assets/img/svg/icon_arrow_right.svg';

import { getBreadcrumbsNames } from './utils';

import {
  MUIBreadcrumbs,
  HomeIcon,
  CurrentLocation,
  PastLocation,
} from './styles';

const Breadcrumbs: React.FC = () => {
  const history = useHistory();
  const { pathname } = useLocation();

  const pathnames = pathname.split('/').filter((x: string) => x);

  return (
    <MUIBreadcrumbs aria-label="breadcrumb" separator={<ArrowRight />}>
      {pathnames[0] !== 'home' && (
        <PastLocation onClick={() => history.push('/home')}>
          <Grid container alignItems="center">
            <HomeIcon />
          </Grid>
        </PastLocation>
      )}

      {pathnames.map((name: string, index: number) => {
        if (name === 'home') {
          return undefined;
        }

        const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
        const isLast = index === pathnames.length - 1;
        return isLast ? (
          <CurrentLocation variant="h3" key={name}>
            {getBreadcrumbsNames(name)}
          </CurrentLocation>
        ) : (
          <PastLocation key={name} onClick={() => history.push(routeTo)}>
            {getBreadcrumbsNames(name)}
          </PastLocation>
        );
      })}
    </MUIBreadcrumbs>
  );
};

export default Breadcrumbs;
