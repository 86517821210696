import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useForm } from 'react-hook-form';
import * as Yup from 'yup';

import useStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';

import { closeModal } from 'store/ducks/nav/actions';
import { cancelOrder } from 'store/ducks/order/actions';
import { useDialogStyles } from './useStyles';
import { OrderCancelDialogProps } from './dialogTypes';

const validationSchema = Yup.object({
  message: Yup.string(),
});

const OrderCancelDialog: FC<OrderCancelDialogProps> = props => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const classes = useDialogStyles();
  const { id, title, heading, cancelText, confirmText } = props;

  const { handleSubmit, errors, control } = useForm({
    validationSchema,
  });

  const onSubmit = async ({ message }: Record<string, string>) => {
    try {
      dispatch(cancelOrder(id, message));
      dispatch(closeModal());
    } catch (err) {
      throw new Error(err);
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          spacing={2}
          component="form"
          className={classes.paddingInfoBottom}
        >
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h4"
              color="textPrimary"
              className={classes.info}
              style={{ whiteSpace: 'pre-wrap' }}
            >
              {heading}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Controller
              as={MyOutlinedTextField}
              required
              fullWidth
              control={control}
              name="message"
              label="Mensagem"
              error={Boolean(errors.message)}
            />
            {errors.boxWeight && (
              <FormHelperText>
                <Typography variant="h5" color="primary">
                  {errors.message.message}
                </Typography>
              </FormHelperText>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={styles.buttonLabel}
          style={{ marginRight: 30 }}
          onClick={() => dispatch(closeModal())}
        >
          {cancelText ?? 'Cancelar'}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
          onClick={handleSubmit(onSubmit)}
        >
          {confirmText ?? 'Confirmar'}
        </Button>
      </DialogActions>
    </Grid>
  );
};

export default OrderCancelDialog;
