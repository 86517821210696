import { CondOperator } from '@nestjsx/crud-request';
import { Order } from 'types/generals/order';

export interface OrderItemProps {
  order: Order;
}

export interface Step {
  completed?: boolean;
  value: string;
}

export enum UserTypeLabel {
  CNPJ = 'PJ',
  CPF = 'PF',
}

export interface OrderParams {
  id: string;
}

export const productFilter: ProductFilter[] = [
  { name: 'Igual a 1', operator: CondOperator.EQUALS, value: 1 },
  { name: 'Igual a 2', operator: CondOperator.EQUALS, value: 2 },
  { name: 'Igual a 3', operator: CondOperator.EQUALS, value: 3 },
  { name: 'Igual a 4', operator: CondOperator.EQUALS, value: 4 },
  { name: 'Igual a 5', operator: CondOperator.EQUALS, value: 5 },
  { name: 'Menor que 1', operator: CondOperator.LOWER_THAN, value: 1 },
  { name: 'Menor que 2', operator: CondOperator.LOWER_THAN, value: 2 },
  { name: 'Menor que 3', operator: CondOperator.LOWER_THAN, value: 3 },
  { name: 'Menor que 4', operator: CondOperator.LOWER_THAN, value: 4 },
  { name: 'Menor que 5', operator: CondOperator.LOWER_THAN, value: 5 },
  { name: 'Maior que 1', operator: CondOperator.GREATER_THAN, value: 1 },
  { name: 'Maior que 2', operator: CondOperator.GREATER_THAN, value: 2 },
  { name: 'Maior que 3', operator: CondOperator.GREATER_THAN, value: 3 },
  { name: 'Maior que 4', operator: CondOperator.GREATER_THAN, value: 4 },
  { name: 'Maior que 5', operator: CondOperator.GREATER_THAN, value: 5 },
  { name: 'Maior que 10', operator: CondOperator.GREATER_THAN, value: 10 },
];

export interface ProductFilter {
  name: string;
  operator: string;
  value: number;
}

export interface StoreProductFilter {
  name: string;
  sku: string;
}

export interface OrderReferencedProps {
  order: Order;
  isAdmin?: boolean;
  reason?: string;
  mainOrderTitle?: string;
  refOrderTitle?: string;
}
