import { makeStyles } from '@material-ui/core';
import { Colors } from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  clickableArea: {
    cursor: 'pointer',
  },
  cardItem: {
    padding: theme.spacing(3, 4, 3, 3),
    borderRadius: '8px',
    textAlign: 'start',
  },
  actionButtonBankSlip: {
    padding: 0,
    whiteSpace: 'nowrap',
    marginLeft: 10,
  },
  actionButton: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
  },
  circle: {
    borderRadius: '50%',
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 32,
    cursor: 'pointer',
  },
  orderInfoTitle: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
    margin: theme.spacing(0, 0, 0.5),
  },
  mInfo: {
    margin: theme.spacing(0, 0, 3),
  },
  mRight: {
    margin: theme.spacing(0, 3, 0, 0),
  },
  paperInfoTitle: {
    margin: theme.spacing(1, 0, 5),
    textTransform: 'capitalize',
    color: Colors.GRAY,
    fontWeight: 500,
  },
  trackingInfoBankSlip: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: Colors.RED,
  },
  trackingInfo: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: Colors.GRAY,
  },
  invoiceOrderInfo: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: Colors.RED,
  },
  table: {
    minWidth: 650,
  },
  ordersList: {
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  paperTable: {
    padding: theme.spacing(3, 4),
    borderRadius: '2em',
  },
  gridAction: {
    margin: theme.spacing(0, 0, 2.5),
  },
  buttonXml: {
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 13,
  },
  filterOptionsList: {
    backgroundColor: Colors.WHITE,
  },
  clickableRow: {
    cursor: 'pointer',
  },
  tableCellTypography: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  menuOption: {
    textTransform: 'capitalize',
  },
  placeCenter: {
    placeSelf: 'center',
  },
  textCenter: {
    textAlign: 'center',
  },
  mTop: {
    marginTop: '1em',
  },
  linkStyle: {
    textDecoration: 'none',
    color: 'inherit',
  },
  textBankSlip: {
    color: Colors.RED,
    fontWeight: 'bold',
  },
  autoCompleteFilter: {
    width: '100%',
    backgroundColor: Colors.WHITE,
    outline: 'none',
    border: 'none',
    borderRadius: 13,
    padding: 2,
  },
  textFilter: {
    fontSize: '1.6rem',
    fontWeight: 600,
    textTransform: 'capitalize',
    textAlign: 'left',
    maxWidth: 360,
  },
  textFilterGray: {
    color: Colors.GRAY,
  },
  textLinkTable: {
    fontWeight: 'bold',
    padding: 15,
    textDecoration: 'none',
    color: Colors.BLACK,
  },
});
