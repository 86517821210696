import React, { FC, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import clsx from 'clsx';

import { formatColumnValue } from 'helpers/formatters';
import MyTypography from '../MyTypography';
import { BoldText } from 'components/client/layout/LayoutClient/MyHeaderBar/styles';
import { useStyles } from './styles';
import { FormatOptions } from 'types';
import { AmountLabelProps } from './types';

const AmountLabel: FC<AmountLabelProps> = ({
  title,
  amount,
  textBold,
  isDiscount,
  isAmount,
}) => {
  const styles = useStyles();

  const TextComponent = textBold ? BoldText : MyTypography;

  const amountValue = useCallback(
    value => formatColumnValue(FormatOptions.MONEY, value),
    [],
  );

  return (
    <Grid container direction="row" justify="space-between">
      <TextComponent
        className={clsx(
          isAmount ? styles.mAmountBold : styles.mLabel,
          styles.capitalize,
        )}
      >
        {title}
      </TextComponent>
      <TextComponent
        className={clsx(
          isAmount ? styles.mAmountBold : styles.mLabel,
          styles.capitalize,
        )}
      >
        {isDiscount ? <> - {amountValue(amount)}</> : amountValue(amount)}
      </TextComponent>
    </Grid>
  );
};

export default AmountLabel;
