import { StoreProduct } from 'types/generals';

export enum StoreTypes {
  LOAD_STORE_FILE_REQUEST = '@store/LOAD_STORE_FILE_REQUEST',
  LIST_STORE_REQUEST = '@store/LIST_STORE_REQUEST',
  LIST_STORE_SUCCESS = '@store/LIST_STORE_SUCCESS',
  LIST_STORE_FAIL = '@store/LIST_STORE_FAIL',
  SET_STORE_PAGE = '@store/SET_STORE_PAGE',
  SET_ROWS_PER_PAGE = '@store/SET_ROWS_PER_PAGE',

  LOAD_ONE_STORE_PRODUCT_REQUEST = '@store/LOAD_ONE_STORE_PRODUCT_REQUEST',
  LOAD_ONE_STORE_PRODUCT_SUCCESS = '@store/LOAD_ONE_STORE_PRODUCT_SUCCESS',
  LOAD_ONE_STORE_PRODUCT_FAILED = '@store/LOAD_ONE_STORE_PRODUCT_FAILED',
  RESET_STATE_ONE_STORE_PRODUCT_AFTER_FAILED = '@store/RESET_STATE_ONE_STORE_PRODUCT_AFTER_FAILED',
}

export interface IStorePage {
  page: number;
}
export interface IStoreRowsPerPage {
  rowsPerPage: number;
}

export interface StoreState {
  readonly data: StoreProduct[];
  readonly total: number;
  readonly page: number;
  readonly rowsPerPage: number;
  readonly isLoading: boolean;
  readonly hasError: boolean;
}

export interface StoreProductState
  extends Omit<StoreState, 'data' | 'page' | 'total' | 'rowsPerPage'> {
  readonly data: StoreProduct;
}
