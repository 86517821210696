import { CreditCard } from 'types/CreditCard';

export enum CreditCardTypes {
  LOAD_CREDIT_CARD_REQUEST = '@creditCard/LOAD_CREDIT_CARD_REQUEST',
  LOAD_CREDIT_CARD_SUCCESS = '@creditCard/LOAD_CREDIT_CARD_SUCCESS',
  LOAD_CREDIT_CARD_FAIL = '@creditCard/LOAD_CREDIT_CARD_FAIL',
  LOAD_CREDIT_CARD_PATCH = '@creditCard/LOAD_CREDIT_CARD_PATCH',
}

export interface CreditCardState {
  readonly total: number;
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly data: CreditCard[];
}
