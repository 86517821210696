import React, { FC, useCallback } from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { orderBy } from 'lodash';
import { Link } from 'react-router-dom';

import MyTooltip from 'components/generals/tolltip';
import { formatColumnValue } from 'helpers/formatters';

import { OrderItemProps } from './types';
import { useStyles } from './styles';
import { FormatOptions } from 'types/TableTypes';

const PaymentStatus: FC<OrderItemProps> = ({ order }) => {
  const PAYMENTS = orderBy(order.payments, ['id'], ['desc']);
  const styles = useStyles();

  const handleFormatDateTime = useCallback((date: string) => {
    return formatColumnValue(FormatOptions.DATETIME, date);
  }, []);

  return (
    <Grid item xs={12} style={{ marginBottom: '25px' }}>
      <Paper className={styles.paperTable} elevation={0}>
        <Typography variant="h3" className={styles.paperInfoTitle}>
          Status Pagamento
        </Typography>
        <TableContainer>
          <Table className={styles.table} aria-label="order items table">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Transação</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Descrição</TableCell>
                <TableCell>Motivo do estorno</TableCell>
                <TableCell>Usuário</TableCell>
                <TableCell>Vencimento</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {PAYMENTS.map(payment => (
                <TableRow key={payment.id}>
                  <TableCell>
                    {handleFormatDateTime(payment.createdAt)}
                  </TableCell>
                  <TableCell>{payment?.transactionId || '-'}</TableCell>
                  <TableCell>{payment.status}</TableCell>
                  <MyTooltip
                    title={payment.description}
                    enterDelay={500}
                    placement="top"
                  >
                    <TableCell>{payment.description}</TableCell>
                  </MyTooltip>

                  <MyTooltip
                    title={payment.refundReason?.reason || '-'}
                    enterDelay={500}
                    placement="top"
                  >
                    <TableCell>{payment.refundReason?.reason || '-'}</TableCell>
                  </MyTooltip>

                  <MyTooltip
                    title={payment.refundReason?.requester || '-'}
                    enterDelay={500}
                    placement="top"
                  >
                    <TableCell>
                      {payment.refundReason?.requester || '-'}
                    </TableCell>
                  </MyTooltip>

                  <TableCell>
                    {payment.bankSlipTransaction?.dueDate || '-'}
                  </TableCell>
                  <TableCell>
                    {payment.bankSlipTransaction?.url ? (
                      <Button>
                        <Typography
                          variant="h4"
                          color="textPrimary"
                          className={styles.textBankSlip}
                        >
                          <Link
                            className={styles.linkStyle}
                            to={{ pathname: payment.bankSlipTransaction.url }}
                            target="_blank"
                          >
                            Ver Boleto
                          </Link>
                        </Typography>
                      </Button>
                    ) : (
                      '-'
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default PaymentStatus;
