import React from 'react';
import { Grid } from '@material-ui/core';

import SectionLogo from './SectionLogo';
import RecoverClientForm from './RecoverForm';

const RecoverPassword = () => {
  return (
    <Grid container>
      <SectionLogo />
      <RecoverClientForm />
    </Grid>
  );
};

export default RecoverPassword;
