import { User, Order } from 'types/generals';

export enum InvoiceStatus {
  PENDENTE = 'Pendente',
  EMITIDA = 'Emitida',
  CANCELADA = 'Cancelada',
  AGUARDO_RECIBO = 'Enviada - Aguardando recibo',
  REJEITADA = 'Rejeitada',
  AUTORIZADA = 'Autorizada',
  DANFE = 'Emitida DANFE',
  REGISTRADA = 'Registrada',
  AGUARDO_PROTOCOLO = 'Enviada - Aguardando protocolo',
  DENEGADA = 'Denegada',
}

export interface Invoice {
  id: number;
  createdAt: string;
  updatedAt: string;
  series: string;
  number: string;
  orderNumber: string;
  type: string;
  contato: string;
  cnpj: string;
  vendedor: string;
  issuedAt: string;
  status: InvoiceStatus;
  linkDanfe: string;
  linkXml: string;
  amount: string;
  client: User;
  order: Omit<Order, 'Invoice'>;
  nature: string;
  chaveAcesso?: string;
}

export enum INVOICE_NATURES {
  NOTA_FATURADA = 'Simples faturamento de Venda para entrega futura',
  NOTA_ENVIO = 'Venda - Entrega Futura',
}
