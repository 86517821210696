import { Plan } from 'types/generals';

export enum PlanTypes {
  LOAD_PLAN_LIST_REQUEST = '@plan/LOAD_PLAN_LIST_REQUEST',
  LOAD_PLAN_LIST_SUCCESS = '@plan/LOAD_PLAN_LIST_SUCCESS',
  LOAD_PLAN_LIST_FAILURE = '@plan/LOAD_PLAN_LIST_FAILURE',
  LOAD_PLAN_FILE_REQUEST = '@plan/LOAD_PLAN_FILE_REQUEST',
  LOAD_PLAN_REQUEST = '@plan/LOAD_PLAN_REQUEST',
  LOAD_PLAN_SUCCESS = '@plan/LOAD_PLAN_SUCCESS',
  LOAD_PLAN_FAILURE = '@plan/LOAD_PLAN_FAILURE',
  LOAD_ONE_PLAN = '@plan/LOAD_PLAN_ONE',
  LOAD_PLANS_TO_REACTIVATE = '@plan/LOAD_PLANS_TO_REACTIVATE',
  CLEAR_ONE_PLAN = '@plan/CLEAR_ONE_PLAN',
}

export interface PlanPayload {
  data: Plan | Plan[];
  total?: number;
}

export interface PlanState {
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly total: number;
  readonly data: Plan[];
}

export interface OnePlanState extends Omit<PlanState, 'data' | 'total'> {
  readonly data: Plan;
}
