import { CreateQueryParams } from '@nestjsx/crud-request';
import CrudList from 'components/generals/lists/CrudList';
import { StatusCodes } from 'http-status-codes';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { ColumnProps, ColumnTypes, FormatOptions, Sort } from 'types';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import { RankingSeason } from 'types/generals/rankingSeason';
import RankingSeasonService from 'services/rankingSeasonService';
import {
  DELETE_MODAL_MSG,
  DELETE_MODAL_TITLE,
  DELETE_RANKING_SEASON_ERROR,
  DELETE_RANKING_SEASON_SUCCESS,
} from 'utils/messages/rankingSeason';
import { formatColumnValue } from 'helpers/formatters';

const RankingSeasons: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);

  const handleOpenDeleteDialog = (season: RankingSeason) => {
    const modalProps: BaseDialogProps = {
      title: DELETE_MODAL_TITLE,
      message: DELETE_MODAL_MSG(season.name),
      actionFn: () => onDelete(season),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const onDelete = async (season: RankingSeason) => {
    const response: any = await RankingSeasonService.delete(season?.id);
    if (StatusCodes.OK === response.status) {
      dispatch(notifySuccess(DELETE_RANKING_SEASON_SUCCESS));
      setRefresh(true);
    } else {
      dispatch(notifyError(DELETE_RANKING_SEASON_ERROR));
    }
    dispatch(closeModal());
  };

  const columns: ColumnProps[] = [
    { label: 'NOME', getter: 'name', sortable: true },
    {
      label: 'Data de início',
      getter: 'startDate',
      sortable: true,
      format: date =>
        formatColumnValue(FormatOptions.SIMPLE_DATE_WITH_TRACE, date),
    },
    {
      label: 'Data de termíno',
      getter: 'endDate',
      sortable: true,
      format: date =>
        formatColumnValue(FormatOptions.SIMPLE_DATE_WITH_TRACE, date),
    },
    {
      label: 'Ativo',
      getter: 'isActive',
      sortable: true,
      format: val => (val === true ? 'Sim' : 'Não'),
    },
    {
      label: 'AÇÕES',
      sortable: true,
      onDelete: handleOpenDeleteDialog,
      type: ColumnTypes.DELETE,
    },
  ];
  const [defaultSort] = useState<Sort>({
    field: 'id',
    order: 'DESC',
  });
  const [queryParams, setQueryParams] = useState<CreateQueryParams>({
    sort: defaultSort,
    page: 1,
    limit: 10,
  });
  // fixme: change crudList to suporte single/multiple actions - need change from donation branch
  return (
    <CrudList
      title="Campanhas"
      url="/ranking-season"
      columns={columns}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      searchField="name"
      searchFieldLabel="Nome"
      newButtonLabel="Nova campanha"
      newButtonLinkTo="/admin/ranking-seasons/new"
      onRowClick={item =>
        history.push(`/admin/ranking-seasons/${item.id}`, {
          season: item,
        })
      }
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );
};

export default RankingSeasons;
