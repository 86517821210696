import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  mTop: {
    marginTop: '20px',
  },
  smTop: {
    marginTop: '10px',
  },
  actionLabel: {
    alignSelf: 'center',
    color: Colors.RED,
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '15px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  cardImg: {
    [theme.breakpoints.only('xs')]: {
      height: '277px',
    },
    height: '254px',
    minHeight: '220px',
    borderRadius: '16px',
    cursor: 'pointer',
  },
  selfCenter: {
    alignSelf: 'center',
  },
  smCardImg: {
    [theme.breakpoints.up('xs')]: {
      maxWidth: '285px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '156px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '190px',
    },
    [theme.breakpoints.only('xl')]: {
      maxWidth: '241px',
    },
    height: '182px',
    borderRadius: '16px',
    cursor: 'pointer',
    objectFit: 'contain',
  },
  content: {
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3, 0, 1),
    },
    [theme.breakpoints.only('xl')]: {
      padding: theme.spacing(3, 0, 2),
    },
    padding: theme.spacing(3, 0, 3),
  },
  smPaddingContent: {
    padding: theme.spacing(0, 0, 1),
  },
  smBottomMargin: {
    [theme.breakpoints.only('sm')]: {
      marginBottom: theme.spacing(6),
    },
    marginBottom: theme.spacing(5),
  },
  boxSubtitle: {
    color: Colors.MEDIUM_GRAY,
    fontWeight: 500,
  },
  boxDescription: {
    fontSize: '13px',
    paddingRight: '12px',
    margin: theme.spacing(1, 0, 3),
    maxHeight: '90px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  boldText: {
    fontWeight: 700,
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  ownedProductImage: {
    display: 'block',
    width: '100%',
    height: '182px',
    borderRadius: '16px',
  },
  overlay: {
    position: 'absolute',
    [theme.breakpoints.up('xs')]: {
      maxWidth: '285px',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      maxWidth: '156px',
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '190px',
    },
    [theme.breakpoints.only('xl')]: {
      maxWidth: '241px',
    },
    bottom: 8,
    background:
      'linear-gradient(160deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.2))',
    width: '100%',
    height: '182px',
    opacity: 0.7,
    color: Colors.WHITE,
    borderRadius: '16px',
  },
  iconContainer: {
    backgroundColor: Colors.WHITE,
    color: Colors.RED,
    position: 'absolute',
    top: 0,
    margin: '15px',
    borderRadius: '50%',
    padding: '4.5px 7px',
  },
  renewBottom: {
    marginBottom: 15,
  },
  bansklipText: {
    color: Colors.WHITE,
    fontWeight: 600,
    fontSize: 12,
  },
  showBankslipButton: {
    backgroundColor: Colors.DARKER_GREEN,
    '&:hover': {
      backgroundColor: Colors.DARKER_GREEN,
    },
    height: 32,
  },
}));
