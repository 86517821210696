import { ViewUserReactivation } from 'types/generals/view';

/**
 * Action types
 */
export enum ViewBulkActionReactivationTypes {
  LOAD_VIEW_USERS_REACTIVATION_REQUEST = '@user/LOAD_VIEW_USERS_REACTIVATION_REQUEST',
  LOAD_VIEW_USERS_REACTIVATION_SUCCESS = '@user/LOAD_VIEW_USERS_REACTIVATION_SUCCESS ',
  LOAD_VIEW_USERS_REACTIVATION_FAILURE = '@user/LOAD_VIEW_USERS_REACTIVATION_FAILURE',
}

/**
 * State types
 */
export interface ViewUserReactivationState {
  readonly data: ViewUserReactivation[];
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly total: number;
}

export interface ViewUserReactivationPayload {
  data: ViewUserReactivation[];
  total?: number;
}
