import { call, put } from '@redux-saga/core/effects';

import {
  loadCurrentBoxDone,
  loadCurrentBoxesFail,
  loadPreviousBoxesDone,
  loadPreviousBoxesFail,
} from './actions';
import BoxService from 'services/boxService';
import { BoxHome } from './types';
import { Box, MAX_PREVIOUS_BOXES_HOME } from 'types/generals';
import { head } from 'lodash';

export function* loadCurrentBoxSaga(action: any) {
  try {
    let box: BoxHome = {} as BoxHome;
    const { query } = action.payload;
    const { data } = yield call(BoxService.listBoxes, query);
    const monthBox: Box | undefined = head(data);

    if (monthBox) {
      box = {
        productId: monthBox.id,
        name: monthBox.name,
        boxDate: monthBox.startDate,
        image: monthBox.image,
        description: monthBox.description,
        owned: true,
      } as BoxHome;
    } else {
      throw new Error();
    }
    yield put(loadCurrentBoxDone({ box }));
  } catch (error) {
    yield put(loadCurrentBoxesFail());
  }
}

export function* loadPreviousBoxesSaga(action: any) {
  try {
    const { previousBoxesQuery } = action.payload;

    const { data } = yield call(BoxService.listBoxes, previousBoxesQuery);

    let boxes;

    if (data.length === MAX_PREVIOUS_BOXES_HOME) {
      boxes = data.map((box: Box) => {
        const { id, name, description, image, startDate } = box;

        return {
          productId: id,
          name,
          description,
          image,
          boxDate: startDate,
        } as BoxHome;
      });
    }

    yield put(loadPreviousBoxesDone({ boxes }));
  } catch (error) {
    yield put(loadPreviousBoxesFail());
  }
}
