import { Plp, PlpOneState, PlpTypes } from './types';
import { Reducer } from 'redux';

const INITIAL_STATE: PlpOneState = {
  data: {} as Plp,
  hasError: false,
  isLoading: true,
};

const plpReducer: Reducer<PlpOneState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlpTypes.GET_PLP_REQUEST:
      return { ...state, isLoading: true };
    case PlpTypes.GET_PLP_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default plpReducer;
