import React from 'react';

import MyDropdown from 'components/generals/dropdown/MyDropdown';
import Colors from 'styles/colors';
import { useCommonStyles } from 'styles/common';
import { TableActionsButtonProps } from './types';

const TableActionsButton: React.FC<TableActionsButtonProps> = ({
  menuItems,
}) => {
  const commonStyles = useCommonStyles();
  const myDropdownStyle = {
    width: '100%',
    borderRadius: 13,
    boxShadow: 'none',
    backgroundColor: 'transparent',
    color: Colors.RED,
  };
  return (
    <MyDropdown
      buttonProps={{
        color: 'primary',
        variant: 'contained',
        style: myDropdownStyle,
      }}
      menuProps={{
        classes: {
          list: commonStyles.filterOptionsList,
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        PaperProps: {
          style: {
            marginTop: '1rem',
            borderRadius: 13,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }}
      menuItems={menuItems}
    ></MyDropdown>
  );
};

export default TableActionsButton;
