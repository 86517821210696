import React from 'react';
import { Checkbox } from '@material-ui/core';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlank from '@material-ui/icons/CheckBoxOutlineBlank';

interface MyCheckboxProps {
  onClick(): void;
  checked?: boolean;
  disabled?: boolean;
}

const MyCheckbox: React.FC<MyCheckboxProps> = ({
  checked,
  onClick,
  disabled,
}) => {
  return (
    <Checkbox
      color="primary"
      disabled={disabled}
      checkedIcon={<CheckBoxIcon style={{ fontSize: 30 }} />}
      icon={<CheckBoxOutlineBlank style={{ fontSize: 30 }} />}
      checked={checked}
      onClick={() => onClick()}
    />
  );
};

export default MyCheckbox;
