import { Reducer } from 'redux';
import { Plan } from 'types/generals';
import { OnePlanState, PlanTypes } from '../types';

const INITIAL_STATE: OnePlanState = {
  isLoading: false,
  hasError: false,
  data: {} as Plan,
};

const reducer: Reducer<OnePlanState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlanTypes.LOAD_ONE_PLAN:
    case PlanTypes.LOAD_PLAN_REQUEST:
      return {
        isLoading: true,
        hasError: false,
        data: {} as Plan,
        total: 0,
      };
    case PlanTypes.CLEAR_ONE_PLAN:
      return {
        isLoading: false,
        hasError: false,
        data: {} as Plan,
      };
    case PlanTypes.LOAD_PLAN_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload.data,
      };
    case PlanTypes.LOAD_PLAN_FAILURE:
      return {
        isLoading: false,
        hasError: true,
        data: {} as Plan,
      };
    case PlanTypes.LOAD_PLANS_TO_REACTIVATE:
      return {
        isLoading: true,
        hasError: false,
        data: {} as Plan,
      };

    default:
      return state;
  }
};

export default reducer;
