import { action } from 'typesafe-actions';
import { Plp, PlpTypes, PlpData } from './types';
import { CreateQueryParams } from '@nestjsx/crud-request';

export const loadAllPlp = (query: CreateQueryParams) => {
  return action(PlpTypes.ALL_PLP_REQUEST, { query });
};

export const loadOnePlp = (plpId: string) => {
  return action(PlpTypes.GET_PLP_REQUEST, plpId);
};

export const loadPlpFail = () => {
  return action(PlpTypes.LOAD_FAILURE);
};

export const loadAllPlpSuccess = (payload: PlpData) => {
  return action(PlpTypes.LOAD_ALL_SUCCESS, payload);
};

export const loadOnePlpSucess = (payload: Plp) => {
  return action(PlpTypes.GET_PLP_SUCCESS, payload);
};

export const updatePlp = (data: Plp) => {
  return action(PlpTypes.UPDATE, data);
};
