import { Grid, makeStyles, styled } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  rootPaper: {
    padding: '15px 75px',
    alignSelf: 'center',
  },
  sectionZone: {
    margin: '15px 0',
    width: '225px',
    padding: '30px',
    borderWidth: '1px',
    borderRadius: '8px',
    borderColor: '#acacab',
    borderStyle: 'dashed',
    backgroundColor: 'rgba(242, 242, 242, 0.8)',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    textAlign: 'center',
    alignSelf: 'center',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
      backgroundColor: '#eaeaea',
    },
  },
  title: {
    color: Colors.MEDIUM_GRAY,
  },
  btnAction: {
    color: Colors.RED,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  subtitle: {
    color: Colors.RED,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  boldNoWrapText: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  subText: {
    color: Colors.DARK_GRAY,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  previewArea: {
    alignSelf: 'center',
  },
  imageIconSize: {
    fontSize: 24,
  },
  subTextGrid: {
    marginTop: '16px',
  },
  thumb: (props: any) => ({
    display: 'block',
    height: '200px',
    width: '200px',
    objectFit: 'cover',
    borderRadius: 16,
    border: props.isCover ? `4px solid ${Colors.LIGHT_GREEN}` : 'none',
  }),
});

export const ThumbGrid = styled(Grid)({
  display: 'inline-flex',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
});

export const ThumbInnerGrid = styled(Grid)({
  borderRadius: 16,
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
});
