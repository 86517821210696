import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithSaga, useGetWithSWR } from './apiService';
import { BannerPayload, MultiPartRequestConfig } from 'types/generals/banner';

export default class BannerService {
  static createFormData(banner: BannerPayload) {
    const formData = new FormData();

    formData.append('id', banner.id || '');
    formData.append('banner', JSON.stringify(banner.banner));
    formData.append('secondaryBanner', JSON.stringify(banner.secondaryBanner));
    formData.append('type', banner.type);
    if (banner.files?.length > 0) {
      banner.files.forEach(file => formData.append('files', file));
    }
    return formData;
  }
  static async getAllBanners(query?: CreateQueryParams) {
    try {
      const data = await getWithSaga('banners', query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getOneBanner(id: number) {
    try {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const data = useGetWithSWR(`banners/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async saveBanner(banner: BannerPayload) {
    try {
      const config: MultiPartRequestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = this.createFormData(banner);
      if (banner.id) {
        return this.updateBanner(formData, config);
      } else {
        return this.createBanner(formData, config);
      }
    } catch (error) {
      return error;
    }
  }

  static async createBanner(
    formData: FormData,
    config: MultiPartRequestConfig,
  ) {
    try {
      const { data } = await api.post('banners', formData, config);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updateBanner(
    formData: FormData,
    config: MultiPartRequestConfig,
  ) {
    try {
      const { data } = await api.put(`banners/update-one`, formData, config);
      return data;
    } catch (error) {
      return error;
    }
  }
}
