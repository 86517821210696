import React, { useState, FC, useRef } from 'react';
import { Typography, Card, Grid } from '@material-ui/core';
import { ReactComponent as PlayButton } from 'assets/img/svg/play.svg';
import { useStyles } from './styles';
import ReactPlayer from 'react-player';
import { VideoCardProps } from './types';

const VideoCard: FC<VideoCardProps> = ({
  videoIndex,
  author,
  title,
  url,
  light = false,
  controls,
  cardHeight = '450px',
  playerWidth = '640px',
  playerHeight = '360px',
  handlePlay,
  className = '',
  showDetails = true,
}) => {
  const cardStyle = { width: playerWidth, height: cardHeight };
  const gridPlayerStyle = { height: showDetails ? playerHeight : cardHeight };
  const refReactPlayer = useRef<ReactPlayer>(null);
  const styles = useStyles();
  const [playing, setPlaying] = useState(false);

  const _handlePlay = () => {
    if (handlePlay) {
      handlePlay({ author, title, url }, videoIndex);
      if (refReactPlayer.current) {
        refReactPlayer.current.showPreview();
      }
    } else {
      setPlaying(!playing);
    }
  };

  const VideoPlayyerDetails = () => (
    <Grid className={styles.gridVideoInfo} container item direction="column">
      <Grid item>
        <Typography
          variant="h4"
          color="textPrimary"
          className={styles.videoInfoAuthor}
        >
          {author}
        </Typography>
      </Grid>
      <Grid item>
        <Typography
          variant="h2"
          color="textPrimary"
          className={styles.videoInfoTitle}
        >
          {title}
        </Typography>
      </Grid>
    </Grid>
  );

  return (
    <>
      <Card className={className} style={cardStyle}>
        <Grid>
          <Grid item style={gridPlayerStyle} xs>
            <ReactPlayer
              ref={refReactPlayer}
              playIcon={<PlayButton />}
              light={light}
              url={url}
              playing={playing}
              controls={controls}
              width={playerWidth}
              height={playerHeight}
              onClickPreview={_handlePlay}
            />
          </Grid>
          {showDetails && <VideoPlayyerDetails />}
        </Grid>
      </Card>
    </>
  );
};

export default VideoCard;
