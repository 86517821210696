import { Dialog } from '@material-ui/core';
import { ReactComponent as Logo } from 'assets/img/svg/logo_mbc.svg';
import MyHeaderBar from 'components/generals/header/MyHeaderBar';
import MyDrawer from 'components/generals/menu/MyDrawer';
import MyDrawerItem from 'components/generals/menu/MyDrawerItem';
import MyDrawerSection from 'components/generals/menu/MyDrawerSection';
import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import { getAvailableRoutes, getAvailableDrawers } from 'routes/utils';
import { AppState } from 'store';
import { closeModal } from 'store/ducks/nav/actions';
import { Modal } from 'store/ducks/nav/types';
import { UserState } from 'store/ducks/user/types';
import { Sizes } from 'styles/sizes';
import { IDrawer, IDrawerItem, IRoute } from 'types/generals/routes';
import { useHomeStyles } from './styles';

const AdminAuthRoutes: FC = () => {
  const dispatch = useDispatch();
  const classes = useHomeStyles();

  const pageTitle = useSelector<AppState, string>(
    ({ nav }: AppState) => nav.pageTitle,
  );

  const { open, maxWidth, component: Component, props } = useSelector<
    AppState,
    Modal
  >(({ nav }: AppState) => nav.modal);

  const { data: userData } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const [routes, setRoutes] = useState<IRoute[]>(
    getAvailableRoutes(userData || {}),
  );
  const [drawers, setDrawers] = useState<IDrawer[]>(
    getAvailableDrawers(userData || {}),
  );

  /* Update available routes every time user changes */
  useEffect(() => {
    if (userData) {
      setRoutes(getAvailableRoutes(userData));
      setDrawers(getAvailableDrawers(userData));
    }
  }, [userData]);

  return (
    <div className={classes.root}>
      <MyDrawer>
        <Logo
          overflow="visible"
          style={{
            width: '4rem',
            marginLeft: Sizes.DRAWER_LEFT_PADDING + Sizes.DRAWER_LEFT_BORDER,
            marginTop: '1rem',
            marginBottom: '1rem',
          }}
        />
        {drawers.map((drawer: IDrawer) => (
          <MyDrawerSection
            name={drawer.name}
            icon={drawer.icon}
            to={drawer.to}
            key={drawer.name}
          >
            {drawer.items.map((item: IDrawerItem) => (
              <MyDrawerItem key={item.name} to={item.to}>
                {item.name}
              </MyDrawerItem>
            ))}
          </MyDrawerSection>
        ))}
      </MyDrawer>

      {/* Main content */}
      <main className={classes.content}>
        <MyHeaderBar userName={userData?.name} title={pageTitle} />
        <Switch>
          {routes.map(route => (
            <Route
              exact={route.exact}
              path={route.path}
              component={route.component}
              key={route.path}
            />
          ))}
          <Route
            exact
            path="/admin"
            component={() => <Redirect to={{ pathname: '/admin/panel' }} />}
          />
        </Switch>
      </main>

      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        onClose={() => dispatch(closeModal())}
      >
        {Component ? <Component {...props} /> : null}
      </Dialog>
    </div>
  );
};

export default AdminAuthRoutes;
