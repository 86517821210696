import { ElementType } from 'react';
import { Grid, styled, Typography } from '@material-ui/core';

import Colors from 'styles/colors';

export const Wrapper = styled(Grid)({
  position: 'relative',
});

export const Title = styled(Typography)({
  fontWeight: 'bold',
  maxWidth: '300px',
  textAlign: 'center',
  color: Colors.BLACK,
  margin: '24px 0 16px 0',
});

export const Message = styled(Typography)({
  maxWidth: '400px',
  marginBottom: 8,
  color: Colors.DARK_GRAY,
  lineHeight: 1.5,
});

export const IconWrapper = styled(Grid)({
  width: '100%',
  position: 'absolute',
  top: 0,
  right: 0,
});

export const Icon = styled<ElementType<any>>(Grid)({
  marginTop: 8,
});
