import React, { FC, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useForm } from 'react-hook-form';
import { StatusCodes } from 'http-status-codes';
import { useHistory } from 'react-router-dom';

import useFormStyles from 'components/generals/forms/register/styles';
import { useDialogStyles } from '../useStyles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { BaseDialogProps } from '../dialogTypes';
import { shippingEditSchema } from './utils';
import { AppState } from 'store';
import { closeModal } from 'store/ducks/nav/actions';
import { ShippingOneState } from 'store/ducks/shipping/types';
import ShippingService from 'services/shippingService';
import { TRANSPORT_COMPANIES, SHIPPING_STATUS } from 'types/generals';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { SHIPPING_UPDATE_SUCCESS, SHIPPING_UPDATE_ERROR } from 'utils/messages';
import MySelectForm from 'components/generals/input/MySelectForm';

export interface EditShippingDialogPops extends BaseDialogProps {
  transport: string;
}

const ShippingDialog: FC<EditShippingDialogPops> = ({
  title,
  message,
  transport,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const formStyles = useFormStyles();
  const styles = useDialogStyles();
  const [submitLoading, setSubmitLoading] = useState(false);

  const SHIPPING_STATUS_EDIT = [
    SHIPPING_STATUS.ACTIVE,
    SHIPPING_STATUS.CLOSED,
    SHIPPING_STATUS.INACTIVE,
  ];

  const { data: shipping } = useSelector<AppState, ShippingOneState>(
    ({ shippingReducer }: AppState) => shippingReducer,
  );

  const { control, errors, handleSubmit, register } = useForm({
    validationSchema: shippingEditSchema,
    defaultValues: {
      name: shipping.name,
      transport: shipping.transport,
      status: shipping.status,
    },
  });

  const updateShippingInfo = async (
    name: string,
    transport: TRANSPORT_COMPANIES,
    status: SHIPPING_STATUS,
  ) => {
    try {
      setSubmitLoading(false);
      const response = await ShippingService.updateNameOrTransport(
        shipping.id,
        name,
        transport,
        status,
      );

      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(SHIPPING_UPDATE_SUCCESS));
        dispatch(closeModal());
        history.go(0);
      }
    } catch (error) {
      dispatch(notifyError(SHIPPING_UPDATE_ERROR));
    } finally {
      setSubmitLoading(false);
    }
  };

  const onSubmit = async (data: any) => {
    const { name, transport, status } = data;
    await updateShippingInfo(name, transport, status);
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container className={styles.dialogSpacing} component="form">
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h3" color="textPrimary">
              {message}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item justify="space-between" spacing={2}>
          <Grid item xs={12}>
            <MyOutlinedTextField
              id="name"
              name="name"
              label="Nome da Remessa"
              fullWidth
              type="text"
              inputRef={register}
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="transport"
              control={control}
              as={({ onChange, value }) => (
                <MyOutlinedTextField
                  fullWidth
                  id="transport-field"
                  label="Transportadora"
                  name="transport"
                  type="text"
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors.transport)}
                  helperText={
                    errors.transport ? errors.transport.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="status"
              control={control}
              as={({ value, onBlur, onChange }) => (
                <MySelectForm
                  contracted
                  title="Status"
                  itens={SHIPPING_STATUS_EDIT.map(shipping => ({
                    title: shipping,
                    value: shipping,
                  }))}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  error={false}
                  helperText={null}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          item
          className={styles.dialogSpacing}
          justify="flex-end"
        >
          <Button
            color="primary"
            className={formStyles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => dispatch(closeModal())}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${formStyles.buttonLabel} ${formStyles.buttonWrapper}`}
            onClick={handleSubmit(onSubmit)}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <>
                {'Salvando'}
                <CircularProgress className={styles.progress} size={15} />
              </>
            ) : (
              'Salvar'
            )}
          </Button>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default ShippingDialog;
