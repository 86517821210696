import { ORDER_STATUS_COTATION } from 'types/generals';

export enum PreShippimentOrderTypes {
  LOAD_PRE_SHIPPMENT_ORDERS = '@preshippiment/LOAD_PRE_SHIPPMENT_ORDERS',
  UPDATE_PRE_SHIPPIMENT_ORDERS = '@preshippiment/UPDATE_PRE_SHIPPIMENT_ORDERS',
  RESET_PRE_SHIPPIMENT_ORDERS = '@preshippiment/RESET_PRE_SHIPPIMENT_ORDERS',
}

interface PreShippimentOrders {
  id: number;
  transport: string;
  statusCotation: ORDER_STATUS_COTATION | null;
}

export interface PreShippimentOrderState {
  readonly total: number;
  readonly data: PreShippimentOrders[];
}

export interface PreShippimentOrderPayload {
  total?: number;
  data: PreShippimentOrders[];
}
