import * as Yup from 'yup';
import {
  cardNumber,
  cardName,
  dueMonth,
  dueYear,
  securityCode,
} from 'utils/formYupFields';

export const validationSchema = Yup.object({
  cardNumber: cardNumber,
  cardName: cardName,
  dueMonth: dueMonth,
  dueYear: dueYear,
  securityCode: securityCode,
});
