import { action } from 'typesafe-actions';
import { AuthTypes, Auth, Login } from './types';

export const loginFailure = () => action(AuthTypes.LOGIN_FAILURE);

export const loginRequest = (data: Login) =>
  action(AuthTypes.LOGIN_REQUEST, data);

export const loginSuccess = (data: Auth) =>
  action(AuthTypes.LOGIN_SUCCESS, { data });

export const logoutFailure = () => action(AuthTypes.LOGOUT_FAILURE);

export const logoutRequest = () => action(AuthTypes.LOGOUT_REQUEST);

export const logoutSuccess = () => action(AuthTypes.LOGOUT_SUCCESS);
