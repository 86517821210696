import { ShippingOneState, ShippingTypes } from './types';
import { Reducer } from 'redux';
import { Shipping } from 'types/generals';

const INITIAL_STATE: ShippingOneState = {
  data: {} as Shipping,
  hasError: false,
  isLoading: true,
};

const plpReducer: Reducer<ShippingOneState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ShippingTypes.LOAD_SHIPPING_ONE_REQUEST:
      return { ...state, isLoading: true };
    case ShippingTypes.LOAD_SHIPPING_ONE_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload,
      };
    default:
      return state;
  }
};

export default plpReducer;
