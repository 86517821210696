import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

import { AppState } from 'store';
import { loadAllAddresses } from 'store/ducks/generals/address/actions';
import { AddressState } from 'store/ducks/generals/address/types';
import { openModal } from 'store/ducks/nav/actions';
import MyTableCell from 'components/generals/table/MyTableCell';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import TagWrapper from 'components/generals/tag';
import { DEFINED_TAGS } from 'components/generals/tag/types';
import { formatColumnValue } from 'helpers/formatters';
import { useFilters } from 'hooks/filters';
import Colors from 'styles/colors';
import { Address, FormatOptions } from 'types';
import { profileStyles } from '../styles';
import { SubscriberPageProps } from '../types';
import { EDIT_ADDRESS_MESSAGE } from 'utils/messages';
import { CondOperator } from '@nestjsx/crud-request';
import SubscriberAddressDialog from 'components/admin/SubscriberAddressDialog';
import { AddressDialogProps } from 'components/admin/SubscriberAddressDialog/types';

const SubscriberAddressPage: FC<SubscriberPageProps> = ({ signature }) => {
  const dispatch = useDispatch();
  const style = profileStyles();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const { page, handleSetValuePage } = useFilters({});

  const { data: addresses, total, isLoading, hasError } = useSelector<
    AppState,
    AddressState
  >(({ address }: AppState) => address);
  const loadUserAddressses = useCallback(() => {
    dispatch(
      loadAllAddresses({
        sort: { field: 'isDefault', order: 'DESC' },
        filter: {
          field: 'user.id',
          operator: CondOperator.EQUALS,
          value: signature.user.id,
        },
        page: page + 1,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, page, rowsPerPage, signature]);

  useEffect(() => {
    loadUserAddressses();
  }, [loadUserAddressses]);

  const zipCodeFormatted = (zipcode: string) =>
    formatColumnValue(FormatOptions.ZIPCODE, zipcode);

  const addressModal = useCallback(
    (address?: Address) => {
      const modalProps: AddressDialogProps = {
        title: address ? 'Editar endereço' : 'Adicionar endereço',
        message: EDIT_ADDRESS_MESSAGE,
        address,
        user: signature.user,
        signature: signature,
      };
      dispatch(openModal(SubscriberAddressDialog, modalProps));
    },
    [dispatch, signature],
  );

  return (
    <Paper className={style.rootPaper} elevation={0}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs>
          <Typography
            variant="h1"
            color="textPrimary"
            style={{ fontWeight: 'normal' }}
          >
            <strong>Endereços</strong>
          </Typography>
        </Grid>
        <Grid container item xs justify="flex-end">
          <Button onClick={() => addressModal()}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                color: Colors.RED,
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              Adicionar endereço
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} className={style.mTop}>
        <TableContainer>
          <Table aria-label="user addresses">
            <TableHead>
              <TableRow>
                <MyTableCell>Rua</MyTableCell>
                <MyTableCell>Número</MyTableCell>
                <MyTableCell>CEP</MyTableCell>
                <MyTableCell>Bairro</MyTableCell>
                <MyTableCell>Cidade</MyTableCell>
                <MyTableCell>Estado</MyTableCell>
                <MyTableCell>Ações</MyTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && !hasError
                ? addresses.map(address => (
                    <TableRow key={address.id}>
                      <MyTableCell>
                        {address.street}
                        {address.isDefault ? (
                          <Grid item xs>
                            <TagWrapper
                              config={DEFINED_TAGS.STATUS}
                              value={'Padrão'}
                            />
                          </Grid>
                        ) : null}
                      </MyTableCell>
                      <MyTableCell>{address.number}</MyTableCell>
                      <MyTableCell>
                        {zipCodeFormatted(address.zipcode)}
                      </MyTableCell>
                      <MyTableCell>{address.neighborhood}</MyTableCell>
                      <MyTableCell>{address.city}</MyTableCell>
                      <MyTableCell>{address.state}</MyTableCell>
                      <MyTableCell>
                        <Button onClick={() => addressModal(address)}>
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            style={{
                              color: Colors.RED,
                              fontWeight: 'bold',
                              textTransform: 'capitalize',
                            }}
                          >
                            Editar endereço
                          </Typography>
                        </Button>
                      </MyTableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container item xs justify="flex-end">
          <TablePagination
            ActionsComponent={MyTablePaginationActions}
            component="div"
            count={total}
            page={page}
            labelRowsPerPage="Itens por página"
            onChangePage={(_event: unknown, newPage: number) =>
              handleSetValuePage(newPage)
            }
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
              handleSetValuePage(0);
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
            rowsPerPageOptions={ROWS_PER_PAGE}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SubscriberAddressPage;
