import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import {
  InputLabel,
  MenuItem,
  InputBase,
  FormHelperText,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      border: `1px solid ${Colors.GRAY}`,
      backgroundColor: Colors.WHITE,
    },
    input: {
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: '1.5em',
      borderRadius: 4,
      padding: '1.15em',
      margin: '0.5em',
      paddingLeft: '5px',
      '&:focus': {
        outline: 'none',
        backgroundColor: Colors.WHITE,
      },
    },
  }),
)(InputBase);

export const ContractedBootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      border: `1px solid ${Colors.GRAY}`,
      backgroundColor: Colors.WHITE,
    },
    input: {
      borderRadius: 4,
      padding: '27px 12px 10px',
      fontSize: '16px',
      '&:focus': {
        outline: 'none',
        backgroundColor: Colors.WHITE,
      },
    },
  }),
)(InputBase);

export const MyLabel = withStyles(() =>
  createStyles({
    root: {
      transform: 'translate(3em, 30px)',
      fontSize: '1.75em',
    },
  }),
)(InputLabel);

export const MyLabelContracted = withStyles(() =>
  createStyles({
    root: {
      fontSize: '1.4rem',
      marginRight: '60px',
      transform: 'translate(1em, 2rem)',
      whiteSpace: 'nowrap',
    },
  }),
)(InputLabel);

export const MyMenuItem = withStyles(() =>
  createStyles({
    root: {
      fontSize: '1.5em',
    },
  }),
)(MenuItem);

export const MyKeyboardArrowDownIcon = withStyles(() =>
  createStyles({
    root: {
      width: '2em',
      height: '2em',
      transform: 'translateX(-1em)',
    },
  }),
)(KeyboardArrowDownIcon);

export const MyFormHelperText = withStyles(() =>
  createStyles({
    root: {
      color: Colors.RED,
    },
  }),
)(FormHelperText);

export const useStyles = makeStyles({
  select: {
    backgroundColor: 'white !important',
    border: 'none !important',
    borderRadius: '15px !important',
    '& .MuiSelect-select.MuiSelect-select': {
      borderRadius: '15px !important',
    },
  },
});

// export { useStyles };
