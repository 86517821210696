import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Grid, Typography } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import { format, parseISO } from 'date-fns';
import { head } from 'lodash';

import { AppState } from 'store';
import {
  actUpdatePageTitle,
  closeModal,
  openModal,
} from 'store/ducks/nav/actions';
import {
  cancelOrder,
  loadOneOrder,
  reactivateOrder,
} from 'store/ducks/order/actions';
import { OrderOneState } from 'store/ducks/order/types';

import MyDropdown from 'components/generals/dropdown/MyDropdown';
import PaperTitle from 'components/admin/PaperTitle';
import OrderInfo from './OrderInfo';
import PaymentData from './PaymentData';
import OrderStatus from './OrderStatus';
import OrderItems from './OrderItems';

import { useStyles } from './styles';
import PaymentStatus from './PaymentStatus';
import {
  ORDER_CANCELLING_CONFIRM,
  ORDER_REACTIVATE_CONFIRM,
  ORDER_REFUND_ERROR,
  ORDER_REFUND_SOLICITATION,
  REFUND_PARCIAL_PAYMENT,
  ORDER_SECOND_COPY_BANKSLIP_CONFIRM,
  ORDER_SECOND_COPY_BANKSLIP_ESSUED,
  ORDER_SECOND_COPY_BANKSLIP_ERROR,
  ORDER_CHARGE_CONFIRM,
  ORDER_CHARGE_TITLE,
  ORDER_CHARGE_SUCCESS,
  ORDER_CHARGE_ERROR,
  ORDER_STATUS_CHANGE_CONFIRM,
  ORDER_STATUS_CHANGE_SUCCESS,
  ORDER_STATUS_CHANGE_ERROR,
} from 'utils/messages';
import { OrderParams } from './types';
import {
  ORDER_STATUS,
  ORDER_TYPE,
  Order,
  RECURRENCES_MONTHS,
  ORDER_REFERENCE_REASON,
  ENHANCE_STATUS,
} from 'types/generals';
import {
  notifyError,
  notifySuccess,
  enableButton,
  disableButton,
} from 'store/ducks/notification/actions';
import OrdersService from 'services/ordersService';
import ChargebackDialog from 'components/generals/dialog/ChargebackDialog';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import FeedbackDialog from 'components/generals/dialog/FeedbackDialog';
import TimelineOrderModal from 'components/generals/timelineOrderModal';
import {
  BaseDialogProps,
  ChargebackDialogProps,
  FeedbackDialogPropsBase,
} from 'components/generals/dialog/dialogTypes';
import BindInvoiceOrderDialog from 'components/generals/dialog/BindInvoiceOrderDialog';
import OrderInvoice from './OrderInvoice';
import InternalStatus from './InternalStatus';
import TagWrapper, { DEFINED_TAGS } from 'components/generals/tag';

import { ReactComponent as SuccessIcon } from 'assets/img/svg/icon_active_sucess.svg';
import OrderReferenced from 'components/generals/OrderReferenced';
import {
  canDisableChangeStatusOrder,
  canDisableOrEnableChargeOrder,
  canDisableReactivate,
} from './utils';
import { UpdateInvoiceAccessKeyDialogProps } from 'components/generals/dialog/invoicesDialog/types';
import UpdateInvoiceKeyDialog from 'components/generals/dialog/invoicesDialog/UpdateInvoiceKeyDialog';

const OrderDetail: FC = () => {
  const params = useParams<OrderParams>();
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const orderId: string = params.id;

  const { data: order, isLoading } = useSelector<AppState, OrderOneState>(
    ({ orderReducer }: AppState) => orderReducer,
  );

  const orderRef = head(order.referenceOrders);

  const formatDate = (data: string) => {
    return format(parseISO(data), 'dd/MM/yyyy');
  };

  const loadSpecificOrder = useCallback(() => {
    dispatch(
      loadOneOrder(orderId, {
        join: [
          ['orderDetail'],
          ['payments'],
          ['sigepLabel'],
          ['shipping'],
          ['signature'],
        ],
      }),
    );
  }, [dispatch, orderId]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Pedidos > Detalhe do Pedido'));
  }, [dispatch]);

  useEffect(() => {
    loadSpecificOrder();
  }, [loadSpecificOrder]);

  const handleConfirmActionDialog = useCallback(
    (title: string, message: string, actionFn) => {
      const dialogProps: BaseDialogProps = {
        actionFn,
        title,
        message,
      };

      dispatch(openModal(ConfirmationDialog, dialogProps));
    },
    [dispatch],
  );

  const handleChargebackDialog = (
    title: string,
    message: string,
    actionFn: any,
    order: Order,
  ) => {
    try {
      const dialogProps: ChargebackDialogProps = {
        actionFn,
        title,
        message,
        order,
      };
      dispatch(openModal(ChargebackDialog, dialogProps));
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const canRefundOrder = useMemo(() => {
    if (!isLoading) {
      const hasStatusToRefund = order.orderDetail.amount > 0;
      return (
        order.orderDetail.orderType === ORDER_TYPE.STORE && hasStatusToRefund
      );
    }
  }, [order.orderDetail, isLoading]);

  const handleRedirectToResend = () =>
    history.push(`/admin/orders/${order.id}/resend`, { order });

  const handleActionOrder = (action: any) => {
    try {
      dispatch(action);
      dispatch(closeModal());
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleRefundOrder = async (
    orderId: number,
    refundReason: string,
    amountToRefund: number,
  ) => {
    try {
      let response: any;
      if (amountToRefund) {
        response = await OrdersService.partialRefundPayment(
          orderId,
          refundReason,
          amountToRefund,
        );
      } else {
        response = await OrdersService.refundPayment(orderId, refundReason);
      }

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ORDER_REFUND_SOLICITATION));
        loadSpecificOrder();
      } else {
        throw new Error(ORDER_REFUND_ERROR(response.data.message));
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleTryingPaymentBlankslip = async (id: string) => {
    try {
      const response = await OrdersService.tryingBankSlipOrderPayment(id);
      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ORDER_SECOND_COPY_BANKSLIP_ESSUED));
        dispatch(closeModal());
      } else {
        throw new Error(ORDER_SECOND_COPY_BANKSLIP_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleTryingChargeOrder = async (orderId: string) => {
    try {
      dispatch(disableButton());
      const response = await OrdersService.tryingChargeOrder(orderId);

      if (response.status === StatusCodes.CREATED) {
        const modalProps: FeedbackDialogPropsBase = {
          title: 'Solicitação de cobrança',
          message: ORDER_CHARGE_SUCCESS,
          icon: SuccessIcon,
        };
        dispatch(openModal(FeedbackDialog, modalProps));
      } else {
        throw new Error(ORDER_CHARGE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleTryingChangeStatusOrderToPaid = async (orderId: number) => {
    try {
      dispatch(disableButton());
      const response: any = await OrdersService.tryingChangeOrderStatusToPaid(
        orderId,
      );
      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ORDER_STATUS_CHANGE_SUCCESS));
        history.go(0);
      } else {
        const message =
          response?.response?.data?.message || ORDER_STATUS_CHANGE_ERROR;
        throw new Error(message);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(closeModal());
      dispatch(enableButton());
    }
  };

  const handleOpenTimelineModal = (order: Order) => {
    dispatch(openModal(TimelineOrderModal, { order }));
  };

  const handleBindInvoiceOrder = (orderId: number) => {
    const modalProps = {
      orderId,
    };
    dispatch(openModal(BindInvoiceOrderDialog, modalProps));
  };

  const handleUpdateInvoiceAccessKey = (orderId: number) => {
    const modalProps: UpdateInvoiceAccessKeyDialogProps = {
      orderIds: [orderId],
    };

    dispatch(openModal(UpdateInvoiceKeyDialog, modalProps));
  };

  const Actions = () => (
    <MyDropdown
      buttonProps={{
        color: 'primary',
        variant: 'contained',
        style: {
          width: '100%',
          borderRadius: 13,
        },
      }}
      menuProps={{
        classes: {
          list: styles.filterOptionsList,
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        PaperProps: {
          style: {
            marginTop: '1rem',
            borderRadius: 13,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }}
      menuItems={[
        {
          title: 'Cancelar',
          disabled: order.status === ORDER_STATUS.CANCELED,
          action: () =>
            handleConfirmActionDialog(
              'Cancelar Pedido',
              ORDER_CANCELLING_CONFIRM,
              () => handleActionOrder(cancelOrder(order.id.toString())),
            ),
        },
        {
          title: 'Reativar',
          disabled: canDisableReactivate(order),
          action: () =>
            handleConfirmActionDialog(
              'Reativar Pedido',
              ORDER_REACTIVATE_CONFIRM,
              () => handleActionOrder(reactivateOrder(order.id.toString())),
            ),
        },
        {
          title: 'Reenviar',
          action: () => handleRedirectToResend(),
        },
        {
          title: 'Estornar',
          hidden: !canRefundOrder,
          action: () =>
            handleChargebackDialog(
              'Estornar Pedido',
              REFUND_PARCIAL_PAYMENT(order.orderDetail.amount),
              (orderId: number, refundReason: string, amountToRefund: number) =>
                handleRefundOrder(orderId, refundReason, amountToRefund),
              order,
            ),
        },
        {
          title: '2ª via boleto',
          action: () =>
            handleConfirmActionDialog(
              'Emitir 2ª via do boleto',
              ORDER_SECOND_COPY_BANKSLIP_CONFIRM,
              () => handleTryingPaymentBlankslip(String(order.id)),
            ),
        },
        {
          title: 'Cobrar',
          disabled: canDisableOrEnableChargeOrder(order),
          action: () =>
            handleConfirmActionDialog(
              ORDER_CHARGE_TITLE,
              ORDER_CHARGE_CONFIRM,
              () => handleTryingChargeOrder(String(order.id)),
            ),
        },
        {
          title: 'Alterar para pago',
          disabled: canDisableChangeStatusOrder(order),
          action: () =>
            handleConfirmActionDialog(
              'Status do pedido',
              ORDER_STATUS_CHANGE_CONFIRM(order.status, ORDER_STATUS.PAID),
              () => handleTryingChangeStatusOrderToPaid(order.id),
            ),
        },
        {
          title: 'Vincular NF',
          action: () => handleBindInvoiceOrder(order.id),
        },
        {
          title: 'Atualizar chaves NF',
          action: () => handleUpdateInvoiceAccessKey(order.id),
        },
        {
          title: 'Log do pedido',
          action: () => handleOpenTimelineModal(order),
        },
      ]}
    >
      <Typography variant="h3" className={styles.actionButton}>
        Ações
      </Typography>
    </MyDropdown>
  );

  return (
    <Grid
      container
      direction="column"
      justify="flex-start"
      alignItems="center"
      spacing={3}
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      {!isLoading && (
        <Grid item container alignItems="center" justify="space-between">
          <Grid item md={6} sm={4} xs={2}>
            <PaperTitle
              title={`Pedido ${order.id} - ${
                order.orderDetail.orderType
              } -  Data: ${formatDate(order.paymentDate)}`}
              marginB={5}
            />
            {order.isResend && orderRef ? (
              <Typography
                variant="h2"
                style={{
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  marginBottom: 10,
                }}
              >
                {`(Referência: ${orderRef.id})`}
              </Typography>
            ) : null}
            {order.shipping ? (
              <Grid item xs>
                <TagWrapper
                  config={DEFINED_TAGS.INFO}
                  value={order.shipping.name}
                />
              </Grid>
            ) : null}
            {order.isFirstPaidOrder ? (
              <Grid item xs>
                <TagWrapper config={DEFINED_TAGS.STATUS} value={'1ª pedido'} />
              </Grid>
            ) : null}
            {order.isFirstOrderGift ? (
              <Grid item xs>
                <TagWrapper config={DEFINED_TAGS.INFO} value={'Brinde'} />
              </Grid>
            ) : null}
            {order.oldOrder ? (
              <Typography
                variant="h2"
                style={{
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  marginBottom: 10,
                }}
              >
                {`Pedido antigo: ${order.oldOrder.codigo_pedido} - `}
                {`nf_bling: ${order.oldOrder.nf_bling} - `}
                {`nf_bling_tri: ${order.oldOrder.nf_bling_tri}`}
              </Typography>
            ) : null}
            {order.isEnhanced && orderRef ? (
              <Typography
                variant="h2"
                style={{
                  fontStyle: 'italic',
                  fontWeight: 'bold',
                  marginBottom: 10,
                }}
              >
                {`(Referência da turbinação: ${orderRef.id})`}
              </Typography>
            ) : null}
          </Grid>
          <Grid
            container
            item
            xs={4}
            sm={4}
            md={4}
            spacing={3}
            justify="flex-end"
          >
            <OrderInfo order={order} />{' '}
          </Grid>
          <Grid
            container
            item
            xs={4}
            sm={2}
            md={2}
            spacing={3}
            justify="flex-end"
          >
            <Grid item xl={8}>
              <Actions />
            </Grid>
          </Grid>

          <Grid container direction="row" justify="space-between">
            <Grid item xs>
              {order.isDonation ? (
                <Grid item xs>
                  <TagWrapper config={DEFINED_TAGS.INFO} value={'Doação'} />
                </Grid>
              ) : null}
              {order.enhanceStatus === ENHANCE_STATUS.PARENT_ENHANCED ? (
                <Grid item xs>
                  <TagWrapper
                    config={DEFINED_TAGS.MESSAGE}
                    value={'Pedido turbinado'}
                  />
                </Grid>
              ) : null}
            </Grid>
          </Grid>

          {!isLoading ? <PaymentData order={order} /> : null}
          {!isLoading ? <OrderStatus order={order} /> : null}
          {!isLoading ? (
            <OrderReferenced
              order={order}
              isAdmin={true}
              reason={ORDER_REFERENCE_REASON.ENHANCE}
              refOrderTitle="Status dos pedidos turbinados"
            />
          ) : null}
          {!isLoading ? <OrderItems order={order} /> : null}
          {!isLoading ? <OrderInvoice order={order} /> : null}
          {!isLoading ? <PaymentStatus order={order} /> : null}
          {!isLoading ? <InternalStatus order={order} /> : null}
        </Grid>
      )}
    </Grid>
  );
};

export default OrderDetail;
