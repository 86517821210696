import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';

import { ViewBulkActionTurbineTypes, ViewUserTurbinePayload } from './types';

export const loadViewUsersTurbineRequest = (query?: CreateQueryParams) =>
  action(ViewBulkActionTurbineTypes.LOAD_VIEW_USERS_TURBINE_REQUEST, {
    query,
  });

export const loadViewUsersTurbineFailure = () =>
  action(ViewBulkActionTurbineTypes.LOAD_VIEW_USERS_TURBINE_FAILURE);

export const loadViewUsersTurbineSuccess = (payload: ViewUserTurbinePayload) =>
  action(ViewBulkActionTurbineTypes.LOAD_VIEW_USERS_TURBINE_SUCCESS, payload);
