import * as Yup from 'yup';
import { createStyles, withStyles, Theme, InputBase } from '@material-ui/core';
import Colors from 'styles/colors';
import { Address } from 'types';
import { verifyValidateCard } from 'helpers/creditCards';
import { PAYMENT_TYPE } from 'types/generals';
import { CREDIT_CARD_MAX_LENGTH_NAME } from 'utils/messages';

export const addressValidationSchema = Yup.object({
  zipcode: Yup.string().required('Cep obrigatório'),
  street: Yup.string().required('Endereço obrigatório'),
  number: Yup.string().required('Número obrigatório'),
  neighborhood: Yup.string().required('Bairro obrigatório'),
  city: Yup.string().required('Cidade obrigatório'),
  state: Yup.string()
    .max(2, 'Só é permitido 2 dígitos')
    .required('Estado obrigatório'),
  complement: Yup.string().max(30, 'Limite de caracteres é de 30'),
});

export const creditCardValidationSchema = Yup.object({
  paymentType: Yup.string().required('Selecione uma opção de pagamento'),
  cardNumber: Yup.number()
    .required('Número do cartão obrigatório')
    .min(14, 'Número do cartão deve conter no mínimo 14 dígitos'),
  cardName: Yup.string()
    .required('Nome do cartão obrigatório')
    .max(25, CREDIT_CARD_MAX_LENGTH_NAME),
  dueDate: Yup.string()
    .required('Data de validade obrigatória')
    .test('invalid-duedate', 'Data vencida ou inválida', function (value) {
      if (!value) return this.createError();

      const validate = verifyValidateCard(value);
      if (validate < 1) return this.createError();
      return true;
    }),
  securityCode: Yup.string()
    .required('Código de segurança obrigatório')
    .min(3, 'O Código de Segurança deve conter no mínimo 3 dígitos'),
  ccBrand: Yup.string(),
});

export const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 5,
      border: `1px solid ${Colors.GRAY}`,
    },
    input: {
      padding: 10,
      height: 40,
    },
  }),
)(InputBase);

export const defaultValues = (address?: Address) => {
  return {
    zipcode: address?.zipcode,
    street: address?.street,
    number: address?.number,
    neighborhood: address?.neighborhood,
    city: address?.city,
    state: address?.state,
    complement: address?.complement,
  };
};

export const validationPaymentSchema = Yup.object({
  paymentType: Yup.string().required('Selecione uma opção de pagamento'),
  cards: Yup.string().when('paymentType', {
    is: PAYMENT_TYPE.CARD,
    then: Yup.string().required('Você precisa selecionar um cartão'),
  }),
});
