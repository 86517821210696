import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  CircularProgress,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import { AppState } from 'store';
import { listStoreRequest } from 'store/ducks/store/actions';
import { StoreState } from 'store/ducks/store/types';
import { Container, PageTitle, StyledPaper } from '../styles';
import { ProductsToResendProps } from '../types';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import useDebounce from 'hooks/debounce';
import { CondOperator, CreateQueryParams } from '@nestjsx/crud-request';
import { useFilters } from 'hooks/filters';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { useStyles } from '../styles';
import ProductCountRow from './ProductCountRow';

const ProductsToResend: FC<ProductsToResendProps> = ({
  appendProductsHandler,
}) => {
  const [searchFilter, setSearchFilter] = useState('');
  const [queryParams, setQueryParams] = useState<CreateQueryParams>({});
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const dispatch = useDispatch();
  const styles = useStyles();

  const { page, handleSetValuePage } = useFilters({});

  const {
    data: storeProducts = [],
    total = 0,
    isLoading,
    hasError,
  } = useSelector<AppState, StoreState>(
    ({ storeReducer }: AppState) => storeReducer,
  );

  const loadProducts = useCallback(async () => {
    dispatch(
      listStoreRequest({
        page: page + 1,
        limit: rowsPerPage,
        ...queryParams,
      }),
    );
  }, [dispatch, page, rowsPerPage, queryParams]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleSearch = () => {
    const filter = {
      search: {
        $or: [
          { name: { [CondOperator.CONTAINS_LOW]: searchFilter } },
          { sku: { [CondOperator.CONTAINS_LOW]: searchFilter } },
        ],
      },
    };

    if (filter) setQueryParams(filter);
  };

  const debouncedSearch = useDebounce(handleSearch, 750);

  return (
    <Grid container direction="column">
      <Grid
        item
        xs
        container
        justify="flex-start"
        direction="row"
        alignItems="center"
        spacing={3}
      >
        <Grid item>
          <PageTitle>Produtos</PageTitle>
        </Grid>
        <Grid item>
          <MyInputFilter
            onKeyUp={() => debouncedSearch()}
            onChange={e => setSearchFilter(e.target.value)}
            value={searchFilter}
            placeholder={'Buscar'}
          />
        </Grid>
      </Grid>
      <Grid item xs>
        <StyledPaper elevation={0}>
          <Container container>
            <TableContainer>
              {!isLoading && !hasError ? (
                <Table style={{ minWidth: 650 }} aria-label="products table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Item</TableCell>
                      <TableCell>SKU</TableCell>
                      <TableCell>Quantidade</TableCell>
                      <TableCell>Valor Unit.</TableCell>
                      <TableCell>Ações</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {storeProducts.map(product => (
                      <ProductCountRow
                        key={product.id}
                        appendProductsHandler={appendProductsHandler}
                        product={product}
                      />
                    ))}
                  </TableBody>
                </Table>
              ) : (
                <Grid item xs className={styles.circularProgressContainer}>
                  <CircularProgress size="5em" />
                </Grid>
              )}
            </TableContainer>
            <Grid container justify="flex-end">
              <TablePagination
                ActionsComponent={MyTablePaginationActions}
                component="div"
                count={total}
                page={page}
                labelRowsPerPage="Itens por página"
                onChangePage={(_event: unknown, newPage: number) =>
                  handleSetValuePage(newPage)
                }
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
                  handleSetValuePage(0);
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </Grid>
          </Container>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default ProductsToResend;
