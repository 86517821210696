import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { makeStyles, Grid, Typography } from '@material-ui/core';

import { logoutRequest } from 'store/ducks/auth/actions';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import MyUserProfileImage from './MyUserProfileImage';

const useStyles = makeStyles(theme => ({
  root: {
    marginTop: theme.spacing(1),
  },
}));

interface MyHeaderBarProps {
  title?: string;
  userName?: string;
}

const MyHeaderBar: React.FC<MyHeaderBarProps> = ({ title, userName }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const userFirstName = userName?.split(' ')[0];

  const handleDispatch = () => {
    dispatch(logoutRequest());
    history.replace('/admin/login');
  };

  return (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      classes={{ root: classes.root }}
    >
      <Grid item>
        <Typography color="textPrimary" style={{ fontSize: '2.4rem' }}>
          {title || ''}
        </Typography>
      </Grid>

      <Grid item>
        <Grid container alignItems="center">
          <MyUserProfileImage
            letter={userFirstName?.charAt(0)}
            style={{ marginRight: '1rem' }}
          />
          <Typography
            display="inline"
            color="textPrimary"
            style={{ fontSize: '1.6rem' }}
          >
            Olá,
          </Typography>
          &nbsp;
          <MyDropdown
            menuItems={[
              {
                action: () => handleDispatch(),
                title: 'Sair',
              },
            ]}
          >
            <Typography
              display="inline"
              color="textPrimary"
              style={{ fontWeight: 800, fontSize: '1.6rem' }}
            >
              {userFirstName || 'Desconhecido'}
            </Typography>
          </MyDropdown>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default MyHeaderBar;
