import { action } from 'typesafe-actions';
import { CreateQueryParams } from '@nestjsx/crud-request';

import { InvoiceTypes } from './types';
import { Invoice } from 'types/generals';

export const loadInvoiceRequest = (query: CreateQueryParams) => {
  return action(InvoiceTypes.LOAD_REQUEST, { query });
};

export const loadInvoiceFailure = () => {
  return action(InvoiceTypes.LOAD_FAILURE);
};

export const loadInvoiceSuccess = (payload: {
  total: number;
  data: Invoice[];
}) => {
  return action(InvoiceTypes.LOAD_SUCCESS, payload);
};

export const updateInvoice = (data: Invoice) => {
  return action(InvoiceTypes.UPDATE, data);
};
