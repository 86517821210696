import React from 'react';
import { Grid } from '@material-ui/core';

import { ReactComponent as Logo } from 'assets/img/svg/logo_mbc_text.svg';

import useStyles from './styles';

const Header: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid className={classes.wrapperHeader}>
      <Logo className={classes.logo} />
    </Grid>
  );
};

export default Header;
