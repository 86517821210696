import { Benefits } from 'types/generals/benefits';

export enum BenefitsTypes {
  LOAD_BENEFITS_REQUEST = '@benefits/LOAD_BENEFITS_REQUEST',
  LOAD_BENEFITS_SUCCESS = '@benefits/LOAD_BENEFITS_SUCCESS',
  LOAD_BENEFITS_ERROR = '@benefits/LOAD_BENEFITS_ERROR',
}

export interface BenefitsState {
  readonly total: number;
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly data: Benefits[];
}
