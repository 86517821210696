import { Label } from '../label/types';

export enum TrackingTypes {
  LOAD_SEDEX_REQUEST = '@tracking/LOAD_SEDEX_REQUEST',
  LOAD_SEDEX_SUCCESS = '@tracking/LOAD_SEDEX_SUCCESS',
  LOAD_PAC_SUCCESS = '@tracking/LOAD_PAC_SUCCESS',
  LOAD_PAC_REQUEST = '@tracking/LOAD_PAC_REQUEST',
  LOAD_MODICO_SUCCESS = '@tracking/LOAD_MODICO_SUCCESS',
  LOAD_MODICO_REQUEST = '@tracking/LOAD_MODICO_REQUEST',
  LOAD_FAILURE = '@tracking/LOAD_FAILURE',
}

export interface PostService {
  serviceName: string;
  serviceNumber: string;
}

export interface Tracking {
  sedex: Label[];
  pac: Label[];
  modico: Label[];
}

export interface TrackingState {
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly data: Tracking;
}
