import produce from 'immer';
import { Reducer } from 'redux';
import { BoxesState, BoxesTypes } from './types';

const INITIAL_STATE: BoxesState = {
  isLoading: true,
  hasError: false,
  data: [],
  total: 0,
};

const reducer: Reducer<BoxesState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BoxesTypes.LOAD_BOXES_REQUEST:
      return { ...INITIAL_STATE };
    case BoxesTypes.LOAD_BOXES_SUCCESS:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.hasError = false;
        draft.data = action.payload.data;
        draft.total = action.payload.total;
      });
    case BoxesTypes.LOAD_BOXES_FAIL:
      return produce(state, draft => {
        draft.isLoading = false;
        draft.hasError = true;
        draft.data = [];
        draft.total = 0;
      });
    default:
      return state;
  }
};

export default reducer;
