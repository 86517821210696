import { action } from 'typesafe-actions';

import { InstallmentsPayload, StoreProduct } from 'types/generals';
import {
  ActiveStoreSettings,
  CartDiscountPayload,
  CartPayload,
  CartScoreDataPayload,
  StoreSettings,
} from 'types/client';
import { CartTypes } from './types';
import { Coupon } from 'types/generals/coupon';

export const listCartProducts = () => {
  return action(CartTypes.CART_LIST_PRODUCTS);
};

export const setCartInstallments = (payload: InstallmentsPayload) => {
  return action(CartTypes.CART_SET_INSTALLMENTS, payload);
};

export const clearCartProducts = () => {
  return action(CartTypes.CART_CLEAR_PRODUCTS);
};

export const addCartProduct = (
  payload: StoreProduct,
  activeSettings: ActiveStoreSettings,
) => {
  return action(CartTypes.CART_ADD_PRODUCT, { ...payload, activeSettings });
};

export const deleteCartProduct = (
  payload: CartPayload,
  activeSettings: ActiveStoreSettings,
) => {
  return action(CartTypes.CART_DELETE_PRODUCT, { ...payload, activeSettings });
};

export const increaseCartItemCount = (
  payload: CartPayload,
  activeSettings: ActiveStoreSettings,
) => {
  return action(CartTypes.CART_INCREASE_PRODUCT_AMOUNT, {
    ...payload,
    activeSettings,
  });
};

export const decreaseCartItemCount = (
  payload: CartPayload,
  activeSettings: ActiveStoreSettings,
) => {
  return action(CartTypes.CART_DECREASE_PRODUCT_AMOUNT, {
    ...payload,
    activeSettings,
  });
};

export const applyCartDiscount = (payload: CartDiscountPayload) => {
  return action(CartTypes.CART_APPLY_DISCOUNT, payload);
};

export const applyScoreDiscount = (payload: CartScoreDataPayload) => {
  return action(CartTypes.CART_APPLY_SCORE_DISCOUNT, payload);
};

export const setCartCoupon = (payload: { coupon: Coupon }) => {
  return action(CartTypes.CART_ADD_COUPON, payload);
};

export const deleteCartCoupon = () => {
  return action(CartTypes.CART_REMOVE_COUPON);
};

export const cartActionFail = () => {
  return action(CartTypes.CART_ACTION_FAILURE);
};

export const updateCartAmount = () => {
  return action(CartTypes.CART_UPDATE_AMOUNT);
};

export const handleChangeCartOpen = () => {
  return action(CartTypes.CART_CHANGE_OPEN);
};

export const handleApplyFreightDataFrete = (payload: {
  activeSettings: ActiveStoreSettings;
  freight: number;
  isFirstTransport: boolean;
}) => {
  return action(CartTypes.CART_APPLY_FREIGHT, payload);
};

export const handleAddSelectedTransport = (payload: { transport: string }) => {
  return action(CartTypes.CART_ADD_SELECTED_TRANSPORT, payload);
};
