import { createStyles, Grid, makeStyles, styled } from '@material-ui/core';

import MyHeaderBar from '../MyHeaderBar';

import { ReactComponent as LogoSVG } from 'assets/img/svg/logo_mbc_text.svg';
import { ReactComponent as ScorePointsSVG } from 'assets/img/svg/score_wallet.svg';

import Colors from 'styles/colors';

export const Wrapper = styled(Grid)({
  width: '100%',
  backgroundColor: Colors.RED,
  padding: '8px 20px',
  zIndex: 1,
  position: 'fixed',
  top: 0,
  left: 0,
});

export const Logo = styled(LogoSVG)({
  height: 46,
  width: 98,
  fill: Colors.WHITE,
});

export const ScorePoints = styled(ScorePointsSVG)({
  height: 29,
  width: 29,
  fill: Colors.WHITE,
});

export const HeaderBar = styled(MyHeaderBar)({
  padding: '24px 0 0 0 !important',
});

export const useStyles = makeStyles(theme =>
  createStyles({
    userWalletScoreText: {
      margin: '15px 0 -25px 26px',
      opacity: '0.7',
      fontWeight: 500,
      cursor: 'pointer',
    },
  }),
);
