import { makeStyles } from '@material-ui/core';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  mTop: {
    marginTop: theme.spacing(3),
  },
  filters: {
    maxWidth: '100%',
  },
});
