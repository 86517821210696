import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  badge: {
    color: Colors.SALMON,
  },
  btn: {
    border: '2px solid',
    borderColor: Colors.LIGHT_GRAY,
    borderRadius: 5,
  },
  paperTable: {
    borderRadius: 8,
    backgroundColor: 'transparent',
  },
  shopIcon: {
    height: '2.5rem',
    width: '2.5rem',
  },
  cartText: {
    fontWeight: 'bold',
    color: Colors.DARKER_GRAY,
    fontSize: '1.4rem',
  },
});
