import { ViewSubscriberUser } from 'types/generals/view';

/**
 * Action types
 */
export enum ViewBulkActionTypes {
  LOAD_VIEW_USERS_LAST_ORDER_REQUEST = '@user/LOAD_VIEW_USERS_LAST_ORDER_REQUEST',
  LOAD_VIEW_USERS_LAST_ORDER_SUCCESS = '@user/LOAD_VIEW_USERS_LAST_ORDER_SUCCESS ',
  LOAD_VIEW_USERS_LAST_ORDER_FAILURE = '@user/LOAD_VIEW_USERS_LAST_ORDER_FAILURE',
  CLEAR_USER = '@user/CLEAR_USER',
}

/**
 * State types
 */
export interface ViewUserLastOrderState {
  readonly data: ViewSubscriberUser[];
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly total: number;
}

export interface ViewPayload {
  data: ViewSubscriberUser[] | ViewSubscriberUser;
  total?: number;
}

export interface UpdateRetryPaymentSeveralPayload {
  subscribers: {
    signatureId: number;
    userId: number;
  }[];
}

export interface UpdateRenewDateSeveralPayload {
  renewDate: string;
  subscribers: {
    orderId: number;
    userId: number;
  }[];
}
