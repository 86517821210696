import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

const useStyles = makeStyles({
  container: {
    minHeight: '100vh',
  },
  progress: {
    color: Colors.GRAY,
  },
  infoText: {
    fontWeight: 'bold',
    color: Colors.GRAY,
  },
  bankslipImg: {
    width: 150,
    height: 100,
  },
});

export { useStyles };
