import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    marginTop: {
      marginTop: '20px',
    },
    cardPlayer: {
      padding: '20px',
    },
    videoCard: {
      borderRadius: '15px',
      opacity: 0.8,
      '&:hover': {
        opacity: 1,
      },
    },
    noPadding: {
      padding: 0,
    },
  }),
);
