import React, { FC, useMemo } from 'react';
import { Grid, Button } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import { notifySuccess, notifyError } from 'store/ducks/notification/actions';
import { openModal, closeModal } from 'store/ducks/nav/actions';

import OrdersService from 'services/ordersService';

import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import MyTypography from 'components/generals/labels/MyTypography';
import AmountLabel from 'components/generals/labels/AmountLabel/AmountLabel';

import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { useStyles } from '../styles';
// import { ActionButtonProps } from './types';
import { OrderDetailsProps } from '../types';
import { PAYMENT_TYPE, PAYMENT_STATUS, Payment } from 'types/generals/payment';
import {
  ORDER_SECOND_COPY_BANKSLIP_CONFIRM,
  ORDER_SECOND_COPY_BANKSLIP_ERROR,
  ORDER_SECOND_COPY_BANKSLIP_ESSUED,
} from 'utils/messages';
import { isBefore, isAfter, isEqual, isThisMonth, startOfDay } from 'date-fns';
import { separateDate, separateDateWithTrace } from 'helpers/validateDate';
import { ORDER_STATUS, Order } from 'types/generals';

const OrderPayment: FC<OrderDetailsProps> = ({ order }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const DUE_DATE_BOLETO =
    order.orderDetail.paymentType === PAYMENT_TYPE.BANKSLIP;

  const lastPayment = order.orderLastPayment;

  const ORDER_DETAIL = order.orderDetail;

  const userZipCode = useMemo(
    () => formatColumnValue(FormatOptions.ZIPCODE, ORDER_DETAIL.zipcode),
    [ORDER_DETAIL.zipcode],
  );

  const formatInstallments = (amount: number, installments: number) => {
    if (installments > 1) {
      return formatColumnValue(
        FormatOptions.MONEY,
        Number((amount / installments).toFixed(2)),
      );
    }

    return formatColumnValue(FormatOptions.MONEY, amount);
  };

  // TODO: Quando for feito a parte das invoices corretamente reativo tal parte
  // const handleInvoice = () => {
  //   const win = window.open(order.invoice.linkDanfe, '_blank');
  //   if (win) win.focus();
  // };

  const handleGoToBankSlip = () => {
    const { orderLastPayment } = order;
    const { bankSlipTransaction } = orderLastPayment;
    const win = window.open(bankSlipTransaction?.url, '_blank');
    if (win) win.focus();
  };

  const handleEmitNewBankSlip = async () => {
    try {
      const response = await OrdersService.tryingBankSlipOrderPayment(
        String(order.id),
      );
      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ORDER_SECOND_COPY_BANKSLIP_ESSUED));
        dispatch(closeModal());
        history.push('/my-account/orders');
      } else {
        throw new Error(ORDER_SECOND_COPY_BANKSLIP_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleOpenModalConfirm = () => {
    const modalProps: BaseDialogProps = {
      title: 'Emitir 2ª via do boleto',
      message: ORDER_SECOND_COPY_BANKSLIP_CONFIRM,
      cancelText: 'Não',
      confirmText: 'Sim',
      actionFn: handleEmitNewBankSlip,
    };

    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleRenderActionBankslip = (lastPayment: Payment, order: Order) => {
    if (lastPayment?.bankSlipTransaction) {
      const bankslipDueDate = separateDate(
        lastPayment.bankSlipTransaction.dueDate,
      );
      const currentDate = startOfDay(new Date());
      if (
        order.status === ORDER_STATUS.PENDING &&
        (isAfter(bankslipDueDate, currentDate) ||
          isEqual(bankslipDueDate, currentDate)) &&
        isThisMonth(bankslipDueDate)
      ) {
        return (
          <Button
            className={styles.actionButton}
            onClick={() => handleGoToBankSlip()}
          >
            <MyTypography variant="subtitle1" className={styles.trackingInfo}>
              Ver boleto
            </MyTypography>
          </Button>
        );
      } else if (
        order.status === ORDER_STATUS.PENDING &&
        isBefore(bankslipDueDate, currentDate) &&
        isThisMonth(bankslipDueDate)
      ) {
        return (
          <Button
            className={styles.actionButton}
            onClick={() => handleOpenModalConfirm()}
          >
            <MyTypography variant="subtitle1" className={styles.trackingInfo}>
              Emitir novo boleto
            </MyTypography>
          </Button>
        );
      } else {
        return null;
      }
    }

    if (
      isThisMonth(separateDateWithTrace(order.paymentDate)) &&
      !lastPayment?.bankSlipTransaction &&
      order.orderDetail.paymentType === PAYMENT_TYPE.BANKSLIP
    ) {
      return (
        <Button
          className={styles.actionButton}
          onClick={() => handleOpenModalConfirm()}
        >
          <MyTypography variant="subtitle1" className={styles.trackingInfo}>
            Emitir novo boleto
          </MyTypography>
        </Button>
      );
    }
  };

  return (
    <Grid
      container
      direction="row"
      justify="space-between"
      style={{ marginTop: '25px' }}
    >
      <Grid item xs={12} sm={3} xl={2}>
        <MyTypography variant="h3" className={styles.paperInfoTitle}>
          Dados do Pagamento
        </MyTypography>
        <Grid item xs className={styles.mLabelBold}>
          {DUE_DATE_BOLETO ? (
            <>
              {order.payments.length ? (
                <>
                  <MyTypography
                    className={clsx(styles.mLabel, styles.capitalize)}
                  >
                    {ORDER_DETAIL.paymentType}
                  </MyTypography>
                  <MyTypography
                    className={clsx(styles.mLabel, styles.capitalize)}
                  >
                    Vencimento: {lastPayment?.bankSlipTransaction?.dueDate}
                  </MyTypography>
                </>
              ) : null}
              {handleRenderActionBankslip(lastPayment, order)}
            </>
          ) : (
            <>
              {lastPayment ? (
                <>
                  <MyTypography
                    className={clsx(styles.mLabel, styles.capitalize)}
                  >
                    {lastPayment?.cardTransaction?.cardNumber}
                  </MyTypography>

                  {lastPayment.status !== PAYMENT_STATUS.FAILED && (
                    <MyTypography
                      className={clsx(styles.mLabel, styles.capitalize)}
                    >
                      {`${lastPayment?.cardTransaction?.installments || 1}x `}
                      <strong>
                        {formatInstallments(
                          ORDER_DETAIL.amount,
                          lastPayment?.cardTransaction?.installments || 1,
                        )}
                      </strong>
                    </MyTypography>
                  )}
                  <MyTypography
                    className={clsx(styles.mLabel, styles.capitalize)}
                  >
                    {lastPayment.status}
                  </MyTypography>
                </>
              ) : null}
            </>
          )}
        </Grid>
      </Grid>
      <Grid item xs={12} sm={3}>
        <MyTypography variant="h3" className={styles.paperInfoTitle}>
          Dados de entrega
        </MyTypography>
        <MyTypography className={clsx(styles.mLabel, styles.capitalize)}>
          {ORDER_DETAIL.street}, {ORDER_DETAIL.number}
        </MyTypography>
        <MyTypography className={clsx(styles.mLabel, styles.capitalize)}>
          {ORDER_DETAIL?.complement}
        </MyTypography>
        <MyTypography className={clsx(styles.mLabel, styles.capitalize)}>
          {ORDER_DETAIL?.neighborhood} - {ORDER_DETAIL?.city} -{' '}
          {ORDER_DETAIL?.state}
        </MyTypography>
        <MyTypography className={clsx(styles.mLabel, styles.capitalize)}>
          {userZipCode}
        </MyTypography>
      </Grid>
      <Grid item xs={12} sm={3}>
        <MyTypography variant="h3" className={styles.paperInfoTitle}>
          Total pago
        </MyTypography>
        <Grid container direction="column">
          <AmountLabel
            title="Subtotal"
            amount={ORDER_DETAIL.subtotal.toString()}
          />
          <AmountLabel title="Frete" amount={ORDER_DETAIL.freight.toString()} />
          <AmountLabel
            isDiscount
            title="Desconto"
            amount={ORDER_DETAIL.discount.toString()}
          />
          <AmountLabel
            title="Total"
            amount={ORDER_DETAIL?.amount?.toString() || '0'}
            textBold
            isAmount
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default OrderPayment;
