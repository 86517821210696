import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  stepWrapper: {
    height: 'auto',
    backgroundColor: Colors.WHITE,
    marginBottom: 20,
    padding: '12px 12px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      padding: 12,
      borderRadius: 16,
    },
  },
  stepWrapperDisabled: {
    height: 'auto',
    backgroundColor: Colors.WHITE_SMOKE,
    marginBottom: 20,
    padding: '12px 12px',
    [theme.breakpoints.up('md')]: {
      marginBottom: 0,
      padding: 12,
      borderRadius: 16,
    },
  },
  paperTable: {
    padding: theme.spacing(3),
    borderRadius: '2em',
  },
  cardImg: {
    padding: '15px',
  },
  selfCenter: {
    alignSelf: 'center',
  },
  disabledColor: {
    color: Colors.SILVER,
  },
  content: {
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3, 0, 1),
    },
    [theme.breakpoints.only('xl')]: {
      padding: theme.spacing(3, 0, 2),
    },
    padding: theme.spacing(3, 0, 3),
  },

  boxDescription: {
    fontSize: '13px',
    paddingRight: '12px',
    margin: theme.spacing(1, 0, 3),
    maxHeight: '90px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  boldText: {
    fontWeight: 700,
  },
  enhancedProductInfo: {
    fontSize: 13,
    opacity: 0.8,
    marginTop: 15,
  },
  pricedText: {
    marginTop: '10px',
    fontWeight: 500,
  },
  noPadding: {
    padding: '0 !important',
  },
  container: {
    position: 'relative',
    width: '100%',
  },
  actiontButton: {
    backgroundColor: Colors.BLUE,
    color: Colors.WHITE,
    width: '100%',
    height: 44,
    padding: 12,
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 7,
    '&:hover': {
      backgroundColor: Colors.BLUE,
    },
  },
  actiontButtonToggled: {
    backgroundColor: Colors.LIGHTER_RED,
    color: Colors.RED,
    width: '100%',
    height: 44,
    padding: 12,
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 7,
    '&:hover': {
      backgroundColor: Colors.LIGHTER_RED,
    },
  },
});
