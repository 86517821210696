import React, { FC, MouseEventHandler } from 'react';
import ImageGallery from 'react-image-gallery';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import 'react-image-gallery/styles/css/image-gallery.css';
import './gallery.css';

import { IMyImageGallery } from './types';

const MyImageGallery: FC<IMyImageGallery> = ({ images }) => {
  const backNav = (onClick: MouseEventHandler<HTMLElement>) => (
    <>
      <button
        type="button"
        className="image-gallery-icon image-gallery-left-nav"
        onClick={onClick}
        aria-label="Next Slide"
      >
        <ArrowBackIcon style={{ fontSize: '3.3rem' }} />
      </button>
    </>
  );

  const fwdNav = (onClick: MouseEventHandler<HTMLElement>) => (
    <>
      <button
        type="button"
        className="image-gallery-icon image-gallery-right-nav"
        onClick={onClick}
        aria-label="Prev Slide"
      >
        <ArrowForwardIcon style={{ fontSize: '3.3rem' }} />
      </button>
    </>
  );

  return (
    <ImageGallery
      disableSwipe={false}
      showPlayButton={false}
      showFullscreenButton={false}
      items={images}
      renderLeftNav={backNav}
      renderRightNav={fwdNav}
    />
  );
};

export default MyImageGallery;
