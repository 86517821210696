import { Reducer } from 'redux';
import produce from 'immer';

import { CartState, CartTypes } from './types';
import { StoreProduct } from 'types/generals';
import {
  ActiveStoreSettings,
  CartAmountData,
  CartProductDetails,
  ScoreData,
  ScoreDataStauts,
} from 'types/client';
import { Coupon } from 'types/generals/coupon';
import { applyPercentDiscount } from './utils';
import { verifyIfApplyFreight } from 'utils/functions/generals';

const INITIAL_STATE: CartState = {
  data: [],
  cartProducts: [],
  cartLength: 0,
  cartAmount: 0,
  subTotal: 0,
  freight: 0,
  installments: 1,
  discount: 0,
  productsDiscount: 0,
  scoreData: {
    discount: 0,
    points: 0,
    rankingSeasonId: undefined,
  },
  cartCoupon: {} as Coupon,
  cartOpen: false,
  selectedTransport: '',
  isFirstTransport: false,
};

const mountProductsList = (products: StoreProduct[]): CartProductDetails[] => {
  return products.map(product => {
    return {
      id: product.id,
      productAmount: Number.parseFloat(product.price) * product.amount,
      productCount: product.amount,
    };
  });
};

const applyFreight = (
  freight: number,
  { freightConfig, isPremium }: ActiveStoreSettings,
  subTotal: number,
  productsDiscount: number,
  isFirstTransport: boolean,
) => {
  const priceFreight = verifyIfApplyFreight(
    freight,
    subTotal,
    productsDiscount,
    freightConfig,
    isPremium,
    isFirstTransport,
  );

  if (priceFreight === 0) {
    return 0;
  }
  return freight;
};

const applyAmount = (draft: CartAmountData) => {
  const { subTotal, freight, discount } = draft;
  let _subtotal = Number(subTotal) + Number(-discount);
  _subtotal = _subtotal > 0 ? _subtotal : 0;
  return _subtotal + Number(freight);
};

const applySubTotal = (subTotal: number, price: number) => {
  return subTotal + price;
};

const decreaseSubTotal = (subTotal: number, price: number) => {
  return subTotal - price;
};

const resetScoreData = (draft: CartAmountData) => {
  draft.scoreData.discount = 0;
  draft.scoreData.points = 0;
  draft.scoreData.status = ScoreDataStauts.reset;
};

export const getDiscountValue = (
  productDiscount: number,
  subTotal: number,
  data: Coupon,
) => {
  return data?.isPercent
    ? productDiscount +
        Number(applyPercentDiscount(subTotal, data?.percent | 0))
    : productDiscount + (Number.parseFloat(`${data.value}`) | 0);
};

export const getCouponDiscountValue = (
  productDiscount: number,
  subTotal: number,
  data: Coupon,
) => {
  return data?.isPercent
    ? productDiscount +
        Number(applyPercentDiscount(subTotal, data?.percent | 0))
    : productDiscount + (Number.parseFloat(`${data.value}`) | 0);
};

const cartReducer: Reducer<CartState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CartTypes.CART_LIST_PRODUCTS:
      return produce(state, draft => {
        draft.cartProducts = mountProductsList(draft.data);
      });
    case CartTypes.CART_SET_INSTALLMENTS:
      return produce(state, draft => {
        draft.installments = action.payload.installment;
      });
    case CartTypes.CART_CLEAR_PRODUCTS:
      return INITIAL_STATE;
    case CartTypes.CART_ADD_PRODUCT:
      return produce(state, draft => {
        const indexItem = draft.data.findIndex(
          (product: StoreProduct) => product.id === action.payload.id,
        );
        const { activeSettings, isFirstTransport } = action.payload;
        const price = Number.parseFloat(action.payload.price);
        const productDiscount = Number.parseFloat(action.payload.discount);
        draft.productsDiscount += productDiscount;
        indexItem === -1
          ? draft.data.push(action.payload)
          : (draft.data[indexItem].amount += 1);

        draft.cartLength += 1;
        draft.cartProducts = mountProductsList(draft.data);
        resetScoreData(draft);
        // Calculo do frete
        draft.freight = applyFreight(
          draft.freight,
          activeSettings,
          draft.subTotal,
          draft.discount,
          isFirstTransport,
        );

        draft.subTotal = applySubTotal(draft.subTotal, price);
        draft.discount = getDiscountValue(
          draft.productsDiscount,
          draft.subTotal,
          draft.cartCoupon,
        );
        draft.cartAmount = applyAmount(draft);
      });
    case CartTypes.CART_INCREASE_PRODUCT_AMOUNT:
      return produce(state, draft => {
        const indexItem = draft.data.findIndex(
          (product: StoreProduct) => product.id === action.payload.id,
        );
        const { activeSettings, isFirstTransport } = action.payload;

        if (indexItem >= 0) {
          const price = Number.parseFloat(draft.data[indexItem].price);
          const productDiscount = Number.parseFloat(
            draft.data[indexItem].discount || '0',
          );

          draft.data[indexItem].amount += 1;
          draft.cartLength += 1;
          draft.productsDiscount += productDiscount;
          draft.cartProducts = mountProductsList(draft.data);

          // Calculo do frete
          draft.freight = applyFreight(
            draft.freight,
            activeSettings,
            draft.subTotal,
            draft.discount,
            isFirstTransport,
          );

          draft.subTotal = applySubTotal(draft.subTotal, price);
          draft.discount = getDiscountValue(
            draft.productsDiscount,
            draft.subTotal,
            draft.cartCoupon,
          );
          draft.cartAmount = applyAmount(draft);
        }
      });
    case CartTypes.CART_DECREASE_PRODUCT_AMOUNT:
      return produce(state, draft => {
        const indexItem = draft.data.findIndex(
          (product: StoreProduct) => product.id === action.payload.id,
        );

        if (indexItem >= 0 && draft.data[indexItem].amount > 1) {
          const price = Number.parseFloat(draft.data[indexItem].price);
          const productDiscount = Number.parseFloat(
            draft.data[indexItem].discount || '0',
          );
          const { activeSettings, isFirstTransport } = action.payload;

          draft.data[indexItem].amount -= 1;
          draft.cartLength -= 1;
          draft.productsDiscount -= productDiscount;
          draft.cartProducts = mountProductsList(draft.data);
          resetScoreData(draft);

          // Calculo do frete
          draft.freight = applyFreight(
            draft.freight,
            activeSettings,
            draft.subTotal,
            draft.discount,
            isFirstTransport,
          );

          draft.subTotal = decreaseSubTotal(draft.subTotal, price);
          draft.discount = getDiscountValue(
            draft.productsDiscount,
            draft.subTotal,
            draft.cartCoupon,
          );
          draft.cartAmount = applyAmount(draft);
        }
      });
    case CartTypes.CART_DELETE_PRODUCT:
      return produce(state, draft => {
        const indexItem = draft.data.findIndex(
          (product: StoreProduct) => product.id === action.payload.id,
        );

        if (indexItem >= 0) {
          const price = Number.parseFloat(draft.data[indexItem].price);
          const productPriceAmount = price * draft.data[indexItem].amount;
          const productDiscount = Number.parseFloat(
            draft.data[indexItem].discount || '0',
          );
          const productCount = draft.cartProducts[indexItem].productCount;
          const { activeSettings } = action.payload;
          draft.cartLength -= draft.data[indexItem].amount;
          draft.productsDiscount -= productDiscount * productCount;
          resetScoreData(draft);
          // Calculo do frete
          draft.freight = applyFreight(
            draft.freight,
            activeSettings,
            draft.subTotal,
            draft.discount,
            draft.isFirstTransport,
          );

          draft.subTotal = decreaseSubTotal(draft.subTotal, productPriceAmount);
          draft.discount = getDiscountValue(
            draft.productsDiscount,
            draft.subTotal,
            draft.cartCoupon,
          );
          draft.cartAmount = applyAmount(draft);
          draft.data.splice(indexItem, 1);
          draft.cartProducts = mountProductsList(draft.data);
        }
      });
    case CartTypes.CART_ADD_COUPON:
      return produce(state, draft => {
        draft.cartCoupon = action.payload.coupon;
      });
    case CartTypes.CART_REMOVE_COUPON:
      return produce(state, draft => {
        draft.cartCoupon = {} as Coupon;
        resetScoreData(draft);
      });
    case CartTypes.CART_APPLY_DISCOUNT:
      return produce(state, draft => {
        draft.discount = Number.parseFloat(action.payload.discount || '0');
        if (action.payload.shouldResetScore) {
          resetScoreData(draft);
        }
      });
    case CartTypes.CART_APPLY_SCORE_DISCOUNT:
      return produce(state, draft => {
        const _discount = Number.parseFloat(action.payload.discount || '0');
        draft.scoreData.discount = _discount >= 0 ? _discount : 0;
        draft.scoreData.points = Number.parseFloat(
          action.payload.points || '0',
        );
        draft.scoreData.rankingSeasonId = action.payload.rankingSeasonId;
        draft.scoreData.status = action.payload.status;
      });
    case CartTypes.CART_UPDATE_AMOUNT:
      return produce(state, draft => {
        draft.cartAmount = applyAmount(draft);
      });
    case CartTypes.CART_CHANGE_OPEN:
      return produce(state, draft => {
        draft.cartOpen = !state.cartOpen;
      });
    case CartTypes.CART_APPLY_FREIGHT:
      return produce(state, draft => {
        const { activeSettings, isFirstTransport } = action.payload;
        draft.freight = action.payload.freight;

        draft.freight = applyFreight(
          draft.freight,
          activeSettings,
          draft.subTotal,
          draft.discount,
          isFirstTransport,
        );

        draft.cartAmount = applyAmount(draft);
      });
    case CartTypes.CART_ADD_SELECTED_TRANSPORT:
      return produce(state, draft => {
        draft.selectedTransport = action.payload.transport;
      });
    default:
      return state;
  }
};

export default cartReducer;
