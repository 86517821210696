import React, { FC, useEffect, useCallback } from 'react';
import { Grid } from '@material-ui/core';
import { useParams, Redirect } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import SubscriberProfilePage from './SubscriberProfilePage';
import SubscriberAddressPage from './SubscriberAddressPage';

import { ParamsProps } from '../types';
import { profileStyles } from './styles';
import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { loadOneSignature } from 'store/ducks/generals/signature/oneSignature/actions';
import { getOneSignatureQuery } from 'utils/queries/signature';
import CircularLoading from 'components/generals/loading/CircularLoading';

const SubscriberProfile: FC = () => {
  const style = profileStyles();
  const dispatch = useDispatch();
  const subscriberId = useParams<ParamsProps>().id;

  const { data: subscriber, isLoading } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  const loadSubsciber = useCallback(() => {
    dispatch(loadOneSignature(subscriberId, getOneSignatureQuery()));
  }, [dispatch, subscriberId]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Assinantes > Detalhe do assinante'));
  }, [dispatch]);

  useEffect(() => {
    loadSubsciber();
  }, [loadSubsciber]);

  return (
    <Grid
      container
      item
      direction="row"
      spacing={2}
      className={style.rootPanel}
    >
      {isLoading ? (
        <CircularLoading isLoading={isLoading} title={'Carregando perfil'} />
      ) : subscriber?.id ? (
        <>
          <Grid item xs={12}>
            <SubscriberProfilePage signature={subscriber} />
          </Grid>
          <Grid item xs={12}>
            <SubscriberAddressPage signature={subscriber} />
          </Grid>
        </>
      ) : (
        <Redirect to="/admin/subscriptions" />
      )}
    </Grid>
  );
};

export default SubscriberProfile;
