import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router';
import {
  CardContent,
  CardMedia,
  Grid,
  Typography,
  Button,
} from '@material-ui/core';

import { StatusTag } from 'components/generals/tag/StatusTag';

import { FormatOptions } from 'types';
import { formatColumnValue } from 'helpers/formatters';

import { useStyles } from '../styles';
import { CardsProps } from '../types';
import MyTypography from 'components/generals/labels/MyTypography';
import TagWrapper, { DEFINED_TAGS } from 'components/generals/tag';
import { PAYMENT_TYPE } from 'types/generals';

const DetailedCard: FC<CardsProps> = ({ cardData }) => {
  const {
    boxTitle,
    imgPath,
    description,
    order,
    renewDate,
    isEnhanced,
  } = cardData;
  const history = useHistory();
  const classes = useStyles();

  const handleRedirectToOrders = () => history.push('/my-account/orders');

  const handleFormatDate = useCallback((date: string) => {
    return formatColumnValue(FormatOptions.SIMPLE_DATE_WITH_TRACE, date);
  }, []);

  const handleGoToBankSlip = (url?: string) => {
    window.open(url, '_blank');
  };
  return (
    <>
      <Grid item xs className={classes.selfCenter}>
        <CardMedia
          className={classes.cardImg}
          image={imgPath}
          component="img"
          alt={`Imagem do box ${boxTitle}`}
          onClick={() => handleRedirectToOrders()}
        />
      </Grid>
      <Grid item xs>
        {/* <Grid>
          {tag ? <TagWrapper config={tag.config} value={tag.value} /> : null}
          <Typography variant="subtitle1" className={classes.boxSubtitle}>
            {`Previsão de entrega: ${handleFormatDate(deliveryDate)}`}
          </Typography>
        </Grid> */}

        {isEnhanced ? (
          <Grid item xs>
            <TagWrapper config={DEFINED_TAGS.INFO} value={'Turbinado'} />
          </Grid>
        ) : null}

        <CardContent className={classes.content}>
          <Grid container direction="column">
            <Grid item className={classes.renewBottom}>
              {order ? <StatusTag status={order.status} /> : null}
              {!!renewDate ? (
                <Typography variant="subtitle1" className={classes.boxSubtitle}>
                  Renovação prevista: {handleFormatDate(renewDate)}
                </Typography>
              ) : null}
            </Grid>
          </Grid>
          <Typography
            variant="h3"
            color="textPrimary"
            className={classes.boldText}
          >
            {boxTitle}
          </Typography>
          <Typography color="textPrimary" className={classes.boxDescription}>
            {description}
          </Typography>
        </CardContent>

        <Grid container justify="space-between">
          <Grid item>
            <Button onClick={() => handleRedirectToOrders()} variant="text">
              <Typography className={classes.actionLabel}>
                Ver detalhes
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            {order && order.paymentType === PAYMENT_TYPE.BANKSLIP ? (
              <Grid item xs>
                <Button
                  variant="contained"
                  className={classes.showBankslipButton}
                  onClick={() => handleGoToBankSlip(order.bankslip)}
                >
                  <MyTypography
                    variant="subtitle1"
                    className={classes.bansklipText}
                  >
                    Ver boleto
                  </MyTypography>
                </Button>
              </Grid>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DetailedCard;
