import React, { FC, MouseEventHandler } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';

import { AppState } from 'store';
import { LayoutState } from 'store/ducks/layout/types';
import { toggleMenu } from 'store/ducks/layout/actions';
import { Sizes } from 'styles/sizes';
import { MyDrawerProps } from './types';

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: Sizes.DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  drawerPaper: {
    width: Sizes.DRAWER_WIDTH,
    border: 'none',
  },
}));

const MyDrawer: FC<MyDrawerProps> = props => {
  const theme = useTheme();
  const classes = useStyles();

  const dispatch = useDispatch();

  const { isMobileMenuOpen } = useSelector<AppState, LayoutState>(
    ({ layout }: AppState) => layout,
  );

  const handleDrawerToggle: MouseEventHandler = () => {
    dispatch(toggleMenu());
  };

  return (
    <nav className={classes.drawer} aria-label="main menu">
      <Hidden smUp implementation="css">
        <Drawer
          variant="temporary"
          anchor={theme.direction === 'rtl' ? 'right' : 'left'}
          open={isMobileMenuOpen}
          onClose={handleDrawerToggle}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true,
          }}
        >
          {props.children}
        </Drawer>
      </Hidden>
      <Hidden xsDown implementation="css">
        <Drawer
          variant="permanent"
          classes={{
            paper: classes.drawerPaper,
          }}
          open
        >
          {props.children}
        </Drawer>
      </Hidden>
    </nav>
  );
};

export default MyDrawer;
