import { CreateQueryParams } from '@nestjsx/crud-request';
import { ROOT_PATH } from 'services/fileservice';
import { action } from 'typesafe-actions';
import { RankingTypes } from './types';

export const loadActiveRankingRequest = (query: CreateQueryParams) => {
  return action(RankingTypes.LOAD_ACTIVE_RANKING_REQUEST, { query });
};
export const loadActiveRankingFailure = () => {
  return action(RankingTypes.LOAD_ACTIVE_RANKING_FAILURE);
};
export const loadActiveRankingSuccess = (data: any) => {
  return action(RankingTypes.LOAD_ACTIVE_RANKING_SUCCESS, { data });
};

export const loadRankingRequest = (query: CreateQueryParams) => {
  return action(RankingTypes.LOAD_RANKING_REQUEST, { query });
};
export const loadRankingFailure = () => {
  return action(RankingTypes.LOAD_RANKING_FAILURE);
};
export const loadRankingSuccess = (data: any) => {
  return action(RankingTypes.LOAD_RANKING_SUCCESS, { data });
};

export const loadUserRankingSeasonRequest = (query: CreateQueryParams) => {
  return action(RankingTypes.LOAD_USER_RANKING_SEASON_REQUEST, {
    query,
  });
};
export const loadUserRankingSeasonFailure = () => {
  return action(RankingTypes.LOAD_USER_RANKING_SEASON_FAILURE);
};
export const loadUserRankingSeasonSuccess = (
  data: any,
  page: number,
  total: number,
) => {
  return action(RankingTypes.LOAD_USER_RANKING_SEASON_SUCCESS, {
    data,
    page,
    total,
  });
};

export const loadRankingFile = (
  query: CreateQueryParams,
  rootPath: ROOT_PATH,
  reportPath: ROOT_PATH,
) => {
  return action(RankingTypes.LOAD_RANKING_FILE_REQUEST, {
    query,
    rootPath,
    reportPath,
  });
};
