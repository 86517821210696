import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

import { AppState } from 'store';
import { loadAllAddresses } from 'store/ducks/generals/address/actions';
import { AddressState } from 'store/ducks/generals/address/types';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import AddressDialog from 'components/generals/dialog/AddressDialog';
import MyTableCell from 'components/generals/table/MyTableCell';
import { AddressDialogProps } from 'components/generals/dialog/AddressDialog/types';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import TagWrapper from 'components/generals/tag';
import { DEFINED_TAGS } from 'components/generals/tag/types';
import { formatColumnValue } from 'helpers/formatters';
import { useFilters } from 'hooks/filters';
import Colors from 'styles/colors';
import { Address, FormatOptions } from 'types';
import { profileStyles } from '../styles';
import { UserAddressProps, UserAddressActionsProps } from '../types';
import { EDIT_ADDRESS_MESSAGE } from 'utils/messages';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import AddressService from 'services/addressService';
import { StatusCodes } from 'http-status-codes';
import {
  REMOVE_ADDRESS_ERROR,
  REMOVE_ADDRESS_MSG,
  REMOVE_ADDRESS_SUCCESS,
} from 'utils/messages/user';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import TableActionsButton from 'components/generals/TableActionsButton';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';

const UserAddressPage: FC<UserAddressProps> = ({ userInfo }) => {
  const dispatch = useDispatch();
  const style = profileStyles();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const { sort, page, handleSort, handleSetValuePage } = useFilters({});

  const { data: addresses, total, isLoading, hasError } = useSelector<
    AppState,
    AddressState
  >(({ address }: AppState) => address);

  const loadUserAddressses = useCallback(() => {
    dispatch(
      loadAllAddresses({
        sort,
        page: page + 1,
        limit: rowsPerPage,
      }),
    );
  }, [dispatch, page, rowsPerPage, sort]);

  useEffect(() => {
    loadUserAddressses();
  }, [loadUserAddressses]);

  const zipCodeFormatted = (zipcode: string) =>
    formatColumnValue(FormatOptions.ZIPCODE, zipcode);

  const addressModal = useCallback(
    (address?: Address) => {
      const modalProps: AddressDialogProps = {
        title: address ? 'Editar endereço' : 'Adicionar endereço',
        message: EDIT_ADDRESS_MESSAGE,
        address,
        user: userInfo,
      };
      dispatch(openModal(AddressDialog, modalProps));
    },
    [dispatch, userInfo],
  );
  // fixme: when PlanCardInfo is deployed implement the other actions
  // change paymentDate and paymentType
  const handleDelete = useCallback(
    async (address: Address) => {
      try {
        const response = await AddressService.deleteAddress(address.id);
        if (response.status === StatusCodes.OK) {
          dispatch(notifySuccess(REMOVE_ADDRESS_SUCCESS));
          loadUserAddressses();
          dispatch(closeModal());
        } else {
          throw new Error(REMOVE_ADDRESS_ERROR);
        }
      } catch (error) {
        dispatch(notifyError(error.message));
      }
    },
    [dispatch, loadUserAddressses],
  );

  const handleConfirmActionDialog = useCallback(
    (address: Address) => {
      const dialogProps: BaseDialogProps = {
        title: 'Excluir endereço',
        message: REMOVE_ADDRESS_MSG,
        actionFn: () => handleDelete(address),
      };

      dispatch(openModal(ConfirmationDialog, dialogProps));
    },
    [dispatch, handleDelete],
  );

  const Actions = ({ address }: UserAddressActionsProps) => (
    <TableActionsButton
      menuItems={[
        {
          title: 'Editar endereço',
          action: () => addressModal(address),
        },
        {
          disabled: address.isDefault,
          title: 'Remover',
          action: () => handleConfirmActionDialog(address),
        },
      ]}
    />
  );

  return (
    <Paper className={style.rootPaper} elevation={0}>
      <Grid container direction="row" alignItems="center">
        <Grid item xs>
          <Typography
            variant="h1"
            color="textPrimary"
            style={{ fontWeight: 'normal' }}
          >
            Meus <strong>endereços</strong>
          </Typography>
        </Grid>
        <Grid container item xs justify="flex-end">
          <Button onClick={() => addressModal()}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                color: Colors.RED,
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              Adicionar endereço
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2} className={style.mTop}>
        <TableContainer>
          <Table aria-label="user addresses">
            <TableHead>
              <TableRow>
                <MySortableTableCell
                  onClick={() => handleSort('street')}
                  direction={sort.field === 'street' && sort.order}
                >
                  Rua
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('number')}
                  direction={sort.field === 'number' && sort.order}
                >
                  Número
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('zipcode')}
                  direction={sort.field === 'zipcode' && sort.order}
                >
                  CEP
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('neighborhood')}
                  direction={sort.field === 'neighborhood' && sort.order}
                >
                  Bairro
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('city')}
                  direction={sort.field === 'city' && sort.order}
                >
                  Cidade
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('state')}
                  direction={sort.field === 'state' && sort.order}
                >
                  Estado
                </MySortableTableCell>
                <MyTableCell className={style.textCenter}>Ações</MyTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!isLoading && !hasError
                ? addresses.map(address => (
                    <TableRow key={address.id}>
                      <MyTableCell>
                        {address.street}
                        {address.isDefault ? (
                          <Grid item xs>
                            <TagWrapper
                              config={DEFINED_TAGS.STATUS}
                              value={'Padrão'}
                            />
                          </Grid>
                        ) : null}
                      </MyTableCell>
                      <MyTableCell>{address.number}</MyTableCell>
                      <MyTableCell>
                        {zipCodeFormatted(address.zipcode)}
                      </MyTableCell>
                      <MyTableCell>{address.neighborhood}</MyTableCell>
                      <MyTableCell>{address.city}</MyTableCell>
                      <MyTableCell>{address.state}</MyTableCell>
                      <MyTableCell>
                        <Actions address={address} />
                      </MyTableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
        <Grid container item xs justify="flex-end">
          <TablePagination
            ActionsComponent={MyTablePaginationActions}
            component="div"
            count={total}
            page={page}
            labelRowsPerPage="Itens por página"
            onChangePage={(_event: unknown, newPage: number) =>
              handleSetValuePage(newPage)
            }
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
              handleSetValuePage(0);
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
            rowsPerPageOptions={ROWS_PER_PAGE}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserAddressPage;
