import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  thumb: {
    display: 'block',
    height: '100px',
    width: '100px',
    objectFit: 'cover',
    borderRadius: 16,
    border: 'none',
  },
});
