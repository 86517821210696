import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { QuerySort } from '@nestjsx/crud-request';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';

import { actUpdatePageTitle, openModal } from 'store/ducks/nav/actions';

import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MyTableCell from 'components/generals/table/MyTableCell';

import { useStyles } from './styles';
import { AppState } from 'store';
import { format, parseISO } from 'date-fns';
import { loadCouponFile, loadCouponRequest } from 'store/ducks/coupons/actions';
import { CouponsState } from 'store/ducks/coupons/types';
import CouponsDialog from 'components/generals/dialog/CouponDialog';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { FileState } from 'store/ducks/generals/file/types';
import { ROOT_PATH } from 'services/fileservice';

const Coupons: FC = () => {
  const [sort, setSort] = useState<QuerySort>({
    field: 'id',
    order: 'DESC',
  });
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const classes = useStyles();

  const { data: coupons = [], total = 0, isLoading, hasError } = useSelector<
    AppState,
    CouponsState
  >(({ couponReducer }: AppState) => couponReducer);

  const { hasError: hasErrorFile } = useSelector<AppState, FileState>(
    ({ file }: AppState) => file,
  );

  const loadAllCoupons = useCallback(() => {
    dispatch(
      loadCouponRequest({
        page: page + 1,
        limit: rowsPerPage,
        sort,
      }),
    );
  }, [dispatch, sort, page, rowsPerPage]);

  const loadCouponsXls = useCallback(() => {
    dispatch(
      loadCouponFile(
        {
          page: page + 1,
          limit: rowsPerPage,
          sort,
        },
        ROOT_PATH.COUPONS,
      ),
    );
  }, [dispatch, sort, page, rowsPerPage]);

  const formatDate = useCallback(data => {
    return format(parseISO(data), 'dd/MM/yyyy');
  }, []);

  // Effects
  useEffect(() => {
    dispatch(actUpdatePageTitle('Cupom'));
  }, [dispatch]);

  useEffect(() => {
    loadAllCoupons();
  }, [loadAllCoupons]);

  // Handlers
  const handleSort = (field: string) => {
    let order: 'ASC' | 'DESC' = 'DESC';
    if (field === sort.field) {
      order = sort.order === 'ASC' ? 'DESC' : 'ASC';
    }
    setSort({
      field,
      order,
    });
  };

  const handleCallDialog = (title: string) => {
    try {
      dispatch(openModal(CouponsDialog, { title }));
    } catch (err) {
      throw new Error(err);
    }
  };

  const SearchFilter = () => <MyInputFilter placeholder={'Buscar'} />;

  const CouponsList = () => (
    <Paper className={classes.ordersList} elevation={0}>
      <TableContainer>
        <Table className={classes.table} aria-label="orders table">
          <TableHead>
            <TableRow>
              <MySortableTableCell
                onClick={() => handleSort('name')}
                direction={sort.field === 'name' && sort.order}
              >
                NOME DO CUPOM
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('limit')}
                direction={sort.field === 'limit' && sort.order}
              >
                QUANTIDADE
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('start_date')}
                direction={sort.field === 'start_date' && sort.order}
              >
                INÍCIO
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('end_date')}
                direction={sort.field === 'end_date' && sort.order}
              >
                TÉRMINO
              </MySortableTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!isLoading &&
              !hasError &&
              coupons.map(coupon => (
                <TableRow key={coupon.id}>
                  <MyTableCell>{coupon.name || '-'}</MyTableCell>
                  <MyTableCell>{coupon.limit}</MyTableCell>
                  <MyTableCell>
                    {formatDate(coupon.start_date) || '-'}
                  </MyTableCell>
                  <MyTableCell>
                    {formatDate(coupon.end_date) || '-'}
                  </MyTableCell>
                </TableRow>
              ))) ||
              null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  return (
    <Grid container direction="column">
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={3}
        style={{ marginTop: 20, marginBottom: 10 }}
      >
        <Grid item xs={3} sm={3} md={3}>
          <SearchFilter />
        </Grid>
        <Grid container item xs alignItems="center" justify="flex-end">
          <Grid item xs={2} xl={2}>
            <ActionsButton
              hasErrorFile={hasErrorFile}
              menuItems={[
                {
                  title: 'Adicionar',
                  action: () => handleCallDialog('Adicionar cupom'),
                },
                {
                  title: 'Exportar planilha de cupons',
                  action: loadCouponsXls,
                },
              ]}
              buttonText="Ações"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <CouponsList />
      </Grid>
      <TablePagination
        ActionsComponent={MyTablePaginationActions}
        component="div"
        count={total}
        page={page}
        labelRowsPerPage="Itens por página"
        onChangePage={(_event: unknown, newPage: number) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
          setPage(0);
          setRowsPerPage(parseInt(event.target.value, 10));
        }}
        rowsPerPageOptions={ROWS_PER_PAGE}
      />
    </Grid>
  );
};

export default Coupons;
