import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  badge: {
    color: Colors.SALMON,
  },
  paperTable: {
    padding: theme.spacing(1, 2),
    borderRadius: 8,
    backgroundColor: 'transparent',
  },
  shopIcon: {
    height: 32,
    width: 32,
  },
  cartText: {
    fontWeight: 600,
    color: Colors.DARKER_GRAY,
  },
});
