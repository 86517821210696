import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { closeModal } from 'store/ducks/nav/actions';

import { useDialogStyles } from '../useStyles';
import { Title, IconWrapper, Message, Wrapper, Icon } from './styles';

import { FeedbackDialogPropsBase } from '../dialogTypes';

const FeedbackDialog: React.FC<FeedbackDialogPropsBase> = ({
  title,
  message,
  icon,
  disableCloseButton,
  buttonActions,
}) => {
  const dispatch = useDispatch();
  const classes = useDialogStyles();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Wrapper
      container
      direction="column"
      style={{ padding: isMobile ? 0 : '20px' }}
    >
      {!disableCloseButton && (
        <IconWrapper container justify="flex-end">
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </IconWrapper>
      )}
      <Grid container direction="column" justify="center" alignItems="center">
        {icon && <Icon component={icon} />}
        <Title variant="h1" color="textPrimary">
          {title}
        </Title>
        <Grid item xs={12} sm={12} md={12}>
          <Message
            variant="h3"
            color="textPrimary"
            className={classes.feedback_mb}
          >
            {message}
          </Message>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default FeedbackDialog;
