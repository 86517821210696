import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    flexGrow: 1,
    borderRadius: '16px',
    alignItems: 'flex-end',
    justifyContent: 'flex-start',
    position: 'relative',
  },
  autoPlay: {
    borderRadius: '16px',
  },
  storeAutoPlay: {
    borderRadius: '5px',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    height: 50,
    paddingLeft: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  img: {
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    objectFit: 'cover',
    cursor: 'pointer',
  },
  imgMobile: {
    height: '118px',
    display: 'block',
    width: '100%',
    overflow: 'hidden',
    objectFit: 'contain',
    cursor: 'pointer',
  },
  sliderStepper: {
    backgroundColor: 'transparent',
    position: 'absolute',
    bottom: '1px',
  },
}));
