import React from 'react';
import { Paper, Grid, Typography } from '@material-ui/core';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { ReactComponent as LinkIcon } from 'assets/img/svg/link_banners.svg';
import { ReactComponent as EyesIcon } from 'assets/img/svg/eye.svg';
import { ReactComponent as CloseIcon } from 'assets/img/svg/close.svg';
import { ReactComponent as DragIcon } from 'assets/img/svg/icon_drag.svg';

import { StyledTypography, useStyles } from './styles';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/ducks/nav/actions';
import { BannerType } from 'types/generals/banner';
import {
  ImagePreviewDialogProps,
  SimpleInputDialogProps,
} from 'components/generals/dialog/dialogTypes';
import ImagePreviewDialog from 'components/generals/dialog/ImagePreviewDialog';
import { IStoreBanner } from 'types/client';
import { IBannerDataListProps, IStoreBannerProps } from './types';
import SimpleInputDialog from 'components/generals/dialog/SimpleInputDialog';
import BannerImagesZone from 'pages/admin/manager/banners/bannerImagesZone';

const BannerUploader: React.FC<IStoreBannerProps> = ({
  title,
  banner,
  bannerData,
  handleUpdateBanner,
  handleUpdateBannerData,
  handleUpdateBannerOrder,
  errors,
  register,
  setValue,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const onDragEnd = (result: any) => {
    if (!result.destination) return;
    const items = bannerData;
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    handleUpdateBannerOrder(items);
  };

  const onChangeLink = (text: string, file: any) => {
    const newBanners = bannerData.map(b => {
      if (b.id === file.id) {
        return { ...b, redirectLink: text };
      }
      return b;
    });
    handleUpdateBannerData(newBanners, true);
  };

  const handlePreview = (file: IStoreBanner) => {
    const modalProps: ImagePreviewDialogProps = {
      title: 'Imagem',
      imagePath: file.imageLink || file.link || '',
      cancelText: 'Ok',
    };
    dispatch(openModal(ImagePreviewDialog, modalProps));
  };

  const handleLink = (file: IStoreBanner, index: string) => {
    const value = file.redirectLink || '';
    const modalProps: SimpleInputDialogProps = {
      title: 'Adicionar link à imagem',
      confirmText: 'Adicionar',
      handleAction: (e: any) => onChangeLink(e, file),
      initialValue: value,
      inputText: 'Link de acesso',
      id: file.id || index,
    };
    dispatch(openModal(SimpleInputDialog, modalProps));
  };

  const handleRemoveFromList = (file: IStoreBanner) => {
    if (file?.id) {
      const bannerIndex = banner.findIndex(b => b.name === file.id);
      if (bannerIndex >= 0) {
        const _banner = banner;
        _banner.splice(bannerIndex, 1);
        handleUpdateBanner(_banner, true);
      }
      const bannerDataIndex = bannerData.findIndex(b => b.id === file.id);
      if (!isNaN(bannerDataIndex)) {
        const _bannerData = bannerData;
        _bannerData.splice(bannerDataIndex, 1);
        handleUpdateBannerData(_bannerData, true);
      }
    }
  };

  const BannerDataList = (props: IBannerDataListProps) => {
    return props?.banner?.length ? (
      <>
        <Grid item xs={12}>
          <StyledTypography variant="h3">Ordem das imagens</StyledTypography>
        </Grid>

        <Grid container justify="space-between" className={styles.gridHeader}>
          <Grid item xs={10}>
            <StyledTypography variant="h4">Nome</StyledTypography>
          </Grid>
          <Grid item xs={2}>
            <StyledTypography variant="h4">Ações</StyledTypography>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="bannerTop">
              {provided => (
                <Grid
                  container
                  direction="column"
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                >
                  {props.banner?.map((data, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {provided => (
                        <Grid container>
                          <Grid
                            className={styles.draggableItem}
                            item
                            xs={12}
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <Grid
                              container
                              direction="row"
                              justify="space-between"
                              alignItems="center"
                            >
                              <Grid item xs={10}>
                                <Grid container alignItems="center">
                                  <Grid item xs={1}>
                                    <DragIcon />
                                  </Grid>
                                  <Grid item xs={9}>
                                    <Typography variant="h4">
                                      {data.name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item xs={2}>
                                <EyesIcon onClick={() => handlePreview(data)} />
                                <LinkIcon
                                  onClick={() => handleLink(data, `${index}`)}
                                />
                                <CloseIcon
                                  onClick={() => handleRemoveFromList(data)}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Grid>
              )}
            </Droppable>
          </DragDropContext>
        </Grid>
      </>
    ) : (
      <></>
    );
  };
  return (
    <Grid container direction="column" xs>
      <Typography className={styles.bannerTitle} variant="h2">
        {title}
      </Typography>
      <Paper elevation={0} className={styles.rootPaper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BannerImagesZone
              bannerFiles={banner}
              bannerData={bannerData}
              bannerType={BannerType.PRIMARY}
              errors={errors}
              register={register}
              setValue={setValue}
              handleUpdateBanner={handleUpdateBanner}
              handleUpdateBannerData={handleUpdateBannerData}
              multiple={true}
            />
          </Grid>
          <BannerDataList banner={bannerData} />
        </Grid>
      </Paper>
    </Grid>
  );
};

export default BannerUploader;
