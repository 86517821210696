import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import { MyDropdownProps } from '../dropdown/types';
import Colors from 'styles/colors';

const useStyles = makeStyles({
  list: {
    backgroundColor: Colors.WHITE,
  },
});

const ITEM_HEIGHT = 35.5;

const MySelectFilter: FC<MyDropdownProps> = ({
  children,
  menuItems,
  buttonProps = {},
  expandMoreIconStyle = null,
  buttonStyle = null,
  maxItems = 5,
  defaultOption,
}) => {
  const classes = useStyles();
  return (
    <MyDropdown
      expandMoreIconStyle={expandMoreIconStyle}
      buttonStyle={buttonStyle}
      buttonProps={{
        style: {
          fontSize: '1rem',
          paddingTop: '6px',
          paddingBottom: '6px',
          paddingLeft: '1.5em',
          paddingRight: '1.5em',
          backgroundColor: Colors.WHITE,
          borderRadius: 13,
          width: '100%',
          borderWidth: 1,
          borderStyle: 'solid',
          borderColor: Colors.WHITE,
          ...(buttonProps?.style || {}),
        },
      }}
      menuProps={{
        classes: {
          list: classes.list,
        },
        PaperProps: {
          style: {
            marginTop: '1rem',
            borderRadius: 13,
            maxHeight: ITEM_HEIGHT * maxItems,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'left',
        },
      }}
      menuItems={menuItems}
      defaultOption={defaultOption}
    >
      {children}
    </MyDropdown>
  );
};

export default MySelectFilter;
