import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Hidden } from '@material-ui/core';

import { ReactComponent as Logo } from 'assets/img/svg/logo_mbc_text.svg';

import { LoginForm } from 'components/generals/forms/LoginForm';
import { useLoginStyles } from './styles';
import { Storage } from 'services/storage';
import { notifyError } from 'store/ducks/notification/actions';

const Login: React.FC = () => {
  const classes = useLoginStyles();
  const dispatch = useDispatch();
  const errorMessage = Storage.get('errorMessage');

  useEffect(() => {
    if (errorMessage) {
      dispatch(notifyError(errorMessage));
      Storage.remove('errorMessage');
    }
  }, [dispatch, errorMessage]);

  return (
    <Grid classes={{ root: classes.root }} container justify="space-between">
      <Hidden xsDown>
        <Grid item sm={1} />
        <Grid item container sm={4} alignItems="center">
          <Logo className={classes.logo} />
        </Grid>
        <Grid item sm={2} />
      </Hidden>
      <Grid item container sm={4} xs={12} alignItems="center">
        <LoginForm />
      </Grid>
      <Grid item sm={1} />
    </Grid>
  );
};

export default Login;
