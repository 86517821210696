import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Grid, Button, CircularProgress } from '@material-ui/core';
import { format } from 'date-fns';
import { first } from 'lodash';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';

import { defaultSettings, validationSchema } from './utils';
import { useStyles } from './styles';
import { ImageZone } from 'components/admin/ImagesZone/types';
import { useForm } from 'react-hook-form';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { AppState } from 'store';
import {
  endStoreSettingsRequest,
  initStoreSettingstRequest,
  loadStoreSettings,
} from 'store/ducks/store/storeSettings/actions';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import {
  IStoreBanner,
  PromotionPeriods,
  StoreSettings,
  StoreSettingsToSave,
  StoreTags,
} from 'types/client';
import BannerUploader from 'components/generals/storeBannerUpload';
import StoreSettingsService from 'services/storeSettingsService';
import {
  SAVE_STORE_SETTINGS_ERROR,
  SAVE_STORE_SETTINGS_SUCCESS,
  STORE_SETTINGS_DESKPTOP_BANNER_TITLE,
  STORE_SETTINGS_MOBILE_BANNER_TITLE,
  STORE_SETTINGS_TITLE,
  STORE_SETTINGS_SEASON_BANNER_TITLE,
} from 'utils/messages/freight';
import StoreSettingsFormPeriods from './StoreSettingsFormPeriods';
import StoreSettingsFormShipping from './StoreSettingsFormShipping';
import { mountDateWithHour } from 'helpers/validateDate';
import StoreSettingsFormRankingSeason from './StoreSettingsFormRankingSeason';

const StoreSettingsPage: React.FC = () => {
  const { settings = [], isLoading: isLoadingRequest } = useSelector<
    AppState,
    StoreSettingsState
  >(({ storeSettings }: AppState) => storeSettings);

  const dispatch = useDispatch();
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [storeSettings, setStoreSettings] = useState<StoreSettings>(
    defaultSettings,
  );
  const [bannerDesktop, setBannerDesktop] = useState<ImageZone[]>([]);
  const [bannerDesktopData, setBannerDesktopData] = useState<IStoreBanner[]>(
    [],
  );
  const [bannerMobile, setBannerMobile] = useState<ImageZone[]>([]);
  const [bannerMobileData, setBannerMobileData] = useState<IStoreBanner[]>([]);
  const [bannerSeason, setBannerSeason] = useState<ImageZone[]>([]);
  const [bannerSeasonData, setBannerSeasonData] = useState<IStoreBanner[]>([]);

  const {
    control,
    register,
    errors,
    setValue,
    getValues,
    watch,
    handleSubmit,
  } = useForm({
    validationSchema,
  });

  const loadActiveStoreSettingsCB = useCallback(() => {
    dispatch(loadStoreSettings());
  }, [dispatch]);

  const formatDate = (date: string) => new Date(`${date} 00:00:00`);

  const initFormValues = useCallback(
    (storeSettings: StoreSettings) => {
      const promotionPeriods = first(storeSettings.promotionPeriods);

      if (promotionPeriods?.endDate) {
        setValue('endDate', formatDate(promotionPeriods.endDate));
      }

      if (promotionPeriods?.startDate) {
        setValue('startDate', formatDate(promotionPeriods.startDate));
      }

      if (promotionPeriods?.reason) {
        setValue('reason', promotionPeriods.reason);
      }

      setValue('isActive', storeSettings.isActive);
      setValue('isShippingActive', storeSettings.freightConfig.isActive);
      setValue(
        'freeFreightFromThisValue',
        storeSettings.freightConfig.freeFreightFromThisValue || 0,
      );
      setValue('scoreValue', storeSettings.rankingSeasonConfig.scoreValue);
      setValue(
        'showReferralMenu',
        storeSettings.rankingSeasonConfig.showReferralMenu,
      );
      setIsLoading(false);
    },
    [setValue],
  );

  const normalizeSettings = useCallback(
    (storeSettings: StoreSettings | undefined) => {
      if (storeSettings) {
        setStoreSettings(storeSettings);
        const { banners, mobileBanners, seasonBanners } = storeSettings;
        setBannerDesktopData(banners);
        setBannerMobileData(mobileBanners);
        setBannerSeasonData(seasonBanners);
        initFormValues(storeSettings);
      } else {
        initFormValues(defaultSettings);
      }
    },
    [initFormValues],
  );

  useEffect(() => {
    dispatch(actUpdatePageTitle(STORE_SETTINGS_TITLE));
    loadActiveStoreSettingsCB();
  }, [dispatch, loadActiveStoreSettingsCB]);

  useEffect(() => {
    if (settings && !isLoadingRequest) {
      normalizeSettings(settings[0]);
    }
  }, [isLoadingRequest, normalizeSettings, settings]);

  const handleUpdateBannerDesktop = (
    items: ImageZone[],
    isOverriding: boolean,
  ) => {
    if (isOverriding) {
      setBannerDesktop(() => [...items]);
    } else {
      setBannerDesktop(data => [...data, ...items]);
    }
  };
  const handleUpdateBannerDesktopData = (
    items: IStoreBanner[],
    isOverriding: boolean,
  ) => {
    if (isOverriding) {
      setBannerDesktopData(() => [...items]);
    } else {
      setBannerDesktopData(data => [...data, ...items]);
    }
  };
  const handleUpdateBannerDesktopOrder = (items: IStoreBanner[]) => {
    setBannerDesktopData(items);
  };

  const handleUpdateBannerMobileOrder = (items: IStoreBanner[]) => {
    setBannerMobileData(items);
  };
  const handleUpdateBannerMobile = (items: ImageZone[]) => {
    setBannerMobile(() => [...items]);
  };
  const handleUpdateBannerMobileData = (items: IStoreBanner[]) => {
    setBannerMobileData(() => [...items]);
  };

  const handleUpdateBannerSeasonOrder = (items: IStoreBanner[]) => {
    setBannerSeasonData(items);
  };
  const handleUpdateBannerSeason = (items: ImageZone[]) => {
    setBannerSeason(() => [...items]);
  };
  const handleUpdateBannerSeasonData = (items: IStoreBanner[]) => {
    setBannerSeasonData(() => [...items]);
  };

  const handleFormatDateHour = (hour: string) => {
    const _date = mountDateWithHour(new Date(), hour);
    return format(_date, 'kk:mm');
  };

  const saveSettings = async (data: any) => {
    try {
      const period: PromotionPeriods = {
        ...storeSettings.promotionPeriods[0],
        startHourDate: handleFormatDateHour(getValues('startHourDate')),
        endHourDate: handleFormatDateHour(getValues('endHourDate')),
        startDate: getValues('startDate'),
        endDate: getValues('endDate'),
        reason: getValues('reason'),
      };
      const freightConfig = {
        isActive: getValues('isShippingActive'),
        freeFreightFromThisValue: getValues('freeFreightFromThisValue'),
      };
      const rankingSeasonConfig = {
        scoreValue: getValues('scoreValue'),
        showReferralMenu: getValues('showReferralMenu'),
      };
      const isActive = getValues('isActive');
      const payload: StoreSettingsToSave = {
        ...storeSettings,
        bannerDesktop,
        bannerMobile,
        bannerSeason,
        bannersData: {
          banners: bannerDesktopData,
          mobileBanners: bannerMobileData,
          seasonBanners: bannerSeasonData,
        },
        promotionPeriods: [period],
        freightConfig,
        rankingSeasonConfig,
        isActive,
        // fixme temporario, reavaliar
        tags: isActive ? [StoreTags.isPromotionalPeriod] : [],
      };
      dispatch(initStoreSettingstRequest());
      await StoreSettingsService.saveSettings(payload);
    } catch (error) {
      throw error;
    }
  };
  const onSubmit = async (data: any) => {
    try {
      await saveSettings(data);
      dispatch(endStoreSettingsRequest());
      loadActiveStoreSettingsCB();
      dispatch(notifySuccess(SAVE_STORE_SETTINGS_SUCCESS));
    } catch (error) {
      dispatch(notifyError(SAVE_STORE_SETTINGS_ERROR));
    }
  };

  return (
    <Grid container direction="column">
      <Grid item xs>
        <BannerUploader
          title={STORE_SETTINGS_DESKPTOP_BANNER_TITLE}
          banner={bannerDesktop}
          bannerData={bannerDesktopData}
          errors={errors}
          register={register}
          setValue={setValue}
          handleUpdateBanner={handleUpdateBannerDesktop}
          handleUpdateBannerData={handleUpdateBannerDesktopData}
          handleUpdateBannerOrder={handleUpdateBannerDesktopOrder}
        />
      </Grid>
      <Grid item xs>
        <BannerUploader
          title={STORE_SETTINGS_MOBILE_BANNER_TITLE}
          banner={bannerMobile}
          bannerData={bannerMobileData}
          errors={errors}
          register={register}
          setValue={setValue}
          handleUpdateBanner={handleUpdateBannerMobile}
          handleUpdateBannerData={handleUpdateBannerMobileData}
          handleUpdateBannerOrder={handleUpdateBannerMobileOrder}
        />
      </Grid>

      {!isLoading && (
        <>
          <StoreSettingsFormPeriods
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            getValues={getValues}
            storeSettings={storeSettings}
            setValue={setValue}
          />

          <StoreSettingsFormShipping
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            getValues={getValues}
            storeSettings={storeSettings}
            setValue={setValue}
          />

          <StoreSettingsFormRankingSeason
            control={control}
            register={register}
            errors={errors}
            watch={watch}
            getValues={getValues}
            storeSettings={storeSettings}
            setValue={setValue}
          />
        </>
      )}

      <Grid item xs>
        <BannerUploader
          title={STORE_SETTINGS_SEASON_BANNER_TITLE}
          banner={bannerSeason}
          bannerData={bannerSeasonData}
          errors={errors}
          register={register}
          setValue={setValue}
          handleUpdateBanner={handleUpdateBannerSeason}
          handleUpdateBannerData={handleUpdateBannerSeasonData}
          handleUpdateBannerOrder={handleUpdateBannerSeasonOrder}
        />
      </Grid>
      <Grid
        container
        direction="row"
        justify="flex-end"
        spacing={2}
        className={styles.footerGridContainer}
      >
        <Grid item xs>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={clsx(styles.buttonLabel, styles.buttonWrapper)}
            disabled={isLoadingRequest}
            onClick={handleSubmit(onSubmit)}
          >
            {isLoadingRequest ? <CircularProgress size={20} /> : 'Salvar'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreSettingsPage;
