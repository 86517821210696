import { Address, FormatOptions } from 'types';
import { formatColumnValue } from './formatters';

export const formatAddress = (address: Address) => {
  return `
  ${address.street}, ${address.number} - ${address.neighborhood}
  ${address.city} - ${address.state} - CEP ${formatColumnValue(
    FormatOptions.ZIPCODE,
    address.zipcode,
  )}
  `;
};

export const reducedFormatAddress = (address: Address) => {
  return `
  ${address.neighborhood}, ${address.city} - ${
    address.state
  } - ${formatColumnValue(FormatOptions.ZIPCODE, address.zipcode)}
  `;
};

export const brStates = [
  { value: 'AC', alias: 'AC', name: 'Acre' },
  { value: 'AL', alias: 'AL', name: 'Alagoas' },
  { value: 'AP', alias: 'AP', name: 'Amapá' },
  { value: 'AM', alias: 'AM', name: 'Amazonas' },
  { value: 'BA', alias: 'BA', name: 'Bahia' },
  { value: 'CE', alias: 'CE', name: 'Ceará' },
  { value: 'DF', alias: 'DF', name: 'Distrito Federal' },
  { value: 'ES', alias: 'ES', name: 'Espírito Santo' },
  { value: 'GO', alias: 'GO', name: 'Goías' },
  { value: 'MA', alias: 'MA', name: 'Maranhão' },
  { value: 'MT', alias: 'MT', name: 'Mato Grosso' },
  { value: 'MS', alias: 'MS', name: 'Mato Grosso do Sul' },
  { value: 'MG', alias: 'MG', name: 'Minas Gerais' },
  { value: 'PA', alias: 'PA', name: 'Pará' },
  { value: 'PB', alias: 'PB', name: 'Paraíba' },
  { value: 'PR', alias: 'PR', name: 'Paraná' },
  { value: 'PE', alias: 'PE', name: 'Pernambuco' },
  { value: 'PI', alias: 'PI', name: 'Piauí' },
  { value: 'RJ', alias: 'RJ', name: 'Rio de Janeiro' },
  { value: 'RN', alias: 'RN', name: 'Rio Grande do Norte' },
  { value: 'RS', alias: 'RS', name: 'Rio Grande do Sul' },
  { value: 'RO', alias: 'RO', name: 'Rondônia' },
  { value: 'RR', alias: 'RR', name: 'Roraíma' },
  { value: 'SC', alias: 'SC', name: 'Santa Catarina' },
  { value: 'SP', alias: 'SP', name: 'São Paulo' },
  { value: 'SE', alias: 'SE', name: 'Sergipe' },
  { value: 'TO', alias: 'TO', name: 'Tocantins' },
];
