import React, { FC } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button, Grid, Paper, Badge } from '@material-ui/core';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';

import ShopCartDetail from './ShopCartDetail';
import { AppState } from 'store';
import { CartState } from 'store/ducks/cart/types';
import { useStyles } from './styles';
import { handleChangeCartOpen } from 'store/ducks/cart/actions';

const ShopCart: FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { cartLength, cartOpen } = useSelector<AppState, CartState>(
    ({ cartReducer }: AppState) => cartReducer,
  );

  const openCart = () => {
    dispatch(handleChangeCartOpen());
  };

  return (
    <>
      <Button onClick={() => openCart()}>
        <Paper elevation={0} className={styles.paperTable}>
          <Grid
            container
            item
            justify="space-between"
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ flexFlow: 'nowrap' }}
          >
            <Grid item xs>
              <Badge
                badgeContent={cartLength}
                color="default"
                className={styles.badge}
              >
                <ShoppingCartIcon color="primary" className={styles.shopIcon} />
              </Badge>
            </Grid>
          </Grid>
        </Paper>
      </Button>
      <ShopCartDetail
        opened={cartOpen}
        setOpened={() => dispatch(handleChangeCartOpen())}
      />
    </>
  );
};

export default ShopCart;
