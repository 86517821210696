import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from 'components/generals/forms/register/styles';

import { AppState } from 'store';
import { closeModal } from 'store/ducks/nav/actions';
import { NotificationState } from 'store/ducks/notification/types';

import { useDialogStyles } from './useStyles';
import { ConfirmDialogProps } from './dialogTypes';

const ConfirmationDialog: React.FC<ConfirmDialogProps> = props => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const classes = useDialogStyles();
  const { actionFn, title, message, cancelText, confirmText } = props;
  const [isLoading, setIsLoading] = useState(props.isLoading);

  const { buttonIsDisabled } = useSelector<AppState, NotificationState>(
    ({ notification }: AppState) => notification,
  );

  const handleClick = () => {
    if (actionFn) {
      actionFn();
      setIsLoading(true);
    } else {
      dispatch(closeModal());
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ marginTop: 8 }} spacing={2} component="form">
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h3"
              color="textPrimary"
              className={classes.info_mb}
            >
              {message}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={styles.buttonLabel}
          style={{ marginRight: 30 }}
          onClick={() => dispatch(closeModal())}
        >
          {cancelText ?? 'Cancelar'}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
          onClick={handleClick}
          disabled={buttonIsDisabled || isLoading}
        >
          {isLoading ? (
            <>
              <CircularProgress className={styles.progress} size={15} />
            </>
          ) : (
            confirmText ?? 'Confirmar'
          )}
        </Button>
      </DialogActions>
    </Grid>
  );
};

export default ConfirmationDialog;
