import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  actionLabel: {
    alignSelf: 'center',
    color: Colors.RED,
    cursor: 'pointer',
    fontWeight: 600,
    fontSize: '15px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  boxItem: {
    padding: '0 12px',
    [theme.breakpoints.down(401)]: {
      margin: theme.spacing(0, 0, 3, 0),
    },
  },
  cardTitle: {
    color: Colors.DARKER_GRAY,
    fontSize: '2em',
    fontWeight: 'bold',
    marginBlockStart: '0.67em',
    marginBlockEnd: '0.67em',
  },
  cardWrapper: {
    textAlign: 'center',
  },
  disabledText: {
    textAlign: 'center',
    fontWeight: 600,
    color: Colors.MEDIUM_GRAY,
  },
  iconAligned: {
    marginLeft: '6px',
    position: 'relative',
    top: '2px',
  },
  paperTable: {
    padding: theme.spacing(3, 1.5),
    borderRadius: '2em',
  },
}));
