import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Collapse, useMediaQuery, useTheme } from '@material-ui/core';
import { ExpandLess, ExpandMore } from '@material-ui/icons';

import ActiveSign from './ActiveSign';

import {
  useStyles,
  List,
  Icon,
  ItemText,
  ListItem,
  IconWrapper,
  Wrapper,
} from './styles';

import { handleAction } from './utils';

import { ListItemProps } from './types';
import { Item } from '../types';

const ListItemComponent: React.FC<ListItemProps> = ({
  item,
  handleCloseMenu,
}) => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const [isCurrentLocation, setIsCurrentLocation] = useState(false);
  const [openSublist, setOpenSublist] = useState<string | boolean>(false);

  useEffect(() => {
    setIsCurrentLocation(
      location.pathname === item.route ||
        (!!item.group && location.pathname.includes(item.group)),
    );
  }, [location.pathname, item.route, item.group]);

  const handleClick = (item: Item) => {
    if (item.route) {
      handleCloseMenu && handleCloseMenu();
      history.push(item.route);
    }
    item.action && handleAction(dispatch)(item.action);
    item.subItems &&
      setOpenSublist(item.name === openSublist ? false : item.name);
  };

  const iconUrl =
    item.icon && require(`assets/img/svg/menu-icons/${item.icon}.svg`);

  const iconUrlActive =
    item.icon && require(`assets/img/svg/menu-icons/${item.icon}_active.svg`);

  return (
    <Wrapper key={item.id}>
      <ListItem onClick={() => handleClick(item)}>
        <IconWrapper style={{ width: isMobile ? '4.7em' : '6.5em' }}>
          <ActiveSign isCurrentLocation={isCurrentLocation} />
          {item.icon && (
            <Icon
              alt={`Ícone para o menu ${item.name}`}
              src={isCurrentLocation ? iconUrlActive : iconUrl}
            />
          )}
        </IconWrapper>
        <ItemText
          style={{
            width: isMobile ? '40%' : '100%',
          }}
        >
          {item.name}
        </ItemText>
        {item.subItems &&
          (openSublist === item.name ? (
            <ExpandLess classes={{ root: classes.expandIcon }} />
          ) : (
            <ExpandMore classes={{ root: classes.expandIcon }} />
          ))}
      </ListItem>
      {item.subItems && (
        <Wrapper>
          <Collapse in={openSublist === item.name} timeout="auto" unmountOnExit>
            <List
              style={{
                padding: isMobile ? '0 0 0 4.7em' : '0 0 0 6.5em',
              }}
            >
              {item.subItems
                .filter(sub => !sub.isDisabled)
                .map((subItem: Item) => (
                  <ListItem
                    key={subItem.id}
                    onClick={() => handleClick(subItem)}
                  >
                    <ItemText
                      style={{
                        width: isMobile ? '40%' : '100%',
                      }}
                    >
                      {subItem.name}
                    </ItemText>
                  </ListItem>
                ))}
            </List>
          </Collapse>
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default ListItemComponent;
