import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  Box,
  Button,
  Divider,
  Hidden,
  CircularProgress,
} from '@material-ui/core';
import * as Yup from 'yup';

import { AuthState } from 'store/ducks/auth/types';
import { AppState } from 'store';
import { loginRequest } from 'store/ducks/auth/actions';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';

import useStyles from './styles';
import { TERMS_CONDITIONS } from 'utils/links/termsConditions';
import { PLANS_MBC_LINK } from 'utils/links/common';

const SectionLogin: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const validationSchema = Yup.object({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const { isLoading } = useSelector<AppState, AuthState>(
    ({ auth }: AppState) => auth,
  );

  const { handleSubmit, register, errors, reset } = useForm({
    validationSchema,
  });

  const onSubmit = (data: any) => {
    if (!isLoading) {
      dispatch(loginRequest(data));
      reset();
    }
  };

  return (
    <Grid item xs={12} md={6} className={classes.containerWrapper}>
      <Typography className={classes.titleText}>Bem-vindo(a)</Typography>
      <Hidden smDown>
        <Typography className={classes.subtitleText}>
          Faça login para acessar a área do assinante.
        </Typography>
      </Hidden>
      <Box className={classes.containerForm}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <MyOutlinedTextField
            fullWidth
            name="email"
            label="Email"
            type="email"
            className={classes.inputText}
            classes={{ root: classes.inputWrapper }}
            inputRef={register}
            error={Boolean(errors.email)}
            helperText={errors.email ? errors.email.message : null}
          />
          <MyOutlinedTextField
            fullWidth
            name="password"
            label="Senha"
            type="password"
            classes={{ root: classes.inputWrapper }}
            inputRef={register}
            error={Boolean(errors.password)}
            helperText={errors.password ? errors.password.message : null}
          />
          <Typography className={classes.helperText}>
            <Link to="/recover-password" className={classes.observationLink}>
              Esqueceu a senha?
            </Link>
          </Typography>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className={classes.button}
          >
            Fazer Login
          </Button>
        </form>

        <Typography className={classes.helperText}>
          <a href={PLANS_MBC_LINK} className={classes.observationLink}>
            Não é assinante? Clique aqui
          </a>
        </Typography>

        {/* <Button
          startIcon={<FacebookIcon style={{ fontSize: 32, marginRight: 8 }} />}
          variant="outlined"
          color="secondary"
          className={`${classes.button} ${classes.socialFBButton}`}
        >
          Entrar com Facebook
        </Button> */}
      </Box>
      <Grid
        container
        direction="column"
        className={classes.termsConditionsContainer}
      >
        <Grid item>
          <Typography className={classes.subtitleText}>
            <Link
              to={{
                pathname: TERMS_CONDITIONS,
              }}
              target="_blank"
              className={classes.observationLink}
            >
              Acesse os termos de uso
            </Link>{' '}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SectionLogin;
