import React from 'react';
import { Avatar } from '@material-ui/core';
import { useStyles } from './styles';
import { MyUserProfileImageProps } from './types';

const MyUserProfileImage: React.FC<MyUserProfileImageProps> = props => {
  const styles = useStyles();
  return (
    <Avatar variant="rounded" style={props.style ? props.style : {}}>
      {props.photo ? (
        <img
          src={props.photo}
          alt="Prévia da imagem"
          className={styles.thumb}
        />
      ) : (
        props.letter || '-'
      )}
    </Avatar>
  );
};

export default MyUserProfileImage;
