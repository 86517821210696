import { Reducer } from 'redux';
import { ViewState, ViewTypes } from './types';

const INITIAL_STATE: ViewState = {
  data: [],
  hasError: false,
  isLoading: true,
  total: 0,
};

const reducer: Reducer<ViewState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ViewTypes.LOAD_VIEW_USERS_REQUEST:
      return { isLoading: true, hasError: false, data: [], total: 0 };
    case ViewTypes.LOAD_VIEW_USERS_FAILURE:
      return { isLoading: false, hasError: true, data: [], total: 0 };
    case ViewTypes.LOAD_VIEW_USERS_FILE_REQUEST_SUCCESS:
      return { ...state, isLoading: false, hasError: false };
    case ViewTypes.LOAD_VIEW_USERS_FILE_REQUEST_FAILURE:
      return { ...state, isLoading: false, hasError: true };
    case ViewTypes.LOAD_VIEW_USERS_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload.data,
        total: action.payload.total,
      };

    default:
      return state;
  }
};

export default reducer;
