import { Box } from './box';
import { PAYMENT_TYPE } from './payment';

export enum RECURRENCES_MONTHS {
  MONTHLY = 1,
  BIMONTHLY = 2,
  QUARTERLY = 3,
  SEMESTER = 6,
  YEARLY = 12,
}

export interface Recurrence {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  months: RECURRENCES_MONTHS;
}

export interface IPrices {
  bankslipPrice: number;
  cardPrice: number;
}

export interface IPlanDiscount {
  bankslipDiscount: number;
  cardDiscount: number;
}

export interface Plan {
  id: number;
  createdAtd: string;
  updatedAt: string;
  name: string;
  sku: string;
  description: string;
  paymentType: PAYMENT_TYPE;
  prices: IPrices;
  discounts: IPlanDiscount;
  message?: string;
  image?: string;
  freight: string;
  cardNumber?: string;
  cardBrand?: string;
  boxes: Box[];
  recurrence: Recurrence;
  isPrivate?: boolean;
  isDonation?: boolean;
  successPage?: string;
  actionPage?: string;
  unitPrice?: string;
  hash?: string;
  stepsDisabled?: boolean;
}

export interface PlanForm extends Plan {
  bankslipPrice: number;
  cardPrice: number;
  bankslipDiscount: number;
  cardDiscount: number;
}
