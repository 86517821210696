import { Typography } from '@material-ui/core';
import { Variant } from '@material-ui/core/styles/createTypography';
import React, { FC } from 'react';

interface MyTypographyProps {
  variant?: Variant;
  color?:
    | 'initial'
    | 'inherit'
    | 'primary'
    | 'secondary'
    | 'textPrimary'
    | 'textSecondary'
    | 'error';
  className?: string;
}

const MyTypography: FC<MyTypographyProps> = ({
  children,
  color,
  variant,
  className,
}) => {
  return (
    <Typography
      variant={!variant ? 'h4' : variant}
      color={!color ? 'textPrimary' : color}
      className={className}
    >
      {children}
    </Typography>
  );
};

export default MyTypography;
