import React from 'react';
import { Grid } from '@material-ui/core';
import { Link } from 'react-router-dom';

import MyHeaderBar from '../MyHeaderBar';
import List from '../List';

import { Wrapper, Logo, CustomGreyText, Footer, useStyles } from './styles';

import { SidebarPropsType } from './types';
import {
  TERMS_CONDITIONS,
  PRIVATE_CONDITIONS,
} from 'utils/links/termsConditions';
import UserWalletScore from '../UserWalletScore';

const Sidebar: React.FC<SidebarPropsType> = ({ data }) => {
  const styles = useStyles();
  return (
    <Wrapper
      item
      container
      wrap="nowrap"
      direction="column"
      justify="space-between"
    >
      <Grid item xs className={styles.sidebar}>
        <MyHeaderBar />
        <UserWalletScore />
        <List items={data} />
      </Grid>
      <Footer item xs>
        <Logo />
        <Link
          className={styles.termsAndConditionsLink}
          to={{
            pathname: TERMS_CONDITIONS,
          }}
          target="_blank"
        >
          <CustomGreyText variant="h4">Termos e Condições</CustomGreyText>
        </Link>
        <Link
          className={styles.termsAndConditionsLink}
          to={{
            pathname: PRIVATE_CONDITIONS,
          }}
          target="_blank"
        >
          <CustomGreyText variant="h4">Política de Privacidade</CustomGreyText>
        </Link>
      </Footer>
    </Wrapper>
  );
};

export default Sidebar;
