import React, { useState } from 'react';
import { Button, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { ToggleButtonProps } from './types';

const ToggleButton: React.FC<ToggleButtonProps> = ({
  id,
  buttonTitle = '',
  isToggled = false,
  handleClick,
}) => {
  const styles = useStyles();

  const onClick = () => {
    handleClick(id, !isToggled);
  };

  return (
    <Button
      onClick={onClick}
      className={isToggled ? styles.toggleButtonActive : styles.toggleButton}
    >
      <Typography
        variant="h4"
        color="textPrimary"
        className={isToggled ? styles.filterButtonActive : styles.filterButton}
      >
        {buttonTitle}
      </Typography>
    </Button>
  );
};

export default ToggleButton;
