import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid, Hidden } from '@material-ui/core';

import RecoverAdminForm from './RecoverForm';

import { AuthState } from 'store/ducks/auth/types';
import { AppState } from 'store';

import { ReactComponent as Logo } from 'assets/img/svg/logo_mbc_text.svg';

import { useLoginStyles } from '../styles';

const RecoverPassword: React.FC = () => {
  const classes = useLoginStyles();

  const { isAuthenticated } = useSelector<AppState, AuthState>(
    ({ auth }: AppState) => auth,
  );

  return isAuthenticated ? (
    <Redirect to={{ pathname: '/admin' }} />
  ) : (
    <Grid classes={{ root: classes.root }} container justify="space-between">
      <Hidden xsDown>
        <Grid item sm={1} />
        <Grid item container sm={4} alignItems="center">
          <Logo className={classes.logo} />
        </Grid>
        <Grid item sm={2} />
      </Hidden>
      <Grid item container sm={4} xs={12} alignItems="center">
        <RecoverAdminForm />
      </Grid>
      <Grid item sm={1} />
    </Grid>
  );
};

export default RecoverPassword;
