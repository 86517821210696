import { Address } from 'types';

export enum AddressTypes {
  LOAD_ALL_ADDRESSES = '@addresses/LOAD_ALL_ADDRESSES',
  LOAD_ADDRESSES_SUCCESS = '@addresses/LOAD_ADDRESSES_SUCCESS',
  LOAD_ADDRESSES_FAILURE = '@addresses/LOAD_ADDRESSES_FAILURE',
  LOAD_DEFAULT_ADDRESS = '@addresses/LOAD_DEFAULT_ADDRESS',
  LOAD_DEFAULT_ADDRESS_SUCCESS = '@addresses/LOAD_DEFAULT_ADDRESS_SUCCESS',
  LOAD_DEFAULT_ADDRESS_FAILURE = '@addresses/LOAD_DEFAULT_ADDRESS_FAILURE',
}

export interface AddressPayload {
  data: Address[] | Address;
  total: number;
}

export interface AddressState {
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly data: Address[];
  readonly total: number;
}

export interface OneAddressState extends Omit<AddressState, 'data' | 'total'> {
  readonly data: Address;
}
