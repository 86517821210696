import React, { useRef, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ReactGA from 'react-ga';
import TagManager from 'react-gtm-module';

import {
  TextWrapper,
  PageWrapper,
  Title,
  SubTitle,
  CheckoutInfo,
  Line,
  CongratsIcon,
  BoldText,
  RedText,
  PaymentCodeWrapper,
  CopyIcon,
  TextArea,
  ShipmentInfo,
  MyAccountButton,
} from './styles';

import CircularLoading from 'components/generals/loading/CircularLoading';

import { useGetWithSWR } from 'services/apiService';
import { PAYMENT_TYPE } from 'types/generals';

const CheckoutCompleted = () => {
  const paymentCodeRef = useRef() as any;
  const history = useHistory();
  const signatureId = localStorage.getItem('signatureId');

  const [signaturePrice, setSignaturePrice] = useState();
  const [orderNumber, setOrderNumber] = useState();
  const [paymentCode, setPaymentCode] = useState();
  const [paymentType, setPaymentType] = useState();

  const { data: signature } = useGetWithSWR(
    `open-endpoints/signature/${signatureId}`,
  );

  TagManager.initialize({ gtmId: 'GTM-56JXR6D' });

  ReactGA.initialize('UA-112009147-1');
  ReactGA.pageview(window.location.pathname + window.location.search);

  useEffect(() => {
    if (!signatureId) {
      history.push('/login');
    }
  }, [history, signatureId]);

  useEffect(() => {
    if (signature) {
      const { paymentType, paymentCode, orderNumber, signaturePrice } =
        signature;
      setSignaturePrice(signaturePrice);
      setOrderNumber(orderNumber);
      setPaymentCode(paymentCode);
      setPaymentType(paymentType);
    }
  }, [signature]);

  const handleCopy = () => {
    paymentCodeRef.current.select();
    document.execCommand('copy');
  };

  const getShipmentInfo = () =>
    paymentType === 'boleto'
      ? 'O seu box será enviado após a confirmação pela instituição financeira, que leva em média 3 dias após o pagamento.'
      : 'O seu box será enviado em breve. enquanto isso aproveito todos os benefícios e conteúdos exclusivos para assinantes.';

  const getSubtitle = () =>
    paymentType === 'boleto'
      ? 'Sua contratação foi realizada com sucesso! Agora é só efetuar o pagamento do seu boleto.'
      : 'Sua contratação foi realizada com sucesso! Agora é só aguardar o seu box chegar.';

  const goHome = () => {
    localStorage.clear();
    history.push('/');
  };

  return (
    <PageWrapper>
      {signature ? (
        <>
          <CongratsIcon />
          <TextWrapper>
            <Title>Estamos felizes de ter você aqui!</Title>
            <SubTitle>{getSubtitle()}</SubTitle>
            <CheckoutInfo>
              <Line>
                Valor total da assinatura:
                {signaturePrice ? (
                  <BoldText>{` R$ ${signaturePrice}`} </BoldText>
                ) : (
                  ''
                )}
              </Line>
              <Line>
                Número do pedido: <RedText>{orderNumber}</RedText>
              </Line>
              {paymentType === PAYMENT_TYPE.BANKSLIP && (
                <>
                  <Line>Código do boleto de assinatura:</Line>
                  <PaymentCodeWrapper>
                    <TextArea ref={paymentCodeRef} value={paymentCode} />
                    <CopyIcon onClick={handleCopy} />
                  </PaymentCodeWrapper>
                </>
              )}
            </CheckoutInfo>
            <ShipmentInfo>{getShipmentInfo()}</ShipmentInfo>
            <MyAccountButton onClick={goHome}>
              Acessar minha conta
            </MyAccountButton>
          </TextWrapper>
        </>
      ) : (
        <CircularLoading
          isLoading={signature ? false : true}
          title="Criando assinatura"
        />
      )}
    </PageWrapper>
  );
};

export default CheckoutCompleted;
