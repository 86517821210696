export const UNMASK = {
  onlyDigits: (value: string) => value.replace(/\D/g, ''),
};

const MASK = {
  CPF: '999.999.999-99',
  ZIPCODE: '99999-999',
  CPF_TO_CNPJ: '999.999.999-99*',
  CNPJ: '99.999.999/9999-99',
  CREDIT_CARD: '9999 9999 9999 9999',
  CC_EXPIRATION_DATE: '99/9999',
  CELLPHONE: '(99) 99999-9999',
  DATE: '99/99/9999',
  HOUR: '99:99',
  MONEY: 'R$ 99999999',
  NUMBER: '99999999',
  CARD_CODE: '9999',
};

export default MASK;
