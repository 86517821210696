import { makeStyles, styled, Typography } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

export const useStyles = makeStyles({
  gridHeader: {
    padding: '0px 30px 0 35px',
  },
  rootPaper: {
    padding: theme.spacing(4, 3),
    borderRadius: '2em',
  },
  draggableItem: {
    backgroundColor: Colors.LIGHTER_GRAY,
    color: Colors.DARKER_GRAY,
    marginBottom: '12px',
    cursor: 'pointer !important',
    padding: '8px 16px',
    borderRadius: '8px',
    minHeight: '48px',
  },
  bannerTitle: {
    marginTop: 40,
    marginBottom: 10,
    fontWeight: 'bold',
  },
});
