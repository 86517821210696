import React from 'react';
import { Grid, Hidden } from '@material-ui/core';

import NewPasswordForm from './NewPasswordForm';

import { ReactComponent as Logo } from 'assets/img/svg/logo_mbc_text.svg';

import { useLoginStyles } from '../../styles';

const RecoverPassword: React.FC = () => {
  const classes = useLoginStyles();

  return (
    <Grid classes={{ root: classes.root }} container justify="space-between">
      <Hidden xsDown>
        <Grid item sm={1} />
        <Grid item container sm={4} alignItems="center">
          <Logo className={classes.logo} />
        </Grid>
        <Grid item sm={2} />
      </Hidden>
      <Grid item container sm={4} xs={12} alignItems="center">
        <NewPasswordForm />
      </Grid>
      <Grid item sm={1} />
    </Grid>
  );
};

export default RecoverPassword;
