import React, { useRef } from 'react';
import {
  Grid,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import clsx from 'clsx';
import { StatusCodes } from 'http-status-codes';

import { AppState } from 'store';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import {
  notifyError,
  notifySuccess,
  disableButton,
  enableButton,
} from 'store/ducks/notification/actions';

import MySelectForm from '../input/MySelectForm';
import ConfirmationDialog from './ConfirmationDialog';

import useStyles from 'components/generals/forms/register/styles';
import { rankingScoreSchema } from './utils';
import { BaseDialogProps, UserDataProps } from './dialogTypes';
import { sleep } from 'utils/functions/generals';
import { WalletTransactionsType } from 'types/generals/rankingSeason';
import RankingSeasonService from 'services/rankingSeasonService';
import MyOutlinedTextField from '../input/MyOutlinedTextField';
import { InputRef } from 'types';
import {
  RANKING_SCORE_ACTION_MESSAGE,
  RANKING_SCORE_ADD_ERROR,
  RANKING_SCORE_ADD_SUCCESS,
  RANKING_SCORE_REMOVE_ERROR,
  RANKING_SCORE_REMOVE_SUCCESS,
  RANKING_SEASON_INVALID,
} from 'utils/messages/rankingSeason';
import { NotificationState } from 'store/ducks/notification/types';
import { useHistory } from 'react-router-dom';

const AddRakingScoreDialog: React.FC<UserDataProps> = ({ user, rankings }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const history = useHistory();
  const walletTransactionTypes = [
    { title: 'Adicionar', value: WalletTransactionsType.in },
    { title: 'Remover', value: WalletTransactionsType.out },
  ];
  const numberInput = useRef() as InputRef;

  const { buttonIsDisabled } = useSelector<AppState, NotificationState>(
    ({ notification }: AppState) => notification,
  );

  const { handleSubmit, errors, control, register } = useForm({
    validationSchema: rankingScoreSchema,
  });

  const onSubmit = async (data: any) => {
    try {
      dispatch(disableButton());
      if (!user) return;
      const isAdding =
        data.walletAction === WalletTransactionsType.in ? true : false;
      const rankingSeasonId = Number(data.rankingSeasonId);
      if (isNaN(rankingSeasonId)) {
        dispatch(notifyError(RANKING_SEASON_INVALID));
        return;
      }
      const response = await RankingSeasonService.addManualRankingPoints(
        user.id,
        data.score,
        data.observation,
        data.walletAction,
        rankingSeasonId,
      );
      let message = '';
      if (response.status === StatusCodes.CREATED) {
        await sleep(3000);
        message = isAdding
          ? RANKING_SCORE_ADD_SUCCESS
          : RANKING_SCORE_REMOVE_SUCCESS;
        dispatch(notifySuccess(message));
      } else {
        message = isAdding
          ? RANKING_SCORE_ADD_ERROR
          : RANKING_SCORE_REMOVE_ERROR;
        throw new Error(message);
      }
    } catch (error) {
      const message = error?.response?.data.message || error?.message;
      dispatch(notifyError(message));
    } finally {
      dispatch(enableButton());
      dispatch(closeModal());
      history.go(0);
    }
  };
  const handleOpenConfirmDialog = (data: any) => {
    const isAdding =
      data.walletAction === WalletTransactionsType.in ? true : false;
    const message = isAdding ? 'Adicionando' : 'Removendo';
    const modalProps: BaseDialogProps = {
      title: `${message} pontos de campanha`,
      confirmText: 'Sim',
      cancelText: 'Não',
      message: RANKING_SCORE_ACTION_MESSAGE,
      actionFn: () => onSubmit(data),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  return (
    <>
      <Grid container direction="column">
        <DialogTitle>
          <Grid container justify="space-between">
            <Typography variant="h1">Atribuir pontos de campanha</Typography>
            <IconButton onClick={() => dispatch(closeModal())}>
              <CloseIcon />
            </IconButton>
          </Grid>
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column" spacing={1}>
            <Grid item xs>
              <Controller
                name="rankingSeasonId"
                control={control}
                defaultValue=""
                as={({ onChange, onBlur, value }) => (
                  <MySelectForm
                    contracted
                    title="Campanha"
                    itens={rankings?.map(ranking => ({
                      title: ranking.name,
                      value: ranking.id,
                    }))}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={Boolean(errors.rankingSeasonId)}
                    helperText={
                      errors.rankingSeasonId
                        ? errors.rankingSeasonId.message
                        : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <Controller
                name="walletAction"
                control={control}
                defaultValue=""
                as={({ onChange, onBlur, value }) => (
                  <MySelectForm
                    contracted
                    title="Ação"
                    itens={walletTransactionTypes}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={Boolean(errors.walletAction)}
                    helperText={
                      errors.walletAction ? errors.walletAction.message : null
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs>
              <MyOutlinedTextField
                id="observation"
                name="observation"
                label="Observação"
                fullWidth
                inputRef={register}
                error={Boolean(errors.observation)}
                helperText={
                  errors.observation ? errors.observation.message : null
                }
              />
            </Grid>
            <Grid item xs>
              <MyOutlinedTextField
                fullWidth
                label="Quantidade de pontos"
                id="score"
                name="score"
                type="text"
                inputProps={{ ref: numberInput }}
                error={Boolean(errors.score)}
                helperText={errors.score ? errors.score.message : null}
                inputRef={register}
              />
            </Grid>

            <Grid container item justify="flex-end">
              <Button
                className={clsx(styles.buttonLabel, styles.buttonWrapper)}
                variant="contained"
                color="primary"
                disabled={buttonIsDisabled}
                onClick={handleSubmit(handleOpenConfirmDialog as any)}
              >
                Enviar
              </Button>
            </Grid>
          </Grid>
        </DialogContent>
      </Grid>
    </>
  );
};

export default AddRakingScoreDialog;
