import { call, put } from 'redux-saga/effects';
import ViewsService from 'services/viewsService';
import {
  loadViewUsersReactivationFailure,
  loadViewUsersReactivationSuccess,
} from './actions';

export function* loadViewUserReactivationSaga(action: any) {
  try {
    const { data, total } = yield call(
      ViewsService.getViewUserReactivation,
      action.payload.query,
    );
    yield put(loadViewUsersReactivationSuccess({ data, total }));
  } catch (error) {
    yield put(loadViewUsersReactivationFailure());
  }
}
