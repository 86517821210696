import React, { FC } from 'react';
import ptLocale from 'date-fns/locale/pt';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import DateFnsUtils from '@date-io/date-fns';

interface MyDatePickerProps {
  value?: any;
  onChange?: any;
  inputRef?: any;
  buttonProps?: any;
  id: string;
  name: string;
  label: string;
  error: any;
  helperText: any;
  minDate?: Date;
  maxDate?: Date;
  disablePast?: boolean;
  disableFuture?: boolean;
  allowKeyboardControl?: boolean;
}

const MyDatePicker: FC<MyDatePickerProps> = props => {
  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale}>
      <KeyboardDatePicker
        clearable
        value={props.value || null}
        onChange={props.onChange}
        minDate={new Date(1)}
        format="dd/MM/yyyy"
        inputVariant="outlined"
        error={props.error}
        helperText={props.helperText}
        label={props.label}
        inputRef={props.inputRef}
        InputProps={{
          notched: false,
          readOnly: props.allowKeyboardControl,
        }}
        fullWidth
      />
    </MuiPickersUtilsProvider>
  );
};

export default MyDatePicker;
