import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  alignCenter: {
    alignItems: 'center',
  },
  brandLabel: {
    margin: 0,
    [theme.breakpoints.down('xs')]: {
      marginTop: '4px',
      marginBottom: '19px',
    },
  },
  cardItem: {
    padding: theme.spacing(2, 3),
    borderRadius: '8px',
  },
  icon: {
    fontSize: '32px',
  },
  button: {
    fontSize: 14,
  },
}));
