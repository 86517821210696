import { call, put } from 'redux-saga/effects';
import ViewsService from 'services/viewsService';
import { createAndDownloadFile } from 'utils/functions/generals';
import {
  loadViewUsersSuccess,
  loadViewUsersFailure,
  loadViewUsersXlsRequestSuccess,
  loadViewUsersXlsRequestFailure,
} from './actions';

export function* loadViewUsersSaga(action: any) {
  try {
    const { data, total } = yield call(
      ViewsService.getViewSubscribers,
      action.payload.query,
    );
    yield put(loadViewUsersSuccess({ data, total }));
  } catch (error) {
    yield put(loadViewUsersFailure());
  }
}
export function* loadViewUsersFileSaga(action: any) {
  try {
    const file = yield call(
      ViewsService.getViewSubscribersFile,
      action.payload.query,
    );
    createAndDownloadFile(file, 'Assinantes.xls');
    yield put(loadViewUsersXlsRequestSuccess());
  } catch (error) {
    yield put(loadViewUsersXlsRequestFailure());
  }
}
