import React, { useState, FC, ChangeEvent } from 'react';
import { Grid, FormControl, InputLabel, Select } from '@material-ui/core';

import { useStyles } from './styles';
import { MonthYearSelectorProps } from './types';

const MonthYearSelector: FC<Partial<MonthYearSelectorProps>> = ({
  setValue,
  register,
  disabled,
}) => {
  const style = useStyles();
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const monthsObject = months.map(value => ({
    title: `${value}`,
    value,
  }));
  const currentYear = new Date().getFullYear();
  const yearsObject = Array.from(new Array(21)).map((e, index) => {
    return {
      title: `${currentYear + index}`,
      value: currentYear + index,
    };
  });
  const [dueMonth, setDueMonth] = useState(monthsObject[0].value);
  const [dueYear, setDueYear] = useState(yearsObject[0].value);

  const handleChange = (
    event: ChangeEvent<{ name?: string; value: unknown }>,
    field: string,
  ) => {
    const value = event.target.value;
    if (value && field === 'month') {
      if (setValue) {
        setValue('dueMonth', value);
      }
      setDueMonth(value as number);
    } else {
      if (setValue) {
        setValue('dueYear', value);
      }
      setDueYear(value as number);
    }
  };

  return (
    <Grid container direction="row" spacing={2}>
      <Grid item xs={6}>
        <FormControl className={style.formControl} variant="outlined" fullWidth>
          <InputLabel htmlFor="month-native-simple">Mês</InputLabel>
          <Select
            native
            inputRef={register}
            value={dueMonth}
            onChange={(event: any) => handleChange(event, 'month')}
            inputProps={{
              name: 'dueMonth',
              id: 'month-native-simple',
            }}
            disabled={disabled}
          >
            {monthsObject.map(month => (
              <option
                value={month.value}
                key={month.value}
                className={style.option}
              >
                {month.title}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl className={style.formControl} variant="outlined" fullWidth>
          <InputLabel htmlFor="year-native-simple">Ano</InputLabel>
          <Select
            native
            inputRef={register}
            value={dueYear}
            onChange={(event: any) => handleChange(event, 'year')}
            inputProps={{
              name: 'dueYear',
              id: 'year-native-simple',
            }}
            disabled={disabled}
          >
            {yearsObject.map(year => (
              <option
                value={year.value}
                key={year.value}
                className={style.option}
              >
                {year.title}
              </option>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default MonthYearSelector;
