import { Video } from 'types/generals';

export enum VideosTypes {
  LOAD_VIDEO_LIST_REQUEST = '@video/LOAD_VIDEO_LIST_REQUEST',
  LOAD_VIDEO_LIST_SUCCESS = '@video/LOAD_VIDEO_LIST_SUCCESS',
  LOAD_VIDEO_LIST_FAILURE = '@video/LOAD_VIDEO_LIST_FAILURE',
  // LOAD_VIDEO_FILE_REQUEST = '@video/LOAD_VIDEO_FILE_REQUEST',
  /* LOAD_VIDEO_REQUEST = '@video/LOAD_VIDEO_REQUEST',
  LOAD_VIDEO_SUCCESS = '@video/LOAD_VIDEO_SUCCESS',
  LOAD_VIDEO_FAILURE = '@video/LOAD_VIDEO_FAILURE',
  LOAD_ONE_VIDEO = '@video/LOAD_VIDEO_ONE', */
}

export interface VideoPayload {
  data: Video | Video[];
  total?: number;
}

export interface VideoState {
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly total: number;
  readonly data: Video[];
}

/* export interface OneVIDEOState extends Omit<VIDEOState, 'data' | 'total'> {
  readonly data: VIDEO;
}
 */
