import React from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';

import { Grid, Typography, TextField, Button } from '@material-ui/core';

import { loginRequest } from 'store/ducks/auth/actions';

import { Colors } from 'styles/colors';
import { useStyles } from './styles';

export const LoginForm: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, register } = useForm();

  const onSubmit = (data: any) => {
    dispatch(loginRequest(data));
  };

  return (
    <Grid item container direction="column" justify="space-between">
      <Grid
        item
        container
        direction="column"
        classes={{ root: classes.header }}
      >
        <Typography variant="h2" className={classes.main}>
          Bem-vindo(a)
        </Typography>
        <Typography variant="h4" className={classes.secondary}>
          Faça login para continuar
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item container direction="column">
          <TextField
            inputRef={register}
            autoFocus
            variant="filled"
            label="Email"
            name="email"
            classes={{ root: classes.root }}
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: Colors.DARKER_GRAY,
                fontSize: 20,
                paddingLeft: 7,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: { root: classes.inputRoot, focused: classes.focused },
              style: {
                background: Colors.LIGHT_GRAY,
                borderRadius: 4,
              },
            }}
          />
          <TextField
            inputRef={register}
            variant="filled"
            label="Senha"
            name="password"
            classes={{ root: classes.root }}
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: Colors.DARKER_GRAY,
                fontSize: 20,
                paddingLeft: 7,
              },
            }}
            inputProps={{
              type: 'password',
            }}
            InputProps={{
              disableUnderline: true,
              classes: { root: classes.inputRoot, focused: classes.focused },
              style: {
                background: Colors.LIGHT_GRAY,
                borderRadius: 4,
              },
            }}
          />
        </Grid>
        <Grid>
          <Link to="/admin/recover-password" className={classes.textLink}>
            <Typography display="inline" classes={{ root: classes.textRoot }}>
              Esqueceu a senha?
            </Typography>
          </Link>
        </Grid>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            classes={{
              root: classes.buttonRoot,
            }}
            type="submit"
          >
            Entrar
          </Button>
        </Grid>
      </form>
    </Grid>
  );
};
