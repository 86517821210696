import React, { useEffect, useRef, useState } from 'react';
import { FormControlLabel, Grid, Paper, Typography } from '@material-ui/core';

import { Controller } from 'react-hook-form';
import { InputRef } from 'types';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { useStyles } from './styles';
import MySwitch from 'components/generals/input/MySwitch';
import MyDatePicker from 'components/generals/input/MyDatePicker';
import { StoreSettingsProps } from './types';

import MyDateHourInput from 'components/generals/input/MyDateHourInput';
import { last } from 'lodash';

const StoreSettingsFormPeriods: React.FC<StoreSettingsProps> = ({
  register,
  control,
  errors,
  watch,
  storeSettings,
  setValue,
}) => {
  const styles = useStyles();
  const reasonInput = useRef() as InputRef;
  const expiringDateInput = useRef() as InputRef;

  const [isActive, setIsActive] = useState<boolean>(storeSettings.isActive);

  useEffect(() => {
    setIsActive(storeSettings.isActive);
  }, [storeSettings.isActive]);

  useEffect(() => {
    const lastPromotionalPeriods = last(storeSettings.promotionPeriods);
    let startHourDate = lastPromotionalPeriods?.startHourDate;
    let endHourDate = lastPromotionalPeriods?.endHourDate;

    setValue('startHourDate', startHourDate);
    setValue('endHourDate', endHourDate);
  }, [storeSettings.promotionPeriods, setValue]);

  return (
    <>
      <Grid className={styles.marginTop}>
        <Typography className={styles.bannerTitle} variant="h2">
          Período promocional
        </Typography>
        <Paper elevation={0} className={styles.rootPaper}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Controller
                name="startDate"
                control={control}
                as={({ onChange, value }) => (
                  <MyDatePicker
                    id="boxStore-start-date"
                    value={value}
                    onChange={onChange}
                    disablePast
                    name="startDate"
                    label="Data de início"
                    error={Boolean(errors.startDate)}
                    helperText={
                      errors.startDate ? errors.startDate.message : null
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MyDateHourInput
                name="startHourDate"
                label="Hora do início"
                setValue={setValue}
                control={control}
                errors={Boolean(errors.startHourDate)}
                register={register}
                helperText={
                  errors.startHourDate ? errors.startHourDate.message : null
                }
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="endDate"
                control={control}
                as={({ onChange, value }) => (
                  <MyDatePicker
                    minDate={
                      watch('startDate')
                        ? new Date(watch('startDate'))
                        : new Date()
                    }
                    id="boxStore-end-date"
                    value={value}
                    onChange={onChange}
                    disablePast
                    name="endDate"
                    label="Data de término"
                    error={Boolean(errors.endDate)}
                    helperText={errors.endDate ? errors.endDate.message : null}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <MyDateHourInput
                name="endHourDate"
                label="Hora do término"
                setValue={setValue}
                control={control}
                errors={Boolean(errors.endHourDate)}
                register={register}
                helperText={
                  errors.endHourDate ? errors.endHourDate.message : null
                }
              />
            </Grid>

            <Grid item xs={12}>
              <MyOutlinedTextField
                fullWidth
                name="reason"
                label="Razão"
                type="text"
                inputProps={{ ref: reasonInput }}
                inputLabelprops={{ shrink: true }}
                changeFocusOnEnter={() => expiringDateInput.current.focus()}
                inputRef={register}
                error={Boolean(errors.reason)}
                helperText={errors.reason ? errors.reason.message : null}
              />
            </Grid>

            <Grid item xs>
              <FormControlLabel
                control={
                  <MySwitch
                    inputRef={register}
                    name="isActive"
                    color="primary"
                    checked={isActive}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setIsActive(e.target.checked);
                    }}
                  />
                }
                label="Período promocional ativo?"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default StoreSettingsFormPeriods;
