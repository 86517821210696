import { api, apiQueue } from './apiService';

const TOKEN_KEY = 'jwtToken';
const CURRENT_USER = 'currentUser';

export const getToken = () => localStorage.getItem(TOKEN_KEY);

export const setLogin = (token: string, user: object) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(CURRENT_USER, JSON.stringify(user));
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(CURRENT_USER);
};

export const getCurrentUser = () =>
  JSON.parse(localStorage.getItem(CURRENT_USER) || '{}');

export const FORGOT_VERIFY = '/auth/forgot-password/verify';
export default class AuthService {
  static async login(email: string, password: string) {
    return api.post('auth/login', { email, password });
  }

  static async resetPassword(
    email: string,
    password: string,
    newPassword: string,
  ) {
    try {
      return await api.put('auth/change-password', {
        email,
        password,
        newPassword,
      });
    } catch (error) {
      return error.response;
    }
  }

  static async recoverPassword(email: string) {
    try {
      return await apiQueue.get('/auth/forgot-password', {
        params: { email },
      });
    } catch (error) {
      throw new Error(error.response);
    }
  }

  static async verifyRecoveryToken(token: string) {
    try {
      return await api.get(`/auth/forgot-password/verify/${token}`);
    } catch (error) {
      throw new Error(error.response);
    }
  }

  static async resetPasswordWithToken(token: string, password: string) {
    try {
      return await api.post(`/auth/forgot-password/new/${token}`, {
        password,
      });
    } catch (error) {
      throw new Error(error.response);
    }
  }
}
