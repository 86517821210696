import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';

import MyDropdown from 'components/generals/dropdown/MyDropdown';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import {
  BaseDialogProps,
  InvoiceDialogProps,
} from 'components/generals/dialog/dialogTypes';

import { openModal, closeModal } from 'store/ducks/nav/actions';

import { Order } from 'types/generals/order';
import {
  ORDER_SECOND_COPY_BANKSLIP_CONFIRM,
  ORDER_SECOND_COPY_BANKSLIP_ERROR,
} from 'utils/messages';
import OrdersService from 'services/ordersService';
import { notifyError } from 'store/ducks/notification/actions';
import { OrdersStoreActionsProps } from './interfaces';
import { PAYMENT_TYPE } from 'types/generals';
import { useStyles } from './styles';
import Colors from 'styles/colors';
import InvoiceDialog from 'components/generals/dialog/InvoiceDialog';

const OrdersStoreActions: React.FC<OrdersStoreActionsProps> = ({ order }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const handleTryingPaymentBlankslip = async (order: Order) => {
    try {
      const response = await OrdersService.getSmartLinkBankslip(order.id);
      if (response.status === StatusCodes.OK) {
        dispatch(closeModal());
        const donwloadBankslip = 'download-bankslip';
        const [root, hash] = response.data.split(donwloadBankslip);
        const redirectUrl = `${root}admin/${donwloadBankslip}${hash}`;
        const win = window.open(redirectUrl, '_blank');
        if (win) win.focus();
      } else {
        throw new Error(ORDER_SECOND_COPY_BANKSLIP_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleOpenInvoices = async (order: Order) => {
    try {
      if (order.invoices.length === 1) {
        const win = window.open(order.invoices[0].linkDanfe, '_blank');
        if (win) win.focus();
      } else {
        const dialogProps: InvoiceDialogProps = {
          order,
        };

        dispatch(openModal(InvoiceDialog, dialogProps, 'md'));
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleConfirmActionDialog = useCallback(
    (title: string, message: string, actionFn) => {
      const dialogProps: BaseDialogProps = {
        actionFn,
        title,
        message,
      };

      dispatch(openModal(ConfirmationDialog, dialogProps));
    },
    [dispatch],
  );

  return (
    <MyDropdown
      buttonProps={{
        color: 'primary',
        variant: 'contained',
        style: {
          width: '100%',
          borderRadius: 13,
          boxShadow: 'none',
          backgroundColor: 'transparent',
          color: Colors.RED,
        },
      }}
      menuProps={{
        classes: {
          list: styles.filterOptionsList,
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        PaperProps: {
          style: {
            marginTop: '1rem',
            borderRadius: 13,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }}
      menuItems={[
        {
          disabled: order.orderDetail.paymentType === PAYMENT_TYPE.CARD,
          title: '2ª via boleto',
          action: () =>
            handleConfirmActionDialog(
              'Emitir 2ª via do boleto',
              ORDER_SECOND_COPY_BANKSLIP_CONFIRM,
              () => handleTryingPaymentBlankslip(order),
            ),
        },
        {
          disabled: order?.invoices?.length <= 0,
          title: 'Notas fiscais',
          action: () => handleOpenInvoices(order),
        },
      ]}
    >
      <Typography variant="h3">{/* Ações */}</Typography>
    </MyDropdown>
  );
};

export default OrdersStoreActions;
