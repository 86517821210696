import React, { FC, useCallback, useEffect, useState } from 'react';
import { CondOperator } from '@nestjsx/crud-request';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { openModal } from 'store/ducks/nav/actions';
import { useStyles } from './styles';
import { AppState } from 'store';
import { UserState } from 'store/ducks/user/types';
import RankingSeasonService from 'services/rankingSeasonService';
import MyTableCell from 'components/generals/table/MyTableCell';
import { UserRankingData } from 'types/generals/rankingSeason';
import UserRankingDialog from 'components/generals/dialog/UserRankingDialog';
import { useCommonStyles } from 'styles/common';
import { ReactComponent as FirstPosSVG } from 'assets/img/svg/first_position.svg';
import { ReactComponent as SecondPosSVG } from 'assets/img/svg/second_position.svg';
import { ReactComponent as ThirdPosSVG } from 'assets/img/svg/thrid_position.svg';
import { RankingState } from 'store/ducks/ranking/types';

const RankingSeasonClient: FC = () => {
  const styles = useStyles({});
  const commnStyles = useCommonStyles();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const [usersRankingData, setUserRankingData] = useState<UserRankingData[]>(
    [],
  );

  const { data: user } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const { activeRanking } = useSelector<AppState, RankingState>(
    ({ ranking }: AppState) => ranking,
  );

  const getAllUserRankingData = useCallback(async () => {
    if (user) {
      const response = await RankingSeasonService.getRankingTop5({
        filter: [
          {
            field: 'rankingPosition',
            operator: CondOperator.LOWER_THAN_EQUALS,
            value: 4,
          },
          {
            field: 'rankingSeasonId',
            operator: CondOperator.EQUALS,
            value: activeRanking?.id,
          },
        ],
        or: [
          {
            field: 'userId',
            operator: CondOperator.EQUALS,
            value: user.id,
          },
          {
            field: 'rankingSeasonId',
            operator: CondOperator.EQUALS,
            value: activeRanking?.id,
          },
        ],
        limit: 5,
      });
      if (response.data) {
        setUserRankingData(response.data);
      }
      setIsLoading(false);
    }
  }, [activeRanking, user]);

  useEffect(() => {
    if (user && user.id && isLoading) {
      getAllUserRankingData();
    }
  }, [getAllUserRankingData, isLoading, user]);

  const handleOpenUserRanking = () => {
    dispatch(openModal(UserRankingDialog, {}, 'lg'));
  };

  const RankingPosition = (props: any) => {
    if (!props.rankingPosition) return <></>;
    let pos = Number(props.rankingPosition);
    switch (pos) {
      case 1:
        return <FirstPosSVG />;
      case 2:
        return <SecondPosSVG />;
      case 3:
        return <ThirdPosSVG />;
      default:
        return <>{pos}</>;
    }
  };

  return (
    <Paper component={Grid} className={styles.paperTable} elevation={0}>
      <Grid container item xs>
        <Grid item xs>
          <Typography variant="h4" color="textPrimary">
            Ranking de indicações
          </Typography>
        </Grid>
        <Grid item xs style={{ textAlign: 'right' }}>
          <Button
            id="btn-cancel"
            color="primary"
            onClick={handleOpenUserRanking}
          >
            Ver lista completa
          </Button>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <TableContainer>
          <Table aria-label="subscriptions list">
            <TableHead>
              <TableRow>
                <TableCell>Posição</TableCell>
                <TableCell>NOME</TableCell>
                <TableCell className={commnStyles.textAlignCenter}>
                  Pontução
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {usersRankingData && usersRankingData.length
                ? usersRankingData.map(referred => (
                    <TableRow key={referred.userId}>
                      <MyTableCell>
                        <RankingPosition
                          rankingPosition={referred?.rankingPosition}
                        />
                      </MyTableCell>
                      <MyTableCell>
                        {referred.userId === user?.id ? (
                          <b>{referred.name}</b>
                        ) : (
                          referred.name
                        )}
                      </MyTableCell>

                      <MyTableCell className={commnStyles.textAlignCenter}>
                        {referred.totalScore}
                      </MyTableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </Paper>
  );
};

export default RankingSeasonClient;
