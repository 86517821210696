import { CreateQueryParams, RequestQueryBuilder } from '@nestjsx/crud-request';
import { StaffPayload, UserUpdate } from 'types/generals';
import { api, getWithSaga } from './apiService';

export default class UserService {
  static createFormData(user: UserUpdate) {
    const formData = new FormData();
    formData.append('name', user.name);
    formData.append('email', user.email);
    formData.append('phone', user.phone);
    formData.append('identification', user.identification);
    formData.append('metadata', JSON.stringify(user.metadata || ''));
    if (user.photo?.length > 0) {
      user.photo.forEach(p => formData.append('photo', p));
    }
    return formData;
  }
  static async updateProfile(id: any, profile: any) {
    try {
      const formData = this.createFormData(profile);
      return await api.put(`users/${id}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    } catch (error) {
      return error.response;
    }
  }

  static async updateUserPremium(id: number, isPremium: boolean) {
    try {
      const response = await api.patch(`users/${id}/update-user-is-premium`, {
        isPremium,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  }

  static async setUserFixedTransport(id: number, transport: any) {
    try {
      return await api.post(`users/${id}/fixed-transport`, {
        transport,
      });
    } catch (error) {
      return error.response;
    }
  }

  static async resetUserFixedTransport(id: number) {
    try {
      return await api.post(`users/${id}/reset-fixed-transport`);
    } catch (error) {
      return error.response;
    }
  }

  static async getStaffs(query: CreateQueryParams) {
    try {
      const data = getWithSaga('staffs', query);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async getWalletTransactions(query?: CreateQueryParams) {
    try {
      const queryString = RequestQueryBuilder.create({
        ...query,
      }).query();

      let url = 'wallet-transactions';

      if (queryString) {
        url = `${url}?${queryString}`;
      }

      const { data } = await api.get(url);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async getWallet(query: CreateQueryParams) {
    try {
      const data = getWithSaga('user-wallet', query);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async saveStaff(staffData: StaffPayload) {
    try {
      const data = await api.post('staffs', staffData);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async updateStaff(userId: number, staffData: StaffPayload) {
    try {
      const data = await api.patch(`staffs/${userId}`, staffData);
      return data;
    } catch (error) {
      return error.response;
    }
  }
}
