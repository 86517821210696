import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

import AddressColumn from './AddressColumn';
import SubscriberColumn from './SubscriberColumn';
import { validationSchema, defaultValue, fixedTransports } from './utils';
import { useStyles } from './styles';
import { Container, SectionName, StyledPaper, UserNameTitle } from './styles';
import TagWrapper, { DEFINED_TAGS } from 'components/generals/tag';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import CircularLoading from 'components/generals/loading/CircularLoading';
import ClientFormActions from './ClientFormActions';
import { CHANGE_PLAN_SUCCESS } from 'utils/messages';
import { SignatureStatusTag } from 'components/generals/tag/StatusTag';
// import useStyles from 'components/generals/forms/register/styles';

const ClientForm: FC = () => {
  const styles = useStyles();
  const [isLoadingRequest, setIsLoadingRequest] = useState(false);

  const { data: signature } = useSelector<AppState, SingleSignatureState>(
    ({ oneSignature }: AppState) => oneSignature,
  );
  const { getValues, register, errors, reset, control } = useForm({
    validationSchema,
    defaultValues: defaultValue({
      ...signature?.user,
      defaultAddress: signature?.user?.address?.[0],
    }),
  });

  return (
    <Grid container>
      <CircularLoading isLoading={isLoadingRequest} title="Salvando dados" />
      <Grid container alignItems="center" justify="space-between">
        <UserNameTitle>{`${getValues('name')} - ${
          signature?.plan?.name
        }: ${formatColumnValue(
          FormatOptions.SIMPLE_DATE_WITH_TRACE,
          signature.renewDate,
        )} - ${signature.paymentType}`}</UserNameTitle>
        <Grid item xs={2} xl={1}>
          <ClientFormActions
            setIsLoadingRequest={setIsLoadingRequest}
            isLoadingRequest={isLoadingRequest}
          />
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        direction="row"
        item
        xs={4}
        className={styles.gridSignatureStatus}
      >
        <Grid item xs>
          <Typography variant="h4" className={styles.signatureStatusText}>
            Status da assinatura:{' '}
            <SignatureStatusTag status={signature.status} />
          </Typography>
        </Grid>
      </Grid>
      <StyledPaper elevation={0}>
        <Grid container alignItems="center" justify="space-between">
          <SectionName>Dados do Cliente</SectionName>
          {signature.isDonation ? (
            <Grid item xs>
              <TagWrapper config={DEFINED_TAGS.INFO} value={'Doação'} />
            </Grid>
          ) : null}
        </Grid>
        <Container container spacing={2}>
          <SubscriberColumn
            errors={errors}
            register={register}
            control={control}
          />
          <AddressColumn
            control={control}
            reset={reset}
            errors={errors}
            register={register}
          />

          <Grid
            item
            container
            justify={signature.nextSignaturePlan ? 'space-between' : 'flex-end'}
            xs={12}
            spacing={3}
            style={{ marginTop: 10 }}
          >
            {signature.nextSignaturePlan ? (
              <Grid item>
                <TagWrapper
                  config={DEFINED_TAGS.MESSAGE}
                  value={CHANGE_PLAN_SUCCESS(
                    signature.nextSignaturePlan?.newPlanName,
                    formatColumnValue(
                      FormatOptions.SIMPLE_DATE_WITH_TRACE,
                      signature.nextSignaturePlan?.startDate,
                    ),
                  )}
                  fullwidth
                />
              </Grid>
            ) : null}
            {/* <Grid item>
              <Button
                id="save-box"
                disableElevation
                onClick={onSubmit}
                variant="contained"
                color="primary"
                className={clsx(styles.buttonLabel, styles.buttonWrapper)}
              >
                Salvar Alterações
              </Button>
            </Grid> */}
          </Grid>
        </Container>

        {/* <SectionName>Dados do Pagamento</SectionName>
          <PaymentForm errors={errors} control={control} /> */}
      </StyledPaper>
    </Grid>
  );
};

export default ClientForm;
