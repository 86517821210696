import { action } from 'typesafe-actions';
import { PlanPayload, PlanTypes } from '../types';

export const loadOnePlan = () => {
  return action(PlanTypes.LOAD_PLAN_LIST_REQUEST);
};

export const loadOnePlanSuccess = (payload: PlanPayload) => {
  return action(PlanTypes.LOAD_PLAN_SUCCESS, payload);
};

export const loadOnePlanFail = () => {
  return action(PlanTypes.LOAD_PLAN_FAILURE);
};

export const loadOnePlanByHash = (hashPlan: string) => {
  return action(PlanTypes.LOAD_ONE_PLAN, hashPlan);
};

/**
 * @description Util para situações de link com action que aponta direto para um
 * plano, com isso é possivel atender a solicitação do admin de passar um link
 * com um plano especifico e não quebrar o fluxo comum da troca de plano
 */
export const clearOnePlanState = () => {
  return action(PlanTypes.CLEAR_ONE_PLAN);
};
