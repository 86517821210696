import { action } from 'typesafe-actions';
import { EnhancedProductTypes } from './types';
import { CreateQueryParams } from '@nestjsx/crud-request';

export const loadEnhancedProductsRequest = (query: CreateQueryParams) => {
  return action(EnhancedProductTypes.LOAD_REQUEST, { query });
};

export const loadEnhancedProductsOpenRequest = (query: CreateQueryParams) => {
  return action(EnhancedProductTypes.LOAD_OPEN_REQUEST, { query });
};
export const loadEnhancedProductsSuccess = (payload: any) => {
  return action(EnhancedProductTypes.LOAD_REQUEST_SUCCESS, payload);
};

export const loadEnhancedProductsFail = () => {
  return action(EnhancedProductTypes.LOAD_REQUEST_ERROR);
};
