import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';

import { loadCurrentBox } from 'store/ducks/client/home/actions';
import { useStyles } from './styles';
import { CardEnhancedProductProps } from './types';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';

const CardEnhancedProduct: FC<CardEnhancedProductProps> = ({
  enhancedProduct,
  action,
  btnText,
  showDuration,
  submitStatus,
  submitedText = 'Turbinado',
  disabled,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const loadMonthBox = useCallback(() => {
    dispatch(loadCurrentBox());
  }, [dispatch]);

  useEffect(() => {
    loadMonthBox();
  }, [loadMonthBox]);

  const handleFormatDate = useCallback((date: string) => {
    if (!date) return '';
    return formatColumnValue(FormatOptions.DATE, date);
  }, []);

  const handlePrice = useCallback((price: number) => {
    return formatColumnValue(FormatOptions.MONEY, price);
  }, []);

  return (
    <Paper className={classes.paperTable} elevation={0}>
      <Grid container justify="center">
        <Grid container justify="center">
          <Card elevation={0}>
            <Grid
              container
              justify="space-between"
              spacing={2}
              direction="column"
            >
              <Grid item xs className={classes.selfCenter}>
                {enhancedProduct.product?.images ? (
                  <CardMedia
                    className={classes.cardImg}
                    image={enhancedProduct.product?.images[0]?.image}
                    component="img"
                    alt={`Imagem do box ${enhancedProduct.product.name}`}
                  />
                ) : null}
              </Grid>
              <Grid item xs>
                <CardContent className={classes.content}>
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    className={classes.boldText}
                  >
                    {enhancedProduct.product.name}
                  </Typography>
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    className={classes.boldText}
                  >
                    <b> Por: {handlePrice(enhancedProduct.price)}</b>
                  </Typography>
                  {showDuration ? (
                    <Typography
                      color="textPrimary"
                      className={classes.boxDescription}
                    >
                      Só ate {handleFormatDate(enhancedProduct.endDate)} para
                      garantir.
                    </Typography>
                  ) : (
                    ''
                  )}
                </CardContent>

                <Grid container justify="space-between">
                  <Grid item xs={12}>
                    {submitStatus === 'success' || disabled ? (
                      <Button
                        type="submit"
                        variant="contained"
                        color="secondary"
                        disabled
                        style={{ width: '100%' }}
                      >
                        {submitedText}
                      </Button>
                    ) : (
                      <Button
                        type="submit"
                        variant="contained"
                        onClick={() => action(enhancedProduct.id)}
                        className={classes.actiontButton}
                      >
                        {btnText}
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardEnhancedProduct;
