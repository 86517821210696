import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  Divider,
  Button,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { first } from 'lodash';

import { useStyles } from './styles';

import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { loadDefaultAddress } from 'store/ducks/generals/address/actions';
import { loadPlansToReactivate } from 'store/ducks/generals/plan/actions';
import { PlanState, OnePlanState } from 'store/ducks/generals/plan/types';
import { UserState } from 'store/ducks/user/types';

import PlanInfoCard from './PlanInfoCard';

import { ChangePlanProps, PlanPages } from '../types';
import CircularLoading from 'components/generals/loading/CircularLoading';

const PlanStep: React.FC<ChangePlanProps> = ({ page }) => {
  const style = useStyles();
  const dispatch = useDispatch();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('xs'));

  const [selectedPlan, setSelectedPlan] = useState();

  const { data: user, isLoading: isLoadingUser } = useSelector<
    AppState,
    UserState
  >(({ user }: AppState) => user);

  const { data: signature, isLoading: isLoadingSignature } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  const { data: plans, isLoading: isLoadingPlans } = useSelector<
    AppState,
    PlanState
  >(({ plans }: AppState) => plans);

  const { data: plan, isLoading: isLoadingPlan } = useSelector<
    AppState,
    OnePlanState
  >(({ onePlan }: AppState) => onePlan);

  const loadAddress = useCallback(() => {
    if (!isLoadingUser && user) {
      dispatch(loadDefaultAddress(user.id));
    }
  }, [dispatch, user, isLoadingUser]);

  const loadAvailablePlans = useCallback(() => {
    if (!isLoadingSignature) {
      dispatch(loadPlansToReactivate(page));
    }
  }, [dispatch, isLoadingSignature, page]);

  useEffect(() => {
    loadAddress();
    loadAvailablePlans();
  }, [loadAddress, loadAvailablePlans]);

  useEffect(() => {
    let defaultPlan = first(plans);
    if (plan.id) defaultPlan = plan;
    setSelectedPlan(defaultPlan);
  }, [plans, plan, isLoadingPlan]);

  return (
    <>
      {(!isLoadingPlan || !isLoadingPlans) &&
      !isLoadingSignature &&
      selectedPlan ? (
        <Grid container spacing={3} justify="space-around">
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {page === PlanPages.REACTIVATE_PAGE ? (
            <Grid item xs={12}>
              <Typography variant="h3">Planos</Typography>
            </Grid>
          ) : null}

          <Grid container item xs={12}>
            {!smallSize ? (
              <Grid item xs={12} md={5}>
                <Typography className={style.changePlanText} variant="h2">
                  {page === PlanPages.REACTIVATE_PAGE
                    ? 'Escolha o plano que deseja reativar:'
                    : 'Complete a sua nova assinatura'}
                </Typography>
              </Grid>
            ) : null}
            {page === PlanPages.REACTIVATE_PAGE ? (
              <>
                <Grid
                  container
                  item
                  xs={12}
                  md={7}
                  alignItems="center"
                  justify={smallSize ? 'space-between' : 'flex-start'}
                  spacing={1}
                  className={style.mBottom}
                >
                  {plans.length
                    ? plans.map(plan => (
                        <Grid key={plan.id} item xs={6} sm={2}>
                          <Button
                            fullWidth
                            className={
                              plan.recurrence.months ===
                              selectedPlan.recurrence.months
                                ? style.changePlanButtonActive
                                : style.changePlanButton
                            }
                            variant="outlined"
                            onClick={() => {
                              setSelectedPlan(plan);
                            }}
                          >
                            <Typography
                              className={
                                plan.recurrence.months ===
                                selectedPlan.recurrence.months
                                  ? style.changePLanButtonTextActive
                                  : style.changePLanButtonText
                              }
                            >
                              {plan.recurrence.name}
                            </Typography>
                          </Button>
                        </Grid>
                      ))
                    : null}
                </Grid>

                <PlanInfoCard
                  signature={signature}
                  plan={selectedPlan}
                  smallSize={smallSize}
                  page={page}
                />
              </>
            ) : (
              <Grid item xs={12} md={7}>
                <PlanInfoCard
                  signature={signature}
                  plan={selectedPlan}
                  smallSize={smallSize}
                  page={page}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      ) : (
        <CircularLoading
          isLoading={isLoadingPlan || isLoadingPlans}
          title={'Carregando'}
        />
      )}
    </>
  );
};

export default PlanStep;
