import React, { FC } from 'react';
import { createStyles, withStyles, Theme } from '@material-ui/core/styles';
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  InputBase,
  FormHelperText,
} from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import Colors from 'styles/colors';
import { Message } from 'react-hook-form';

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      border: `1px solid ${Colors.GRAY}`,
    },
    input: {
      display: 'flex',
      alignItems: 'flex-end',
      fontSize: '1.5em',
      border: 'none',
      padding: '1.15em',
      margin: '0.5em',
      paddingLeft: '5px',
    },
  }),
)(InputBase);

const ContractedBootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      borderRadius: 4,
      border: `1px solid ${Colors.GRAY}`,
    },
    input: {
      padding: '27px 12px 10px',
      fontSize: '16px',
    },
  }),
)(InputBase);

const MyLabel = withStyles(() =>
  createStyles({
    root: {
      transform: 'translate(3em, 30px)',
      fontSize: '1.75em',
    },
  }),
)(InputLabel);

const MyLabelContracted = withStyles(() =>
  createStyles({
    root: {
      fontSize: '1.4rem',
      marginRight: '60px',
      transform: 'translate(1em, 2rem)',
      whiteSpace: 'nowrap',
    },
  }),
)(InputLabel);

const MyMenuItem = withStyles(() =>
  createStyles({
    root: {
      fontSize: '1.5em',
    },
  }),
)(MenuItem);

const MyKeyboardArrowDownIcon = withStyles(() =>
  createStyles({
    root: {
      width: '2em',
      height: '2em',
      transform: 'translateX(-1em)',
    },
  }),
)(KeyboardArrowDownIcon);

const MyFormHelperText = withStyles(() =>
  createStyles({
    root: {
      color: Colors.RED,
    },
  }),
)(FormHelperText);

export interface SelectItem {
  value: any;
  title: string;
}

interface MySelectFormProps {
  title: string;
  itens: SelectItem[];
  onChange: any;
  onBlur?: any;
  value: any;
  error: any;
  helperText: Message | string | null;
  className?: string;
  classFormControl?: string;
  disabled?: boolean;
  contracted?: boolean;
  multiple?: boolean;
  menuProps?: any;
}

const MySelectForm: FC<MySelectFormProps> = props => {
  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={props.classFormControl}
    >
      {props.contracted ? (
        <MyLabelContracted htmlFor="outlined-age-native-simple">
          {props.title}
        </MyLabelContracted>
      ) : (
        <MyLabel htmlFor="outlined-age-native-simple">{props.title}</MyLabel>
      )}
      <Select
        disabled={!!props.disabled}
        className={props.className ? props.className : ''}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        inputProps={{
          name: props.title,
          id: 'outlined-age-native-simple',
        }}
        input={
          !props.contracted ? <BootstrapInput /> : <ContractedBootstrapInput />
        }
        multiple={props.multiple}
        IconComponent={props => <MyKeyboardArrowDownIcon {...props} />}
        onChange={props.onChange}
        value={props.value}
        error={props.error}
        MenuProps={props.menuProps}
      >
        <MenuItem value="" style={{ backgroundColor: Colors.LIGHTER_GRAY }} />
        {props.itens &&
          props.itens.map(item => (
            <MyMenuItem key={item.value} value={item.value}>
              {item.title}
            </MyMenuItem>
          ))}
      </Select>
      <MyFormHelperText>{props.helperText}</MyFormHelperText>
    </FormControl>
  );
};

export default MySelectForm;
