import React from 'react';
import {
  Grid,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';

import { closeModal, openModal } from 'store/ducks/nav/actions';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';

import { UpdateInvoiceOrderInShippingKeyDialogProps } from './types';
import useStyles from 'components/generals/forms/register/styles';
import { BaseDialogProps, FeedbackDialogPropsBase } from '../dialogTypes';
import ConfirmationDialog from '../ConfirmationDialog';
import { validationSchema } from './utils';
import {
  notifyError,
  disableButton,
  enableButton,
} from 'store/ducks/notification/actions';
import { StatusCodes } from 'http-status-codes';
import ShippingService from 'services/shippingService';
import {
  INVOICE_UPDATE_ACCESS_KEY_SUCCESS,
  INVOICE_UPDATE_ACCESS_KEY_ERROR,
  INVOICE_UPDATE_ACCESS_KEY_ASK,
} from 'utils/messages/invoice';
import FeedbackDialog from '../FeedbackDialog';

import { ReactComponent as SuccessIcon } from 'assets/img/svg/icon_active_sucess.svg';

const UpdateInvoiceKeyOrderInShippingDialog: React.FC<UpdateInvoiceOrderInShippingKeyDialogProps> = ({
  shippingId,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const { errors, register, handleSubmit } = useForm({
    validationSchema,
  });

  const handleOpenConfirmDialog = (data: any) => {
    const modalProps: BaseDialogProps = {
      title: 'Atualizando chaves',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: INVOICE_UPDATE_ACCESS_KEY_ASK,
      actionFn: () => onSubmit(data),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const onSubmit = async (data: any) => {
    try {
      dispatch(disableButton());
      const response = await ShippingService.updateKeyAccessInvoices(
        shippingId,
        data.serie,
      );

      if (response.status === StatusCodes.CREATED) {
        const modalProps: FeedbackDialogPropsBase = {
          message: INVOICE_UPDATE_ACCESS_KEY_SUCCESS,
          title: `Chaves adicionadas.`,
          icon: SuccessIcon,
        };

        dispatch(openModal(FeedbackDialog, modalProps));
      } else {
        throw new Error(INVOICE_UPDATE_ACCESS_KEY_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle>
        <Grid container justify="space-between">
          <Typography variant="h1">Atualizar chave de acesso</Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <MyOutlinedTextField
              id="invoice-serie"
              name="serie"
              label="Série da nota fiscal"
              fullWidth
              error={Boolean(errors.serie)}
              helperText={errors.serie ? errors.serie.message : null}
              inputRef={register}
            />
          </Grid>

          <Grid container item justify="flex-end">
            <Button
              className={clsx(styles.buttonLabel, styles.buttonWrapper)}
              variant="contained"
              color="primary"
              onClick={handleSubmit(handleOpenConfirmDialog)}
            >
              Confirmar
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Grid>
  );
};

export default UpdateInvoiceKeyOrderInShippingDialog;
