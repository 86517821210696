import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import { StatusCodes } from 'http-status-codes';
import { CondOperator, CreateQueryParams } from '@nestjsx/crud-request';

import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyTableCell from 'components/generals/table/MyTableCell';

import useDebounce from 'hooks/debounce';
import { useFilters } from 'hooks/filters';
import { AppState } from 'store';
import {
  actUpdatePageTitle,
  closeModal,
  openModal,
} from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { listStoreRequest, loadStoreFile } from 'store/ducks/store/actions';
import { StoreState } from 'store/ducks/store/types';
import { formatColumnValue } from 'helpers/formatters';

import { useStyles } from './styles';
import { listFilters, STORE_PRODUCT_STATUS, STORE_TYPES } from './utils';
import { FormatOptions } from 'types';
import { StoreProduct } from 'types/generals';
import StoreService from 'services/storeService';
import MySelectFilter from 'components/generals/input/MySelectFilter';
import Colors from 'styles/colors';
import { ROOT_PATH } from 'services/fileservice';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { FileState } from 'store/ducks/generals/file/types';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import {
  REMOVE_PRODUCT_ERROR,
  REMOVE_PRODUCT_MSG,
  REMOVE_PRODUCT_SUCCESS,
} from 'utils/messages/product';

const StoreProducts: FC = () => {
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const [searchFilter, setSearchFilter] = useState('');
  const [queryParams, setQueryParams] = useState<CreateQueryParams>({});
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();

  const { data: storeProducts = [], total, isLoading, hasError } = useSelector<
    AppState,
    StoreState
  >(({ storeReducer }: AppState) => storeReducer);

  const { hasError: hasErrorFile } = useSelector<AppState, FileState>(
    ({ file }: AppState) => file,
  );

  const {
    sort,
    page,
    pageFilters,
    filterFields,
    handleSort,
    handleSetValuePage,
    handleClearPageFilter,
    handleUpdateFilters,
  } = useFilters(listFilters);

  const loadProducts = useCallback(async () => {
    dispatch(
      listStoreRequest({
        join: [['categories']],
        sort,
        page: page + 1,
        limit: rowsPerPage,
        search: {
          $and: [...filterFields],
        },
        ...queryParams,
      }),
    );
  }, [dispatch, sort, page, rowsPerPage, queryParams, filterFields]);

  const loadProductsXls = useCallback(() => {
    dispatch(
      loadStoreFile(
        {
          // join: [['plans']],
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [...filterFields],
          },
          ...queryParams,
        },
        ROOT_PATH.STORE,
      ),
    );
  }, [dispatch, page, sort, rowsPerPage, queryParams, filterFields]);

  const handleFormatMoney = useCallback(
    (price: string) => formatColumnValue(FormatOptions.MONEY, price),
    [],
  );

  useEffect(() => {
    dispatch(actUpdatePageTitle('Produtos loja'));
  }, [dispatch]);

  useEffect(() => {
    loadProducts();
  }, [loadProducts]);

  const handleSearch = () => {
    const filter = {
      search: {
        $or: [
          { name: { [CondOperator.CONTAINS_LOW]: searchFilter } },
          { sku: { [CondOperator.CONTAINS]: searchFilter } },
        ],
      },
    };

    if (filter) setQueryParams(filter);
  };

  const debouncedSearch = useDebounce(handleSearch);

  const handleDetailProduct = (product?: StoreProduct) => {
    history.push(
      `/admin/storeProducts/new/${product?.id || ''}`,
      product ? { product } : {},
    );
  };
  const handleOpenDeleteDialog = (product: StoreProduct) => {
    const modalProps: BaseDialogProps = {
      title: 'Excluir produto',
      message: REMOVE_PRODUCT_MSG(product?.name),
      actionFn: () => handleDeleteProduct(product),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleDeleteProduct = async (product: StoreProduct) => {
    try {
      const response = await StoreService.deleteStoreProduct(product.id);

      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(REMOVE_PRODUCT_SUCCESS));
        loadProducts();
        dispatch(closeModal());
      } else {
        throw new Error(REMOVE_PRODUCT_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  return (
    <Grid container direction="column" style={{ marginTop: 24 }}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 24 }}
      >
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          direction="row"
          style={{ marginBottom: 26 }}
          spacing={2}
        >
          <Grid item xs={3}>
            <MyInputFilter
              onKeyUp={() => debouncedSearch()}
              onChange={e => setSearchFilter(e.target.value)}
              value={searchFilter}
              placeholder={'Buscar'}
            />
          </Grid>
          <Grid item xs={2}>
            <MySelectFilter
              defaultOption={() => handleClearPageFilter('storeType')}
              menuItems={STORE_TYPES.map(option => ({
                title: option.title,
                action: () =>
                  handleUpdateFilters(
                    'storeType',
                    {
                      filterTitle: option.title,
                      value: option.value,
                    },
                    CondOperator.EQUALS,
                  ),
                className: classes.menuOption,
              }))}
              buttonProps={{
                style: {
                  borderColor: pageFilters['storeType'].value
                    ? Colors.RED
                    : Colors.WHITE,
                },
              }}
            >
              <Typography
                variant="h3"
                color="textPrimary"
                className={classes.selectOption}
              >
                {pageFilters['storeType'].filterTitle}
              </Typography>
            </MySelectFilter>
          </Grid>
          <Grid item xs={2}>
            <MySelectFilter
              defaultOption={() => handleClearPageFilter('isVisible')}
              menuItems={STORE_PRODUCT_STATUS.map(option => ({
                title: option.title,
                action: () =>
                  handleUpdateFilters(
                    'isVisible',
                    {
                      filterTitle: option.title,
                      value: option.value,
                    },
                    CondOperator.EQUALS,
                  ),
                className: classes.menuOption,
              }))}
              buttonProps={{
                style: {
                  borderColor: STORE_PRODUCT_STATUS.find(
                    status =>
                      status.title === pageFilters['isVisible'].filterTitle,
                  )
                    ? Colors.RED
                    : Colors.WHITE,
                },
              }}
            >
              <Typography
                variant="h3"
                color="textPrimary"
                className={classes.selectOption}
              >
                {pageFilters['isVisible'].filterTitle}
              </Typography>
            </MySelectFilter>
          </Grid>
          <Grid container item xs alignItems="center" justify="flex-end">
            <Grid item xs={4} xl={3}>
              <ActionsButton
                hasErrorFile={hasErrorFile}
                menuItems={[
                  {
                    title: 'Adicionar',
                    action: () => handleDetailProduct(),
                  },
                  {
                    title: 'Exportar planilha de produtos',
                    action: loadProductsXls,
                  },
                ]}
                buttonText="Ações"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container direction="column">
          <Paper className={classes.paperTable} elevation={0}>
            <TableContainer>
              <Table
                className={classes.table}
                aria-label="store products table"
              >
                <TableHead>
                  <TableRow>
                    <MySortableTableCell
                      onClick={() => handleSort('id')}
                      direction={sort.field === 'id' && sort.order}
                    >
                      Código
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('name')}
                      direction={sort.field === 'name' && sort.order}
                    >
                      Nome do produto
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('name')}
                      direction={sort.field === 'name' && sort.order}
                    >
                      SKU
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('description')}
                      direction={sort.field === 'description' && sort.order}
                    >
                      Descrição
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('storeType')}
                      direction={sort.field === 'storeType' && sort.order}
                    >
                      Tipo de loja
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('isVisible')}
                      direction={sort.field === 'isVisible' && sort.order}
                    >
                      Status
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('price')}
                      direction={sort.field === 'price' && sort.order}
                    >
                      Preço
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('stock')}
                      direction={sort.field === 'stock' && sort.order}
                    >
                      Estoque
                    </MySortableTableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(!isLoading &&
                    !hasError &&
                    storeProducts.map((product: StoreProduct) => (
                      <TableRow key={product.id}>
                        <MyTableCell
                          className={classes.clickable}
                          onClick={() => handleDetailProduct(product)}
                        >
                          {product.id}
                        </MyTableCell>
                        <MyTableCell>{product.name}</MyTableCell>
                        <MyTableCell>{product.sku || '-'}</MyTableCell>
                        <MyTableCell>{product.smallDescription}</MyTableCell>
                        <MyTableCell>{product.storeType}</MyTableCell>
                        <MyTableCell>
                          {product.isVisible ? 'Habilitado' : 'Desabilitado'}
                        </MyTableCell>
                        <MyTableCell>
                          {handleFormatMoney(product.price.toString())}
                        </MyTableCell>
                        <MyTableCell>{product.stock.toString()}</MyTableCell>

                        <TableCell>
                          <Grid container alignItems="center">
                            <Button
                              className={classes.circle}
                              onClick={() => handleOpenDeleteDialog(product)}
                            >
                              <DeleteOutlinedIcon className={classes.icon} />
                            </Button>
                          </Grid>
                        </TableCell>
                      </TableRow>
                    ))) ||
                    null}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container justify="flex-end">
              <TablePagination
                ActionsComponent={MyTablePaginationActions}
                component="div"
                count={total}
                page={page}
                labelRowsPerPage="Itens por página"
                onChangePage={(_event: unknown, newPage: number) =>
                  handleSetValuePage(newPage)
                }
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
                  handleSetValuePage(0);
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
export default StoreProducts;
