import React, { FC, useCallback, useEffect, useMemo } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dialog } from '@material-ui/core';

import Home from 'pages/client/home';
import Study from 'pages/client/study';
import Cards from 'pages/client/cards';
import NewCard from 'pages/client/cards/NewCard';
import Security from 'pages/client/security';
import UserOrderDetail from 'pages/client/orders/details';
import Profile from 'pages/client/profile';
import Orders from 'pages/client/orders';
import Signature from 'pages/client/signature';
import ChangePlan from 'pages/client/change-plan';
import Library from 'pages/client/library';
import VirtualStore from 'pages/client/store';
import ProductPage from 'pages/client/store/ProductPage';
import { AppState } from 'store';
import { UserState } from 'store/ducks/user/types';
import { loadUserSignature } from 'store/ducks/generals/signature/oneSignature/actions';
import { Modal } from 'store/ducks/nav/types';
import { closeModal } from 'store/ducks/nav/actions';
import { AuthState } from 'store/ducks/auth/types';
import GenerateBankslip from 'pages/client/generate-bankslip';
import { STORE_PATH, STORE_PRODUCT_PATH } from 'types/generals/storePaths';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import { loadActiveStoreSettings } from 'store/ducks/store/storeSettings/actions';
import { PlanPages } from 'pages/client/change-plan/types';
import RankingSeasonClient from 'pages/client/ranking';
import UserRanking from 'pages/client/ranking/userRanking';

const ClientAuthRoutes: FC = () => {
  const dispatch = useDispatch();
  const { data: userData } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );
  const { isAuthenticated } = useSelector<AppState, AuthState>(
    ({ auth }: AppState) => auth,
  );

  const { isMaintenance, isLoading } = useSelector<
    AppState,
    StoreSettingsState
  >(({ storeSettings }: AppState) => storeSettings);

  const { open, maxWidth, component: Component, props } = useSelector<
    AppState,
    Modal
  >(({ nav }: AppState) => nav.modal);

  const storePath = useMemo(() => {
    return STORE_PATH(isMaintenance);
  }, [isMaintenance]);

  const loadActiveStoreSettingsCB = useCallback(() => {
    dispatch(loadActiveStoreSettings());
  }, [dispatch]);

  useEffect(() => {
    if (userData) {
      dispatch(loadUserSignature(userData.id));
      window &&
        (window as any).Intercom('boot', {
          app_id: 'hvrwg5d4',
          email: userData.email,
          name: userData.name,
          user_id: userData.id,
        });
    }
  }, [dispatch, userData, isAuthenticated]);

  useEffect(() => {
    if (isLoading) {
      loadActiveStoreSettingsCB();
    }
  }, [isLoading, loadActiveStoreSettingsCB]);

  return (
    <>
      <Switch>
        <Route path="/home">
          <Home />
        </Route>
        <Route exact path="/download-bankslip/:hash">
          <GenerateBankslip />
        </Route>
        <Route exact path="/my-account/signature">
          <Signature />
        </Route>
        {/* // FIXME: Corrigir esse nome depois */}
        <Route exact path="/my-account/signature/reativar-assinatura">
          <ChangePlan page={PlanPages.REACTIVATE_PAGE} />
        </Route>
        <Route exact path="/my-account/signature/change-plan">
          <ChangePlan page={PlanPages.UPGRADE_PAGE} />
        </Route>
        <Route exact path="/my-account/cards">
          <Cards />
        </Route>
        <Route path="/my-account/cards/new">
          <NewCard />
        </Route>
        <Route path="/my-account/security">
          <Security />
        </Route>
        <Route path="/user-ranking">
          <UserRanking />
        </Route>
        <Route exact path="/my-account/orders">
          <Orders />
        </Route>
        <Route path="/my-account/orders/:id">
          <UserOrderDetail />
        </Route>
        <Route exact path="/my-account/profile">
          <Profile />
        </Route>
        <Route exact path="/library">
          <Library />
        </Route>
        <Route exact path={storePath}>
          <VirtualStore />
        </Route>
        {isMaintenance ? (
          <Route
            exact
            path="/store"
            component={() => {
              window.open(
                'http://clube.bibliotecacatolica.com.br/loja-manutencao',
                '_blank',
              );
              return <Redirect to={{ pathname: '/home' }} />;
            }}
          />
        ) : (
          ''
        )}
        <Route exact path={`${STORE_PRODUCT_PATH(isMaintenance)}/:id`}>
          <ProductPage />
        </Route>
        <Route exact path="/study">
          <Study />
        </Route>
        {/* component={() => {
            window.open(
              'https://www.bibliotecacatolica.com.br/area-de-formacao',
              '_blank',
            );
            return <Redirect to={{ pathname: '/home' }} />;
          }} */}
        <Route
          exact
          path="/login"
          component={() => <Redirect to={{ pathname: '/home' }} />}
        />
      </Switch>
      <Dialog
        fullWidth
        maxWidth={maxWidth}
        open={open}
        disableBackdropClick={props?.disableBackdropClick || false}
        onClose={() => dispatch(closeModal())}
      >
        {Component ? <Component {...props} /> : null}
      </Dialog>
    </>
  );
};

export default ClientAuthRoutes;
