import React, { useRef, useEffect, useState } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { Grid, Typography, Button } from '@material-ui/core';
import ReCAPTCHA from 'react-google-recaptcha';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import MASK, { UNMASK } from 'helpers/masks';
import useStyles from '../../styles';
import { UserStepProps, InputRef } from './types';
import { handleBlockPaste } from 'utils/functions/generals';
import OpenEndpointsService from 'services/openEndpointsService';
import { openModal } from 'store/ducks/nav/actions';
import { useDispatch } from 'react-redux';
import { FeedBackWithButtonDialogProps } from 'components/generals/dialog/dialogTypes';
import { ReactComponent as LogInIcon } from 'assets/img/svg/icon_log_in.svg';
import FeedBackWithButtonDialog from 'components/generals/dialog/FeedBackWithButtonDialog';
import {
  USER_ALREADY_CREATED_BUTTON_TITLE,
  USER_ALREADY_CREATED_MSG_1,
  USER_ALREADY_CREATED_MSG_2,
  USER_ALREADY_CREATED_MSG_3,
  USER_ALREADY_CREATED_TITLE,
  CREATE_USER_LEAD_ERROR,
  UPDATE_USER_LEAD_ERROR,
  USER_UNAUTHORIZED,
} from 'utils/messages/user';
import { StatusCodes } from 'http-status-codes';
import MyTerms from 'components/generals/terms';
import { useSignature } from 'contexts/signatureContext';
import clsx from 'clsx';
import { useQuery } from 'routes/AuthRoutes';
import { UserLead, UTM } from 'types/generals';

const UserStep = ({
  register,
  control,
  errors,
  setValue,
  handleSubmit,
  reset,
}: UserStepProps) => {
  const query = useQuery();
  const classes = useStyles();
  const dispatch = useDispatch();
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const referralCode = query.get('codigoIndicacao') || '';

  const {
    showInitialStep,
    setShowInitialStep,
    setShowFinishStep,
    planStepsDisabled,
    plan,
  } = useSignature();

  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const leadId = localStorage.getItem('leadId');

  const nameInput = useRef() as InputRef;
  const emailInput = useRef() as InputRef;
  const repeatEmailInput = useRef() as InputRef;
  const CPFOrCNPJInput = useRef() as InputRef;
  const phoneInput = useRef() as InputRef;
  const passwordInput = useRef() as InputRef;
  const repeatPasswordInput = useRef() as InputRef;
  const formStep = useRef() as InputRef;

  const handlePhoneChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    return unmasked;
  };

  const handleIdentificationChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    return unmasked;
  };

  const validateUser = async () => {
    const email = emailInput?.current.value;
    try {
      if (email) {
        await OpenEndpointsService.validateEmail(email);
      }
    } catch (error) {
      const { response }: any = error;
      const message = (
        <Grid>
          {USER_ALREADY_CREATED_MSG_1}
          <br />
          <b>{USER_ALREADY_CREATED_MSG_2} </b>
          {USER_ALREADY_CREATED_MSG_3}
          <br />
        </Grid>
      );
      const modalProps: FeedBackWithButtonDialogProps = {
        title: USER_ALREADY_CREATED_TITLE,
        message,
        icon: LogInIcon,
        link: '/login',
        buttonTitle: USER_ALREADY_CREATED_BUTTON_TITLE,
      };
      if (response?.status === StatusCodes.FORBIDDEN) {
        dispatch(openModal(FeedBackWithButtonDialog, modalProps));
      }
    }
  };

  const getUserLead = (data: Partial<UserLead>) => {
    const utm: UTM = {
      utmSource: query.get('utm_source'),
      utmMedium: query.get('utm_medium'),
      utmCampaign: query.get('utm_campaign'),
      utmContent: query.get('utm_content'),
      utmTerm: query.get('utm_term'),
      utmId: query.get('utm_id'),
    };

    return {
      name: data.name,
      email: data.email,
      phone: data.phone,
      plan: plan.hash,
      planName: plan.name,
      planRecurrence: plan.recurrence.name,
      utm,
    };
  };

  const handleCreateLead = async (data: Partial<UserLead>) => {
    try {
      setButtonDisabled(true);
      await handleVerifyRecaptcha();
      const response: any = await OpenEndpointsService.createLeadUser(
        getUserLead(data),
      );

      if (response.status === StatusCodes.CREATED) {
        setShowFinishStep(true);
        setShowInitialStep(false);
        setValue('formStep', 2);
        localStorage.setItem('leadId', response.data.id);
      } else {
        throw new Error(CREATE_USER_LEAD_ERROR);
      }

      return response;
    } catch (error) {
      console.log(error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const handleUpdateLead = async (leadId: number, data: Partial<UserLead>) => {
    try {
      setButtonDisabled(true);
      await handleVerifyRecaptcha();
      const response: any = await OpenEndpointsService.updateLeadUser(
        leadId,
        getUserLead(data),
      );
      if (response.status === StatusCodes.OK) {
        setShowFinishStep(true);
        setShowInitialStep(false);
        setValue('formStep', 2);
      } else {
        throw new Error(UPDATE_USER_LEAD_ERROR);
      }
    } catch (error) {
      console.log(error.message);
    } finally {
      setButtonDisabled(false);
    }
  };

  const handleSubmitUserLead = (data: Partial<UserLead>) => {
    if (leadId) {
      handleUpdateLead(Number(leadId), data);
    } else {
      handleCreateLead(data);
    }
  };

  const renderTextLead = () => {
    return leadId ? 'Salvar' : 'Começar';
  };

  const handleVerifyRecaptcha = async () => {
    try {
      const token = await recaptchaRef.current?.executeAsync();
      const response = await OpenEndpointsService.verifyUserRequest(token);
      const { success, score } = response.data;
      if (!success && score <= 0.5) {
        throw new Error(USER_UNAUTHORIZED);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    if (planStepsDisabled) {
      reset({
        formStep: 1,
        referralCode,
      });
    } else {
      reset({
        formStep: 2,
        referralCode,
      });
    }
  }, [setValue, planStepsDisabled, reset, referralCode]);

  return (
    <Grid
      container
      className={
        !showInitialStep ? classes.stepWrapperDisabled : classes.stepWrapper
      }
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.wrapperTitle}
      >
        <Typography className={classes.titleButton}>1</Typography>
        <Typography variant="h2" className={classes.title}>
          Dados
        </Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MyOutlinedTextField
            fullWidth
            name="name"
            label="Nome"
            type="text"
            inputProps={{ ref: nameInput, className: classes.input }}
            error={Boolean(errors.name)}
            helperText={errors.name ? errors.name.message : null}
            inputRef={register}
            InputLabelProps={{ className: classes.label }}
            isDisabled={!showInitialStep}
          />
        </Grid>
        <Grid item xs={12}>
          <MyOutlinedTextField
            fullWidth
            name="email"
            label="Email"
            type="email"
            inputProps={{
              ref: emailInput,
              style: { textTransform: 'lowercase' },
              className: classes.input,
            }}
            onBlur={validateUser}
            error={Boolean(errors.email)}
            helperText={errors.email ? errors.email.message : null}
            inputRef={register}
            InputLabelProps={{ className: classes.label }}
            isDisabled={!showInitialStep}
          />
        </Grid>
        <Grid item xs={12}>
          <MyOutlinedTextField
            fullWidth
            name="repeatEmail"
            label="Confirmar Email"
            type="email"
            inputProps={{
              ref: repeatEmailInput,
              style: { textTransform: 'lowercase' },
              className: classes.input,
            }}
            onPaste={e => handleBlockPaste(e)}
            error={Boolean(errors.repeatEmail)}
            helperText={errors.repeatEmail ? errors.repeatEmail.message : null}
            inputRef={register}
            InputLabelProps={{ className: classes.label }}
            isDisabled={!showInitialStep}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={InputMask}
            mask={MASK.CPF}
            name="identification"
            control={control}
            maskChar={null}
            onChange={handleIdentificationChange}
          >
            {() => (
              <MyOutlinedTextField
                fullWidth
                label="CPF"
                type="text"
                inputProps={{
                  ref: CPFOrCNPJInput,
                  inputMode: 'numeric',
                  className: classes.input,
                }}
                error={Boolean(errors.identification)}
                helperText={
                  errors.identification ? errors.identification.message : null
                }
                InputLabelProps={{ className: classes.label }}
                isDisabled={!showInitialStep}
              />
            )}
          </Controller>
        </Grid>
        <Grid item xs={12}>
          <Controller
            as={InputMask}
            mask={MASK.CELLPHONE}
            name="phone"
            control={control}
            maskChar={null}
            onChange={handlePhoneChange}
          >
            {() => (
              <MyOutlinedTextField
                fullWidth
                label="Telefone"
                type="text"
                inputProps={{
                  ref: phoneInput,
                  inputMode: 'numeric',
                  className: classes.input,
                }}
                error={Boolean(errors.phone)}
                helperText={errors.phone ? errors.phone.message : null}
                inputRef={register}
                InputLabelProps={{ className: classes.label }}
                isDisabled={!showInitialStep}
              />
            )}
          </Controller>
        </Grid>
        <Grid item xs={12}>
          <MyOutlinedTextField
            fullWidth
            name="password"
            label="Senha"
            type="password"
            inputProps={{ ref: passwordInput, className: classes.input }}
            error={Boolean(errors.password)}
            helperText={errors.password ? errors.password.message : null}
            inputRef={register}
            InputLabelProps={{ className: classes.label }}
            isDisabled={!showInitialStep}
          />
        </Grid>
        <Grid item xs={12}>
          <MyOutlinedTextField
            fullWidth
            name="repeatPassword"
            label="Confirmar senha"
            type="password"
            inputProps={{ ref: repeatPasswordInput, className: classes.input }}
            error={Boolean(errors.repeatPassword)}
            helperText={
              errors.repeatPassword ? errors.repeatPassword.message : null
            }
            onPaste={e => handleBlockPaste(e)}
            inputRef={register}
            className={classes.rootBottom}
            InputLabelProps={{ className: classes.label }}
            isDisabled={!showInitialStep}
          />
        </Grid>
        <Controller
          name="formStep"
          control={control}
          as={({ onChange, value }) => (
            <MyOutlinedTextField
              fullWidth
              name="formStep"
              label="step"
              type="number"
              inputProps={{
                ref: formStep,
                className: classes.input,
              }}
              onChange={onChange}
              value={value}
              error={Boolean(errors.formStep)}
              helperText={errors.formStep ? errors.formStep.message : null}
              onPaste={e => handleBlockPaste(e)}
              inputRef={register}
              style={{ display: 'none' }}
            />
          )}
        />

        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
          size="invisible"
        />

        {planStepsDisabled ? (
          <Grid item xs={12}>
            <Button
              variant={!showInitialStep ? 'text' : 'contained'}
              className={clsx(
                classes.buttonSubmitUserLead,
                !showInitialStep
                  ? classes.buttonSubmitUserLeadUpdate
                  : classes.buttonSubmitUserLeadInit,
              )}
              onClick={
                !showInitialStep
                  ? () => {
                      setShowInitialStep(true);
                      setShowFinishStep(false);
                      reset({
                        formStep: 1,
                      });
                    }
                  : handleSubmit(handleSubmitUserLead as any)
              }
              disabled={buttonDisabled}
              fullWidth
            >
              <Typography
                className={
                  !showInitialStep
                    ? classes.textSubmitUserLeadUpdate
                    : classes.textSubmitUserLeadInit
                }
              >
                {!showInitialStep ? 'Alterar' : renderTextLead()}
              </Typography>
            </Button>
          </Grid>
        ) : null}

        {showInitialStep && planStepsDisabled && !leadId ? (
          <Grid item xs={12}>
            <Typography className={classes.termsAndConditionsText}>
              Ao clicar em COMEÇAR, confirmo que li e concordo com os &nbsp;
              <MyTerms className={classes.termsAndConditionsDetail} />
            </Typography>
          </Grid>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default UserStep;
