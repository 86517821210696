import { makeStyles } from '@material-ui/core';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  radioWrapper: {
    border: `1px solid ${theme.palette.grey['500']}`,
    padding: '8px',
    borderRadius: 8,
    margin: '0 0 16px 0',
    '& .MuiTypography-root': {
      fontSize: 16,
      color: theme.palette.grey['800'],
      fontWeight: 500,
    },
  },
  labelsGroup: {
    maxHeight: '250px',
    flexWrap: 'nowrap',
    overflow: 'visible',
  },
  listSpacing: {
    margin: theme.spacing(2.8, 0),
  },
});
