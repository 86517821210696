import { Reducer } from 'redux';
import { BenefitsState, BenefitsTypes } from './types';

const INITIAL_STATE: BenefitsState = {
  data: [],
  hasError: false,
  isLoading: true,
  total: 0,
};

const reducer: Reducer<BenefitsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case BenefitsTypes.LOAD_BENEFITS_REQUEST:
      return { ...state, isLoading: true };
    case BenefitsTypes.LOAD_BENEFITS_ERROR:
      return { ...state, isLoading: false, hasError: true };
    case BenefitsTypes.LOAD_BENEFITS_SUCCESS:
      return {
        hasError: false,
        isLoading: false,
        data: action.payload.data,
        total: action.payload.total,
      };
    default:
      return state;
  }
};

export default reducer;
