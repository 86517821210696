import { UserLead } from 'types/generals';

export enum LeadsTypes {
  LOAD_REQUEST = '@leads/LOAD_LEADS_REQUEST',
  LOAD_SUCCESS = '@leads/LOAD_SUCCESS',
  LOAD_ERROR = '@leads/LOAD_ERROR',
  LOAD_LEAD_FILE = '@leads/LOAD_LEAD_FILE',
}

export interface LeadsState {
  readonly total: number;
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly data: UserLead[];
}
