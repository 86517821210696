import React, {
  FC,
  Fragment,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import clsx from 'clsx';
import { first } from 'lodash';

import { AppState } from 'store';
import { loadCreditCards } from 'store/ducks/payment/creditCard/actions';
import { CartState } from 'store/ducks/cart/types';
import { UserState } from 'store/ducks/user/types';
import { CreditCardState } from 'store/ducks/payment/creditCard/types';
import { notifyError } from 'store/ducks/notification/actions';
import {
  clearCartProducts,
  listCartProducts,
  setCartInstallments,
} from 'store/ducks/cart/actions';
import { openModal } from 'store/ducks/nav/actions';
import { loadAllAddresses } from 'store/ducks/generals/address/actions';
import { AddressState } from 'store/ducks/generals/address/types';
import { calculateInstallments } from 'store/ducks/cart/utils';

import MyTypography from 'components/generals/labels/MyTypography';
import PaymentTypeControl from 'components/client/PaymentTypeControl';
import TransportCompanyControl from 'components/client/TransportCompanyControl';
import { ItemsListDialogProps } from 'components/generals/dialog/ItemsListDialog/types';
import ItemsListDialog from 'components/generals/dialog/ItemsListDialog';
import { ItemLabelField } from 'components/generals/labels/ItemLabel/types';
import MySelectForm, {
  SelectItem,
} from 'components/generals/input/MySelectForm';
import FeedbackDialog from 'components/generals/dialog/FeedbackDialog';
import { FeedbackDialogPropsBase } from 'components/generals/dialog/dialogTypes';
import DetailHeader from '../../CartHeader';
import CartAmount from '../../CartAmount';
import Colors from 'styles/colors';
import { useStyles } from '../../styles';
import { formatAddress, reducedFormatAddress } from 'helpers/address';
import { ShopStepsProps } from '../../types';
import { validationSchema, defaultValues } from './utils';
import { CartPaymentStore } from 'types/client';
import StoreService from 'services/storeService';
import { Address, PAYMENT_TYPE, TRANSPORT_COMPANIES } from 'types/generals';
import {
  ORDER_SUBMIT_FAIL,
  ORDER_SUBMIT_SUCCESS,
  PRODUCT_STORE_NOT_STOCK,
  SELECT_ORDER_ADDRESS,
  ORDER_SUBMIT_SUCCESS_BANKSLIP,
  ADD_CARD_LABEL,
  CHANGE_PAYMENT_METHOD_LABEL,
} from 'utils/messages';
import { CreditCard } from 'types/CreditCard';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { SELECT_ORDER_CARD } from 'utils/messages/creditCard';
import {
  TERMS_CONDITIONS,
  PRIVATE_CONDITIONS,
} from 'utils/links/termsConditions';

import { ReactComponent as SuccessIcon } from 'assets/img/svg/icon_active_sucess.svg';
import { DataFreteTransportState } from 'store/ducks/dataFreteTransport/types';
import { handleRemoveCharacteres } from 'utils/functions/generals';

const CartPayment: FC<ShopStepsProps> = memo(
  ({ handleChangeStep, setOpened, handleVerifyRecaptcha }) => {
    const { control, errors, handleSubmit, setValue, watch } = useForm({
      validationSchema,
      defaultValues: defaultValues(),
    });
    const [addressOptionId, setAddressOptionId] = useState<number>();
    const [cardOptionId, setCardOptionId] = useState<number>();
    const [buttonDisabled, setButtonDisabled] = useState(false);
    const [installmentsOptions, setInstallmentsOptions] = useState<
      SelectItem[]
    >();
    const chosenPaymentType = watch('paymentType');
    const chosenTransport = watch('transportCompany');

    const dispatch = useDispatch();
    const history = useHistory();
    const styles = useStyles();

    const {
      data: cartItems,
      cartAmount,
      cartProducts: cartProductDetails,
      installments,
      freight,
      discount,
      scoreData,
      cartCoupon,
      subTotal: subtotal,
    } = useSelector<AppState, CartState>(
      ({ cartReducer }: AppState) => cartReducer,
    );

    const { data: user, isLoading: isLoadingUser } = useSelector<
      AppState,
      UserState
    >(({ user }: AppState) => user);

    const { data: creditCards, isLoading: isLoadingCards } = useSelector<
      AppState,
      CreditCardState
    >(({ creditCard }: AppState) => creditCard);

    const { data: addresses } = useSelector<AppState, AddressState>(
      ({ address }: AppState) => address,
    );

    const { data: transportData } = useSelector<
      AppState,
      DataFreteTransportState
    >(({ dataFreteTransport }: AppState) => dataFreteTransport);

    const chosenAddress = useMemo(
      () =>
        addresses.find(address =>
          addressOptionId ? address.id === addressOptionId : address.isDefault,
        ),
      [addressOptionId, addresses],
    );

    const chosenCard = useMemo(
      () =>
        creditCards.find(card =>
          cardOptionId ? card.id === cardOptionId : card.isDefault,
        ),
      [creditCards, cardOptionId],
    );

    const loadCreditCard = useCallback(() => {
      dispatch(loadCreditCards());
    }, [dispatch]);

    const loadCartProducts = useCallback(() => {
      dispatch(listCartProducts());
    }, [dispatch]);

    const loadUserAddressses = useCallback(() => {
      dispatch(loadAllAddresses());
    }, [dispatch]);

    const formatInstallments = useCallback(() => {
      const installmentsCalc = calculateInstallments(cartAmount);
      const itemsInstallments = installmentsCalc.map(installment => {
        return {
          title: `${installment.installments}x de ${formatColumnValue(
            FormatOptions.MONEY,
            installment.installmentPrice,
          )}`,
          value: installment.installments,
        };
      });
      setInstallmentsOptions(itemsInstallments);
    }, [cartAmount]);

    useEffect(() => {
      loadCartProducts();
      loadCreditCard();
      loadUserAddressses();
    }, [loadCartProducts, loadCreditCard, loadUserAddressses]);

    useEffect(() => {
      dispatch(
        setCartInstallments({
          installment: watch('installments'),
        }),
      );
    }, [dispatch, watch]);

    useEffect(() => {
      formatInstallments();
    }, [cartAmount, formatInstallments]);

    const redirectAction = (route: string) => {
      resetSlider();
      history.push(route);
    };

    const resetSlider = () => {
      handleChangeStep(0);
      setOpened(false);
    };

    const getFinalDiscount = () => {
      let finalDiscount = Number(discount);
      finalDiscount = finalDiscount > subtotal ? subtotal : finalDiscount;
      return finalDiscount;
    };

    const handleSendOrderPayment = async () => {
      try {
        setButtonDisabled(true);
        const token = await handleVerifyRecaptcha();

        const cardId = chosenCard?.id;
        const addressId = chosenAddress?.id;
        const userId = user?.id;
        const products = cartItems.map(product => {
          return { id: product.id, amount: product.amount };
        });

        const logCotation = transportData.data.filter(
          t => t.nome_transportador === chosenTransport,
        );

        let transport = chosenTransport;

        const selectedTransport = chosenTransport.split(' ');

        const firstWord = first(selectedTransport);

        const firstTransport = first(logCotation);

        if (
          firstWord?.toLowerCase() ===
          TRANSPORT_COMPANIES.CORREIOS.toLowerCase()
        ) {
          transport = TRANSPORT_COMPANIES.CORREIOS;
        }

        const verifyStock = await StoreService.verifyStock(products);
        if (verifyStock.status === StatusCodes.BAD_REQUEST) {
          throw new Error(PRODUCT_STORE_NOT_STOCK);
        }

        if (userId && addressId) {
          const orderData: CartPaymentStore = {
            addressId,
            userId,
            cartAmount: Number(cartAmount.toFixed(2)),
            cartProductDetails,
            freight: Number(freight.toFixed(2)),
            discount: getFinalDiscount(),
            couponId: cartCoupon.id,
            transport,
            subtotal,
            paymentType: chosenPaymentType as PAYMENT_TYPE,
            logCotation: {
              requester: user?.email || '',
              data: logCotation,
              id_cotacao: transportData.id_cotacao,
              codigo_retorno: transportData.codigo_retorno,
            },
            transportIdentification: handleRemoveCharacteres(
              firstTransport?.cnpj_transportador,
            ),
            scoreData,
          };

          if (chosenPaymentType === PAYMENT_TYPE.CARD) {
            orderData.cardId = cardId;
            orderData.installments = installments;
          }

          if (token) {
            const requestStatus = await StoreService.submitStoreOrder(
              orderData,
            );

            if (requestStatus.status === StatusCodes.CREATED) {
              const modalProps: FeedbackDialogPropsBase = {
                title: 'Pedido efetuado com sucesso!',
                message:
                  chosenPaymentType === PAYMENT_TYPE.BANKSLIP
                    ? ORDER_SUBMIT_SUCCESS_BANKSLIP
                    : ORDER_SUBMIT_SUCCESS,
                icon: SuccessIcon,
              };
              dispatch(openModal(FeedbackDialog, modalProps));
              dispatch(clearCartProducts());
              redirectAction('/my-account/orders');
            }
          } else {
            throw new Error(ORDER_SUBMIT_FAIL);
          }
        }
      } catch (error) {
        dispatch(notifyError(error.message));
      } finally {
        setButtonDisabled(false);
      }
    };

    const itemsModal = (modalProps: ItemsListDialogProps) => {
      dispatch(openModal(ItemsListDialog, modalProps));
    };

    const handleAddressModal = () => {
      const items: ItemLabelField[] = addresses.map((address: Address) => {
        return {
          id: address.id,
          name: address.street,
          subtitle: reducedFormatAddress(address),
        };
      });

      const modalProps: ItemsListDialogProps = {
        items,
        title: 'Seus endereços',
        message: SELECT_ORDER_ADDRESS,
        redirectOption: {
          buttonText: 'Editar endereços',
          url: '/my-account/profile',
        },
        selected: chosenAddress?.id,
        changeFn: setAddressOptionId,
      };
      itemsModal(modalProps);
    };

    const handleCardsModal = () => {
      const items: ItemLabelField[] = creditCards.map((card: CreditCard) => {
        return {
          id: card.id,
          name: card.cardName,
          subtitle: formatColumnValue(FormatOptions.CREDIT_CARD, card.number),
        };
      });
      const modalProps: ItemsListDialogProps = {
        items,
        title: 'Seus cartões',
        message: SELECT_ORDER_CARD,
        redirectOption: {
          buttonText: 'Editar cartões',
          url: '/my-account/cards',
        },
        selected: chosenCard?.id,
        changeFn: setCardOptionId,
      };
      itemsModal(modalProps);
    };

    const ChangeCreditCardLabel = () => (
      <Button
        onClick={() => handleCardsModal()}
        className={styles.noPaddingButton}
      >
        <Typography variant="h4" style={{ color: Colors.RED, fontWeight: 500 }}>
          {CHANGE_PAYMENT_METHOD_LABEL}
        </Typography>
      </Button>
    );

    const AddCreditCardLabel = () => (
      <Button
        onClick={() => redirectAction('/my-account/cards/new')}
        className={styles.noPaddingButton}
      >
        <Typography variant="h4" style={{ color: Colors.RED, fontWeight: 500 }}>
          {ADD_CARD_LABEL}
        </Typography>
      </Button>
    );

    return (
      <Grid
        container
        item
        justify="space-between"
        direction="column"
        alignItems="center"
        style={{ padding: '0px 55px' }}
      >
        <Grid container direction="row" style={{ marginBottom: '30px' }}>
          <DetailHeader
            title="Pagamento"
            action={() => handleChangeStep(0)}
            step={2}
            totalSteps={2}
          />
        </Grid>
        <Grid container direction="column">
          <CartAmount
            subtotal={subtotal.toString()}
            total={cartAmount.toString()}
            discount={discount}
            freight={freight.toString()}
            scoreData={scoreData}
          />
        </Grid>
        <Divider className={styles.divider} />
        {!isLoadingUser && user && !isLoadingCards ? (
          <Fragment>
            <Grid
              container
              item
              justify="space-between"
              direction="column"
              spacing={3}
            >
              <Grid container item justify="flex-start">
                <Grid item xs>
                  <MyTypography variant="h3" className={styles.infoTitle}>
                    Forma de pagamento
                  </MyTypography>
                </Grid>
                <Grid item xs={12} className={styles.sectionSpacing}>
                  <PaymentTypeControl
                    control={control}
                    errors={errors}
                    cardName={chosenCard?.cardName}
                    controlField={'paymentType'}
                  />
                </Grid>
                <Grid container item xs justify="flex-end">
                  {chosenCard ? (
                    <ChangeCreditCardLabel />
                  ) : (
                    <AddCreditCardLabel />
                  )}
                </Grid>
              </Grid>
              {chosenPaymentType === PAYMENT_TYPE.CARD &&
              installmentsOptions ? (
                <Grid container item justify="flex-start">
                  <Controller
                    name="installments"
                    control={control}
                    as={({ onChange, onBlur, value }) => (
                      <MySelectForm
                        title="Em quantas vezes?"
                        contracted
                        itens={installmentsOptions.map(({ title, value }) => ({
                          title,
                          value,
                        }))}
                        onChange={onChange}
                        onBlur={onBlur}
                        value={value}
                        error={Boolean(errors.installments)}
                        helperText={null}
                      />
                    )}
                  />
                  {errors.installments && (
                    <Typography color="error">
                      {errors.installments.message}
                    </Typography>
                  )}
                </Grid>
              ) : null}
              <Divider className={styles.divider} />
              <Grid
                container
                item
                justify="flex-start"
                style={{ marginBottom: 12 }}
              >
                <MyTypography variant="h3" className={styles.infoTitle}>
                  Endereço de entrega
                </MyTypography>
                {chosenAddress ? (
                  <MyTypography
                    className={clsx(styles.infoBlock, styles.textContent)}
                  >
                    {formatAddress(chosenAddress)}
                  </MyTypography>
                ) : null}
                <Grid container item xs justify="flex-end">
                  <Button
                    onClick={() => handleAddressModal()}
                    className={styles.noPaddingButton}
                  >
                    <Typography
                      variant="h4"
                      style={{ color: Colors.RED, fontWeight: 500 }}
                    >
                      Alterar dados de entrega
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Divider className={styles.divider} />
            {chosenAddress ? (
              <Grid container item justify="flex-start">
                <Grid item xs>
                  <MyTypography variant="h3" className={styles.infoTitle}>
                    Forma de entrega
                  </MyTypography>
                </Grid>
                <Grid item xs={12} className={styles.sectionSpacing}>
                  <TransportCompanyControl
                    control={control}
                    errors={errors}
                    setValue={setValue}
                    controlField={'transportCompany'}
                    zipcode={chosenAddress.zipcode}
                    chosenTransport={chosenTransport}
                    subtotal={subtotal}
                    isPremium={user.isPremium}
                  />
                </Grid>
              </Grid>
            ) : null}
            <Grid item xs={12} className={styles.buttonSpacing}>
              <Button
                type="submit"
                variant="contained"
                disabled={buttonDisabled}
                className={styles.confirmButton}
                onClick={handleSubmit(handleSendOrderPayment)}
              >
                <Typography
                  variant="h3"
                  className={styles.confirmBtnText}
                  style={{ color: Colors.WHITE }}
                >
                  Concluir pedido
                </Typography>
              </Button>
            </Grid>
          </Fragment>
        ) : null}
        <Grid item style={{ textAlign: 'center' }}>
          <MyTypography className={styles.textContentBottom}>
            Ao clicar em concluir pedido, você concorda com os{' '}
            <Link
              className={styles.termsAndConditionsLink}
              to={{
                pathname: TERMS_CONDITIONS,
              }}
              target="_blank"
            >
              <Typography color="primary" className={styles.terms}>
                Termos e Condições{' '}
              </Typography>
            </Link>
            <Link
              className={styles.termsAndConditionsLink}
              to={{
                pathname: PRIVATE_CONDITIONS,
              }}
              target="_blank"
            >
              <Typography color="primary" className={styles.terms}>
                e a Política de Privacidade.
              </Typography>
            </Link>
          </MyTypography>
        </Grid>
      </Grid>
    );
  },
);

export default CartPayment;
