import { Reducer } from 'redux';
import { OneAddressState, AddressTypes } from './types';
import { Address } from 'types/generals';

const INITIAL_STATE: OneAddressState = {
  isLoading: true,
  hasError: false,
  data: {} as Address,
};

const reducer: Reducer<OneAddressState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AddressTypes.LOAD_DEFAULT_ADDRESS:
      return { ...state, isLoading: true };
    case AddressTypes.LOAD_DEFAULT_ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload.data,
      };
    case AddressTypes.LOAD_DEFAULT_ADDRESS_FAILURE:
      return { ...state, isLoading: false, hasError: true };
    default:
      return state;
  }
};

export default reducer;
