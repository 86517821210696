import React, { FC } from 'react';
import clsx from 'clsx';
import { useIconStyles } from './useStyles';
import { Check } from '@material-ui/icons';

interface MyStepperIconProps {
  active: boolean;
  completed: boolean;
}

const MyStepperIcon: FC<MyStepperIconProps> = ({ active, completed }) => {
  const styles = useIconStyles();

  return (
    <div
      className={clsx(styles.root, {
        [styles.activedCircle]: active,
      })}
    >
      {completed ? (
        <div className={styles.completed}>
          <Check className={styles.iconCompleted} />
        </div>
      ) : (
        <div className={styles.circle} />
      )}
    </div>
  );
};

export default MyStepperIcon;
