import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import {
  actUpdatePageTitle,
  closeModal,
  openModal,
} from 'store/ducks/nav/actions';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MyTableCell from 'components/generals/table/MyTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';

import { AppState } from 'store';
import { loadAllPlans, loadPlanFile } from 'store/ducks/generals/plan/actions';

import { useFilters } from 'hooks/filters';
import { PlanState } from 'store/ducks/generals/plan/types';
import { Plan, Video } from 'types/generals';
import { searchObject, VideoFilters } from './utils';
import { useStyles } from './styles';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { ROOT_PATH } from 'services/fileservice';
import { FileState } from 'store/ducks/generals/file/types';
import { VideoState } from 'store/ducks/generals/video/types';
import { loadAllVideos } from 'store/ducks/generals/video/actions';
import VideoService from 'services/videoService';
import { StatusCodes } from 'http-status-codes';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import {
  REMOVE_VIDEO_ERROR,
  REMOVE_VIDEO_MSG,
  REMOVE_VIDEO_SUCCESS,
} from 'utils/messages/video';

const Videos: FC = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const {
    sort,
    page,
    filterFields,
    genericFilterFields,
    handleGenericSearch,
    handleSort,
    handleSetValuePage,
  } = useFilters(VideoFilters);

  const { data: videos = [], isLoading, total, hasError } = useSelector<
    AppState,
    VideoState
  >(({ videos }: AppState) => videos);

  const { hasError: hasErrorFile } = useSelector<AppState, FileState>(
    ({ file }: AppState) => file,
  );
  const loadVideos = useCallback(() => {
    dispatch(
      loadAllVideos({
        sort,
        page: page + 1,
        limit: rowsPerPage,
        search: {
          $and: [...filterFields, { $or: [...genericFilterFields] }],
        },
      }),
    );
  }, [dispatch, sort, page, rowsPerPage, filterFields, genericFilterFields]);

  const redirectToVideo = (video: any /* Video */) =>
    history.push(`/admin/videos/${video.id}`, { video });

  useEffect(() => {
    loadVideos();
  }, [loadVideos]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Vídeos'));
  }, [dispatch]);

  const handleNewVideo = () => {
    history.push(`/admin/videos/new`);
  };

  const handleRemoveVideo = useCallback(
    (video: Video) => {
      const doRemoveVideo = async (videoId: number) => {
        const data = await VideoService.deleteVideo(videoId);

        if (data?.status === StatusCodes.OK) {
          dispatch(notifySuccess(REMOVE_VIDEO_SUCCESS));
          loadVideos();
          dispatch(closeModal());
        } else {
          dispatch(notifyError(REMOVE_VIDEO_ERROR));
        }
      };
      const dialogProps: BaseDialogProps = {
        actionFn: () => doRemoveVideo(video.id),
        title: 'Excluir vídeo',
        message: REMOVE_VIDEO_MSG(video.title),
      };

      dispatch(openModal(ConfirmationDialog, dialogProps));
    },
    [dispatch, loadVideos],
  );

  return (
    <Grid container className={styles.containerMargin}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={styles.headerMargin}
      >
        <Grid item xs={3} sm={3} md={3} className={styles.searchFilter}>
          <MyInputFilter
            onChange={(event: any) => handleGenericSearch(event, searchObject)}
            placeholder={'Buscar'}
          />
        </Grid>
        <Grid container item xs justify="flex-end" alignItems="flex-end">
          <Grid item xs={2} xl={2}>
            <ActionsButton
              hasErrorFile={hasErrorFile}
              menuItems={[
                {
                  title: 'Adicionar',
                  action: handleNewVideo,
                },
                /*  {
                  title: 'Exportar planilha de planos',
                  action: loadPlansXls,
                }, */
              ]}
              buttonText="Ações"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <Paper className={styles.paperTable} elevation={0}>
            <TableContainer>
              <Table className={styles.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <MySortableTableCell
                      onClick={() => handleSort('title')}
                      direction={sort.field === 'title' && sort.order}
                    >
                      Título
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('author')}
                      direction={sort.field === 'author' && sort.order}
                    >
                      Autor
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('url')}
                      direction={sort.field === 'url' && sort.order}
                    >
                      Link do vídeo
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('keyWord')}
                      direction={sort.field === 'keyWord' && sort.order}
                    >
                      Palavra-chave
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('isActive')}
                      direction={sort.field === 'isActive' && sort.order}
                    >
                      Status
                    </MySortableTableCell>
                    <TableCell>Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading && !hasError && videos
                    ? videos.map(video => (
                        <TableRow
                          key={video.id}
                          className={styles.clickableArea}
                        >
                          <MyTableCell onClick={() => redirectToVideo(video)}>
                            {video.title}
                          </MyTableCell>
                          <MyTableCell onClick={() => redirectToVideo(video)}>
                            {video.author}
                          </MyTableCell>
                          <MyTableCell onClick={() => redirectToVideo(video)}>
                            {video.url}
                          </MyTableCell>
                          <MyTableCell onClick={() => redirectToVideo(video)}>
                            {video.keyWord}
                          </MyTableCell>
                          <MyTableCell>
                            {video.isActive ? 'Ativo' : 'Inativo'}
                          </MyTableCell>
                          <TableCell>
                            <Grid container alignItems="center">
                              <Button
                                className={styles.circle}
                                onClick={() => handleRemoveVideo(video)}
                              >
                                <DeleteOutlinedIcon className={styles.icon} />
                              </Button>
                            </Grid>
                          </TableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
              <TablePagination
                ActionsComponent={MyTablePaginationActions}
                component="div"
                count={total}
                page={page}
                labelRowsPerPage="Itens por página"
                onChangePage={(_event: unknown, newPage: number) =>
                  handleSetValuePage(newPage)
                }
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
                  handleSetValuePage(0);
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Videos;
