import { makeStyles, createStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    formControl: {
      minWidth: 120,
    },
    option: {
      fontSize: '1.8rem',
    },
  }),
);
