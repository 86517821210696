import { CondOperator } from '@nestjsx/crud-request';
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { loadActiveRankingRequest } from 'store/ducks/ranking/actions';
import { RankingState } from 'store/ducks/ranking/types';
import { loadUserWalletRequest } from 'store/ducks/user/actions';
import { UserState } from 'store/ducks/user/types';
import { User } from 'types/generals';
import { RankingSeason, UserWallet } from 'types/generals/rankingSeason';

interface SubscriberConfigContextProps {
  activeRanking: RankingSeason | undefined;
  user: User | null;
  wallet: UserWallet | null;
}

const SubscriberConfigContext = createContext<SubscriberConfigContextProps>(
  {} as SubscriberConfigContextProps,
);

export const SubscriberConfigProvider: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const { activeRanking, isLoading: isLoadingRanking } = useSelector<
    AppState,
    RankingState
  >(({ ranking }: AppState) => ranking);

  const { data: user, wallet } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );
  const getUserWallet = useCallback(() => {
    if (user) {
      dispatch(
        loadUserWalletRequest({
          filter: [
            {
              field: 'userId',
              operator: CondOperator.EQUALS,
              value: user.id,
            },
          ],
        }),
      );
    }
  }, [dispatch, user]);

  const loadActiveRankingSeason = useCallback(() => {
    const currentDate = new Date().toISOString();
    dispatch(
      loadActiveRankingRequest({
        limit: 1,
        filter: [
          {
            field: 'isActive',
            operator: CondOperator.EQUALS,
            value: true,
          },
          {
            field: 'startDate',
            value: currentDate,
            operator: CondOperator.LOWER_THAN_EQUALS,
          },
          {
            field: 'endDate',
            value: currentDate,
            operator: CondOperator.GREATER_THAN_EQUALS,
          },
        ],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!activeRanking && isLoadingRanking) {
      loadActiveRankingSeason();
    }
  }, [activeRanking, isLoadingRanking, loadActiveRankingSeason]);

  useEffect(() => {
    if (user) {
      getUserWallet();
    }
  }, [activeRanking, getUserWallet, user]);
  return (
    <SubscriberConfigContext.Provider
      value={{
        activeRanking,
        user,
        wallet,
      }}
    >
      {children}
    </SubscriberConfigContext.Provider>
  );
};

export function useSubscriberConfig(): SubscriberConfigContextProps {
  const context = useContext(SubscriberConfigContext);
  return context;
}
