import React from 'react';
import {
  Grid,
  Divider,
  Typography,
  useTheme,
  useMediaQuery,
  Button,
} from '@material-ui/core';
import { CheckCircle, AccessTime } from '@material-ui/icons';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { AppState } from 'store';
import { ChangePlanState } from 'store/ducks/change-plan/types';
import { resetStepPlanPage } from 'store/ducks/change-plan/actions';
import { loadUserSignature } from 'store/ducks/generals/signature/oneSignature/actions';

import { FormatOptions } from 'types';
import { formatPaymentType, PAYMENT_TYPE } from 'types/generals';
import { formatColumnValue } from 'helpers/formatters';

import { useStyles } from './styles';
import { SuccessPageProps } from './types';
import { PlanPages } from '../types';

const SuccessStep: React.FC<SuccessPageProps> = ({ page }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const style = useStyles();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('xs'));

  const { signature, address, paymentType, plan } = useSelector<
    AppState,
    ChangePlanState
  >(({ changePlan }: AppState) => changePlan);

  const PLAN_CARD_PRICE = plan.prices?.cardPrice || 0;
  const PLAN_BANKSLIP_PRICE = plan.prices?.bankslipPrice || 0;
  const FRETE = 'R$ 15';

  const paymentTypeFormatted = (paymentType: string) => {
    switch (paymentType) {
      case PAYMENT_TYPE.BANKSLIP:
        return 'Boleto';
      case PAYMENT_TYPE.CARD:
        return 'Cartão';
      default:
        return '';
    }
  };

  const reloadSignature = () => {
    dispatch(loadUserSignature(signature.user.id));
  };

  const resetSteps = () => {
    dispatch(resetStepPlanPage());
  };

  const goTo = (route: string) => {
    reloadSignature();
    resetSteps();
    history.push(route);
  };

  const buttonGoToSignature = () => {
    return (
      <Button onClick={() => goTo('/my-account/signature')}>
        <Typography className={style.buttonText}>
          Ir para minha assinatura
        </Typography>
      </Button>
    );
  };

  return (
    <Grid
      container
      spacing={5}
      justify="center"
      alignItems="center"
      alignContent="center"
      className={style.mTop}
    >
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item>
        <AccessTime
          className={smallSize ? style.waitingIconMobile : style.waitingIcon}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography className={smallSize ? style.titleMobile : style.title}>
          {page === PlanPages.REACTIVATE_PAGE
            ? 'Sua reativação está sendo processada'
            : 'Mudança de plano confirmada'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography
          className={
            smallSize ? style.textInformationMobile : style.textInformation
          }
        >
          Você deverá receber um e-mail em alguns minutos confirmando que está
          tudo certo com sua assinatura
        </Typography>
      </Grid>

      <Grid
        container
        item
        direction={smallSize ? 'column' : 'row'}
        justify={smallSize ? 'center' : 'space-evenly'}
      >
        <Grid item className={style.mTop}>
          <Typography className={style.textInfoTitle}>
            Plano {plan.recurrence?.name || ''}
          </Typography>
          <Typography className={style.textInfoSubTitle}>
            {formatPaymentType(
              plan.recurrence?.months,
              paymentType,
              paymentType === PAYMENT_TYPE.CARD
                ? PLAN_CARD_PRICE
                : PLAN_BANKSLIP_PRICE,
            )}
          </Typography>
        </Grid>

        <Grid item className={style.mTop}>
          <Typography className={style.textInfoTitle}>Endereço</Typography>
          <Typography className={style.textInfoSubTitle}>
            {address.street || ''} {address.street || ''}
          </Typography>
          <Typography className={style.textInfoSubTitle}>
            {address.neighborhood || ''}
          </Typography>
          <Typography className={style.textInfoSubTitle}>
            CEP
            {address.zipcode
              ? formatColumnValue(FormatOptions.ZIPCODE, address.zipcode)
              : ''}
          </Typography>
          <Typography className={style.textInfoSubTitle}>
            {address.city || ''} - {address.state || ''}
          </Typography>
        </Grid>

        <Grid item className={style.mTop}>
          <Typography className={style.textInfoTitle}>
            Taxa de Entrega
          </Typography>
          <Typography className={style.textInfoSubTitle}>{FRETE}</Typography>
        </Grid>

        <Grid item className={style.mTop}>
          <Typography className={style.textInfoTitle}>
            Forma de pagamento
          </Typography>
          <Typography className={style.textInfoSubTitle}>
            {paymentTypeFormatted(paymentType)}
          </Typography>
        </Grid>
      </Grid>

      <Grid item>
        {page === PlanPages.UPGRADE_PAGE ? (
          buttonGoToSignature()
        ) : (
          <>
            {paymentType === PAYMENT_TYPE.CARD ? (
              buttonGoToSignature()
            ) : (
              <Button
                onClick={() => goTo('/my-account/orders')}
                variant="contained"
                className={style.buttonBankslip}
              >
                <Typography className={style.buttonBankslipText}>
                  Ver Boleto
                </Typography>
              </Button>
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default SuccessStep;
