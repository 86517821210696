import { Reducer } from 'redux';
import { ChangePlanState, ChangePlanTypes } from './types';
import { Address } from 'types';
import { CreditCard } from 'types/CreditCard';
import { Signature, Plan } from 'types/generals';

const INITIAL_STATE: ChangePlanState = {
  step: 0,
  totalSteps: 3,
  address: {} as Address,
  card: {} as CreditCard,
  signature: {} as Signature,
  plan: {} as Plan,
  paymentType: '',
};

const changePlanReducer: Reducer<ChangePlanState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ChangePlanTypes.LOAD_STEP_PLAN:
      return { ...state };
    case ChangePlanTypes.NEXT_STEP_PLAN:
      return { ...state, step: state.step + 1 };
    case ChangePlanTypes.PREVIOUS_STEP_PLAN:
      return { ...state, step: state.step - 1 };
    case ChangePlanTypes.RESET_STEP_PLAN:
      return { ...state, step: 0 };
    case ChangePlanTypes.SET_ADDRESS_PAYLOAD:
      return { ...state, address: action.payload };
    case ChangePlanTypes.SET_SIGNATURE_PAYLOAD:
      return { ...state, signature: action.payload };
    case ChangePlanTypes.SET_PLAN_PAYLOAD:
      return { ...state, plan: action.payload };
    case ChangePlanTypes.SET_PAYMENT_TYPE:
      return { ...state, paymentType: action.payload };
    default:
      return state;
  }
};

export default changePlanReducer;
