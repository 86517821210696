import { makeStyles, createStyles } from '@material-ui/core/styles';
import theme from 'styles/theme';

export const useOrdersStyles = makeStyles(() =>
  createStyles({
    alignEnd: {
      textAlign: 'end',
    },
    paperTitle: {
      marginBottom: 40,
    },
    paperTitleBold: {
      fontWeight: 'bold',
      marginLeft: 5,
    },
    mb24: {
      marginBottom: 24,
    },
    mt32: {
      marginTop: 32,
    },
    tableItem: {
      marginBottom: 16,
    },
    overflowCell: {
      overflow: 'inherit',
    },
    responsiveCell: {
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
      display: 'table-cell',
    },
  }),
);
