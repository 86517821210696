import { Signature } from 'types/generals';

export enum SignatureTypes {
  LOAD_SIGNATURE_REQUEST = '@signature/LOAD_SIGNATURE_REQUEST',
  LOAD_USER_SIGNATURE_REQUEST = '@signature/LOAD_USER_SIGNATURE_REQUEST',
  LOAD_SIGNATURE_LIST_REQUEST = '@signature/LOAD_SIGNATURE_LIST_REQUEST',
  LOAD_SIGNATURE_SUCCESS = '@signature/LOAD_SIGNATURE_SUCCESS',
  LOAD_SIGNATURE_LIST_SUCCESS = '@signature/LOAD_SIGNATURE_LIST_SUCCESS',
  LOAD_SIGNATURE_FAILURE = '@signature/LOAD_SIGNATURE_FAILURE',
  LOAD_SIGNATURE_LIST_FAILURE = '@signature/LOAD_SIGNATURE_LIST_FAILURE',
  CLEAR_SIGNATURE = '@signature/CLEAR_SIGNATURE',
  REQUEST_CANCEL_SIGNATURE = '@signature/REQUEST_CANCEL_SIGNATURE',
  REACTIVATE_SIGNATURE = '@signature/REACTIVATE_SIGNATURE',
  CANCELED_SIGNATURE = '@signature/CANCELED_SIGNATURE',
}

export interface SignaturePayload {
  data: Signature[] | Signature;
  total?: number;
}

export interface SignatureState {
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly total: number;
  readonly data: Signature[];
}

export interface SingleSignatureState
  extends Omit<SignatureState, 'data' | 'total'> {
  readonly data: Signature;
}
