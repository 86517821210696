import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  confirmButton: {
    backgroundColor: Colors.BRIGHT_DARK_GREEN,
    height: 44,
    width: 260,
    '&:hover': {
      backgroundColor: Colors.BRIGHT_DARK_GREEN,
    },
  },
  confirmButtonMobile: {
    backgroundColor: Colors.BRIGHT_DARK_GREEN,
    marginTop: 12,
    height: 44,
    width: '100%',
    '&:hover': {
      backgroundColor: Colors.BRIGHT_DARK_GREEN,
    },
  },
  confirmButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: Colors.WHITE,
  },
  backButton: {
    width: 140,
    height: 44,
  },
  backButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: Colors.ORANGE,
  },
  backButtonMobile: {
    marginTop: 12,
    marginBottom: 12,
    width: '100%',
    height: 44,
  },
  containerPadding: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  boldText: {
    fontWeight: 'bold',
  },
  paymentTypeGrid: {
    border: '1px solid',
    borderColor: Colors.GRAY,
    borderRadius: 8,
    padding: 20,
    marginBottom: 10,
  },
  selectText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.ORANGE,
  },
  buttonMargin: {
    marginBottom: 15,
  },
  buttonAddCardText: {
    fontSize: 13,
    fontWeight: 'bold',
  },
  addIcon: {
    color: Colors.ORANGE,
    fontSize: 16,
  },
  errorText: {
    color: Colors.LIGHT_RED,
  },
  buttonAddCard: {
    float: 'right',
    width: 170,
    backgroundColor: Colors.WHITE_GREEN,
    borderColor: Colors.BRIGHT_DARK_GREEN,
    marginTop: 12,
    marginBottom: 12,
    height: 44,
    color: Colors.BRIGHT_DARK_GREEN,
    '&:hover': {
      backgroundColor: Colors.WHITE_GREEN,
    },
  },
  buttonTextAddCard: {
    fontSize: 14,
    fontWeight: 'bold',
  },
  creditCardNotSelectedText: {
    marginTop: 10,
  },
});
