import { makeStyles } from '@material-ui/core';
import { Colors } from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  actionButton: {
    padding: 0,
    whiteSpace: 'nowrap',
  },
  capitalize: {
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  paperLastStatus: {
    margin: theme.spacing(0, 8),
    padding: theme.spacing(5),
    borderRadius: '8px',
    textAlign: 'center',
    fontSize: 14,
  },
  cardItem: {
    padding: theme.spacing(3, 4, 3, 3),
    borderRadius: '8px',
    textAlign: 'start',
  },
  divider: {
    margin: theme.spacing(5.8, 0, 4),
    width: '100%',
  },
  imgContent: {
    width: '100px',
    height: '96px',
    margin: '0 24px 0 0',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  orderInfoTitle: {
    fontWeight: 500,
    textTransform: 'capitalize',
    textAlign: 'left',
    color: Colors.GRAY,
    margin: theme.spacing(0, 0, 0.5),
  },
  mInfo: {
    margin: theme.spacing(0, 0, 3),
  },
  mLabel: {
    margin: theme.spacing(0, 3, 0.4, 0),
  },
  mLabelBold: {
    margin: theme.spacing(1, 3, 0.4, 0),
  },
  paperInfoTitle: {
    margin: theme.spacing(5, 0, 2),
    textTransform: 'capitalize',
    color: Colors.GRAY,
    fontWeight: 500,
  },
  infoTitle: {
    textTransform: 'capitalize',
    color: Colors.GRAY,
    fontWeight: 500,
    maxHeight: '100px',
    minWidth: '270px',
    overflowY: 'auto',
    marginBottom: theme.spacing(4),
  },
  trackingInfo: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: Colors.RED,
  },
  invoiceOrderInfo: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: Colors.DARKER_GRAY,
  },
  table: {
    minWidth: 650,
  },
  ordersList: {
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  paperTable: {
    padding: theme.spacing(3, 4),
    borderRadius: '2em',
  },
  gridAction: {
    margin: theme.spacing(0, 0, 2.5),
  },
  buttonXml: {
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 13,
  },
  filterOptionsList: {
    backgroundColor: Colors.WHITE,
  },
  tableCellBold: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  tableCellTypography: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  menuOption: {
    textTransform: 'capitalize',
  },
});
