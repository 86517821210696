export const getBreadcrumbsNames = (name: string) => {
  const breadcrumbsNames: Record<string, any> = {
    new: 'Novo',
    library: 'Minha Biblioteca',
    // store: 'Loja Virtual',
    store: 'Loja Premium',
    study: 'Área de Formação',
    cards: 'Cartões',
    signature: 'Assinatura',
    orders: 'Pedidos',
    profile: 'Perfil',
    security: 'Segurança',
    'my-account': 'Minha Conta',
    'change-plan': 'Planos de assinatura',
    product: 'Detalhes do Produto',
    reactivate: 'Reativar',
    upgrade: 'Upgrade',
    'download-bankslip': 'Download boleto',
    'user-ranking': 'Indique e ganhe',
  };

  return name in breadcrumbsNames ? breadcrumbsNames[name] : name;
};
