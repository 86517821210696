import { makeStyles } from '@material-ui/core';
import theme from 'styles/theme';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  messageAddress: {
    maxWidth: 260,
    fontWeight: 'bold',
  },
  addressContainer: {
    border: '1px solid',
    borderColor: Colors.GRAY,
    borderRadius: 8,
    padding: 20,
    marginBottom: 10,
  },
  selectText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.ORANGE,
  },
  backButton: {
    height: 48,
    width: 160,
  },
  backButtonMobile: {
    height: 48,
    width: '100%',
  },
  backButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: Colors.ORANGE,
  },
  nextButton: {
    width: 160,
    height: 48,
    backgroundColor: Colors.BRIGHT_DARK_GREEN,
    '&:hover': {
      backgroundColor: Colors.BRIGHT_DARK_GREEN,
    },
  },
  nextButtonMobile: {
    width: '100%',
    height: 48,
    backgroundColor: Colors.BRIGHT_DARK_GREEN,
    '&:hover': {
      backgroundColor: Colors.BRIGHT_DARK_GREEN,
    },
  },
  nextButtonText: {
    fontSize: 16,
    fontWeight: 'bold',
    color: Colors.WHITE,
  },
});
