import * as Yup from 'yup';

export const validationSchema = Yup.object({
  password: Yup.string().required('Campo Obrigatório'),
  repeatPassword: Yup.string()
    .required('Campo Obrigatório')
    .label('Confirme a senha')
    .test('password-match', 'As senhas devem ser iguais', function (value) {
      return this.parent.password === value;
    }),
});
