import { Grid, makeStyles, styled } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  sectionZone: {
    padding: '20px',
    borderWidth: '1px',
    borderRadius: '8px',
    borderColor: '#acacab',
    borderStyle: 'dashed',
    backgroundColor: 'rgba(242, 242, 242, 0.8)',
    outline: 'none',
    transition: 'border .24s ease-in-out',
  },
  dropArea: {
    width: '100%',
    padding: '65px',
    textAlign: 'center',
    cursor: 'pointer',
    '&:focus': {
      outline: 'none',
      backgroundColor: '#eaeaea',
    },
  },
  closeBadge: {
    top: '14%',
    backgroundColor: '#ed5240',
    cursor: 'pointer',
    right: '14%',
    transform: 'scale(1) translate(50%, -50%)',
    transformOrigin: '100% 0%',
    padding: '11px 3px',
  },
  boldNoWrapText: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  subText: {
    color: Colors.DARK_GRAY,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  previewArea: {
    maxHeight: '250px',
    overflow: 'auto',
  },
  thumb: (props: any) => ({
    display: 'block',
    maxHeight: '100px',
    minHeight: '110px',
    width: '90%',
    objectFit: 'cover',
    borderRadius: 16,
    border: props.isCover ? `4px solid ${Colors.LIGHT_GREEN}` : 'none',
  }),
});

export const ThumbGrid = styled(Grid)({
  display: 'inline-flex',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
});

export const ThumbInnerGrid = styled(Grid)({
  borderRadius: 16,
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden',
});
