import {
  isEqual,
  isAfter,
  startOfToday,
  isBefore,
  format,
  parseISO,
} from 'date-fns';
import { FeedBackModalNoAccessStoreProps } from 'components/generals/dialog/dialogTypes';
import {
  Order,
  ORDER_STATUS,
  Signature,
  SIGNATURE_STATUS,
  PAYMENT_TYPE,
  RECURRENCES_MONTHS,
} from 'types/generals';
import { separateDateWithTrace } from 'helpers/validateDate';

import {
  SUBSCRIBER_BANKSLIP_NO_ACCESS_STORE,
  SUBSCRIBER_CARD_NO_ACCESS_STORE,
  CHANGE_PLAN_STORE_ACCESS,
  SUBSCRIBER_INACTIVE_NO_ACCESS_STORE,
} from 'utils/messages';
import { cpf } from 'cpf-cnpj-validator';
import { IFreightConfig } from 'types/client';

export const OpenNewWindowLink = (link: string) => window.open(link, '_blank');
export const goToExternalLink = (link: string) => (window.location.href = link);

export const isAfterOrEqual = (dateOne: Date, dateTwo: Date) => {
  return isAfter(dateOne, dateTwo) || isEqual(dateOne, dateTwo);
};

export const handleBlockPaste = (e: any) => {
  e.preventDefault();
};

export const handleVerifyStatusOrderNotIsPaid = (order: Order) => {
  return (
    order.status === ORDER_STATUS.FAILED ||
    order.status === ORDER_STATUS.PENDING ||
    order.status === ORDER_STATUS.SUBMITTED
  );
};

export const handleSplitNumberFromTextCollection = (values: string[]) => {
  const regExp = new RegExp(/\d/g);

  return values.map(val => regExp.exec(val)?.pop());
};

export const getPropsToOpenModalNoAccessStore = (
  signature: Signature,
  order?: Order,
) => {
  if (
    signature.status === SIGNATURE_STATUS.INACTIVE &&
    isBefore(separateDateWithTrace(signature.renewDate), startOfToday()) &&
    !order?.id
  ) {
    const modalProps: FeedBackModalNoAccessStoreProps = {
      message: SUBSCRIBER_INACTIVE_NO_ACCESS_STORE,
      buttonTitle: 'Reativar assinatura',
      link: '/my-account/signature/reativar-assinatura',
    };

    return modalProps;
  }

  if (order) {
    if (
      handleVerifyStatusOrderNotIsPaid(order) &&
      signature.paymentType === PAYMENT_TYPE.CARD &&
      signature.plan.recurrence.months === RECURRENCES_MONTHS.MONTHLY
    ) {
      const modalProps: FeedBackModalNoAccessStoreProps = {
        message: SUBSCRIBER_CARD_NO_ACCESS_STORE,
        buttonTitle: 'Atualizar cartão',
        link: '/my-account/cards',
      };

      return modalProps;
    }

    if (
      handleVerifyStatusOrderNotIsPaid(order) &&
      signature.paymentType === PAYMENT_TYPE.BANKSLIP &&
      signature.plan.recurrence.months === RECURRENCES_MONTHS.MONTHLY
    ) {
      const modalProps: FeedBackModalNoAccessStoreProps = {
        message: SUBSCRIBER_BANKSLIP_NO_ACCESS_STORE,
        buttonTitle: 'Pagar boleto',
        link: '/my-account/signature/reativar-assinatura',
        isBankslipOrder: true,
        orderId: order.id,
      };

      return modalProps;
    }

    if (
      (signature.status === SIGNATURE_STATUS.CANCELED ||
        signature.status === SIGNATURE_STATUS.PENDING_CANCELED) &&
      order.status === ORDER_STATUS.CANCELED
    ) {
      const modalProps: FeedBackModalNoAccessStoreProps = {
        message: SUBSCRIBER_INACTIVE_NO_ACCESS_STORE,
        buttonTitle: 'Reativar assinatura',
        link: '/my-account/signature/change-plan',
      };
      return modalProps;
    }
  }

  const modalProps: FeedBackModalNoAccessStoreProps = {
    message: CHANGE_PLAN_STORE_ACCESS,
    buttonTitle: 'Fazer upgrade',
    link: '/my-account/signature/change-plan',
  };

  return modalProps;
};

export const getCurrentMonthOrder = (orders: Order[]): Order | undefined => {
  if (orders) {
    const month = format(new Date(), 'MM-yyyy');
    return orders.find(
      order => format(parseISO(order.paymentDate), 'MM-yyyy') === month,
    );
  }
  return undefined;
};

export const createAndDownloadFile = (file: string, fileName: string) => {
  const url = window.URL.createObjectURL(new Blob([file]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};

export const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};

/**
 * FIXME: quando for necessario colocar CNPJ da para agrupar aqui
 */
export const validateCpfOrCnpj = (value: string) => {
  return cpf.isValid(value);
};

/**
 * @description Centralização da validação do frete da loja virtual, o papel
 * dessa função é receber os paramentros relacionado a frete e retornar um valor
 * unico que será usado no componente que mostra transportadora e tbm no redux
 * responsavel por colocar o frete no carrinho
 * @param freight: Frete retornado pela integração
 * @param subTotal: Valor do carrinho
 * @param activeStoreSettings: Configuração da loja, nela podemos definir em que
 * momento o frete será zerado
 * @param isPremium: Usuários premium não tem frete na cobrança
 */
export const verifyIfApplyFreight = (
  freight: number,
  subTotal: number,
  productsDiscount: number,
  freightConfig: IFreightConfig,
  isPremium: boolean,
  isFirstTransport: boolean,
) => {
  if (isPremium && isFirstTransport) {
    return 0;
  }

  if (freightConfig && freightConfig.isActive && isFirstTransport) {
    const finalProductTotal = subTotal - productsDiscount;
    if (
      Number(finalProductTotal) >=
      Number(freightConfig.freeFreightFromThisValue)
    ) {
      return 0;
    }
  }

  return freight;
};

export const handleRemoveCharacteres = (transport?: string) => {
  if (!transport) return '';
  return transport.replace(/[^\d]+/g, '');
};
