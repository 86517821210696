import React from 'react';
import { Grid } from '@material-ui/core';

import ImagesZone from 'components/admin/ImagesZone/index';
import { BannerImageLocation, BannerType } from 'types/generals/banner';
import { IBannerImagesZone } from './types';
import { ImageZone } from 'components/admin/ImagesZone/types';

/**
 * Component responsible for uploading/managing images from banners
 *
 * @param bannerFiles - an array of files
 * @param bannerData  - an array of IBannerData
 * @param bannerType  - the type of banner used
 * @param handleUpdateBanner  - function responsible for updating the banner files
 * @param handleUpdateBannerData  - function responsible for updating the banner data
 * @returns
 */
const BannerImagesZone: React.FC<IBannerImagesZone> = ({
  bannerFiles,
  bannerData,
  bannerType,
  handleUpdateBanner,
  handleUpdateBannerData,
  errors,
  register,
  setValue,
  multiple = true,
}) => {
  const handleDropFile = (files: ImageZone[]) => {
    if (files?.length) {
      const _file = files[0];
      handleUpdateBanner([_file]);
      handleUpdateBannerData([
        {
          name: _file.name,
          location: BannerImageLocation.DESKTOP,
          id: (Math.random() * 100).toFixed(0),
          type: bannerType,
          order: bannerData.length + 1,
          deleted: false,
          imageLink: _file.image,
        },
      ]);
    }
  };

  const handleRemoveFromDropzone = (files: ImageZone[], fileIndex: number) => {
    if (!isNaN(fileIndex)) {
      handleUpdateBanner(files, true);
      const bannerIndex = bannerData.findIndex(b => b.id === `${fileIndex}`);
      if (!isNaN(bannerIndex)) {
        const _bannerData = bannerData;
        _bannerData.splice(bannerIndex, 1);
        handleUpdateBannerData(_bannerData, true);
      }
    }
  };

  return (
    <Grid item xs={12}>
      <ImagesZone
        showTitle={false}
        register={register}
        setValue={setValue}
        errors={errors}
        multiple={multiple}
        files={bannerFiles}
        handleDropFile={handleDropFile}
        handleRemoveFile={handleRemoveFromDropzone}
      />
    </Grid>
  );
};

export default BannerImagesZone;
