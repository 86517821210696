import { makeStyles } from '@material-ui/core';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  rootPanel: {
    padding: theme.spacing(5.5, 0),
    height: '100%',
  },
  rootPaper: {
    padding: theme.spacing(3, 4),
    borderRadius: '2em',
    height: '100%',
  },
  mobilePaper: {
    padding: 10,
    marginBottom: 20,
    borderRadius: 8,
  },
  pageTitle: {
    fontWeight: 'bold',
  },
});
