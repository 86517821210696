import React, { useState } from 'react';
import ColorPicker from 'react-pick-color';
import { Modal } from '@material-ui/core';
import Colors from 'styles/colors';

interface PickColorDialogProps {
  open: boolean;
  setModalIsOpen: (isOpen: boolean) => void;
}

const PickColorDialog: React.FC<PickColorDialogProps> = ({
  open,
  setModalIsOpen,
}) => {
  const [color, setColor] = useState(Colors.WHITE);
  const handleCloseModal = () => setModalIsOpen(false);

  return (
    <Modal
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
      open={open}
      onClose={handleCloseModal}
    >
      <ColorPicker color={color} onChange={color => setColor(color.hex)} />
    </Modal>
  );
};

export default PickColorDialog;
