import React, { useCallback } from 'react';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import { Link } from 'react-router-dom';

import MyTableCell from 'components/generals/table/MyTableCell';
import MyTooltip from 'components/generals/tolltip';

import { IFilesShipping } from 'types/generals';
import { FormatOptions } from 'types';
import { formatColumnValue } from 'helpers/formatters';
import { useStyles } from '../useStyles';

interface FilesTableProps {
  files: IFilesShipping[];
}

const FilesTable: React.FC<FilesTableProps> = ({ files }) => {
  const styles = useStyles();

  const handleFormatDateTime = useCallback((date: string) => {
    return formatColumnValue(FormatOptions.DATETIME, date);
  }, []);

  return (
    <>
      <Grid item container alignItems="center" spacing={3}>
        <Grid item>
          <Typography
            variant="h2"
            color="textPrimary"
            className={styles.boldText}
          >
            Etiquetas
          </Typography>
        </Grid>
      </Grid>
      <Grid className={styles.mTop}>
        <Grid container direction="column">
          <Paper className={styles.paperTable} elevation={0}>
            <TableContainer>
              <Table className={styles.table} aria-label="filesLabel">
                <TableHead>
                  <TableRow>
                    <TableCell>Data</TableCell>
                    <TableCell>Nome</TableCell>
                    <TableCell>Primeiro pedido</TableCell>
                    <TableCell>Último pedido</TableCell>
                    <TableCell>Total</TableCell>
                    <TableCell>Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {files.length ? (
                    files.map((file, index: number) => (
                      <TableRow key={index}>
                        <TableCell>
                          {handleFormatDateTime(
                            file.createdAt?.toString() || '',
                          )}
                        </TableCell>
                        <MyTooltip
                          title={file.originalFilename || ''}
                          enterDelay={500}
                          placement="top"
                        >
                          <TableCell>{file.originalFilename || '-'}</TableCell>
                        </MyTooltip>
                        <TableCell>{file.firstOrderId || '-'}</TableCell>
                        <TableCell>{file.lastOrderId || '-'}</TableCell>
                        <TableCell>{file.lengthOrders || '-'}</TableCell>
                        <TableCell>
                          <Link
                            className={styles.linkTextDecoration}
                            to={{
                              pathname: file.url || '',
                            }}
                            target="_blank"
                          >
                            <Button variant="text" color="primary">
                              <Typography
                                className={styles.boldText}
                                variant="h4"
                              >
                                Ver arquivo
                              </Typography>
                            </Button>
                          </Link>
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <MyTableCell colSpan={6} align="center">
                        Nenhuma etiqueta gerada
                      </MyTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </>
  );
};

export default FilesTable;
