import { Reducer } from 'redux';
import { StoreNotificationState, StoreNotificationTypes } from './types';

const INITIAL_STATE = {
  data: [] as any,
  isLoading: true,
  hasError: false,
};

const reducer: Reducer<StoreNotificationState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case StoreNotificationTypes.INIT_NOTIFICATION_REQUEST:
    case StoreNotificationTypes.LOAD_NOTIFICATION_REQUEST: {
      return { ...state, isLoading: true };
    }
    case StoreNotificationTypes.LOAD_NOTIFICATION_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        page: action.payload.page,
      };
    }
    case StoreNotificationTypes.LOAD_NOTIFICATION_FAILURE: {
      return { ...state, isLoading: false, hasError: true };
    }
    case StoreNotificationTypes.END_NOTIFICATION_REQUEST: {
      return { ...state, isLoading: false, hasError: false };
    }

    default:
      return state;
  }
};

export default reducer;
