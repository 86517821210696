import { call, put } from 'redux-saga/effects';
import BoxService from 'services/boxService';
import FileService from 'services/fileservice';
import { createAndDownloadFile } from 'utils/functions/generals';
import { loadFile, loadFileFailure, loadFileSuccess } from '../file/actions';
import { loadBoxesFail, loadBoxesSuccess } from './actions';

export function* loadBoxesSaga(action: any) {
  try {
    const { data, total } = yield call(BoxService.listBoxes, action.payload);
    yield put(loadBoxesSuccess({ data, total }));
  } catch (err) {
    yield put(loadBoxesFail());
  }
}

export function* loadBoxesFileSaga(action: any) {
  try {
    yield put(loadFile());
    const file = yield call(
      FileService.getFile,
      action.payload.query,
      action.payload.rootPath,
    );
    if (file?.response?.status) {
      yield put(loadFileFailure());
    } else {
      yield put(loadFileSuccess());
      createAndDownloadFile(file, 'Planos.xls');
    }
  } catch (error) {
    yield put(loadFileFailure());
  }
}
