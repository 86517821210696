import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Colors from 'styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWrapper: {
      padding: '24px 30px',
      [theme.breakpoints.up('md')]: {
        padding: '112px 100px 0px 100px',
        height: '100vh',
        overflow: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '112px 170px 0px 170px',
      },
    },
    containerForm: {
      marginTop: 53,
    },
    titleText: {
      fontSize: 32,
      fontWeight: 'bold',
    },
    subtitleText: {
      fontSize: 18,
      fontWeight: 500,
    },
    inputText: {
      marginBottom: 0,
    },
    inputWrapper: {
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 25px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(0.75)',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 8,
        height: 64,
      },
      '& .MuiFormHelperText-root': {
        color: Colors.RED,
        fontSize: 14,
        margin: '6px 0 0 12px',
      },
    },
    feedbackButton: {
      fontSize: 16,
    },
    button: {
      width: '100%',
      marginTop: 40,
      [theme.breakpoints.down('sm')]: {
        marginTop: 14,
      },
      padding: 18,
      fontSize: 14,
      fontWeight: 'bold',
      borderRadius: 8,
      height: 64,
    },
    progress: {
      color: Colors.WHITE,
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    paper: {
      backgroundColor: theme.palette.background.paper,
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
  }),
);

export default useStyles;
