import { getWithSaga } from './apiService';
import { CreateQueryParams } from '@nestjsx/crud-request';

const BASE_URL = 'leads';

export default class UsersLeadsService {
  static async getAllLeads(query: CreateQueryParams) {
    try {
      const data = getWithSaga(BASE_URL, query);
      return data;
    } catch (error) {
      return error.response;
    }
  }
}
