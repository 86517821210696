import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from 'store';
import { AuthState } from 'store/ducks/auth/types';

import AuthRoutes from './AuthRoutes';
import NonAuthRoutes from './NonAuthRoutes';

const Routes: FC = () => {
  const { isAuthenticated } = useSelector<AppState, AuthState>(
    ({ auth }: AppState) => auth,
  );

  return isAuthenticated ? <AuthRoutes /> : <NonAuthRoutes />;
};

export default Routes;
