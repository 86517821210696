import React, { FC, useEffect, useCallback, useState } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';
import clsx from 'clsx';

import { useSelector, useDispatch } from 'react-redux';
import { startOfToday, isBefore } from 'date-fns';

import { MySliderProps } from 'components/client/slider/types';
import { HeaderBar } from 'components/client/layout/LayoutClient/Header/styles';
import { FeedbackWithLinkDialogProps } from 'components/generals/dialog/dialogTypes';
import MySlider from 'components/client/slider';
import FeedbackWithLinkDialog from 'components/generals/dialog/FeedBackWithLinkDialog';

import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { NotificationState } from 'store/ducks/notification/types';
import { openModal } from 'store/ducks/nav/actions';
import { notifySignature } from 'store/ducks/notification/actions';
import { OrderOneState } from 'store/ducks/order/types';
import { loadOrderMonthSignature } from 'store/ducks/order/actions';

import { useStyles } from './styles';
import MobileAppCard from './MobileAppCard';
import PreviousBox from './PreviousBox';
import MonthBox from './MonthBox';
import {
  Signature,
  PAYMENT_TYPE,
  Order,
  RECURRENCES_MONTHS,
  ENHANCE_STATUS,
} from 'types/generals';
import {
  SIGNATURE_INACTIVE_MESSAGE_MODAL,
  SIGNATURE_INACTIVE_LINK_TITLE,
  CREDIT_CARD_PAYMENT_ERROR_MESSAGE_MODAL,
  CREDIT_CARD_UPDATE_LINK_TITLE,
  ORDER_PAYMENT_BANKSLIP_PENDING_MESSAGE_MODAL,
  ORDER_PAYMENT_BANKSLIP_LINK_TITLE,
} from 'utils/messages';
import { separateDateWithTrace } from 'helpers/validateDate';
import {
  verifyStatusSignature,
  verifyStatusOrder,
  verifyRecurrence,
} from './utils';
import { loadBanners } from 'store/ducks/generals/banner/actions';
import { CondOperator } from '@nestjsx/crud-request';
import { Banner, BannerLocation, IBannerData } from 'types/generals/banner';
import { getCurrentMonthOrder } from 'utils/functions/generals';
import { BannerState } from 'store/ducks/generals/banner/types';
import EnhancedProductBox from './EnhancedProductBox';

const Home: FC = () => {
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.only('xs'));
  const averageSize = useMediaQuery('(min-width:600px) and (max-width:959px)');
  const classes = useStyles();
  const dispatch = useDispatch();
  const [thisMonthOrder, setThisMonthOrder] = useState<Order>();

  const { isNotified } = useSelector<AppState, NotificationState>(
    ({ notification }: AppState) => notification,
  );

  const [sliderImages, setSliderImages] = useState<MySliderProps>();
  const [mobileAppData, setMobileAppData] = useState<any>();
  const [isSliderLoaded, setIsSliderLoaded] = useState(false);

  const { storeBanners, isLoading } = useSelector<AppState, BannerState>(
    ({ banner }: AppState) => banner,
  );

  const { data: signature, isLoading: isLoadingSignature } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  const { data: order, isLoading: isLoadingOrder } = useSelector<
    AppState,
    OrderOneState
  >(({ orderMonthReducer }: AppState) => orderMonthReducer);

  const loadOrdersMonth = useCallback(() => {
    if (signature.id && !isLoadingSignature) {
      dispatch(loadOrderMonthSignature(signature.id));
    }
  }, [dispatch, signature.id, isLoadingSignature]);

  const compact = averageSize || smallSize;

  const showModalInactiveSignature = useCallback(() => {
    const modalProps: FeedbackWithLinkDialogProps = {
      title: 'Assinatura inativa.',
      message: SIGNATURE_INACTIVE_MESSAGE_MODAL,
      myLink: '/my-account/signature/reativar-assinatura',
      myLinkMessage: SIGNATURE_INACTIVE_LINK_TITLE,
    };

    dispatch(openModal(FeedbackWithLinkDialog, modalProps));
    dispatch(notifySignature(true));
  }, [dispatch]);

  const showModalWithSignaturePaymentCreditCardRefused = useCallback(() => {
    const modalProps: FeedbackWithLinkDialogProps = {
      title: 'Erro no cartão.',
      message: CREDIT_CARD_PAYMENT_ERROR_MESSAGE_MODAL,
      myLink: '/my-account/cards',
      myLinkMessage: CREDIT_CARD_UPDATE_LINK_TITLE,
    };

    dispatch(openModal(FeedbackWithLinkDialog, modalProps));
    dispatch(notifySignature(true));
  }, [dispatch]);

  const showModalWithSignaturePaymentBankslipPending = useCallback(() => {
    const modalProps: FeedbackWithLinkDialogProps = {
      title: 'Pagamento não encontrado.',
      message: ORDER_PAYMENT_BANKSLIP_PENDING_MESSAGE_MODAL,
      myLink: '/my-account/orders',
      myLinkMessage: ORDER_PAYMENT_BANKSLIP_LINK_TITLE,
    };

    dispatch(openModal(FeedbackWithLinkDialog, modalProps));
    dispatch(notifySignature(true));
  }, [dispatch]);

  const isEnhanced = (currentOrder: Order | undefined) => {
    return (
      currentOrder?.enhanceStatus === ENHANCE_STATUS.PARENT_ENHANCED ||
      currentOrder?.enhanceStatus === ENHANCE_STATUS.ENHANCED
    );
  };

  // FIXME: Regra de negocio é melhor no backend, fazer um endpoint que responda
  // isso
  const handleVerifyModalToOpen = useCallback(
    (signature: Signature, order: Order) => {
      if (
        !order?.id &&
        verifyStatusSignature(signature) &&
        isBefore(separateDateWithTrace(signature.renewDate), startOfToday()) &&
        !isNotified
      ) {
        return showModalInactiveSignature();
      }

      if (order?.id) {
        if (
          verifyStatusOrder(order) &&
          signature.paymentType === PAYMENT_TYPE.BANKSLIP &&
          signature.plan.recurrence.months === RECURRENCES_MONTHS.MONTHLY &&
          !isNotified
        ) {
          return showModalWithSignaturePaymentBankslipPending();
        }

        if (
          verifyStatusOrder(order) &&
          signature.paymentType === PAYMENT_TYPE.CARD &&
          verifyRecurrence(signature.plan.recurrence.months)
        ) {
          return showModalWithSignaturePaymentCreditCardRefused();
        }
      }
    },
    [
      showModalInactiveSignature,
      showModalWithSignaturePaymentBankslipPending,
      showModalWithSignaturePaymentCreditCardRefused,
      isNotified,
    ],
  );

  useEffect(() => {
    loadOrdersMonth();
  }, [loadOrdersMonth]);

  const currentOrder = getCurrentMonthOrder(signature.orders);

  useEffect(() => {
    if (!isLoadingSignature && !isLoadingOrder) {
      handleVerifyModalToOpen(signature, order);
      if (signature && order?.id) {
        setThisMonthOrder(order);
      }
    }
  }, [
    isLoadingOrder,
    isLoadingSignature,
    order,
    signature,
    handleVerifyModalToOpen,
  ]);

  const getBanners = useCallback(() => {
    dispatch(
      loadBanners({
        limit: 10,
        sort: [
          {
            field: 'id',
            order: 'DESC',
          },
        ],
        filter: [
          {
            field: 'type',
            operator: CondOperator.EQUALS,
            value: BannerLocation.HOME,
          },
        ],
      }),
    );
  }, [dispatch]);
  useEffect(() => {
    getBanners();
  }, [isSliderLoaded, getBanners]);

  useEffect(() => {
    if (storeBanners && storeBanners.length && !isSliderLoaded) {
      const _storeBanners: Banner = storeBanners[0];
      const images = _storeBanners?.banner?.desktop;
      const secondayrImages = _storeBanners?.secondaryBanner?.desktop[0];
      const slider: MySliderProps = {
        steps: images.map((banner: IBannerData) => {
          return {
            path: banner.imageLink || '',
            label: banner.name || '',
            link: banner.redirectLink || '',
          };
        }),
      };
      if (secondayrImages) {
        setMobileAppData({
          imagePath: secondayrImages.imageLink,
          linkGoogle: secondayrImages.linkGoogle,
          linkApple: secondayrImages.linkApple,
        });
      }
      setSliderImages(slider);
      setIsSliderLoaded(true);
    }
  }, [isLoading, isSliderLoaded, storeBanners]);

  return (
    <Grid container direction="column">
      <Grid
        container
        direction="row"
        justify="space-between"
        className={
          averageSize ? clsx(classes.mTop, classes.centerContent) : undefined
        }
      >
        {compact ? (
          <Grid item xs={averageSize ? 3 : 12}>
            <HeaderBar />
          </Grid>
        ) : null}
        <Grid item xs={averageSize ? true : 12}>
          {storeBanners && sliderImages ? (
            <MySlider steps={sliderImages.steps} />
          ) : (
            ''
          )}
        </Grid>
      </Grid>
      <Grid container direction="row" justify="space-between" spacing={3}>
        <Grid item xs={12} sm={4} md={5} lg={4} xl={3} className={classes.mTop}>
          <MonthBox
            order={thisMonthOrder}
            renewDate={signature.renewDate}
            isEnhanced={isEnhanced(currentOrder)}
          />
        </Grid>
        <Grid item xs={12} sm={8} md={7} lg={8} xl={9} className={classes.mTop}>
          <Grid container direction="row" justify="space-between" item xs>
            <EnhancedProductBox disabled={isEnhanced(currentOrder)} />
            <Grid item xs>
              <PreviousBox />
            </Grid>
          </Grid>
          {mobileAppData ? (
            <Grid item xs={12} sm={12} className={classes.mTop}>
              <MobileAppCard mobileAppData={mobileAppData} />
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Home;
