import { CreateQueryParams } from '@nestjsx/crud-request';
import { ROOT_PATH } from 'services/fileservice';
import { StoreProduct } from 'types/generals';
import { action } from 'typesafe-actions';
import { StoreTypes, IStorePage, IStoreRowsPerPage } from './types';

export const listStoreRequest = (query?: CreateQueryParams) => {
  return action(StoreTypes.LIST_STORE_REQUEST, { query });
};

export const listStoreSuccess = (payload: {
  data: StoreProduct[];
  total: number;
}) => {
  return action(StoreTypes.LIST_STORE_SUCCESS, payload);
};

export const listStoreFail = () => {
  return action(StoreTypes.LIST_STORE_FAIL);
};

export const setStorePage = (payload: IStorePage) => {
  return action(StoreTypes.SET_STORE_PAGE, payload);
};

export const setStoreRowsPerPage = (payload: IStoreRowsPerPage) => {
  return action(StoreTypes.SET_ROWS_PER_PAGE, payload);
};

export const loadStoreFile = (
  query: CreateQueryParams,
  rootPath: ROOT_PATH,
) => {
  return action(StoreTypes.LOAD_STORE_FILE_REQUEST, {
    query,
    rootPath,
  });
};
