import FileSaver from 'file-saver';
import { apiQueue, transformBlobResponseInJSON } from './apiService';

const URL_BASE = 'shipping';

export default class ShippingStoreService {
  static async generateCsvFile(shippingId: number, shippingName: string) {
    try {
      const { data } = await apiQueue.get(
        `${URL_BASE}/${shippingId}/store/export-csv`,
        {
          responseType: 'blob',
        },
      );

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${shippingName}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      return error.response.data;
    }
  }

  static async setSigepLabelsOrders(shippingId: number) {
    try {
      const { data } = await apiQueue.put(
        `${URL_BASE}/${shippingId}/store/set-sigep-labels`,
      );
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async generateInvoicesShipping(shippingId: number) {
    try {
      const shipping = await apiQueue.put(
        `${URL_BASE}/${shippingId}/store/generate-invoices`,
      );
      return shipping;
    } catch (error) {
      return error.response.data;
    }
  }

  static async exportPackingList(shippingId: number) {
    try {
      const { data } = await apiQueue.get(
        `${URL_BASE}/${shippingId}/store/export-packing-list`,
        {
          responseType: 'blob',
        },
      );
      FileSaver.saveAs(data, `Romaneio_${shippingId}.xlsx`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async exportLabels(shippingId: number) {
    try {
      const { data } = await apiQueue.post(
        `${URL_BASE}/${shippingId}/store/export-labels`,
        {},
        {
          responseType: 'blob',
        },
      );
      FileSaver.saveAs(data, 'Etiquetas.pdf');
      return data;
    } catch (error) {
      const { data } = error.response;
      return (await transformBlobResponseInJSON(data)).message;
    }
  }
}
