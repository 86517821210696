import { makeStyles, styled } from '@material-ui/core';
import { ReactComponent as CopyIconSVG } from 'assets/img/svg/link.svg';
import Colors from 'styles/colors';
import theme from 'styles/theme';
import { GreyText } from 'components/client/layout/LayoutClient/MyHeaderBar/styles';

export const CustomGreyTextIndication = styled(GreyText)({
  cursor: 'pointer',
  margin: '14px 0 0 0',
  fontWeight: 500,
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const useStyles = makeStyles({
  mTop: {
    marginTop: theme.spacing(3),
  },
  linkIndicationTerms: {
    textDecoration: 'none',
  },
  walletScore: {
    color: Colors.RED,
    fontSize: '40px',
    fontWeight: 'bold',
    textAlign: 'center',
  },
  referralCode: {
    color: 'deepskyblue',
    textDecoration: 'underline',
    cursor: 'pointer',
  },
  rootMargin: {
    // padding: theme.spacing(2, 0),
    height: '100%',
  },
  paperTable: {
    padding: theme.spacing(3, 4),
    borderRadius: '2em',
    height: '100%',
  },
  paperTableMobile: {
    padding: 10,
    borderRadius: '2em',
  },
  cardContainer: {
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    borderRadius: 8,
    boxShadow: `0px 4px 3px rgba(0, 0, 0, 0.07)`,
    padding: '16px 16px 16px 30px',
    height: '100%',
  },
  marginTop15: {
    marginTop: '15px',
  },
  copyLink: {
    marginTop: '15px',
  },
  pendingCounter: {
    margin: '10px 0px 0 10px',
    fontSize: '1.3rem',
    fontWeight: 500,
  },
  activeCounter: {
    color: Colors.DARK_GREEN,
    fontSize: '1.3rem',
    fontWeight: 500,
    margin: '10px 0px 0 10px',
  },
  pointsText: {
    fontSize: '1rem',
    marginLeft: 5,
  },
});

export const TextArea = styled('textarea')({
  border: 'none',
  overflow: 'hidden',
  outline: 'none',
  boxShadow: 'none',
  resize: 'none',
  fontFamily: 'inherit',
  fontSize: '1.4rem',
  width: '100%',
  height: '30px',
  backgroundColor: Colors.LIGHT_GRAY,
  borderTopLeftRadius: '15px',
  borderBottomLeftRadius: '15px',
  padding: '6px 10px',
  whiteSpace: 'nowrap',
});

export const CopyIcon = styled(CopyIconSVG)({
  cursor: 'pointer',
  backgroundColor: Colors.RED,
  color: Colors.WHITE,
  borderTopRightRadius: '15px',
  borderBottomRightRadius: '15px',
  padding: '4px',
  height: '30px',
});
