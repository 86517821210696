import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import { Tag } from './styles';
import { ICustomTag } from './types';

const CustomTag: FC<ICustomTag> = ({ background, color, text }) => {
  return (
    <Grid>
      <Tag background={background} color={color}>
        {text}
      </Tag>
    </Grid>
  );
};

export default CustomTag;
