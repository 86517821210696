import { StepConnector, withStyles } from '@material-ui/core';
import Colors from 'styles/colors';

const MyStepVerticalConnector = withStyles({
  active: {
    '& $line': {
      borderColor: Colors.RED,
    },
  },
  completed: {
    '& $line': {
      borderColor: Colors.RED,
    },
  },
  line: {
    borderColor: Colors.LIGHTER_GRAY,
    borderTopWidth: 1,
    height: 20,
    justifyContent: 'center',
    marginTop: 10,
    marginLeft: 2,
  },
})(StepConnector);

export default MyStepVerticalConnector;
