import React, { FC } from 'react';
import { Card, Grid } from '@material-ui/core';

import ContractedCard from './ContractedCard';
import { BoxCard } from './types';
import DetailedCard from './DetailedCard';

const BoxCardData: FC<BoxCard> = ({
  boxTitle,
  imgPath,
  contracted,
  description,
  deliveryDate,
  tag,
  owned,
  order,
  renewDate,
  isEnhanced,
}) => {
  const cardsProps = {
    boxTitle,
    imgPath,
    description,
    deliveryDate,
    tag,
    owned,
    order,
    renewDate,
    isEnhanced,
  };

  return (
    <Grid container justify="center" style={{ height: '100%' }}>
      <Card elevation={0} style={{ height: '100%', position: 'relative' }}>
        <Grid container justify="space-between" spacing={2} direction="column">
          {contracted ? (
            <ContractedCard cardData={cardsProps} />
          ) : (
            <DetailedCard cardData={cardsProps} />
          )}
        </Grid>
      </Card>
    </Grid>
  );
};

export default BoxCardData;
