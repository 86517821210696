import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Dialog,
} from '@material-ui/core';

import { useStyles } from 'components/generals/forms/LoginForm/styles';
import FeedbackDialog from 'components/generals/dialog/FeedbackDialog';

import { AppState } from 'store';
import { Modal } from 'store/ducks/nav/types';
import { closeModal, openModal } from 'store/ducks/nav/actions';

import AuthService, { FORGOT_VERIFY } from 'services/authService';

import { ReactComponent as SuccessIcon } from 'assets/img/svg/icon_active_sucess.svg';
import { ReactComponent as ErrorIcon } from 'assets/img/svg/icon_active_error.svg';

import { validationSchema } from './utils';

import { Colors } from 'styles/colors';
import { handleBlockPaste } from 'utils/functions/generals';
import { useGetWithSWR } from 'services/apiService';

export const NewPasswordAdminForm: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  const recoveryToken = location.pathname.split('/').pop();

  const {
    open,
    maxWidth,
    component: Component,
    props,
  } = useSelector<AppState, Modal>(({ nav }: AppState) => nav.modal);

  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onBlur',
    validationSchema,
  });

  const { error } = useGetWithSWR(`${FORGOT_VERIFY}/${recoveryToken}`);

  const handleMountMondalInfor = (title: string, success = false) => {
    return {
      title,
      message: (
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            dispatch(closeModal());
            history.push('/admin/login');
          }}
          classes={{ root: classes.feedbackButton }}
        >
          Voltar ao login
        </Button>
      ),
      disableCloseButton: true,
      icon: success ? SuccessIcon : ErrorIcon,
    };
  };

  const onSubmit = async ({ password }: Record<string, string>) => {
    try {
      setIsLoading(true);
      if (error) {
        const modalProps = handleMountMondalInfor(
          'Token de recuperação inválido',
        );
        dispatch(openModal(FeedbackDialog, modalProps));
      } else if (recoveryToken) {
        await AuthService.resetPasswordWithToken(recoveryToken, password);
        const modalProps = handleMountMondalInfor(
          'Sua senha foi alterada com sucesso!',
          true,
        );

        dispatch(openModal(FeedbackDialog, modalProps));
      }
    } catch (error) {
      const modalProps = handleMountMondalInfor('Algo de errado aconteceu');
      dispatch(openModal(FeedbackDialog, modalProps));
    }
    reset();
  };

  return (
    <Grid item container direction="column" justify="space-between">
      <Grid
        item
        container
        direction="column"
        classes={{ root: classes.header }}
      >
        <Typography variant="h2" className={classes.main}>
          Definir nova senha
        </Typography>
        <Typography variant="h4" className={classes.descriptionText}>
          Defina a nova senha de acesso ao sistema.
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item container direction="column">
          <TextField
            inputRef={register}
            variant="filled"
            label="Nova Senha"
            name="password"
            classes={{ root: classes.root }}
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: Colors.DARKER_GRAY,
                fontSize: 20,
                paddingLeft: 7,
              },
            }}
            inputProps={{
              type: 'password',
            }}
            InputProps={{
              disableUnderline: true,
              classes: { root: classes.inputRoot, focused: classes.focused },
              style: {
                background: Colors.LIGHT_GRAY,
                borderRadius: 4,
              },
            }}
            error={Boolean(errors.password)}
            helperText={errors.password ? errors.password.message : null}
          />
          <TextField
            inputRef={register}
            variant="filled"
            label="Confirmar Nova Senha"
            name="repeatPassword"
            classes={{ root: classes.root }}
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: Colors.DARKER_GRAY,
                fontSize: 20,
                paddingLeft: 7,
              },
            }}
            inputProps={{
              type: 'password',
            }}
            InputProps={{
              disableUnderline: true,
              classes: { root: classes.inputRoot, focused: classes.focused },
              style: {
                background: Colors.LIGHT_GRAY,
                borderRadius: 4,
              },
            }}
            onPaste={e => handleBlockPaste(e)}
            error={Boolean(errors.repeatPassword)}
            helperText={
              errors.repeatPassword ? errors.repeatPassword.message : null
            }
          />
        </Grid>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            classes={{
              root: classes.buttonRoot,
            }}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress className={classes.progress} size={20} />
            ) : (
              'Enviar'
            )}
          </Button>
        </Grid>
      </form>

      <Dialog maxWidth={maxWidth} open={open}>
        {Component ? <Component {...props} /> : null}
      </Dialog>
    </Grid>
  );
};

export default NewPasswordAdminForm;
