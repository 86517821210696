import React from 'react';
import { Grid, Typography, Paper, CardMedia } from '@material-ui/core';

import { ReactComponent as LinkIcon } from 'assets/img/svg/link.svg';
import { ReactComponent as CloseIcon } from 'assets/img/svg/close.svg';

import { StyledTypography, useStyles } from '../styles';
import BannerImagesZone from '../bannerImagesZone';
import { openModal } from 'store/ducks/nav/actions';
import BannerLinksDialog from 'components/generals/dialog/BannerLinksDialog';
import { useDispatch } from 'react-redux';
import { IBannerBottom } from './types';
import { BannerType, IStoreLinks } from 'types/generals/banner';
import { BannerLinksDialogProps } from 'components/generals/dialog/dialogTypes';

const BannerBottom: React.FC<IBannerBottom> = ({
  banner,
  bannerData,
  handleUpdateBanner,
  handleUpdateBannerData,
  errors,
  register,
  setValue,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const onChangeLink = (storeLinks: IStoreLinks, file: any) => {
    const newBanners = bannerData.map(b => {
      if (b.id === file.id) {
        return {
          ...b,
          linkGoogle: storeLinks.linkGoogle,
          linkApple: storeLinks.linkApple,
        };
      }
      return b;
    });
    handleUpdateBannerData(newBanners, true);
  };

  const handleLink = (file: any) => {
    const initialValueGoogle = file.linkGoogle;
    const initialValueApple = file.linkApple;
    const modalProps: BannerLinksDialogProps = {
      title: 'Adicionar link à imagem',
      confirmText: 'Adicionar',
      handleAction: (e: any) => onChangeLink(e, file),
      initialValueGoogle,
      initialValueApple,
      inputLinkGoogle: 'Link da loja no Google',
      inputLinkApple: 'Link da loja na Apple',
      id: file.id,
    };
    dispatch(openModal(BannerLinksDialog, modalProps));
  };

  const handleRemoveFromList = (image: any) => {
    if (image?.id) {
      const bannerIndex = banner.findIndex(b => b.name === image.name);
      if (!isNaN(bannerIndex)) {
        const _banner = banner;
        _banner.splice(bannerIndex, 1);
        handleUpdateBanner(_banner, true);
      }
      const bannerDataIndex = bannerData.findIndex(b => b.id === image.id);
      if (!isNaN(bannerDataIndex)) {
        const _bannerData = bannerData;
        _bannerData.splice(bannerDataIndex, 1);
        handleUpdateBannerData(_bannerData, true);
      }
    }
  };

  const BannerDataList = () => {
    return bannerData.length ? (
      <>
        <Grid item xs={12}>
          <StyledTypography variant="h3">Imagem inserida</StyledTypography>
        </Grid>
        <Grid item xs={12} className={styles.gridBottomContent}>
          <Grid
            container
            justify="space-between"
            className={styles.bottomGridHeader}
          >
            <Grid item xs={3}>
              <StyledTypography variant="h4">Miniatura</StyledTypography>
            </Grid>
            <Grid item xs={7}>
              <StyledTypography variant="h4">Nome</StyledTypography>
            </Grid>
            <Grid item xs={2}>
              <StyledTypography variant="h4">Ações</StyledTypography>
            </Grid>
          </Grid>
          <Grid className={styles.gridBottomContentList}>
            {bannerData.map((data, index) => (
              <Grid
                container
                className={styles.draggableItem}
                alignItems="center"
                key={index}
              >
                <Grid item xs={3}>
                  <CardMedia
                    component="img"
                    src={data.imageLink || banner[index]?.image}
                    alt="Banner de baixo"
                    className={styles.bottomCardMedia}
                  />
                </Grid>
                <Grid item xs={7}>
                  <Typography variant="h4">{data.name}</Typography>
                </Grid>

                <Grid item xs={2}>
                  <LinkIcon onClick={() => handleLink(data)} />
                  <CloseIcon onClick={() => handleRemoveFromList(data)} />
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </>
    ) : (
      <></>
    );
  };

  return (
    <Grid container direction="column">
      <StyledTypography className={styles.marginRoot} variant="h2">
        Upload banner inferior
      </StyledTypography>
      <Paper elevation={0} className={styles.rootPaper}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <BannerImagesZone
              bannerFiles={banner}
              bannerData={bannerData}
              bannerType={BannerType.SECONDARY}
              errors={errors}
              register={register}
              setValue={setValue}
              handleUpdateBanner={handleUpdateBanner}
              handleUpdateBannerData={handleUpdateBannerData}
              multiple={false}
            />
            <BannerDataList />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default BannerBottom;
