import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from 'components/generals/forms/register/styles';
import MySelectForm from 'components/generals/input/MySelectForm';
import { StatusCodes } from 'http-status-codes';
import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import RolesService from 'services/RolesService';
import { closeModal } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { StoreCategory } from 'types/generals';
import { ROLES_UPDATE_ERROR, ROLES_UPDATE_SUCCESS } from 'utils/messages';
import { PermissionsDialogProps } from '../dialogTypes';
import useStylesLocal from './styles';

const PermissionsDialog: FC<PermissionsDialogProps> = ({
  title,
  role,
  permissions,
  onSubmitSuccess,
}) => {
  const styles = useStyles();
  const componentStyles = useStylesLocal();
  const dispatch = useDispatch();

  const [rolePermissions, setRolePermissions] = useState<number[]>(
    (role && role?.permissions?.map(perm => perm?.id)) || [],
  );
  const { handleSubmit, errors, register, reset } = useForm({
    defaultValues: {},
  });

  const handleCloseDialog = () => {
    reset();
    dispatch(closeModal());
  };

  const onSubmit = async () => {
    if (role) {
      const response: any = await RolesService.updateRole(role?.id, {
        permissions: rolePermissions.map(perm => {
          return { id: perm };
        }),
      });

      if (![StatusCodes.OK].includes(response.status)) {
        dispatch(notifyError(ROLES_UPDATE_ERROR));
      } else {
        if (StatusCodes.OK === response.status) {
          dispatch(notifySuccess(ROLES_UPDATE_SUCCESS));

          onSubmitSuccess(response.data);
          handleCloseDialog();
        }
      }
    }
  };

  const handleOnChangePermissions = (event: any) => {
    setRolePermissions(event.target.value);
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={2}
          component="form"
          style={{ overflow: 'hidden' }}
        >
          <Grid item xs>
            <MySelectForm
              classFormControl={componentStyles.formControl}
              contracted
              itens={permissions?.map(permission => ({
                title: permission.displayName || '',
                value: permission.id,
              }))}
              onChange={handleOnChangePermissions}
              value={rolePermissions}
              helperText={null}
              title="Permissões"
              error={false}
              multiple={true}
              menuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            />
          </Grid>
          <Grid
            container
            spacing={3}
            item
            justify="space-between"
            direction="row"
            alignItems="center"
          >
            <Grid item xs></Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          item
          spacing={2}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => dispatch(closeModal())}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
            onClick={handleSubmit(onSubmit)}
          >
            Confirmar
          </Button>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default PermissionsDialog;
