import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Grid } from '@material-ui/core';
import { CreateQueryParams, CondOperator } from '@nestjsx/crud-request';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useGetWithSWR } from 'services/apiService';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import MyNewButton from 'components/generals/buttons/MyNewButton';
import MyTable from 'components/generals/table/MyTable';
import { Sort } from 'types/TableTypes';
import { CrudListProps } from './types';
import MyDateRangeFilter from '../input/MyDateRangeFilter';
import MyInputFilter from '../input/MyInputFilter';
import { useFilters } from 'hooks/filters';
import { useStyles } from './styles';

const CrudList: FC<CrudListProps> = ({
  title,
  columns,
  queryParams,
  setQueryParams = () => {},
  url,
  searchField,
  searchFieldLabel,
  newButtonLabel,
  newButtonLinkTo,
  onNewButtonClick,
  onRowClick,
  refresh,
  setRefresh,
  showSearch = true,
  showDateFilter = false,
  dateField = 'createdAt',
  dateFilterLabel = 'Período',
  customFilter,
  children,
  rowsPerPage: perPage,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [localCustomFilter, setLocalCustomFilter] =
    useState<any[]>(customFilter);
  const [search, setSearch] = useState<string>('');
  const { data, isValidating, revalidate } = useGetWithSWR(url, queryParams);
  const [rowsPerPage, setRowsPerPage] = useState(perPage || 10);

  const {
    filterFields,
    genericFilterFields,
    handleGenericSearch,
    dateFilter,
    handleDateFilter,
  } = useFilters({
    name: { filterTitle: 'Nome', value: undefined },
    createdAt: { filterTitle: 'Data criação', value: undefined },
  });
  const styles = useStyles();

  const onSortClicked = (sort: Sort) => {
    setQueryParams((oldQuery: CreateQueryParams) => {
      return { ...oldQuery, sort };
    });
  };

  const onChangePage = (_: any, page: number) => {
    setQueryParams((oldQuery: CreateQueryParams) => {
      return { ...oldQuery, page: page + 1 };
    });
  };

  const handleSearch = (e: any) => {
    const isEnter = e.key === 'Enter' || e.keyCode === '13';
    const value = e.target.value;
    if (searchField && isEnter && search !== value) {
      setSearch(value);
      const isNumber = !isNaN(value);
      const defaultSearchObject = [
        {
          field: searchField,
          operator: CondOperator.CONTAINS_LOW,
        },
      ];
      const searchObject = isNumber
        ? [
            {
              field: searchField,
              operator: CondOperator.CONTAINS_LOW,
            },
            {
              field: 'id',
              operator: CondOperator.EQUALS,
            },
          ]
        : defaultSearchObject;
      handleGenericSearch(e, searchObject);
    }
  };

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    const limit = parseInt(event.target.value, 10);
    setQueryParams((oldQuery: CreateQueryParams) => {
      return { ...oldQuery, page: 1, limit };
    });
    setRowsPerPage(limit);
  };

  const searchQuery = useCallback(() => {
    if (showDateFilter && dateFilter[0] !== null && dateFilter[1] === null) {
      return;
    }
    setIsLoading(true);
    setQueryParams((oldQuery: CreateQueryParams) => {
      let search = {
        $and: [...filterFields, { $or: [...genericFilterFields] }],
      };
      if (localCustomFilter?.length) {
        search = {
          $and: [
            ...filterFields,
            ...localCustomFilter,
            {
              $or: [...genericFilterFields],
            },
          ],
        };
      }
      return { ...oldQuery, search };
    });
  }, [
    localCustomFilter,
    dateFilter,
    filterFields,
    genericFilterFields,
    setQueryParams,
    showDateFilter,
  ]);

  useEffect(() => {
    setIsLoading(!data || isValidating);
  }, [data, isValidating]);

  useEffect(() => {
    if (refresh) {
      revalidate();
      setRefresh(false);
    }
  }, [refresh, revalidate, setRefresh]);

  useEffect(() => {
    if (customFilter?.length) {
      setLocalCustomFilter(customFilter);
    }
  }, [customFilter]);

  useEffect(() => {
    if (showDateFilter) {
      searchQuery();
    }
  }, [search, dateFilter, searchQuery, showDateFilter]);

  useEffect(() => {
    if (title) {
      dispatch(actUpdatePageTitle(title));
    }
  }, [dispatch, title]);

  const onClickNewButton = () => {
    if (newButtonLinkTo) {
      history.push(newButtonLinkTo);
    } else if (onNewButtonClick) {
      onNewButtonClick();
    }
  };

  return (
    <Grid container className={styles.mTop}>
      <Grid
        container
        item
        direction="row"
        spacing={2}
        className={styles.filters}
      >
        {showDateFilter && (
          <Grid item xs={3}>
            <MyDateRangeFilter
              placeholder={dateFilterLabel}
              value={dateFilter}
              onChange={(date: Date[]) => {
                handleDateFilter(date, '', dateField);
              }}
            />
          </Grid>
        )}
        {showSearch ? (
          <Grid item xs={5} md={3}>
            <MyInputFilter
              onKeyUp={e => handleSearch(e)}
              placeholder={searchFieldLabel}
            />
          </Grid>
        ) : null}

        {children ? children : ''}
        {newButtonLabel ? (
          <Grid item xs justify="flex-end" alignItems="flex-end" container>
            <MyNewButton onClick={onClickNewButton}>
              {newButtonLabel}
            </MyNewButton>
          </Grid>
        ) : null}
      </Grid>
      <MyTable
        isLoading={isLoading}
        rootContainerStyle={{
          marginTop: 20,
          marginBottom: 10,
          width: '100%',
        }}
        columns={columns}
        data={data?.data}
        total={data?.total}
        page={data?.page}
        onSortClicked={onSortClicked}
        onChangePage={onChangePage}
        onRowClick={onRowClick}
        selectRowsPerPage={rowsPerPage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Grid>
  );
};

export default CrudList;
