import { StatusCodes } from 'http-status-codes';
import { call, put } from 'redux-saga/effects';
import StoreSettingsService from 'services/storeSettingsService';
import { StoreSettings, StoreTags } from 'types/client';
import {
  loadActiveStoreSettingsDone,
  loadFailStoreSettings,
  loadStoreSettingsDone,
  setHasPromotion,
  setIsMaintenance,
  setIsPromotionalPeriod,
} from './actions';

export function* loadStoreSettingsSaga() {
  try {
    const { data } = yield call(StoreSettingsService.requestStoreSettings);
    yield put(loadStoreSettingsDone(data));
    return data;
  } catch (error) {
    yield put(loadFailStoreSettings());
  }
}

export function* loadActiveStoreSettingsSaga() {
  try {
    const { data } = yield call(StoreSettingsService.requestStoreSettings);
    yield put(loadStoreSettingsDone(data));
    // fixme: revisar nome?
    const activeStoreSettings: StoreSettings =
      data.find((config: StoreSettings) => config.isActive) || data[0];
    const { tags } = activeStoreSettings;
    yield put(loadActiveStoreSettingsDone(activeStoreSettings));
    yield put(setIsMaintenance(tags.includes(StoreTags.isMaintenance)));
    yield put(
      setIsPromotionalPeriod(tags.includes(StoreTags.isPromotionalPeriod)),
    );
  } catch (error) {
    yield put(loadFailStoreSettings());
  }
}
export function* loadVerifyHasPromotionSaga(action: any) {
  try {
    const { status } = yield call(
      StoreSettingsService.handleVerifyHasPromotion,
      action.payload.signatureId,
    );
    if (status === StatusCodes.OK) {
      yield put(setHasPromotion(true));
    }
  } catch (error) {
    yield put(setHasPromotion(false));
  }
}
