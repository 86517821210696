import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { includes, last } from 'lodash';
import { Button, Grid, Paper } from '@material-ui/core';

import MyTypography from 'components/generals/labels/MyTypography';
import MyStepper from 'components/generals/stepper/MyStepper';
import OrderCancelDialog from 'components/generals/dialog/OrderCancelDialog';
import {
  ACTIVE_STATUS,
  ORDER_CANCEL_STATUS_LIST,
  ORDER_STATUS_LIST,
} from 'helpers/orderConstants';
import { openModal } from 'store/ducks/nav/actions';
import { useStyles } from '../styles';
import { OrderDetailsProps } from '../types';
import { ORDER_STATUS, ORDER_TYPE, SIGNATURE_STATUS } from 'types/generals';
import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { CANCEL_STORE_ORDER_INFO } from 'utils/messages';

const OrderTrackingStatus: FC<OrderDetailsProps> = ({ order }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const STATUS_HISTORY = order.statusHistory;
  const STATUS_CANCEL_HISTORY = order.statusCancelHistory;

  const { data: signature } = useSelector<AppState, SingleSignatureState>(
    ({ oneSignature }: AppState) => oneSignature,
  );

  const handleCancelOrderDialog = useCallback(
    (title: string, heading: string) => {
      dispatch(
        openModal(OrderCancelDialog, {
          title,
          heading,
          id: order.id,
          confirmText: 'Solicitar suspensão do pedido',
        }),
      );
    },
    [dispatch, order.id],
  );

  const hasCancelFlow = () => {
    return (
      order.status === ORDER_STATUS.PENDING_CANCELED ||
      order.status === ORDER_STATUS.CANCELED
    );
  };

  const hasCancelFlowButton = () => {
    const currentMonth = new Date().getMonth();
    const orderPaymentMonth = new Date(order.paymentDate).getMonth();

    return (
      !includes(ACTIVE_STATUS, order.status) ||
      orderPaymentMonth < currentMonth ||
      signature.status === SIGNATURE_STATUS.INACTIVE ||
      order.orderDetail.orderType === ORDER_TYPE.SIGNATURE
    );
  };

  const canDisabledCancelOrder = () => {
    const lastCancelStatus = last(STATUS_CANCEL_HISTORY);

    return (
      hasCancelFlow() ||
      hasCancelFlowButton() ||
      lastCancelStatus?.oldState === ORDER_STATUS.PENDING_CANCELED
    );
  };

  const TrackingInfo = () => (
    <Grid
      container
      justify="space-between"
      alignItems="center"
      direction="row"
      spacing={1}
    >
      <Grid container direction="column" item xs alignItems="flex-end">
        {order.sigepLabel && (
          <>
            <MyTypography className={styles.orderInfoTitle}>
              Código de Rastreio
            </MyTypography>
            <MyTypography
              variant="subtitle1"
              className={styles.invoiceOrderInfo}
            >
              {order?.sigepLabel?.trackingNumber || '-'}
            </MyTypography>
          </>
        )}
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="row" justify="space-between">
      <Grid item xs>
        <MyTypography variant="h3" className={styles.infoTitle}>
          Status do Pedido
        </MyTypography>
      </Grid>
      <Grid item xs>
        <TrackingInfo />
      </Grid>
      <Grid
        item
        xs={12}
        style={
          order.status === ORDER_STATUS.CANCELED
            ? { margin: 0 }
            : { marginTop: '56px' }
        }
      >
        <MyStepper
          order={order}
          steps={ORDER_STATUS_LIST}
          statusHistory={STATUS_HISTORY}
        />
      </Grid>
    </Grid>
  );
};

export default OrderTrackingStatus;
