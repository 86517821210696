import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { AppState } from 'store';
import { EnhancedProductState } from 'store/ducks/enhancedProduct/types';
import EnhancedProductService from 'services/enhancedProductService';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { StatusCodes } from 'http-status-codes';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import {
  SIGNATURE_ENHANCED_ERROR,
  SIGNATURE_ENHANCED_SUCCESS,
  SIGNATURE_ENHANCE_MESSAGE,
} from 'utils/messages/enhancedProduct';
import { loadEnhancedProductsRequest } from 'store/ducks/enhancedProduct/actions';
import { CondOperator } from '@nestjsx/crud-request';
import CardEnhancedProduct from 'pages/client/cards/CardEnhancedProduct';
import { getCurrentMonthOrder } from 'utils/functions/generals';
import { ENHANCE_STATUS, ORDER_STATUS, PAYMENT_TYPE } from 'types/generals';
import {
  BaseDialogProps,
  FeedbackWithLinkDialogProps,
} from 'components/generals/dialog/dialogTypes';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import {
  ENHANCED_ORDER_BANKSLIP_WARNING,
  ENHANCED_ORDER_CARD_WARNING,
  ORDER_PAYMENT_BANKSLIP_LINK_TITLE,
  ORDER_PAYMENT_CARD_LINK_TITLE,
} from 'utils/messages';
import FeedbackWithLinkDialog from 'components/generals/dialog/FeedBackWithLinkDialog';

interface EnhancedProductBoxProps {
  disabled: boolean;
}

const EnhancedProductBox: FC<EnhancedProductBoxProps> = ({ disabled }) => {
  const dispatch = useDispatch();
  const [submitStatus, setSubmitStatus] = useState('');

  const { data: signature, isLoading: isLoadingSignature } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  const { data: enhancedProducts } = useSelector<
    AppState,
    EnhancedProductState
  >(({ enhancedProduct }: AppState) => enhancedProduct);

  const dashboardProducts = enhancedProducts.filter(
    eProduct => eProduct.displayConfig.dashboard,
  );
  const enhancedProduct = dashboardProducts.length
    ? dashboardProducts[0]
    : null;
  const currentOrder = getCurrentMonthOrder(signature.orders);
  const isCurrentOrderPaid = () => {
    return currentOrder?.status === ORDER_STATUS.PAID;
  };
  const isPaymentTypeCard = useCallback(() => {
    return signature.paymentType === PAYMENT_TYPE.CARD;
  }, [signature.paymentType]);

  const loadEnhancedProductsMonth = useCallback(() => {
    if (signature.id && !isLoadingSignature) {
      const currentDate = new Date().toISOString();
      dispatch(
        loadEnhancedProductsRequest({
          join: [['product'], ['product.images']],
          filter: [
            {
              field: 'startDate',
              operator: CondOperator.LOWER_THAN_EQUALS,
              value: currentDate,
            },
            {
              field: 'endDate',
              operator: CondOperator.GREATER_THAN_EQUALS,
              value: currentDate,
            },
          ],
        }),
      );
    }
  }, [dispatch, isLoadingSignature, signature.id]);

  useEffect(() => {
    loadEnhancedProductsMonth();
  }, [loadEnhancedProductsMonth]);

  const handleEnhanceSginature = async (enhancedProductId: number) => {
    try {
      setSubmitStatus('submiting');
      const response = await EnhancedProductService.enhanceSignature(
        signature.id,
        { enhancedProducts: [enhancedProductId] },
      );
      dispatch(closeModal());
      if (![StatusCodes.CREATED, StatusCodes.OK].includes(response.status)) {
        dispatch(notifyError(SIGNATURE_ENHANCED_ERROR));
        setSubmitStatus('error');
      } else {
        dispatch(notifySuccess(SIGNATURE_ENHANCED_SUCCESS));
        setSubmitStatus('success');
      }
    } catch (error) {
      dispatch(closeModal());
      dispatch(notifyError(SIGNATURE_ENHANCED_ERROR));
    }
  };

  const handleButtonAction = (id: number) => {
    if (isCurrentOrderPaid()) {
      handleOpenConfirmDialog(id);
    } else {
      showUnpaidAlertDialog();
    }
  };

  const showUnpaidAlertDialog = useCallback(() => {
    let warningText = ENHANCED_ORDER_BANKSLIP_WARNING;
    let linkUrl = '/my-account/orders';
    let bankslipLinkMsg = ORDER_PAYMENT_BANKSLIP_LINK_TITLE;

    if (isPaymentTypeCard()) {
      warningText = ENHANCED_ORDER_CARD_WARNING;
      linkUrl = '/my-account/signature';
      bankslipLinkMsg = ORDER_PAYMENT_CARD_LINK_TITLE;
    }

    const modalProps: FeedbackWithLinkDialogProps = {
      title: 'Pagamento não encontrado.',
      message: warningText,
      myLink: linkUrl,
      myLinkMessage: bankslipLinkMsg,
    };

    dispatch(openModal(FeedbackWithLinkDialog, modalProps));
  }, [dispatch, isPaymentTypeCard]);

  const handleOpenConfirmDialog = (id: number) => {
    const modalProps: BaseDialogProps = {
      title: 'Turbinando pedido',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: SIGNATURE_ENHANCE_MESSAGE,
      actionFn: () => handleEnhanceSginature(id),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  return (
    <>
      {enhancedProduct && isPaymentTypeCard() ? (
        <Grid item xs={12} sm={6} md={5}>
          <CardEnhancedProduct
            enhancedProduct={enhancedProduct}
            action={handleButtonAction}
            btnText="Quero turbinar!"
            showDuration={true}
            submitStatus={submitStatus}
            submitedText="Turbinado"
            disabled={disabled}
          />
        </Grid>
      ) : null}
    </>
  );
};

export default EnhancedProductBox;
