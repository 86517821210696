import React, { FC, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import OpenEndpointsService from 'services/openEndpointsService';
import MASK, { UNMASK } from 'helpers/masks';
import { InputRef } from 'types/FormTypes';

import { AddressFormProps } from './types';
import MyOutlinedTextField from '../input/MyOutlinedTextField';

const AddressForm: FC<AddressFormProps> = ({
  control,
  reset,
  errors,
  register,
  xsValue,
}) => {
  const [loading, setLoading] = useState(false);
  const zipcodeInput = useRef() as InputRef;
  const streetInput = useRef() as InputRef;
  const numberInput = useRef() as InputRef;
  const neighborhoodInput = useRef() as InputRef;
  const cityInput = useRef() as InputRef;
  const stateInput = useRef() as InputRef;
  const complementInput = useRef() as InputRef;

  const lookupAddress = async (zipcode: string) => {
    if (zipcode.length === 8) {
      setLoading(true);
      try {
        const data = await OpenEndpointsService.userLookupAddress(zipcode);
        reset({
          'address.neighborhood': data.bairro,
          'address.complement': data.complemento2 || '',
          'address.city': data.cidade,
          'address.street': data.end,
          'address.state': data.uf,
        });
      } catch (error) {
        reset({
          'address.neighborhood': '',
          'address.complement': '',
          'address.city': '',
          'address.street': '',
          'address.state': '',
        });
      } finally {
        setLoading(false);
      }
      return zipcode;
    }
  };

  const handleZipcodeChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    lookupAddress(unmasked);
    return unmasked;
  };

  // FIXME: Corrigir a implementação do yup, só fiz funcionar provisoriamente
  return (
    <Grid container item xs={xsValue} spacing={1}>
      <Grid item xs={12}>
        <Controller
          as={InputMask}
          mask={MASK.ZIPCODE}
          name="address.zipcode"
          control={control}
          maskChar={null}
          onChange={handleZipcodeChange}
        >
          {() => (
            <MyOutlinedTextField
              fullWidth
              label="CEP"
              type="text"
              name="address.zipcode"
              inputProps={{
                ref: zipcodeInput,
                inputMode: 'numeric',
              }}
              changeFocusOnEnter={() => streetInput.current.focus()}
              isLoading={loading}
              error={Boolean(errors.address)}
              helperText={
                errors.address?.zipcode ? errors.address.zipcode.message : null
              }
              inputRef={register}
            />
          )}
        </Controller>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="address.street"
          control={control}
          as={({ value, onChange }) => (
            <MyOutlinedTextField
              fullWidth
              label="Endereço"
              name="address.street"
              type="text"
              inputProps={{ ref: streetInput }}
              changeFocusOnEnter={() => numberInput.current.focus()}
              onChange={onChange}
              isLoading={loading}
              error={Boolean(errors.address)}
              helperText={
                errors.address?.street ? errors.address.street.message : null
              }
              value={value || ''}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <MyOutlinedTextField
          fullWidth
          label="Número"
          id="number"
          name="address.number"
          type="text"
          inputProps={{ ref: numberInput }}
          changeFocusOnEnter={() => neighborhoodInput.current.focus()}
          error={Boolean(errors.address)}
          helperText={
            errors.address?.number ? errors.address.number.message : null
          }
          inputRef={register}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="address.neighborhood"
          control={control}
          as={({ value, onChange }) => (
            <MyOutlinedTextField
              fullWidth
              label="Bairro"
              name="address.neighborhood"
              type="text"
              inputProps={{ ref: neighborhoodInput }}
              changeFocusOnEnter={() => cityInput.current.focus()}
              onChange={onChange}
              isLoading={loading}
              error={Boolean(errors.address)}
              helperText={
                errors.address?.neighborhood
                  ? errors.address.neighborhood.message
                  : null
              }
              value={value}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="address.city"
          control={control}
          as={({ value, onChange }) => (
            <MyOutlinedTextField
              fullWidth
              name="address.city"
              label="Cidade"
              type="text"
              inputProps={{ ref: cityInput }}
              changeFocusOnEnter={() => stateInput.current.focus()}
              onChange={onChange}
              isLoading={loading}
              error={Boolean(errors.address)}
              helperText={
                errors.address?.city ? errors.address.city.message : null
              }
              value={value || ''}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="address.state"
          control={control}
          as={({ value, onChange }) => (
            <MyOutlinedTextField
              fullWidth
              name="address.state"
              label="Estado"
              type="text"
              inputProps={{ ref: stateInput }}
              changeFocusOnEnter={() => complementInput.current.focus()}
              onChange={onChange}
              isLoading={loading}
              error={Boolean(errors.address)}
              helperText={
                errors.address?.state ? errors.address.state.message : null
              }
              value={value || ''}
            />
          )}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="address.complement"
          control={control}
          as={({ value, onChange }) => (
            <MyOutlinedTextField
              name="address.complement"
              fullWidth
              label="Complemento"
              type="text"
              inputProps={{ ref: complementInput }}
              onChange={onChange}
              isLoading={loading}
              value={value || ''}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AddressForm;
