import { call, put } from 'redux-saga/effects';
import RecurrencesService from 'services/recurrencesService';
import { loadRecurrencesSuccess, loadRecurrenceFailure } from './actions';

export function* loadRecurrencesSaga() {
  try {
    const { data, total } = yield call(RecurrencesService.getAllRecurrences);
    yield put(loadRecurrencesSuccess({ total, data }));
  } catch (error) {
    yield put(loadRecurrenceFailure());
  }
}
