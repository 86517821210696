import { Grid, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import SearchIcon from 'assets/png/search.png';
import { useStyles } from './styles';

const StoreEmptyState: FC = () => {
  const styles = useStyles();
  return (
    <>
      <Grid
        container
        item
        justify="space-between"
        direction="column"
        className={styles.wrapper}
      >
        <Grid className={styles.icon}>
          <img src={SearchIcon} alt="Ícone de pesquisa" />
        </Grid>
        <Typography variant="h1" className={styles.title}>
          Ops, parece que não foi possível
        </Typography>
        <Typography variant="h1" className={styles.title}>
          encontrar o que você está procurando
        </Typography>
      </Grid>
      <Grid>
        <Typography className={styles.sub}>
          Tente fazer uma nova pesquisa
        </Typography>
      </Grid>
    </>
  );
};

export default StoreEmptyState;
