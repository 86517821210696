import { all, takeLatest, takeLeading } from 'redux-saga/effects';
import { AuthTypes } from './auth/types';
import { authenticateUserSaga, unauthenticateUserSaga } from './auth/sagas';
import { UserTypes } from './user/types';
import {
  loadStaffFileSaga,
  loadStaffSaga,
  loadUserSaga,
  loadUserWalletSaga,
} from './user/sagas';
import { InvoiceTypes } from './invoice/types';
import { loadInvoicesSaga } from './invoice/sagas';
import { loadAllPlpSaga, loadOnePlpSaga } from './sigep/plp/sagas';
import { PlpTypes } from './sigep/plp/types';
import { OrderTypes, UserOrderTypes } from './order/types';
import {
  cancelOrderSaga,
  loadOneOrderSaga,
  loadOrdersSaga,
  loadUserOrderSaga,
  reactivateOrderSaga,
  loadOrderMonthSignatureSaga,
} from './order/sagas';
import { LabelTypes } from './sigep/label/types';
import { loadLabelsSaga } from './sigep/label/sagas';
import { TrackingTypes } from './sigep/tracking/types';
import {
  loadModicoTrackingSaga,
  loadPacTrackingSaga,
  loadSedexTrackingSaga,
} from './sigep/tracking/sagas';
import { CreditCardTypes } from './payment/creditCard/types';
import { loadCreditCardsSaga } from './payment/creditCard/sagas';
import { SignatureTypes } from './generals/signature/types';
import {
  loadSignatureSaga,
  loadUserSignatureSaga,
  cancelSignatureSaga,
} from './generals/signature/oneSignature/sagas';
import { loadSignatureListSaga } from './generals/signature/sagas';
import { PlanTypes } from './generals/plan/types';
import {
  loadPlansFileSaga,
  loadPlansListSaga,
  loadPlansToChangeSaga,
} from './generals/plan/sagas';
import {
  loadOnePlanSaga,
  loadPlanByHashSaga,
} from './generals/plan/onePlan/sagas';
import { BoxesTypes } from './generals/boxes/types';
import { loadBoxesFileSaga, loadBoxesSaga } from './generals/boxes/sagas';
import { CouponsTypes } from './coupons/types';
import { loadCouponsFileSaga, loadCouponsSaga } from './coupons/sagas';
import { ShippingTypes } from './shipping/types';
import {
  loadShippingListSaga,
  loadShippingOneSaga,
  loadShippngFileSaga,
} from './shipping/sagas';
import { StoreTypes } from './store/types';
import { loadStoreFileSaga, loadStoreSaga } from './store/sagas';
import { AddressTypes } from './generals/address/types';
import {
  loadAddressesSaga,
  loadDefaultAddressSaga,
} from './generals/address/sagas';
import {
  requestCancelSignatureSaga,
  reactivateSignatureSaga,
} from './generals/signature/oneSignature/sagas';
import { HomeTypes } from './client/home/types';
import { loadCurrentBoxSaga, loadPreviousBoxesSaga } from './client/home/sagas';
import { TransportTypes } from './transport/types';
import { loadTransportCoverageSaga } from './transport/sagas';
import {
  loadActiveStoreSettingsSaga,
  loadStoreSettingsSaga,
  loadVerifyHasPromotionSaga,
} from './store/storeSettings/sagas';
import { loadViewUsersFileSaga, loadViewUsersSaga } from './views/sagas';
import { StoreSettingsTypes } from './store/storeSettings/types';
import { ViewTypes } from 'store/ducks/views/types';
import { ViewBulkActionTypes } from 'store/ducks/bulk-actions/view-user-last-order/types';
import { BannerTypes } from './generals/banner/types';
import { loadBannersSaga } from './generals/banner/sagas';
import { CategoriesTypes } from './categories/types';
import { loadCategoriesSaga, loadCategoryFileSaga } from './categories/sagas';
import { VideosTypes } from './generals/video/types';
import { loadVideoListSaga } from './generals/video/sagas';
import { RecurrencesTypes } from './recurrences/types';
import { loadRecurrencesSaga } from './recurrences/sagas';
import { LeadsTypes } from './leads/types';
import { loadLeadsFileSaga, loadLeadsSaga } from './leads/saga';
import { BenefitsTypes } from './benefits/types';
import { loadBenefitsSaga } from './benefits/saga';
import { loadOneStoreProductSaga } from './store/oneProduct/sagas';
import { DataFreteTransportTypes } from './dataFreteTransport/types';
import { loadTransportDataFreteSaga } from './dataFreteTransport/sagas';
import { StoreNotificationTypes } from './store/storeNotification/types';
import { loadStoreNotificationsSaga } from './store/storeNotification/sagas';
import { RankingTypes } from './ranking/types';
import {
  loadActiveRankingSeasonSaga,
  loadRankingFileSaga,
  loadRankingSeasonSaga,
  loadUserRankingSeasonSaga,
} from './ranking/sagas';
import { EnhancedProductTypes } from './enhancedProduct/types';
import {
  loadEnhancedProductsOpenEndpointSaga,
  loadEnhancedProductsSaga,
} from './enhancedProduct/sagas';
import { loadViewUserSignatureLastOrderSaga } from './bulk-actions/view-user-last-order/sagas';
import { loadViewUserReactivationSaga } from './bulk-actions/view-user-reactivation/sagas';
import { ViewBulkActionReactivationTypes } from './bulk-actions/view-user-reactivation/types';
import { ViewBulkActionTurbineTypes } from './bulk-actions/view-user-turbine/types';
import { loadViewUserTurbineSaga } from './bulk-actions/view-user-turbine/sagas';

export default function* rootSaga() {
  return yield all([
    takeLeading(AuthTypes.LOGIN_REQUEST, authenticateUserSaga),
    takeLatest(AuthTypes.LOGOUT_REQUEST, unauthenticateUserSaga),

    // User related sagas
    takeLatest(UserTypes.LOAD_REQUEST, loadUserSaga),
    takeLatest(UserTypes.LOAD_STAFF_REQUEST, loadStaffSaga),
    takeLatest(UserTypes.LOAD_USER_WALLET_REQUEST, loadUserWalletSaga),
    takeLatest(UserTypes.LOAD_STAFF_FILE_REQUEST, loadStaffFileSaga),

    // View related sagas
    takeLatest(ViewTypes.LOAD_VIEW_USERS_REQUEST, loadViewUsersSaga),
    takeLatest(ViewTypes.LOAD_VIEW_USERS_FILE_REQUEST, loadViewUsersFileSaga),

    takeLatest(
      ViewBulkActionTypes.LOAD_VIEW_USERS_LAST_ORDER_REQUEST,
      loadViewUserSignatureLastOrderSaga,
    ),
    takeLatest(
      ViewBulkActionReactivationTypes.LOAD_VIEW_USERS_REACTIVATION_REQUEST,
      loadViewUserReactivationSaga,
    ),
    takeLatest(
      ViewBulkActionTurbineTypes.LOAD_VIEW_USERS_TURBINE_REQUEST,
      loadViewUserTurbineSaga,
    ),

    // Invoice related sagas
    takeLatest(InvoiceTypes.LOAD_REQUEST, loadInvoicesSaga),

    // Sigep related sagas
    takeLatest(PlpTypes.ALL_PLP_REQUEST, loadAllPlpSaga),
    takeLatest(PlpTypes.GET_PLP_REQUEST, loadOnePlpSaga),

    // EnhancedProduct related sagas
    takeLatest(EnhancedProductTypes.LOAD_REQUEST, loadEnhancedProductsSaga),
    takeLatest(
      EnhancedProductTypes.LOAD_OPEN_REQUEST,
      loadEnhancedProductsOpenEndpointSaga,
    ),

    // Orders related sagas
    takeLatest(OrderTypes.LOAD_ORDERS_REQUEST, loadOrdersSaga),
    takeLatest(OrderTypes.LOAD_ONE_ORDER_REQUEST, loadOneOrderSaga),
    takeLatest(
      OrderTypes.LOAD_MONTH_ORDER_SIGNATURE_REQUEST,
      loadOrderMonthSignatureSaga,
    ),
    takeLatest(OrderTypes.CANCEL, cancelOrderSaga),
    takeLatest(OrderTypes.REACTIVATE, reactivateOrderSaga),
    takeLatest(UserOrderTypes.LOAD_USER_ORDER, loadUserOrderSaga),

    // Labels related sagas
    takeLatest(LabelTypes.LOAD_REQUEST, loadLabelsSaga),

    // Tracking related sagas
    takeLatest(TrackingTypes.LOAD_SEDEX_REQUEST, loadSedexTrackingSaga),
    takeLatest(TrackingTypes.LOAD_PAC_REQUEST, loadPacTrackingSaga),
    takeLatest(TrackingTypes.LOAD_MODICO_REQUEST, loadModicoTrackingSaga),

    // Credit cards related sagas
    takeLatest(CreditCardTypes.LOAD_CREDIT_CARD_REQUEST, loadCreditCardsSaga),

    // Signature related sagas
    takeLatest(
      SignatureTypes.LOAD_SIGNATURE_LIST_REQUEST,
      loadSignatureListSaga,
    ),
    takeLatest(SignatureTypes.LOAD_SIGNATURE_REQUEST, loadSignatureSaga),
    takeLatest(
      SignatureTypes.LOAD_USER_SIGNATURE_REQUEST,
      loadUserSignatureSaga,
    ),
    takeLatest(
      SignatureTypes.REQUEST_CANCEL_SIGNATURE,
      requestCancelSignatureSaga,
    ),
    takeLatest(SignatureTypes.REACTIVATE_SIGNATURE, reactivateSignatureSaga),
    takeLatest(SignatureTypes.CANCELED_SIGNATURE, cancelSignatureSaga),

    // Plans related sagas
    takeLatest(PlanTypes.LOAD_PLAN_LIST_REQUEST, loadPlansListSaga),
    takeLatest(PlanTypes.LOAD_PLAN_REQUEST, loadOnePlanSaga),
    takeLatest(PlanTypes.LOAD_ONE_PLAN, loadPlanByHashSaga),
    takeLatest(PlanTypes.LOAD_PLAN_FILE_REQUEST, loadPlansFileSaga),

    // Videos related sagas
    takeLatest(VideosTypes.LOAD_VIDEO_LIST_REQUEST, loadVideoListSaga),
    takeLatest(PlanTypes.LOAD_PLANS_TO_REACTIVATE, loadPlansToChangeSaga),

    // Coupons related sagas
    takeLatest(CouponsTypes.LOAD_REQUEST, loadCouponsSaga),
    takeLatest(CouponsTypes.LOAD_COUPON_FILE_REQUEST, loadCouponsFileSaga),

    // Categories related sagas
    takeLatest(CategoriesTypes.LOAD_REQUEST, loadCategoriesSaga),
    takeLatest(CategoriesTypes.LOAD_FILE_REQUEST, loadCategoryFileSaga),

    // Shipping related sagas
    takeLatest(ShippingTypes.LOAD_SHIPPING_LIST_REQUEST, loadShippingListSaga),
    takeLatest(ShippingTypes.LOAD_SHIPPING_ONE_REQUEST, loadShippingOneSaga),
    takeLatest(ShippingTypes.LOAD_SHIPPING_FILE_REQUEST, loadShippngFileSaga),

    // Store related sagas
    takeLatest(StoreTypes.LIST_STORE_REQUEST, loadStoreSaga),
    takeLatest(StoreTypes.LOAD_STORE_FILE_REQUEST, loadStoreFileSaga),
    takeLatest(
      StoreTypes.LOAD_ONE_STORE_PRODUCT_REQUEST,
      loadOneStoreProductSaga,
    ),

    // Boxes related sagas
    takeLatest(BoxesTypes.LOAD_BOXES_REQUEST, loadBoxesSaga),
    takeLatest(BoxesTypes.LOAD_BOXES_FILE_REQUEST, loadBoxesFileSaga),

    // Address related sagas
    takeLatest(AddressTypes.LOAD_ALL_ADDRESSES, loadAddressesSaga),
    takeLatest(AddressTypes.LOAD_DEFAULT_ADDRESS, loadDefaultAddressSaga),

    // Client HomePage sagas
    takeLatest(HomeTypes.LOAD_CURRENT_BOX, loadCurrentBoxSaga),
    takeLatest(HomeTypes.LOAD_PREVIOUS_BOXES, loadPreviousBoxesSaga),

    // Transport sagas
    takeLatest(
      TransportTypes.LOAD_TRANSPORT_COVERAGE_REQUEST,
      loadTransportCoverageSaga,
    ),
    // Banners
    takeLatest(BannerTypes.LOAD_BANNERS_REQUEST, loadBannersSaga),

    // Recurrences
    takeLatest(RecurrencesTypes.LOAD_RECURRENCES_REQUEST, loadRecurrencesSaga),
    // Notifications
    takeLatest(
      StoreNotificationTypes.LOAD_NOTIFICATION_REQUEST,
      loadStoreNotificationsSaga,
    ),

    // Ranking Season
    takeLatest(
      RankingTypes.LOAD_ACTIVE_RANKING_REQUEST,
      loadActiveRankingSeasonSaga,
    ),
    takeLatest(RankingTypes.LOAD_RANKING_REQUEST, loadRankingSeasonSaga),
    // User Ranking Season
    takeLatest(
      RankingTypes.LOAD_USER_RANKING_SEASON_REQUEST,
      loadUserRankingSeasonSaga,
    ),
    takeLatest(RankingTypes.LOAD_RANKING_FILE_REQUEST, loadRankingFileSaga),

    // Benefits
    takeLatest(BenefitsTypes.LOAD_BENEFITS_REQUEST, loadBenefitsSaga),

    // Store Settings related sagas
    takeLatest(
      StoreSettingsTypes.REQUEST_STORE_SETTINGS,
      loadStoreSettingsSaga,
    ),
    takeLatest(
      StoreSettingsTypes.REQUEST_ACTIVE_STORE_SETTINGS,
      loadActiveStoreSettingsSaga,
    ),
    takeLatest(
      StoreSettingsTypes.LOAD_VERIFY_HAS_PROMOTION,
      loadVerifyHasPromotionSaga,
    ),

    // Data Frete Transport
    takeLatest(
      DataFreteTransportTypes.LOAD_TRANSPORT_DATA_FRETE_REQUEST,
      loadTransportDataFreteSaga,
    ),
    // Leads
    takeLatest(LeadsTypes.LOAD_REQUEST, loadLeadsSaga),
    takeLatest(LeadsTypes.LOAD_LEAD_FILE, loadLeadsFileSaga),
  ]);
}
