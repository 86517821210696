import React, { FC } from 'react';
import { useSelector } from 'react-redux';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  TableCell,
} from '@material-ui/core';
import { orderBy } from 'lodash';
import MyTooltip from 'components/generals/tolltip';

import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTableCell from 'components/generals/table/MyTableCell';
import theme from 'styles/theme';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { useFilters } from 'hooks/filters';

const StatusHistorySignature: FC = () => {
  const { data: subscriber, isLoading, hasError } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  const statusHistory = orderBy(
    subscriber.statusHistory,
    ['createdAt'],
    ['desc'],
  );
  const { sort, handleSort } = useFilters({
    'signature.id': { filterTitle: 'Signature', value: undefined },
  });

  const handleFormatDate = (date: string) => {
    return formatColumnValue(FormatOptions.DATETIME, date);
  };

  return (
    <Grid container justify="space-between" direction="column">
      <Grid
        container
        justify="flex-start"
        direction="row"
        spacing={6}
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h1"
            color="textPrimary"
            style={{ fontWeight: 600 }}
          >
            Histórico de status
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <Paper
          style={{
            marginTop: 24,
            padding: theme.spacing(1, 3, 3, 3),
            borderRadius: 30,
          }}
          elevation={0}
        >
          <TableContainer>
            <Table aria-label="orders list">
              <TableHead>
                <TableRow>
                  <MySortableTableCell
                    onClick={() => handleSort('date')}
                    direction={sort.field === 'date' && sort.order}
                  >
                    Data
                  </MySortableTableCell>
                  <MySortableTableCell
                    onClick={() => handleSort('type')}
                    direction={sort.field === 'type' && sort.order}
                  >
                    Status atual
                  </MySortableTableCell>
                  <MySortableTableCell
                    onClick={() => handleSort('discount')}
                    direction={sort.field === 'discount' && sort.order}
                  >
                    Detalhe
                  </MySortableTableCell>
                  <MySortableTableCell
                    onClick={() => handleSort('total')}
                    direction={sort.field === 'total' && sort.order}
                  >
                    Usuário
                  </MySortableTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading &&
                  !hasError &&
                  statusHistory.map((status, key) => (
                    <TableRow key={key}>
                      <MyTableCell>
                        {handleFormatDate(status.createdAt)}
                      </MyTableCell>
                      <MyTableCell>{status.newState}</MyTableCell>

                      <MyTooltip
                        title={status.details || ''}
                        enterDelay={500}
                        placement="top"
                      >
                        <TableCell>{status.details}</TableCell>
                      </MyTooltip>
                      <MyTableCell>{status.requester}</MyTableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default StatusHistorySignature;
