import { call, put } from 'redux-saga/effects';
import SignatureService from 'services/signatureService';
import { loadSignaturesFail, loadSignaturesSuccess } from './actions';

export function* loadSignatureListSaga(action: any) {
  try {
    const { data, total } = yield call(
      SignatureService.getAllSignatures,
      action.payload,
    );
    yield put(loadSignaturesSuccess({ data, total }));
  } catch (err) {
    yield put(loadSignaturesFail());
  }
}
