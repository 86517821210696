import { action } from 'typesafe-actions';
import { DataFreteTransportTypes } from './types';
import { DataFreteProductsPayload } from 'types/generals/dataFrete';

export const loadTransportDataFreteRequest = (
  zipcode: string,
  products: DataFreteProductsPayload[],
) => {
  return action(DataFreteTransportTypes.LOAD_TRANSPORT_DATA_FRETE_REQUEST, {
    zipcode,
    products,
  });
};

export const loadTransportDataFreteSuccess = (payload: any) => {
  return action(
    DataFreteTransportTypes.LOAD_TRANSPORT_DATA_FRETE_SUCCESS,
    payload,
  );
};

export const loadTranportDataFreteFail = () => {
  return action(DataFreteTransportTypes.LOAD_TRANSPORT_DATA_FRETE_FAIL);
};
