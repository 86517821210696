import { LayoutState, MenuTypes } from './types';
import { Reducer } from 'redux';

const INITIAL_STATE: LayoutState = {
  isMobileMenuOpen: false,
};

const reducer: Reducer<LayoutState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MenuTypes.TOGGLE: {
      return { ...state, isMobileMenuOpen: !state.isMobileMenuOpen };
    }
    default:
      return state;
  }
};

export default reducer;
