import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

import { Colors } from 'styles/colors';
import { Sizes } from 'styles/sizes';
import { MyDrawerSectionProps } from './types';

const useStyles = makeStyles({
  gridRoot: {
    borderLeft: Sizes.DRAWER_LEFT_BORDER,
    borderLeftColor: 'transparent',
    borderLeftStyle: 'solid',
    textDecoration: 'none',
    paddingLeft: Sizes.DRAWER_LEFT_PADDING,
    marginBottom: '1.2rem',
    marginTop: '1.2rem',
    fontSize: '1.2rem',
    fontWeight: 800,
    color: Colors.GRAY,
    textTransform: 'uppercase',
  },
  icon: {
    height: '2rem',
  },
  activeLink: {
    borderLeftColor: Colors.RED,
    color: Colors.RED,
    '& path': {
      stroke: Colors.RED,
    },
  },
});

const MyDrawerSection: FC<MyDrawerSectionProps> = props => {
  const { icon: Icon } = props;
  const classes = useStyles();
  return (
    <Grid container direction="column">
      <Grid
        container
        alignItems="center"
        to={props.to ? props.to : ''}
        component={NavLink}
        activeClassName={classes.activeLink}
        isActive={(match: any, _location: any) => {
          if (props.to && match) {
            return true;
          }
          return false;
        }}
        classes={{ root: classes.gridRoot }}
        style={props.to ? {} : { color: Colors.DARKER_GRAY, cursor: 'default' }}
      >
        {Icon && <Icon className={classes.icon} />}
        <div style={{ marginLeft: Icon ? 10 : 0 }}>{props.name}</div>
      </Grid>
      {props.children}
    </Grid>
  );
};

export default MyDrawerSection;
