import React from 'react';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import CloseIcon from '@material-ui/icons/Close';
import { format, parseISO } from 'date-fns';

import { closeModal, openModal } from 'store/ducks/nav/actions';
import useStyles from 'components/generals/forms/register/styles';
import {
  notifyError,
  notifySuccess,
  disableButton,
  enableButton,
} from 'store/ducks/notification/actions';
import { renewDateValidationSchema, defaultValues } from './utils';
import { SignatureTypeDialogProps, BaseDialogProps } from './dialogTypes';
import {
  CHANGE_RENEW_DATE_SUCCESS,
  CHANGE_RENEW_DATE_ERROR,
} from 'utils/messages';
import SignatureService from 'services/signatureService';
import ConfirmationDialog from './ConfirmationDialog';
import MyDatePicker from '../input/MyDatePicker';

const SignatureRenewDateDialog: React.FC<SignatureTypeDialogProps> = ({
  modalTitle,
  cancelText,
  cancelAction,
  submitText,
  subscriber,
  minDate,
  maxDate,
  isAdmin = true,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, control, errors, watch } = useForm({
    validationSchema: renewDateValidationSchema,
    defaultValues: defaultValues(parseISO(subscriber.renewDate)),
  });

  const renewDate = watch('renewDate');

  const onSubmit = async () => {
    try {
      dispatch(disableButton());
      const formatedDate = format(new Date(renewDate), 'yyyy-MM-dd');
      const response = await SignatureService.changeRenewDateSignature(
        subscriber.id,
        parseISO(formatedDate),
        isAdmin,
      );
      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(CHANGE_RENEW_DATE_SUCCESS));
        dispatch(closeModal());
        if (isAdmin) {
          history.push(`/admin/subscriptions/${subscriber.id}`, {
            subscriber: { ...subscriber, renewDate: formatedDate },
          });
        } else {
          history.go(0);
        }
      } else {
        throw new Error(CHANGE_RENEW_DATE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleOpenConfirmDialog = () => {
    const modalProps: BaseDialogProps = {
      title: 'Alterar data de renovação',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: 'Deseja alterar a data de renovação?',
      actionFn: () => onSubmit(),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {modalTitle}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <Controller
              name="renewDate"
              control={control}
              as={({ value, onChange }) => (
                <MyDatePicker
                  id="renewDate"
                  onChange={onChange}
                  value={value}
                  name="renewDate"
                  label="Data de renovação"
                  minDate={minDate}
                  maxDate={maxDate}
                  error={Boolean(errors.renewDate)}
                  helperText={
                    errors.renewDate ? errors['renewDate'].message : null
                  }
                  allowKeyboardControl={true}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={2}
        className={styles.actionsWrapper}
      >
        <DialogActions>
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={cancelAction || (() => dispatch(closeModal()))}
          >
            {cancelText ?? 'Cancelar'}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
            onClick={handleSubmit(handleOpenConfirmDialog)}
          >
            {submitText}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default SignatureRenewDateDialog;
