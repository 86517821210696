import { call, put } from 'redux-saga/effects';
import TransportService from 'services/transportService';
import {
  loadTransportDataFreteSuccess,
  loadTranportDataFreteFail,
} from './actions';
import { DataFreteQuoteFreight } from 'types/generals/dataFrete';
import { TRANSPORT_COMPANIES } from 'types/generals';

export function* loadTransportDataFreteSaga(action: any) {
  try {
    if (action.payload.products.length) {
      const { data } = yield call(
        TransportService.getTransportFreteData,
        action.payload.zipcode,
        action.payload.products,
      );

      const transportCorreios = data.data.filter(
        (d: DataFreteQuoteFreight) =>
          d.nome_transportador.toLowerCase() ===
          TRANSPORT_COMPANIES.CORREIOS.toLowerCase(),
      );

      if (transportCorreios.length > 1) {
        for (const transport of transportCorreios) {
          transport.nome_transportador = `${transport.nome_transportador} (${transport.descricao})`;
        }
      }

      yield put(loadTransportDataFreteSuccess(data));
    }
  } catch (error) {
    yield put(loadTranportDataFreteFail());
  }
}
