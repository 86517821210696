import React, {
  useCallback,
  useState,
  useEffect,
  ChangeEvent,
  Fragment,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { first, groupBy } from 'lodash';
import { startOfMonth, lastDayOfMonth } from 'date-fns';

import { AppState } from 'store';
import { OrderState } from 'store/ducks/order/types';
import { loadOrders, cleanOrdersList } from 'store/ducks/order/actions';
import { useFilters } from 'hooks/filters';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import {
  ORDER_TYPE,
  ORDER_STATUS,
  SHIPPING_TYPE,
  SHIPPING_STATUS,
  TRANSPORT_COMPANIES,
} from 'types/generals';
import { Button, Typography, Grid, TablePagination } from '@material-ui/core';
import { useStyles } from './styles';
import {
  actUpdatePageTitle,
  openModal,
  closeModal,
} from 'store/ducks/nav/actions';

import MyDropdown from 'components/generals/dropdown/MyDropdown';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import Colors from 'styles/colors';
import { CondOperator } from '@nestjsx/crud-request';
import { StatusCodes } from 'http-status-codes';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import PreShippmentOrdersListResend from './PreShippmentOrdersListResend';
import MyDateRangeFilter from 'components/generals/input/MyDateRangeFilter';
import MySelectFilter from 'components/generals/input/MySelectFilter';
import {
  loadPreShippmentOrders,
  resetPreShippmentOrders,
} from 'store/ducks/preShippimentOrders/action';
import { PreShippimentOrderState } from 'store/ducks/preShippimentOrders/types';
import {
  AutoCompleteDialogProps,
  OrderShippingDialogProps,
  SetCustomTransportDialogProps,
} from 'components/generals/dialog/dialogTypes';
import AutoCompleteDialog from 'components/generals/dialog/AutoCompleteDialog';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import ShippingService from 'services/shippingService';
import { notifySuccess, notifyError } from 'store/ducks/notification/actions';
import { loadShippingListRequest } from 'store/ducks/shipping/actions';
import { ShippingState } from 'store/ducks/shipping/types';
import OrderShippingDialog from 'components/generals/dialog/OrderShippingDialog';
import { ADD_ORDER_SHIPPING_ERROR } from 'utils/messages';
import { PlanState } from 'store/ducks/generals/plan/types';
import { loadAllPlans } from 'store/ducks/generals/plan/actions';
import { RecurrenceState } from 'store/ducks/recurrences/types';
import { loadRecurrences } from 'store/ducks/recurrences/actions';
import TransportService from 'services/transportService';
import { SIGEP_SERVICES } from 'helpers/trackingConstants';
import SetCustomTransportInResendOrdersDialog from 'components/generals/dialog/SetCustomTransportInResendOrdersDialog';
import { brStates } from 'helpers/address';
import MySelectTableFilter from 'components/generals/input/MySelectTableFilter';

const PreShipmentOrdersResend: React.FC = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const [dateFilter, setDateFilter] = useState<Date[]>([]);

  const { data: orders = [], total = 0 } = useSelector<AppState, OrderState>(
    ({ ordersListReducer }: AppState) => ordersListReducer,
  );

  const { data: preShippimentOrders } = useSelector<
    AppState,
    PreShippimentOrderState
  >(({ preShippimentOrderReducer }: AppState) => preShippimentOrderReducer);

  const { data: shippingList = [] } = useSelector<AppState, ShippingState>(
    ({ shippingListReducer }: AppState) => shippingListReducer,
  );

  const { data: plans } = useSelector<AppState, PlanState>(
    ({ plans }: AppState) => plans,
  );

  const { data: recurrences } = useSelector<AppState, RecurrenceState>(
    ({ recurrences }: AppState) => recurrences,
  );

  const {
    sort,
    page,
    filterFields,
    genericFilterFields,
    handleClearFilters,
    handleGenericSearch,
    handleSetValuePage,
    handleClearPageFilter,
    handleUpdateFilters,
    pageFilters,
  } = useFilters({
    statusCotation: { filterTitle: 'Status da cotação', value: undefined },
    'signature.planId': { filterTitle: 'Plano', value: undefined },
    'signature.plan.recurrenceId': {
      filterTitle: 'Recorrência',
      value: undefined,
    },
    'orderDetail.state': {
      filterTitle: 'Estado',
      value: [],
    },
    'signature.user.currentSubscription': {
      filterTitle: 'Assinatura atual',
      value: undefined,
    },
    isFirst: {
      filterTitle: 'Primeiro mês da assinatura',
      value: undefined,
    },
    isFirstPaidOrder: {
      filterTitle: 'Primeiro pedido do assinante',
      value: undefined,
    },
    isFirstOrderGift: { filterTitle: 'Contem brinde', value: undefined },
    enhanceStatus: {
      filterTitle: 'Pedido turbinado',
      value: undefined,
    },
    'orderDetail.orderType': {
      filterTitle: 'Tipo',
      value: undefined,
    },
  });

  const searchObject = [
    {
      field: 'orderDetail.transport',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'orderDetail.zipcode',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'id',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
  ];

  const loadAllOrders = useCallback(() => {
    if (
      (dateFilter.length && dateFilter[0] !== null && dateFilter[1] !== null) ||
      genericFilterFields.length
    ) {
      dispatch(
        loadOrders({
          fields: [
            'id',
            'paymentDate',
            'statusCotation',
            'status',
            'isFirstPaidOrder',
            'isFirstOrderGift',
            'isDonation',
            'isResend',
            'enhanceStatus',
          ],
          join: [
            {
              field: 'orderDetail',
              select: [
                'freight',
                'products',
                'city',
                'state',
                'logCotation',
                'transport',
                'zipcode',
                'id',
                'paymentType',
                'subtotal',
                'discount',
                'transportIdentification',
                'orderType',
              ],
            },
            { field: 'signature', select: ['plan', 'id'] },
            { field: 'signature.plan', select: ['recurrenceId', 'name', 'id'] },
            { field: 'signature.user', select: ['currentSubscription', 'id'] },
          ],
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [
              ...filterFields,
              {
                status: ORDER_STATUS.PAID,
                orderIsInShipment: false,
                isEnhanced: false,
                isResend: true,
              },
              {
                paymentDate: {
                  $between: dateFilter,
                },
              },
              { $or: [...genericFilterFields] },
            ],
          },
        }),
      );
    }
  }, [
    dispatch,
    dateFilter,
    page,
    sort,
    rowsPerPage,
    genericFilterFields,
    filterFields,
  ]);

  const loadAvailablePlans = useCallback(() => {
    dispatch(
      loadAllPlans({
        sort: { field: 'createdAt', order: 'DESC' },
      }),
    );
  }, [dispatch]);

  const loadAvailableRecurrences = useCallback(() => {
    dispatch(loadRecurrences());
  }, [dispatch]);

  const loadShippingList = useCallback(() => {
    dispatch(
      loadShippingListRequest({
        filter: [
          {
            field: 'shippingType',
            operator: CondOperator.EQUALS,
            value: SHIPPING_TYPE.STORE,
          },
          {
            field: 'status',
            operator: CondOperator.EQUALS,
            value: SHIPPING_STATUS.ACTIVE,
          },
        ],
      }),
    );
  }, [dispatch]);

  const handleAddSelectedOrdersInShipping = () => {
    const transport = first(preShippimentOrders)?.transport;

    const shippingOptions = shippingList
      .filter(s => s.transport === transport)
      .map(shipping => ({
        id: shipping.id,
        title: shipping.name,
      }));

    const dialogProps: AutoCompleteDialogProps = {
      modalTitle: 'Adicionar à Remessa',
      modalInfo: `${preShippimentOrders.length} item(ns) selecionado(s)`,
      labelPlaceholder: 'Buscar Remessa',
      options: shippingOptions,
      submitText: 'Adicionar',
      clearInputBlur: true,
      submitAction: (shippingName?: any) =>
        confirmationSubmitShipping(shippingName),
    };

    dispatch(openModal(AutoCompleteDialog, dialogProps));
  };

  const handleCreateNewShippingSelectedOrders = () => {
    const ordersIds = preShippimentOrders.map(order => order.id);
    const transport = first(preShippimentOrders)?.transport;

    const dialogProps: OrderShippingDialogProps = {
      modalTitle: 'Criar Remessa',
      modalInfo: `${ordersIds.length} item(ns) selecionado(s)`,
      submitText: 'Confirmar',
      shippingType: SHIPPING_TYPE.SIGNATURE,
      ordersIds,
      transport,
    };

    dispatch(openModal(OrderShippingDialog, dialogProps));
  };

  const confirmationSubmitShipping = async (shipping: any) => {
    const shippingTitle = shipping.title ? shipping.title : shipping;
    try {
      dispatch(
        openModal(ConfirmationDialog, {
          title: 'Adicionar à Remessa',
          message: `Deseja adicionar ${preShippimentOrders.length} item(ns) à ${shippingTitle}?`,
          actionFn: () => submitGenerateShipping(shipping.title, shipping.id),
        }),
      );
    } catch (err) {
      throw new Error(err);
    }
  };

  const submitGenerateShipping = async (
    shippingName: string,
    shippingId: number,
  ) => {
    try {
      const ordersCount = preShippimentOrders.length;

      const orderIds = preShippimentOrders.map(order => order.id);

      const shippingResponse = await ShippingService.addOrderInShipping(
        shippingId,
        orderIds,
      );

      if (shippingResponse.status !== StatusCodes.CREATED) {
        throw new Error(ADD_ORDER_SHIPPING_ERROR);
      }

      dispatch(
        notifySuccess(
          `${
            ordersCount === 1
              ? `${ordersCount} pedido foi adicionado`
              : `${ordersCount} pedidos foram adicionados`
          } à ${shippingName}`,
        ),
      );
      dispatch(resetPreShippmentOrders());
      loadAllOrders();
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(closeModal());
    }
  };

  const canDisableAddShippingOrders = () => {
    const preShippimentOrdersByTransport = groupBy(
      preShippimentOrders,
      'transport',
    );

    return Object.entries(preShippimentOrdersByTransport).length === 1;
  };

  const handleSearch = (e: any) => {
    handleGenericSearch(e, searchObject);
  };

  const loadOrdersPreShipping = useCallback(() => {
    dispatch(loadPreShippmentOrders());
  }, [dispatch]);

  useEffect(() => {
    loadOrdersPreShipping();
  }, [loadOrdersPreShipping]);

  useEffect(() => {
    loadShippingList();
  }, [loadShippingList]);

  useEffect(() => {
    setDateFilter([startOfMonth(new Date()), lastDayOfMonth(new Date())]);
  }, []);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Pedidos pré Remessa - Reenvios'));
  }, [dispatch]);

  useEffect(() => {
    loadAvailablePlans();
  }, [loadAvailablePlans]);

  useEffect(() => {
    loadAvailableRecurrences();
  }, [loadAvailableRecurrences]);

  useEffect(() => {
    dispatch(cleanOrdersList());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFilterSearch = () => {
    loadAllOrders();
    dispatch(resetPreShippmentOrders());
  };

  const handleSetTransportInResendOrders = async (
    transport: string,
    description: string,
  ) => {
    const orderIds = preShippimentOrders.map(order => order.id);
    await TransportService.handleSetTransportInResendOrders(
      transport,
      description,
      orderIds,
    );

    handleFilterSearch();
  };

  const handleSetCustomTransportInResendOrders = async () => {
    const orderIds = preShippimentOrders.map(order => order.id);
    const modalProps: SetCustomTransportDialogProps = {
      title: 'Definir Transportadora',
      message:
        'O nome da transportadora tem que ser exatamente como o Data frete retorna',
      orderIds,
    };

    dispatch(openModal(SetCustomTransportInResendOrdersDialog, modalProps));
  };

  const handleChangeMultiFilter = (value: any, filterName: string) => {
    if (!value.length) {
      handleClearPageFilter(filterName);
    } else {
      handleUpdateFilters(
        filterName,
        {
          filterTitle: value,
          value: value,
        },
        CondOperator.IN,
      );
    }
  };

  const handleSelectMultiStates = (event: any) => {
    const value = event?.target?.value;
    handleChangeMultiFilter(value, 'orderDetail.state');
  };

  const RenderGridActions = () => (
    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
      <MyDropdown
        buttonProps={{
          color: 'primary',
          variant: 'contained',
          style: {
            width: '100%',
            borderRadius: 13,
          },
        }}
        menuProps={{
          classes: {
            list: styles.filterOptionsList,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          PaperProps: {
            style: {
              marginTop: '1rem',
              borderRadius: 13,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
        menuItems={[
          {
            title: 'Definir seleções como Sedex',
            action: () =>
              handleSetTransportInResendOrders(
                TRANSPORT_COMPANIES.CORREIOS,
                SIGEP_SERVICES.SEDEX,
              ),
            disabled: !preShippimentOrders.length,
          },
          {
            title: 'Definir seleções como Pac',
            action: () =>
              handleSetTransportInResendOrders(
                TRANSPORT_COMPANIES.CORREIOS,
                SIGEP_SERVICES.PAC,
              ),
            disabled: !preShippimentOrders.length,
          },
          {
            title: 'Definir seleções como Módico',
            action: () =>
              handleSetTransportInResendOrders(
                TRANSPORT_COMPANIES.CORREIOS,
                SIGEP_SERVICES.MODICO,
              ),
            disabled: !preShippimentOrders.length,
          },
          {
            title: 'Definir seleções como DBA',
            action: () =>
              handleSetTransportInResendOrders(
                TRANSPORT_COMPANIES['DBA Express'],
                TRANSPORT_COMPANIES['DBA Express'],
              ),
            disabled: !preShippimentOrders.length,
          },
          {
            title: 'Definir seleções como Elohim',
            action: () =>
              handleSetTransportInResendOrders(
                TRANSPORT_COMPANIES.ELOHIM,
                TRANSPORT_COMPANIES.ELOHIM,
              ),
            disabled: !preShippimentOrders.length,
          },
          {
            title: 'Definir seleções com outra transportadora',
            action: () => handleSetCustomTransportInResendOrders(),
            disabled: !preShippimentOrders.length,
          },
          {
            title: 'Adicionar pedidos selecionados à Remessa',
            action: () => handleAddSelectedOrdersInShipping(),
            disabled: !canDisableAddShippingOrders(),
          },
          {
            title: 'Criar Remessa com pedidos selecionados',
            action: () => handleCreateNewShippingSelectedOrders(),
            disabled: !canDisableAddShippingOrders(),
          },
        ]}
      >
        <Typography
          variant="h3"
          style={{
            fontSize: '1.6rem',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            width: '100%',
            textAlign: 'left',
          }}
        >
          Ações
        </Typography>
      </MyDropdown>
    </Grid>
  );

  const RenderGridTablePagination = () => (
    <Grid container justify="space-between">
      <Grid item xs>
        <TablePagination
          ActionsComponent={MyTablePaginationActions}
          component="div"
          count={total}
          page={page}
          labelRowsPerPage="Itens por página"
          onChangePage={(_event: unknown, newPage: number) =>
            handleSetValuePage(newPage)
          }
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
            handleSetValuePage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
          }}
          rowsPerPageOptions={ROWS_PER_PAGE}
        />
      </Grid>
    </Grid>
  );

  return (
    <Grid container direction="column">
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={3}
        style={{ marginTop: 20, marginBottom: 10 }}
      >
        <Grid item>
          <Button style={{ width: '100%' }} onClick={handleClearFilters}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                color: Colors.RED,
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              Limpar filtros
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            style={{
              fontSize: '1.5em',
              height: '3.2em',
              borderRadius: 13,
            }}
            onClick={() => handleFilterSearch()}
          >
            Pesquisar
          </Button>
        </Grid>
        <RenderGridActions />
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          className={styles.gridAction}
          spacing={3}
          style={{ marginBottom: 5 }}
        >
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <MyDateRangeFilter
              value={dateFilter}
              onChange={(date: Date[]) => setDateFilter(date)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <MySelectFilter
              defaultOption={() =>
                handleClearPageFilter('orderDetail.orderType')
              }
              menuItems={Object.values(ORDER_TYPE).map(orderType => ({
                title: orderType,
                action: () =>
                  handleUpdateFilters(
                    'orderDetail.orderType',
                    {
                      filterTitle: orderType,
                      value: orderType,
                    },
                    CondOperator.EQUALS,
                  ),
                className: styles.menuOption,
              }))}
              buttonProps={{
                style: {
                  borderColor: pageFilters['orderDetail.orderType'].value
                    ? Colors.RED
                    : Colors.WHITE,
                },
              }}
            >
              <Typography
                variant="h3"
                color="textPrimary"
                style={{
                  fontSize: '1.6rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                {pageFilters['orderDetail.orderType'].filterTitle}
              </Typography>
            </MySelectFilter>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <MySelectFilter
              defaultOption={() => handleClearPageFilter('signature.planId')}
              menuItems={plans.map(plan => ({
                title: `${plan.id}- ${plan.name}`,
                action: () =>
                  handleUpdateFilters(
                    'signature.planId',
                    {
                      filterTitle: plan.name,
                      value: plan.id,
                    },
                    CondOperator.EQUALS,
                  ),
                className: styles.menuOption,
              }))}
              buttonProps={{
                style: {
                  borderColor: pageFilters['signature.planId'].value
                    ? Colors.RED
                    : Colors.WHITE,
                },
              }}
            >
              <Typography
                variant="h3"
                color="textPrimary"
                style={{
                  fontSize: '1.6rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                {pageFilters['signature.planId'].filterTitle}
              </Typography>
            </MySelectFilter>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <MySelectFilter
              defaultOption={() =>
                handleClearPageFilter('signature.plan.recurrenceId')
              }
              menuItems={recurrences.map(recurrence => ({
                title: recurrence.name,
                action: () =>
                  handleUpdateFilters(
                    'signature.plan.recurrenceId',
                    {
                      filterTitle: recurrence.name,
                      value: recurrence.id,
                    },
                    CondOperator.EQUALS,
                  ),
                className: styles.menuOption,
              }))}
              buttonProps={{
                style: {
                  borderColor: pageFilters['signature.plan.recurrenceId'].value
                    ? Colors.RED
                    : Colors.WHITE,
                },
              }}
            >
              <Typography
                variant="h3"
                color="textPrimary"
                style={{
                  fontSize: '1.6rem',
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                  width: '100%',
                  textAlign: 'left',
                }}
              >
                {pageFilters['signature.plan.recurrenceId'].filterTitle}
              </Typography>
            </MySelectFilter>
          </Grid>
          <Grid item xs={12} sm={6} md={2}>
            <MySelectTableFilter
              contracted
              itens={brStates.map(states => ({
                title: states.alias,
                value: states.value,
              }))}
              onChange={handleSelectMultiStates}
              value={pageFilters['orderDetail.state']?.value}
              helperText={null}
              title="Estados"
              error={false}
              multiple={true}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <PreShippmentOrdersListResend orders={orders} />
      </Grid>
      <Grid item xs>
        <RenderGridTablePagination />
      </Grid>
    </Grid>
  );
};

export default PreShipmentOrdersResend;
