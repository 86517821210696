import { call, put } from 'redux-saga/effects';
import {
  loadShippingListSuccess,
  loadShippingFail,
  loadShippingOneSuccess,
} from './actions';
import ShippingService from 'services/shippingService';
import {
  loadFile,
  loadFileFailure,
  loadFileSuccess,
} from '../generals/file/actions';
import FileService from 'services/fileservice';
import { createAndDownloadFile } from 'utils/functions/generals';

export function* loadShippingListSaga(action: any) {
  try {
    const { data, total, count, page, pageCount } = yield call(
      ShippingService.loadShippingList,
      action.payload.query,
    );
    yield put(
      loadShippingListSuccess({
        total,
        data,
        count,
        page,
        pageCount,
      }),
    );
  } catch (err) {
    yield put(loadShippingFail());
  }
}

export function* loadShippingOneSaga(action: any) {
  try {
    const shipping = yield call(
      ShippingService.loadShippingOne,
      action.payload,
    );
    yield put(loadShippingOneSuccess(shipping));
  } catch (err) {
    yield put(loadShippingFail());
  }
}

export function* loadShippngFileSaga(action: any) {
  try {
    yield put(loadFile());
    const file = yield call(
      FileService.getFile,
      action.payload.query,
      action.payload.rootPath,
    );
    if (file?.response?.status) {
      yield put(loadFileFailure());
    } else {
      yield put(loadFileSuccess());
      createAndDownloadFile(file, 'Remessas.xls');
    }
  } catch (error) {
    yield put(loadFileFailure());
  }
}
