import { CreateQueryParams } from '@nestjsx/crud-request';
import CrudList from 'components/generals/lists/CrudList';
import { StatusCodes } from 'http-status-codes';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { ColumnProps, ColumnTypes, Sort } from 'types';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import EnhancedProductService from 'services/enhancedProductService';
import { EnhancedProduct } from 'types/generals/enhancedProduct';
import {
  DELETE_ENHANCED_PRODUCT_ERROR,
  DELETE_ENHANCED_PRODUCT_SUCCESS,
} from 'utils/messages/enhancedProduct';

const EnhancedProducts: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [refresh, setRefresh] = useState(false);

  const handleOpenDeleteDialog = (eProduct: EnhancedProduct) => {
    const modalProps: BaseDialogProps = {
      title: 'Excluir permissão',
      message: `Tem certeza que deseja excluir o produto turbinado ${eProduct?.name}?`,
      actionFn: () => onDelete(eProduct),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const onDelete = async (eProduct: EnhancedProduct) => {
    const response: any = await EnhancedProductService.delete(eProduct?.id);
    if (![StatusCodes.OK].includes(response.status)) {
      dispatch(notifyError(DELETE_ENHANCED_PRODUCT_ERROR));
    } else {
      if (StatusCodes.OK === response.status) {
        dispatch(notifySuccess(DELETE_ENHANCED_PRODUCT_SUCCESS));
      }
      setRefresh(true);
    }
    dispatch(closeModal());
  };

  const columns: ColumnProps[] = [
    { label: 'Produto', getter: 'product.name', sortable: true },
    { label: 'Valor da turbinação', getter: 'price', sortable: true },
    {
      label: 'AÇÕES',
      sortable: true,
      onDelete: handleOpenDeleteDialog,
      type: ColumnTypes.DELETE,
    },
  ];
  const [defaultSort] = useState<Sort>({
    field: 'id',
    order: 'DESC',
  });
  const [queryParams, setQueryParams] = useState<CreateQueryParams>({
    join: ['product'],
    sort: defaultSort,
    page: 1,
    limit: 10,
  });

  return (
    <CrudList
      title="Produtos turbinados"
      url="/enhanced-products"
      columns={columns}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      searchField="name"
      searchFieldLabel="Nome"
      newButtonLabel="Novo produto turbinado"
      newButtonLinkTo="/admin/enhanced-products/new"
      onRowClick={item =>
        history.push(`/admin/enhanced-products/${item.id}`, {
          enhancedProduct: item,
        })
      }
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );
};

export default EnhancedProducts;
