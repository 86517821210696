import { User } from '.';

export interface BaseRankingSeasonConfig {
  id?: number;
  score: number;
  recurrence: number;
}
export interface RankingSeasonReferred extends BaseRankingSeasonConfig {
  error?: string;
}

export interface RankingSeasonReferral extends BaseRankingSeasonConfig {
  startPoint: number;
  endPoint: number;
  deleted?: boolean;
  error?: {
    score: string;
    startPoint: string;
    endPoint: string;
    '': string;
  };
}

export interface BaseRankingSeason {
  name: string;
  isActive: boolean;
  startDate: string | Date;
  endDate: string | Date;
  referral?: RankingSeasonReferral[];
  referred?: RankingSeasonReferred[];
  expireInMonths: number;
}

export interface RankingSeason extends BaseRankingSeason {
  id: number;
  createdAt: string;
  updatedAt: string;
}

export interface BaseEntity {
  id: number;
  createdAt: string;
  updatedAt: string;
  deletedAt: string;
}

export interface UserRankingSeason extends BaseEntity {
  isActive: boolean;
  type: string;
  observation: string;
  rankingSeasonId: number;
  referralId: number;
  score: number;
  userId: number;
  user?: Partial<User>;
  referralUser?: Partial<User>;
  planRecurrence: string;
}

export interface UserWallet extends BaseEntity {
  rankingSeasonId: number;
  score: number;
  userId: number;
}

export enum WalletTransactionsType {
  in = 'Recebeu',
  out = 'Removeu',
  used = 'Utilizou',
  pending = 'Pendente',
}
export interface WalletTransactions {
  id: number;
  userWalletId: number;
  score: number;
  type: WalletTransactionsType;
  description: string;
}

export interface UserRankingData {
  userId: number;
  totalScore: number;
  rankingPosition: number;
  name: string;
}

export interface UserScoreData {
  referralCode: string;
  totalScore: number;
  wallet: { score: number };
}

export enum UserRankingSeasonType {
  referral = 'Indicação',
  referred = 'Indicado',
  manualAddition = 'Adição Manual',
  manualDeduction = 'Remoção Manual',
}
