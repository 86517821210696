import React from 'react';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import { closeModal } from 'store/ducks/nav/actions';
import useStyles from 'components/generals/forms/register/styles';
import UserScoreHistory from 'pages/client/ranking/userRanking/UserScoreHistory';
import { User } from 'types/generals';

interface UserRankingHistoryDialogProps {
  user: User;
}

const UserRankingHistoryDialog: React.FC<UserRankingHistoryDialogProps> = ({
  user,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            Histórico de pontos
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <UserScoreHistory user={user} />
        </Grid>
      </DialogContent>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={2}
        className={styles.actionsWrapper}
      >
        <DialogActions>
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => dispatch(closeModal())}
          >
            Fechar
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default UserRankingHistoryDialog;
