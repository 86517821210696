import { createStyles, Grid, makeStyles, styled } from '@material-ui/core';

import { GreyText } from '../MyHeaderBar/styles';

import { ReactComponent as LogoSVG } from 'assets/img/svg/logo_mbc_text.svg';

import Colors from 'styles/colors';

export const Wrapper = styled(Grid)({
  width: '275px',
  height: '100vh',
  maxHeight: '100vh',
  backgroundColor: Colors.WHITE,
  position: 'fixed',
  top: 0,
  left: 0,
  overflow: 'hidden',
});

export const Logo = styled(LogoSVG)({
  height: 'auto',
  width: 130,
  fill: Colors.RED,
  marginLeft: '24px',
});

export const CustomGreyText = styled(GreyText)({
  cursor: 'pointer',
  margin: '14px 0 0 24px',
  fontWeight: 500,
  '&:hover': {
    textDecoration: 'underline',
  },
});

export const Footer = styled(Grid)({
  height: '100px',
  maxHeight: '100px',
  marginBottom: '40px',
});

export const useStyles = makeStyles(theme =>
  createStyles({
    sidebar: {
      overflowY: 'auto',
      overflowX: 'hidden',
    },
    termsAndConditionsLink: {
      textDecoration: 'none',
    },
  }),
);
