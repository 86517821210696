import React, { FC, MouseEvent, useState } from 'react';
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@material-ui/core';
import { Error, Visibility, VisibilityOff } from '@material-ui/icons';

import { PasswordInputProps } from './types';

const PasswordInput: FC<PasswordInputProps> = props => {
  const {
    error,
    helperText,
    inputLabel = 'Senha',
    id = 'standard-adornment-password',
    ...rest
  } = props;
  const [textMode, setTextMode] = useState<boolean>();

  const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  return (
    <FormControl error={error} variant="outlined" fullWidth>
      <InputLabel>{inputLabel}</InputLabel>
      <OutlinedInput
        {...rest}
        id={id}
        type={textMode ? 'text' : 'password'}
        endAdornment={
          <InputAdornment position="end">
            {error ? (
              <Error color="primary" style={{ width: '1em', height: '1em' }} />
            ) : (
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setTextMode(!textMode)}
                onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                {textMode ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            )}
          </InputAdornment>
        }
      />
      {helperText ? (
        <FormHelperText id="component-helper-text">{helperText}</FormHelperText>
      ) : null}
    </FormControl>
  );
};

export default PasswordInput;
