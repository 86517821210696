import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stepper,
  Typography,
  Step,
  StepLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { orderBy } from 'lodash';
import { format, parseISO } from 'date-fns';

import MyStepperIcon from '../stepper/MyStepperIcon';
import MyStepperVerticalConnector from '../stepper/MyStepperVerticalConnector';

import { closeModal } from 'store/ducks/nav/actions';
import { useStyles } from './useStyles';
import { Order } from 'types/generals';
import { LOG_ACTION } from 'types/generals/log';

const TimelineOrderModal: FC<{ order: Order }> = ({ order }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const _steps = order.logErrors?.filter(
    log => log.action && log.action !== null,
  );
  const steps = _steps.length ? orderBy(_steps, 'createdAt', 'desc') : [];
  const formatDate = (data: string) => {
    return format(parseISO(data), 'dd/MM/yyyy hh:mm:ss');
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between">
          <Typography variant="h1" color="textPrimary">
            Histórico do pedido {order.id}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Stepper
          activeStep={steps.length}
          connector={<MyStepperVerticalConnector />}
          orientation="vertical"
        >
          {steps.map((step, idx) => (
            <Step key={idx}>
              <StepLabel StepIconComponent={MyStepperIcon}>
                <Typography variant="h4" style={{ fontWeight: 500 }}>
                  {step.action === LOG_ACTION.CALLBACK_SAFE2PAY
                    ? `Callback safe2pay - ${step.body.Name}`
                    : step.message}
                </Typography>
                <Typography variant="subtitle2" className={styles.spanDate}>
                  {formatDate(step.createdAt) || '-'}
                  {step.user ? ` - ${step.user}` : null}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Grid>
  );
};

export default TimelineOrderModal;
