export const NEW_RANKING_SEASON_ERROR = `Erro ao tentar criar campanha`;
export const NEW_RANKING_SEASON_SUCCESS = `Campanha criada com sucesso.`;
export const RANKING_SEASON_UPDATE_SUCCESS = `Campanha atualizada com sucesso.`;
export const RANKING_SEASON_UPDATE_ERROR = `Erro ao tentar atualizar campanha.`;
export const RANKING_SEASON_DUPLICATE_ERROR = `Erro ao tentar duplicar campanha`;
export const RANKING_SEASON_DUPLICATE_SUCCESS = `Campanha duplicada com sucesso.`;
export const DELETE_RANKING_SEASON_SUCCESS = `Campanha deletada com sucesso.`;
export const DELETE_RANKING_SEASON_ERROR = `Erro ao tentar deletar campanha.`;

export const USER_RANKING_CODE_COPY_SUCCESS = `Código de indicação copiado com sucesso.`;

export const RANKING_SEASON_INVALID = 'Campanha invalida';
export const RANKING_SCORE_ADD_SUCCESS =
  'Pontos de campanha adicionados com sucesso';
export const RANKING_SCORE_ADD_ERROR = 'Falha ao adicionar pontos de campanha';
export const RANKING_SCORE_REMOVE_SUCCESS =
  'Pontos de campanha removidos com sucesso';
export const RANKING_SCORE_REMOVE_ERROR = 'Falha ao remover pontos de campanha';

export const RANKING_SCORE_ACTION_MESSAGE = `Tem certeza que deseja alterar os pontos do usuário?`;

export const DELETE_MODAL_TITLE = 'Excluir campanha';
export const DELETE_MODAL_MSG = (name: string) =>
  `Tem certeza que deseja excluir a campanha ${name}?`;

export const INVALID_RANKING_POINTS_NUMBER = 'Número inválido de pontos';
