import * as Yup from 'yup';
import { CondOperator } from '@nestjsx/crud-request';

export const validationSchema = Yup.object().shape(
  {
    name: Yup.string().required(),
    sku: Yup.string().required(),
    description: Yup.string().required(),
    recurrenceId: Yup.number().required(),
    bankslipPrice: Yup.number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable()
      .when('cardPrice', {
        is: (cardPrice, value) => !cardPrice && !value,
        then: (rule: any) =>
          rule.required('Um dos campos de preço precisa ser preenchido'),
      }),
    bankslipDiscount: Yup.number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      })
      .notRequired()
      .nullable()
      .typeError('O valor do produto deve ser no formato numérico'),
    cardPrice: Yup.number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable()
      .when('bankslipPrice', {
        is: (bankslipPrice, value) => !bankslipPrice && !value,
        then: (rule: any) =>
          rule.required('Um dos campos de preço precisa ser preenchido'),
      }),
    cardDiscount: Yup.number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      })
      .nullable()
      .notRequired()
      .typeError('O valor do produto deve ser no formato numérico'),
    message: Yup.string()
      .notRequired()
      .when('message', {
        is: value => value?.length,
        then: (rule: any) =>
          rule.max(20, 'Este campo deve ter no máximo 20 caracteres'),
      }),
    paymentType: Yup.string().required(),
    successPage: Yup.string(),
    image: Yup.array(),
  },
  [
    ['message', 'message'],
    ['bankslipPrice', 'cardPrice'],
    ['cardPrice', 'bankslipPrice'],
  ],
);

export const searchObject = [
  {
    field: 'name',
    operator: CondOperator.CONTAINS_LOW,
  },
];

export const PlansFilters = {
  name: { filterTitle: 'Nome do Plano', value: undefined },
};
