import React, { FC, memo } from 'react';
import { Grid } from '@material-ui/core';

import MyTypography from 'components/generals/labels/MyTypography';

import { useStyles } from './styles';
import { OrderItemProps } from './types';

const OrderInfo: FC<OrderItemProps> = memo(({ order }) => {
  const styles = useStyles();

  return (
    <Grid container justify="center" alignItems="center" direction="row">
      <Grid item xs>
        <MyTypography className={styles.orderInfoTitle}>Rastreio</MyTypography>
        <MyTypography variant="subtitle1" className={styles.trackingInfo}>
          {order.sigepLabel ? order.sigepLabel.trackingNumber : '-'}
        </MyTypography>
      </Grid>
    </Grid>
  );
});

export default OrderInfo;
