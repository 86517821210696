import React, { FC, useMemo } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types/TableTypes';

import { OrderItemProps } from './types';
import { useStyles } from './styles';
import MyTypography from 'components/generals/labels/MyTypography';
import { ORDER_TYPE } from 'types/generals';

const OrderItems: FC<OrderItemProps> = ({ order }) => {
  const styles = useStyles();
  const AMOUNT = order?.orderDetail?.amount;
  const BOXES = order?.orderDetail?.products;
  const ORDER_DETAIL = order?.orderDetail;

  const formatUnitPrice = (amount: number) =>
    formatColumnValue(FormatOptions.MONEY, amount);

  const formatAmount = useMemo(
    () => formatColumnValue(FormatOptions.MONEY, AMOUNT),
    [AMOUNT],
  );

  const ProductPrice = ({ item }: any) => {
    if (item.isEnhancedProduct) {
      return formatUnitPrice(item.productUnitPrice);
    }
    return (
      <>
        {ORDER_DETAIL.orderType === ORDER_TYPE.STORE || item.isGift
          ? formatUnitPrice(item.productAmount)
          : formatAmount}
      </>
    );
  };

  return (
    <Grid item xs={12} style={{ marginBottom: '25px' }}>
      <Paper className={styles.paperTable} elevation={0}>
        <MyTypography variant="h3" className={styles.paperInfoTitle}>
          Itens do Pedido
        </MyTypography>
        <TableContainer>
          <Table className={styles.table} aria-label="order items table">
            <TableHead>
              <TableRow>
                <TableCell>Item</TableCell>
                <TableCell>Quantidade</TableCell>
                <TableCell>Total</TableCell>
                <TableCell>Turbinado</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {BOXES &&
                BOXES.map((item, key) => (
                  <TableRow key={key}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.productCount}</TableCell>
                    <TableCell>
                      <ProductPrice item={item} />
                    </TableCell>
                    <TableCell>
                      {item.isEnhancedProduct ? 'Sim' : 'Não'}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default OrderItems;
