import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import ReactGA from 'react-ga';
import { Container } from '@material-ui/core';

import Header from './Header';
import CheckoutForms from './CheckoutForms';
import { useStyles } from './styles';
import { CreateAccountProps } from './types';

import { COUPON_TYPE } from 'components/generals/dialog/CouponDialog/types';
import { useSignature } from 'contexts/signatureContext';
import CouponsService from 'services/couponsService';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { CreateSignature, PAYMENT_TYPE, UTM } from 'types/generals';
import {
  SIGNATURE_CREATE_ERROR,
  LIMIT_EXCEEDED,
  DUE_DATE_ERROR,
} from 'utils/messages';
import OpenEndpointsService from 'services/openEndpointsService';
import { USER_CREATE_SUCCESS } from 'utils/messages/user';
import { generateQueryString } from './utils';
import CircularLoading from 'components/generals/loading/CircularLoading';
import { compareAsc } from 'date-fns';
import { useQuery } from 'routes/AuthRoutes';
import { getDueDateString } from 'helpers/formatters';

const CreateAccount: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const query = useQuery();
  const [referralCode] = useState<string>(query.get('codigoIndicacao') || '');

  const { hashPlan } = useParams<CreateAccountProps>();
  const {
    plan,
    loadPlan,
    createSignatureRequest,
    loading,
    enhancedProducts,
  } = useSignature();

  useEffect(() => {
    loadPlan(hashPlan);
  }, [loadPlan, hashPlan]);

  useEffect(() => {
    ReactGA.initialize('UA-112009147-1');
  }, []);

  useEffect(() => {
    localStorage.removeItem('leadId');
  }, []);

  const onSubmit = async (data: any) => {
    const {
      couponId,
      paymentType,
      repeatEmail,
      repeatPassword,
      coupon,
      ccBrand,
      cardName,
      dueDate,
      ccNumber,
      cvv,
      ..._data
    } = data;
    let userType;
    let dueDateCreditCard;

    if (_data.identification) {
      userType = _data.identification.length > 11 ? 'PJ' : 'PF';
    }

    const utm: UTM = {
      utmSource: query.get('utm_source'),
      utmMedium: query.get('utm_medium'),
      utmCampaign: query.get('utm_campaign'),
      utmContent: query.get('utm_content'),
      utmTerm: query.get('utm_term'),
      utmId: query.get('utm_id'),
    };

    const createSignature: CreateSignature = {
      user: {
        name: _data.name,
        email: _data.email,
        password: _data.password,
        identification: _data.identification,
        type: userType,
        phone: _data.phone,
      },
      address: {
        zipcode: _data.zipcode,
        street: _data.street,
        number: _data.number,
        neighborhood: _data.neighborhood,
        city: _data.city,
        state: _data.state,
        complement: _data.complement,
      },
      couponId,
      paymentType,
      hashPlan,
      referralCode: data.referralCode,
      enhancedProducts: enhancedProducts.map(eprod => eprod.id),
      utm,
    };

    if (paymentType === PAYMENT_TYPE.CARD) {
      dueDateCreditCard = new Date(
        `${data.dueYear}/${data.dueMonth}/01 00:00:00`,
      );
      createSignature.creditCard = {
        number: ccNumber,
        cardName: cardName,
        dueDate: getDueDateString(data),
        cvv,
      };
    }

    try {
      const successPage =
        plan.successPage || `/create-account/${hashPlan}/success`;

      if (coupon) {
        const { status } = await CouponsService.validateCoupon(
          coupon,
          COUPON_TYPE.Signature,
          _data.email,
        );

        if (status !== StatusCodes.CREATED) {
          throw new Error(LIMIT_EXCEEDED);
        }
      }

      if (dueDateCreditCard) {
        const validateDueDate = compareAsc(dueDateCreditCard, new Date());
        if (validateDueDate < 0) {
          dispatch(notifyError(DUE_DATE_ERROR));
          return;
        }
      }

      createSignatureRequest(true);
      const checkoutData = await OpenEndpointsService.createSignature(
        createSignature,
      );

      if (!checkoutData.id) {
        throw new Error(SIGNATURE_CREATE_ERROR);
      }

      const leadId = localStorage.getItem('leadId');

      if (leadId) {
        await OpenEndpointsService.updateLeadUser(Number(leadId), {
          registrationCompleted: true,
        });
      }

      localStorage.removeItem('leadId');

      localStorage.setItem('signatureId', checkoutData.id);
      if (plan.successPage) {
        const params = {
          nome: _data.name,
          rua: _data.street,
          Bairro: _data.neighborhood,
          numero: _data.number,
          complemento: _data.complement,
          cidade: _data.city,
          estado: _data.state,
          cep: _data.zipcode,
          tipoPagamento: paymentType,
          email: _data.email,
        };
        const queryString = generateQueryString(params);
        window.location.href = `${successPage}${queryString}`;
      } else {
        history.push(successPage);
      }

      dispatch(notifySuccess(USER_CREATE_SUCCESS));
    } catch (error) {
      createSignatureRequest(false);
      console.log(error);
      dispatch(notifyError(error.message));
    }
  };

  return (
    <Container className={classes.pageWrapper}>
      <Header />
      {loading ? (
        <CircularLoading isLoading={loading} title={'Carregando página'} />
      ) : (
        <CheckoutForms onSubmit={onSubmit} referralCode={referralCode} />
      )}
    </Container>
  );
};

export default CreateAccount;
