import * as Yup from 'yup';
import { verifyValidateCard } from 'helpers/creditCards';
import { CREDIT_CARD_MAX_LENGTH_NAME } from 'utils/messages';
import { securityCode } from 'utils/formYupFields';

export const validationSchema = Yup.object({
  paymentBankslip: Yup.boolean(),
  paymentCard: Yup.boolean().when('paymentBankslip', {
    is: (paymentBakslip, value) => !paymentBakslip && !value,
    then: Yup.boolean().required(
      'Você precisa selecionar uma forma de pagamento',
    ),
  }),
  creditCardId: Yup.string().when('paymentCard', {
    is: true,
    then: Yup.string().required(
      'Cartão obrigatório para esse tipo de pagamento',
    ),
  }),
});

export const validationSchemaCreditCard = Yup.object({
  newCardButton: Yup.boolean(),
  ccNumber: Yup.string().when('newCardButton', {
    is: true,
    then: Yup.string()
      .required('Número do cartão obrigatório')
      .min(14, 'Número do cartão deve conter no mínimo 14 dígitos'),
  }),
  cardName: Yup.string().when('newCardButton', {
    is: true,
    then: Yup.string()
      .required('Nome do cartão obrigatório')
      .max(25, CREDIT_CARD_MAX_LENGTH_NAME),
  }),
  ccBrand: Yup.string(),
  dueDate: Yup.string().when('newCardButton', {
    is: true,
    then: Yup.string()
      .required('Data de validade obrigatória')
      .test('invalid-duedate', 'Data vencida ou inválida', function (value) {
        if (!value) return this.createError();

        const validate = verifyValidateCard(value);
        if (validate < 1) return this.createError();
        return true;
      }),
  }),
  cvv: Yup.string().when('newCardButton', {
    is: true,
    then: securityCode,
  }),
});
