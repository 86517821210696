import React, { useState } from 'react';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import Autocomplete, {
  createFilterOptions,
} from '@material-ui/lab/Autocomplete';
import CloseIcon from '@material-ui/icons/Close';
import useStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { closeModal } from 'store/ducks/nav/actions';
import { useDialogStyles } from './useStyles';
import {
  AutoCompleteDialogFilter,
  AutoCompleteDialogProps,
} from './dialogTypes';
import * as Yup from 'yup';

const validationSchema = Yup.object({
  title: Yup.string().required(),
});

const filter = createFilterOptions<AutoCompleteDialogFilter>();

const AutoCompleteDialog: React.FC<AutoCompleteDialogProps> = props => {
  const styles = useStyles();
  const componentClasses = useDialogStyles();
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState<AutoCompleteDialogFilter | null>(
    null,
  );

  const {
    handleSubmit,
    getValues,
    setValue,
    errors,
    register,
    control,
  } = useForm({
    validationSchema,
  });

  const OptionMenu = (props: AutoCompleteDialogFilter) => {
    const { title } = props;
    const match = /(\w*Adicionar\w*)+(\W*"\W*)/g;

    const styleClass = match.test(title)
      ? styles.menuItemTextLink
      : styles.menuItemText;

    return <Typography className={styleClass}>{title}</Typography>;
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {props.modalTitle}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          className={componentClasses.mBottom}
          spacing={2}
          component="form"
        >
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h4"
              color="textPrimary"
              className={componentClasses.info}
            >
              {props.modalInfo}
            </Typography>
            {/* TODO: use 'MyAutoComplete' component validating submit on form */}
            <Controller
              as={
                <FormControl error>
                  <Autocomplete
                    freeSolo
                    id="auto-complete"
                    value={inputValue}
                    onChange={(e, newValue) => {
                      if (typeof newValue === 'string') {
                        setInputValue({
                          title: newValue,
                        });
                      } else if (newValue && newValue.inputValue) {
                        // handle to add new option in list
                        setInputValue({
                          title: newValue.inputValue,
                        });
                      } else {
                        setValue('title', newValue);
                        setInputValue(newValue);
                      }
                    }}
                    filterSelectedOptions
                    filterOptions={
                      props.onlySearch
                        ? undefined
                        : (options, params) => {
                            const filtered = filter(options, params);

                            if (params.inputValue !== '') {
                              filtered.push({
                                inputValue: params.inputValue,
                                title: `Adicionar "${params.inputValue}"`,
                              });
                            }
                            return filtered;
                          }
                    }
                    getOptionLabel={option => {
                      if (typeof option === 'string') return option;

                      if (option.inputValue) return option.inputValue;

                      return option.title;
                    }}
                    options={props.options || []}
                    renderOption={option => <OptionMenu title={option.title} />}
                    style={{ width: 530 }}
                    fullWidth
                    selectOnFocus
                    clearOnBlur={props.clearInputBlur}
                    renderInput={params => (
                      <MyOutlinedTextField
                        {...params}
                        name="title"
                        error={Boolean(errors.name)}
                        helperText={errors.name ? errors.name.message : null}
                        inputRef={register}
                        label={props.labelPlaceholder ?? 'Buscar'}
                        style={{ paddingTop: 4, paddingBottom: 8 }}
                      />
                    )}
                  />
                  {errors.title && (
                    <FormHelperText id="component-helper-text">
                      {errors.title.message}
                    </FormHelperText>
                  )}
                </FormControl>
              }
              name="title"
              control={control}
              error={Boolean(errors.title)}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={2}
        className={styles.actionsWrapper}
      >
        <DialogActions>
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={props.cancelAction || (() => dispatch(closeModal()))}
          >
            {props.cancelText ?? 'Cancelar'}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
            onClick={handleSubmit(() => props.submitAction(getValues('title')))}
          >
            {props.submitText ?? 'Confirmar'}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default AutoCompleteDialog;
