import { Reducer } from 'redux';
import { PreShippimentOrderState, PreShippimentOrderTypes } from './types';

const INITIAL_STATE: PreShippimentOrderState = {
  data: [],
  total: 0,
};

const preShippimentOrderReducer: Reducer<PreShippimentOrderState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case PreShippimentOrderTypes.UPDATE_PRE_SHIPPIMENT_ORDERS:
      return { data: action.payload.data, total: state.data.length };
    case PreShippimentOrderTypes.LOAD_PRE_SHIPPMENT_ORDERS:
      return { ...state };
    case PreShippimentOrderTypes.RESET_PRE_SHIPPIMENT_ORDERS:
      return { data: [], total: 0 };
    default:
      return state;
  }
};

export default preShippimentOrderReducer;
