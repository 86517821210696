import { addMinutes, compareAsc } from 'date-fns';

export const separateDate = (date: string) => {
  const separate_date = date.split('/');
  const day = +separate_date[0];
  const month = +separate_date[1];
  const year = +separate_date[2];

  return new Date(`${year}/${month}/${day} 00:00:00`);
};

export const separateDateToStringDate = (date: string) => {
  const separate_date = date.split('/');
  const day = separate_date[0];
  const month = separate_date[1];
  const year = separate_date[2];

  return `${year}-${month}-${day}`;
};

export const separateViewDateToStringDate = (date: string) => {
  const dateWithoutTime = date.split('T');
  const separate_date = dateWithoutTime[0].split('-');
  const day = separate_date[2];
  const month = separate_date[1];
  const year = separate_date[0];

  return `${day}/${month}/${year}`;
};

export const separateDateWithTrace = (date: string) => {
  if (date) {
    const separate_date = date.split('-');
    const day = +separate_date[2];
    const month = +separate_date[1];
    const year = +separate_date[0];
    return new Date(`${year}/${month}/${day} 00:00:00`);
  }
  return new Date();
};

export const convertDateToUTC = (date: Date): Date => {
  return addMinutes(date, date.getTimezoneOffset());
};

export const validateDate = (start_date: string, end_date?: string) => {
  const today = new Date();

  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  if (start_date && end_date) {
    return compareAsc(separateDate(end_date), separateDate(start_date));
  }
  if (start_date) {
    return compareAsc(separateDate(start_date), today);
  }
};

export const mountDateWithHour = (date: Date, hour: string) => {
  const hourSeparated = hour.split(':');

  const year = date.getFullYear();
  const month = date.getMonth();
  const day = date.getDate();
  const _hour = +hourSeparated[0];
  const minutes = +hourSeparated[1];

  return new Date(year, month - 1, day, _hour, minutes);
};
