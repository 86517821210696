import * as Yup from 'yup';

export const validationSchema = Yup.object({
  address: Yup.object({
    newAddress: Yup.boolean(),
    zipcode: Yup.string().when('newAddress', {
      is: true,
      then: Yup.string().required('Cep obrigatório'),
    }),
    street: Yup.string().when('newAddress', {
      is: true,
      then: Yup.string().required('Endereço obrigatório'),
    }),
    number: Yup.string().when('newAddress', {
      is: true,
      then: Yup.string().required('Número obrigatório'),
    }),
    neighborhood: Yup.string().when('newAddress', {
      is: true,
      then: Yup.string().required('Bairro obrigatório'),
    }),
    city: Yup.string().when('newAddress', {
      is: true,
      then: Yup.string().required('Cidade obrigatório'),
    }),
    state: Yup.string().when('newAddress', {
      is: true,
      then: Yup.string()
        .max(2, 'Só é permitido 2 dígitos, ex: RS')
        .required('Estado obrigatório'),
    }),
    complement: Yup.string(),
  }),
});
