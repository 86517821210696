import { AuthState, AuthTypes } from './types';
import { Reducer } from 'redux';
import { api, apiQueue } from 'services/apiService';

const INITIAL_STATE: AuthState = {
  isAuthenticated: false,
  isAdmin: false,
  accessToken: null,
  hasError: false,
  isLoading: false,
};

const reducer: Reducer<AuthState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AuthTypes.LOGIN_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        isAuthenticated: false,
        isAdmin: false,
        accessToken: null,
      };
    case AuthTypes.LOGIN_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    case AuthTypes.LOGIN_SUCCESS:
      api.defaults.headers.Authorization = `Bearer ${action.payload.data.accessToken}`;
      apiQueue.defaults.headers.Authorization = `Bearer ${action.payload.data.accessToken}`;
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isAuthenticated: true,
        isAdmin: action.payload.data.user.isAdmin,
        accessToken: action.payload.data.accessToken,
      };
    case AuthTypes.LOGOUT_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    case AuthTypes.LOGOUT_SUCCESS:
      delete api.defaults.headers.Authorization;
      delete apiQueue.defaults.headers.Authorization;
      return {
        ...state,
        isLoading: false,
        hasError: false,
        isAuthenticated: false,
        accessToken: null,
      };
    case AuthTypes.LOGOUT_REQUEST:
      return {
        ...state,
        isLoading: true,
        hasError: false,
      };
    default:
      return state;
  }
};

export default reducer;
