import React, { MutableRefObject, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, Button, Dialog, Grid, Typography } from '@material-ui/core';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import FeedbackDialog from 'components/generals/dialog/FeedbackDialog';
import { ReactComponent as SuccessIcon } from 'assets/img/svg/icon_active_sucess.svg';
import { ReactComponent as ErrorIcon } from 'assets/img/svg/icon_active_error.svg';

import AuthService, { FORGOT_VERIFY } from 'services/authService';

import { AppState } from 'store';
import { openModal, closeModal } from 'store/ducks/nav/actions';
import { Modal } from 'store/ducks/nav/types';

import { validationSchema } from './utils';

import useStyles from './styles';
import { useGetWithSWR } from 'services/apiService';

const NewPasswordClientForm = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const repeatPasswordInput = useRef() as MutableRefObject<HTMLInputElement>;

  const recoveryToken = location.pathname.split('/').pop();

  const {
    open,
    maxWidth,
    component: Component,
    props,
  } = useSelector<AppState, Modal>(({ nav }: AppState) => nav.modal);

  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onBlur',
    validationSchema,
  });

  const { error } = useGetWithSWR(`${FORGOT_VERIFY}/${recoveryToken}`);

  const handleMountMondalInfor = (title: string, success = false) => {
    return {
      title,
      message: (
        <Button
          variant="text"
          color="primary"
          onClick={() => {
            dispatch(closeModal());
            history.push('/login');
          }}
          classes={{ root: classes.feedbackButton }}
        >
          Voltar ao login
        </Button>
      ),
      disableCloseButton: true,
      icon: success ? SuccessIcon : ErrorIcon,
    };
  };

  const onSubmit = async ({ password }: Record<string, string>) => {
    try {
      setIsLoading(true);
      if (error) {
        const modalProps = handleMountMondalInfor(
          'Token de recuperação inválido',
        );
        dispatch(openModal(FeedbackDialog, modalProps));
      } else if (recoveryToken) {
        await AuthService.resetPasswordWithToken(recoveryToken, password);
        const modalProps = handleMountMondalInfor(
          'Sua senha foi alterada com sucesso!',
          true,
        );

        dispatch(openModal(FeedbackDialog, modalProps));
      }
    } catch (error) {
      const modalProps = handleMountMondalInfor('Algo de errado aconteceu');
      dispatch(openModal(FeedbackDialog, modalProps));
    }
    reset();
  };

  return (
    <Grid item xs={12} md={6} className={classes.containerWrapper}>
      <Typography className={classes.titleText}>Definir nova senha</Typography>
      <Typography className={classes.subtitleText}>
        Defina a nova senha de acesso à sua conta.
      </Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2} className={classes.mtop}>
          <Grid item xs={12}>
            <MyOutlinedTextField
              fullWidth
              name="password"
              label="Senha"
              type="password"
              changeFocusOnEnter={() => repeatPasswordInput.current.focus()}
              error={Boolean(errors.password)}
              helperText={errors.password ? errors.password.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12}>
            <MyOutlinedTextField
              fullWidth
              name="repeatPassword"
              label="Confirmar senha"
              type="password"
              inputProps={{ ref: repeatPasswordInput }}
              error={Boolean(errors.repeatPassword)}
              helperText={
                errors.repeatPassword ? errors.repeatPassword.message : null
              }
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              className={classes.button}
              disabled={isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>

      <Dialog maxWidth={maxWidth} open={open}>
        {Component ? <Component {...props} /> : null}
      </Dialog>
    </Grid>
  );
};

export default NewPasswordClientForm;
