import { makeStyles } from '@material-ui/core';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
  tableList: {
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  tabOptions: {
    padding: 0,
  },
  circle: {
    borderRadius: '50%',
    // padding: 0,
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 22,
    cursor: 'pointer',
  },
  tableRow: {
    cursor: 'pointer',
  },
});
