import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import AmountLabel from 'components/generals/labels/AmountLabel/AmountLabel';
import { CartAmountProps } from '../types';
import { useStyles } from '../styles';

const CartAmount: FC<CartAmountProps> = ({
  discount,
  freight,
  subtotal,
  total,
  scoreData,
}) => {
  const styles = useStyles();
  const _discount = scoreData.discount
    ? discount - scoreData.discount
    : discount;
  return (
    <Grid container item className={styles.sectionSpacing}>
      <AmountLabel title="Subtotal" amount={subtotal} />
      <AmountLabel title="Frete" amount={freight} />
      <AmountLabel isDiscount title="Desconto" amount={_discount.toString()} />
      {scoreData?.discount > 0 ? (
        <AmountLabel
          isDiscount
          title="Desconto por Pontos MBC"
          amount={scoreData.discount.toString()}
        />
      ) : null}
      <AmountLabel title="Total" amount={total} textBold isAmount />
    </Grid>
  );
};

export default CartAmount;
