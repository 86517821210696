import * as Yup from 'yup';
import { StoreProduct } from 'types/generals';

export const validationSchema = Yup.object().shape(
  {
    productName: Yup.string().required('Campo Obrigatório'),
    startDate: Yup.string().required('Campo Obrigatório'),
    endDate: Yup.string(),
    weight: Yup.number()
      .typeError('O valor do peso deve ser no formato numérico')
      .required('Campo Obrigatório'),
    price: Yup.number()
      .typeError('O valor do produto deve ser no formato numérico')
      .required('Campo Obrigatório'),
    images: Yup.array(),
    sku: Yup.string().required('Campo Obrigatório'),
    stock: Yup.number()
      .typeError('O valor do produto deve ser no formato numérico')
      .required('Campo Obrigatório'),
    discount: Yup.number()
      .transform((currentValue, originalValue) => {
        return originalValue === '' ? null : currentValue;
      })
      .notRequired()
      .nullable()
      .typeError('O valor do produto deve ser no formato numérico')
      .lessThan(Yup.ref('price'), 'Desconto não pode ser maior que o preço.'),
    tagName: Yup.string(),
    tagColor: Yup.string()
      .notRequired()
      .when('tagColor', {
        is: value => value?.length,
        then: (rule: any) =>
          rule.min(7, 'Este campo precisa ter 7 caracteres ex: #FFFFFF'),
      })
      .when('tagColor', {
        is: value => value?.length,
        then: (rule: any) =>
          rule.max(7, 'Este campo precisa ter 7 caracteres ex: #FFFFFF'),
      }),
    smallDescription: Yup.string()
      .required('Campo Obrigatório')
      .max(125, 'O campo deve ter no máximo 125 caracteres'),

    about: Yup.string().max(500, 'O campo deve ter no máximo 500 caracteres'),
    specifications: Yup.string(),
    keyword: Yup.string(),
    items: Yup.string(),
  },
  [['tagColor', 'tagColor']],
);

export const defaultValues = (product?: StoreProduct) => {
  return {
    productName: product?.name,
    startDate: product?.startDate,
    endDate: product?.endDate || '',
    weight: product?.weight,
    price: Number(product?.price),
    images: [],
    sku: product?.sku,
    stock: product?.stock,
    discount: Number(product?.discount),
    tagName: product?.tagName,
    tagColor: product?.tagColor,
    smallDescription: product?.smallDescription,
    about: product?.about,
    specifications: product?.specifications,
    keyword: product?.keyword,
    items: product?.items,
  };
};
