import {
  createStyles,
  Grid,
  makeStyles,
  Paper,
  styled,
  Typography,
} from '@material-ui/core';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import MySelectForm from 'components/generals/input/MySelectForm';

import Colors from 'styles/colors';

export const StyledPaper = styled(Paper)({
  padding: '24px 12px',
  borderRadius: 30,
  marginBottom: '48px',
});

export const Column = styled(Grid)({
  width: '50%',
  padding: '12px',
});

export const Container = styled(Grid)({
  padding: '24px 12px',
});

export const UserNameTitle = styled(Typography)({
  marginTop: '35px',
  marginBottom: '35px',
  fontSize: '25px',
  color: Colors.DARKER_GRAY,
  fontWeight: 600,
});

export const TextInput = styled(MyOutlinedTextField)({
  // margin: '8px 0',
  // borderRadius: '8px',
  // height: '64px',
});

export const StyledSelect = styled(MySelectForm)({
  margin: '8px 0',
  borderRadius: '8px',
  height: '64px',
});

export const SectionName = styled(Typography)({
  fontSize: 18,
  color: Colors.MEDIUM_GRAY,
  padding: '0 12px',
});

export const useStyles = makeStyles(theme =>
  createStyles({
    gridSignatureStatus: {
      marginTop: '-42px',
      fontWeight: 'bold',
      marginBottom: '21px',
    },
    signatureStatusText: {
      opacity: '0.8',
      fontWeight: 'bold',
    },
  }),
);
