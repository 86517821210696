import { RecurrencesTypes } from './types';
import { action } from 'typesafe-actions';
import { Recurrence } from 'types/generals';

export const loadRecurrences = () => {
  return action(RecurrencesTypes.LOAD_RECURRENCES_REQUEST);
};

export const loadRecurrencesSuccess = (payload: {
  total: number;
  data: Recurrence[];
}) => {
  return action(RecurrencesTypes.LOAD_RECURRENCES_SUCCESS, payload);
};

export const loadRecurrenceFailure = () => {
  return action(RecurrencesTypes.LOAD_RECURRENCES_FAILURE);
};
