import React, { FC, memo, useCallback } from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx';

import { StatusTag } from 'components/generals/tag/StatusTag';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { ProductItemProps } from './types';
import { useStyles } from './styles';

const ProductItem: FC<ProductItemProps> = memo(
  ({
    imgSrc,
    owned,
    checkOwned,
    releaseDate,
    title,
    price,
    productOrderStatus,
    callbackLink,
    stock,
  }) => {
    const styles = useStyles(owned);

    const handleFormatDate = useCallback((date: string) => {
      return formatColumnValue(FormatOptions.START_DATE, date);
    }, []);

    const handleFormatMoney = useCallback((price: string) => {
      return formatColumnValue(FormatOptions.MONEY, price);
    }, []);

    const hasOrdersStatus = !!productOrderStatus;
    return (
      <Paper className={styles.paperTable} elevation={0}>
        <Card elevation={0} style={{ height: '100%', position: 'relative' }}>
          <>
            {!stock && !hasOrdersStatus ? (
              <>
                <CardMedia
                  className={styles.img}
                  image={imgSrc}
                  component="img"
                  alt={`Imagem do produto ${title}`}
                />
                <Grid className={styles.stockOverlay} />
                {/* <Grid className={styles.stockContainer}>
                  <TagWrapper config={DEFINED_TAGS.INFO} value="Indisponível" />
                </Grid> */}
              </>
            ) : (
              <CardMedia
                className={styles.img}
                image={imgSrc}
                component="img"
                alt={`Imagem do produto ${title}`}
              />
            )}
          </>
          {/* {!owned && checkOwned ? (
            <>
              <Grid className={styles.container}>
                <CardMedia
                  className={styles.ownedProductImage}
                  image={imgSrc}
                  component="img"
                  alt={`Imagem do produto ${title}`}
                />
                <Grid className={styles.overlay} />
                <Grid className={styles.iconContainer}>
                  <img
                    src={CartIcon}
                    alt={'Ícone'}
                    style={{ height: '22px' }}
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              {!stock && !hasOrdersStatus ? (
                <>
                  <CardMedia
                    className={styles.img}
                    image={imgSrc}
                    component="img"
                    alt={`Imagem do produto ${title}`}
                  />
                  <Grid className={styles.stockOverlay} />
                  <Grid className={styles.stockContainer}>
                    <TagWrapper
                      config={DEFINED_TAGS.INFO}
                      value="Indisponível"
                    />
                  </Grid>
                </>
              ) : (
                <CardMedia
                  className={styles.img}
                  image={imgSrc}
                  component="img"
                  alt={`Imagem do produto ${title}`}
                />
              )}
            </>
          )} */}
          <CardContent className={styles.cardContent}>
            {releaseDate ? (
              <Typography variant="subtitle1" className={styles.boxSubtitle}>
                {handleFormatDate(releaseDate)}
              </Typography>
            ) : null}

            <Typography
              variant="h3"
              color="textPrimary"
              className={styles.boldText}
            >
              {title}
            </Typography>
          </CardContent>
          <CardActions className={clsx(styles.paddingLeft, styles.mTopButton)}>
            <Grid
              container
              item
              xs
              justify="space-between"
              alignItems="center"
              style={{ position: 'absolute', bottom: 0, left: 0 }}
            >
              {price && (
                <Grid item xs={7}>
                  <Typography
                    variant="h3"
                    color="textPrimary"
                    style={{ fontWeight: 600 }}
                  >
                    {handleFormatMoney(price.toString())}
                  </Typography>
                </Grid>
              )}
              <Grid
                container
                item
                xs={price ? 5 : 'auto'}
                justify={price ? 'flex-end' : 'flex-start'}
              >
                {productOrderStatus && !price ? (
                  <StatusTag status={productOrderStatus} />
                ) : (
                  <Button
                    onClick={() => callbackLink()}
                    className={styles.paddingLeft}
                  >
                    <Typography
                      variant="h4"
                      color="textPrimary"
                      className={styles.actionButton}
                    >
                      {price ? 'Ver detalhes' : 'Ver na loja'}
                    </Typography>
                  </Button>
                )}
              </Grid>
            </Grid>
          </CardActions>
        </Card>
      </Paper>
    );
  },
);

export default ProductItem;
