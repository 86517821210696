import { UserWallet } from 'types/generals/rankingSeason';
import { User } from 'types/generals/user';

/**
 * Action types
 */
export enum UserTypes {
  LOAD_STAFF_FILE_REQUEST = '@user/LOAD_STAFF_FILE_REQUEST',
  LOAD_REQUEST = '@user/LOAD_REQUEST',
  LOAD_SUCCESS = '@user/LOAD_SUCCESS',
  LOAD_FAILURE = '@user/LOAD_FAILURE',
  LOAD_STAFF_REQUEST = '@user/LOAD_STAFF_REQUEST',
  LOAD_STAFF_SUCCESS = '@user/LOAD_STAFF_SUCCESS',
  LOAD_STAFF_FAILURE = '@user/LOAD_STAFF_FAILURE',
  LOAD_USER_WALLET_REQUEST = '@user/LOAD_USER_WALLET_REQUEST',
  LOAD_USER_WALLET_SUCCESS = '@user/LOAD_USER_WALLET_SUCCESS',
  LOAD_USER_WALLET_FAILURE = '@user/LOAD_USER_WALLET_FAILURE',
  CLEAR_USER = '@user/CLEAR_USER',
}

/**
 * State types
 */
export interface UserState {
  readonly data: User | null;
  readonly staffs: User[];
  readonly wallet: UserWallet | null;
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly total: number;
}
