export enum LabelTypes {
  LOAD_REQUEST = '@label/LOAD_REQUEST',
  LOAD_SUCCESS = '@label/LOAD_SUCCESS',
  LOAD_FAILURE = '@label/LOAD_FAILURE',
  UPDATE = '@label/UPDATE',
}

export interface Label {
  id: number;
  createdAt: string;
  updatedAt: string;
  trackingNumber: string;
  trackingNumberWithoutSpace: string;
  serviceName: string;
  serviceNumber: string;
  isActive: boolean;
}

export interface Range {
  rangeStart: string;
  rangeEnd: string;
  serviceName: string;
  serviceNumber: string;
  alias: string;
}

export interface LabelState {
  readonly total: number;
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly data: Label[];
}

export interface LabelPayload {
  total: number;
  data: Label[];
}
