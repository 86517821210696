import React from 'react';
import {
  Button,
  ButtonProps,
  createStyles,
  makeStyles,
  Theme,
} from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    button: {
      height: 50,
      borderRadius: 13,
      fontSize: '1.5rem',
      fontWeight: 'bold',
      width: 156,
    },
  }),
);

const MyNewButton: React.FC<ButtonProps> = props => {
  const styles = useStyles();

  return (
    <Button
      variant="contained"
      color="primary"
      className={styles.button}
      {...props}
    >
      {props.children}
    </Button>
  );
};

export default MyNewButton;
