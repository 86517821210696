import { createStyles, makeStyles } from '@material-ui/core';

import Colors from 'styles/colors';

export const useCommonStyles = makeStyles(theme =>
  createStyles({
    rootPaper: {
      marginTop: '2em',
      padding: '4em',
      borderRadius: '2em',
      marginBottom: '3em',
    },
    actionButton: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      width: '100%',
      textAlign: 'left',
    },
    filterOptionsList: {
      backgroundColor: Colors.WHITE,
    },
    textBold: {
      fontWeight: 'bold',
    },
    marginTop10: {
      marginTop: 10,
    },
    width50p: {
      width: '50%',
    },
    textAlignCenter: {
      textAlign: 'center',
    },
    progress: {
      color: Colors.WHITE,
      marginLeft: theme.spacing(1),
    },
    boldText: {
      fontWeight: 'bold',
    },
    buttonWrapper: {
      padding: '0.5em 3em',
    },
    buttonLabel: {
      fontSize: '1.5em',
      height: '3.2em',
      borderRadius: 13,
    },
  }),
);
