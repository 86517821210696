import { call, put } from 'redux-saga/effects';
import OrdersService from 'services/ordersService';
import {
  loadOneOrderSuccess,
  loadOrdersFail,
  loadOrdersSuccess,
  loadUserOrderSuccess,
  loadOneOrder,
  loadOrderMonthSignatureSuccess,
} from './actions';

export function* loadOrdersSaga(action: any) {
  try {
    const { data, total } = yield call(
      OrdersService.getAllOrders,
      action.payload.query,
    );
    yield put(
      loadOrdersSuccess({
        data,
        total,
      }),
    );
  } catch (err) {
    yield put(loadOrdersFail());
  }
}

export function* loadOneOrderSaga(action: any) {
  try {
    const { ...order } = yield call(
      OrdersService.getOrder,
      action.payload.orderId,
      action.payload.query,
    );

    yield put(
      loadOneOrderSuccess({
        data: order,
      }),
    );
  } catch (err) {
    yield put(loadOrdersFail());
  }
}

export function* loadOrderMonthSignatureSaga(action: any) {
  try {
    const { ...order } = yield call(
      OrdersService.getSignatureOrderMonth,
      action.payload.signatureId,
    );

    yield put(
      loadOrderMonthSignatureSuccess({
        data: order,
      }),
    );
  } catch (err) {
    yield put(loadOrdersFail());
  }
}

export function* loadUserOrderSaga(action: any) {
  try {
    const { ...order } = yield call(
      OrdersService.getClientOrder,
      action.payload,
    );
    yield put(
      loadUserOrderSuccess({
        data: order,
      }),
    );
  } catch (err) {
    yield put(loadOrdersFail());
  }
}

export function* cancelOrderSaga(action: any) {
  try {
    const orderId = action.payload.orderId;
    yield call(OrdersService.cancelOrder, orderId, action.payload.message);
    yield put(
      loadOneOrder(orderId, {
        join: [['orderDetail'], ['payments'], ['sigepLabel'], ['shipping']],
      }),
    );
  } catch (err) {
    yield put(loadOrdersFail());
  }
}

export function* reactivateOrderSaga(action: any) {
  try {
    const orderId = action.payload.orderId;
    yield call(OrdersService.reactivateOrder, orderId);
    yield put(
      loadOneOrder(orderId, {
        join: [['orderDetail'], ['payments'], ['sigepLabel'], ['shipping']],
      }),
    );
  } catch (err) {
    yield put(loadOrdersFail());
  }
}
