import React, { useState } from 'react';
import {
  Grid,
  makeStyles,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Typography,
} from '@material-ui/core';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import MyTableCell from 'components/generals/table/MyTableCell';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MyTablePagination from 'components/generals/table/MyTablePagination';
import { ColumnProps, ColumnTypes, MyTableProps, Sort } from 'types/TableTypes';
import { getColumnValue } from 'helpers/formatters';

import theme from 'styles/theme';
import MyCircularLoading from 'components/generals/loading/CircularLoading';
import { RECURRENCES_MONTHS } from 'types/generals';
import styles from '../terms/styles';
import Colors from 'styles/colors';

const useStyles = makeStyles({
  rootContainer: {
    position: 'relative',
  },
  table: {
    minWidth: 650,
  },
  paperTable: {
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  containerTitle: {
    padding: theme.spacing(1, 4, 4, 4),
    borderRadius: 30,
  },
  gridAction: {
    marginBottom: theme.spacing(2.5),
  },
  tableCellBold: {
    fontWeight: 'bold',
  },
  tableCellTypography: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  tabOptions: {
    padding: 0,
  },
  circle: {
    borderRadius: '50%',
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 22,
    cursor: 'pointer',
  },
  buttonText: {
    color: Colors.RED,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
});

interface PrivateColumnProps extends ColumnProps {
  onSortClicked: (query: any) => void;
  sort: Sort;
}

export const ROWS_PER_PAGE = [10, 25, 50, 100, 250, 500];
export const ROWS_PER_PAGE_BULK_ACTION = [100, 250, 500, 1000, 2000];

const ColumnHeaderCell: React.FC<PrivateColumnProps> = props => {
  const { sortable, label, getter, sort, onSortClicked } = props;

  // Handle sort everytime a table header is clicked
  // Sort by one field only
  const handleSortClick = (field: string) => {
    let order: 'ASC' | 'DESC' = 'DESC';
    if (field === sort.field) {
      order = sort.order === 'ASC' ? 'DESC' : 'ASC';
    }
    onSortClicked({ field, order });
  };

  if (sortable) {
    if (getter) {
      return (
        <MySortableTableCell
          onClick={() => handleSortClick(getter)}
          direction={sort.field === getter && sort.order}
        >
          {label}
        </MySortableTableCell>
      );
    }
  }

  return <MyTableCell>{label}</MyTableCell>;
};

const MyTable: React.FC<MyTableProps> = props => {
  const classes = useStyles();
  const {
    data,
    columns,
    defaultSort,
    onSortClicked,
    onChangePage,
    total,
    page,
    rootContainerStyle,
    isLoading,
    selectRowsPerPage,
    handleChangeRowsPerPage = () => {},
    rowsPerPageOptions,
    title,
    onAddButton,
    addButtonLabel,
  } = props;

  // Sorting state
  const [sort, setSort] = useState<Sort>(
    defaultSort || {
      field: 'id',
      order: 'ASC',
    },
  );

  const _onSortClicked = (sort: Sort) => {
    if (onSortClicked) {
      onSortClicked(sort);
    }
    setSort(sort);
  };

  const getColumn = (column: ColumnProps, index: number, item: any) => {
    const onClick = (event: any) => {
      event.stopPropagation();
      if (column.onDelete) {
        column.onDelete(item);
      }
    };

    if (column.type === ColumnTypes.DELETE) {
      return (
        <MyTableCell key={item.id + index} className={classes.tabOptions}>
          <Grid container alignItems="center">
            <Button className={classes.circle} onClick={onClick}>
              <DeleteOutlinedIcon className={classes.icon} />
            </Button>
          </Grid>
        </MyTableCell>
      );
    }
    return (
      <MyTableCell key={item.id + index}>
        {getColumnValue(column, item)}
      </MyTableCell>
    );
  };

  return (
    <Grid
      container
      direction="row"
      spacing={3}
      className={classes.rootContainer}
      style={rootContainerStyle}
    >
      {title && (
        <Grid
          container
          justify="space-between"
          direction="row"
          alignItems="center"
          className={classes.containerTitle}
        >
          <Grid item>
            <Typography
              variant="h1"
              color="textPrimary"
              style={{ fontWeight: 600 }}
            >
              {title}
            </Typography>
          </Grid>
          {onAddButton && (
            <Grid item>
              <Button variant="text" onClick={onAddButton}>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  className={classes.buttonText}
                >
                  {addButtonLabel}
                </Typography>
              </Button>
            </Grid>
          )}
        </Grid>
      )}
      <MyCircularLoading isLoading={isLoading} />
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={classes.paperTable} elevation={0}>
            <TableContainer>
              <Table className={classes.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    {columns.map((column: ColumnProps, index: number) => (
                      <ColumnHeaderCell
                        key={index}
                        {...column}
                        sort={sort}
                        onSortClicked={_onSortClicked}
                      />
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data?.map((item: any) => (
                    <TableRow
                      hover={(props.onRowClick && true) || false}
                      onClick={() => props.onRowClick && props.onRowClick(item)}
                      key={item.id}
                      style={{
                        cursor: (props.onRowClick && 'pointer') || 'default',
                      }}
                    >
                      {columns.map((column: ColumnProps, index: number) =>
                        getColumn(column, index, item),
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              {data && (
                <MyTablePagination
                  ActionsComponent={MyTablePaginationActions}
                  rowsPerPageOptions={rowsPerPageOptions}
                  rowsPerPage={selectRowsPerPage}
                  count={total}
                  page={page - 1}
                  onChangePage={onChangePage}
                  onChangeRowsPerPage={handleChangeRowsPerPage}
                />
              )}
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

MyTable.defaultProps = {
  rootContainerStyle: {},
  page: 1,
  total: 0,
  rowsPerPageOptions: ROWS_PER_PAGE,
};

export default MyTable;
