import { Order } from 'types/generals/order';

export enum OrderTypes {
  CLEAN_ORDERS = '@order/CLEAN_ORDERS',
  LOAD_ORDERS_REQUEST = '@order/LOAD_ORDERS_REQUEST',
  LOAD_ONE_ORDER_REQUEST = '@order/LOAD_ONE_ORDER_REQUEST',
  LOAD_ORDERS_SUCCESS = '@order/LOAD_ORDER_SUCCESS',
  LOAD_ONE_ORDER_SUCCESS = '@order/LOAD_ONE_ORDER_SUCCESS',
  LOAD_MONTH_ORDER_SIGNATURE_REQUEST = '@order/LOAD_MONTH_ORDER_SIGNATURE_REQUEST',
  LOAD_MONTH_ORDER_SIGNATURE_SUCCESS = '@order/LOAD_MONTH_ORDER_SIGNATURE_SUCCESS',
  LOAD_FAILURE = '@order/LOAD_FAILURE',
  UPDATE = '@order/UPDATE',
  CANCEL = '@order/CANCEL',
  REACTIVATE = '@order/REACTIVATE',
}

export enum UserOrderTypes {
  LOAD_USER_ORDER = '@order/LOAD_USER_ORDER',
  LOAD_USER_ORDER_SUCCESS = '@order/LOAD_USER_ORDER_SUCCESS',
}

export interface OrderPayload {
  total?: number;
  data: Order[] | Order;
}

export interface OrderState {
  readonly total: number;
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly data: Order[];
}

export interface OrderOneState extends Omit<OrderState, 'data' | 'total'> {
  readonly data: Order;
}
