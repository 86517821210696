import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import { AppState } from 'store';
import { UserState } from 'store/ducks/user/types';
import { useSelector } from 'react-redux';
import { ScorePoints, useStyles } from './Header/styles';
import { useHistory } from 'react-router-dom';

const UserWalletScore: React.FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const { wallet } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const handleClick = () => {
    history.push('/user-ranking');
  };

  return (
    <Typography
      variant="h4"
      color="textPrimary"
      className={styles.userWalletScoreText}
      onClick={handleClick}
    >
      <Grid container direction="row" alignItems="center">
        <Grid item xs={2}>
          <ScorePoints />
        </Grid>
        <Grid item xs>
          <b>Pontos:</b> {wallet?.score || 0}
        </Grid>
      </Grid>
    </Typography>
  );
};

export default UserWalletScore;
