import { CondOperator } from '@nestjsx/crud-request';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import React, { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ROOT_PATH } from 'services/fileservice';
import { AppState } from 'store';
import { FileState } from 'store/ducks/generals/file/types';
import { loadRankingFile } from 'store/ducks/ranking/actions';

interface RankingActionsProps {
  sort: any;
  page: any;
  filterFields: any;
  genericFilterFields: any;
  selectedRanking: number;
}

const RankingActions: FC<RankingActionsProps> = ({ sort, selectedRanking }) => {
  const dispatch = useDispatch();

  const { hasError: hasErrorFile } = useSelector<AppState, FileState>(
    ({ file }: AppState) => file,
  );

  const loadHistoryReportXls = useCallback(() => {
    dispatch(
      loadRankingFile(
        {
          sort,
          filter: [
            {
              field: 'rankingSeasonId',
              operator: CondOperator.EQUALS,
              value: selectedRanking,
            },
          ],
        },
        ROOT_PATH.USER_RANKING_SEASON,
        ROOT_PATH.USER_RANKING_SEASON_HISTORY,
      ),
    );
  }, [dispatch, sort, selectedRanking]);

  const loadRankingXls = useCallback(() => {
    dispatch(
      loadRankingFile(
        {
          filter: [
            {
              field: 'rankingSeasonId',
              operator: CondOperator.EQUALS,
              value: selectedRanking,
            },
          ],
        },
        ROOT_PATH.USER_RANKING_SEASON,
        ROOT_PATH.USER_RANKING_SEASON_REPORT,
      ),
    );
  }, [dispatch, selectedRanking]);

  return (
    <ActionsButton
      hasErrorFile={hasErrorFile}
      menuItems={[
        {
          title: 'Exportar planilha de extrato',
          action: loadHistoryReportXls,
          disabled: !selectedRanking,
        },
        {
          title: 'Exportar planilha de ranking',
          action: loadRankingXls,
          disabled: !selectedRanking,
        },
      ]}
      buttonText="Ações"
    />
  );
};

export default RankingActions;
