import { Reducer } from 'redux';
import { UserState, UserTypes } from './types';

const INITIAL_STATE: UserState = {
  data: null,
  staffs: [],
  hasError: false,
  isLoading: false,
  total: 0,
  wallet: null,
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.LOAD_REQUEST:
    case UserTypes.LOAD_STAFF_REQUEST:
      return { ...state, isLoading: true };
    case UserTypes.LOAD_USER_WALLET_REQUEST:
      return { ...state };
    case UserTypes.LOAD_FAILURE:
    case UserTypes.LOAD_USER_WALLET_FAILURE:
    case UserTypes.LOAD_STAFF_FAILURE:
      return { ...state, isLoading: false, hasError: true, data: null };
    case UserTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        data: action.payload.data,
      };
    case UserTypes.LOAD_STAFF_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        staffs: action.payload.data,
        total: action.payload.total,
      };
    case UserTypes.LOAD_USER_WALLET_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        wallet: action.payload.data,
      };
    case UserTypes.CLEAR_USER:
      return { ...state, isLoading: false, hasError: false, data: null };
    default:
      return state;
  }
};

export default reducer;
