export const PLAN_NOT_FOUND = 'Plano não encontrado';
export const CONFIRM_PLAN_CHANGE =
  'Deseja a confirmar a alteração do seu plano atual';
export const RENEWAL_PLAN_DATE = (date: string) =>
  `Seu novo plano começa em ${date}`;
export const PLAN_TERMS =
  'Ao confirmar a alteração de plano você concorda com os ';
export const CHANGE_PLAN_SUCCESS = (planName?: string, startDate?: string) =>
  `A troca para o plano ${planName} foi programada para a data ${startDate}.`;
export const CHANGE_PLAN_ERROR =
  'Houve uma falha ao trocar o plano da sua assinatura. Por favor, tente novamente';
export const NEXT_PLAN_CANCEL = 'Troca de plano cancelada.';
export const NEXT_PLAN_CANCEL_ERROR = 'Erro ao tentar cancelar troca de plano';
export const CHANGE_PLAN_SOLICITED_TODAY =
  'Atenção! Você já solicitou uma mudança de plano hoje, entre em contato com o atendimento para saber mais.';
export const CREATE_PLAN_SUCCESS = 'Plano criado com sucesso';
export const CREATE_PLAN_ERROR = 'Falha ao criar o plano';
export const UPDATE_PLAN_SUCCESS = 'Plano editado com sucesso';
export const PLAN_CANT_USE_RECURRENCE =
  'Este tipo de plano não pode usar essa recorrência';
export const UPDATE_PLAN_ERROR = 'Falha ao editar o plano desejado';
