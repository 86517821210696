import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootGrid: { marginBottom: 20 },
    btnCancel: {
      marginRight: 30,
    },
  }),
);
