import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  spanDate: {
    color: Colors.GRAY,
  },
  boldText: {
    fontWeight: 'bold',
  },
});
