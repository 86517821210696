export interface DataFreteQuoteFreight {
  tp_entrega: string;
  nome_transportador: string;
  cnpj_transportador: string;
  cod_transportador: string;
  descricao: string;
  valor_frete_exibicao: number;
  valor_frete: number;
  valor_icms: number;
  aliquota_icms: number;
  prazo_exibicao: number;
  prazo: number;
  data_simulacao: string;
  hora_simulacao: string;
  cod_tabela: string;
}

export interface DataFreteTransportData {
  codigo_retorno: number;
  data: Array<DataFreteQuoteFreight>;
  id_cotacao: string;
}

export interface DataFreteProductsPayload {
  descricao: string;
  sku: string;
  peso: number;
  altura: number;
  largura: number;
  comprimento: number;
  preco: number;
  qtd: number;
  volume: number;
}

export const DefaultValueToDataFrete = {
  altura: 0.065,
  largura: 0.165,
  comprimento: 0.249,
  volume: 1,
};
