import React, { useEffect, useState } from 'react';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';

import { closeModal } from 'store/ducks/nav/actions';
import useStyles from 'components/generals/forms/register/styles';
import CrudList from 'components/generals/lists/CrudList';
import { CondOperator, CreateQueryParams } from '@nestjsx/crud-request';
import { ColumnProps } from 'types';
import { AppState } from 'store';
import { RankingState } from 'store/ducks/ranking/types';
import MyInputFilter from 'components/generals/input/MyInputFilter';

const UserRankingDialog: React.FC = () => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [searchText, setSearchText] = useState('');

  const { activeRanking } = useSelector<AppState, RankingState>(
    ({ ranking }: AppState) => ranking,
  );

  const columns: ColumnProps[] = [
    { label: 'NOME', getter: 'name', sortable: false },
    {
      label: 'Posição',
      getter: 'rankingPosition',
      sortable: false,
    },
    {
      label: 'Pontuação',
      getter: 'totalScore',
      sortable: false,
    },
  ];
  const [queryParams, setQueryParams] = useState<CreateQueryParams>({
    page: 1,
    limit: 10,
  });

  useEffect(() => {
    if (activeRanking?.id) {
      setQueryParams({
        filter: [
          {
            field: 'rankingSeasonId',
            operator: CondOperator.EQUALS,
            value: activeRanking?.id,
          },
          {
            field: 'name',
            operator: CondOperator.EQUALS,
            value: searchText,
          },
        ],
        page: 1,
        limit: 10,
      });
    }
  }, [activeRanking, searchText]);

  const handleSetSearchText = (event: any) => {
    const isEnter = event.key === 'Enter' || event.keyCode === '13';
    const value = event?.target?.value;
    if (isEnter) {
      setSearchText(value);
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            Ranking
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          {queryParams?.filter ? (
            <CrudList
              title="Campanhas"
              url="/user-ranking-season/list-rank"
              columns={columns}
              queryParams={queryParams}
              setQueryParams={setQueryParams}
              searchField="name"
              searchFieldLabel="Nome"
              refresh={refresh}
              showSearch={false}
              setRefresh={setRefresh}
            >
              <Grid
                container
                item
                xs
                direction="row"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs={5} md={3}>
                  <MyInputFilter
                    onKeyUp={e => handleSetSearchText(e)}
                    placeholder="Nome"
                  />
                </Grid>
              </Grid>
            </CrudList>
          ) : null}
        </Grid>
      </DialogContent>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={2}
        className={styles.actionsWrapper}
      >
        <DialogActions>
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => dispatch(closeModal())}
          >
            Fechar
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default UserRankingDialog;
