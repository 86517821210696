import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Grid, Button, CircularProgress } from '@material-ui/core';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';

import { get } from 'lodash';
import {
  defaultNotificationsBulkAction,
  defaultNotificationsLogistic,
  defaultNotificationsPaymentSignature,
  defaultNotificationsPaymentStore,
  defaultNotificationsSchedule,
} from './utils';
import { useStyles } from './styles';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { AppState } from 'store';
import NotificationService from 'services/notificationService';
import { StoreNotificationState } from 'store/ducks/store/storeNotification/types';
import {
  endNotificationRequest,
  initNotificationRequest,
  loadNotifications,
} from 'store/ducks/store/storeNotification/actions';
import {
  NOTIFICATION_GROUP,
  StoreNotification,
} from 'types/generals/notification';
import NotificationDialog from 'components/generals/dialog/NotificationDialog';
import { NotificationDialogProps } from 'components/generals/dialog/NotificationDialog/types';
import { openModal } from 'store/ducks/nav/actions';
import GeneralNotifications from './GeneralNotifications';
import PaymentNotifications from './PaymentNotifications';

const StoreNotifications: React.FC = () => {
  const { data: storeNotifications, isLoading: isLoadingRequest } = useSelector<
    AppState,
    StoreNotificationState
  >(({ storeNotification }: AppState) => storeNotification);

  const dispatch = useDispatch();
  const styles = useStyles();

  const [logisticsNotifications, setLogisticsNotifications] = useState<
    StoreNotification[]
  >(defaultNotificationsLogistic);

  const [storePaymentNotifications, setStorePaymentNotifications] = useState<
    StoreNotification[]
  >(defaultNotificationsPaymentStore);

  const [scheduleNotifications, setScheduleNotifications] = useState<
    StoreNotification[]
  >(defaultNotificationsSchedule);

  const [bulkActionNotifications, setBulkActionNotifications] = useState<
    StoreNotification[]
  >(defaultNotificationsBulkAction);

  const [
    signaturePaymentNotifications,
    setSignaturePaymentNotifications,
  ] = useState<StoreNotification[]>(defaultNotificationsPaymentSignature);

  const getNotifications = useCallback(async () => {
    dispatch(
      loadNotifications({
        sort: [
          {
            field: 'id',
            order: 'ASC',
          },
        ],
      }),
    );
  }, [dispatch]);

  const getInitNotificationGroupValue = (
    storeNotifications: StoreNotification[],
    group: NOTIFICATION_GROUP,
    defaultValue: StoreNotification[],
  ) => {
    const _notification = storeNotifications.filter(not => not.group === group);
    return _notification.length ? _notification : defaultValue;
  };

  useEffect(() => {
    getNotifications();
    dispatch(actUpdatePageTitle('Notificações'));
  }, [dispatch, getNotifications]);

  useEffect(() => {
    if (storeNotifications?.length) {
      setLogisticsNotifications(
        storeNotifications.filter(
          not => not.group === NOTIFICATION_GROUP.LOGISTICS,
        ),
      );
      setStorePaymentNotifications(
        storeNotifications.filter(
          not => not.group === NOTIFICATION_GROUP.PAYMENT_STORE,
        ),
      );
      setSignaturePaymentNotifications(
        storeNotifications.filter(
          not => not.group === NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
        ),
      );
      setScheduleNotifications(
        storeNotifications.filter(
          not => not.group === NOTIFICATION_GROUP.SCHEDULE,
        ),
      );
      const bulkAction = getInitNotificationGroupValue(
        storeNotifications,
        NOTIFICATION_GROUP.BULK_ACTION,
        defaultNotificationsBulkAction,
      );
      setBulkActionNotifications(bulkAction);
    }
  }, [storeNotifications.length, storeNotifications]);

  const saveNotifications = async () => {
    try {
      const payload: any = {
        notifications: [
          ...logisticsNotifications,
          ...signaturePaymentNotifications,
          ...storePaymentNotifications,
          ...scheduleNotifications,
          ...bulkActionNotifications,
        ],
      };
      dispatch(initNotificationRequest());
      await NotificationService.saveNotifications(payload);
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = async () => {
    try {
      await saveNotifications();
      getNotifications();
      dispatch(endNotificationRequest());
      dispatch(notifySuccess('Notificações cadastradas com sucesso'));
    } catch (error) {
      dispatch(notifyError('Não foi possivel concluir o cadastro'));
    }
  };

  const changeNotificationData = (
    targetNotification: StoreNotification,
    notificationToChange: StoreNotification,
    field: string,
  ) => {
    if (targetNotification.type === notificationToChange.type) {
      let newNotification = {
        ...targetNotification,
        permissions: {
          ...targetNotification.permissions,
          [field]: !get(targetNotification.permissions, field),
        },
      };
      return newNotification;
    }
    return targetNotification;
  };

  const changeNotificationDataTexts = (
    targetNotification: StoreNotification,
    notificationToChange: StoreNotification,
  ) => {
    return targetNotification.type === notificationToChange.type
      ? notificationToChange
      : targetNotification;
  };

  const getNotificationArray = (notification: StoreNotification): any => {
    switch (notification.group) {
      case NOTIFICATION_GROUP.LOGISTICS:
        return [logisticsNotifications, setLogisticsNotifications];
      case NOTIFICATION_GROUP.PAYMENT_SIGNATURE:
        return [
          signaturePaymentNotifications,
          setSignaturePaymentNotifications,
        ];
      case NOTIFICATION_GROUP.PAYMENT_STORE:
        return [storePaymentNotifications, setStorePaymentNotifications];
      case NOTIFICATION_GROUP.SCHEDULE:
        return [scheduleNotifications, setScheduleNotifications];
      case NOTIFICATION_GROUP.BULK_ACTION:
        return [bulkActionNotifications, setBulkActionNotifications];
    }
  };

  const handleChangePermissions = (
    notification: StoreNotification,
    field: string,
  ) => {
    const [notificationArray, setArray] = getNotificationArray(notification);
    const newArray = notificationArray.map((_notification: StoreNotification) =>
      changeNotificationData(_notification, notification, field),
    );
    setArray(newArray);
  };

  const handleEditText = (notification: StoreNotification) => {
    const [notificationArray, setArray] = getNotificationArray(notification);
    const newArray = notificationArray.map((_notification: StoreNotification) =>
      changeNotificationDataTexts(_notification, notification),
    );
    setArray(newArray);
  };
  const handleOpenEdit = (notification: StoreNotification) => {
    const modalProps: NotificationDialogProps = {
      title: 'Editar notificação',
      notification,
      handleAction: handleEditText,
      cancelText: 'Ok',
    };
    dispatch(openModal(NotificationDialog, modalProps));
  };

  return (
    <Grid container direction="column">
      <GeneralNotifications
        title="Notificações de logística"
        notifications={logisticsNotifications}
        handleOpenEdit={handleOpenEdit}
        handleChangePermissions={handleChangePermissions}
      />

      <PaymentNotifications
        signatureNotifications={signaturePaymentNotifications}
        storeNotifications={storePaymentNotifications}
        handleOpenEdit={handleOpenEdit}
        handleChangePermissions={handleChangePermissions}
      />
      <GeneralNotifications
        title="Notificações recorrentes"
        notifications={scheduleNotifications}
        handleOpenEdit={handleOpenEdit}
        useTypeForText={true}
        handleChangePermissions={handleChangePermissions}
      />

      <GeneralNotifications
        title="Notificações em massa"
        notifications={bulkActionNotifications}
        handleOpenEdit={handleOpenEdit}
        useTypeForText={true}
        handleChangePermissions={handleChangePermissions}
      />

      <Grid
        container
        direction="row"
        justify="flex-end"
        spacing={2}
        className={styles.footerGridContainer}
      >
        <Grid item xs>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={clsx(styles.buttonLabel, styles.buttonWrapper)}
            disabled={isLoadingRequest}
            onClick={onSubmit}
          >
            {isLoadingRequest ? <CircularProgress size={20} /> : 'Salvar'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreNotifications;
