import React, { FC, memo, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

import { openModal } from 'store/ducks/nav/actions';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import BulkChangePlanDialog from 'components/generals/dialog/BulkChangePlanDialog';
import { SignatureBulkDialogProps } from 'components/generals/dialog/dialogTypes';
import { loadRecurrences } from 'store/ducks/recurrences/actions';
import BulkCreateOrderDialog from 'components/generals/dialog/BulkCreateOrderDialog';

interface BAActionsProps {
  signatureIds: number[];
}
const BAActionsReactivation: FC<BAActionsProps> = memo(({ signatureIds }) => {
  const dispatch = useDispatch();
  const style = useStyles();

  const handleOpenChangePlanDialog = () => {
    const dialogProps: SignatureBulkDialogProps = {
      modalTitle: 'Trocar planos',
      submitText: 'Confirmar',
      signatureIds: signatureIds,
    };
    dispatch(loadRecurrences());
    dispatch(openModal(BulkChangePlanDialog, dialogProps));
  };

  const handleOpenCreateOrderDialog = () => {
    const dialogProps: SignatureBulkDialogProps = {
      modalTitle: 'Criar pedidos',
      submitText: 'Confirmar',
      signatureIds: signatureIds,
    };
    dispatch(openModal(BulkCreateOrderDialog, dialogProps));
  };

  return (
    <MyDropdown
      buttonProps={{
        color: 'primary',
        variant: 'contained',
        className: style.filterOptionsButton,
        disabled: signatureIds.length === 0,
      }}
      menuProps={{
        classes: {
          list: style.filterOptionsList,
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        PaperProps: {
          style: {
            marginTop: '1rem',
            borderRadius: 13,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }}
      menuItems={[
        {
          title: 'Trocar de plano',
          action: handleOpenChangePlanDialog,
        },
        {
          title: 'Criar pedido',
          action: handleOpenCreateOrderDialog,
        },
      ]}
    >
      <Typography variant="h3" className={style.actionButton}>
        Ações
      </Typography>
    </MyDropdown>
  );
});

export default BAActionsReactivation;
