import React, { FC, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { Button, Grid } from '@material-ui/core';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';

import { loadOneSignature } from 'store/ducks/generals/signature/oneSignature/actions';
import useFormStyles from 'components/generals/forms/register/styles';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import ResendAddress from './ResendAddress';
import NewResendProducts from './NewResendProducts';
import { PageTitle } from './styles';
import { OrderResendLocation } from './types';
import ProductsToResend from './ProductsToResend';
import {
  Address,
  ResendOrder,
  ResendProduct,
  ResendProductPayload,
} from 'types/generals';
import { defaultUserValues, validationSchema } from './utils';
import {
  RESEND_ORDER_FAIL,
  RESEND_ORDER_SUCCESS,
  RESEND_EMPTY_PRODUCTS,
} from 'utils/messages';
import OrdersService from 'services/ordersService';
import { StatusCodes } from 'http-status-codes';
import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { UNMASK } from 'helpers/masks';

const OrderResend: FC = () => {
  const { order } = useLocation<OrderResendLocation>().state;
  const orderProducts = order.orderDetail.products;
  const referenceOrderIds: number[] = [order.id];
  const [saveAddress, setSaveAddress] = useState<boolean>(false);
  const [resendProductsView, setResendProductsView] = useState<ResendProduct[]>(
    [],
  );
  const dispatch = useDispatch();
  const styles = useFormStyles();
  const history = useHistory();

  const { data: subscriber } = useSelector<AppState, SingleSignatureState>(
    ({ oneSignature }: AppState) => oneSignature,
  );

  const orderUser = subscriber.user;
  const { control, errors, getValues, reset, register, handleSubmit } = useForm(
    {
      validationSchema,
      defaultValues: defaultUserValues(order.orderDetail),
    },
  );

  useEffect(() => {
    dispatch(actUpdatePageTitle('Reenvio de pedido'));
    dispatch(loadOneSignature(order.signature.id.toString()));
  }, [dispatch, order]);

  const handleRedirect = (url: string) => history.push(url);

  const handleFormatZipcode = (value: string) => {
    return UNMASK.onlyDigits(value);
  };

  const submitOrderResend = async () => {
    try {
      const productDetails: ResendProductPayload[] = resendProductsView.map(
        ({ id, quantity }) => ({ id, productCount: quantity }),
      );

      if (!productDetails.length) throw new Error(RESEND_EMPTY_PRODUCTS);

      const address: Partial<Address> = {
        zipcode: handleFormatZipcode(getValues('address.zipcode')),
        street: getValues('address.street'),
        number: getValues('address.number'),
        neighborhood: getValues('address.neighborhood'),
        city: getValues('address.city'),
        state: getValues('address.state'),
        complement: getValues('address.complement') || '',
      };
      const resendReason: string = getValues('resendReason') || '';
      const transport = getValues('transport');

      const resendPayload: ResendOrder = {
        userId: orderUser.id,
        canSaveAddress: saveAddress,
        address,
        productDetails,
        referenceOrderIds,
        orderType: order.orderDetail.orderType,
        resendReason,
        transport,
      };

      const data = await OrdersService.createResendOrder(resendPayload);
      if (data.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(RESEND_ORDER_SUCCESS));
        handleRedirect(`/admin/orders`);
      } else throw new Error(RESEND_ORDER_FAIL);
    } catch (err) {
      dispatch(notifyError(err.message));
    }
  };

  return (
    <Grid container>
      <Grid
        container
        justify="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid item xs>
          <PageTitle>Pedido ref. {order.id}</PageTitle>
        </Grid>
        <Grid container item xs justify="flex-end">
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => handleRedirect(`/admin/orders/${order.id}`)}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={clsx(styles.buttonLabel, styles.buttonWrapper)}
            onClick={handleSubmit(submitOrderResend)}
          >
            Reenviar
          </Button>
        </Grid>
      </Grid>
      <ResendAddress
        control={control}
        errors={errors}
        register={register}
        reset={reset}
        canSaveAddress={saveAddress}
        handleSaveAddress={setSaveAddress}
      />
      <NewResendProducts
        resendProducts={resendProductsView}
        appendProductsHandler={setResendProductsView}
      />
      <ProductsToResend appendProductsHandler={setResendProductsView} />
    </Grid>
  );
};

export default OrderResend;
