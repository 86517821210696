import { call, put } from 'redux-saga/effects';
import LabelService from 'services/sigep/labelService';
import { loadLabelsFail, loadLabelsSuccess } from './actions';

export function* loadLabelsSaga(action: any) {
  try {
    const { data, total } = yield call(
      LabelService.getAll,
      action.payload.query,
    );
    yield put(loadLabelsSuccess({ data, total }));
  } catch (err) {
    yield put(loadLabelsFail());
  }
}
