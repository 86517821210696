import { call, put } from 'redux-saga/effects';
import { api } from 'services/apiService';
import {
  loginFailure,
  loginSuccess,
  logoutSuccess,
  logoutFailure,
} from './actions';
import { AppAction } from '../../types';
import { notifyError } from '../notification/actions';
import { clearUserData } from '../user/actions';
import { clearSignature } from '../generals/signature/oneSignature/actions';
import { persistor } from 'store';

export function* authenticateUserSaga(action: AppAction) {
  try {
    const response = yield call(api.post, '/auth/login', action.payload);
    yield put(loginSuccess(response?.data));
  } catch (err) {
    persistor.purge();
    yield put(notifyError('Não foi possível realizar o login'));
    yield put(loginFailure());
  }
}

export function* unauthenticateUserSaga() {
  try {
    yield call(api.post, '/auth/logout');
    yield put(logoutSuccess());
    yield put(clearUserData());
    yield put(clearSignature());
    // Resets intercom and start a new session
    window && (window as any).Intercom('shutdown');
    window && (window as any).Intercom('boot', { app_id: 'hvrwg5d4' });
    persistor.purge();
  } catch (err) {
    yield put(logoutFailure());
  }
}
