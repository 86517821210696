import React, { FC } from 'react';
import { Grid } from '@material-ui/core';
import { useCommonStyles } from 'styles/common';
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

interface SocialShareButtonsProps {
  url: string;
  text?: string;
}

const SocialShareButtons: FC<SocialShareButtonsProps> = ({
  url,
  text = `Vem fazer parte do maior clube de leitores católicos do Brasil!​ `,
}) => {
  const styles = useCommonStyles();

  const whatsAppMessage = `Oi! Vim aqui te fazer um convite muito especial.
    Já faz um tempinho que eu assino a Minha Biblioteca Católica, *o maior Clube de leitores Católicos do Brasil*.
    Todos os meses, eles enviam para minha casa uma obra da tradição da Igreja em uma edição exclusiva, em capa dura com muitos detalhes personalizados.
    Edição com a fidelidade original das obras, capa desenhada a mão…  até mesmo traduções inéditas!
    _Até agora eles já chegaram até a casa de mais de 120 mil famílias brasileiras e já imprimiram mais de 3 milhões de livros_. Mas isso é apenas o começo!
    Agora eles lançaram o programa *Indique e Ganhe*, se você fizer sua assinatura por este link, *nós dois ganhamos um desconto exclusivo* na loja.
    Eu já faço parte! E você? O que está esperando?`;

  const shareMessage = `Oi! Vim aqui te fazer um convite muito especial.
    Já faz um tempinho que eu assino a Minha Biblioteca Católica, *o maior Clube de leitores Católicos do Brasil*.
    Todos os meses, eles enviam para minha casa uma obra da tradição da Igreja em uma edição exclusiva, em capa dura com muitos detalhes personalizados.
    Edição com a fidelidade original das obras, capa desenhada a mão…  até mesmo traduções inéditas!
    *Até agora eles já chegaram até a casa de mais de 120 mil famílias brasileiras e já imprimiram mais de 3 milhões de livros*. Mas isso é apenas o começo!
    Agora eles lançaram o programa "Indique e Ganhe", se você fizer sua assinatura por este link, *nós dois ganhamos um desconto exclusivo* na loja.
    Eu já faço parte! E você? O que está esperando?

    `;

  return (
    <Grid container spacing={1} className={styles.marginTop10}>
      <Grid item xs={2} sm={2} md={2} lg={1}>
        <WhatsappShareButton url={url} title={whatsAppMessage}>
          <WhatsappIcon size={32} round={true} />
        </WhatsappShareButton>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={1}>
        <TwitterShareButton url={url} title={shareMessage}>
          <TwitterIcon size={32} round={true} />
        </TwitterShareButton>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={1}>
        <FacebookShareButton url={url} quote={shareMessage}>
          <FacebookIcon size={32} round={true} />
        </FacebookShareButton>
      </Grid>
      <Grid item xs={2} sm={2} md={2} lg={1}>
        <EmailShareButton url={url} subject={text} body={shareMessage}>
          <EmailIcon size={32} round={true} />
        </EmailShareButton>
      </Grid>
    </Grid>
  );
};

export default SocialShareButtons;
