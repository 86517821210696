import { useCallback, useRef } from 'react';

export const useDebounce = (callback: Function, milliseconds = 500) => {
  const timeoutRef = useRef<any>();
  const debounced = useCallback(
    (...args) => {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = setTimeout(() => callback(...args), milliseconds);
    },
    [callback, milliseconds],
  );
  return debounced;
};
export default useDebounce;
