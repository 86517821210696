import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CondOperator } from '@nestjsx/crud-request';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  FormControlLabel,
} from '@material-ui/core';

import { useStyles } from './styles';
import { SIGNATURES_STATUS } from './utils';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTableCell from 'components/generals/table/MyTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MyDateRangeFilter from 'components/generals/input/MyDateRangeFilter';
import MySelectFilter from 'components/generals/input/MySelectFilter';
import { formatColumnValue } from 'helpers/formatters';
import {
  PAYMENT_TYPES_FILTER,
  CONSECUTIVE_MONTHS,
} from 'helpers/planConstants';
import { useFilters } from 'hooks/filters';
import { AppState } from 'store';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { loadSignatures } from 'store/ducks/generals/signature/actions';
import { loadAllPlans } from 'store/ducks/generals/plan/actions';
import { SignatureState } from 'store/ducks/generals/signature/types';
import { PlanState } from 'store/ducks/generals/plan/types';
import Colors from 'styles/colors';
import { FormatOptions } from 'types';
import { Signature, SIGNATURE_STATUS } from 'types/generals';
import MySwitch from 'components/generals/input/MySwitch';

const Subscribers: FC = memo(() => {
  const dispatch = useDispatch();
  const style = useStyles();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const [displayCurrentSubscription, setDisplayCurrentSubscription] = useState<
    boolean
  >(false);

  const {
    data: subscribers = [],
    total = 0,
    hasError,
    isLoading,
  } = useSelector<AppState, SignatureState>(
    ({ signature }: AppState) => signature,
  );

  const { data: plans = [] } = useSelector<AppState, PlanState>(
    ({ plans }: AppState) => plans,
  );

  const searchObject = [
    {
      field: 'user.name',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'user.email',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'user.identification',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'id',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
    {
      field: 'user.id',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
  ];

  const {
    filterFields,
    genericFilterFields,
    sort,
    pageFilters,
    dateFilter,
    page,
    handleGenericSearch,
    handleUpdateFilters,
    handleSort,
    handleDateFilter,
    handleClearFilters,
    handleClearPageFilter,
    handleSetValuePage,
  } = useFilters({
    orderType: { filterTitle: 'Tipo', value: undefined },
    series: { filterTitle: 'Série', value: undefined },
    renewDate: { filterTitle: 'Período', value: [null, null] },
    'plan.name': {
      filterTitle: 'Plano',
      value: undefined,
    },
    paymentType: {
      filterTitle: 'Pagamento',
      value: undefined,
    },
    status: {
      filterTitle: 'Status assinatura',
      value: undefined,
    },
    'user.countPaidMonths': {
      filterTitle: 'Meses Consec.',
      value: undefined,
    },
    'user.status': { filterTitle: 'Status assinante', value: undefined },
    'user.currentSubscription': {
      filterTitle: 'Assinatura atual',
      value: undefined,
    },
  });

  const loadAllSignatures = useCallback(() => {
    if (displayCurrentSubscription) {
      dispatch(
        loadSignatures({
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [
              ...filterFields,
              { $or: [...genericFilterFields] },
              { status: { $eq: SIGNATURE_STATUS.ACTIVE } },
              { isDonation: { $eq: false } },
            ],
          },
        }),
      );
    } else {
      dispatch(
        loadSignatures({
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [
              ...filterFields,
              { $or: [...genericFilterFields] },
              { isDonation: { $eq: false } },
            ],
          },
        }),
      );
    }
  }, [
    dispatch,
    page,
    rowsPerPage,
    sort,
    genericFilterFields,
    filterFields,
    displayCurrentSubscription,
  ]);

  const loadPlansList = useCallback(() => {
    dispatch(loadAllPlans());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Assinaturas'));
  }, [dispatch]);

  useEffect(() => {
    loadAllSignatures();
  }, [loadAllSignatures]);

  useEffect(() => {
    loadPlansList();
  }, [loadPlansList]);

  useEffect(() => {
    setDisplayCurrentSubscription(
      pageFilters['user.currentSubscription']?.value,
    );
  }, [pageFilters]);

  const handleSubscriberDetail = (subscriberData: Signature) => {
    history.push(`/admin/subscriptions/${subscriberData.id}`);
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  return (
    <Grid container direction="column" className={style.marginTop}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        style={{ marginBottom: 24 }}
      >
        <Grid item>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
        </Grid>
        <Grid item>
          <Grid item xs className={style.placeCenter}>
            <Button style={{ width: '100%' }} onClick={handleClearFilters}>
              <Typography
                variant="h4"
                color="textPrimary"
                style={{
                  color: Colors.RED,
                  fontWeight: 'bold',
                  textTransform: 'capitalize',
                }}
              >
                Limpar filtros
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="center" spacing={3}>
        <Grid item xs>
          <MyDateRangeFilter
            value={dateFilter}
            onChange={(date: Date[]) => handleDateFilter(date, '', 'renewDate')}
            placeholder="Data de renovação"
          />
        </Grid>
        <Grid item xs>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('user.status')}
            menuItems={SIGNATURES_STATUS.map(status => ({
              title: status,
              action: () =>
                handleUpdateFilters(
                  'user.status',
                  {
                    filterTitle: status,
                    value: status,
                  },
                  CondOperator.EQUALS,
                ),
              className: style.menuOption,
            }))}
            buttonProps={{
              style: {
                borderColor: pageFilters?.['user.status'].value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              className={style.filterSelect}
            >
              {pageFilters['user.status']?.filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
        <Grid item xs>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('plan.name')}
            menuItems={plans.map(plan => ({
              title: plan.name,
              action: () =>
                handleUpdateFilters(
                  'plan.name',
                  {
                    filterTitle: plan.name,
                    value: plan.name,
                  },
                  CondOperator.EQUALS,
                ),
              className: style.menuOption,
            }))}
            buttonProps={{
              style: {
                borderColor: pageFilters?.['plan.name'].value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              className={style.filterSelect}
            >
              {pageFilters['plan.name']?.filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
        <Grid item xs>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('paymentType')}
            menuItems={PAYMENT_TYPES_FILTER.map(option => ({
              title: option.value,
              action: () =>
                handleUpdateFilters(
                  'paymentType',
                  {
                    filterTitle: option.title,
                    value: option.value,
                  },
                  CondOperator.EQUALS,
                ),
              className: style.menuOption,
            }))}
            buttonProps={{
              style: {
                borderColor: pageFilters?.['paymentType'].value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              className={style.filterSelect}
            >
              {pageFilters['paymentType'].filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
        <Grid item xs>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('user.countPaidMonths')}
            menuItems={CONSECUTIVE_MONTHS.map(value => ({
              title: value,
              action: () =>
                handleUpdateFilters(
                  'user.countPaidMonths',
                  {
                    filterTitle: value,
                    value: value,
                  },
                  CondOperator.EQUALS,
                ),
              className: style.menuOption,
            }))}
            buttonProps={{
              style: {
                borderColor: pageFilters?.['user.countPaidMonths'].value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              className={style.filterSelect}
            >
              {pageFilters['user.countPaidMonths'].filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
      </Grid>
      <Paper className={style.paperTable} elevation={0}>
        <TableContainer>
          <Table className={style.table} aria-label="subscriptions list">
            <TableHead>
              <TableRow>
                <MyTableCell>Código</MyTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('user.name')}
                  direction={sort.field === 'name' && sort.order}
                >
                  Nome
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('user.email')}
                  direction={sort.field === 'email' && sort.order}
                >
                  E-mail
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('plan.name')}
                  direction={sort.field === 'plan' && sort.order}
                >
                  Plano
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('paymentType')}
                  direction={sort.field === 'paymentType' && sort.order}
                >
                  Pagamento
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('paymentType')}
                  direction={sort.field === 'paymentType' && sort.order}
                >
                  Criada em
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('paymentType')}
                  direction={sort.field === 'paymentType' && sort.order}
                >
                  Renovação
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('user.countPaidMonths')}
                  direction={
                    sort.field === 'user.countPaidMonths' && sort.order
                  }
                >
                  Meses consec.
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('user.status')}
                  direction={sort.field === 'user.status' && sort.order}
                >
                  Status assinante
                </MySortableTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!hasError &&
                !isLoading &&
                subscribers.map(sub => (
                  <TableRow
                    key={sub.id}
                    className={style.clickableRow}
                    onClick={() => handleSubscriberDetail(sub)}
                  >
                    <MyTableCell>{sub.id}</MyTableCell>
                    <MyTableCell>{sub.user?.name || '-'}</MyTableCell>
                    <MyTableCell>{sub.user?.email || '-'}</MyTableCell>
                    <MyTableCell>{sub.plan?.name || '-'}</MyTableCell>
                    <MyTableCell>{sub.paymentType}</MyTableCell>
                    <MyTableCell>
                      {formatColumnValue(FormatOptions.DATE, sub.createdAt)}
                    </MyTableCell>
                    <MyTableCell>
                      {formatColumnValue(
                        FormatOptions.SIMPLE_DATE_WITH_TRACE,
                        sub.renewDate,
                      )}
                    </MyTableCell>
                    <MyTableCell>{sub.user.countPaidMonths}</MyTableCell>
                    <MyTableCell>{sub.user.status}</MyTableCell>
                    <MyTableCell>
                      {sub.user?.currentSubscription === sub.id
                        ? 'Principal'
                        : null}
                    </MyTableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Grid container justify="space-between">
        <FormControlLabel
          control={
            <MySwitch
              name="currentSubscription"
              color="primary"
              checked={displayCurrentSubscription}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'user.currentSubscription',
                  {
                    filterTitle: '',
                    value: e.target.checked ? true : undefined,
                  },
                  CondOperator.NOT_NULL,
                );
                setDisplayCurrentSubscription(e.target.checked);
              }}
            />
          }
          label="Assinatura atual"
        />

        <TablePagination
          ActionsComponent={MyTablePaginationActions}
          component="div"
          count={total}
          page={page}
          labelRowsPerPage="Itens por página"
          onChangePage={(_event: unknown, newPage: number) =>
            handleSetValuePage(newPage)
          }
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleSetValuePage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
          }}
          rowsPerPageOptions={ROWS_PER_PAGE}
        />
      </Grid>
    </Grid>
  );
});

export default Subscribers;
