import React, { FC, useEffect, useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { Grid, Paper, Typography, Divider } from '@material-ui/core';

import { useStyles } from './styles';
import { DetailStateProps } from './types';
import OrderPayment from './OrderLastPayment';
import OrderTrackingStatus from './OrderTrackingStatus';
import UserOrderItems from './OrderItems';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from 'store';
import { OrderOneState } from 'store/ducks/order/types';
import { loadOneOrder } from 'store/ducks/order/actions';
import UserOrderPayments from './OrderPayments';
import UserOrderInvoices from './OrderInvoice';
import OrderReferenced from 'components/generals/OrderReferenced';
import { ORDER_REFERENCE_REASON } from 'types/generals';

const UserOrderDetail: FC = () => {
  const styles = useStyles();
  const { orderId } = useLocation<DetailStateProps>().state;
  const dispatch = useDispatch();

  const { data: order, isLoading } = useSelector<AppState, OrderOneState>(
    ({ orderReducer }: AppState) => orderReducer,
  );

  const loadOrder = useCallback(() => {
    dispatch(
      loadOneOrder(orderId, {
        join: [
          {
            field: 'orderDetail',
            select: [
              'id',
              'paymentType',
              'products',
              'zipcode',
              'subtotal',
              'amount',
              'freight',
              'discount',
              'street',
              'number',
              'city',
              'neighborhood',
              'complement',
              'state',
              'orderType',
            ],
          },
          ['payments'],
          ['sigepLabel'],
        ],
        fields: [
          'id',
          'statusHistory',
          'statusCancelHistory',
          'statusTrackingDetails',
          'status',
          'paymentDate',
          'payments',
          'sigepLabel',
          'referenceOrders',
        ],
      }),
    );
  }, [dispatch, orderId]);

  useEffect(() => {
    loadOrder();
  }, [loadOrder]);

  // const tryingPayment = () => {
  //   const ordersService = OrdersService;
  //   ordersService.tryingOrderPayment(orderId);
  //   dispatch(notifySuccess(TRYING_ORDER_PAYMENT));
  // };

  // const hasLastPayment = () => {
  //   const lastPayment = order.orderLastPayment;
  //   if (lastPayment && lastPayment.status !== PAYMENT_STATUS.FAILED) {
  //     switch (order.orderDetail.paymentType) {
  //       case PAYMENT_TYPE.BANKSLIP:
  //         if (lastPayment.bankSlipTransaction) {
  //           const validateDueDate = compareAsc(
  //             separateDate(lastPayment.bankSlipTransaction.dueDate),
  //             new Date(),
  //           );
  //           return validateDueDate < 0;
  //         }
  //         break;
  //     }
  //     return true;
  //   }
  //   return false;
  // };

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      <Grid item xs={12} style={{ marginTop: '25px' }}>
        <Paper className={styles.paperTable} elevation={0}>
          <Grid container>
            <Grid item xs={8}>
              <Typography variant="h1">
                Detalhe do <strong>pedido {order.id}</strong>
              </Typography>
            </Grid>
            {/* <Grid container item xs={4} justify="flex-end">
              {hasLastPayment() && (
                <Button onClick={() => tryingPayment()}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    style={{
                      color: Colors.RED,
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                    }}
                  >
                    Novo pagamento
                  </Typography>
                </Button>
              )}
            </Grid> */}
          </Grid>
          {!isLoading && <OrderPayment order={order} />}
          <Divider className={styles.divider} />
          {!isLoading && <OrderTrackingStatus order={order} />}
          <Divider className={styles.divider} />
          {!isLoading && <UserOrderItems order={order} />}
          <Divider className={styles.divider} />
          {!isLoading && <UserOrderPayments order={order} />}
          <Divider className={styles.divider} />
          {!isLoading && <UserOrderInvoices order={order} />}

          {order?.referenceOrders?.length ? (
            <>
              <Divider className={styles.divider} />
              {!isLoading ? (
                <OrderReferenced
                  order={order}
                  reason={ORDER_REFERENCE_REASON.ENHANCE}
                  refOrderTitle="Status dos pedidos turbinados"
                />
              ) : null}
            </>
          ) : (
            ''
          )}
        </Paper>
      </Grid>
    </Grid>
  );
};

export default UserOrderDetail;
