import { Grid, Typography } from '@material-ui/core';
import useStyles from 'components/generals/forms/register/styles';
import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/ducks/nav/actions';
import { User } from 'types/generals';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import { UserDataProps } from 'components/generals/dialog/dialogTypes';
import AddRakingScoreDialog from 'components/generals/dialog/AddRakingScoreDialog';
import { RankingSeason } from 'types/generals/rankingSeason';

interface EditUserActionsProps {
  user: User;
  rankings: RankingSeason[];
}

const EditUserActions: FC<EditUserActionsProps> = ({ user, rankings }) => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const handleOpenConfirmDialog = () => {
    const modalProps: UserDataProps = {
      user,
      rankings,
    };
    dispatch(openModal(AddRakingScoreDialog, modalProps));
  };

  return (
    <Grid item xs={4} md={4}>
      <MyDropdown
        buttonProps={{
          color: 'primary',
          variant: 'contained',
          style: {
            width: '100%',
            borderRadius: 13,
          },
        }}
        menuProps={{
          classes: {
            list: styles.filterOptionsList,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          PaperProps: {
            style: {
              marginTop: '1rem',
              borderRadius: 13,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
        menuItems={[
          {
            title: 'Gerenciar pontos de campanha',
            action: () => handleOpenConfirmDialog(),
          },
        ]}
      >
        <Typography
          variant="h3"
          style={{
            fontSize: '1.6rem',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            width: '100%',
            textAlign: 'left',
          }}
        >
          Ações
        </Typography>
      </MyDropdown>
    </Grid>
  );
};

export default EditUserActions;
