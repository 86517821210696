import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  toggleButton: {
    backgroundColor: Colors.LIGHT_GRAY,
    '&:focus': {
      backgroundColor: Colors.LIGHT_GRAY,
    },
  },
  toggleButtonActive: {
    backgroundColor: Colors.RED,
    '&:focus': {
      backgroundColor: Colors.RED,
    },
  },
  filterButton: {
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'unset',
    },
  },
  filterButtonActive: {
    color: Colors.WHITE,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'unset',
    },
  },
  confirmButton: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 90px',
    },
    fontSize: '1.6em',
    height: '56px',
    fontWeight: 'bold',
    borderRadius: '7px',
    padding: '16px 135px',
    maxWidth: '180px',
    backgroundColor: Colors.LIGHT_GREEN,
    color: Colors.WHITE,
    marginTop: '26px',
    marginBottom: '50px',
    '&:hover': {
      backgroundColor: Colors.DARK_GREEN,
    },
  },
  confirmButtonSmallScreen: {
    fontSize: '1.5em',
    height: '56px',
    borderRadius: '7px',
    padding: '16px 90px',
    backgroundColor: Colors.LIGHT_GREEN,
    color: Colors.WHITE,
    '&:hover': {
      backgroundColor: Colors.DARK_GREEN,
    },
  },
  confirmBtnText: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
}));
