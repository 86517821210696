import React, { FC } from 'react';
import { Select, FormControl, MenuProps } from '@material-ui/core';
import { useStyles } from './styles';
import {
  BootstrapInput,
  ContractedBootstrapInput,
  MyFormHelperText,
  MyKeyboardArrowDownIcon,
  MyLabel,
  MyLabelContracted,
  MyMenuItem,
} from './styles';
import Colors from 'styles/colors';

export interface SelectItem {
  value: any;
  title: string;
}

interface MySelectTableFilterProps {
  title: string;
  itens: SelectItem[];
  onChange: any;
  onBlur?: any;
  value: any;
  error: any;
  helperText: string | null;
  className?: string;
  classFormControl?: string;
  disabled?: boolean;
  contracted?: boolean;
  multiple?: boolean;
  menuProps?: any;
}

const MySelectTableFilter: FC<MySelectTableFilterProps> = props => {
  const selectMenuProps: Partial<MenuProps> = {
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
    getContentAnchorEl: null,
    MenuListProps: {
      style: {
        fontFamily: 'font-family: Raleway,sans-serif',
        fontSize: '1.2rem',
        fontWeight: 400,
        color: Colors.MEDIUM_GRAY,
        backgroundColor: Colors.WHITE,
      },
    },
  };

  const styles = useStyles();
  return (
    <FormControl
      variant="outlined"
      fullWidth
      className={props.classFormControl}
    >
      {props.contracted ? (
        <MyLabelContracted htmlFor="outlined-age-native-simple">
          {props.title}
        </MyLabelContracted>
      ) : (
        <MyLabel htmlFor="outlined-age-native-simple">{props.title}</MyLabel>
      )}
      <Select
        disabled={!!props.disabled}
        className={styles.select}
        labelId="demo-customized-select-label"
        id="demo-customized-select"
        inputProps={{
          name: props.title,
          id: 'outlined-age-native-simple',
        }}
        input={
          !props.contracted ? <BootstrapInput /> : <ContractedBootstrapInput />
        }
        multiple={props.multiple}
        IconComponent={props => <MyKeyboardArrowDownIcon {...props} />}
        onChange={props.onChange}
        value={props.value}
        error={props.error}
        MenuProps={selectMenuProps}
      >
        {props.itens &&
          props.itens.map(item => (
            <MyMenuItem key={item.value} value={item.value}>
              {item.title}
            </MyMenuItem>
          ))}
      </Select>
      <MyFormHelperText>{props.helperText}</MyFormHelperText>
    </FormControl>
  );
};

export default MySelectTableFilter;
