import { PromotionPeriods, StoreSettings } from 'types/client';
import * as Yup from 'yup';
import { validateDate } from 'helpers/validateDate';
import { format } from 'date-fns';

export const validationSchema = Yup.object({
  startDate: Yup.date()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório'),
  endDate: Yup.date()
    .required('Campo obrigatório')
    .typeError('Campo obrigatório')
    .test(
      'invalid-end-date',
      'Data de termino anterior ao momento presente',
      function (value) {
        if (!value) return this.createError({ message: 'Campo obrigatório' });

        const formatedEndDate = format(value, 'dd/MM/yyyy');
        if (!this.parent.startDate) {
          const validate = validateDate(formatedEndDate);
          if (validate && validate < 0) return this.createError();
        } else {
          const formatedStartDate = format(this.parent.startDate, 'dd/MM/yyyy');
          const validate = validateDate(formatedStartDate, formatedEndDate);
          if (validate && validate < 0) {
            console.log(validate);
            return this.createError({
              message: 'Data de termino menor que a data de início',
            });
          }
        }

        return true;
      },
    ),
  reason: Yup.string().required('Campo obrigatório'),
  isShippingActive: Yup.boolean(),
  freeFreightFromThisValue: Yup.string().when('isShippingActive', {
    is: value => value === true,
    then: (rule: any) => rule.required('Campo obrigatório'),
  }),
  startHourDate: Yup.string().required().typeError('Campo obrigatório'),
  endHourDate: Yup.string().required().typeError('Campo obrigatório'),
});

const defaultPromotionPeriods: PromotionPeriods = {
  endDate: '',
  reason: '',
  startDate: '',
  endHourDate: '',
  startHourDate: '',
};
export const defaultSettings: StoreSettings = {
  banners: [],
  mobileBanners: [],
  seasonBanners: [],
  isActive: false,
  promotionPeriods: [defaultPromotionPeriods],
  tags: [],
  freightConfig: {
    isActive: false,
    freeFreightFromThisValue: 200,
  },
  rankingSeasonConfig: {
    scoreValue: 0,
    showReferralMenu: false,
  },
};
