import React from 'react';
import { Grid } from '@material-ui/core';

import SectionLogo from '../SectionLogo';
import NewPasswordClientForm from './NewPasswordForm';

const NewPassword = () => {
  return (
    <Grid container>
      <SectionLogo />
      <NewPasswordClientForm />
    </Grid>
  );
};

export default NewPassword;
