import { CartProductDetails, ScoreData } from 'types/client';
import { StoreProduct } from 'types/generals';
import { Coupon } from 'types/generals/coupon';

export enum CartTypes {
  CART_LIST_PRODUCTS = '@cart/CART_LIST_PRODUCTS',
  CART_SET_INSTALLMENTS = '@cart/CART_SET_INSTALLMENTS',
  CART_CLEAR_PRODUCTS = '@cart/CART_CLEAR_PRODUCTS',
  CART_ADD_PRODUCT = '@cart/ADD_PRODUCT',
  CART_DELETE_PRODUCT = '@cart/DELETE_PRODUCT',
  CART_INCREASE_PRODUCT_AMOUNT = '@cart/INCREASE_PRODUCT_AMOUNT',
  CART_DECREASE_PRODUCT_AMOUNT = '@cart/DECREASE_PRODUCT_AMOUNT',
  CART_APPLY_DISCOUNT = '@cart/CART_APPLY_DISCOUNT',
  CART_ACTION_FAILURE = '@cart/CART_ACTION_FAILURE',
  CART_APPLY_SCORE_DISCOUNT = '@cart/CART_APPLY_SCORE_DISCOUNT',
  CART_ADD_COUPON = '@cart/CART_ADD_COUPON',
  CART_REMOVE_COUPON = '@cart/CART_REMOVE_COUPON',
  CART_UPDATE_AMOUNT = '@cart/CART_UPDATE_AMOUNT',
  CART_CHANGE_OPEN = '@cart/CART_CHANGE_OPEN',
  CART_APPLY_FREIGHT = '@cart/CART_APPLY_FREIGHT',
  CART_ADD_SELECTED_TRANSPORT = '@cart/CART_ADD_SELECTED_TRANSPORT',
}

export interface CartState {
  readonly data: StoreProduct[];
  readonly cartProducts: CartProductDetails[];
  readonly cartLength: number;
  readonly subTotal: number;
  readonly cartAmount: number;
  readonly freight: number;
  readonly installments: number;
  readonly cartCoupon: Coupon;
  readonly discount: number;
  readonly productsDiscount: number;
  readonly scoreData: ScoreData;
  readonly cartOpen: boolean;
  readonly selectedTransport: string;
  readonly isFirstTransport: boolean;
}
