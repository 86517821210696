import React, { useEffect, useCallback } from 'react';
import {
  Paper,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import SwipeableViews from 'react-swipeable-views';

import PlanStep from './PlanStep';
import AddressStep from './AddressStep';
import PaymentStep from './PaymentStep';
import SuccessStep from './SuccessStep';

import { AppState } from 'store';
import { ChangePlanState } from 'store/ducks/change-plan/types';

import { ChangePlanProps, PlanPages } from './types';
import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';
import { loadOnePlanByHash } from 'store/ducks/generals/plan/onePlan/actions';

const ChangePlan: React.FC<ChangePlanProps> = ({ page }) => {
  const style = useStyles();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down('xs'));
  const { state } = useLocation();
  const hashPlan = state?.hashPlan || null;
  const dispatch = useDispatch();

  const { step, totalSteps } = useSelector<AppState, ChangePlanState>(
    ({ changePlan }: AppState) => changePlan,
  );

  const renderStepOf = () => {
    return (
      <Grid item>
        <Typography variant="h3">
          Etapa <strong>{step + 1}</strong> de&nbsp;
          <strong>{totalSteps}</strong>
        </Typography>
      </Grid>
    );
  };

  const loadPlan = useCallback(() => {
    dispatch(loadOnePlanByHash(hashPlan));
  }, [dispatch, hashPlan]);

  useEffect(() => {
    if (hashPlan && page === PlanPages.UPGRADE_PAGE) {
      loadPlan();
    }
  }, [hashPlan, page, loadPlan]);

  const TitlePlanStep = () => {
    if (hashPlan && page === PlanPages.UPGRADE_PAGE) {
      return 'Avançar ao Plano';
    } else if (page === PlanPages.UPGRADE_PAGE) {
      return 'Avançar ao Plano Anual';
    }
    return 'Reativar plano';
  };

  return (
    <Grid container className={style.rootPanel}>
      <Grid item xs={12}>
        {smallSize && step < 3 ? (
          <Paper className={style.mobilePaper}>{renderStepOf()}</Paper>
        ) : null}

        <Paper variant="outlined" className={style.rootPaper}>
          <Grid container item justify="space-between">
            <Grid item>
              <Typography className={style.pageTitle} variant="h1">
                {TitlePlanStep()}
              </Typography>
            </Grid>
            {!smallSize && step < 3 ? renderStepOf() : null}
          </Grid>

          <SwipeableViews
            index={step}
            slideStyle={{ overflow: 'hidden', padding: 0, margin: 0 }}
          >
            <PlanStep page={page} />
            <AddressStep />
            <PaymentStep />
            <SuccessStep page={page} />
          </SwipeableViews>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default ChangePlan;
