import React, { FC, useCallback } from 'react';
import {
  Button,
  Grid,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import MyTableCell from 'components/generals/table/MyTableCell';
import { Container, PageTitle, StyledPaper } from '../styles';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { NewResendProps } from '../types';
import Colors from 'styles/colors';

const NewResendProducts: FC<NewResendProps> = ({
  appendProductsHandler,
  resendProducts,
}) => {
  const priceFormatted = useCallback(
    (price: string) => formatColumnValue(FormatOptions.MONEY, price),
    [],
  );

  const deleteProductFromResend = (productId: Number) => {
    const products = resendProducts;
    const productsToResend = products.filter(
      product => product.id !== productId,
    );
    appendProductsHandler(productsToResend);
  };

  return (
    <Grid container justify="space-between" direction="column">
      <Grid item xs>
        <PageTitle>Novo envio</PageTitle>
      </Grid>
      <Grid item xs>
        <StyledPaper elevation={0}>
          <Container container spacing={2}>
            <TableContainer>
              <Table style={{ minWidth: 650 }} aria-label="orders table">
                <TableHead>
                  <TableRow>
                    <MyTableCell>Item</MyTableCell>
                    <MyTableCell>SKU</MyTableCell>
                    <MyTableCell>Quantidade</MyTableCell>
                    <MyTableCell>Valor Unit.</MyTableCell>
                    <MyTableCell>Ações</MyTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {resendProducts.map((product, idx) => (
                    <TableRow key={idx}>
                      <MyTableCell>{product.name}</MyTableCell>
                      <MyTableCell>{product.sku}</MyTableCell>
                      <MyTableCell>{product.quantity}</MyTableCell>
                      <MyTableCell>
                        {priceFormatted(product.unitPrice)}
                      </MyTableCell>
                      <MyTableCell>
                        <Button
                          onClick={() => deleteProductFromResend(product.id)}
                        >
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            style={{
                              color: Colors.RED,
                              fontWeight: 'bold',
                            }}
                          >
                            Excluir
                          </Typography>
                        </Button>
                      </MyTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Container>
        </StyledPaper>
      </Grid>
    </Grid>
  );
};

export default NewResendProducts;
