import React, { FC, useRef } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import { useCommonStyles } from 'styles/common';
import {
  useStyles,
  TextArea,
  CopyIcon,
  CustomGreyTextIndication,
} from '../styles';
import { notifySuccess } from 'store/ducks/notification/actions';
import { USER_RANKING_CODE_COPY_SUCCESS } from 'utils/messages/rankingSeason';
import SocialShareButtons from 'components/generals/socialShareButtons';
import { OR_SHARE_REFERRAL_LINK, USE_REFERRAL_LINK } from '../utils';
import { INDICATION_CONDITIONS } from 'utils/links/termsConditions';

interface ReferralBlockProps {
  userRanking: any;
}

const ReferralBlock: FC<ReferralBlockProps> = ({ userRanking }) => {
  const paymentCodeRef = useRef() as any;
  const styles = useStyles({});
  const dispatch = useDispatch();
  const commnStyles = useCommonStyles();
  const planPage = `https://box.bibliotecacatolica.com.br/planos`;
  const referralLink = `${planPage}?codigoIndicacao=${userRanking?.referralCode}`;

  const handleOnClickCopy = () => {
    navigator.clipboard.writeText(referralLink);
    dispatch(notifySuccess(USER_RANKING_CODE_COPY_SUCCESS));
  };

  return (
    <Paper component={Grid} className={styles.paperTable} elevation={0}>
      <Typography variant="h4" color="textPrimary">
        {USE_REFERRAL_LINK}
      </Typography>
      <Grid container item xs direction="row" className={styles.marginTop15}>
        <Grid item xs={10} md={11}>
          <TextArea ref={paymentCodeRef} value={referralLink} disabled />
        </Grid>
        <Grid item xs>
          {' '}
          <CopyIcon color="white" onClick={handleOnClickCopy} />
        </Grid>
      </Grid>
      <Typography
        variant="h4"
        color="textPrimary"
        className={commnStyles.marginTop10}
      >
        {OR_SHARE_REFERRAL_LINK}
      </Typography>
      <SocialShareButtons url={referralLink} />
      <Typography>
        <Link
          to={{
            pathname: INDICATION_CONDITIONS,
          }}
          target="_blank"
          className={styles.linkIndicationTerms}
        >
          <CustomGreyTextIndication variant="h4">
            Saiba mais sobre o programa
          </CustomGreyTextIndication>
        </Link>
      </Typography>
    </Paper>
  );
};

export default ReferralBlock;
