import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid } from '@material-ui/core';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';
// import ComparativeHistoric from './reports/ComparativeHistoric';

// TODO: reactivate subcribers historic graph
const Dashboard: FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actUpdatePageTitle('Painel'));
  }, [dispatch]);

  return (
    <Grid container direction="column">
      {/* <ComparativeHistoric /> */}
    </Grid>
  );
};

export default Dashboard;
