import React, { FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

import { closeModal, openModal } from 'store/ducks/nav/actions';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import ViewsService from 'services/viewsService';
import { StatusCodes } from 'http-status-codes';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import {
  RETRY_PAYMENT_ERROR,
  RETRY_PAYMENT_MESSAGE,
  RETRY_PAYMENT_SUCESS,
} from 'utils/messages/bunkActions';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { useHistory } from 'react-router-dom';

interface BAActionsProps {
  orderIds: number[];
}

const BAActions: FC<BAActionsProps> = memo(({ orderIds }) => {
  const dispatch = useDispatch();
  const style = useStyles();
  const history = useHistory();

  const handleRetryPayment = async () => {
    try {
      const response: any = await ViewsService.retryTurbinePaymentSeveral({
        orderIds,
      });

      if (response.status === StatusCodes.CREATED) {
        dispatch(closeModal());
        dispatch(notifySuccess(RETRY_PAYMENT_SUCESS));
        history.go(0);
      } else {
        throw new Error(RETRY_PAYMENT_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleGetReportBankslipOrders = async () => {
    try {
      await ViewsService.generateBankslipOrdersTurbineCsv({
        orderIds,
      });
      history.go(0);
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(closeModal());
    }
  };

  const handleOpenRetryPaymentDialog = () => {
    const modalProps: BaseDialogProps = {
      title: 'Realizar cobrança',
      message: RETRY_PAYMENT_MESSAGE,
      confirmText: 'Sim',
      cancelText: 'Não',
      actionFn: handleRetryPayment,
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleOpenReportBankslipDialog = () => {
    const modalProps: BaseDialogProps = {
      title: 'Relatório de boletos',
      message: 'Deseja gerar link dos boletos?',
      confirmText: 'Sim',
      cancelText: 'Não',
      actionFn: handleGetReportBankslipOrders,
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  return (
    <MyDropdown
      buttonProps={{
        color: 'primary',
        variant: 'contained',
        className: style.filterOptionsButton,
        disabled: orderIds.length === 0,
      }}
      menuProps={{
        classes: {
          list: style.filterOptionsList,
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        PaperProps: {
          style: {
            marginTop: '1rem',
            borderRadius: 13,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }}
      menuItems={[
        {
          title: 'Cobrar pedidos',
          action: handleOpenRetryPaymentDialog,
        },
        {
          title: 'Gerar link de boletos',
          action: handleOpenReportBankslipDialog,
        },
      ]}
    >
      <Typography variant="h3" className={style.actionButton}>
        Ações
      </Typography>
    </MyDropdown>
  );
});

export default BAActions;
