import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import {
  Grid,
  Typography,
  TextField,
  Button,
  CircularProgress,
  Dialog,
} from '@material-ui/core';

import { useStyles } from 'components/generals/forms/LoginForm/styles';
import { FeedbackDialogPropsBase } from 'components/generals/dialog/dialogTypes';
import FeedbackDialog from 'components/generals/dialog/FeedbackDialog';

import { AppState } from 'store';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import { Modal } from 'store/ducks/nav/types';

import AuthService from 'services/authService';

import { ReactComponent as SuccessIcon } from 'assets/img/svg/icon_active_sucess.svg';
import { ReactComponent as ErrorIcon } from 'assets/img/svg/icon_active_error.svg';

import { validationSchema } from './utils';

import { Colors } from 'styles/colors';

export const RecoverAdminForm: React.FC = () => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const { open, maxWidth, component: Component, props } = useSelector<
    AppState,
    Modal
  >(({ nav }: AppState) => nav.modal);

  const { handleSubmit, register, errors, reset } = useForm({
    mode: 'onSubmit',
    validationSchema,
  });

  const onSubmit = async ({ email }: Record<string, string>) => {
    setIsLoading(true);

    if (email) {
      try {
        await AuthService.recoverPassword(email);

        const modalProps: FeedbackDialogPropsBase = {
          title: 'Sua solicitação foi enviada com sucesso!',
          message: `Um e-mail foi enviado para ${email}, siga as instruções para recuperar sua senha.`,
          icon: SuccessIcon,
        };

        dispatch(openModal(FeedbackDialog, modalProps));
      } catch (error) {
        const modalProps: FeedbackDialogPropsBase = {
          title: 'Algo de errado aconteceu',
          message: (
            <Button
              variant="text"
              color="primary"
              onClick={() => {
                dispatch(closeModal());
                history.push('/login');
              }}
              classes={{ root: classes.feedbackButton }}
            >
              Voltar ao login
            </Button>
          ),
          disableCloseButton: true,
          icon: ErrorIcon,
        };

        dispatch(openModal(FeedbackDialog, modalProps));
      }
    }
    reset();
  };

  return (
    <Grid item container direction="column" justify="space-between">
      <Grid
        item
        container
        direction="column"
        classes={{ root: classes.header }}
      >
        <Typography variant="h2" className={classes.main}>
          Redefinir senha
        </Typography>
        <Typography variant="h4" className={classes.descriptionText}>
          Para redefinir sua senha, insira seu e-mail. Você receberá um e-mail
          com as instruções para prosseguir.
        </Typography>
      </Grid>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid item container direction="column">
          <TextField
            inputRef={register}
            autoFocus
            variant="filled"
            label="Email"
            name="email"
            classes={{ root: classes.root }}
            InputLabelProps={{
              style: {
                fontWeight: 'bold',
                color: Colors.DARKER_GRAY,
                fontSize: 20,
                paddingLeft: 7,
              },
            }}
            InputProps={{
              disableUnderline: true,
              classes: { root: classes.inputRoot, focused: classes.focused },
              style: {
                background: Colors.LIGHT_GRAY,
                borderRadius: 4,
              },
            }}
            error={Boolean(errors.email)}
            helperText={errors.email ? errors.email.message : null}
          />
        </Grid>
        <Grid container justify="flex-end">
          <Button
            variant="contained"
            color="primary"
            classes={{
              root: classes.buttonRoot,
            }}
            type="submit"
            disabled={isLoading}
          >
            {isLoading ? (
              <CircularProgress className={classes.progress} size={20} />
            ) : (
              'Enviar'
            )}
          </Button>
        </Grid>
      </form>

      <Dialog maxWidth={maxWidth} open={open}>
        {Component ? <Component {...props} /> : null}
      </Dialog>
    </Grid>
  );
};

export default RecoverAdminForm;
