import { makeStyles, Theme, createStyles } from '@material-ui/core';

import BackgroundImage from 'assets/img/jpg/login_background.jpg';
import { Colors } from 'styles/colors';

export const useHomeStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      minHeight: '100vh',
      background: Colors.LIGHTER_GRAY,
      paddingTop: theme.spacing(2),
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
      paddingBottom: theme.spacing(11),

      // Round border only on devices where menu
      // is visible by default
      [theme.breakpoints.up('sm')]: {
        borderTopLeftRadius: 50,
      },
    },
    toolbar: theme.mixins.toolbar,
  }),
);

export const useLoginStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100vh',
      backgroundImage: `url(${BackgroundImage})`,
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'cover',

      [theme.breakpoints.down('sm')]: {
        padding: 30,
      },
    },
    logo: {
      fill: Colors.RED,
    },
  }),
);
