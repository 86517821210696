import { Shipping } from 'types/generals';

/**
 * Action types
 */
export enum ShippingTypes {
  LOAD_SHIPPING_FILE_REQUEST = '@shipping/LOAD_SHIPPING_FILE_REQUEST',
  LOAD_SHIPPING_LIST_REQUEST = '@shipping/LOAD_SHIPPING_LIST_REQUEST',
  LOAD_SHIPPING_LIST_SUCCESS = '@shipping/LOAD_SHIPPING_LIST_SUCCESS',
  LOAD_SHIPPING_ONE_REQUEST = '@shipping/LOAD_SHIPPING_ONE_REQUEST ',
  LOAD_SHIPPING_ONE_SUCCESS = '@shipping/LOAD_SHIPPING_ONE_SUCCESS',
  LOAD_FAILURE = '@shipping/LOAD_FAILURE',
  UPDATE = '@shipping/UPDATE',
}

/**
 * Data types
 */
export interface ShippingData {
  data: Shipping[];
  total: number;
  count: number;
  page: number;
  pageCount: number;
}

/**
 * State types
 */
export interface ShippingState {
  readonly total: number;
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly data: Shipping[];
}

export interface ShippingOneState
  extends Omit<ShippingState, 'data' | 'total'> {
  readonly data: Shipping;
}
