import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loadEnhancedProductsOpenRequest } from 'store/ducks/enhancedProduct/actions';
import CardEnhancedProductToggle from 'pages/client/cards/CardEnhancedProductToggle';
import { useSignature } from 'contexts/signatureContext';
import { EnhancedProduct } from 'types/generals/enhancedProduct';
import { EnhancedProductState } from 'store/ducks/enhancedProduct/types';
import { AppState } from 'store';

const CheckoutEnhancedProduct: FC = () => {
  const dispatch = useDispatch();
  const [isAdded, setIsAdded] = useState(false);
  const { setEnhancedProducts } = useSignature();

  const { data: enhancedProducts } = useSelector<
    AppState,
    EnhancedProductState
  >(({ enhancedProduct }: AppState) => enhancedProduct);

  const newSignatureProducts = enhancedProducts?.filter(
    eProduct => eProduct.displayConfig.newSignature,
  );
  // fixme: alterar quando for utilizar mais produtos, e rever o design
  const enhancedProduct = newSignatureProducts.length
    ? newSignatureProducts[0]
    : null;

  const loadEnhancedProductsMonth = useCallback(() => {
    dispatch(loadEnhancedProductsOpenRequest({}));
  }, [dispatch]);

  useEffect(() => {
    loadEnhancedProductsMonth();
  }, [loadEnhancedProductsMonth]);

  const handleAddProduct = async (enhancedProduct: EnhancedProduct) => {
    setEnhancedProducts([enhancedProduct]);
    setIsAdded(true);
  };
  const handleRemoveProduct = async () => {
    setEnhancedProducts([]);
    setIsAdded(false);
  };

  return (
    <>
      {enhancedProduct ? (
        <CardEnhancedProductToggle
          enhancedProduct={enhancedProduct}
          addProduct={handleAddProduct}
          removeProduct={handleRemoveProduct}
          btnText={enhancedProduct.buttonText}
          isAdded={isAdded}
        />
      ) : (
        ''
      )}
    </>
  );
};

export default CheckoutEnhancedProduct;
