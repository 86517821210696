import { makeStyles, Slider, withStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  buttonSpacing: {
    margin: theme.spacing(7, 0, 3),
  },
  sectionSpacing: {
    marginTop: theme.spacing(4),
  },
  cartProductName: {
    fontWeight: 'bold',
    display: '-webkit-box',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    WebkitLineClamp: 3,
    WebkitBoxOrient: 'vertical',
  },
  cartItemsArea: {
    padding: theme.spacing(1),
    maxHeight: '400px',
    overflow: 'auto',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  confirmButton: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
    padding: '16px 90px',
    width: '100%',
    maxWidth: '100%',
    backgroundColor: Colors.LIGHT_GREEN,
    color: Colors.WHITE,
    '&:hover': {
      backgroundColor: Colors.DARK_GREEN,
    },
  },
  confirmBtnText: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  infoBlock: {
    padding: theme.spacing(3, 0, 1),
  },
  infoTitle: {
    textTransform: 'capitalize',
    color: Colors.GRAY,
    fontWeight: 500,
  },
  imgContent: {
    width: '100px',
    height: '96px',
    margin: '0 24px 0 0',
    borderRadius: '8px',
    objectFit: 'cover',
  },
  keepButtonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
    padding: '16px 90px',
    maxWidth: '100%',
  },
  keepBtnText: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  scoreWarningText: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
    opacity: 0.7,
    fontSize: '1.3rem',
    marginBottom: 5,
  },
  divider: {
    margin: '24px 0',
    width: '100%',
  },
  greyText: {
    color: Colors.MEDIUM_GRAY,
    fontWeight: 500,
  },
  menuOption: {
    whiteSpace: 'break-spaces',
  },
  noPaddingButton: {
    paddingLeft: 0,
    paddingRight: 0,
    whiteSpace: 'nowrap',
  },
  radioWrapper: {
    border: `1px solid ${theme.palette.grey['500']}`,
    padding: '6px',
    borderRadius: 8,
    margin: '0 0 8px 0',
    '& .MuiTypography-root': {
      fontSize: 16,
      color: theme.palette.grey['800'],
      fontWeight: 500,
    },
  },
  textContent: {
    fontWeight: 500,
    color: Colors.DARKER_GRAY,
  },
  textContentBottom: {
    fontWeight: 500,
    color: Colors.DARKER_GRAY,
    marginBottom: theme.spacing(2),
  },
  terms: {
    fontSize: '1.4rem',
    cursor: 'pointer',
    display: 'inline',
    fontWeight: 500,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  termsAndConditionsLink: {
    textDecoration: 'none',
  },
});

export const ScoreSlider = withStyles({
  root: {
    color: '#52af77',
    height: 8,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    marginTop: -8,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
  },
})(Slider);
