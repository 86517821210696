import { action } from 'typesafe-actions';
import { NavTypes, Modal } from './types';

export const actUpdatePageTitle = (pageTitle: string) =>
  action(NavTypes.UPDATE_PAGE_TITLE, { pageTitle });

export const openModal = (
  component: any,
  props?: any,
  maxWidth?: Modal['maxWidth'],
) => action(NavTypes.OPEN_MODAL, { modal: { component, props, maxWidth } });

export const closeModal = () => action(NavTypes.CLOSE_MODAL);
