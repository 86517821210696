import React, { FC } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import { useStyles } from '../styles';
import { UserScoreData } from 'types/generals/rankingSeason';
import UserRankingHistoryDialog from 'components/generals/dialog/UserRankingHistoryDialog';
import { useDispatch } from 'react-redux';
import { openModal } from 'store/ducks/nav/actions';
import { User } from 'types/generals';

interface UserScoreRankingProps {
  userRanking: UserScoreData;
  user?: User | null;
  showHistoryButton?: boolean;
}

const UserScoreRanking: FC<UserScoreRankingProps> = ({
  userRanking,
  user,
  showHistoryButton,
}) => {
  const styles = useStyles({});
  const dispatch = useDispatch();

  const handleOpenUserRanking = () => {
    dispatch(openModal(UserRankingHistoryDialog, { user }, 'lg'));
  };

  return (
    <Paper component={Grid} className={styles.paperTable} elevation={0}>
      <Grid container direction="row">
        <Grid item xs={5}>
          <Typography variant="h4" color="textPrimary">
            Pontos acumulados
          </Typography>
          <Typography
            variant="h1"
            color="textPrimary"
            className={styles.marginTop15}
          >
            <b>{userRanking.totalScore}</b>
            <span className={styles.pointsText}>pontos</span>
          </Typography>
        </Grid>
        <Grid item xs={5}>
          <Typography variant="h4" color="textPrimary">
            Pontos para utilizar
          </Typography>

          <Typography
            variant="h1"
            color="textPrimary"
            className={styles.marginTop15}
          >
            <b>{userRanking.wallet.score}</b>
            <span className={styles.pointsText}>pontos</span>
          </Typography>
        </Grid>
        {showHistoryButton ? (
          <Grid item xs>
            <Button
              id="btn-cancel"
              color="primary"
              onClick={handleOpenUserRanking}
            >
              Ver histórico
            </Button>
          </Grid>
        ) : null}
      </Grid>
    </Paper>
  );
};

export default UserScoreRanking;
