import { makeStyles, createStyles } from '@material-ui/core/styles';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme =>
  createStyles({
    '@global': {
      '.grecaptcha-badge': {
        visibility: 'hidden',
      },
    },
    wrapperInfosBox: {
      flexDirection: 'row',
    },
    imageWrapper: {
      width: 77,
      height: 77,
      float: 'left',
      [theme.breakpoints.up('md')]: {
        float: 'right',
      },
      borderRadius: 5,
    },
    boxTitle: {
      fontSize: 24,
      fontWeight: 'bold',
    },
    disabledColor: {
      color: Colors.SILVER,
    },
    boxSubTitle: {
      fontWeight: 500,
      fontSize: 16,
      color: theme.palette.grey['500'],
      margin: '8px, 0 17px 0',
    },
    boxPrice: {
      fontWeight: 'bold',
      fontSize: 24,
      color: theme.palette.primary.main,
    },
    divider: {
      margin: '20px 0',
      width: '100%',
    },
    discountWrapper: {
      marginBottom: 32,
    },
    cupomAction: {
      fontSize: 12,
      color: theme.palette.primary.main,
      padding: 1,
      textAlign: 'right',
      marginRight: 16,
      marginLeft: 'auto',
    },
    discountBoxWrapper: {
      marginBottom: 16,
    },
    discountInfo: {
      fontWeight: 500,
      fontSize: 14,
      color: theme.palette.grey['800'],
    },
    discountInfoCode: {
      color: theme.palette.grey['500'],
      marginRight: 'auto',
      marginLeft: 10,
    },
    totalPriceInfo: {
      fontWeight: 500,
      color: theme.palette.primary.main,
      fontSize: 18,
    },
    totalPriceValue: {
      fontWeight: 'bold',
    },
    keepBtnText: {
      fontWeight: 600,
      whiteSpace: 'nowrap',
    },
    checkoutButton: {
      backgroundColor: Colors.BRIGHT_DARK_GREEN,
      color: Colors.WHITE,
      width: '100%',
      height: 44,
      padding: 12,
      fontSize: 16,
      fontWeight: 600,
      borderRadius: 7,
      '&:hover': {
        backgroundColor: Colors.BRIGHT_DARK_GREEN,
      },
    },
    termsAndConditionsInfo: {
      fontWeight: 500,
      fontSize: 14,
      color: theme.palette.grey['400'],
      textAlign: 'center',
      marginBottom: 16,
    },
    progress: {
      color: Colors.WHITE,
    },
    gridButton: {
      marginBottom: 10,
      marginTop: 10,
    },
    priceText: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    discountText: {
      fontSize: 14,
      fontWeight: 'bold',
      textDecoration: 'line-through',
      textAlign: 'right',
    },
    totalPlanText: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    kitPriceText: {
      fontSize: 14,
    },
    messageText: {
      fontSize: 14,
      fontWeight: 600,
      color: Colors.ORANGE,
    },
    infoPlanText: {
      fontSize: 14,
      fontWeight: 500,
    },
    monthlyText: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    smallPrice: {
      fontSize: 12,
      fontWeight: 'bold',
    },
    mediumGray: {
      color: Colors.MEDIUM_GRAY,
    },
    darkGray: {
      color: Colors.DARK_GRAY,
    },
    renewMonthlyMessage: {
      width: '100%',
      textAlign: 'center',
      fontSize: 12,
      fontWeight: 600,
      lineHeight: '23px',
    },
  }),
);

export default useStyles;
