import { call, put } from 'redux-saga/effects';
import StoreService from 'services/storeService';
import {
  loadOneStoreProductSuccess,
  loadOneStoreProductFailed,
} from './actions';

export function* loadOneStoreProductSaga(action: any) {
  try {
    const storeProduct = yield call(
      StoreService.getStoreProduct,
      action.payload.productId,
      action.payload.query,
    );
    if (storeProduct?.response?.status) {
      yield put(loadOneStoreProductFailed());
    } else {
      yield put(loadOneStoreProductSuccess(storeProduct));
    }
  } catch (err) {
    yield put(loadOneStoreProductFailed());
  }
}
