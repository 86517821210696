import { action } from 'typesafe-actions';
import { FileTypes } from './types';

export const loadFile = () => {
  return action(FileTypes.LOAD_FILE_REQUEST);
};
export const loadFileSuccess = () => {
  return action(FileTypes.LOAD_FILE_REQUEST_SUCCESS);
};
export const loadFileFailure = () => {
  return action(FileTypes.LOAD_FILE_REQUEST_FAILURE);
};
