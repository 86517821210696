import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useDialogStyles = makeStyles(theme => ({
  wrapper: {
    padding: theme.breakpoints.down('sm') ? 0 : '20px',
  },
  closeButton: {
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
  },
  closeIcon: {
    width: '2.0rem',
    height: '2.0rem',
    color: Colors.DARKER_GRAY,
  },
  dialogButton: {
    fontSize: '1.6rem',
    borderRadius: 16,
  },
  info: {
    fontWeight: 500,
    color: Colors.DARKER_GRAY,
    marginBottom: '1rem',
  },
  info_mb: {
    fontWeight: 500,
    color: Colors.DARKER_GRAY,
    marginBottom: '6rem',
  },
  feedback_mb: {
    fontWeight: 500,
    color: Colors.DARKER_GRAY,
    marginBottom: '6rem',
    textAlign: 'center',
  },
  infoBox: {
    background: Colors.LIGHTER_GRAY,
    padding: '2rem 3rem 1rem',
  },
  infoBoxSection: {
    marginBottom: '1rem',
  },
  infoBoxSectionTitle: {
    fontWeight: 800,
    textTransform: 'uppercase',
    marginBottom: '1rem',
  },
  mBottom: {
    marginBottom: '120px',
  },
  trackDialogMBottom: {
    marginBottom: '57px',
    overflow: 'hidden',
  },
  paddingInfo: {
    padding: theme.spacing(2, 0),
  },
  paddingInfoBottom: {
    padding: theme.spacing(1, 0, 3),
  },
  dialogSpacing: {
    margin: theme.spacing(2, 0),
  },
  progress: {
    color: Colors.WHITE,
    marginLeft: theme.spacing(1),
  },
  buttonText: {
    color: Colors.RED,
    fontWeight: 'bold',
  },
  buttonConfirmReactivate: {
    color: Colors.WHITE,
    fontWeight: 'bold',
  },
  button: {
    width: 222,
    height: 56,
    borderRadius: 8,
    backgroundColor: Colors.LIGHT_GREEN,
    '&:hover': {
      backgroundColor: Colors.LIGHT_GREEN,
    },
  },
  buttonWrapper: {
    padding: '0.5em 3em',
  },
  buttonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
  },
}));

export const useStylesNoAccessModalStore = makeStyles(theme => ({
  container: {
    padding: 8,
  },
  closeIcon: {
    width: 45,
    height: 45,
    padding: 10,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: Colors.LIGHTER_GRAY,
      borderRadius: '50%',
    },
  },

  title: {
    fontFamily: 'Montserrat, Ralleway, sans-serif',
    fontSize: 18,
    lineHeight: '24px',
    fontWeight: 700,
    color: Colors.ORANGE,
  },
  storeLockIcon: {
    width: 80,
    height: 80,
  },
  message: {
    fontFamily: 'Montserrat, Ralleway, sans-serif',
    fontSize: 16,
    paddingBottom: '38px',
    lineHeight: '24px',
    fontWeight: 500,
    color: Colors.DARKER_GRAY,
    textAlign: 'center',
  },
  button: {
    width: 222,
    height: 56,
    borderRadius: 8,
    backgroundColor: Colors.LIGHT_GREEN,
    '&:hover': {
      backgroundColor: Colors.LIGHT_GREEN,
    },
  },
  buttonTitle: {
    fontFamily: 'Montserrat, Ralleway, sans-serif',
    fontSize: 16,
    lineHeight: '24px',
    fontWeight: 600,
    textAlign: 'center',
    color: Colors.WHITE,
  },
}));

export const useStylesVideoModal = makeStyles(theme => ({
  gridTitle: {
    backgroundColor: Colors.DARK_GRAY,
  },
  title: {
    fontWeight: 'bold',
    maxWidth: '300px',
    // textAlign: 'center',
    textAlign: 'left',
    color: Colors.WHITE,
    margin: '24px 0 16px 15px',
    // margin-left: 10px;
  },
  white: {
    color: Colors.WHITE,
  },
}));

export const useStylesBenefitsDialog = makeStyles(theme => ({
  gridMargin: {
    marginTop: 15,
    marginBottom: 15,
  },
  buttonWrapper: {
    padding: '0.5em 3em',
  },
  buttonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
  },
}));
