import { Debit } from './debit';
import { Order, StatusHistory } from './order';
import { User } from './user';
import { PAYMENT_TYPE } from './payment';
import { Plan } from './plan';
import { Address } from './address';
import { CreditCard } from 'types/CreditCard';

export enum SIGNATURE_STATUS {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
  PENDING_CANCELED = 'Cancelamento pendente',
  CANCELED = 'Cancelada',
}

export enum ACTIVE_SIGNATURE_STATUS {
  NEW_ACTIVE = 'Ativos - Novos',
  RECURRENT = 'Ativos - Recorrentes',
  MAINTENANCE = 'Ativos - Manutenção',
  RECOVERED = 'Ativos - Recuperados',
}

export enum INACTIVE_SIGNATURE_STATUS {
  NEW_INACTIVES = 'Inativos - Novos',
  OLD_INACTIVES = 'Inativos - Antigos',
  RECOVERED = 'Inativos - Recuperados',
}

export enum UNMAPPED_SIGNATURE_STATUS {
  UNMAPPED = 'Não Mapeado',
}

interface nextSignaturePlan {
  newPlanName: string;
  startDate: string;
  forcedBy: string;
}

export interface SignatureObservation {
  createdAt: Date;
  requester: string;
  observation: string;
}

export interface Signature {
  id: number;
  createdAt: string;
  updatedAt: string;
  status: SIGNATURE_STATUS;
  paymentType: PAYMENT_TYPE;
  orders: Order[];
  user: User;
  debits: Debit[];
  plan: Plan;
  isDonation: boolean;
  statusHistory: StatusHistory[];
  nextSignaturePlan?: nextSignaturePlan;
  renewDate: string;
  observations?: SignatureObservation[];
}

interface CreateUser extends Partial<User> {
  password: string;
}

export interface UTM {
  utmSource: string | null;
  utmMedium: string | null;
  utmCampaign: string | null;
  utmContent: string | null;
  utmTerm: string | null;
  utmId: string | null;
}
export interface CreateSignature {
  user: CreateUser;
  address: Partial<Address>;
  creditCard?: Partial<CreditCard>;
  paymentType?: string;
  couponId?: number;
  hashPlan: string;
  referralCode: string;
  enhancedProducts?: number[];
  utm: UTM;
}

// TODO: Correção provisoria, vamos migrar todas as tipagens do frontend para um
// apater
export interface CreateSignatureSubmit
  extends Partial<Omit<CreditCard, 'user'>>,
    Partial<User>,
    Partial<Omit<Address, 'user'>> {
  paymentType: string;
  repeatEmail: string;
  repeatPassword: string;
  password: string;
  coupon: string;
  couponId?: number;
}

export interface DonationForm {
  name: string;
  email: string;
  repeatEmail: string;
  identification: string;
  phone: string;
  password: string;
  repeatPassword: string;
  zipcode: string;
  street: string;
  number: string;
  neighborhood: string;
  city: string;
  state: string;
  complement: string;
  hashPlan: string;
}

export interface DonationPayload {
  user: {
    name: string;
    email: string;
    repeatEmail: string;
    identification: string;
    phone: string;
    password: string;
    repeatPassword: string;
  };
  address: {
    zipcode: string;
    street: string;
    number: string;
    neighborhood: string;
    city: string;
    state: string;
    complement: string;
  };
  hashPlan: string;
}
