import React, { useEffect, useCallback, ChangeEvent, useState } from 'react';
import {
  Paper,
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Typography,
  TablePagination,
  Button,
} from '@material-ui/core';
import { useSelector, useDispatch } from 'react-redux';
import { startOfMonth, lastDayOfMonth } from 'date-fns';
import { CondOperator } from '@nestjsx/crud-request';

import MyTableCell from 'components/generals/table/MyTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MySelectFilter from 'components/generals/input/MySelectFilter';
import MyDateRangeFilter from 'components/generals/input/MyDateRangeFilter';
import MyInputFilter from 'components/generals/input/MyInputFilter';

import { AppState } from 'store';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';

import { useFilters } from 'hooks/filters';
import { useStyles } from './styles';
import { LeadsState } from 'store/ducks/leads/types';
import { loadLeads, loadLeadsFile } from 'store/ducks/leads/actions';
import CircularLoading from 'components/generals/loading/CircularLoading';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import Colors from 'styles/colors';
import { loadRecurrences } from 'store/ducks/recurrences/actions';
import { RecurrenceState } from 'store/ducks/recurrences/types';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { ROOT_PATH } from 'services/fileservice';

export const REGISTRATION_COMPLETED = [
  { title: 'Sim', value: true },
  { title: 'Não', value: false },
];

const Leads: React.FC = () => {
  const dispatch = useDispatch();
  const style = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const [dateFilter, setDateFilter] = useState<Date[]>([]);

  const {
    handleSort,
    sort,
    page,
    filterFields,
    pageFilters,
    genericFilterFields,
    handleSetValuePage,
    handleGenericSearch,
    handleUpdateFilters,
    handleClearPageFilter,
    handleClearFilters,
  } = useFilters({
    registrationCompleted: {
      filterTitle: 'Assinatura finalizada',
      value: undefined,
    },
    planRecurrence: {
      filterTitle: 'Recorrência',
      value: undefined,
    },
  });

  const { data: leads = [], hasError, isLoading, total } = useSelector<
    AppState,
    LeadsState
  >(({ leads }: AppState) => leads);

  const { data: recurrences } = useSelector<AppState, RecurrenceState>(
    ({ recurrences }: AppState) => recurrences,
  );

  const searchObject = [
    {
      field: 'name',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'email',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'phone',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'plan',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'planName',
      operator: CondOperator.CONTAINS_LOW,
    },
  ];

  const loadAllRecurrences = useCallback(() => {
    dispatch(loadRecurrences());
  }, [dispatch]);

  const loadAllLeads = useCallback(() => {
    if (
      (dateFilter.length && dateFilter[0] !== null && dateFilter[1] !== null) ||
      genericFilterFields.length
    ) {
      dispatch(
        loadLeads({
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [
              ...filterFields,
              {
                createdAt: {
                  $between: dateFilter,
                },
              },
              { $or: [...genericFilterFields] },
            ],
          },
        }),
      );
    }
  }, [
    dispatch,
    page,
    sort,
    dateFilter,
    rowsPerPage,
    genericFilterFields,
    filterFields,
  ]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Leads'));
  }, [dispatch]);

  useEffect(() => {
    loadAllLeads();
  }, [loadAllLeads]);

  useEffect(() => {
    loadAllRecurrences();
  }, [loadAllRecurrences]);

  useEffect(() => {
    setDateFilter([startOfMonth(new Date()), lastDayOfMonth(new Date())]);
  }, []);

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  const loadLeadsXls = useCallback(() => {
    dispatch(
      loadLeadsFile(
        {
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [...filterFields, { $or: [...genericFilterFields] }],
          },
        },
        ROOT_PATH.LEADS,
      ),
    );
  }, [dispatch, page, sort, rowsPerPage, genericFilterFields, filterFields]);

  return (
    <Grid container direction="column" className={style.containerMargin}>
      <Grid
        container
        item
        justify="flex-start"
        alignItems="center"
        spacing={1}
        className={style.mBottom}
      >
        <Grid item>
          <Button onClick={handleClearFilters}>
            <Typography
              variant="h4"
              color="textPrimary"
              className={style.clearFilterButton}
            >
              Limpar filtros
            </Typography>
          </Button>
        </Grid>
        <Grid item xs={2}>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
        </Grid>
        <Grid container item xs justify="flex-end" alignItems="flex-end">
          <Grid item xs={3}>
            <ActionsButton
              hasErrorFile={false}
              menuItems={[
                {
                  title: 'Exportar planilha de leads',
                  action: loadLeadsXls,
                },
              ]}
              buttonText="Ações"
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item alignItems="center" direction="row" spacing={2}>
        <Grid item xs={12} sm={6} md={2}>
          <MyDateRangeFilter
            placeholder="Data de criação"
            value={dateFilter}
            onChange={(date: Date[]) => setDateFilter(date)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('registrationCompleted')}
            menuItems={REGISTRATION_COMPLETED.map(registrationCompleted => ({
              title: registrationCompleted.title,
              action: () =>
                handleUpdateFilters(
                  'registrationCompleted',
                  {
                    filterTitle: registrationCompleted.title,
                    value: registrationCompleted.value,
                  },
                  CondOperator.EQUALS,
                ),
              className: style.menuOption,
            }))}
            buttonProps={{
              style: {
                borderColor: pageFilters?.['registrationCompleted'].value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              className={style.filterText}
            >
              {pageFilters['registrationCompleted'].filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
        <Grid item xs={12} sm={6} md={2}>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('planRecurrence')}
            menuItems={recurrences.map(recurrence => ({
              title: recurrence.name,
              action: () =>
                handleUpdateFilters(
                  'planRecurrence',
                  {
                    filterTitle: recurrence.name,
                    value: recurrence.name,
                  },
                  CondOperator.EQUALS,
                ),
              className: style.menuOption,
            }))}
            buttonProps={{
              style: {
                borderColor: pageFilters?.['planRecurrence'].value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              className={style.filterText}
            >
              {pageFilters['planRecurrence'].filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
      </Grid>
      {isLoading ? (
        <CircularLoading isLoading={isLoading} title={'Carregando página'} />
      ) : (
        <>
          <Paper className={style.paperTable} elevation={0}>
            <TableContainer>
              <Table className={style.table} aria-label="subscriptions list">
                <TableHead>
                  <TableRow>
                    <MySortableTableCell
                      onClick={() => handleSort('createdAt')}
                      direction={sort.field === 'createdAt' && sort.order}
                    >
                      Criada em
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('name')}
                      direction={sort.field === 'name' && sort.order}
                    >
                      NOME
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('email')}
                      direction={sort.field === 'email' && sort.order}
                    >
                      E-MAIL
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('phone')}
                      direction={sort.field === 'phone' && sort.order}
                    >
                      TELEFONE
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('planName')}
                      direction={sort.field === 'planName' && sort.order}
                    >
                      PLANO
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('planRecurrence')}
                      direction={sort.field === 'planRecurrence' && sort.order}
                    >
                      RECORRÊNCIA
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('plan')}
                      direction={sort.field === 'plan' && sort.order}
                    >
                      HASH
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('registrationCompleted')}
                      direction={
                        sort.field === 'registrationCompleted' && sort.order
                      }
                    >
                      ASSINATURA FINALIZADA
                    </MySortableTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!hasError && !isLoading && leads.length ? (
                    leads.map(lead => (
                      <TableRow key={lead.id}>
                        <MyTableCell>
                          {formatColumnValue(
                            FormatOptions.DATETIME,
                            lead.createdAt,
                          )}
                        </MyTableCell>
                        <MyTableCell>{lead.name || '-'}</MyTableCell>
                        <MyTableCell>{lead.email || '-'}</MyTableCell>
                        <MyTableCell>{lead.phone || '-'}</MyTableCell>
                        <MyTableCell>{lead.planName || '-'}</MyTableCell>
                        <MyTableCell>{lead.planRecurrence || '-'}</MyTableCell>
                        <MyTableCell>{lead.plan || '-'}</MyTableCell>
                        <MyTableCell>
                          {lead.registrationCompleted ? 'Sim' : 'Não'}
                        </MyTableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <MyTableCell colSpan={8} align="center">
                        Nenhum lead encontrado
                      </MyTableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
              <TablePagination
                ActionsComponent={MyTablePaginationActions}
                component="div"
                count={total}
                page={page}
                labelRowsPerPage="Itens por página"
                onChangePage={(_event: unknown, newPage: number) =>
                  handleSetValuePage(newPage)
                }
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
                  handleSetValuePage(0);
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </TableContainer>
          </Paper>
        </>
      )}
    </Grid>
  );
};
export default Leads;
