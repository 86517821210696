import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  filterButtonMobile: {
    // padding: '0 0 0 4px !important',
    maxWidth: '60vw !important',
    textAlign: 'right',
  },
  filterButton: {
    width: '90% !important',
  },
  gridSortable: {
    marginTop: '10px',
    textAlign: 'right',
  },
  gridSortableMobile: {
    padding: '10px 13px 0 5px',
  },
  gridCategories: {
    marginTop: '10px',
    overflowY: 'auto',
    display: 'flex',
    maxWidth: '90vw',
  },
  gridCategoryItem: {
    padding: '0 5px',
  },
  formControl: {
    maxWidth: '200px',
  },
  select: {
    backgroundColor: 'white !important',
    border: 'none !important',
    borderRadius: '15px !important',
  },
  filterSelectText: {
    fontSize: '1.5rem',
  },
  filterSelectTextMobile: {
    fontSize: '1.2rem',
  },
  expandIcon: {
    color: Colors.RED,
  },
}));
