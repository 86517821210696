import { IFiles } from './filtes';
import { Order } from './order';
import { StoreProductPayload } from './storeProduct';

export enum SHIPPING_STATUS {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
  CLOSED = 'Fechada',
}

export enum TRANSPORT_COMPANIES {
  CORREIOS = 'Correios',
  ELOHIM = 'Elohim',
  'DBA Express' = 'DBA',
}

/** TODO - to be defined */
export interface TransportCompany {}

export enum SHIPPING_TYPE {
  DONATION = 'Doação',
  SIGNATURE = 'Assinatura',
  STORE = 'Loja',
}

export interface IShippingLogs {
  message: string;
  requester: string;
  createdAt: Date;
}

export interface Shipping {
  id: number;
  name: string;
  transport: TRANSPORT_COMPANIES | string;
  shippingType: SHIPPING_TYPE;
  createdAt: string;
  updatedAt: string;
  status: SHIPPING_STATUS;
  products: StoreProductPayload[];
  orders: Order[];
  files: IFilesShipping[];
  logs: IShippingLogs[];
}

export interface IFilesShipping extends Partial<IFiles> {
  firstOrderId: number;
  lastOrderId: number;
  lengthOrders: number;
  shippingId?: number;
  transport?: string;
  createdAt?: Date;
  index?: number;
}
