import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { Grid, Button, CircularProgress } from '@material-ui/core';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';

import BannerBottom from './bannerBottom';
import { head } from 'lodash';
import { validationSchema } from './utils';
import { useStyles } from './styles';
import BannerService from 'services/bannerService';
import { ImageZone } from 'components/admin/ImagesZone/types';
import { useForm } from 'react-hook-form';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import {
  Banner,
  BannerLocation,
  BannerPayload,
  BannerType,
  IBanner,
  IBannerData,
  ISecondaryBanner,
} from 'types/generals/banner';
import {
  initRequest,
  loadBanners,
  endRequest,
} from 'store/ducks/generals/banner/actions';
import { CondOperator } from '@nestjsx/crud-request';
import { AppState } from 'store';
import { BannerState } from 'store/ducks/generals/banner/types';
import BannerUploader from 'components/generals/storeBannerUpload';

const Banners: React.FC = () => {
  const { storeBanners, isLoading: isLoadingRequest } = useSelector<
    AppState,
    BannerState
  >(({ banner }: AppState) => banner);

  const dispatch = useDispatch();
  const styles = useStyles();

  const [isLoading, setIsLoading] = useState(true);
  const [banners, setBanners] = useState({});
  const [bannerTop, setBannerTop] = useState<ImageZone[]>([]);
  const [bannerTopData, setBannerTopData] = useState<IBannerData[]>([]);
  const [bannerBottom, setBannerBottom] = useState<ImageZone[]>([]);
  const [bannerBottomData, setBannerBottomData] = useState<IBannerData[]>([]);

  const { register, errors, reset, setValue } = useForm({
    validationSchema,
  });

  const getBanners = useCallback(() => {
    dispatch(
      loadBanners({
        limit: 10,
        sort: [
          {
            field: 'id',
            order: 'DESC',
          },
        ],
        filter: [
          {
            field: 'type',
            operator: CondOperator.EQUALS,
            value: BannerLocation.HOME,
          },
        ],
      }),
    );
  }, [dispatch]);

  const normalizeStoreBanner = useCallback(
    (storeBanners: Banner | undefined) => {
      if (storeBanners) {
        const { banner, secondaryBanner } = storeBanners;
        const _banners: IBannerData[] = normalizeBannerData(
          banner,
          BannerType.PRIMARY,
        );
        const _secondaryBanner: IBannerData[] = normalizeBannerData(
          secondaryBanner,
          BannerType.SECONDARY,
        );
        setBannerTopData(_banners);
        setBannerBottomData(_secondaryBanner);
        setBanners(storeBanners);
        setIsLoading(false);
      }
    },
    [],
  );

  const normalizeBannerData = (
    banner: IBanner | ISecondaryBanner,
    bannerType: BannerType,
  ) => {
    const _banners: IBannerData[] = [];
    Object.entries(banner).forEach(([location, data]) => {
      data.forEach((_data: any) => {
        _banners.push({
          ..._data,
          location,
          type: bannerType,
        });
      });
    });
    return _banners;
  };

  useEffect(() => {
    getBanners();
    dispatch(actUpdatePageTitle('Banners'));
  }, [dispatch, getBanners]);

  useEffect(() => {
    if (storeBanners?.length) {
      normalizeStoreBanner(head(storeBanners));
    }
  }, [storeBanners, isLoading, normalizeStoreBanner]);

  const handleUpdateBannerTop = (items: any[], isOverriding: boolean) => {
    if (isOverriding) {
      setBannerTop(() => [...items]);
    } else {
      setBannerTop(data => [...data, ...items]);
    }
  };
  const handleUpdateBannerTopData = (items: any[], isOverriding: boolean) => {
    if (isOverriding) {
      setBannerTopData(() => [...items]);
    } else {
      setBannerTopData(data => [...data, ...items]);
    }
  };
  const handleUpdateBannerTopOrder = (item: any) => {
    setBannerTopData(item);
  };
  const handleUpdateBannerBottom = (items: any[]) => {
    setBannerBottom(() => [...items]);
  };
  const handleUpdateBannerBottomData = (items: any[]) => {
    setBannerBottomData(() => [...items]);
  };
  const saveBanner = async () => {
    try {
      const payload: BannerPayload = {
        ...banners,
        files: [...bannerTop, ...bannerBottom],
        banner: bannerTopData,
        secondaryBanner: bannerBottomData,
        type: BannerLocation.HOME,
      };
      dispatch(initRequest());
      await BannerService.saveBanner(payload);
    } catch (error) {
      throw error;
    }
  };

  const onSubmit = async () => {
    try {
      await saveBanner();
      reset();
      dispatch(endRequest());
      dispatch(notifySuccess('Banners cadastrados com sucesso'));
    } catch (error) {
      dispatch(notifyError('Não foi possivel concluir o cadastro'));
    }
  };

  return (
    <Grid container>
      <BannerUploader
        banner={bannerTop}
        bannerData={bannerTopData}
        errors={errors}
        register={register}
        setValue={setValue}
        handleUpdateBanner={handleUpdateBannerTop}
        handleUpdateBannerData={handleUpdateBannerTopData}
        handleUpdateBannerOrder={handleUpdateBannerTopOrder}
      />
      <BannerBottom
        banner={bannerBottom}
        bannerData={bannerBottomData}
        errors={errors}
        register={register}
        setValue={setValue}
        handleUpdateBanner={handleUpdateBannerBottom}
        handleUpdateBannerData={handleUpdateBannerBottomData}
      />
      {/* FIXME: do it in the other task */}
      {/* <EditBannerVisible /> */}
      <Grid
        container
        direction="row"
        justify="flex-end"
        spacing={2}
        className={styles.footerGridContainer}
      >
        <Grid item xs>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={clsx(styles.buttonLabel, styles.buttonWrapper)}
            disabled={isLoadingRequest}
            onClick={onSubmit}
          >
            {isLoadingRequest ? <CircularProgress size={20} /> : 'Salvar'}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Banners;
