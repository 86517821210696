import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CondOperator } from '@nestjsx/crud-request';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { startOfMonth, lastDayOfMonth } from 'date-fns';

import { useStyles } from './styles';
import { SIGNATURES_STATUS, RECURRENCES_PLAN } from './utils';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTableCell from 'components/generals/table/MyTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { formatColumnValue } from 'helpers/formatters';
import {
  PAYMENT_TYPES_FILTER,
  CONSECUTIVE_MONTHS,
} from 'helpers/planConstants';
import { useFilters } from 'hooks/filters';
import { AppState } from 'store';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import {
  loadViewUsersXlsRequest,
  loadViewUsersRequest,
} from 'store/ducks/views/actions';
import { FormatOptions } from 'types';
import { ViewState } from 'store/ducks/views/types';
import { SIGNATURE_STATUS, ORDER_STATUS } from 'types/generals';
import MyDateRangeFilter from 'components/generals/input/MyDateRangeFilter';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import MySelectTableFilter from 'components/generals/input/MySelectTableFilter';

const Subscriptions: FC = memo(() => {
  const dispatch = useDispatch();
  const style = useStyles();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const [dateFilter, setDateFilter] = useState<Date[]>([
    startOfMonth(new Date()),
    lastDayOfMonth(new Date()),
  ]);
  const [signatureStatus, setSignatureStatus] = useState([]);
  const [signaturesStatus, setSignaturesStatus] = useState([]);
  const [recurrence, setRecurrence] = useState([]);
  const [paymentType, setPaymentType] = useState([]);
  const [orderStatus, setOrderStatus] = useState([]);
  const [countPaidMonths, setCountPaidMonths] = useState([]);

  const {
    data: subscribers = [],
    total = 0,
    hasError,
    isLoading,
  } = useSelector<AppState, ViewState>(({ viewState }: AppState) => viewState);

  const {
    filterFields,
    genericFilterFields,
    sort,
    page,
    handleGenericSearch,
    handleUpdateFilters,
    handleSort,
    handleClearFilters,
    handleSetValuePage,
    handleClearPageFilter,
    pageFilters,
  } = useFilters({
    id: { filterTitle: 'ID', value: undefined },
    name: { filterTitle: 'Nome', value: undefined },
    email: { filterTitle: 'E-mail', value: undefined },
    signatureId: { filterTitle: 'Id Assinatura', value: undefined },
    signatureStatus: { filterTitle: 'Status Assinatura', value: [] },
    status: { filterTitle: 'Sub-Status', value: [] },
    signatureRenewDate: {
      filterTitle: 'Renovação Assinatura',
      value: undefined,
    },
    signaturePaymentType: {
      filterTitle: 'Tipo',
      value: [],
    },
    orderPaymentDate: { filterTitle: 'Data do Pedido', value: [null, null] },
    orderNextPaymentAttemptDate: {
      filterTitle: 'Próxima Tentativa',
      value: undefined,
    },
    orderId: { filterTitle: 'Id do Pedido', value: undefined },
    recurrenceMonths: { filterTitle: 'Recorrência', value: [] },
    orderStatus: { filterTitle: 'Status Do Pedido', value: [] },
    countPaidMonths: { filterTitle: 'Meses Consec.', value: [] },
  });

  const searchObject = [
    {
      field: 'name',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'email',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'identification',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'id',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
    {
      field: 'countPaidMonths',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
    {
      field: 'signatureId',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
    {
      field: 'orderId',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
  ];

  const loadAllSignatures = useCallback(() => {
    if (
      (dateFilter[0] !== null &&
        dateFilter[1] !== null &&
        filterFields.length) ||
      genericFilterFields.length
    ) {
      dispatch(
        loadViewUsersRequest({
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [
              ...filterFields,

              {
                orderPaymentDate: {
                  $between: dateFilter,
                },
              },
              { $or: [...genericFilterFields] },
            ],
          },
        }),
      );
    }
  }, [
    dispatch,
    page,
    sort,
    dateFilter,
    rowsPerPage,
    genericFilterFields,
    filterFields,
  ]);

  const loadAllSignaturesXls = useCallback(() => {
    if (dateFilter[0] !== null && dateFilter[1] !== null) {
      dispatch(
        loadViewUsersXlsRequest({
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [...filterFields, { $or: [...genericFilterFields] }],
          },
        }),
      );
    }
  }, [
    dispatch,
    page,
    sort,
    dateFilter,
    rowsPerPage,
    genericFilterFields,
    filterFields,
  ]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Assinantes'));
  }, [dispatch]);

  useEffect(() => {
    loadAllSignatures();
  }, [loadAllSignatures]);

  useEffect(() => {
    setDateFilter([startOfMonth(new Date()), lastDayOfMonth(new Date())]);
  }, []);

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  const handleExportSignatures = () => {
    loadAllSignaturesXls();
  };

  const handleChangeMultiFilter = (value: any, filterName: string) => {
    if (!value.length) {
      handleClearPageFilter(filterName);
    } else {
      handleUpdateFilters(
        filterName,
        {
          filterTitle: value,
          value: value,
        },
        CondOperator.IN,
      );
    }
  };

  const handleChangeSignatureStatus = (event: any) => {
    const value = event?.target?.value;
    setSignatureStatus(value);
    handleChangeMultiFilter(value, 'signatureStatus');
  };
  const handleChangeSignaturesStatus = (event: any) => {
    const value = event?.target?.value;
    setSignaturesStatus(value);
    handleChangeMultiFilter(value, 'status');
  };
  const handleChangeRecurrence = (event: any) => {
    const value = event?.target?.value;
    setRecurrence(value);
    handleChangeMultiFilter(value, 'recurrenceMonths');
  };
  const handleChangePaymentType = (event: any) => {
    const value = event?.target?.value;
    setPaymentType(value);
    handleChangeMultiFilter(value, 'signaturePaymentType');
  };
  const handleChangeOrderStatus = (event: any) => {
    const value = event?.target?.value;
    setOrderStatus(value);

    handleChangeMultiFilter(value, 'orderStatus');
  };
  const handleCountPaidMonths = (event: any) => {
    const value = event?.target?.value;
    setCountPaidMonths(value);
    handleChangeMultiFilter(value, 'countPaidMonths');
  };

  const _handleClearFilters = () => {
    setSignatureStatus([]);
    setSignaturesStatus([]);
    setRecurrence([]);
    setPaymentType([]);
    setOrderStatus([]);
    setCountPaidMonths([]);
    handleClearFilters();
  };

  const Actions = () => (
    <MyDropdown
      buttonProps={{
        color: 'primary',
        variant: 'contained',
        className: style.filterOptionsButton,
      }}
      menuProps={{
        classes: {
          list: style.filterOptionsList,
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        PaperProps: {
          style: {
            marginTop: '1rem',
            borderRadius: 13,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }}
      menuItems={[
        {
          title: 'Exportar planilha de assinantes',
          action: handleExportSignatures,
        },
      ]}
    >
      <Typography variant="h3" className={style.actionButton}>
        Ações
      </Typography>
    </MyDropdown>
  );

  return (
    <Grid container direction="column" className={style.clearFilterContainer}>
      <Grid
        item
        container
        justify="space-between"
        alignItems="center"
        className={style.clearFilterItem}
      >
        <Grid item className={style.searchFilter}>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
          <Actions />
        </Grid>
        <Grid item>
          <Grid item xs className={style.placeCenter}>
            <Button className={style.fullWidth} onClick={_handleClearFilters}>
              <Typography
                variant="h4"
                color="textPrimary"
                className={style.buttonText}
              >
                Limpar filtros
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        <Grid item xs={2}>
          <MyDateRangeFilter
            placeholder="Data pagamento"
            value={dateFilter}
            onChange={(date: Date[]) => setDateFilter(date)}
          />
        </Grid>
        <Grid item xs={2}>
          <MySelectTableFilter
            contracted
            itens={Object.values(SIGNATURE_STATUS).map(value => ({
              title: value,
              value,
            }))}
            onChange={handleChangeSignatureStatus}
            value={pageFilters.signatureStatus.value}
            helperText={null}
            title="Status Assinatura"
            error={false}
            multiple={true}
          />
        </Grid>
        <Grid item xs={2}>
          <MySelectTableFilter
            contracted
            itens={Object.values(SIGNATURES_STATUS).map(value => ({
              title: value,
              value,
            }))}
            onChange={handleChangeSignaturesStatus}
            value={pageFilters.status.value}
            helperText={null}
            title="Sub-Status"
            error={false}
            multiple={true}
          />
        </Grid>
        <Grid item xs={2}>
          <MySelectTableFilter
            contracted
            itens={RECURRENCES_PLAN.map(plan => ({
              title: plan.name,
              value: plan.value,
            }))}
            onChange={handleChangeRecurrence}
            value={pageFilters.recurrenceMonths.value}
            helperText={null}
            title="Recorrência"
            error={false}
            multiple={true}
          />
        </Grid>
        <Grid item xs={2}>
          <MySelectTableFilter
            contracted
            itens={PAYMENT_TYPES_FILTER.map(payment => ({
              title: payment.title,
              value: payment.value,
            }))}
            onChange={handleChangePaymentType}
            value={pageFilters.signaturePaymentType.value}
            helperText={null}
            title="Tipo pagamento"
            error={false}
            multiple={true}
          />
        </Grid>
        <Grid item xs={2}>
          <MySelectTableFilter
            contracted
            itens={Object.values(ORDER_STATUS).map(value => ({
              title: value,
              value,
            }))}
            onChange={handleChangeOrderStatus}
            value={pageFilters.orderStatus.value}
            helperText={null}
            title="Status Do Pedido"
            error={false}
            multiple={true}
          />
        </Grid>
        <Grid item xs={2}>
          <MySelectTableFilter
            contracted
            itens={CONSECUTIVE_MONTHS.map(value => ({
              title: value,
              value,
            }))}
            onChange={handleCountPaidMonths}
            value={pageFilters.countPaidMonths.value}
            helperText={null}
            title="Meses Consec."
            error={false}
            multiple={true}
          />
        </Grid>
      </Grid>
      <Paper className={style.paperTable} elevation={0}>
        <TableContainer>
          <Table className={style.table} aria-label="subscriptions list">
            <TableHead>
              <TableRow>
                <MyTableCell>USER ID</MyTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('name')}
                  direction={sort.field === 'name' && sort.order}
                >
                  NOME
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('email')}
                  direction={sort.field === 'email' && sort.order}
                >
                  E-MAIL
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('signatureStatus')}
                  direction={sort.field === 'signatureStatus' && sort.order}
                >
                  STATUS
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('status')}
                  direction={sort.field === 'status' && sort.order}
                >
                  SUB-STATUS
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('countPaidMonths')}
                  direction={sort.field === 'countPaidMonths' && sort.order}
                >
                  MESES CONSEQ.
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('signaturePaymentType')}
                  direction={
                    sort.field === 'signaturePaymentType' && sort.order
                  }
                >
                  TIPO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('recurrenceName')}
                  direction={sort.field === 'recurrenceName' && sort.order}
                >
                  PLANO
                </MySortableTableCell>

                <MySortableTableCell
                  onClick={() => handleSort('orderId')}
                  direction={sort.field === 'orderStatus' && sort.order}
                >
                  ID DO PEDIDO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('orderStatus')}
                  direction={sort.field === 'orderStatus' && sort.order}
                >
                  PEDIDO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('orderPaymentDate')}
                  direction={sort.field === 'orderPaymentDate' && sort.order}
                >
                  DATA PAGAMENTO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('signatureRenewDate')}
                  direction={sort.field === 'signatureRenewDate' && sort.order}
                >
                  RENOVAÇÃO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('orderNextPaymentAttemptDate')}
                  direction={
                    sort.field === 'orderNextPaymentAttemptDate' && sort.order
                  }
                >
                  PRÓXIMA TENTATIVA
                </MySortableTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {!hasError && !isLoading && subscribers.length ? (
                subscribers.map(sub => (
                  <TableRow key={sub.id} className={style.clickableRow}>
                    <MyTableCell>{sub.id || '-'}</MyTableCell>
                    <MyTableCell>{sub.name || '-'}</MyTableCell>
                    <MyTableCell>{sub.email || '-'}</MyTableCell>
                    <MyTableCell>{sub.signatureStatus || '-'}</MyTableCell>
                    <MyTableCell>{sub.status || '-'}</MyTableCell>
                    <MyTableCell>{sub.countPaidMonths}</MyTableCell>
                    <MyTableCell>{sub.signaturePaymentType || '-'}</MyTableCell>
                    <MyTableCell>{sub.recurrenceName || '-'}</MyTableCell>
                    <MyTableCell>{sub.orderId || '-'}</MyTableCell>
                    <MyTableCell>{sub.orderStatus || '-'}</MyTableCell>
                    <MyTableCell>
                      {sub.orderPaymentDate
                        ? formatColumnValue(
                            FormatOptions.VIEW_DATE,
                            sub.orderPaymentDate,
                          )
                        : '-'}
                    </MyTableCell>
                    <MyTableCell>
                      {sub.signatureRenewDate
                        ? formatColumnValue(
                            FormatOptions.VIEW_DATE,
                            sub.signatureRenewDate,
                          )
                        : '-'}
                    </MyTableCell>
                    <MyTableCell>
                      {sub.orderNextPaymentAttemptDate
                        ? formatColumnValue(
                            FormatOptions.VIEW_DATE,
                            sub.orderNextPaymentAttemptDate,
                          )
                        : '-'}
                    </MyTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className={style.clickableRow}>
                  <MyTableCell colSpan={15} align="center">
                    Nenhum resultado encontrado
                  </MyTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Grid container justify="flex-end">
        <TablePagination
          ActionsComponent={MyTablePaginationActions}
          component="div"
          count={total}
          page={page}
          labelRowsPerPage="Itens por página"
          onChangePage={(_event: unknown, newPage: number) =>
            handleSetValuePage(newPage)
          }
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleSetValuePage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
          }}
          rowsPerPageOptions={ROWS_PER_PAGE}
        />
      </Grid>
    </Grid>
  );
});

export default Subscriptions;
