import React, { FC, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';

import { AppState } from 'store';
import { UserState } from 'store/ducks/user/types';

import { profileStyles } from './styles';
import UserProfilePage from './UserProfilePage';
import UserAddressPage from './UserAddressPage';
import UserNotificationConfig from 'components/generals/UserNotificationConfig';
import { profileValidationSchema } from './types';
import { useForm } from 'react-hook-form';

const Profile: FC = () => {
  const style = profileStyles();
  const [notificationConfigId, setNotificationConfigId] = useState<number>();

  const { data: userData } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const { errors, register, control, setValue, handleSubmit } = useForm({
    validationSchema: profileValidationSchema,
    defaultValues: {
      name: userData?.name,
      email: userData?.email,
      phone: userData?.phone,
      identification: userData?.identification,
    },
  });

  useEffect(() => {
    if (userData) {
      setValue('name', userData.name);
      setValue('email', userData.email);
      setValue('phone', userData.phone);
      setValue('identification', userData.identification);
    }
  }, [setValue, userData]);

  return (
    <Grid
      container
      item
      direction="row"
      spacing={2}
      className={style.rootPanel}
    >
      {userData ? (
        <>
          <Grid item xs={12}>
            <UserProfilePage
              userInfo={userData}
              notificationConfigId={notificationConfigId}
              errors={errors}
              register={register}
              control={control}
              setValue={setValue}
              handleSubmit={handleSubmit}
            />
          </Grid>
          <Grid item xs={12}>
            <UserAddressPage userInfo={userData} />
          </Grid>
          <Grid item xs={12}>
            <UserNotificationConfig
              setNotificationConfigId={setNotificationConfigId}
              register={register}
              control={control}
              user={userData}
            />
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default Profile;
