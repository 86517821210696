import { Reducer } from 'redux';
import { Transport } from 'types/generals';
import { TransportState, TransportTypes } from './types';

const INITIAL_STATE: TransportState = {
  data: {} as Transport,
  isLoading: true,
  hasError: false,
};

const reducer: Reducer<TransportState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case TransportTypes.LOAD_TRANSPORT_COVERAGE_REQUEST:
      return { ...state, isLoading: true };
    case TransportTypes.LOAD_TRANSPORT_COVERAGE_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload,
      };
    case TransportTypes.LOAD_TRANSPORT_COVERAGE_FAIL:
      return { ...state, hasError: true };
    default:
      return state;
  }
};

export default reducer;
