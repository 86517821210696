import { api, getWithSaga } from './apiService';
import { Roles } from 'types/generals';
import { CreateQueryParams } from '@nestjsx/crud-request';

export default class RolesService {
  static async getRole(query?: CreateQueryParams) {
    try {
      const data = getWithSaga('/roles', query);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async createRole(roleData: Partial<Roles>) {
    try {
      const data = api.post('/roles', roleData);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updateRole(roleId: number, roleData: Partial<Roles>) {
    try {
      const data = api.patch(`/roles/${roleId}`, roleData);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async deleteRole(roleId: number) {
    try {
      const data = api.delete(`/roles/${roleId}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async findPermissions(query?: CreateQueryParams) {
    try {
      const data = getWithSaga('/permissions', query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async findRoles(query?: CreateQueryParams) {
    try {
      const data = getWithSaga('/roles', query);
      return data;
    } catch (error) {
      return error;
    }
  }
}
