import React, { FC, useCallback } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@material-ui/icons/Close';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import { head, sortBy } from 'lodash';

import { AppState } from 'store';
import { CartState } from 'store/ducks/cart/types';
import {
  decreaseCartItemCount,
  deleteCartProduct,
  increaseCartItemCount,
} from 'store/ducks/cart/actions';
import { formatColumnValue } from 'helpers/formatters';
import { useStyles } from '../styles';
import { CartPayload } from 'types/client';
import { StoreProduct } from 'types/generals';
import { CartProductProps } from '../types';
import { FormatOptions } from 'types';
import { verifyCartRules } from 'store/ducks/cart/utils';
import { notifyError } from 'store/ducks/notification/actions';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';

const CartProduct: FC<CartProductProps> = ({ product }) => {
  const coverImage = head(sortBy(product.images, [({ fileName }) => fileName]));
  const dispatch = useDispatch();
  const styles = useStyles();

  const { cartLength, cartProducts } = useSelector<AppState, CartState>(
    ({ cartReducer }: AppState) => cartReducer,
  );

  const { data: signature } = useSelector<AppState, SingleSignatureState>(
    ({ oneSignature }: AppState) => oneSignature,
  );

  const { activeSettings } = useSelector<AppState, StoreSettingsState>(
    ({ storeSettings }: AppState) => storeSettings,
  );

  const inFullDate = useCallback(
    date => formatColumnValue(FormatOptions.IN_FULL_DATE, date),
    [],
  );

  const handleDecreaseAmount = useCallback(
    (product: StoreProduct) => {
      const payload: CartPayload = {
        id: product.id,
      };
      dispatch(
        decreaseCartItemCount(payload, {
          ...activeSettings,
          isPremium: signature.user.isPremium,
        }),
      );
    },
    [dispatch, activeSettings, signature.user.isPremium],
  );

  const handleIncreaseAmount = useCallback(
    (product: StoreProduct) => {
      try {
        if (verifyCartRules(cartLength, cartProducts, product)) {
          const payload: CartPayload = {
            id: product.id,
          };
          dispatch(
            increaseCartItemCount(payload, {
              ...activeSettings,
              isPremium: signature.user.isPremium,
            }),
          );
        }
      } catch (error) {
        dispatch(notifyError(error.message));
      }
    },
    [
      cartLength,
      cartProducts,
      dispatch,
      activeSettings,
      signature.user.isPremium,
    ],
  );

  const handleDeleteProduct = useCallback(
    (product: StoreProduct) => {
      const payload: CartPayload = {
        id: product.id,
      };
      dispatch(
        deleteCartProduct(payload, {
          ...activeSettings,
          isPremium: signature.user.isPremium,
        }),
      );
    },
    [activeSettings, dispatch, signature.user.isPremium],
  );

  return (
    <Grid
      container
      justify="space-between"
      direction="row"
      alignItems="flex-start"
      item
      xs={12}
      style={{ marginBottom: '16px' }}
    >
      <Grid item xs>
        <img
          src={coverImage?.image}
          className={styles.imgContent}
          alt={'Imagem do produto'}
        />
      </Grid>
      <Grid item xs>
        <Typography variant="subtitle1" className={styles.greyText}>
          {inFullDate(product.startDate)}
        </Typography>
        <Typography
          variant="h4"
          color="textPrimary"
          className={styles.cartProductName}
        >
          {product.name}
        </Typography>
        <Grid
          container
          item
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ flexFlow: 'row' }}
        >
          <Grid item xs>
            <IconButton onClick={() => handleDecreaseAmount(product)}>
              <RemoveIcon />
            </IconButton>
          </Grid>
          <Grid item xs>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{ textAlign: 'center' }}
            >
              {product.amount}
            </Typography>
          </Grid>
          <Grid item xs>
            <IconButton onClick={() => handleIncreaseAmount(product)}>
              <AddIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item xs justify="flex-end">
        <IconButton onClick={() => handleDeleteProduct(product)}>
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default CartProduct;
