import { StoreNotification } from 'types/generals/notification';
export enum StoreNotificationTypes {
  LOAD_NOTIFICATION_REQUEST = '@notification/LOAD_NOTIFICATION_REQUEST',
  SAVE_NOTIFICATION_REQUEST = '@notification/SAVE_NOTIFICATION_REQUEST',
  INIT_NOTIFICATION_REQUEST = '@notification/INIT_NOTIFICATION_REQUEST',
  END_NOTIFICATION_REQUEST = '@notification/END_NOTIFICATION_REQUEST',
  LOAD_NOTIFICATION_SUCCESS = '@notification/LOAD_NOTIFICATION_SUCCESS',
  LOAD_NOTIFICATION_FAILURE = '@notification/LOAD_NOTIFICATION_FAILURE',
}

export interface StoreNotificationState {
  readonly data: StoreNotification[];
  readonly isLoading: boolean;
  readonly hasError: boolean;
}
