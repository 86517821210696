import React, { ChangeEvent, FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { closeModal } from 'store/ducks/nav/actions';
import useFormStyles from 'components/generals/forms/register/styles';
import Colors from 'styles/colors';
import { ItemsListDialogProps } from './types';
import ItemLabel from 'components/generals/labels/ItemLabel';
import { useStyles } from './styles';

const ItemsListDialog: FC<ItemsListDialogProps> = ({
  title,
  message,
  items,
  redirectOption,
  selected,
  changeFn,
  confirmationFn,
}) => {
  const [idItemSelected, setIdItemSelected] = useState<number | undefined>(
    selected,
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const formStyles = useFormStyles();

  const redirectToPage = (url: string) => {
    dispatch(closeModal());
    history.push(url);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const itemIndex = items.findIndex(item => item.name === e.target.value);
    setIdItemSelected(items[itemIndex].id);
  };

  const confirmSelection = () => {
    if (changeFn) {
      changeFn(idItemSelected);
      dispatch(closeModal());
    }
  };

  // O componente estava limitado a executar apenas um setState, agora ele tem uma função
  // que pode ser passada como callback e ja executa uma ação.
  const confirmFn = () => {
    if (idItemSelected !== undefined && confirmationFn) {
      confirmationFn(idItemSelected);
      dispatch(closeModal());
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h3" color="textPrimary">
            {message}
          </Typography>
        </Grid>
      </DialogTitle>
      <DialogContent className={styles.listSpacing}>
        <Grid container item justify="space-between" direction="column">
          <FormControl component="fieldset">
            <RadioGroup className={styles.labelsGroup} onChange={handleChange}>
              {items.map(item => (
                <FormControlLabel
                  key={item.id}
                  tabIndex={item.id}
                  checked={item.id === idItemSelected}
                  value={item.name}
                  control={<Radio />}
                  label={
                    <ItemLabel name={item.name} subtitle={item.subtitle} />
                  }
                  className={styles.radioWrapper}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item justify="space-between">
          <Grid container item justify="flex-end" alignItems="center">
            {redirectOption ? (
              <Grid item xs>
                <Button onClick={() => redirectToPage(redirectOption.url)}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    style={{
                      color: Colors.RED,
                      fontWeight: 'bold',
                      textTransform: 'capitalize',
                    }}
                  >
                    {redirectOption.buttonText}
                  </Typography>
                </Button>
              </Grid>
            ) : null}
            <Button
              color="primary"
              className={formStyles.buttonLabel}
              style={{ marginRight: 30 }}
              onClick={() => dispatch(closeModal())}
            >
              Cancelar
            </Button>
            {confirmationFn ? (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={`${formStyles.buttonLabel} ${formStyles.buttonWrapper}`}
                onClick={() => confirmFn()}
              >
                Confirmar
              </Button>
            ) : (
              <Button
                type="submit"
                variant="contained"
                color="primary"
                className={`${formStyles.buttonLabel} ${formStyles.buttonWrapper}`}
                onClick={() => confirmSelection()}
              >
                Selecionar
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default ItemsListDialog;
