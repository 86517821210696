import { call, put } from 'redux-saga/effects';
import ViewsService from 'services/viewsService';
import {
  loadViewUsersLastOrderFailure,
  loadViewUsersLastOrderSuccess,
} from './actions';

export function* loadViewUserSignatureLastOrderSaga(action: any) {
  try {
    const { data, total } = yield call(
      ViewsService.getViewUserSignatureLastOrder,
      action.payload.query,
    );
    yield put(loadViewUsersLastOrderSuccess({ data, total }));
  } catch (error) {
    yield put(loadViewUsersLastOrderFailure());
  }
}
