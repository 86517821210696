import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

const useStyles = makeStyles({
  buttonText: {
    color: Colors.RED,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  boldText: {
    fontWeight: 600,
  },
  gridTop: {
    marginTop: 50,
  },
  paperTable: {
    marginTop: 18,
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
});

export { useStyles };
