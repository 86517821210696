/**
 * Action types
 */
export enum MenuTypes {
  TOGGLE = '@layout/menu/TOGGLE',
}

export interface LayoutState {
  readonly isMobileMenuOpen: boolean;
}
