import { CreateQueryParams } from '@nestjsx/crud-request';
import { ROOT_PATH } from 'services/fileservice';
import { action } from 'typesafe-actions';
import { PlanPayload, PlanTypes } from './types';
import { PlanPages } from 'pages/client/change-plan/types';

export const loadAllPlans = (query?: CreateQueryParams) => {
  return action(PlanTypes.LOAD_PLAN_LIST_REQUEST, query);
};
export const loadAllPlansSuccess = (payload: PlanPayload) => {
  return action(PlanTypes.LOAD_PLAN_LIST_SUCCESS, payload);
};
export const loadAllPlansFailure = () => {
  return action(PlanTypes.LOAD_PLAN_LIST_FAILURE);
};

export const loadPlanFile = (query: CreateQueryParams, rootPath: ROOT_PATH) => {
  return action(PlanTypes.LOAD_PLAN_FILE_REQUEST, { query, rootPath });
};
export const loadPlansToReactivate = (page: PlanPages) => {
  return action(PlanTypes.LOAD_PLANS_TO_REACTIVATE, page);
};
