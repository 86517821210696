import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  icon: {
    paddingBottom: 24,
  },
  wrapper: {
    [theme.breakpoints.up('lg')]: {
      marginTop: '10%',
    },
    marginTop: '15%',
    textAlign: 'center',
  },
  title: {
    fontWeight: 600,
    fontSize: '20px',
  },
  sub: {
    textAlign: 'center',
    paddingTop: 20,
    fontWeight: 400,
    fontSize: '16px',
    color: Colors.MEDIUM_GRAY,
  },
}));
