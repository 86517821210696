import React, { useEffect, useState } from 'react';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import CloseIcon from '@material-ui/icons/Close';
import { format, parseISO } from 'date-fns';

import { closeModal, openModal } from 'store/ducks/nav/actions';
import useStyles from 'components/generals/forms/register/styles';
import {
  notifyError,
  notifySuccess,
  disableButton,
  enableButton,
} from 'store/ducks/notification/actions';
import { monthOrderValidationSchema } from './utils';
import { SignatureTypeDialogProps, BaseDialogProps } from './dialogTypes';
import {
  ORDER_CREATE_SUCCESS,
  ORDER_CREATE_CONFIRM,
  ORDER_CREATE_ERROR,
} from 'utils/messages';
import SignatureService from 'services/signatureService';
import ConfirmationDialog from './ConfirmationDialog';
import MyDatePicker from '../input/MyDatePicker';
import MyOutlinedTextField from '../input/MyOutlinedTextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { AppState } from 'store';
import { BoxesState } from 'store/ducks/generals/boxes/types';
import { loadBoxes } from 'store/ducks/generals/boxes/actions';

const AddMonthOrderDialog: React.FC<SignatureTypeDialogProps> = ({
  modalTitle,
  cancelText,
  cancelAction,
  submitText,
  subscriber,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const [boxId, setBoxId] = useState<number>(0);

  const { data: boxes } = useSelector<AppState, BoxesState>(
    ({ boxes }: AppState) => boxes,
  );

  const { handleSubmit, control, errors, watch, register } = useForm({
    validationSchema: monthOrderValidationSchema,
  });

  const startDate = watch('startDate');

  const onSubmit = async () => {
    try {
      dispatch(disableButton());
      const formatedDate = format(new Date(startDate), 'yyyy-MM-dd');
      const response = await SignatureService.creteOrderMonth(
        subscriber.id,
        parseISO(formatedDate),
        boxId,
      );
      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ORDER_CREATE_SUCCESS));
        dispatch(closeModal());
        history.go(0);
      } else {
        throw new Error(ORDER_CREATE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleOpenConfirmDialog = () => {
    const modalProps: BaseDialogProps = {
      title: 'Adicionando pedido',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: ORDER_CREATE_CONFIRM,
      actionFn: () => onSubmit(),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  useEffect(() => {
    dispatch(loadBoxes({ fields: ['id', 'name'] }));
  }, [dispatch]);

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {modalTitle}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <Controller
              name="startDate"
              control={control}
              as={({ value, onChange }) => (
                <MyDatePicker
                  id="startDate"
                  onChange={onChange}
                  value={value}
                  name="startDate"
                  label="Data do pedido"
                  error={Boolean(errors.startDate)}
                  helperText={
                    errors.startDate ? errors['startDate'].message : null
                  }
                  allowKeyboardControl={true}
                />
              )}
            />
          </Grid>
          <Grid item xs>
            <Autocomplete
              id="auto-complete"
              fullWidth
              options={boxes}
              getOptionLabel={(option: any) => option.name}
              renderInput={params => (
                <MyOutlinedTextField
                  {...params}
                  id="boxId"
                  name="boxId"
                  error={Boolean(errors.name)}
                  helperText={errors.name ? errors.name.message : null}
                  inputRef={register}
                  label="Box"
                  style={{ paddingTop: 4, paddingBottom: 8 }}
                />
              )}
              onChange={(e: any, newValue: any) => {
                setBoxId(newValue.id);
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={2}
        className={styles.actionsWrapper}
      >
        <DialogActions>
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={cancelAction || (() => dispatch(closeModal()))}
          >
            {cancelText ?? 'Cancelar'}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
            onClick={handleSubmit(handleOpenConfirmDialog)}
          >
            {submitText}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default AddMonthOrderDialog;
