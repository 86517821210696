import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const profileStyles = makeStyles((theme: Theme) =>
  createStyles({
    rootPanel: {
      padding: theme.spacing(5.5, 0),
      height: '80%',
    },
    rootPaper: {
      padding: theme.spacing(3, 4),
      borderRadius: '2em',
      height: '100%',
    },
    mTop: {
      marginTop: theme.spacing(5),
    },
    paddingButton: {
      padding: theme.spacing(2, 4),
    },
  }),
);
