import { Box } from 'types';

export enum BoxesTypes {
  LOAD_BOXES_REQUEST = '@boxes/LOAD_BOXES_REQUEST',
  LOAD_ONE_BOX_REQUEST = '@boxes/LOAD_ONE_BOX_REQUEST',
  LOAD_BOXES_FILE_REQUEST = '@boxes/LOAD_BOXES_FILE_REQUEST',
  LOAD_BOXES_SUCCESS = '@boxes/LOAD_BOXES_SUCCESS',
  LOAD_ONE_BOX_SUCCESS = '@boxes/LOAD_ONE_BOX_SUCCESS',
  LOAD_BOXES_FAIL = '@boxes/LOAD_BOXES_FAIL',
}

export interface BoxesState {
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly total: number;
  readonly data: Box[];
}

export interface OneBoxState extends Omit<BoxesState, 'data' | 'total'> {
  readonly data: Box;
}
