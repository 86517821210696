import * as Yup from 'yup';

export const addressValidationSchema = Yup.object({
  zipcode: Yup.string().required('Cep obrigatório'),
  street: Yup.string()
    .min(2, 'Endereço esta incompleto')
    .required('Endereço obrigatório'),
  number: Yup.string().required('Número obrigatório'),
  neighborhood: Yup.string()
    .min(2, 'Nome da bairro esta incompleto')
    .required('Bairro obrigatório'),
  city: Yup.string().required('Cidade obrigatório'),
  state: Yup.string()
    .max(2, 'Só é permitido 2 dígitos')
    .required('Estado obrigatório'),
  complement: Yup.string().max(30, 'Limite de caracteres é de 30'),
});
