import { CreateQueryParams } from '@nestjsx/crud-request';
import { User } from 'types/generals';
import {
  NOTIFICATION_GROUP,
  UserNotificationConfig,
} from 'types/generals/notification';
import { api, getWithSaga } from './apiService';

const STORE_NOTIFICATION_URL = 'notifications';
const USER_NOTIFICATION_URL = 'user-notifications';

interface UserNotificationConfigGroupsForm {
  isLogistics: boolean;
  isPaymentStore: boolean;
  isPaymentSignature: boolean;
  isSchedule: boolean;
}
export default class NotificationService {
  static async getAllNotifications(query?: CreateQueryParams) {
    try {
      const data = await getWithSaga(STORE_NOTIFICATION_URL, query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async saveNotifications(payload: any) {
    try {
      const { data } = await api.post(
        `${STORE_NOTIFICATION_URL}/save-many`,
        payload,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async saveUserNotificationConfig(
    data: any,
    notificationConfigId: number,
    user: User,
  ) {
    try {
      const groups = this.mapUserNotificationConfigGroups({
        isLogistics: data.isLogistics,
        isPaymentStore: data.isPaymentStore,
        isPaymentSignature: data.isPaymentSignature,
        isSchedule: data.isSchedule,
      });
      const payload = {
        groups,
        id: notificationConfigId,
        userId: user.id,
      };
      return await api.post(`${USER_NOTIFICATION_URL}/configuration`, payload);
    } catch (error) {
      return error;
    }
  }

  static async getUserNotificationConfig(
    userId: number,
  ): Promise<UserNotificationConfig> {
    try {
      const { data } = await api.get(
        `${USER_NOTIFICATION_URL}/configuration/${userId}`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static mapUserNotificationConfigGroups(
    data: UserNotificationConfigGroupsForm,
  ) {
    const newGroups = [];

    if (data.isLogistics) {
      newGroups.push(NOTIFICATION_GROUP.LOGISTICS);
    }
    if (data.isPaymentStore) {
      newGroups.push(NOTIFICATION_GROUP.PAYMENT_STORE);
    }
    if (data.isPaymentSignature) {
      newGroups.push(NOTIFICATION_GROUP.PAYMENT_SIGNATURE);
    }
    if (data.isSchedule) {
      newGroups.push(NOTIFICATION_GROUP.SCHEDULE);
    }
    return newGroups;
  }
}
