import { CreateQueryParams } from '@nestjsx/crud-request';
import { getWithSaga, api, apiQueue } from './apiService';
import { DonationPayload, PAYMENT_TYPE } from 'types/generals';
import { getCurrentUser } from './authService';

const BASE_URL = 'signature';

export default class SignatureService {
  static async getAllSignatures(query: CreateQueryParams) {
    try {
      const data = await getWithSaga(BASE_URL, query);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async hasOrderToPayment(signatureId: number, orderId: number) {
    try {
      const data = await apiQueue.post(
        `${BASE_URL}/${signatureId}/force-payment-with-new-card/${orderId}`,
        {},
      );
      return data;
    } catch (err) {
      return err.response.data;
    }
  }

  static async getUserSignature(query?: CreateQueryParams) {
    try {
      const { data } = await getWithSaga(BASE_URL, query);
      return data?.[0];
    } catch (err) {
      return err;
    }
  }

  static async getSignature(signatureId: string, query?: CreateQueryParams) {
    try {
      const data = await getWithSaga(`${BASE_URL}/${signatureId}`, query);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async saveDonation(donation: DonationPayload) {
    try {
      const data = await api.post(`${BASE_URL}/donation`, donation);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async requestCancelSignature(id: string, message: string) {
    try {
      const data = await api.put(`${BASE_URL}/${id}/request-cancel`, {
        message,
      });
      return data;
    } catch (err) {
      return err;
    }
  }

  static async cancelSignature(id: string) {
    try {
      const data = await api.put(`${BASE_URL}/${id}/cancel`);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async reactivateSignature(id: string) {
    try {
      const data = await apiQueue.put(`${BASE_URL}/${id}/reactivate`);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async forceStartNextSignature(id: number) {
    try {
      const data = await apiQueue.put(
        `${BASE_URL}/${id}/force-start-signature-plan`,
      );
      return data;
    } catch (err) {
      return err;
    }
  }

  static async setSignatureDefault(id: number, userId: number) {
    try {
      const data = await apiQueue.put(
        `${BASE_URL}/${id}/set-signature-default`,
        null,
        {
          params: { userId },
        },
      );
      return data;
    } catch (err) {
      return err;
    }
  }

  static async renewSignatureAnticipate(id: number) {
    try {
      const data = await apiQueue.put(`${BASE_URL}/${id}/renew-anticipate`);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async requestCancelChangePlan(id: number) {
    try {
      const data = await api.delete(
        `${BASE_URL}/${id}/force-start-signature-plan`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async changeSignatureOrderAddress(id: number) {
    try {
      const data = await api.post(`${BASE_URL}/${id}/change-order-address`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async reactivateSignatureUser(id: number, paymentType: PAYMENT_TYPE) {
    try {
      const data = await apiQueue.put(
        `${BASE_URL}/${id}/reactivate-signature`,
        {
          paymentType,
        },
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getOrdersWithoutPaymentBySignature(signatureId: number) {
    try {
      const data = await api.get(
        `${BASE_URL}/${signatureId}/get-orders-without-payment`,
      );
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async currentSignatureUser(userId?: number) {
    try {
      let id = userId;
      if (!userId) {
        id = getCurrentUser().id;
      }
      const { data } = await api.get(`${BASE_URL}/get-current-signature/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async changePaymentTypeSignature(
    id: number,
    paymentType: PAYMENT_TYPE,
    isAdmin = true,
  ) {
    try {
      const path = isAdmin
        ? `${BASE_URL}/${id}/change-payment-type`
        : `${BASE_URL}/${id}/change-payment-type/client`;
      const data = await api.patch(path, {
        paymentType,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  static async changeRenewDateSignature(
    id: number,
    renewDate: Date,
    isAdmin = true,
  ) {
    try {
      const path = isAdmin
        ? `${BASE_URL}/${id}/change-renew-date`
        : `${BASE_URL}/${id}/change-renew-date/client`;
      const data = await api.patch(path, {
        renewDate,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  static async creteOrderMonth(
    signatureId: number,
    startDate: Date,
    boxId: number,
  ) {
    try {
      const data = await apiQueue.post(
        `${BASE_URL}/create-order-monthy`,
        null,
        {
          params: { signatureId, startDate, boxId },
        },
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async changePlan(
    signatureId: number,
    planId: number,
    paymentType: PAYMENT_TYPE,
    isDonation = false,
  ) {
    try {
      const path = isDonation ? 'change-donation-plan' : 'change-plan';
      const data = await apiQueue.post(`${BASE_URL}/${signatureId}/${path}`, {
        planId,
        paymentType,
      });
      return data;
    } catch (error) {
      return error;
    }
  }

  static async changePlanVerifyIfHasCardRegistered(userId: number) {
    try {
      const data = await api.get(
        `${BASE_URL}/change-plan-verify-card/${userId}`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async handleAddObservationSignature(
    signatureId: number,
    observation: string,
  ) {
    try {
      const data = await api.post(
        `${BASE_URL}/${signatureId}/add-observation`,
        { observation },
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async handleCreateSignatureRemaingOrders(signatureId: number) {
    try {
      const data = await api.post(
        `${BASE_URL}/${signatureId}/create-remaing-orders`,
        {},
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async handleGetNextSignatureByCurrentSignatureId(signatureId: number) {
    try {
      const data = await api.get(
        `${BASE_URL}/${signatureId}/change-plan/next-signature`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }
}
