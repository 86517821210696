import { DataFreteTransportData } from 'types/generals/dataFrete';

export enum DataFreteTransportTypes {
  LOAD_TRANSPORT_DATA_FRETE_REQUEST = '@datafrete/LOAD_TRANSPORT_DATA_FRETE_REQUEST',
  LOAD_TRANSPORT_DATA_FRETE_SUCCESS = '@datafrete/LOAD_TRANSPORT_DATA_FRETE_SUCCESS',
  LOAD_TRANSPORT_DATA_FRETE_FAIL = '@datafrete/LOAD_TRANSPORT_DATA_FRETE_FAIL',
}

export interface DataFreteTransportState {
  readonly data: DataFreteTransportData;
  readonly hasError: boolean;
  readonly isLoading: boolean;
}
