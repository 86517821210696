import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  IconButton,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import MyTypography from 'components/generals/labels/MyTypography';
import { closeModal } from 'store/ducks/nav/actions';
import { useDialogStyles } from '../useStyles';
import { Title, IconWrapper, Message, Wrapper, Icon } from './styles';
import { FeedbackWithLinkDialogProps } from '../dialogTypes';

const FeedbackWithLinkDialog: React.FC<FeedbackWithLinkDialogProps> = ({
  title,
  message,
  icon,
  disableCloseButton,
  myLink,
  myLinkMessage,
  goBackOnClose,
}) => {
  const dispatch = useDispatch();
  const classes = useDialogStyles();
  const history = useHistory();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleRedirect = () => {
    history.push(myLink);
    dispatch(closeModal());
  };

  const handleClose = () => {
    if (goBackOnClose) {
      history.push('/home');
    }
    dispatch(closeModal());
  };

  return (
    <Wrapper
      container
      direction="column"
      style={{ padding: isMobile ? 0 : '20px' }}
    >
      {!disableCloseButton && (
        <IconWrapper container justify="flex-end">
          <IconButton onClick={() => handleClose()}>
            <CloseIcon />
          </IconButton>
        </IconWrapper>
      )}
      <Grid container direction="column" justify="center" alignItems="center">
        {icon && <Icon component={icon} />}
        <Title variant="h1" color="textPrimary">
          {title}
        </Title>
        <Grid item xs={12} sm={12} md={12}>
          <Message variant="h3" color="textPrimary" className={classes.info_mb}>
            {message}
          </Message>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Button
            variant="text"
            color="primary"
            onClick={() => handleRedirect()}
          >
            <MyTypography className={classes.buttonText}>
              {myLinkMessage}
            </MyTypography>
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default FeedbackWithLinkDialog;
