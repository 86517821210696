import { makeStyles } from '@material-ui/core';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  capitalize: {
    fontSize: '14px',
    textTransform: 'capitalize',
  },
  mLabel: {
    margin: theme.spacing(0, 0, 0.4, 0),
  },
  mAmountBold: {
    margin: theme.spacing(1, 0, 0.4, 0),
  },
});
