import React, { FC } from 'react';
import { Grid } from '@material-ui/core';

import { Tag, useStyles } from './styles';
import { TagProps } from './types';

const TagWrapper: FC<TagProps> = ({ config, value, fullwidth }) => {
  const classes = useStyles();
  return (
    <Grid className={classes.inlineTag}>
      <Tag config={config} fullwidth={fullwidth}>
        {value}
      </Tag>
    </Grid>
  );
};

export * from './types';
export default TagWrapper;
