import React, { FC, useState } from 'react';
import { Grid, useMediaQuery } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import MobileStepper from '@material-ui/core/MobileStepper';
import SwipeableViews from 'react-swipeable-views';
import { autoPlay } from 'react-swipeable-views-utils';

import { useStyles } from './styles';
import { MySliderProps } from './types';

const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const MySlider: FC<MySliderProps> = ({ steps, storeSlider }) => {
  const [activeStep, setActiveStep] = useState(0);
  const classes = useStyles();
  const theme = useTheme();
  const maxSteps = steps.length;
  const smallMobile = useMediaQuery(theme.breakpoints.down(415));

  const handleStepChange = (step: any) => {
    setActiveStep(step);
  };

  const handleRedirect = (step: any) => {
    if (step?.link) {
      window.open(step?.link, '_blank');
    }
  };

  return (
    <Grid className={classes.root}>
      <AutoPlaySwipeableViews
        className={storeSlider ? classes.storeAutoPlay : classes.autoPlay}
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={activeStep}
        onChangeIndex={handleStepChange}
        enableMouseEvents
        animateTransitions
      >
        {steps.map((step, index) => (
          <div key={step.label}>
            {Math.abs(activeStep - index) <= 2 ? (
              <img
                className={smallMobile ? classes.imgMobile : classes.img}
                src={step.path}
                alt={step.label}
                onClick={() => handleRedirect(step)}
              />
            ) : null}
          </div>
        ))}
      </AutoPlaySwipeableViews>
      <MobileStepper
        className={classes.sliderStepper}
        nextButton={null}
        backButton={null}
        steps={maxSteps}
        position="static"
        variant="dots"
        activeStep={activeStep}
      />
    </Grid>
  );
};

export default MySlider;
