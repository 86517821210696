export const PRODUCT_APPEND_SUCCESS =
  'O produto desejado foi adicionado à remessa';
export const PRODUCT_APPEND_ERROR =
  'Houve uma falha ao adicionar o produto desejado na remessa';
export const SELECT_PRODUCT_TO_SHIPPING =
  'Selecione o produto desejado para incluir na remessa';
export const SELECT_PRODUCT_PLACEHOLDER = 'Selecione o produto desejado';
export const ORDER_DELETED_SUCCESS =
  'O pedido foi removido da remessa com sucesso';
export const ORDER_DELETED_ERROR =
  'Houve uma falha ao remover o pedido da remessa';
export const PRODUCT_DELETED_SUCCESS =
  'O produto desejado foi removido da remessa com sucesso';
export const PRODUCT_DELETED_ERROR =
  'Houve uma falha ao remover o produto da remessa';
export const REMOVE_ORDER_MODAL = (
  orderId: number,
  shipping: string,
) => `Tem certeza que deseja remover o pedido ${orderId} da ${shipping}? 
  Ao confirmar essa ação não é possível reverter.`;

export const REMOVE_PRODUCT_MODAL = (
  productName: string,
  shipping: string,
) => `Tem certeza que deseja remover o produto ${productName} da ${shipping}? 
Ao confirmar essa ação não é possível reverter.`;

export const SHIPPING_CREATED_SUCCESS = 'Remessa criada com sucesso.';
export const SHIPPING_CREATE_ERROR = 'Erro ao gerar remessa.';
export const SHIPPING_LABELS_ERROR = 'Houve um erro ao gerar as etiquetas';
export const SHIPPING_LABELS_SUCCESS =
  'As etiquetas foram coladas na fila com sucesso';
export const SHIPPING_UPDATE_SUCCESS = 'Remessa atualizada com sucesso';
export const SHIPPING_UPDATE_ERROR = 'Houve uma falha ao atualizar a remessa';

export const SHIPPING_PROCCESS_LIST = (shippingId: number) =>
  `Processando romaneio para remessa ${shippingId}`;
export const SHIPPING_SET_SIGEP_LABELS_SUCCESS = (shippingId: number) =>
  `Processo de inserção de etiquetas da Remessa ${shippingId} foi inicializado.`;
export const SHIPPING_SET_SIGEP_LABELS_ERROR = (message: string) =>
  `Não foi possivel gerar etiquetas para a remessa. Error: ${message}`;
export const SHIPPING_GENERATE_INVOICES_SUCCESS = (shippingId: number) =>
  `Processo de gerar notas da remessa ${shippingId} foi inicializado.`;
export const SHIPPING_GENERATE_INVOICES_ERROR = (message: string) =>
  `Não foi possivel gerar notas. Error: ${message}`;
export const SHIPPING_EXPORT_LABELS_SUCCESS = (shippingId: number) =>
  `Gerando etiquetas para a remessa ${shippingId}`;
export const SHIPPING_EXPORT_LABELS_ERROR = `Falha ao gerar as etiquetas`;

export const ADD_ORDER_SHIPPING_ERROR =
  'Aconteceu um erro ao adicionar pedido a remessa.';
export const REMOVE_SHIPPING_MSG = (value: string) =>
  `Tem certeza que deseja excluir a ${value}? Ao confirmar essa ação não é possível reverter.`;

export const REMOVE_SHIPPING_ERROR = `Não foi possível excluir a Remessa solicitada`;
