import { useState, useMemo, useEffect, useCallback } from 'react';
import { QuerySort, CondOperator, QueryFilter } from '@nestjsx/crud-request';
import { Filters } from 'types';
import { AppState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import {
  PageFilterData,
  PageFilterState,
  QueryFilterFull,
} from 'store/ducks/page-filters/types';
import { useHistory } from 'react-router-dom';
import { pageFiltersUpdate } from 'store/ducks/page-filters/actions';

/**
 * @param fields: Normalmente a tela tem campos no modo select, então eu preciso
 * limpar tais campos quando o user clicar em "limpar campos" e ao selecionar um
 * valor em 1 select especifico, preciso colocar o valor selecionado como
 * "title" do cambo, por isso preciso passar o fields
 *
 * Possivel melhoria: Tem telas que não tem o filtro em select, apenas o filtro
 * generico, então imagino que o correto seria esse custom Hook ter a props
 * fields como optional
 *
 * @function
 * */
export const useFilters = (fields: Filters) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [sort, setSort] = useState<QuerySort>({ field: 'id', order: 'DESC' });
  const [filters, setFilters] = useState<QueryFilterFull[]>([]);
  const [genericFilter, setGenericFilter] = useState<QueryFilter[]>([]);
  const [search, setSearch] = useState('');

  const [dateFilter, setDateFilter] = useState<any>([null, null]);
  const [pageFilters, setPageFilters] = useState<Filters>(fields);
  const [page, setPage] = useState(0);
  const arrayPathName = history.location.pathname.split('/');
  const currentPage = arrayPathName.length
    ? arrayPathName[arrayPathName.length - 1]
    : '';

  const { filters: savedFilters } = useSelector<AppState, PageFilterState>(
    ({ pageFilter }: AppState) => pageFilter,
  );

  const filterFields = useMemo(
    () =>
      filters.map(filter => ({
        [filter.field]: { [filter.operator]: filter.value },
      })),
    [filters],
  );

  const genericFilterFields = useMemo(
    () =>
      genericFilter.map(f => ({
        [f.field]: { [f.operator]: f.value },
      })),
    [genericFilter],
  );

  useEffect(() => {
    if (currentPage && savedFilters[currentPage]) {
      const _filters = savedFilters[currentPage];
      if (_filters?.rawFilters) {
        setFilters(_filters?.rawFilters);
      }
      mapFiltersToPageFilters(savedFilters[currentPage]);
    }
  }, [currentPage, savedFilters]);

  const handleUpdateFilters = (
    field: string,
    genericObject: { filterTitle: string; value: any },
    op?: CondOperator,
    isGeneric?: boolean,
    countGenericFilters?: number,
    genericFilters?: {
      field: string;
      operator: any;
      format?: (v: any) => any;
    }[],
  ) => {
    const operator = op || CondOperator.EQUALS;
    const keptFilters =
      (filters &&
        filters.filter((f: any) => {
          if (isGeneric) {
            if (
              genericFilters &&
              genericFilters.some(gf => {
                return gf.field === f.field;
              })
            ) {
              return f.field !== field;
            }
          } else return f.field !== field;
        })) ||
      [];
    const { value, filterTitle } = genericObject;

    if (value !== undefined) {
      keptFilters.push({ field, operator, value, filterTitle });
    }

    setPageFilters(curretValue => {
      return { ...curretValue, [field]: genericObject };
    });

    if (isGeneric) {
      setGenericFilter(oldState => {
        return [...oldState, ...keptFilters];
      });
    } else {
      setFilters(keptFilters);
      saveFiltersToStore(keptFilters);
    }
  };

  const handleSort = (field: string) => {
    let order: 'ASC' | 'DESC' = 'DESC';
    if (field === sort.field) {
      order = sort.order === 'ASC' ? 'DESC' : 'ASC';
    }
    setSort({
      field,
      order,
    });
  };

  // the properties should be defined in the `useFilters`
  const saveFiltersToStore = (_filters: QueryFilterFull[]) => {
    if (currentPage) {
      const newFilter: any = {
        rawFilters: [],
        mappedFilters: {},
      };
      _filters.forEach(filter => {
        newFilter.mappedFilters[filter.field] = filter.value;
        newFilter.rawFilters.push(filter);
      });

      dispatch(pageFiltersUpdate(currentPage, newFilter));
    }
  };

  /**
   * Map the PageFilters store data to the local PageFilters prop
   * - the properties should be defined in the `useFilters`
   */
  const mapFiltersToPageFilters = (savedFilter: PageFilterData) => {
    const { rawFilters } = savedFilter;
    if (rawFilters) {
      const newPageFilters: any = {};
      rawFilters.forEach((_filter: QueryFilterFull) => {
        newPageFilters[_filter.field] = {
          filterTitle: _filter.filterTitle,
          value: _filter.value,
        };
      });
      setPageFilters(curretValue => {
        return { ...curretValue, ...newPageFilters };
      });
    }
  };

  const handleGenericSearch = (
    event: any,
    filters: { field: string; operator: any; format?: any }[],
  ) => {
    const value = event?.currentTarget?.value;
    // Reseta o filtro toda vez que da enter e já foi filtrado alguma vez antes (by J.S)
    if (genericFilter.length > 0) {
      setGenericFilter([]);
    }

    if (value !== '') {
      filters.map(filter =>
        handleUpdateFilters(
          filter.field,
          {
            filterTitle: filter.field,
            value: filter.format ? filter.format(value) : value,
          },
          filter.operator,
          true,
          filters.length,
          filters,
        ),
      );
    } else setGenericFilter([]);
    setPage(0);
  };

  // Any é util para o momento em que o campo vai ser limpo
  const handleDateFilter = (
    date: Date[] | any,
    filterTitle = 'Período',
    field = 'createdAt',
  ) => {
    setDateFilter(date);
    let transformedDate;
    if (date[0] && date[1]) {
      transformedDate = date.map((d: any) => {
        const _dTz = d?.toISOString();
        return _dTz;
      });
    }
    handleUpdateFilters(
      field,
      { filterTitle, value: transformedDate },
      CondOperator.BETWEEN,
    );
  };

  const handleClearFilters = () => {
    setPageFilters({ ...fields });
    handleDateFilter([null, null]);
    setSearch('');
    setGenericFilter([]);
    setFilters([]);
    setPage(0);

    dispatch(
      pageFiltersUpdate(currentPage, { rawFilters: [], mappedFilters: {} }),
    );
  };

  const handleClearPageFilter = (field: string) => {
    const resetedFilter = { ...fields[field] };
    const pageFiltersToUpdate = pageFilters;
    const queryFiltersToUpdate = filters;
    const queryFiltersIndex = queryFiltersToUpdate.findIndex(
      filter => filter.field === field,
    );

    pageFiltersToUpdate[field] = { ...resetedFilter };

    if (queryFiltersIndex !== -1)
      queryFiltersToUpdate.splice(queryFiltersIndex, 1);

    setFilters([...queryFiltersToUpdate]);
    setPageFilters({ ...pageFiltersToUpdate });
    saveFiltersToStore([...queryFiltersToUpdate]);
  };

  const handleSetValuePage = (value: number) => {
    setPage(value);
  };

  // the property should be defined in the `useFilters`
  const setInitialDateFromStore = useCallback(
    (field: string) => {
      const _date = pageFilters?.[field]?.value;
      // when selecting the first value, the state is not updating passing "undefined"
      // so setting the default value here is not possible
      if (_date && _date[0]) {
        const initialDate = _date.map((val: string) => {
          if (val) {
            return typeof val === 'string' ? new Date(val) : val;
          }
          return null;
        });
        setDateFilter(initialDate);
      }
    },
    [pageFilters],
  );

  return {
    setInitialSort: setSort,
    setInitialDate: setDateFilter,
    setInitialDateFromStore,
    filterFields,
    genericFilterFields,
    sort,
    search,
    dateFilter,
    pageFilters,
    page,
    handleGenericSearch,
    handleSort,
    handleUpdateFilters,
    handleDateFilter,
    handleClearFilters,
    handleClearPageFilter,
    handleSetValuePage,
  };
};
