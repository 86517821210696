import React, { useState, useRef, useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { StatusCodes } from 'http-status-codes';
import {
  Divider,
  Grid,
  Typography,
  useTheme,
  useMediaQuery,
  FormControlLabel,
  Radio,
  Button,
  Select,
  MenuItem,
} from '@material-ui/core';
import { Add, ExpandMore, Remove } from '@material-ui/icons';

import { useStyles } from './styles';

import CreditCardForm from 'components/generals/creditCardForm';
import LoadingDotsDialog from 'components/generals/dialog/loadingDotsDialog';
import SelectedPlanCard from './SelectedPlanCard';

import { AppState } from 'store';
import { CreditCardState } from 'store/ducks/payment/creditCard/types';
import { loadCreditCards } from 'store/ducks/payment/creditCard/actions';
import {
  backStepPlanPage,
  setPaymentType,
  nextStepPlanPage,
} from 'store/ducks/change-plan/actions';
import { ChangePlanState } from 'store/ducks/change-plan/types';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { openModal, closeModal } from 'store/ducks/nav/actions';

import CreditCardService from 'services/creditCardService';
import AddressService from 'services/addressService';
import SignatureService from 'services/signatureService';

import { BootstrapInput } from 'pages/client/signature/ReativarPlano/utils';

import { InputRef, Address } from 'types';
import { PAYMENT_TYPE } from 'types/generals';
import Colors from 'styles/colors';
import { separateDate } from 'helpers/validateDate';
import { discoverCardBrand } from 'helpers/creditCards';
import { ChangePlan, ChangePlanObject } from './types';
import { validationSchema, validationSchemaCreditCard } from './utils';
import {
  ADD_NEW_CARD_ERROR,
  ADD_NEW_CARD_SUCCESS,
  CREATE_ADDRESS_FAIL,
  EDIT_CARD_FAIL,
  CHANGE_PLAN_ERROR,
} from 'utils/messages';
import { OpenNewWindowLink } from 'utils/functions/generals';
import { generateQueryString } from 'pages/client/create-account/utils';
import { clearOnePlanState } from 'store/ducks/generals/plan/onePlan/actions';

const PaymentStep: React.FC = () => {
  const style = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const smallSize = useMediaQuery(theme.breakpoints.down('xs'));
  const [addCardButtonActive, setAddCardButtonActive] = useState(false);
  const [cardChecked, setCardChecked] = useState(false);
  const [bankslipChecked, setBankslipChecked] = useState(false);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = useState(false);
  const [addCardButtonDisabled, setAddCardButtonDisabled] = useState(false);

  const { data: creditCards, isLoading: isLoadingCreditCard } = useSelector<
    AppState,
    CreditCardState
  >(({ creditCard }: AppState) => creditCard);

  const { signature, address, plan } = useSelector<AppState, ChangePlanState>(
    ({ changePlan }: AppState) => changePlan,
  );

  const { control, errors, setValue, handleSubmit } = useForm({
    validationSchema,
  });

  const {
    control: controlCard,
    register: registerCard,
    errors: errorsCard,
    setValue: setValueCard,
    handleSubmit: handleSubmitCard,
  } = useForm({
    validationSchema: validationSchemaCreditCard,
  });

  const loadCreditCard = useCallback(() => {
    dispatch(loadCreditCards());
  }, [dispatch]);

  const paymentCardInputRef = useRef() as InputRef;
  const paymentBankslipInputRef = useRef() as InputRef;

  const saveNewCard = async (data: any) => {
    try {
      const { cardName, dueDate, ccNumber, cvv } = data;

      setAddCardButtonDisabled(true);

      const response = await CreditCardService.addNewCreditCard({
        dueDate: dueDate,
        number: ccNumber,
        cardName: cardName,
        isDefault: true,
        brand: discoverCardBrand(ccNumber.toString()),
        cvv: cvv,
      });

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ADD_NEW_CARD_SUCCESS));
        setAddCardButtonActive(false);
        setConfirmButtonDisabled(false);
        setValueCard('newCardButton', false);
        loadCreditCard();
      } else {
        throw new Error(ADD_NEW_CARD_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      setAddCardButtonDisabled(false);
    }
  };

  const saveAddress = async (address: Address) => {
    try {
      const response = await AddressService.createNewAddress({
        ...address,
        user: signature.user.id,
      });

      if (response.status === StatusCodes.CREATED) {
        return response;
      } else {
        throw new Error(CREATE_ADDRESS_FAIL);
      }
    } catch (error) {
      throw error;
    }
  };

  const setCreditCardDefault = async (creditCardId: number) => {
    try {
      const creditCard = creditCards.find(card => card.id === creditCardId);

      if (creditCard) {
        const response = await CreditCardService.updateCreditCard(
          { ...creditCard, isDefault: true },
          creditCardId,
        );

        if (response.status === StatusCodes.OK) {
          return response;
        } else {
          throw new Error(EDIT_CARD_FAIL(creditCardId.toString()));
        }
      }
    } catch (error) {
      throw error;
    }
  };

  const submit = async (data: ChangePlanObject) => {
    try {
      dispatch(openModal(LoadingDotsDialog));

      if (!address.id) {
        await saveAddress(address);
      }

      const changePlan: ChangePlan = {} as ChangePlan;
      changePlan.planId = plan.id;
      changePlan.signatureId = signature.id;

      if (cardChecked) {
        changePlan.paymentType = PAYMENT_TYPE.CARD;
        changePlan.creditCardId = data.creditCardId;
        await setCreditCardDefault(Number(changePlan.creditCardId));
      } else {
        changePlan.paymentType = PAYMENT_TYPE.BANKSLIP;
      }

      const response = await SignatureService.changePlan(
        signature.id,
        changePlan.planId,
        changePlan.paymentType,
      );

      if (response.status === StatusCodes.CREATED) {
        dispatch(setPaymentType(changePlan.paymentType));
        dispatch(closeModal());
        dispatch(nextStepPlanPage());
        dispatch(clearOnePlanState());

        if (plan.actionPage) {
          const params = {
            tipoPagamento: changePlan.paymentType,
          };
          const queryString = generateQueryString(params);
          const page = `${plan.actionPage}${queryString}`;
          OpenNewWindowLink(page);
        }
      } else {
        dispatch(closeModal());
        throw new Error(CHANGE_PLAN_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const togglePaymentTypeCard = () => {
    setCardChecked(true);
    setBankslipChecked(false);
    setValue('paymentCard', true);
    setValue('paymentBankslip', false);
  };

  const togglePaymentTypeBankslip = () => {
    setCardChecked(false);
    setBankslipChecked(true);
    setValue('paymentCard', false);
    setValue('paymentBankslip', true);
    setConfirmButtonDisabled(false);
  };

  const toggleButtonNewCard = () => {
    setAddCardButtonActive(!addCardButtonActive);
    setConfirmButtonDisabled(!confirmButtonDisabled);
    setValueCard('newCardButton', !addCardButtonActive);
    togglePaymentTypeCard();
  };

  useEffect(() => {
    loadCreditCard();
  }, [loadCreditCard]);

  useEffect(() => {
    switch (plan.paymentType) {
      case PAYMENT_TYPE.BANKSLIP_CARD:
      case PAYMENT_TYPE.CARD:
        setValue('paymentCard', true);
        setCardChecked(true);
        if (!isLoadingCreditCard && creditCards.length) {
          setValue(
            'creditCardId',
            creditCards.find(creditCard => creditCard.isDefault === true)?.id,
          );
        }
        break;
      case PAYMENT_TYPE.BANKSLIP:
        setBankslipChecked(true);
        setValue('paymentBankslip', true);
        break;
    }
  }, [plan, setValue, creditCards, isLoadingCreditCard]);

  return (
    <Grid container justify="space-between">
      <Grid item xs={12} className={style.containerPadding}>
        <Divider />
      </Grid>

      <Grid item xs={12} className={style.containerPadding}>
        <Typography variant="h3">Pagamento</Typography>
      </Grid>

      <Grid container item xs={12} md={8}>
        {!smallSize ? (
          <Grid container item md={12}>
            <Typography className={style.boldText} variant="h2">
              Escolha como deseja reativar sua assinatura:
            </Typography>
          </Grid>
        ) : null}

        <Grid container item xs={12}>
          {plan.paymentType === PAYMENT_TYPE.CARD ||
          plan.paymentType === PAYMENT_TYPE.BANKSLIP_CARD ? (
            <>
              <Grid item xs={12} className={style.paymentTypeGrid}>
                <Grid item xs>
                  <Controller
                    name="paymentCard"
                    control={control}
                    as={({ value }) => (
                      <FormControlLabel
                        name="paymentCard"
                        inputRef={paymentCardInputRef}
                        value={value}
                        control={
                          <Radio
                            checked={cardChecked}
                            onClick={() => togglePaymentTypeCard()}
                          />
                        }
                        label={
                          <Typography className={style.selectText}>
                            Pagar com cartão de crédito
                          </Typography>
                        }
                      />
                    )}
                  />
                </Grid>
                {!addCardButtonActive ? (
                  <Grid item xs={12}>
                    <Controller
                      name="creditCardId"
                      control={control}
                      as={({ value, onChange }) => (
                        <>
                          <Select
                            style={{ border: 0 }}
                            fullWidth
                            id="creditCard"
                            value={value || ''}
                            onChange={e => onChange(e.target.value)}
                            defaultValue={value}
                            input={<BootstrapInput />}
                            error={Boolean(errors.creditCard)}
                            IconComponent={props => (
                              <ExpandMore
                                {...props}
                                style={{ fontSize: 25, color: Colors.ORANGE }}
                              />
                            )}
                            displayEmpty
                          >
                            <MenuItem key="0" value="" disabled>
                              <Grid container item xs={12} alignItems="center">
                                <Typography
                                  className={style.creditCardNotSelectedText}
                                  variant="h3"
                                >
                                  Selecione um cartão
                                </Typography>
                              </Grid>
                            </MenuItem>
                            {creditCards.length &&
                              creditCards.map(card => (
                                <MenuItem key={card.id} value={card.id}>
                                  <Grid item xs={12}>
                                    <Typography variant="h3">
                                      {card.cardName}
                                    </Typography>
                                  </Grid>
                                  <Grid item xs={12}>
                                    <Typography variant="h3">
                                      {card.number}
                                    </Typography>
                                  </Grid>
                                </MenuItem>
                              ))}
                          </Select>
                          {errors.creditCardId ? (
                            <Typography
                              variant="body1"
                              className={style.errorText}
                            >
                              {errors.creditCardId.message}
                            </Typography>
                          ) : null}
                        </>
                      )}
                    />
                  </Grid>
                ) : null}
              </Grid>

              <Grid
                container
                item
                xs={12}
                justify="flex-end"
                alignItems="center"
              >
                <Controller
                  name="newCardButton"
                  control={controlCard}
                  as={() => (
                    <Button
                      onClick={() => toggleButtonNewCard()}
                      variant="text"
                      className={style.buttonMargin}
                    >
                      <Typography className={style.buttonAddCardText}>
                        Adicionar novo cartão de crédito
                      </Typography>
                      {addCardButtonActive ? (
                        <Remove className={style.addIcon} />
                      ) : (
                        <Add className={style.addIcon} />
                      )}
                    </Button>
                  )}
                />
              </Grid>

              {addCardButtonActive ? (
                <Grid item xs={12}>
                  <CreditCardForm
                    control={controlCard}
                    register={registerCard}
                    errors={errorsCard}
                    setValue={setValueCard}
                  />

                  <Grid item>
                    <Button
                      className={style.buttonAddCard}
                      variant="outlined"
                      disabled={addCardButtonDisabled}
                      onClick={handleSubmitCard(saveNewCard)}
                    >
                      <Typography className={style.buttonTextAddCard}>
                        Adicionar
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
            </>
          ) : null}

          {plan.paymentType === PAYMENT_TYPE.BANKSLIP ||
          plan.paymentType === PAYMENT_TYPE.BANKSLIP_CARD ? (
            <>
              <Grid
                container
                item
                alignItems="center"
                xs={12}
                className={style.paymentTypeGrid}
              >
                <Grid container item xs alignItems="center">
                  <Controller
                    name="paymentBankslip"
                    control={control}
                    as={({ value }) => (
                      <FormControlLabel
                        name="paymentBankslip"
                        inputRef={paymentBankslipInputRef}
                        value={value}
                        control={
                          <Radio
                            checked={bankslipChecked}
                            onClick={() => {
                              togglePaymentTypeBankslip();
                              setAddCardButtonActive(false);
                            }}
                          />
                        }
                        label={
                          <Typography className={style.selectText}>
                            Pagar com boleto
                          </Typography>
                        }
                      />
                    )}
                  />
                </Grid>
              </Grid>
              <Typography variant="body1">
                O pagamento por boleto pode levar até 3 dias para ser
                compensado.
              </Typography>
            </>
          ) : null}
        </Grid>
      </Grid>

      <SelectedPlanCard plan={plan} cardChecked={cardChecked} />

      <Grid
        container
        item
        xs={12}
        sm={8}
        direction={smallSize ? 'column-reverse' : 'row'}
        justify="flex-end"
        spacing={1}
      >
        <Grid item>
          <Button
            className={smallSize ? style.backButtonMobile : style.backButton}
            variant="outlined"
            onClick={() => dispatch(backStepPlanPage())}
          >
            <Typography className={style.backButtonText}>Voltar</Typography>
          </Button>
        </Grid>
        <Grid item>
          <Button
            onClick={handleSubmit(submit as any)}
            className={
              smallSize ? style.confirmButtonMobile : style.confirmButton
            }
            variant="contained"
            disabled={confirmButtonDisabled}
          >
            <Typography className={style.confirmButtonText}>
              Confirmar alteração
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PaymentStep;
