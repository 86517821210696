import React, { ChangeEvent, FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StatusCodes } from 'http-status-codes';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { CategoryDialogProps } from '../dialogTypes';
import useStyles from 'components/generals/forms/register/styles';

import { closeModal } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';

import { validationSchema } from './utils';
import {
  NEW_CATEGORY_ERROR,
  NEW_CATEGORY_SUCCESS,
  SAVE_CATEGORY_ERROR,
  SAVE_CATEGORY_SUCCESS,
} from 'utils/messages';

import MySwitch from 'components/generals/input/MySwitch';
import { StoreCategory } from 'types/generals';
import StoreCategoriesService from 'services/storeCategoriesService';
import { loadCategoryRequest } from 'store/ducks/categories/actions';

const CategoryDialog: FC<CategoryDialogProps> = ({
  title,
  id,
  name,
  isActive = true,
  priority,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, errors, register, reset } = useForm({
    validationSchema,
    defaultValues: {
      id,
      name,
      isActive,
      priority,
    },
  });
  const [active, setActive] = useState<boolean>(isActive);

  const handleCloseDialog = () => {
    reset();
    dispatch(
      loadCategoryRequest({
        page: 0,
        limit: 10,
      }),
    );
    dispatch(closeModal());
  };

  const createCategory = async (data: Partial<StoreCategory>) => {
    const response = await StoreCategoriesService.createStoreCategories({
      name: data.name,
      isActive: data.isActive,
      priority: data.priority,
    });
    if (response.status === StatusCodes.CREATED) {
      dispatch(notifySuccess(NEW_CATEGORY_SUCCESS));
      handleCloseDialog();
    } else {
      throw NEW_CATEGORY_ERROR;
    }
  };
  const updateCategory = async (
    categoryId: number,
    data: Partial<StoreCategory>,
  ) => {
    const response = await StoreCategoriesService.updateStoreCategories(
      categoryId,
      {
        name: data.name,
        isActive: data.isActive,
        priority: data.priority,
      },
    );
    if (response.status === StatusCodes.OK) {
      dispatch(notifySuccess(SAVE_CATEGORY_SUCCESS));
      handleCloseDialog();
    } else {
      throw SAVE_CATEGORY_ERROR;
    }
  };
  const onSubmit = async (data: Partial<StoreCategory>) => {
    try {
      if (id) {
        updateCategory(id, data);
      } else {
        createCategory(data);
      }
    } catch (err) {
      dispatch(notifyError(err));
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={2}
          component="form"
          style={{ overflow: 'hidden' }}
        >
          <Grid item xs>
            <MyOutlinedTextField
              id="category-name"
              name="name"
              label="Nome"
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs>
            <MyOutlinedTextField
              id="category-priority"
              name="priority"
              label="Prioridade"
              type="number"
              fullWidth
              error={Boolean(errors.priority)}
              helperText={errors.priority ? errors.priority.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid
            container
            spacing={3}
            item
            justify="space-between"
            direction="row"
            alignItems="center"
          >
            <Grid item xs>
              <FormControlLabel
                name="isActive"
                control={
                  <MySwitch
                    name="isActive"
                    color="primary"
                    checked={active}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      setActive(e.target.checked)
                    }
                    inputRef={register}
                  />
                }
                label="Ativo"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          item
          spacing={2}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => dispatch(closeModal())}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
            onClick={handleSubmit(onSubmit)}
          >
            Confirmar
          </Button>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default CategoryDialog;
