import React, { FC } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import AdminLoginScreen from 'pages/admin/login';
import ClientLoginScreen from 'pages/client/login';
import RecoverPasswordAdmin from 'pages/admin/recover-password';
import RecoverPasswordClient from 'pages/client/recover-password';
import NewPasswordAdmin from 'pages/admin/recover-password/NewPassword';
import NewPasswordClient from 'pages/client/recover-password/NewPassword';
import { SignatureProvider } from 'contexts/signatureContext';
import CreateAccount from 'pages/client/create-account';
import CheckoutCompleted from 'pages/client/create-account/CheckoutCompleted';
import GenerateBankslip from 'pages/client/generate-bankslip';
import { AppState } from 'store';
import { useSelector } from 'react-redux';
import { Modal } from 'store/ducks/nav/types';
import { Dialog } from '@material-ui/core';

const NonAuthRoutes: FC = () => {
  const {
    open,
    maxWidth,
    component: Component,
    props,
  } = useSelector<AppState, Modal>(({ nav }: AppState) => nav.modal);
  return (
    <>
      <Dialog maxWidth={maxWidth} open={open}>
        {Component ? <Component {...props} /> : null}
      </Dialog>
      <Switch>
        <Route
          exact
          path="/admin"
          component={() => <Redirect to={{ pathname: '/admin/login' }} />}
        />
        <Route
          exact
          path="/admin/login"
          component={() => <AdminLoginScreen />}
        />
        <Route
          exact
          path="/admin/recover-password"
          component={() => <RecoverPasswordAdmin />}
        />
        <Route
          exact
          path="/admin/recover-password/:token"
          component={() => <NewPasswordAdmin />}
        />
        <Route
          exact
          path="/"
          component={() => <Redirect to={{ pathname: '/login' }} />}
        />
        <Route path="/login" component={() => <ClientLoginScreen />} />
        <Route
          exact
          path="/create-account/:hashPlan"
          component={() => (
            <SignatureProvider>
              <CreateAccount />
            </SignatureProvider>
          )}
        />
        <Route
          exact
          path="/create-account/:hashPlan/success"
          component={() => <CheckoutCompleted />}
        />
        <Route
          exact
          path="/download-bankslip/:hash"
          component={() => <GenerateBankslip />}
        />
        <Route
          exact
          path="/recover-password"
          component={() => <RecoverPasswordClient />}
        />
        <Route
          exact
          path="/recover-password/:token"
          component={() => <NewPasswordClient />}
        />
      </Switch>
    </>
  );
};

export default NonAuthRoutes;
