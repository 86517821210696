import { apiQueue } from './apiService';
import { DataFreteProductsPayload } from 'types/generals/dataFrete';

export default class TransportService {
  static async getTransportCoverageThroughCEP(zipcode: string) {
    try {
      const data = apiQueue.post('/transport/verify-coverage', {
        zipcode,
      });
      return data;
    } catch (error) {
      return error;
    }
  }
  static async getTransportFreteData(
    zipcode: string,
    products: DataFreteProductsPayload[],
  ) {
    try {
      const response = await apiQueue.post('/transport/quote-freight', {
        cepDestino: zipcode,
        produtos: products,
      });
      return response;
    } catch (error) {
      return error;
    }
  }

  static async handleSetTransportInResendOrders(
    transport: string,
    description: string,
    orderIds: number[],
  ) {
    try {
      const data = apiQueue.post(
        '/transport/set-transport-pre-shipping-resend',
        {
          transport,
          description,
          orderIds,
        },
      );
      return data;
    } catch (error) {
      return error;
    }
  }
}
