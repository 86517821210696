import React, { ChangeEvent } from 'react';
import { Grid, Button, Paper, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import MySwitch from 'components/generals/input/MySwitch';
import { NotificationRowsProps } from './types';

const NotificationRows: React.FC<NotificationRowsProps> = ({
  notifications,
  handleOpenEdit,
  handleChangePermissions,
  useTypeForText = false,
}) => {
  const styles = useStyles();

  return (
    <>
      <Grid container direction="column" spacing={1}>
        {notifications.map((notification, index) => (
          <Grid item xs key={index}>
            <Paper className={styles.rootPaper}>
              <Grid
                container
                item
                direction="row"
                xs={12}
                spacing={1}
                alignItems="center"
              >
                <Grid item xs={5}>
                  <Typography
                    variant="h2"
                    className={styles.notificationRowTitle}
                  >
                    {useTypeForText
                      ? notification.type
                      : `Aviso de "${notification.type}"`}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs
                  container
                  justify="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="h3"
                    className={styles.notificationRowOptionTitle}
                  >
                    Enviar e-mail
                  </Typography>
                  <MySwitch
                    color="primary"
                    checked={notification.permissions.sendEmail}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChangePermissions(notification, 'sendEmail')
                    }
                  />
                </Grid>
                <Grid
                  item
                  xs
                  container
                  justify="flex-start"
                  alignItems="center"
                >
                  <Typography
                    variant="h3"
                    className={styles.notificationRowOptionTitle}
                  >
                    Enviar sms
                  </Typography>
                  <MySwitch
                    name="enabledStore"
                    color="primary"
                    checked={notification.permissions.sendSms}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                      handleChangePermissions(notification, 'sendSms')
                    }
                  />
                </Grid>
                <Grid>
                  <Button onClick={() => handleOpenEdit(notification)}>
                    <Typography
                      variant="h4"
                      color="textPrimary"
                      className={styles.buttonEditText}
                    >
                      Editar
                    </Typography>
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default NotificationRows;
