import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
} from '@material-ui/core';

import { AppState } from 'store';
import { loadCreditCards } from 'store/ducks/payment/creditCard/actions';
import { CreditCardState } from 'store/ducks/payment/creditCard/types';
import { UserState } from 'store/ducks/user/types';
import { loadUserRequest } from 'store/ducks/user/actions';

import { useStyles } from './styles';
import Colors from 'styles/colors';
import theme from 'styles/theme';
import CardPanel from './CardPanel';
import { CREDIT_CARD_NOT_REGISTERED_MESSAGE } from 'utils/messages/creditCard';

const Cards: FC = () => {
  const style = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const mobileSize = useMediaQuery(theme.breakpoints.down('xs'));

  const { data: creditCards = [], isLoading } = useSelector<
    AppState,
    CreditCardState
  >(({ creditCard }: AppState) => creditCard);

  const { data: user } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  useEffect(() => {
    dispatch(loadCreditCards());
  }, [dispatch]);

  useEffect(() => {
    dispatch(loadUserRequest());
  }, [dispatch]);

  const handleRedirectNewCard = () => history.push('/my-account/cards/new');

  const AddCardButton = () => (
    <Button
      className={style.paddingButton}
      onClick={() => handleRedirectNewCard()}
    >
      <Typography
        variant="h4"
        color="textPrimary"
        style={{
          color: Colors.RED,
          fontWeight: 'bold',
        }}
      >
        Adicionar cartão
      </Typography>
    </Button>
  );

  const HeaderView = () => (
    <Grid container direction="row" justify="space-between">
      <Typography
        variant="h1"
        color="textPrimary"
        style={{ fontWeight: 'normal' }}
      >
        Cadastro de <strong>cartões</strong>
      </Typography>
      {!mobileSize && <AddCardButton />}
    </Grid>
  );

  return (
    <Grid container className={style.rootPanel}>
      <Grid item xs={12}>
        <Paper className={style.rootPaper} elevation={0}>
          <HeaderView />
          <Grid
            container
            justify="space-between"
            spacing={3}
            direction="column"
            className={style.mTop}
          >
            {!isLoading && creditCards.length ? (
              creditCards.map(card => (
                <Grid key={card.id} item>
                  <CardPanel cardData={card} />
                </Grid>
              ))
            ) : (
              //FIXME: centralizar usando flexBox
              <Grid container justify="center">
                <Typography variant="subtitle1">
                  {CREDIT_CARD_NOT_REGISTERED_MESSAGE(user?.name)}
                </Typography>
              </Grid>
            )}
            {mobileSize && <AddCardButton />}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Cards;
