import {
  PAYMENT_TYPE,
  StoreProduct,
  TRANSPORT_COMPANIES,
  ILogCotation,
} from 'types/generals';
import { ScoreData, ScoreDataStauts } from './storeSettings';

export const MAX_CART_LENGTH = 50;
export const MAX_PRODUCT_IN_CART = 5;

export interface Cart {
  products: StoreProduct[];
}

export interface CartPayload {
  id: number;
}

export interface CartProductDetails {
  id: number;
  productAmount: number;
  productCount: number;
}

export interface CartDiscountPayload {
  discount: number;
  shouldResetScore?: boolean;
}

export interface CartScoreDataPayload {
  discount: number;
  points: number;
  rankingSeasonId: number;
  status?: ScoreDataStauts;
}

export interface CartPaymentStore {
  userId: number;
  cartProductDetails: CartProductDetails[];
  cartAmount: number;
  subtotal: number;
  freight: number;
  discount: number;
  addressId: number;
  paymentType: PAYMENT_TYPE;
  transport: string;
  couponId?: number;
  cardId?: number;
  installments?: number;
  logCotation?: ILogCotation;
  transportIdentification?: string;
  scoreData?: ScoreData;
}
