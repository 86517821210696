import { action } from 'typesafe-actions';
import { CreateQueryParams } from '@nestjsx/crud-request';
import { LeadsTypes } from './types';
import { UserLead } from 'types/generals';
import { ROOT_PATH } from 'services/fileservice';

export const loadLeads = (query?: CreateQueryParams) => {
  return action(LeadsTypes.LOAD_REQUEST, { query });
};

export const loadLeadsSuccess = (payload: {
  total: number;
  data: UserLead[];
}) => {
  return action(LeadsTypes.LOAD_SUCCESS, payload);
};

export const loadLeadsFailure = () => {
  return action(LeadsTypes.LOAD_ERROR);
};

export const loadLeadsFile = (
  query: CreateQueryParams,
  rootPath: ROOT_PATH,
) => {
  return action(LeadsTypes.LOAD_LEAD_FILE, { query, rootPath });
};
