import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import useStyles from './styles';
import {
  TERMS_CONDITIONS,
  PRIVATE_CONDITIONS,
} from 'utils/links/termsConditions';

export interface MyTermsProps {
  className?: string;
}

const MyTerms: FC<MyTermsProps> = ({ className }) => {
  const styles = useStyles();
  const termLink = TERMS_CONDITIONS;
  const privateLink = PRIVATE_CONDITIONS;
  return (
    <>
      <Link
        className={styles.termsAndConditionsLink}
        to={{
          pathname: termLink,
        }}
        target="_blank"
      >
        <span
          className={className ? className : styles.termsAndConditionsDetail}
        >
          Termos e Condições{' '}
        </span>
      </Link>
      <Link
        className={styles.termsAndConditionsLink}
        to={{
          pathname: privateLink,
        }}
        target="_blank"
      >
        <span
          className={className ? className : styles.termsAndConditionsDetail}
        >
          e a Política de Privacidade.
        </span>
      </Link>
    </>
  );
};
export default MyTerms;
