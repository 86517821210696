import { ORDER_STATUS } from 'types/generals';

export const STATUS_OPTIONS = [
  'Aguardando pagamento',
  'Pago',
  'Cancelado',
  'Em separação',
  'Enviado',
  'Entregue',
].sort();

export const BASE_STATUS_OPTIONS = ['Ativo', 'Inativo'];

export const ORDER_STATUS_LIST = [
  'Pedido',
  'Pagamento',
  'Separação',
  'Envio',
  'Concluído',
];

export const ORDER_CANCEL_STATUS_LIST = [
  'Pedido efetuado',
  'Cancelamento pendente',
  'Cancelado',
];

export const ORDER_STATUS_LIST_FILTER = [
  'Pedido efetuado',
  'Pagamento confirmado',
  'Pagamento pendente',
  'Pagamento devolvido',
  'Pagamento recusado',
  'Em separação',
  'Em trânsito',
  'Aguardando retirada',
  'Falha na entrega',
  'Devolvido ao remetente',
  'Saiu para entrega',
  'Entregue',
  'Cancelamento pendente',
  'Cancelado',
];

export const ACTIVE_STATUS = [
  ORDER_STATUS.SUBMITTED,
  ORDER_STATUS.PENDING,
  ORDER_STATUS.FAILED,
  ORDER_STATUS.PAID,
];
