export enum FileTypes {
  LOAD_FILE_REQUEST = '@File/LOAD_FILE_REQUEST',
  LOAD_FILE_REQUEST_SUCCESS = '@File/LOAD_FILE_REQUEST_SUCCESS',
  LOAD_FILE_REQUEST_FAILURE = '@File/LOAD_FILE_REQUEST_FAILURE',
}

export interface FileState {
  readonly isLoading: boolean;
  readonly hasError: boolean;
}
