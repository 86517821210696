import { RankingSeason, UserRankingSeason } from 'types/generals/rankingSeason';
import { DefaultState } from '../common/types';

export enum RankingTypes {
  LOAD_ACTIVE_RANKING_REQUEST = '@ranking/LOAD_ACTIVE_RANKING_REQUEST',
  LOAD_ACTIVE_RANKING_SUCCESS = '@ranking/LOAD_ACTIVE_RANKING_SUCCESS',
  LOAD_ACTIVE_RANKING_FAILURE = '@ranking/LOAD_ACTIVE_RANKING_FAILURE',
  LOAD_RANKING_REQUEST = '@ranking/LOAD_RANKING_REQUEST',
  LOAD_RANKING_SUCCESS = '@ranking/LOAD_RANKING_SUCCESS',
  LOAD_RANKING_FAILURE = '@ranking/LOAD_RANKING_FAILURE',
  LOAD_RANKING_FILE_REQUEST = '@ranking/LOAD_RANKING_FILE_REQUEST',
  LOAD_USER_RANKING_SEASON_REQUEST = '@userRankingSeason/LOAD_USER_RANKING_SEASON_REQUEST',
  LOAD_USER_RANKING_SEASON_SUCCESS = '@userRankingSeason/LOAD_SUCCESS',
  LOAD_USER_RANKING_SEASON_FAILURE = '@userRankingSeason/LOAD_FAILURE',
}

export interface RankingState extends DefaultState {
  readonly data: RankingSeason[];
  readonly activeRanking: RankingSeason | undefined;
  readonly userRankings: UserRankingSeason[];
  readonly userRankingPage: number;
  readonly userRankingTotal: number;
}
