import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  centerContent: {
    alignItems: 'center',
  },
  mTop: {
    marginTop: '30px',
  },
});
