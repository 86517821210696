import React, { useState, ChangeEvent, useCallback, useEffect } from 'react';
import {
  Grid,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableHead,
  TableBody,
  TablePagination,
  Paper,
} from '@material-ui/core';

import { CondOperator } from '@nestjsx/crud-request';
import { FormatOptions } from 'types';
import { User } from 'types/generals';
import { formatColumnValue } from 'helpers/formatters';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { useFilters } from 'hooks/filters';
import UserService from 'services/userService';
import { WalletTransactionsType } from 'types/generals/rankingSeason';
import { useStyles } from '../styles';

interface UserScoreHistoryProps {
  user: User;
  showSearch?: boolean;
  showAdminColumns?: boolean;
}

const UserScoreHistory: React.FC<UserScoreHistoryProps> = ({
  user,
  showAdminColumns,
}) => {
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const { page, handleSetValuePage } = useFilters({});
  const [walletTransactions, setWalletTransactions] = useState();
  const styles = useStyles();

  const walletTransactionRequest = useCallback(async () => {
    const transactions = await UserService.getWalletTransactions({
      page: page + 1,
      limit: rowsPerPage,
      join: [
        {
          field: 'rankingSeason',
          select: ['id', 'name'],
        },
      ],
      filter: [
        {
          field: 'userId',
          operator: CondOperator.EQUALS,
          value: user.id,
        },
      ],
    });
    if (transactions && transactions.data.length) {
      setWalletTransactions(transactions);
    }
  }, [page, rowsPerPage, user]);

  useEffect(() => {
    walletTransactionRequest();
  }, [walletTransactionRequest]);

  return (
    <Grid style={{ marginLeft: '15px !important' }}>
      <Grid container className={styles.mTop}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper className={styles.paperTable} elevation={0}>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    {showAdminColumns ? (
                      <>
                        <TableCell>Data</TableCell>
                        <TableCell>Campanha</TableCell>
                        <TableCell>Observação</TableCell>
                      </>
                    ) : null}
                    <TableCell>Ação</TableCell>
                    <TableCell>Pontuação</TableCell>
                    <TableCell>Data de expiração</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {walletTransactions &&
                    walletTransactions.data.map(
                      (walletTransaction: any, key: any) => (
                        <TableRow key={key}>
                          {showAdminColumns ? (
                            <>
                              <TableCell>
                                {formatColumnValue(
                                  FormatOptions.DATETIME,
                                  walletTransaction.createdAt,
                                )}
                              </TableCell>
                              <TableCell>
                                {walletTransaction.rankingSeason.name}
                              </TableCell>
                              <TableCell>
                                {walletTransaction.observation}
                              </TableCell>
                            </>
                          ) : null}
                          <TableCell>{walletTransaction.type}</TableCell>
                          <TableCell>{walletTransaction.score}</TableCell>
                          <TableCell>
                            {walletTransaction.type ===
                            WalletTransactionsType.used
                              ? '-'
                              : formatColumnValue(
                                  FormatOptions.DATE,
                                  walletTransaction.expiryDate,
                                )}
                          </TableCell>
                        </TableRow>
                      ),
                    )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container item xs justify="flex-end">
              <TablePagination
                ActionsComponent={MyTablePaginationActions}
                component="div"
                count={walletTransactions?.total || 0}
                page={page}
                labelRowsPerPage="Itens por página"
                onChangePage={(_event: unknown, newPage: number) =>
                  handleSetValuePage(newPage)
                }
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
                  handleSetValuePage(0);
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserScoreHistory;
