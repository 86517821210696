import { createStyles, makeStyles, Theme } from '@material-ui/core';

import Colors from 'styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    header: {
      marginBottom: 60,
    },
    main: {
      fontWeight: 'bold',
      color: Colors.WHITE,
      fontSize: 40, // Move it from here
      marginLeft: -1,
    },
    secondary: {
      color: Colors.WHITE,
      fontSize: 28, // Move it from here
      fontWeight: 300,
    },
    descriptionText: {
      color: Colors.WHITE,
      fontSize: 18, // Move it from here
      fontWeight: 300,
      marginTop: 6,
    },
    focused: {
      borderLeftColor: `${Colors.RED} !important`,
    },
    inputRoot: {
      color: Colors.DARKER_GRAY,
      borderColor: Colors.GRAY,
      borderLeft: 7,
      borderLeftColor: 'transparent',
      borderLeftStyle: 'solid',
      fontSize: '2rem',
    },
    root: {
      marginBottom: '2rem',
      '& .MuiFormHelperText-root': {
        color: Colors.RED,
        fontSize: 14,
        margin: '6px 0 0 12px',
      },
    },
    textRoot: {
      color: Colors.WHITE,
      fontSize: '1.6rem',
      borderBottom: `1px solid ${Colors.WHITE}`,
      paddingBottom: 2,
    },
    textLink: {
      textDecoration: 'none',
    },
    buttonRoot: {
      width: '290px',
      height: '65px',
      borderRadius: '8px',
      fontSize: '2rem',
      padding: '0.5em 3.5em',
      marginTop: '1.5rem',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
    },
    progress: {
      color: Colors.WHITE,
    },
    feedbackButton: {
      fontSize: 16,
    },
  }),
);
