import { makeStyles, styled, Typography } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const StyledTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
}));

export const useStyles = makeStyles({
  gridHeader: {
    padding: '0px 30px 0 35px',
  },
  bottomGridHeader: {
    padding: '0px 10px 0 20px',
  },
  bottomCardMedia: {
    maxWidth: 150,
    height: 60,
    borderRadius: 10,
  },
  gridBottomContent: {
    marginTop: '10px',
    width: '100%',
  },
  gridBottomContentList: {
    margin: '10px',
    width: '100%',
  },
  rootPaper: {
    padding: theme.spacing(4, 3),
    borderRadius: '2em',
  },
  draggableItem: {
    backgroundColor: Colors.LIGHTER_GRAY,
    color: Colors.DARKER_GRAY,
    marginBottom: '12px',
    cursor: 'pointer !important',
    padding: '8px 16px',
    borderRadius: '8px',
    minHeight: '48px',
  },
  marginTop: {
    marginTop: 20,
  },
  buttonWrapper: {
    padding: '0.5em 3em',
  },
  buttonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
  },
  buttonText: {
    color: Colors.RED,
    fontWeight: 'bold',
    whiteSpace: 'nowrap',
  },
  bannerTitle: {
    marginTop: 40,
    marginBottom: 10,
    fontWeight: 'bold',
  },
  footerGridContainer: {
    marginTop: 20,
    textAlign: 'right',
  },
});
