import { Order } from 'types/generals';

/**
 * Possible status
 */
export enum PLP_STATUS {
  ACTIVE = 'Ativo',
  INACTIVE = 'Inativo',
}

/**
 * Action types
 */
export enum PlpTypes {
  ALL_PLP_REQUEST = '@plp/LOAD_REQUEST',
  GET_PLP_REQUEST = '@plp/GET_PLP_REQUEST',
  LOAD_ALL_SUCCESS = '@plp/LOAD_ALL_SUCCESS',
  GET_PLP_SUCCESS = '@plp/GET_PLP_SUCCESS',
  LOAD_FAILURE = '@plp/LOAD_FAILURE',
  UPDATE = '@plp/UPDATE',
}

/**
 * Data types
 */
export interface Plp {
  id: number;
  name: string;
  code: string;
  createdAt: string;
  updatedAt: string;
  status: PLP_STATUS;
  orders: Order[];
}

export interface PlpData {
  data: Plp[];
  total: number;
  count: number;
  page: number;
  pageCount: number;
}

/**
 * State types
 */
export interface PlpState {
  readonly total: number;
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly data: Plp[];
}

export interface PlpOneState extends Omit<PlpState, 'data' | 'total'> {
  readonly data: Plp;
}
