import React, { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Grid } from '@material-ui/core';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import ClientForm from './ClientForm';
import ClientOrders from './ClientOrders';
import { loadOneSignature } from 'store/ducks/generals/signature/oneSignature/actions';
import { ParamsProps } from '../types';
import StatusHistorySignature from './StatusHistory';
import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { getOneSignatureQuery } from 'utils/queries/signature';
import CircularLoading from 'components/generals/loading/CircularLoading';
import Observations from './ClientForm/Observations';

const SubscriberDetail: FC = () => {
  const subscriberId = useParams<ParamsProps>().id;
  const dispatch = useDispatch();

  const { data: subscriber, isLoading } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Assinantes > Detalhe do assinante'));
  }, [dispatch]);

  const loadSubsciber = React.useCallback(() => {
    dispatch(loadOneSignature(subscriberId, getOneSignatureQuery()));
  }, [dispatch, subscriberId]);

  useEffect(() => {
    loadSubsciber();
  }, [loadSubsciber]);

  return (
    <Grid>
      {isLoading && (
        <CircularLoading
          isLoading={isLoading}
          title={'Carregando assinatura'}
        />
      )}
      {subscriber?.id && (
        <>
          <ClientForm />
          <ClientOrders subscriber={subscriber} />
          <StatusHistorySignature />
          <Observations />
        </>
      )}
    </Grid>
  );
};

export default SubscriberDetail;
