import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';

import {
  ViewBulkActionReactivationTypes,
  ViewUserReactivationPayload,
} from './types';

export const loadViewUsersReactivationRequest = (query?: CreateQueryParams) =>
  action(ViewBulkActionReactivationTypes.LOAD_VIEW_USERS_REACTIVATION_REQUEST, {
    query,
  });

export const loadViewUsersReactivationFailure = () =>
  action(ViewBulkActionReactivationTypes.LOAD_VIEW_USERS_REACTIVATION_FAILURE);

export const loadViewUsersReactivationSuccess = (
  payload: ViewUserReactivationPayload,
) =>
  action(
    ViewBulkActionReactivationTypes.LOAD_VIEW_USERS_REACTIVATION_SUCCESS,
    payload,
  );
