import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { StoreTypes } from '../types';
import { StoreProduct } from 'types/generals';

export const loadOneStoreProductRequest = (
  productId: string,
  query?: CreateQueryParams,
) => {
  return action(StoreTypes.LOAD_ONE_STORE_PRODUCT_REQUEST, {
    productId,
    query,
  });
};

export const loadOneStoreProductSuccess = (payload: StoreProduct) => {
  return action(StoreTypes.LOAD_ONE_STORE_PRODUCT_SUCCESS, payload);
};

export const loadOneStoreProductFailed = () => {
  return action(StoreTypes.LOAD_ONE_STORE_PRODUCT_FAILED);
};

export const resetStateOneStoreProductAfterFailed = () => {
  return action(StoreTypes.RESET_STATE_ONE_STORE_PRODUCT_AFTER_FAILED);
};
