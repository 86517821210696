import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  cardTitle: {
    color: Colors.DARKER_GRAY,
    fontSize: '2em',
    fontWeight: 'bold',
    marginBlockStart: '0.67em',
    marginBlockEnd: '0.67em',
  },
  disabledText: {
    textAlign: 'center',
    fontWeight: 600,
    color: Colors.MEDIUM_GRAY,
  },
  paperTable: {
    padding: theme.spacing(3),
    borderRadius: '2em',
  },
});
