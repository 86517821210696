import React from 'react';
import {
  createStyles,
  makeStyles,
  Theme,
  InputAdornment,
  Input,
  InputProps,
} from '@material-ui/core';
import { Colors } from 'styles/colors';
import { ReactComponent as IconSearch } from 'assets/img/svg/icon_search.svg';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    input: {
      fontSize: '1.6rem',
      fontWeight: 600,
      backgroundColor: Colors.LIGHT_GRAY,
      borderRadius: 13,
      padding: theme.spacing(1, 2, 1, 2),
    },
    filter: {
      fontSize: '1.6rem',
      fontWeight: 600,
      backgroundColor: Colors.WHITE,
      borderRadius: 13,
      padding: theme.spacing(1, 2, 1, 2),
    },
  }),
);

interface MyInputFilterProps {
  /**
   * Render component without search icon
   */
  noIcon?: boolean;
  /**
   * Render component as text filter (white background)
   */
  asFilter?: boolean;
  /**
   * Render component without placeholder
   */
  noPlaceholder?: boolean;
}

const MyInputFilter: React.FC<MyInputFilterProps & InputProps> = ({
  noIcon,
  asFilter,
  noPlaceholder,
  ...rest
}) => {
  const classes = useStyles();

  const drawIcon = () => {
    return noIcon ? null : (
      <InputAdornment position="start">
        <IconSearch />
      </InputAdornment>
    );
  };

  return (
    <Input
      disableUnderline
      fullWidth
      placeholder={noPlaceholder ? undefined : 'Buscar'}
      className={asFilter ? classes.filter : classes.input}
      startAdornment={drawIcon()}
      {...rest}
    />
  );
};

export default MyInputFilter;
