import * as Yup from 'yup';
import OpenEndpointsService from 'services/openEndpointsService';
import { DonationForm } from 'types/generals';

// fixme: mover validações quando arquivo centralizado entrar
export const validationSchema = Yup.object({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string()
    .trim()
    .lowercase()
    .email('E-mail inválido')
    .required('E-mail obrigatório')
    .test('email-validate', 'E-mail já cadastrado', function (value) {
      return (
        value &&
        new Promise<boolean>(async (resolve, reject) => {
          try {
            await OpenEndpointsService.validateEmail(value);
            resolve(true);
          } catch (error) {
            reject(this.createError());
          }
        })
      );
    }),
  repeatEmail: Yup.string()
    .trim()
    .lowercase()
    .required('Confirme o email')
    .test('email-match', 'Os emails devem ser iguais', function (value) {
      return this.parent.email === value;
    }),
  identification: Yup.string()
    .min(11, 'CPF inválido')
    .required('CPF obrigatório'),
  phone: Yup.string().required('Telefone obrigatório'),
  password: Yup.string().required('Senha obrigatória'),
  repeatPassword: Yup.string()
    .required('Confirme a senha')
    .test('password-match', 'As senhas devem ser iguais', function (value) {
      return this.parent.password === value;
    }),
  zipcode: Yup.string().required('CEP obrigatório'),
  street: Yup.string().required('Rua obrigatória'),
  number: Yup.string().required('Número obrigatório'),
  neighborhood: Yup.string().required('Bairro obrigatório'),
  city: Yup.string().required('Cidade obrigatória'),
  state: Yup.string()
    .max(2, 'Só é permitido 2 dígitos, ex: RS')
    .required('Estado obrigatório'),
  complement: Yup.string().max(30, 'Limite de caracteres é de 30'),
  hashPlan: Yup.string().required('Plano obrigatorio'),
});

export const getDefaultValues = (donation?: DonationForm) => {
  return {
    name: donation?.name || '',
    email: donation?.email || '',
    repeatEmail: donation?.repeatEmail || '',
    password: donation?.password || '',
    repeatPassword: donation?.repeatPassword || '',
    phone: donation?.phone || '',
    identification: donation?.identification || '',
    number: donation?.number || '',
    zipcode: donation?.zipcode || '',
    neighborhood: donation?.neighborhood || '',
    complement: donation?.complement || '',
    city: donation?.city || '',
    street: donation?.street || '',
    state: donation?.state || '',
    hashPlan: donation?.hashPlan || '',
  };
};
