import React, { FC, useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import { head, orderBy } from 'lodash';
import { Grid } from '@material-ui/core';

import UserService from 'services/userService';
import {
  notifyError,
  notifySuccess,
  disableButton,
  enableButton,
} from 'store/ducks/notification/actions';
import {
  SIGNATURE_REACTIVATE_CONFIRM,
  SIGNATURE_CANCELED_CONFIRM,
  FORCE_NEXT_SIGNATURE_SUCCESS,
  NEXT_SIGNATURE_NOT_EXISTS,
  FORCE_NEXT_SIGNATURE_CONFIRM,
  NEXT_SIGNATURE_FORCED,
  RENEW_ANTICIPATE,
  RENEW_ANTICIPATE_CONFIRM,
  SET_SIGNATURE_DEFAULT,
  SET_SIGNATURE_DEFAULT_SUCCESS,
  ORDER_DETAIL_ADDRESS_CHANGED,
  ORDER_DETAIL_ADDRESS_CHANGE_ERROR,
  FORCE_CANCEL_NEXT_SIGNATURE_CONFIRM,
  FORCE_CANCEL_NEXT_SIGNATURE_SUCCESS,
  RENEW_DONATION_SUCCESS,
  RENEW_DONATION_ERROR,
  DONATION_RENEW_CONFIRM,
} from 'utils/messages';
import { validationSchema, defaultValue, fixedTransports } from '../utils';
import {
  reactivateSignature,
  cancelSignature,
  loadOneSignature,
} from 'store/ducks/generals/signature/oneSignature/actions';
import { loadRecurrences } from 'store/ducks/recurrences/actions';
import { openModal, closeModal } from 'store/ducks/nav/actions';
import { SIGNATURE_STATUS, PAYMENT_TYPE } from 'types/generals';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import {
  SignatureTypeDialogProps,
  BaseDialogProps,
} from 'components/generals/dialog/dialogTypes';
import PaymentTypeDialog from 'components/generals/dialog/PaymentTypeDialog';
import SignatureService from 'services/signatureService';
import { ItemsListDialogProps } from 'components/generals/dialog/ItemsListDialog/types';
import ItemsListDialog from 'components/generals/dialog/ItemsListDialog';
import { ItemLabelField } from 'components/generals/labels/ItemLabel/types';
import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import SignatureRenewDateDialog from 'components/generals/dialog/SignatureRenewDateDialog';
import ChangePlanDialog from 'components/generals/dialog/ChangePlanDialog';
import {
  FIXED_TRANSPORT_SUCCESS,
  FIXED_TRANSPORT_ERROR,
} from 'utils/messages/user';
import { getOneSignatureQuery } from 'utils/queries/signature';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { sleep } from 'utils/functions/generals';

interface ClientFormActionsProps {
  isLoadingRequest: boolean;
  setIsLoadingRequest: any;
}

const ClientFormActions: FC<ClientFormActionsProps> = ({
  isLoadingRequest,
  setIsLoadingRequest,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const transportsMethodsOptions = fixedTransports();
  const redirectTo = (url: string) => history.push(url);

  const { data: signature, isLoading } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);
  const { getValues } = useForm({
    validationSchema,
    defaultValues: defaultValue({
      ...signature?.user,
      defaultAddress: signature?.user?.address?.[0],
    }),
  });

  const handleActionSignature = (action: any) => {
    try {
      dispatch(action);
      dispatch(closeModal());
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleConfirmActionDialog = (
    title: string,
    message: string,
    action: any,
  ) => {
    dispatch(
      openModal(ConfirmationDialog, {
        title,
        message,
        actionFn: action,
        id: signature.id,
      }),
    );
  };

  const handleForceStartNextSignature = async (id: number) => {
    try {
      if (!signature.nextSignaturePlan) {
        throw new Error(NEXT_SIGNATURE_NOT_EXISTS);
      }

      dispatch(disableButton());
      const response = await SignatureService.forceStartNextSignature(id);
      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(FORCE_NEXT_SIGNATURE_SUCCESS));
        dispatch(closeModal());
        redirectTo('/admin/subscriptions');
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleForceCancelStartNextSignature = async (id: number) => {
    try {
      if (!signature.nextSignaturePlan) {
        throw new Error(NEXT_SIGNATURE_NOT_EXISTS);
      }

      dispatch(disableButton());
      const response = await SignatureService.requestCancelChangePlan(id);
      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(FORCE_CANCEL_NEXT_SIGNATURE_SUCCESS));
        dispatch(closeModal());
        history.go(0);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleSetDefaultSignature = async (
    signatureId: number,
    userId: number,
  ) => {
    try {
      dispatch(disableButton());
      const response = await SignatureService.setSignatureDefault(
        signatureId,
        userId,
      );
      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(SET_SIGNATURE_DEFAULT_SUCCESS));
        dispatch(closeModal());
        redirectTo('/admin/subscriptions');
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleRenewSignatureAnticipate = async (subscriberId: number) => {
    try {
      dispatch(disableButton());
      const response = await SignatureService.renewSignatureAnticipate(
        subscriberId,
      );
      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(RENEW_ANTICIPATE(subscriberId)));
        dispatch(closeModal());
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleUpdateAddressOrdersFuture = async (signatureId: number) => {
    try {
      dispatch(disableButton());
      const response = await SignatureService.changeSignatureOrderAddress(
        signatureId,
      );

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ORDER_DETAIL_ADDRESS_CHANGED));
        dispatch(closeModal());
        history.go(0);
      } else {
        throw new Error(ORDER_DETAIL_ADDRESS_CHANGE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
      dispatch(closeModal());
    } finally {
      dispatch(enableButton());
    }
  };

  const chosenFixedTransport: ItemLabelField | undefined = useMemo(() => {
    return isLoading
      ? head(transportsMethodsOptions)
      : transportsMethodsOptions.find(
          ({ name }) => name === signature?.user?.fixedTransportMethod,
        ) || head(transportsMethodsOptions);
  }, [transportsMethodsOptions, signature, isLoading]);

  const handleUpdateFixedTransport = async (itemSelected: number) => {
    try {
      const transport = transportsMethodsOptions.find(
        ({ id }) => id === itemSelected,
      )?.name;

      const _transport = itemSelected > 0 ? transport : null;
      dispatch(disableButton());
      const response = await UserService.setUserFixedTransport(
        signature.user.id,
        _transport,
      );

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(FIXED_TRANSPORT_SUCCESS));

        const modalProps: BaseDialogProps = {
          title: 'Pedidos futuros',
          message: 'Deseja alterar o método de envio nos pedidos futuros?',
          actionFn: () => handleUpdateAddressOrdersFuture(signature.id),
        };
        dispatch(openModal(ConfirmationDialog, modalProps));
      } else {
        throw new Error(FIXED_TRANSPORT_ERROR);
      }
      dispatch(
        loadOneSignature(signature.id.toString(), getOneSignatureQuery()),
      );
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleOpenPaymentTypeDialog = () => {
    const dialogProps: SignatureTypeDialogProps = {
      modalTitle: 'Alterar pagamento',
      submitText: 'Confirmar',
      subscriber: signature,
    };

    dispatch(openModal(PaymentTypeDialog, dialogProps));
  };

  const handleFixedTransportItemsDialog = () => {
    const dialogProps: ItemsListDialogProps = {
      title: 'Método de Envio fixo',
      message: 'Escolha o método de transporte fixo para o assinante',
      items: transportsMethodsOptions,
      selected: chosenFixedTransport?.id,
      confirmationFn: (itemSelected: number) =>
        handleUpdateFixedTransport(itemSelected),
    };

    dispatch(openModal(ItemsListDialog, dialogProps));
  };

  const handleUpdateRenewDateSignatureDialog = () => {
    const dialogProps: SignatureTypeDialogProps = {
      modalTitle: 'Alterar data de renovação',
      submitText: 'Confirmar',
      subscriber: signature,
    };

    dispatch(openModal(SignatureRenewDateDialog, dialogProps));
  };

  const handleOpenChangePlanDialog = () => {
    const modalProps: SignatureTypeDialogProps = {
      modalTitle: 'Alterar plano do assinante',
      submitText: 'Confirmar',
      subscriber: signature,
    };
    dispatch(loadRecurrences());
    dispatch(openModal(ChangePlanDialog, modalProps));
  };

  const handleOpenDialogRenewDonation = () => {
    handleConfirmActionDialog('Renovação', DONATION_RENEW_CONFIRM, () =>
      handleRenewDonation(),
    );
  };

  const handleRenewDonation = async () => {
    try {
      setIsLoadingRequest(true);
      const response = await SignatureService.changePlan(
        signature.id,
        signature.plan.id,
        signature.paymentType,
        true,
      );
      if (response.status === StatusCodes.CREATED) {
        await sleep(3000);
        dispatch(notifySuccess(RENEW_DONATION_SUCCESS));
        const response = await SignatureService.handleGetNextSignatureByCurrentSignatureId(
          signature.id,
        );
        if (response.data) {
          history.push(`/admin/subscriptions/${response.data.id}`);
        } else {
          history.go(0);
        }
      } else {
        throw new Error(RENEW_DONATION_ERROR);
      }
      dispatch(closeModal());
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      setIsLoadingRequest(false);
    }
  };

  const Actions = () => (
    <ActionsButton
      buttonText="Ações"
      menuItems={[
        {
          title: 'Antecipar renovação',
          action: () =>
            handleConfirmActionDialog(
              'Antecipar renovação da assinatura',
              RENEW_ANTICIPATE_CONFIRM(getValues('name')),
              () => handleRenewSignatureAnticipate(Number(signature.id)),
            ),
        },
        {
          title: 'Reativar',
          disabled:
            signature.status === SIGNATURE_STATUS.ACTIVE ||
            signature.status === SIGNATURE_STATUS.INACTIVE,
          action: () =>
            handleConfirmActionDialog(
              'Reativar Assinatura',
              SIGNATURE_REACTIVATE_CONFIRM,
              () =>
                handleActionSignature(
                  reactivateSignature(Number(signature.id)),
                ),
            ),
        },
        {
          title: 'Cancelar',
          disabled: signature.status === SIGNATURE_STATUS.CANCELED,
          action: () =>
            handleConfirmActionDialog(
              'Cancelar Assinatura',
              SIGNATURE_CANCELED_CONFIRM,
              () =>
                handleActionSignature(cancelSignature(Number(signature.id))),
            ),
        },
        {
          title: 'Forçar prox. Assinatura',
          disabled: signature.nextSignaturePlan ? false : true,
          action: () =>
            handleConfirmActionDialog(
              'Forçar prox. Assinatura',
              FORCE_NEXT_SIGNATURE_CONFIRM,
              () => handleForceStartNextSignature(signature.id),
            ),
        },
        {
          title: 'Cancelar prox. Assinatura',
          disabled: signature.nextSignaturePlan ? false : true,
          action: () =>
            handleConfirmActionDialog(
              'Cancelar prox. Assinatura',
              FORCE_CANCEL_NEXT_SIGNATURE_CONFIRM,
              () => handleForceCancelStartNextSignature(signature.id),
            ),
        },
        {
          title: 'Definir como principal',
          disabled: false,
          action: () =>
            handleConfirmActionDialog(
              'Definir como assinatura principal',
              SET_SIGNATURE_DEFAULT,
              () => handleSetDefaultSignature(signature.id, signature.user.id),
            ),
        },
        {
          title: 'Editar usuário',
          disabled: false,
          action: () =>
            history.push(`/admin/subscriber/${signature.id}`, {
              subscriber: signature,
            }),
        },
        {
          title: 'Alterar pagamento',
          disabled: signature?.plan?.paymentType !== PAYMENT_TYPE.BANKSLIP_CARD,
          action: () => handleOpenPaymentTypeDialog(),
        },
        {
          title: 'Fixar método de envio',
          action: () => handleFixedTransportItemsDialog(),
        },
        {
          title: 'Data de renovação',
          action: () => handleUpdateRenewDateSignatureDialog(),
        },
        { title: 'Alterar plano', action: () => handleOpenChangePlanDialog() },
      ]}
    />
  );

  const ActionsDonation = () => (
    <ActionsButton
      buttonText="Ações"
      menuItems={[
        {
          title: 'Renovar',
          disabled: signature.status === SIGNATURE_STATUS.INACTIVE,
          action: handleOpenDialogRenewDonation,
        },
        {
          title: 'Reativar',
          disabled:
            signature.status === SIGNATURE_STATUS.ACTIVE ||
            signature.status === SIGNATURE_STATUS.INACTIVE,
          action: () =>
            handleConfirmActionDialog(
              'Reativar Assinatura',
              SIGNATURE_REACTIVATE_CONFIRM,
              () =>
                handleActionSignature(
                  reactivateSignature(Number(signature.id)),
                ),
            ),
        },
        {
          title: 'Cancelar',
          disabled: signature.status === SIGNATURE_STATUS.CANCELED,
          action: () =>
            handleConfirmActionDialog(
              'Cancelar Assinatura',
              SIGNATURE_CANCELED_CONFIRM,
              () =>
                handleActionSignature(cancelSignature(Number(signature.id))),
            ),
        },
        {
          title: 'Forçar prox. Assinatura',
          disabled: signature.nextSignaturePlan ? false : true,
          action: () =>
            handleConfirmActionDialog(
              'Forçar prox. Assinatura',
              FORCE_NEXT_SIGNATURE_CONFIRM,
              () => handleForceStartNextSignature(signature.id),
            ),
        },
        {
          title: 'Definir como principal',
          disabled: false,
          action: () =>
            handleConfirmActionDialog(
              'Definir como assinatura principal',
              SET_SIGNATURE_DEFAULT,
              () => handleSetDefaultSignature(signature.id, signature.user.id),
            ),
        },
        {
          title: 'Editar usuário',
          disabled: false,
          action: () =>
            history.push(`/admin/subscriber/${signature.id}`, {
              subscriber: signature,
            }),
        },
        {
          title: 'Fixar método de envio',
          action: () => handleFixedTransportItemsDialog(),
        },
        {
          title: 'Data de renovação',
          action: () => handleUpdateRenewDateSignatureDialog(),
        },
        {
          title: 'Alterar plano',
          action: () => handleOpenChangePlanDialog(),
        },
      ]}
    />
  );

  return (
    <Grid>{signature.isDonation ? <ActionsDonation /> : <Actions />}</Grid>
  );
};

export default ClientFormActions;
