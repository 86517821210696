import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { AddressPayload, AddressTypes } from './types';

export const loadAllAddresses = (query?: CreateQueryParams) => {
  return action(AddressTypes.LOAD_ALL_ADDRESSES, { query });
};

export const loadAllAddressesSuccess = (payload: AddressPayload) => {
  return action(AddressTypes.LOAD_ADDRESSES_SUCCESS, payload);
};

export const loadAllAddressesFail = () => {
  return action(AddressTypes.LOAD_ADDRESSES_FAILURE);
};

export const loadDefaultAddress = (userId: number) => {
  return action(AddressTypes.LOAD_DEFAULT_ADDRESS, userId);
};

export const loadDefaultAddressSuccess = (payload: AddressPayload) => {
  return action(AddressTypes.LOAD_DEFAULT_ADDRESS_SUCCESS, payload);
};

export const loadDefaultAddressFail = () => {
  return action(AddressTypes.LOAD_DEFAULT_ADDRESS_FAILURE);
};
