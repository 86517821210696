export const RECURRENCES_PLAN = [
  {
    name: 'Mensal',
    value: 1,
  },
  {
    name: 'Trimestral',
    value: 3,
  },
  {
    name: 'Semestral',
    value: 6,
  },
  {
    name: 'Anual',
    value: 12,
  },
];
