import {
  styled,
  makeStyles,
  createStyles,
  Theme,
  Grid,
} from '@material-ui/core';

import { Colors } from 'styles/colors';

import { ReactComponent as CloseButtonSVG } from 'assets/img/svg/menu-icons/menu_close.svg';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    menu: {
      height: '100vh',
      fontSize: '16px',
    },
    nested: {
      paddingLeft: theme.spacing(4),
    },
  }),
);

export const Header = styled(Grid)({
  padding: '20px 32px',
  backgroundColor: Colors.RED,
  color: Colors.WHITE,
});

export const CloseButton = styled(CloseButtonSVG)({
  height: '25px',
  width: '25px',
});
