import React, { FC, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import InputMask from 'react-input-mask';
import { Controller, useForm } from 'react-hook-form';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import useFormStyles from 'components/generals/forms/register/styles';
import { profileStyles } from '../styles';
import MASK, { UNMASK } from 'helpers/masks';
import { InputRef } from 'types';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { ProfilePageProps } from '../types';
import UserService from 'services/userService';
import { StatusCodes } from 'http-status-codes';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { loadUserRequest } from 'store/ducks/user/actions';
import { CHANGE_PROFILE_ERROR, CHANGE_PROFILE_SUCCESS } from 'utils/messages';
import UserImageZone from '../UserImagesZone';
import { ImageZone } from 'components/admin/ImagesZone/types';
import { UserMetadata } from 'types/generals';

import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import NotificationService from 'services/notificationService';
import { USER_UPDATED_NOTIFICATIONS_ERROR } from 'utils/messages/user';

const UserProfilePage: FC<ProfilePageProps> = ({
  userInfo,
  notificationConfigId,
  errors,
  register,
  control,
  setValue,
  handleSubmit,
}) => {
  const dispatch = useDispatch();
  const style = profileStyles();
  const formStyles = useFormStyles();
  const [photo, setPhoto] = useState<ImageZone[]>([]);
  const [photoData, setPhotoData] = useState<UserMetadata>({
    imageLink: userInfo?.metadata?.imageLink || '',
  });

  const createdAt = new Date(userInfo.createdAt);
  const createdAtMonth = format(createdAt, 'LLLL', {
    locale: ptBR,
  });
  const title = `Membro desde ${createdAtMonth}/${createdAt.getFullYear()}`;

  const emailInput = useRef() as InputRef;
  const phoneInput = useRef() as InputRef;
  const identificationInput = useRef() as InputRef;

  const handlePhoneChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    return unmasked;
  };

  const handleUpdatePhoto = (photo: any) => {
    setPhoto(photo);
  };
  const handleUpdatePhotoData = (data: any) => {
    setPhotoData(data);
  };

  const handleUpdateUserNotificationConfig = async (data: any) => {
    const response: any = await NotificationService.saveUserNotificationConfig(
      data,
      notificationConfigId,
      userInfo,
    );
    if (![StatusCodes.OK, StatusCodes.CREATED].includes(response.status)) {
      dispatch(notifyError(USER_UPDATED_NOTIFICATIONS_ERROR));
    }
  };

  const onSubmit = handleSubmit(async (data: any) => {
    try {
      const { name, email, phone, identification } = data;
      const metadata = { ...userInfo.metadata, ...photoData };
      const response = await UserService.updateProfile(userInfo?.id, {
        name,
        email,
        phone,
        identification,
        photo,
        metadata,
      });

      await handleUpdateUserNotificationConfig(data);

      if (response?.status !== StatusCodes.OK)
        dispatch(notifyError(CHANGE_PROFILE_ERROR));
      else {
        dispatch(notifySuccess(CHANGE_PROFILE_SUCCESS));
        dispatch(loadUserRequest());
      }
    } catch (error) {
      dispatch(notifyError(CHANGE_PROFILE_ERROR));
    }
  });

  return (
    <Paper className={style.rootPaper} elevation={0}>
      <Grid container direction="row" justify="space-between">
        <Grid item xs>
          <Typography
            variant="h1"
            color="textPrimary"
            style={{ fontWeight: 'normal' }}
          >
            Informações do <strong>perfil</strong>
          </Typography>
        </Grid>
        <Grid container item xs justify="flex-end">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${formStyles.buttonLabel} ${formStyles.buttonWrapper}`}
            onClick={() => onSubmit()}
          >
            Salvar
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        direction="row"
        item
        xs={12}
        spacing={1}
        className={style.mTop}
      >
        <Grid container item xs={8} spacing={1}>
          <Grid item xs={12}>
            <MyOutlinedTextField
              fullWidth
              label="Nome completo"
              id="name"
              name="name"
              type="text"
              changeFocusOnEnter={() => emailInput.current.focus()}
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12}>
            <MyOutlinedTextField
              fullWidth
              label="Email"
              id="email"
              name="email"
              inputProps={{ ref: emailInput }}
              changeFocusOnEnter={() => phoneInput.current.focus()}
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={InputMask}
              mask={MASK.CELLPHONE}
              name="phone"
              control={control}
              maskChar={null}
              onChange={handlePhoneChange}
              defaultValue={''}
            >
              {() => (
                <MyOutlinedTextField
                  fullWidth
                  label="Telefone"
                  type="text"
                  name="phone"
                  inputProps={{ ref: phoneInput, inputMode: 'numeric' }}
                  changeFocusOnEnter={() => identificationInput.current.focus()}
                  error={Boolean(errors.phone)}
                  helperText={errors.phone ? errors.phone.message : null}
                  inputRef={register}
                />
              )}
            </Controller>
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              as={InputMask}
              mask={MASK.CPF}
              name="identification"
              control={control}
              maskChar={null}
              defaultValue={''}
            >
              {() => (
                <MyOutlinedTextField
                  fullWidth
                  label="CPF"
                  type="text"
                  inputProps={{
                    ref: identificationInput,
                    inputMode: 'numeric',
                  }}
                  changeFocusOnEnter={() => phoneInput.current.focus()}
                  error={Boolean(errors.identification)}
                  helperText={
                    errors.identification ? errors.identification.message : null
                  }
                />
              )}
            </Controller>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={4} className={style.photo}>
          <UserImageZone
            title={title}
            photo={photo}
            photoData={photoData}
            register={register}
            setValue={setValue}
            errors={errors}
            handleUpdatePhoto={handleUpdatePhoto}
            handleUpdatePhotoData={handleUpdatePhotoData}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UserProfilePage;
