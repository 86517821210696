import React, { FC, memo, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Grid, Paper, Typography } from '@material-ui/core';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import FilesThumbs from './FilesThumbs';
import Colors from 'styles/colors';
import { useStyles } from './styles';
import { useStyles as useRootStyles } from 'pages/admin/register/store/products/styles';
import { CustomFile, ImagesZoneProps, IMAGES_ZONE_ERROR } from './types';
import { notifyError } from 'store/ducks/notification/actions';
import {
  DEFAULT_FILE_ERROR,
  DELETE_PRODUCT_FAIL,
  INVALID_FILE_FORMAT_ERROR,
  TOO_LARGE_FILE_ERROR,
  TOO_MANY_FILES_ERROR,
  TOO_SMALL_FILE_ERROR,
} from 'utils/messages';
import { head } from 'lodash';

const ImagesZone: FC<ImagesZoneProps> = memo(
  ({
    files,
    deleteImageFn,
    errors,
    register,
    setValue,
    maxFiles = 1,
    removeTitle,
    multiple = true,
    showTitle = true,
    handleDropFile,
    handleRemoveFile,
  }) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const rootStyles = useRootStyles();
    const [filesInZone, setFilesInZone] = useState<CustomFile[]>([]);

    const { getInputProps, getRootProps } = useDropzone({
      accept: 'image/jpeg, image/png',
      multiple,
      maxFiles: maxFiles,
      onDropAccepted: droppedContent => {
        const filesOnDrop = droppedContent.map(file =>
          Object.assign(file, {
            image: URL.createObjectURL(file),
            isCover: false,
          }),
        );

        if (!multiple) {
          setFilesInZone([...filesOnDrop]);
        } else {
          setFilesInZone(file => [...file, ...filesOnDrop]);
        }
        if (handleDropFile) {
          handleDropFile(filesOnDrop);
        }
      },
      onDropRejected: (rejections: FileRejection[]) => {
        const errors = head(rejections)?.errors;
        const errorMessage = head(errors)?.code;

        let rejectError = DEFAULT_FILE_ERROR;

        switch (errorMessage) {
          case IMAGES_ZONE_ERROR.TOO_SMALL_FILE:
            rejectError = TOO_SMALL_FILE_ERROR;
            break;
          case IMAGES_ZONE_ERROR.TOO_LARGE_FILE:
            rejectError = TOO_LARGE_FILE_ERROR;
            break;
          case IMAGES_ZONE_ERROR.TOO_MANY_FILES:
            rejectError = TOO_MANY_FILES_ERROR(maxFiles);
            break;
          case IMAGES_ZONE_ERROR.INVALID_FILE_FORMAT:
            rejectError = INVALID_FILE_FORMAT_ERROR;
            break;
        }
        dispatch(notifyError(rejectError));
      },
    });

    const deleteProductImage = (imageId: number) => {
      try {
        if (deleteImageFn) deleteImageFn(imageId);
      } catch (err) {
        dispatch(notifyError(DELETE_PRODUCT_FAIL));
      }
    };

    const handleDeleteFile = (fileToDelete: string) => {
      const fileIndex = filesInZone.findIndex(
        file => file.image === fileToDelete,
      );
      const updatedFiles = [...filesInZone];
      const isNewImage = !Object.keys(updatedFiles[fileIndex]).includes('id');
      if (fileIndex >= 0) {
        if (!isNewImage) {
          const imageId = updatedFiles[fileIndex].id;
          if (imageId && deleteImageFn) {
            deleteProductImage(imageId);
          } else {
            dispatch(notifyError(DELETE_PRODUCT_FAIL));
          }
        }
        updatedFiles.splice(fileIndex, 1);
        setFilesInZone(updatedFiles);
        if (handleRemoveFile) {
          handleRemoveFile(updatedFiles, fileIndex);
        }
      }
    };

    useEffect(() => {
      if (files) setFilesInZone(files);
    }, [files]);

    useEffect(() => {
      if (setValue) {
        const newFiles = filesInZone.filter(file => !file.id);
        setValue('images', newFiles);
      }
    }, [setValue, filesInZone]);

    useEffect(() => {
      if (register) register('images');
    }, [register]);

    return (
      <Grid container justify="space-between" direction="column" spacing={3}>
        {showTitle ? (
          <Grid item xs>
            <Typography
              variant="h1"
              color="textPrimary"
              style={{ fontWeight: 'bold' }}
            >
              Imagem
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs>
          <Grid item xs>
            {errors && Boolean(errors.images) ? (
              <Typography style={{ color: Colors.RED }}>
                {errors.images.message}
              </Typography>
            ) : null}
          </Grid>
          <Paper className={rootStyles.rootPaper} elevation={0}>
            <Grid container justify="space-between" direction="row" spacing={4}>
              <Grid
                {...getRootProps({ className: 'dropzone' })}
                xs={filesInZone.length > 0 ? 6 : 12}
                className={clsx(styles.sectionZone, styles.dropArea)}
              >
                <input {...getInputProps()} />
                <Grid
                  container
                  justify="space-between"
                  direction="column"
                  alignItems="center"
                >
                  <Grid item xs>
                    <ImageOutlinedIcon
                      color="primary"
                      style={{ fontSize: 24 }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h2"
                      color="primary"
                      className={styles.boldNoWrapText}
                    >
                      Adicionar imagem
                    </Typography>
                  </Grid>
                  <Grid item xs style={{ marginTop: '16px' }}>
                    <Typography variant="h3" className={styles.subText}>
                      Formatos .png ou .jpg.
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              {filesInZone.length > 0 ? (
                <Grid item xs={6} style={{ alignSelf: 'flex-start' }}>
                  <Grid
                    container
                    justify="flex-start"
                    direction="row"
                    alignItems="baseline"
                    className={styles.previewArea}
                    spacing={2}
                  >
                    <FilesThumbs
                      files={filesInZone}
                      handleDeleteFile={handleDeleteFile}
                    />
                  </Grid>
                </Grid>
              ) : null}
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  },
);

export default ImagesZone;
