import { ViewUserTurbine } from 'types/generals/view';

/**
 * Action types
 */
export enum ViewBulkActionTurbineTypes {
  LOAD_VIEW_USERS_TURBINE_REQUEST = '@user/LOAD_VIEW_USERS_TURBINE_REQUEST',
  LOAD_VIEW_USERS_TURBINE_SUCCESS = '@user/LOAD_VIEW_USERS_TURBINE_SUCCESS ',
  LOAD_VIEW_USERS_TURBINE_FAILURE = '@user/LOAD_VIEW_USERS_TURBINE_FAILURE',
}

/**
 * State types
 */
export interface ViewUserTurbineState {
  readonly data: ViewUserTurbine[];
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly total: number;
}

export interface ViewUserTurbinePayload {
  data: ViewUserTurbine[];
  total?: number;
}
