import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { isEmpty, orderBy, first } from 'lodash';
import {
  Grid,
  Step,
  StepLabel,
  Stepper,
  Typography,
  Paper,
} from '@material-ui/core';

import StepModal from '../stepModal';
import MyStepConnector from './MyStepperConnector';
import {
  useStyles,
  IconErrorOrder,
  IconWaitingOrder,
  IconDoneOrder,
} from './useStyles';
import MyStepperIcon from './MyStepperIcon';
import { openModal } from 'store/ducks/nav/actions';
import {
  StatusHistory,
  Order,
  ORDER_STATUS,
  ORDER_COLOR_GROUP,
} from 'types/generals';
import { format, parseISO } from 'date-fns';

export interface MyStepperProps {
  steps: Array<string>;
  statusHistory: StatusHistory[];
  order: Order;
}

const MyStepper: FC<MyStepperProps> = ({ order, steps, statusHistory }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const containsDetailedStatus = !isEmpty(order.statusTrackingDetails);
  const INITIAL_STEP = 1;
  let lastStatusStepGroup = 0;
  let lastStatus: StatusHistory = {} as StatusHistory;
  if (statusHistory.length) {
    lastStatus =
      first(orderBy(statusHistory, 'createdAt', 'desc')) ||
      ({} as StatusHistory);
    lastStatusStepGroup = lastStatus?.stepGroup || 0;
  }

  const handleOpenStepModal = () => {
    dispatch(openModal(StepModal, { order }));
  };

  const formatDate = () => {
    if (lastStatus.createdAt) {
      return format(parseISO(lastStatus.createdAt), 'dd/MM/yyyy | HH:mm');
    }
    return undefined;
  };

  const currentStatus = () => {
    switch (lastStatus.colorGroup) {
      case ORDER_COLOR_GROUP.WAITING:
        return (
          <Grid container justify="center">
            <IconWaitingOrder />
            <span className={styles.waitingStatusOrder}>{order.status}</span>
          </Grid>
        );
      case ORDER_COLOR_GROUP.DONE:
        return (
          <Grid container justify="center">
            <IconDoneOrder />
            <span className={styles.doneStatusOrder}>{order.status}</span>
          </Grid>
        );
      case ORDER_COLOR_GROUP.FAILED:
        return (
          <Grid container justify="center">
            <IconErrorOrder />
            <span className={styles.failedStatusOrder}>{order.status}</span>
          </Grid>
        );
      default:
        return (
          <Grid container justify="center">
            <IconDoneOrder />
            <span className={styles.doneStatusOrder}>
              {ORDER_STATUS.SUBMITTED}
            </span>
          </Grid>
        );
    }
  };

  return (
    <Grid className={styles.root}>
      {order.status !== ORDER_STATUS.CANCELED ? (
        <Stepper
          alternativeLabel
          activeStep={
            lastStatusStepGroup
              ? lastStatusStepGroup + INITIAL_STEP
              : INITIAL_STEP
          }
          connector={<MyStepConnector />}
          style={{ placeContent: 'center' }}
        >
          {steps.map(step => (
            <Step key={step}>
              <StepLabel StepIconComponent={MyStepperIcon}>
                <Typography variant="h4" className={styles.label}>
                  {step}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      ) : null}

      <Paper
        variant="outlined"
        className={
          order.status === ORDER_STATUS.CANCELED
            ? styles.paperLastStatus
            : styles.paperLastStatus
        }
      >
        {currentStatus()}
      </Paper>
      <Grid
        container
        justify="space-between"
        className={styles.footerPaperLastStatus}
      >
        <Typography variant="h4" className={styles.lastUpdate}>
          Ultima atualização:{' '}
          <span className={styles.lastUpdateDate}>{formatDate() || null}</span>
        </Typography>
        {containsDetailedStatus ? (
          <Typography
            variant="h4"
            className={styles.actionButton}
            onClick={() => handleOpenStepModal()}
          >
            Rastreio Detalhado
          </Typography>
        ) : null}
      </Grid>
    </Grid>
  );
};

export default MyStepper;
