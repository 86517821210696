import React, {
  FC,
  Fragment,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Controller } from 'react-hook-form';
import {
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
  Grid,
} from '@material-ui/core';
import { first, orderBy } from 'lodash';

import { useStyles } from '../ShopCart/ShopCartDetail/styles';
import { TransportCompanyControlProps } from './types';
import { AppState } from 'store';
import { loadTransportDataFreteRequest } from 'store/ducks/dataFreteTransport/actions';
import { DataFreteTransportState } from 'store/ducks/dataFreteTransport/types';
import { CartState } from 'store/ducks/cart/types';
import {
  handleApplyFreightDataFrete,
  handleAddSelectedTransport,
} from 'store/ducks/cart/actions';
import { FormatOptions } from 'types';
import { formatColumnValue } from 'helpers/formatters';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import {
  DataFreteProductsPayload,
  DefaultValueToDataFrete,
  DataFreteQuoteFreight,
} from 'types/generals/dataFrete';
import { verifyIfApplyFreight } from 'utils/functions/generals';

const TransportCompanyControl: FC<TransportCompanyControlProps> = ({
  controlField,
  control,
  errors,
  setValue,
  zipcode,
  chosenTransport,
  subtotal,
  isPremium,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const [defaultTransport, setDefaultTransport] = useState<
    DataFreteQuoteFreight
  >();

  const { data: transportData, isLoading } = useSelector<
    AppState,
    DataFreteTransportState
  >(({ dataFreteTransport }: AppState) => dataFreteTransport);

  const { data: cartItems, subTotal, discount } = useSelector<
    AppState,
    CartState
  >(({ cartReducer }: AppState) => cartReducer);

  const { activeSettings } = useSelector<AppState, StoreSettingsState>(
    ({ storeSettings }: AppState) => storeSettings,
  );

  const handleFormatMoney = useCallback((price: string) => {
    return formatColumnValue(FormatOptions.MONEY, price);
  }, []);

  const products: DataFreteProductsPayload[] = useMemo(
    () =>
      cartItems.map(product => {
        return {
          descricao: product.description || '',
          sku: product.sku || '',
          peso: Number(product.weight),
          altura: DefaultValueToDataFrete.altura,
          largura: DefaultValueToDataFrete.largura,
          comprimento: DefaultValueToDataFrete.comprimento,
          preco: Number(product.price),
          qtd: product.amount,
          volume: DefaultValueToDataFrete.volume,
        };
      }),
    [cartItems],
  );

  const verifyTransportCoverage = useCallback(() => {
    dispatch(loadTransportDataFreteRequest(zipcode, products));
  }, [dispatch, zipcode, products]);

  const handleVerifyRolesFreight = (
    freight: number,
    isFirstTransport: boolean,
  ) => {
    const priceFreight = verifyIfApplyFreight(
      freight,
      subTotal,
      discount,
      activeSettings.freightConfig,
      isPremium,
      isFirstTransport,
    );

    if (priceFreight === 0) {
      return 'Grátis';
    }
    return handleFormatMoney(freight.toString());
  };

  const renderLabelTransport = (
    transport: DataFreteQuoteFreight,
    isFirstTransport: boolean,
  ) => (
    <Grid container item direction="column">
      <Typography variant="h4">{transport.nome_transportador}</Typography>
      <Typography style={{ fontSize: '13px' }}>
        Taxa de entrega:{' '}
        {handleVerifyRolesFreight(
          transport.valor_frete_exibicao || transport.valor_frete,
          isFirstTransport,
        )}
      </Typography>
      <Typography style={{ fontSize: '13px' }}>
        Prazo: {transport.prazo_exibicao || transport.prazo} dias
      </Typography>
    </Grid>
  );

  const handleSetDefaultTransport = useCallback(() => {
    if (!isLoading && transportData?.data) {
      const firstTransport = first(
        orderBy(transportData.data, 'valor_frete_exibicao', 'asc'),
      );
      if (firstTransport?.cnpj_transportador && setValue) {
        setDefaultTransport(firstTransport);
        setValue('transportCompany', firstTransport.nome_transportador);
        dispatch(
          handleAddSelectedTransport({
            transport: firstTransport.nome_transportador,
          }),
        );
      }
    }
  }, [dispatch, isLoading, transportData, setValue]);

  useEffect(() => {
    verifyTransportCoverage();
  }, [verifyTransportCoverage]);

  useEffect(() => {
    if (chosenTransport) {
      const transport = transportData.data.find(
        (t: any) => t.nome_transportador === chosenTransport,
      );
      dispatch(handleAddSelectedTransport({ transport: chosenTransport }));

      if (transport) {
        dispatch(
          handleApplyFreightDataFrete({
            activeSettings: {
              ...activeSettings,
              isPremium,
            },
            freight: transport.valor_frete_exibicao,
            isFirstTransport:
              transport.cod_tabela === defaultTransport?.cod_tabela,
          }),
        );
      }

      if (!transport) {
        handleSetDefaultTransport();
      }
    }
  }, [
    chosenTransport,
    transportData,
    dispatch,
    subtotal,
    activeSettings,
    isPremium,
    defaultTransport,
    handleSetDefaultTransport,
  ]);

  useEffect(() => {
    if (!isLoading && transportData?.data) {
      handleSetDefaultTransport();
    }
  }, [isLoading, transportData, handleSetDefaultTransport]);

  return (
    <Fragment>
      <Controller
        control={control}
        name={controlField}
        as={({ value, onChange }) => (
          <RadioGroup
            onChange={e => onChange(e.target.value)}
            value={value || ''}
            aria-label="transport-company-options"
          >
            {defaultTransport ? (
              <FormControlLabel
                value={defaultTransport.nome_transportador}
                control={<Radio />}
                label={renderLabelTransport(defaultTransport, true)}
                className={styles.radioWrapper}
                defaultChecked
              />
            ) : null}
            {!isLoading &&
              transportData.data?.map((transport: DataFreteQuoteFreight) => (
                <Fragment key={transport.cod_tabela}>
                  {transport.cod_tabela !== defaultTransport?.cod_tabela ? (
                    <FormControlLabel
                      name={transport.nome_transportador}
                      key={transport.cod_tabela}
                      value={transport.nome_transportador}
                      control={<Radio />}
                      label={renderLabelTransport(transport, false)}
                      className={styles.radioWrapper}
                    />
                  ) : null}
                </Fragment>
              ))}
          </RadioGroup>
        )}
      />
      {errors && errors[controlField] ? (
        <Typography id="transport-required-error" color="error">
          {errors[controlField].message}
        </Typography>
      ) : null}
    </Fragment>
  );
};

export default TransportCompanyControl;
