export const TERMS_CONDITIONS =
  'https://clube.bibliotecacatolica.com.br/termos-e-condicoes';
export const PRIVATE_CONDITIONS =
  'https://clube.bibliotecacatolica.com.br/politica-de-privacidade';
export const EXCHANGE_POLICIES =
  'https://clube.bibliotecacatolica.com.br/politica-de-troca';
export const DEADLINE_POLICIES =
  'https://clube.bibliotecacatolica.com.br/politica-de-prazos';
export const INDICATION_CONDITIONS =
  'https://clube.minhabibliotecacatolica.com.br/indique';
