import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Colors from 'styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    playButton: {
      height: '20px',
      bottom: '100px',
      left: '6px',
      opacity: 0.7,
      '&:hover': {
        opacity: 1,
      },
    },
    gridVideoInfo: {
      padding: '10px',
    },
    videoInfoAuthor: {
      opacity: 0.7,
    },
    videoInfoTitle: {
      fontWeight: 'bold',
    },
  }),
);
