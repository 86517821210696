import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

const useStyles = makeStyles({
  filterOptionsList: {
    backgroundColor: Colors.WHITE,
  },
  filterOptionsButton: {
    width: '100%',
    borderRadius: 13,
  },
  actionButton: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
  },
});

export { useStyles };
