import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  Typography,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Button,
} from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';

import {
  setPlanOnPayload,
  setSignatureOnPayload,
  nextStepPlanPage,
} from 'store/ducks/change-plan/actions';

import { PlanInfoCardProps } from './types';
import {
  RECURRENCES_MONTHS,
  getUnitPrice,
  getRecurrenceText,
} from 'types/generals';
import { moneyFormat } from 'pages/client/signature/utils';
import { PlanPages } from '../../types';
import { useStyles } from './styles';

import { ReactComponent as BenefitsImage } from 'assets/img/svg/benefits.svg';
import { loadBenefits } from 'store/ducks/benefits/action';
import { AppState } from 'store';
import { BenefitsState } from 'store/ducks/benefits/types';
import { Benefits } from 'types/generals/benefits';

const PlanInfoCard: React.FC<PlanInfoCardProps> = ({
  plan,
  smallSize,
  signature,
  page,
}) => {
  const style = useStyles();
  const dispatch = useDispatch();

  // const PLAN_CARD_PRICE = plan.prices.cardPrice;
  // const PLAN_BANKSLIP_PRICE = plan.prices.bankslipPrice;

  const FRETE = 15;
  const PLAN_CARD_PRICE_CUSTOM =
    plan.prices.cardPrice - FRETE * plan.recurrence.months;
  const PLAN_BANKSLIP_PRICE_CUSTOM =
    plan.prices.bankslipPrice - FRETE * plan.recurrence.months;

  const { data: benefits = [], hasError, isLoading } = useSelector<
    AppState,
    BenefitsState
  >(({ benefits }: AppState) => benefits);

  const nextStep = () => {
    dispatch(setPlanOnPayload(plan));
    dispatch(setSignatureOnPayload(signature));
    dispatch(nextStepPlanPage());
  };

  const loadAllBenefits = useCallback(() => {
    dispatch(
      loadBenefits({
        join: ['recurrences'],
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    loadAllBenefits();
  }, [loadAllBenefits]);

  const ShowBenefits = (benefit: Benefits) => {
    return (
      <ListItem alignItems="center" className={style.listItem} key={benefit.id}>
        <ListItemIcon className={style.listItemIcon}>
          {benefit.recurrences.find(
            recurrence => recurrence.id === plan.recurrence.id,
          ) ? (
            <CheckCircle className={style.checkIcon} />
          ) : (
            <Cancel className={style.cancelIcon} />
          )}
        </ListItemIcon>
        <ListItemText>
          <Typography className={style.listTextChecked}>
            {benefit.title}
          </Typography>
        </ListItemText>
      </ListItem>
    );
  };

  return (
    <Grid container item justify="flex-end" alignContent="flex-end">
      <Grid
        container
        item
        xs={12}
        sm={page === PlanPages.REACTIVATE_PAGE ? 7 : undefined}
        className={style.planGrid}
      >
        <Grid
          container
          justify="center"
          direction={smallSize ? 'column' : 'row'}
          className={style.planGridContainer}
        >
          <Grid
            container
            item
            direction="column"
            justify="center"
            spacing={1}
            sm={5}
          >
            {plan.recurrence.months === RECURRENCES_MONTHS.YEARLY ? (
              <Grid item>
                <BenefitsImage className={style.benefitImage} />
              </Grid>
            ) : null}
            <Grid item>
              <Typography variant="h3" className={style.planNameText}>
                Plano {plan.recurrence.name}
              </Typography>
            </Grid>
            <Grid container item direction="row" alignItems="center">
              <Typography className={style.planPriceText} variant="body1">
                {moneyFormat(
                  getUnitPrice(plan.recurrence.months, PLAN_CARD_PRICE_CUSTOM),
                )}
              </Typography>
              <Typography className={style.planRecurrenceText} variant="body1">
                {getRecurrenceText(plan.recurrence.months)}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="h5" className={style.boldText}>
                Frete R$ 15,00
              </Typography>
            </Grid>
          </Grid>
          <Grid container item direction="row" alignItems="center" sm={5}>
            {!isLoading && !hasError ? (
              <>
                <Grid item xs={12}>
                  <Typography variant="h4">
                    <strong>O que você receberá?</strong>
                  </Typography>
                </Grid>

                <List className={style.list}>
                  {benefits.map(benefit => ShowBenefits(benefit)) || null}
                </List>
              </>
            ) : null}
          </Grid>
        </Grid>
      </Grid>
      {!smallSize ? (
        <Grid
          item
          xs={12}
          sm={page === PlanPages.REACTIVATE_PAGE ? 7 : undefined}
        >
          <Typography className={style.infoCard} variant="caption">
            {page === PlanPages.REACTIVATE_PAGE
              ? 'Todos os planos possuem renovação automática.'
              : 'Renovação Automática'}
          </Typography>
        </Grid>
      ) : null}
      <Grid container item justify="flex-end">
        <Button
          onClick={() => nextStep()}
          variant="contained"
          className={style.continueButton}
        >
          <Typography className={style.continueButtonText}>
            Continuar
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default PlanInfoCard;
