import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  arrowIcon: {
    height: '32px',
    width: '32px',
  },
  productTitle: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    [theme.breakpoints.down('xs')]: {
      fontSize: '2.0rem',
    },
  },
  mTop: {
    marginTop: 32,
  },
  headerLink: {
    fontWeight: 500,
    marginLeft: '5px',
    whiteSpace: 'nowrap',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  confirmButton: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 90px',
    },
    fontSize: '1.6em',
    height: '56px',
    fontWeight: 'bold',
    borderRadius: '7px',
    padding: '16px 135px',
    maxWidth: '180px',
    backgroundColor: Colors.LIGHT_GREEN,
    color: Colors.WHITE,
    marginTop: '26px',
    marginBottom: '50px',
    '&:hover': {
      backgroundColor: Colors.DARK_GREEN,
    },
  },
  confirmButtonSmallScreen: {
    fontSize: '1.5em',
    height: '56px',
    borderRadius: '7px',
    padding: '16px 90px',
    backgroundColor: Colors.LIGHT_GREEN,
    color: Colors.WHITE,
    '&:hover': {
      backgroundColor: Colors.DARK_GREEN,
    },
  },
  confirmBtnText: {
    fontWeight: 600,
    whiteSpace: 'nowrap',
  },
  divider: {
    margin: '24px 0',
    width: '100%',
  },
  paddingBtmSpacing: {
    padding: '0px 16px 16px',
  },
  imgCover: {
    width: '100%',
    maxWidth: '100%',
    height: '350px',
    borderRadius: '16px',
    objectFit: 'contain',
  },
  paperTable: {
    padding: theme.spacing(3, 4),
    borderRadius: '10px',
  },
  priceContainer: {
    marginBottom: 15,
  },
  discountOriginal: {
    color: Colors.MEDIUM_GRAY,
    fontWeight: 500,
    textDecoration: 'line-through',
  },
  price: {
    fontWeight: 'bold',
    marginLeft: 2,
  },
  semiBoldText: {
    fontWeight: 600,
  },
  disabledButton: {
    [theme.breakpoints.down('sm')]: {
      padding: '16px 90px',
    },
    fontSize: '1.6em',
    height: '56px',
    fontWeight: 'bold',
    borderRadius: '7px',
    padding: '16px 135px',
    maxWidth: '180px',
    backgroundColor: Colors.LIGHT_GRAY,
    color: Colors.WHITE,
    marginTop: '26px',
    marginBottom: '50px',
  },
  tabs: {
    WebkitJustifyContent: 'space-around',
  },
  tabPanel: {
    fontWeight: 500,
    whiteSpace: 'pre-line',
    fontSize: '14px',
  },
  galleryContainer: {
    padding: '61px',
    borderRight: '1px solid #F1F1F1',
    [theme.breakpoints.down('sm')]: {
      padding: '20px 12px',
      borderRight: 0,
    },
  },
  productDataContainer: {
    padding: 60,
    [theme.breakpoints.down('sm')]: {
      padding: '15',
    },
    alignSelf: 'start',
  },
}));
