import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { TrackingTypes } from './types';

export const loadSedexTracking = (query: CreateQueryParams) => {
  return action(TrackingTypes.LOAD_SEDEX_REQUEST, { query });
};

export const loadSedexSuccess = (payload: any) => {
  return action(TrackingTypes.LOAD_SEDEX_SUCCESS, payload);
};

export const loadPacTracking = (query: CreateQueryParams) => {
  return action(TrackingTypes.LOAD_PAC_REQUEST, { query });
};

export const loadPacSuccess = (payload: any) => {
  return action(TrackingTypes.LOAD_PAC_SUCCESS, payload);
};

export const loadModicoTracking = (query: CreateQueryParams) => {
  return action(TrackingTypes.LOAD_MODICO_REQUEST, { query });
};

export const loadModicoSuccess = (payload: any) => {
  return action(TrackingTypes.LOAD_MODICO_SUCCESS, payload);
};

export const loadTrackingFail = () => {
  return action(TrackingTypes.LOAD_FAILURE);
};
