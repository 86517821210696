import { makeStyles, createStyles } from '@material-ui/core/styles';
import Colors from 'styles/colors';

const useStyles = makeStyles(theme =>
  createStyles({
    info_mb: {
      fontWeight: 500,
      color: Colors.DARKER_GRAY,
      marginBottom: '1rem',
    },
    buttonLabel: {
      fontSize: '1.5em',
      height: '3.2em',
      borderRadius: 13,
    },
    buttonSave: {
      fontSize: '1.5em',
      height: '3.2em',
      borderRadius: 13,
      padding: '0.5em 3em',
      backgroundColor: Colors.LIGHT_GREEN,
      color: Colors.WHITE,
      '&:hover': {
        backgroundColor: Colors.DARK_GREEN,
      },
    },
    helperTitle: {
      fontWeight: 'bold',
      marginTop: '10px',
    },
  }),
);

export default useStyles;
