import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  sectionTitle: {
    fontWeight: 'bold',
    marginTop: 10,
    paddingLeft: 6,
  },
  singleRowPadding: {
    padding: '4px 12px 4px 4px !important',
  },
});
