import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles({
  mTop: {
    marginTop: 10,
  },
  title: {
    fontSize: 20,
    fontWeight: 600,
    textAlign: 'center',
  },
  titleMobile: {
    fontSize: 16,
    fontWeight: 600,
    textAlign: 'center',
  },
  textInformation: {
    textAlign: 'center',
    fontSize: 16,
    fontWeight: 600,
    color: Colors.MEDIUM_GRAY,
  },
  textInformationMobile: {
    fontSize: 16,
    fontWeight: 600,
    color: Colors.MEDIUM_GRAY,
  },
  textInfoTitle: {
    fontSize: 16,
    fontWeight: 600,
  },
  textInfoSubTitle: {
    fontSize: 16,
    fontWeight: 600,
    color: Colors.MEDIUM_GRAY,
  },
  waitingIcon: {
    fontSize: 50,
    color: Colors.YELLOW,
  },
  waitingIconMobile: {
    fontSize: 30,
    color: Colors.YELLOW,
  },
  buttonText: {
    color: Colors.ORANGE,
    fontWeight: 600,
    fontSize: 16,
  },
  buttonBankslip: {
    width: 160,
    height: 44,
    backgroundColor: Colors.BRIGHT_DARK_GREEN,
    color: Colors.WHITE,
    '&:hover': {
      backgroundColor: Colors.BRIGHT_DARK_GREEN,
    },
  },
  buttonBankslipText: {
    fontSize: 16,
    fontWeight: 600,
  },
});
