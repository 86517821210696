export const DEFAULT_INITIAL_STATE = {
  data: [] as any,
  isLoading: true,
  hasError: false,
  page: 0,
  total: 0,
};

export interface DefaultState {
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly page: number;
  readonly total: number;
}
