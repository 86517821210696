import { ReactComponent as IconCadastros } from 'assets/img/svg/icon_cadastros.svg';
import { ReactComponent as IconGerenciar } from 'assets/img/svg/icon_gerenciar.svg';
import { ReactComponent as IconPainel } from 'assets/img/svg/icon_painel.svg';
import { ReactComponent as IconRelatorios } from 'assets/img/svg/icon_relatorios.svg';
import Dashboard from 'pages/admin/dashboard';
import Banners from 'pages/admin/manager/banners';
import Donations from 'pages/admin/manager/donations';
import BenefitsPage from 'pages/admin/manager/benefits';
import Orders from 'pages/admin/manager/orders';
import OrderDetail from 'pages/admin/manager/orders/OrderDetail';
import OrderResend from 'pages/admin/manager/orders/OrderResend';
import OrdersStore from 'pages/admin/manager/orders/OrdersStore';
import ShippingList from 'pages/admin/manager/shipping';
import ShippingDetail from 'pages/admin/manager/shipping/details';
import Subscribers from 'pages/admin/manager/subscribers';
import SubscriberDetail from 'pages/admin/manager/subscribers/SubscriberDetail';
import SubscriberProfile from 'pages/admin/manager/subscribers/SubscriberProfile';
import Subscriptions from 'pages/admin/manager/subscriptions';
import TrackingCodes from 'pages/admin/manager/tracking';
import Boxes from 'pages/admin/register/boxes/Boxes';
import BoxesPage from 'pages/admin/register/boxes/BoxPage';
import CouponsList from 'pages/admin/register/coupons/CouponsList';
import Plans from 'pages/admin/register/plans';
import PlansNew from 'pages/admin/register/plans/PlanPage';
import RolesList from 'pages/admin/register/roles';
import RolesNew from 'pages/admin/register/roles/newRole';
import Categories from 'pages/admin/register/store/categories';
import StoreProducts from 'pages/admin/register/store/products';
import NewStoreProduct from 'pages/admin/register/store/products/newProduct';
import UsersList from 'pages/admin/register/users';
import UsersEdit from 'pages/admin/register/users/EditUser';
import Videos from 'pages/admin/register/videos';
import VideoPage from 'pages/admin/register/videos/VideoPage';
import QueueList from 'pages/admin/report/queues/QueueList';
import GenerateBankslip from 'pages/client/generate-bankslip';
import { PermissionsNames, User } from 'types/generals';
import { IDrawer, IRoute } from 'types/generals/routes';
import { UrlActionName } from './types';
import StoreSettingsPage from 'pages/admin/manager/storeSettings';
import PreShipmentOrdersStore from 'pages/admin/manager/orders/PreShippmentOrdersStore';
import PreShipmentOrders from 'pages/admin/manager/orders/PreShipmentOrders';
import RankingSeasons from 'pages/admin/register/rankingSeasons';
import RankingSeasonNew from 'pages/admin/register/rankingSeasons/newRankingSeason';
import RankingPage from 'pages/admin/manager/ranking';
import EnhancedProducts from 'pages/admin/register/enhancedProducts';
import NewEnhancedProducts from 'pages/admin/register/enhancedProducts/newEnhancedProducts';
import Leads from 'pages/admin/manager/leads';
import StoreNotifications from 'pages/admin/manager/notifications';
// import BenefitsPage from 'pages/admin/manager/benefits';
import SubscriptionBulkActions from 'pages/admin/manager/bulk-actions';
import BulkActionReactivation from 'pages/admin/manager/bulk-action-reactivation';
import BulkActionTurbine from 'pages/admin/manager/bulk-action-turbine';
import PreShipmentOrdersResend from 'pages/admin/manager/orders/PreShipmentOrdersResend';

export enum USER_PAGES {
  userRanking = 'user-ranking',
}

export const handleReturnUrl = (urlAction: string) => {
  switch (urlAction) {
    case UrlActionName.REACTIVATE:
      return { url: `/my-account/signature/reativar-assinatura` };
    case UrlActionName.UPGRADE:
      return {
        url: `/my-account/signature/change-plan`,
      };
    case UrlActionName.CHANGE_CARD:
      return { url: `/my-account/cards?action=${urlAction}` };
    case UrlActionName.GO_TO_STORE:
      return { url: `/store` };
    default:
      return { url: '/home' };
  }
};

export const routes: IRoute[] = [
  { exact: true, path: '/admin/panel', component: Dashboard },
  {
    exact: true,
    path: '/admin/benefits',
    component: BenefitsPage,
    permissions: [PermissionsNames.RECORD_BENEFITS],
  },
  {
    path: '/admin/download-bankslip/:hash',
    component: GenerateBankslip,
    permissions: [
      PermissionsNames.MANAGEMENT_SIGNATURE_ORDER,
      PermissionsNames.MANAGEMENT_STORE_ORDER,
    ],
  },
  {
    exact: true,
    path: '/admin/plans',
    component: Plans,
    permissions: [PermissionsNames.RECORD_PLANS],
  },
  {
    exact: false,
    path: '/admin/plans-new',
    component: PlansNew,
    permissions: [PermissionsNames.RECORD_PLANS],
  },
  {
    exact: false,
    path: '/admin/plans/:id',
    component: PlansNew,
    permissions: [PermissionsNames.RECORD_PLANS],
  },
  {
    exact: false,
    path: '/admin/notifications',
    component: StoreNotifications,
    permissions: [PermissionsNames.MANAGEMENT_NOTIFICATIONS],
  },
  {
    exact: true,
    path: '/admin/boxes',
    component: Boxes,
    permissions: [PermissionsNames.RECORD_BOXES],
  },
  /* {
    exact: true,
    path: '/admin/benefits',
    component: BenefitsPage,
    permissions: [PermissionsNames.RECORD_BENEFITS],
  }, */
  {
    exact: false,
    path: '/admin/boxes-new',
    component: BoxesPage,
    permissions: [PermissionsNames.RECORD_BOXES],
  },
  {
    exact: false,
    path: '/admin/boxes/:id',
    component: BoxesPage,
    permissions: [PermissionsNames.RECORD_BOXES],
  },
  {
    exact: true,
    path: '/admin/storeProducts',
    component: StoreProducts,
    permissions: [PermissionsNames.RECORD_STORE_PRODUCTS],
  },
  {
    exact: false,
    path: '/admin/storeProducts/new',
    component: NewStoreProduct,
    permissions: [PermissionsNames.RECORD_STORE_PRODUCTS],
  },
  {
    exact: false,
    path: '/admin/coupons',
    component: CouponsList,
    permissions: [PermissionsNames.RECORD_COUPONS],
  },
  {
    exact: false,
    path: '/admin/categories',
    component: Categories,
    permissions: [PermissionsNames.RECORD_CATEGORIES],
  },
  {
    exact: false,
    path: '/admin/videos/:id',
    component: VideoPage,
    permissions: [PermissionsNames.RECORD_VIDEOS],
  },
  {
    exact: false,
    path: '/admin/videos',
    component: Videos,
    permissions: [PermissionsNames.RECORD_VIDEOS],
  },
  {
    exact: false,
    path: '/admin/users/:id',
    component: UsersEdit,
    permissions: [PermissionsNames.RECORD_USERS],
  },
  {
    exact: false,
    path: '/admin/users',
    component: UsersList,
    permissions: [PermissionsNames.RECORD_USERS],
  },
  {
    exact: false,
    path: '/admin/banners',
    component: Banners,
    permissions: [PermissionsNames.RECORD_BANNERS],
  },
  {
    exact: false,
    path: '/admin/roles/:id',
    component: RolesNew,
    permissions: [PermissionsNames.RECORD_PERMISSIONS],
  },
  {
    exact: false,
    path: '/admin/donations',
    component: Donations,
    permissions: [PermissionsNames.RECORD_DONATIONS],
  },
  {
    exact: true,
    path: '/admin/roles-new',
    component: RolesNew,
    permissions: [PermissionsNames.RECORD_PERMISSIONS],
  },
  {
    exact: false,
    path: '/admin/roles',
    component: RolesList,
    permissions: [PermissionsNames.RECORD_PERMISSIONS],
  },
  {
    exact: true,
    path: '/admin/subscriptions',
    component: Subscribers,
    permissions: [PermissionsNames.MANAGEMENT_SUBSCRIBERS],
  },
  {
    exact: true,
    path: '/admin/subscribers',
    component: Subscriptions,
    permissions: [PermissionsNames.MANAGEMENT_SUBSCRIBERS],
  },
  {
    exact: true,
    path: '/admin/bulk-actions',
    component: SubscriptionBulkActions,
    permissions: [PermissionsNames.MANAGEMENT_BULK_ACTION],
  },
  {
    exact: true,
    path: '/admin/bulk-action-activation',
    component: BulkActionReactivation,
    permissions: [PermissionsNames.MANAGEMENT_BULK_ACTION_REACTIVATION],
  },
  {
    exact: true,
    path: '/admin/bulk-action-turbine',
    component: BulkActionTurbine,
    permissions: [PermissionsNames.MANAGEMENT_BULK_ACTION_TURBINATION],
  },

  {
    exact: false,
    path: '/admin/subscriptions/:id',
    component: SubscriberDetail,
    permissions: [PermissionsNames.MANAGEMENT_SUBSCRIBERS],
  },
  {
    exact: true,
    path: '/admin/subscriber/:id',
    component: SubscriberProfile,
    permissions: [PermissionsNames.MANAGEMENT_SUBSCRIBERS],
  },
  {
    exact: true,
    path: '/admin/orders/',
    component: Orders,
    permissions: [
      PermissionsNames.MANAGEMENT_SIGNATURE_ORDER,
      PermissionsNames.MANAGEMENT_STORE_ORDER,
    ],
  },
  {
    exact: true,
    path: '/admin/orders/:id',
    component: OrderDetail,
    permissions: [
      PermissionsNames.MANAGEMENT_SIGNATURE_ORDER,
      PermissionsNames.MANAGEMENT_STORE_ORDER,
    ],
  },
  {
    exact: false,
    path: '/admin/orders/:id/resend/',
    component: OrderResend,
    permissions: [
      PermissionsNames.MANAGEMENT_SIGNATURE_ORDER,
      PermissionsNames.MANAGEMENT_STORE_ORDER,
    ],
  },
  {
    exact: true,
    path: '/admin/orders-store/',
    component: OrdersStore,
    permissions: [PermissionsNames.MANAGEMENT_STORE_ORDER],
  },
  {
    exact: true,
    path: '/admin/orders-store/:id',
    component: OrderDetail,
    permissions: [PermissionsNames.MANAGEMENT_STORE_ORDER],
  },
  {
    exact: true,
    path: '/admin/pre-shippiment-orders',
    component: PreShipmentOrders,
    permissions: [PermissionsNames.MANAGEMENT_PRE_SHIPPIMENT_ORDERS_SIGNATURE],
  },
  {
    exact: true,
    path: '/admin/pre-shippiment-orders-store',
    component: PreShipmentOrdersStore,
    permissions: [PermissionsNames.MANAGEMENT_PRE_SHIPPIMENT_ORDERS_STORE],
  },
  {
    exact: true,
    path: '/admin/pre-shippiment-orders-resend',
    component: PreShipmentOrdersResend,
    permissions: [PermissionsNames.MANAGEMENT_PRE_SHIPPIMENT_ORDERS_STORE],
  },

  {
    exact: true,
    path: '/admin/shipping',
    component: ShippingList,
    permissions: [PermissionsNames.MANAGEMENT_SHIPPING],
  },
  {
    exact: false,
    path: '/admin/shipping/:id',
    component: ShippingDetail,
    permissions: [PermissionsNames.MANAGEMENT_SHIPPING],
  },
  {
    exact: false,
    path: '/admin/tracking',
    component: TrackingCodes,

    permissions: [PermissionsNames.MANAGEMENT_TRACKING_CODE],
  },
  {
    exact: false,
    path: '/admin/reports/queues',
    component: QueueList,
    permissions: [PermissionsNames.REPORT_PROCESSING],
  },
  {
    exact: false,
    path: '/admin/store-settings',
    component: StoreSettingsPage,
    permissions: [PermissionsNames.RECORD_STORE_SETTINGS],
  },
  {
    exact: false,
    path: '/admin/ranking-seasons/:id',
    component: RankingSeasonNew,
    permissions: [PermissionsNames.RECORD_RANKING_SEASON],
  },
  {
    exact: false,
    path: '/admin/ranking-seasons',
    component: RankingSeasons,
    permissions: [PermissionsNames.RECORD_RANKING_SEASON],
  },
  {
    exact: false,
    path: '/admin/ranking',
    component: RankingPage,
    permissions: [
      PermissionsNames.RECORD_RANKING_SEASON,
      PermissionsNames.MANAGEMENT_RANKING,
    ],
  },
  {
    exact: false,
    path: '/admin/enhanced-products/:id',
    component: NewEnhancedProducts,
    permissions: [PermissionsNames.RECORD_ENHANCED_PRODUCTS],
  },
  {
    exact: false,
    path: '/admin/enhanced-products',
    //fixme
    component: EnhancedProducts,
    permissions: [PermissionsNames.RECORD_ENHANCED_PRODUCTS],
  },
  {
    exact: true,
    path: '/admin/leads',
    component: Leads,
    permissions: [PermissionsNames.MANAGEMENT_LEADS],
  },
];

export const drawers: IDrawer[] = [
  {
    name: 'Painel',
    icon: IconPainel,
    to: '/admin/panel',
    items: [],
  },
  {
    name: 'Cadastros',
    icon: IconCadastros,
    items: [
      {
        name: 'Planos',
        to: '/admin/plans',
        permissions: [PermissionsNames.RECORD_PLANS],
      },
      {
        name: 'Benefícios',
        to: '/admin/benefits',
        permissions: [PermissionsNames.RECORD_BENEFITS],
      },
      {
        name: 'Boxes',
        to: '/admin/boxes',
        permissions: [PermissionsNames.RECORD_BOXES],
      },
      /* {
        name: 'Benefícios',
        to: '/admin/benefits',
        permissions: [PermissionsNames.RECORD_BENEFITS],
      }, */
      {
        name: 'Produtos Loja',
        to: '/admin/storeProducts',
        permissions: [PermissionsNames.RECORD_STORE_PRODUCTS],
      },
      {
        name: 'Cupons',
        to: '/admin/coupons',
        permissions: [PermissionsNames.RECORD_COUPONS],
      },
      {
        name: 'Categorias',
        to: '/admin/categories',
        permissions: [PermissionsNames.RECORD_CATEGORIES],
      },
      {
        name: 'Vídeos',
        to: '/admin/videos',
        permissions: [PermissionsNames.RECORD_VIDEOS],
      },
      {
        name: 'Usuários',
        to: '/admin/users',
        permissions: [PermissionsNames.RECORD_USERS],
      },
      {
        name: 'Doações',
        to: '/admin/donations',
        permissions: [PermissionsNames.RECORD_DONATIONS],
      },
      {
        name: 'Banners',
        to: '/admin/banners',
        permissions: [PermissionsNames.RECORD_BANNERS],
      },

      {
        name: 'Permissões',
        to: '/admin/roles',
        permissions: [PermissionsNames.RECORD_PERMISSIONS],
      },
      {
        name: 'Configurações gerais',
        to: '/admin/store-settings',
        permissions: [PermissionsNames.RECORD_STORE_SETTINGS],
      },
      {
        name: 'Campanhas',
        to: '/admin/ranking-seasons',
        permissions: [PermissionsNames.RECORD_RANKING_SEASON],
      },
      {
        name: 'Produtos turbinados',
        to: '/admin/enhanced-products',
        permissions: [PermissionsNames.RECORD_ENHANCED_PRODUCTS],
      },
    ],
  },
  {
    name: 'Gerenciar',
    icon: IconGerenciar,
    items: [
      {
        name: 'Assinaturas',
        to: '/admin/subscriptions',
        permissions: [PermissionsNames.MANAGEMENT_SIGNATURE],
      },
      {
        name: 'Assinantes',
        to: '/admin/subscribers',
        permissions: [PermissionsNames.MANAGEMENT_SUBSCRIBERS],
      },
      {
        name: 'Leads',
        to: '/admin/leads',
        permissions: [PermissionsNames.MANAGEMENT_LEADS],
      },
      {
        name: 'Pedidos de Assinatura',
        to: '/admin/orders',
        permissions: [PermissionsNames.MANAGEMENT_SIGNATURE_ORDER],
      },
      {
        name: 'Pedidos de Loja',
        to: '/admin/orders-store',
        permissions: [PermissionsNames.MANAGEMENT_STORE_ORDER],
      },
      {
        name: 'Pedidos pré Remessa Assinatura',
        to: '/admin/pre-shippiment-orders',
        permissions: [
          PermissionsNames.MANAGEMENT_PRE_SHIPPIMENT_ORDERS_SIGNATURE,
        ],
      },
      {
        name: 'Pedidos pré Remessa Loja',
        to: '/admin/pre-shippiment-orders-store',
        permissions: [PermissionsNames.MANAGEMENT_PRE_SHIPPIMENT_ORDERS_STORE],
      },
      {
        name: 'Pedidos pré Remessa Reenvio',
        to: '/admin/pre-shippiment-orders-resend',
        permissions: [PermissionsNames.MANAGEMENT_PRE_SHIPPIMENT_ORDERS_STORE],
      },
      {
        name: 'Remessas',
        to: '/admin/shipping',
        permissions: [PermissionsNames.MANAGEMENT_SHIPPING],
      },
      {
        name: 'Notificações',
        to: '/admin/notifications',
        permissions: [PermissionsNames.MANAGEMENT_NOTIFICATIONS],
      },
      {
        name: 'Códigos de rastreios',
        to: '/admin/tracking',
        permissions: [PermissionsNames.MANAGEMENT_TRACKING_CODE],
      },
      {
        name: 'Ranking',
        to: '/admin/ranking',
        permissions: [
          PermissionsNames.MANAGEMENT_RANKING,
          PermissionsNames.RECORD_RANKING_SEASON,
        ],
      },
      {
        name: 'Ações em massa',
        to: '/admin/bulk-actions',
        permissions: [PermissionsNames.MANAGEMENT_BULK_ACTION],
      },
      {
        name: 'Ações em massa turbinação',
        to: '/admin/bulk-action-turbine',
        permissions: [PermissionsNames.MANAGEMENT_BULK_ACTION_TURBINATION],
      },
      {
        name: 'Reativações',
        to: '/admin/bulk-action-activation',
        permissions: [PermissionsNames.MANAGEMENT_BULK_ACTION_REACTIVATION],
      },
    ],
  },
  {
    name: 'Relatórios',
    icon: IconRelatorios,
    items: [
      {
        name: 'Relatório Financeiro',
        to: '/admin/reports/finances',
        permissions: [PermissionsNames.REPORT_FINANCIAL],
      },
      {
        name: 'Relatório de assinaturas',
        to: '/admin/reports/subscriptions',
        permissions: [PermissionsNames.REPORT_SIGNATURES],
      },
      {
        name: 'Relatório de processamento',
        to: '/admin/reports/queues',
        permissions: [PermissionsNames.REPORT_PROCESSING],
      },
    ],
  },
];

export const getAvailableRoutes = (user: Partial<User>): IRoute[] => {
  const userRoles = user?.roles || [];
  const availableRoutes: IRoute[] = [];
  for (const userRole of userRoles) {
    for (const permission of userRole.permissions) {
      for (const route of routes) {
        if (route.permissions?.some((p: string) => p === permission.name)) {
          availableRoutes.push(route);
        }
      }
    }
  }
  return availableRoutes;
};

export const getAvailableDrawers = (user: Partial<User>): IDrawer[] => {
  const userRoles = user?.roles || [];
  const availableDrawers = [];

  if (userRoles.length === 0) {
    return [];
  }

  for (const drawer of drawers) {
    const items: any = [];
    for (const item of drawer.items) {
      for (const userRole of userRoles) {
        for (const permission of userRole.permissions) {
          if (item.permissions?.some((p: string) => p === permission.name)) {
            if (!items.some((i: any) => i.name === item.name)) {
              items.push(item);
              break;
            }
          }
        }
      }
    }
    availableDrawers.push({ ...drawer, items });
  }
  return availableDrawers.filter(drawer => drawer.items.length > 0);
};
