import Colors from 'styles/colors';

export interface TagConfig {
  tagBackground?: string;
  tagColor?: string;
}

export interface TagProps {
  config: TagConfig;
  value?: string;
  fullwidth?: boolean;
}

export const DEFINED_TAGS = {
  DEFAULT: {
    tagColor: Colors.WHITE,
    tagBackground: Colors.SALMON,
  },
  INFO: {
    tagColor: Colors.DARKER_GRAY,
    tagBackground: Colors.DARK_YELLOW,
  },
  STATUS: {
    tagColor: Colors.DARKER_GRAY,
    tagBackground: Colors.GREEN,
  },
  MESSAGE: {
    tagColor: Colors.DARKER_GRAY,
    tagBackground: Colors.LIGHT_GRAY,
  },
  ERROR: {
    tagColor: Colors.WHITE,
    tagBackground: Colors.RED,
  },
};

export enum TagStatus {
  separation = 'Em separação',
  transit = 'Em trânsito',
}
