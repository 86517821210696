import * as Yup from 'yup';
import { endDate } from 'utils/formYupFields';
import { formatDateWithNoTime } from 'helpers/formatters';

const defaultDisplayConfig = {
  email: true,
  dashboard: true,
  newSignature: true,
  dynamicUrl: true,
};

export const validationSchema = Yup.object().shape({
  description: Yup.string().required('Campo obrigatório'),
  buttonText: Yup.string().required('Campo obrigatório'),
  startDate: Yup.date()
    .typeError('Campo obrigatório')
    .required('Campo obrigatório'),
  endDate,
  product: Yup.string().required('Campo obrigatório'),
  price: Yup.string().required('Campo obrigatório'),
});

export const defaultValues = (item: any) => {
  return {
    id: item?.id || '',
    price: item?.price || '',
    buttonText: item?.buttonText || '',
    description: item?.description || '',
    startDate: formatDateWithNoTime(item?.startDate) || '',
    endDate: formatDateWithNoTime(item?.endDate) || '',
    product: item?.product || '',
    displayConfig: item?.displayConfig || defaultDisplayConfig,
  };
};
