import { makeStyles, createStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formWrapper: {
      width: '100%',
      padding: '12px 0px',
      [theme.breakpoints.up('md')]: {
        maxWidth: '1200px',
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gridGap: 24,
        overflow: 'auto',
        backgroundColor: theme.palette.grey['100'],
      },
    },
  }),
);

export default useStyles;
