import React, { FC, useRef } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { InputRef } from 'types/FormTypes';
import { Grid } from '@material-ui/core';

import MASK, { UNMASK } from 'helpers/masks';

import { TextInput } from '../styles';

import { SubscriberColumnProps } from './types';

const SubscriberColumn: FC<SubscriberColumnProps> = ({
  errors,
  register,
  control,
}) => {
  const nameInput = useRef() as InputRef;
  const emailInput = useRef() as InputRef;
  const phoneInput = useRef() as InputRef;
  const identificationInput = useRef() as InputRef;

  const handleIdentificationChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    return unmasked;
  };

  const handlePhoneChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    return unmasked;
  };

  return (
    <Grid container item xs={6} spacing={1}>
      <Grid item xs={12}>
        <TextInput
          fullWidth
          label="Nome completo"
          id="name"
          name="name"
          type="text"
          inputProps={{ ref: nameInput }}
          changeFocusOnEnter={() => emailInput.current.focus()}
          error={Boolean(errors.name)}
          helperText={errors.name ? errors.name.message : null}
          inputRef={register}
        />
      </Grid>
      <Grid item xs={12}>
        <TextInput
          fullWidth
          label="Email"
          id="email"
          name="email"
          inputProps={{ ref: emailInput }}
          changeFocusOnEnter={() => phoneInput.current.focus()}
          error={Boolean(errors.email)}
          helperText={errors.email ? errors.email.message : null}
          inputRef={register}
        />
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={InputMask}
          mask={MASK.CELLPHONE}
          name="phone"
          control={control}
          maskChar={null}
          onChange={handlePhoneChange}
          defaultValue={''}
        >
          {() => (
            <TextInput
              fullWidth
              label="Telefone"
              type="text"
              name="phone"
              inputProps={{ ref: phoneInput, inputMode: 'numeric' }}
              changeFocusOnEnter={() => identificationInput.current.focus()}
              error={Boolean(errors.phone)}
              helperText={errors.phone ? errors.phone.message : null}
              inputRef={register}
            />
          )}
        </Controller>
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={InputMask}
          mask={MASK.CPF}
          name="identification"
          control={control}
          maskChar={null}
          onChange={handleIdentificationChange}
          defaultValue={''}
        >
          {() => (
            <TextInput
              fullWidth
              label="CPF"
              type="text"
              inputProps={{
                ref: identificationInput,
                inputMode: 'numeric',
              }}
              changeFocusOnEnter={() => phoneInput.current.focus()}
              error={Boolean(errors.identification)}
              helperText={
                errors.identification ? errors.identification.message : null
              }
            />
          )}
        </Controller>
      </Grid>
      <Grid item xs={12}>
        <Controller
          as={InputMask}
          mask={MASK.DATE}
          name="memberSince"
          control={control}
          maskChar={null}
          onChange={handlePhoneChange}
        >
          {() => (
            <TextInput
              fullWidth
              isDisabled={true}
              name="memberSince"
              label="Membro Desde"
              type="text"
              inputProps={{ inputMode: 'numeric' }}
              error={Boolean(errors.memberSince)}
              helperText={
                errors.memberSince ? errors.memberSince.message : null
              }
              inputRef={register}
            />
          )}
        </Controller>
      </Grid>
    </Grid>
  );
};

export default SubscriberColumn;
