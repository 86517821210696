import { Transport } from 'types/generals/transport';

export enum TransportTypes {
  LOAD_TRANSPORT_COVERAGE_REQUEST = '@transport/LOAD_TRANSPORT_COVERAGE_REQUEST',
  LOAD_TRANSPORT_COVERAGE_SUCCESS = '@transport/LOAD_TRANSPORT_COVERAGE_SUCCESS',
  LOAD_TRANSPORT_COVERAGE_FAIL = '@transport/LOAD_TRANSPORT_COVERAGE_FAIL',
}

export interface TransportState {
  readonly data: Transport;
  readonly hasError: boolean;
  readonly isLoading: boolean;
}
