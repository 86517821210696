import React, { FC, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import { useStyles } from './useStyles';
import { ActionsButtonProps } from './types';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import { useDispatch } from 'react-redux';
import { notifyError } from 'store/ducks/notification/actions';

const ActionsButton: FC<ActionsButtonProps> = ({
  menuItems,
  hasErrorFile,
  buttonText = 'Ações',
}) => {
  const style = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    if (hasErrorFile) {
      dispatch(notifyError('Erro ao tentar exportar planilha'));
    }
  }, [dispatch, hasErrorFile]);

  return (
    <>
      <MyDropdown
        buttonProps={{
          color: 'primary',
          variant: 'contained',
          className: style.filterOptionsButton,
        }}
        menuProps={{
          classes: {
            list: style.filterOptionsList,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          PaperProps: {
            style: {
              marginTop: '1rem',
              borderRadius: 13,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
        menuItems={menuItems}
      >
        <Typography variant="h3" className={style.actionButton}>
          {buttonText}
        </Typography>
      </MyDropdown>
    </>
  );
};

export default ActionsButton;
