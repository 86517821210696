import { Reducer } from 'redux';
import { StoreSettings } from 'types/client';
import { StoreSettingsState, StoreSettingsTypes } from './types';

const INITIAL_STATE = {
  settings: [] as StoreSettings[],
  activeSettings: {} as StoreSettings,
  isLoading: true,
  hasError: false,
  // FIXME: property responsable for enabling "black friday mode", for the moment it'll be set manually
  isPromotionalPeriod: false,
  isMaintenance: false,
  hasPromotion: false,
};
const storeSettingsReducer: Reducer<StoreSettingsState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case StoreSettingsTypes.LOAD_VERIFY_HAS_PROMOTION:
    case StoreSettingsTypes.REQUEST_STORE_SETTINGS:
    case StoreSettingsTypes.REQUEST_ACTIVE_STORE_SETTINGS:
    case StoreSettingsTypes.LOAD_STORE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        settings: action.payload,
      };
    case StoreSettingsTypes.LOAD_ACTIVE_STORE_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        activeSettings: action.payload,
      };
    case StoreSettingsTypes.FAIL_LOAD_STORE_SETTINGS:
      return {
        ...state,
        isLoading: false,
        hasError: true,
        settings: [] as StoreSettings[],
      };
    case StoreSettingsTypes.SET_HAS_PROMOTION:
      return { ...state, hasPromotion: action.payload.hasPromotion };
    case StoreSettingsTypes.SET_IS_PROMOTIONAL_PERIOD:
      return {
        ...state,
        isPromotionalPeriod: action.payload.isPromotionalPeriod,
      };
    case StoreSettingsTypes.SET_IS_MAINTENANCE:
      return { ...state, isMaintenance: action.payload.isMaintenance };
    case StoreSettingsTypes.INIT_REQUEST:
      return { ...state, isLoading: true, hasError: false };
    case StoreSettingsTypes.END_REQUEST:
      return { ...state, isLoading: false, hasError: true };
    default:
      return state;
  }
};

export default storeSettingsReducer;
