import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Link } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { loadOrders } from 'store/ducks/order/actions';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';

import { AppState } from 'store';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTableCell from 'components/generals/table/MyTableCell';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import theme from 'styles/theme';
import Colors from 'styles/colors';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { Order, ORDER_TYPE, ORDER_STATUS } from 'types/generals/order';
import { useFilters } from 'hooks/filters';
import { OrderState } from 'store/ducks/order/types';
import {
  ORDER_REFUND_SOLICITATION,
  ORDER_REFUND_ERROR,
  REFUND_PARCIAL_PAYMENT,
  ORDER_MARK_AS_REMOVED_ERROR,
  ORDER_MARK_AS_REMOVED_SUCCESS,
  ORDER_MARK_AS_REMOVED_CONFIRM,
} from 'utils/messages';
import {
  ChargebackDialogProps,
  BaseDialogProps,
  SignatureTypeDialogProps,
} from 'components/generals/dialog/dialogTypes';
import { openModal, closeModal } from 'store/ducks/nav/actions';
import {
  notifyError,
  notifySuccess,
  disableButton,
  enableButton,
} from 'store/ducks/notification/actions';
import ChargebackDialog from 'components/generals/dialog/ChargebackDialog';
import OrdersService from 'services/ordersService';
import { StatusCodes } from 'http-status-codes';
import { ParamsProps, SubscriberProps } from '../../types';
import TagWrapper, { DEFINED_TAGS } from 'components/generals/tag';
import { useStyles } from '../../styles';
import { first } from 'lodash';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { RECURRENCES_MONTHS } from 'types/generals';
import AddMonthOrderDialog from 'components/generals/dialog/AddMonthOrderDialog';

const ClientOrders: FC<SubscriberProps> = ({ subscriber }) => {
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const subscriberId = useParams<ParamsProps>().id;
  const dispatch = useDispatch();
  const styles = useStyles();

  // const searchObject = [
  //   {
  //     field: 'number',
  //     operator: CondOperator.CONTAINS_LOW,
  //   },
  //   { field: 'type', operator: CondOperator.CONTAINS_LOW },
  // ];

  const {
    data: orders = [],
    total,
    isLoading: orderLoading,
    hasError,
  } = useSelector<AppState, OrderState>(
    ({ ordersListReducer }: AppState) => ordersListReducer,
  );

  const {
    genericFilterFields,
    sort,
    page,
    handleSort,
    handleSetValuePage,
    setInitialSort,
  } = useFilters({
    'signature.id': { filterTitle: 'Signature', value: undefined },
  });

  const loadAllOrders = useCallback(() => {
    dispatch(
      loadOrders({
        join: [
          {
            field: 'orderDetail',
            select: [
              'id',
              'amount',
              'transport',
              'orderType',
              'discount',
              'freight',
              'subtotal',
            ],
          },
        ],
        sort,
        page: page + 1,
        limit: rowsPerPage,
        search: {
          $and: [
            { signatureId: { $eq: subscriberId } },
            { 'orderDetail.orderType': { $eq: ORDER_TYPE.SIGNATURE } },
            { $or: [...genericFilterFields] },
          ],
        },
      }),
    );
  }, [dispatch, page, rowsPerPage, genericFilterFields, subscriberId, sort]);

  useEffect(() => {
    loadAllOrders();
  }, [loadAllOrders]);

  useEffect(() => {
    setInitialSort({ field: 'paymentDate', order: 'DESC' });
  }, [setInitialSort]);

  const handleFormatDate = useCallback((date: string) => {
    return formatColumnValue(FormatOptions.SIMPLE_DATE_WITH_TRACE, date);
  }, []);

  const handleFormatPrice = useCallback((price: string) => {
    return formatColumnValue(FormatOptions.MONEY, price);
  }, []);

  const handleChargebackDialog = (
    title: string,
    message: string,
    actionFn: any,
    order: Order,
  ) => {
    try {
      const dialogProps: ChargebackDialogProps = {
        actionFn,
        title,
        message,
        order,
      };
      dispatch(openModal(ChargebackDialog, dialogProps));
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleRefundSignature = async (
    orderId: number,
    refundReason: string,
    amountToRefund: number,
  ) => {
    try {
      let response: any;
      if (amountToRefund) {
        response = await OrdersService.partialRefundPayment(
          orderId,
          refundReason,
          amountToRefund,
        );
      } else {
        response = await OrdersService.refundPayment(orderId, refundReason);
      }

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ORDER_REFUND_SOLICITATION));
      } else {
        throw new Error(ORDER_REFUND_ERROR(response.data.message));
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleMarkOrderAsRemoved = async (id: number) => {
    try {
      dispatch(disableButton());
      const response = await OrdersService.markOrderAsRemoved(id);

      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(ORDER_MARK_AS_REMOVED_SUCCESS));
        dispatch(closeModal());
        loadAllOrders();
      } else {
        throw new Error(ORDER_MARK_AS_REMOVED_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
    }
  };

  const handleOpenModalConfirmDeleteOrder = (id: number) => {
    const modalProps: BaseDialogProps = {
      title: 'Remover pedido',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: ORDER_MARK_AS_REMOVED_CONFIRM(id),
      actionFn: () => handleMarkOrderAsRemoved(id),
    };

    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleOpenModalAddOrder = () => {
    const dialogProps: SignatureTypeDialogProps = {
      modalTitle: 'Adicionar pedido',
      submitText: 'Confirmar',
      subscriber,
    };

    dispatch(openModal(AddMonthOrderDialog, dialogProps));
  };

  return (
    <Grid container justify="space-between" direction="column">
      <Grid
        container
        justify="space-between"
        direction="row"
        spacing={6}
        alignItems="center"
      >
        <Grid item>
          <Typography
            variant="h1"
            color="textPrimary"
            style={{ fontWeight: 600 }}
          >
            Histórico de Pedidos
          </Typography>
        </Grid>
        {subscriber?.plan?.recurrence.months === RECURRENCES_MONTHS.MONTHLY ? (
          <Grid item>
            <Button variant="text" onClick={() => handleOpenModalAddOrder()}>
              <Typography
                variant="h4"
                color="textPrimary"
                className={styles.buttonText}
              >
                Adicionar pedido
              </Typography>
            </Button>
          </Grid>
        ) : null}
      </Grid>
      <Grid item>
        <Paper
          style={{
            marginTop: 24,
            padding: theme.spacing(1, 3, 3, 3),
            borderRadius: 30,
          }}
          elevation={0}
        >
          <TableContainer>
            <Table aria-label="orders list">
              <TableHead>
                <TableRow>
                  <MySortableTableCell
                    onClick={() => handleSort('paymentDate')}
                    direction={sort.field === 'paymentDate' && sort.order}
                  >
                    Data
                  </MySortableTableCell>
                  <MySortableTableCell
                    onClick={() => handleSort('id')}
                    direction={sort.field === 'id' && sort.order}
                  >
                    Nº Pedido
                  </MySortableTableCell>
                  <MySortableTableCell
                    onClick={() => handleSort('oldOrder.codigo_pedido')}
                    direction={
                      sort.field === 'oldOrder.codigo_pedido' && sort.order
                    }
                  >
                    Pedido Antigo
                  </MySortableTableCell>
                  <TableCell>Referência Pedido</TableCell>
                  <MySortableTableCell
                    onClick={() => handleSort('orderDetail.orderType')}
                    direction={
                      sort.field === 'orderDetail.orderType' && sort.order
                    }
                  >
                    Tipo
                  </MySortableTableCell>
                  <MySortableTableCell
                    onClick={() => handleSort('status')}
                    direction={sort.field === 'status' && sort.order}
                  >
                    Status
                  </MySortableTableCell>
                  <MySortableTableCell
                    onClick={() => handleSort('orderDetail.transport')}
                    direction={
                      sort.field === 'orderDetail.transport' && sort.order
                    }
                  >
                    Transporte
                  </MySortableTableCell>
                  <MySortableTableCell
                    onClick={() => handleSort('orderDetail.discount')}
                    direction={
                      sort.field === 'orderDetail.discount' && sort.order
                    }
                  >
                    Desconto
                  </MySortableTableCell>
                  <TableCell>Total</TableCell>
                  <TableCell>Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!orderLoading &&
                  !hasError &&
                  orders.map(order => (
                    <TableRow key={order.id}>
                      <MyTableCell>
                        {handleFormatDate(order.paymentDate)}
                      </MyTableCell>
                      <MyTableCell>
                        {order.id}
                        {order.isResend ? (
                          <Grid item xs>
                            <TagWrapper
                              config={DEFINED_TAGS.STATUS}
                              value={'Reenvio'}
                            />
                          </Grid>
                        ) : null}
                        {order.isEnhanced ? (
                          <Grid item xs>
                            <TagWrapper
                              config={DEFINED_TAGS.INFO}
                              value={'Turbinado'}
                            />
                          </Grid>
                        ) : null}
                        {order.isFirstPaidOrder ? (
                          <Grid item xs>
                            <TagWrapper
                              config={DEFINED_TAGS.STATUS}
                              value={'1ª pedido'}
                            />
                          </Grid>
                        ) : null}
                        {order.isFirstOrderGift ? (
                          <Grid item xs>
                            <TagWrapper
                              config={DEFINED_TAGS.INFO}
                              value={'Brinde'}
                            />
                          </Grid>
                        ) : null}
                      </MyTableCell>
                      <MyTableCell>
                        {order.oldOrder?.codigo_pedido || '-'}
                      </MyTableCell>
                      <MyTableCell>
                        {first(order.referenceOrders)?.id || '-'}
                      </MyTableCell>
                      <MyTableCell>{order.orderDetail.orderType}</MyTableCell>
                      <MyTableCell>{order.status}</MyTableCell>
                      <MyTableCell>{order.orderDetail.transport}</MyTableCell>
                      <MyTableCell>
                        {`-${handleFormatPrice(
                          order.orderDetail.discount.toString(),
                        )}`}
                      </MyTableCell>
                      <MyTableCell>
                        {handleFormatPrice(
                          order.orderDetail?.amount?.toString() || '0',
                        )}
                      </MyTableCell>
                      <MyTableCell>
                        <Link
                          className={styles.linkStyle}
                          to={`/admin/orders/${order.id}`}
                        >
                          <Button>
                            <Typography
                              variant="h4"
                              color="textPrimary"
                              style={{
                                color: Colors.RED,
                                fontWeight: 'bold',
                                textTransform: 'capitalize',
                              }}
                            >
                              Ver pedido
                            </Typography>
                          </Button>
                        </Link>
                      </MyTableCell>

                      <MyTableCell align="center">
                        {order.status === ORDER_STATUS.CANCELED ? (
                          <Button
                            onClick={() =>
                              handleOpenModalConfirmDeleteOrder(order.id)
                            }
                            className={styles.circle}
                          >
                            <Typography
                              variant="h6"
                              color="textPrimary"
                              className={styles.removeTableButtom}
                            >
                              <DeleteOutlinedIcon
                                className={styles.deleteIcon}
                              />
                            </Typography>
                          </Button>
                        ) : (
                          <Typography variant="h4" color="textPrimary">
                            -
                          </Typography>
                        )}
                      </MyTableCell>

                      {order.status !== ORDER_STATUS.SUBMITTED &&
                      order.status !== ORDER_STATUS.PENDING &&
                      order.orderDetail.amount > 0 ? (
                        <MyTableCell>
                          <Button
                            onClick={() =>
                              handleChargebackDialog(
                                'Estornar pedido',
                                REFUND_PARCIAL_PAYMENT(
                                  order.orderDetail.amount,
                                ),
                                (
                                  orderId: number,
                                  refundReason: string,
                                  amountToRefund: number,
                                ) =>
                                  handleRefundSignature(
                                    orderId,
                                    refundReason,
                                    amountToRefund,
                                  ),
                                order,
                              )
                            }
                          >
                            <Typography
                              variant="h4"
                              color="textPrimary"
                              style={{
                                color: Colors.RED,
                                fontWeight: 'bold',
                                textTransform: 'capitalize',
                              }}
                            >
                              Estornar pedido
                            </Typography>
                          </Button>
                        </MyTableCell>
                      ) : (
                        <MyTableCell align="center">
                          <Typography variant="h4" color="textPrimary">
                            -
                          </Typography>
                        </MyTableCell>
                      )}
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
        {!orderLoading && !hasError ? (
          <TablePagination
            ActionsComponent={MyTablePaginationActions}
            component="div"
            count={total}
            page={page}
            labelRowsPerPage="Itens por página"
            onChangePage={(_event: unknown, newPage: number) =>
              handleSetValuePage(newPage)
            }
            rowsPerPage={rowsPerPage}
            onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
              handleSetValuePage(0);
              setRowsPerPage(parseInt(event.target.value, 10));
            }}
            rowsPerPageOptions={ROWS_PER_PAGE}
          />
        ) : null}
      </Grid>
    </Grid>
  );
};

export default ClientOrders;
