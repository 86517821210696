import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

import { POSTSERVICES } from 'helpers/trackingConstants';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';

import { useStyles } from './styles';
import ServiceContainer from './ServiceContainer';

const TrackingCodes: FC = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  useEffect(() => {
    dispatch(actUpdatePageTitle('Códigos de Rastreios'));
  }, [dispatch]);

  return (
    <Grid container direction="column">
      <Grid
        container
        className={styles.containerSpacing}
        justify="flex-start"
        alignItems="center"
      >
        <Typography
          variant="h3"
          style={{ fontWeight: 'bold', marginBottom: '35px' }}
          color="textPrimary"
        >
          Tipos de Serviços
        </Typography>
        <Grid container direction="column" spacing={1} justify="space-between">
          {POSTSERVICES.map(service => (
            <ServiceContainer
              key={service.serviceNumber}
              serviceName={service.serviceName}
              serviceNumber={service.serviceNumber}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default TrackingCodes;
