import React, { FC } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

import StoreHeader from './Header';
import { Wrapper } from '../styles';
import StoreFooter from './Footer';
import { useStyles } from './styles';

const LayoutStore: FC = ({ children }) => {
  const theme = useTheme();
  const styles = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <>
      <Grid item xs={12}>
        <StoreHeader />
      </Grid>
      <Wrapper
        container
        direction={isMobile ? 'column' : 'row'}
        style={{
          padding: isMobile ? '160px 24px 80px 24px' : '120px 32px 0px',
          minHeight: '80vh',
        }}
      >
        <Grid item xs={12} className={styles.centralizedContainer}>
          {children}
        </Grid>
      </Wrapper>
      <Grid item xs={12}>
        <StoreFooter />
      </Grid>
    </>
  );
};

export default LayoutStore;
