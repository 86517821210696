export const REMOVE_BENEFIT_SUCCESS = 'Benefício removido com sucesso.';
export const CREATE_BENEFIT_SUCCESS = 'Benefício cadastrado com sucesso.';
export const UPDATE_BENEFIT_SUCCESS = 'Benefício atualizado com sucesso.';

export const UPDATE_BENEFIT_ERROR = 'Houve uma falha ao atualizar o benefício.';
export const CREATE_BENEFIT_ERROR = 'Houve uma falha ao criar o benefício.';
export const REMOVE_BENEFIT_ERROR = 'Houve uma falha ao remover o benefício.';

export const REMOVE_BENEFIT_ASK = (title: string) =>
  `Deseja remover o benefício ${title}?`;
