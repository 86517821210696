import { makeStyles } from '@material-ui/core';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  itemNameText: {
    fontWeight: 600,
    margin: '10px 0px',
  },
  list: {
    listStyleType: 'none',
    paddingInline: '16px',
    maxHeight: '190px',
    overflow: 'overlay',
  },
  listItem: {
    padding: theme.spacing(1.5),
  },
});
