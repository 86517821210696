import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  expandIcon: {
    color: Colors.ORANGE,
  },
  alignEnd: {
    textAlign: 'end',
  },
  boldText: {
    fontWeight: 500,
  },
  buttonText: {
    color: Colors.RED,
    fontWeight: 'bold',
  },
  img: {
    width: '48px',
    height: '36px',
  },
  mTable: {
    marginTop: '40px',
  },
  mRight: {
    marginRight: theme.spacing(6),
  },
  mTop: {
    marginTop: theme.spacing(3),
  },
  rootMargin: {
    padding: theme.spacing(5.5, 0),
    height: '100%',
  },
  paperTable: {
    padding: theme.spacing(3, 4),
    borderRadius: '2em',
    height: '100%',
  },
  paperTableMobile: {
    padding: 10,
    borderRadius: '2em',
  },
  planCard: {
    padding: theme.spacing(2, 3),
    borderRadius: '8px',
    textAlign: 'center',
    marginTop: '40px',
  },
  confirmationText: {
    margin: '48px 0',
    textAlign: 'center',
  },
  plansList: {
    padding: 24,
  },
  buttonCancelPlanText: {
    color: Colors.RED,
    fontWeight: 'bold',
    fontSize: 12,
  },
  linkText: {
    margin: '48px 0',
    color: Colors.DARK_GRAY,
  },
  overflowCell: {
    overflow: 'inherit',
  },
  responsiveCell: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
    display: 'table-cell',
  },
  textContainerMobile: {
    width: 250,
  },
  textContainer: {
    width: 300,
  },
  advertising: {
    textAlign: 'center',
    fontSize: 18,
  },
  confirmButton: {
    width: 200,
    height: 40,
  },
  paymentTypeText: {
    fontSize: 13,
    fontWeight: 700,
    color: Colors.DARK_GRAY,
  },
  root: {
    marginTop: 20,
  },
  cardContainer: {
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    borderRadius: 8,
    boxShadow: `0px 4px 3px rgba(0, 0, 0, 0.07)`,
    padding: '16px 16px 16px 30px',
    height: '100%',
  },
  textBold: {
    fontWeight: 'bold',
  },
  benefitTitle: {
    fontSize: 14,
    marginLeft: 5,
    color: Colors.DARK_GRAY,
  },
  benefitTop: {
    marginTop: 10,
  },
  benefitImage: {
    height: 68,
    width: 68,
  },
  benefitTextContainerMargin: {
    marginLeft: 40,
  },
  benefitDescriptionText: {
    fontSize: 14,
    marginTop: 8,
    wordWrap: 'break-word',
  },
  benefitImageMobile: {
    height: 50,
    width: 50,
  },
  benefitsContainer: {
    maxHeight: 405,
    overflowY: 'auto',
  },
  rounded: {
    borderRadius: 8,
  },
  benefitInactiveColor: {
    color: Colors.GRAY,
  },
  benefitActiveColor: {
    color: Colors.BRIGHT_DARK_GREEN,
  },
  benefitActiveTextColor: {
    color: Colors.BLACK,
  },
  cardTitle: {
    fontSize: 20,
    fontWeight: 600,
  },
  signatureCardMobileTitle: {
    fontSize: 14,
    fontWeight: 600,
  },
  planName: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  planPrice: {
    marginTop: 5,
    fontSize: 23,
    fontWeight: 600,
  },
  planRecurrenceText: {
    fontSize: 20,
    fontWeight: 500,
  },
  renewGrid: {
    marginTop: 50,
    marginBottom: 10,
  },
  renewDateText: {
    fontSize: 14,
    fontWeight: 600,
    color: Colors.BLUE,
  },
  upgradePlanButton: {
    width: 210,
    height: 44,
    marginRight: 10,
    backgroundColor: Colors.BRIGHT_DARK_GREEN,
    '&:hover': {
      backgroundColor: Colors.BRIGHT_DARK_GREEN,
    },
  },
  upgradePlanButtonMobile: {
    width: '100%',
    height: 44,
    backgroundColor: Colors.BRIGHT_DARK_GREEN,
    marginTop: 8,
    '&:hover': {
      backgroundColor: Colors.BRIGHT_DARK_GREEN,
    },
  },
  upgradeButtonText: {
    fontWeight: 'bold',
    fontSize: 16,
    color: Colors.WHITE,
  },
  addressText: {
    fontSize: 16,
    fontWeight: 500,
  },
  addressUpdateText: {
    fontSize: 14,
    fontWeight: 'bold',
    color: Colors.ORANGE,
  },
  addressUpdateButton: {
    paddingTop: 5,
    paddingBottom: 5,
    paddingRight: 0,
    paddingLeft: 0,
  },
  nextSignatureText: {
    fontWeight: 'bold',
    fontSize: 14,
    color: Colors.ORANGE,
  },
  nextSignatureButton: {
    width: 265,
    height: 44,
    cursor: 'unset',
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    '&:disabled': {
      backgroundColor: Colors.LIGHTER_GRAY,
    },
  },
  moreBenefitsButton: {
    borderColor: Colors.ORANGE,
    width: 260,
    height: 44,
  },
  moreBenefitsButtonMobile: {
    borderColor: Colors.ORANGE,
    width: '100%',
    height: 44,
  },
  moreBenfitsButtonText: {
    fontSize: 14,
    color: Colors.ORANGE,
    fontWeight: 600,
  },
  benefitCardContainer: {
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    borderRadius: 8,
    boxShadow: `0px 4px 3px rgba(0, 0, 0, 0.07)`,
    height: '100%',
  },
  benefitCard: {
    padding: 50,
  },
  benefitCardMobile: {
    padding: 16,
  },
  cancelNextSignatureButton: {
    marginLeft: 10,
  },
  cancelNextSignatureButtonMobile: {
    width: '100%',
    border: `1px solid ${Colors.LIGHT_GRAY}`,
    marginTop: 10,
  },
  buttonDialog: {
    fontSize: 16,
    textAlign: 'center',
    marginBottom: 15,
  },
});
