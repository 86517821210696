import React, { FC, Fragment, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { last } from 'lodash';

import { ILogCotation } from 'types/generals/orderDetail';
import { closeModal } from 'store/ducks/nav/actions';
import { useStyles } from './styles';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';

export interface LogOrderCotationProps {
  logCotation: ILogCotation[];
  orderId: number;
}

const LogOrderCotation: FC<LogOrderCotationProps> = ({
  logCotation,
  orderId,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const lastLog = last(logCotation);

  const lastCotation = last(lastLog?.data);

  const handleFormatDateTime = useCallback((date: string) => {
    return formatColumnValue(FormatOptions.DATETIME, date);
  }, []);

  const renderText = (title: string, text: string) => (
    <Grid item>
      <Typography variant="h4">
        <span className={styles.boldText}>{title}: </span>
        {text || '-'}
      </Typography>
    </Grid>
  );

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between">
          <Typography
            variant="h1"
            color="textPrimary"
            className={styles.boldText}
          >
            Log da cotação do pedido nº {orderId}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        {lastLog ? (
          <Grid container direction="column" spacing={1}>
            {lastLog.error ? (
              <Fragment>
                {renderText('Erro', lastLog.error.messageError)}
                {renderText(
                  'Data da cotação',
                  handleFormatDateTime(lastLog.error.date.toString()),
                )}
                {renderText(
                  'Usuário que solicitou a cotação',
                  lastLog.requester,
                )}
              </Fragment>
            ) : null}

            {lastCotation ? (
              <Fragment>
                {renderText('id da cotação', lastLog.id_cotacao || '-')}
                {renderText(
                  'Data da cotação',
                  handleFormatDateTime(
                    `${lastCotation.data_simulacao} ${lastCotation.hora_simulacao}`,
                  ),
                )}
                {renderText(
                  'CNPJ transportadora',
                  lastCotation.cnpj_transportador || '-',
                )}
                {renderText(
                  'Transportadora',
                  lastCotation.nome_transportador || '-',
                )}
                {renderText('Descrição', lastCotation.descricao || '-')}
                {renderText(
                  'Prazo de entrega',
                  lastCotation.prazo.toString() || '-',
                )}
                {renderText(
                  'Valor do frete',
                  `R$ ${lastCotation.valor_frete.toString()}` || '-',
                )}
                {renderText(
                  ' Usuário que solicitou a cotação',
                  lastLog.requester || '-',
                )}
              </Fragment>
            ) : null}
          </Grid>
        ) : (
          <Typography variant="h4">
            Pedido sem cotação iniciada até o momento
          </Typography>
        )}
      </DialogContent>
    </Grid>
  );
};

export default LogOrderCotation;
