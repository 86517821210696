import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import UserStep from './UserStep';
import AdressAndPaymentStep from './AdressAndPaymentStep';
import FinishStep from './FinishStep';
import { validationSchema } from './utils';
import useStyles from './styles';
import { CheckoutFormsProps } from './types';
import CheckoutEnhancedProduct from '../CheckoutEnhancedProduct';

const CheckoutForms = ({ onSubmit, referralCode }: CheckoutFormsProps) => {
  const classes = useStyles();

  const {
    handleSubmit,
    register,
    control,
    reset,
    errors,
    setValue,
    setError,
  } = useForm({
    mode: 'onSubmit',
    validationSchema,
    reValidateMode: 'onSubmit',
  });

  return (
    <Grid className={classes.formWrapper}>
      <Grid item>
        <UserStep
          register={register}
          control={control}
          errors={errors}
          setValue={setValue}
          handleSubmit={handleSubmit}
          reset={reset}
        />
      </Grid>
      <Grid item>
        <AdressAndPaymentStep
          register={register}
          control={control}
          reset={reset}
          errors={errors}
          setValue={setValue}
          setError={setError}
        />
      </Grid>
      <Grid item container direction="column" spacing={1}>
        <Grid item xs>
          <FinishStep
            handleActionFinishForm={handleSubmit(onSubmit)}
            control={control}
            errors={errors}
            reset={reset}
            register={register}
            setValue={setValue}
            setError={setError}
          />
        </Grid>
        <Grid item xs>
          <CheckoutEnhancedProduct />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CheckoutForms;
