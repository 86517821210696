import React, { FC } from 'react';
import { Typography } from '@material-ui/core';
import { PaperTitleProps } from './types';

const PaperTitle: FC<PaperTitleProps> = ({ title, marginB }) => (
  <Typography
    variant="h2"
    color="textPrimary"
    style={{ fontWeight: 'bold', marginBottom: marginB || 10 }}
  >
    {title}
  </Typography>
);

export default PaperTitle;
