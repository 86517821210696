import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithSaga } from './apiService';
import { Coupon } from 'types/generals/coupon';
import { COUPON_TYPE } from 'components/generals/dialog/CouponDialog/types';

export default class CouponsService {
  static async getAllCoupons(query?: CreateQueryParams) {
    try {
      const data = await getWithSaga('coupons', query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async validateCoupon(
    couponName: string,
    type: COUPON_TYPE,
    userEmail?: string,
  ) {
    try {
      const data = await api.post(`coupons/validate-coupon`, {
        coupon: couponName,
        type,
        email: userEmail,
      });
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async createCoupon(coupon: Partial<Coupon>) {
    try {
      const data = await api.post('coupons', coupon);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updateCoupon(plan: Partial<Coupon>, id?: number) {
    try {
      const data = await api.patch(`coupons/${id}`, plan);
      return data;
    } catch (error) {
      return error;
    }
  }
}
