import { EnhancedProduct } from 'types/generals/enhancedProduct';

/**
 * Action types
 */
export enum EnhancedProductTypes {
  LOAD_REQUEST = '@enhancedProduct/LOAD_REQUEST',
  LOAD_OPEN_REQUEST = '@enhancedProduct/LOAD_OPEN_REQUEST',
  LOAD_REQUEST_ERROR = '@enhancedProduct/LOAD_REQUEST_ERROR',
  LOAD_REQUEST_SUCCESS = '@enhancedProduct/LOAD_REQUEST_SUCCESS',
}

/** Data types */
/* export interface Notification {
  key: string;
  message: string;
  options?: any;
} */

/**
 * State types
 */
export interface EnhancedProductState {
  readonly total: number;
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly data: EnhancedProduct[];
}
