import { call, put } from 'redux-saga/effects';
import FileService from 'services/fileservice';
import RankingSeasonService from 'services/rankingSeasonService';
import { createAndDownloadFile } from 'utils/functions/generals';
import {
  loadFile,
  loadFileFailure,
  loadFileSuccess,
} from '../generals/file/actions';
import {
  loadActiveRankingFailure,
  loadActiveRankingSuccess,
  loadRankingFailure,
  loadRankingSuccess,
  loadUserRankingSeasonFailure,
  loadUserRankingSeasonSuccess,
} from './actions';

export function* loadActiveRankingSeasonSaga(action: any) {
  try {
    const { data } = yield call(
      RankingSeasonService.getAllRankingSeason,
      action.payload.query,
    );

    yield put(loadActiveRankingSuccess(data[0]));
  } catch (err) {
    yield put(loadActiveRankingFailure());
  }
}

export function* loadRankingSeasonSaga(action: any) {
  try {
    const { data } = yield call(
      RankingSeasonService.getAllRankingSeason,
      action.payload.query,
    );
    yield put(loadRankingSuccess(data));
  } catch (err) {
    yield put(loadRankingFailure());
  }
}

export function* loadUserRankingSeasonSaga(action: any) {
  try {
    const { data, page, total } = yield call(
      RankingSeasonService.getAllUserRanking,
      action.payload.query,
    );
    yield put(loadUserRankingSeasonSuccess(data, page, total));
  } catch (err) {
    yield put(loadUserRankingSeasonFailure());
  }
}

export function* loadRankingFileSaga(action: any) {
  try {
    yield put(loadFile());
    const file = yield call(
      FileService.getFile,
      action.payload.query,
      action.payload.rootPath,
      action.payload.reportPath,
    );
    if (file?.response?.status) {
      yield put(loadFileFailure());
    } else {
      yield put(loadFileSuccess());
      createAndDownloadFile(file, 'Ranking da campanha.xls');
    }
  } catch (error) {
    yield put(loadFileFailure());
  }
}
