export const STORE_PATH = (isMaintenance: boolean) => {
  return !isMaintenance ? DEFAULT_STORE_PATH : PROMOTIONAL_STORE_PATH;
};
export const HEADER_LOGO_PATH = (isMaintenance: boolean) => {
  return !isMaintenance
    ? DEFAULT_HEADER_LOGO_PATH
    : PROMOTIONAL_HEADER_LOGO_PATH;
};

export const HEADER_BACK_BUTTON_PATH = (isMaintenance: boolean) => {
  return !isMaintenance
    ? DEFAULT_HEADER_BACK_BUTTON_PATH
    : PROMOTIONAL_HEADER_BACK_BUTTON_PATH;
};

export const STORE_PRODUCT_PATH = (isMaintenance: boolean) => {
  return !isMaintenance
    ? DEFAULT_STORE_PRODUCT_PATH
    : PROMOTIONAL_STORE_PRODUCT_PATH;
};

export const DEFAULT_STORE_PATH = '/store';
export const DEFAULT_HEADER_LOGO_PATH = '/store';
export const DEFAULT_HEADER_BACK_BUTTON_PATH = '/home';
export const DEFAULT_STORE_PRODUCT_PATH = '/store/product';

export const PROMOTIONAL_STORE_PATH = '/beta-store-validation';
export const PROMOTIONAL_HEADER_LOGO_PATH = '/beta-store-validation';
export const PROMOTIONAL_HEADER_BACK_BUTTON_PATH = '/beta-store-validation';
export const PROMOTIONAL_STORE_PRODUCT_PATH = '/beta-store-validation/product';
