import React, { useState, useCallback, useEffect } from 'react';
import {
  Grid,
  Typography,
  TablePagination,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
  TableCell,
} from '@material-ui/core';
import { Colors } from 'styles/colors';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MySelectFilter from 'components/generals/input/MySelectFilter';
import { useFilters } from 'hooks/filters';
import { ShippingState } from 'store/ducks/shipping/types';
import { AppState } from 'store';
import {
  loadShippingfile,
  loadShippingListRequest,
} from 'store/ducks/shipping/actions';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTableCell from 'components/generals/table/MyTableCell';
import { Shipping, TRANSPORT_COMPANIES, SHIPPING_TYPE } from 'types/generals';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import MyDateRangeFilter from 'components/generals/input/MyDateRangeFilter';
import { useHistory } from 'react-router-dom';
import { useStyles } from './useStyles';
import { useDispatch, useSelector } from 'react-redux';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import { CondOperator } from '@nestjsx/crud-request';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { FileState } from 'store/ducks/generals/file/types';
import { ROOT_PATH } from 'services/fileservice';
import clsx from 'clsx';

// TODO: Por enquanto só migrei codigo para um arquivo com o nome novo
const STATUS_OPTIONS = ['Ativo', 'Inativo', 'Fechada'].sort();

const ShippingList: React.FC = () => {
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    filterFields,
    genericFilterFields,
    sort,
    pageFilters,
    dateFilter,
    page,
    handleGenericSearch,
    handleUpdateFilters,
    handleSort,
    handleDateFilter,
    handleClearFilters,
    handleClearPageFilter,
    handleSetValuePage,
  } = useFilters({
    status: { filterTitle: 'Status', value: undefined },
    createdAt: { filterTitle: 'Período', value: undefined },
    name: { filterTitle: 'Nome shipping', value: undefined },
    transport: { filterTitle: 'Transportadora', value: undefined },
    shippingType: { filterTitle: 'Tipo', value: undefined },
  });

  const {
    data: shippingList = [],
    total = 0,
    hasError,
    isLoading,
  } = useSelector<AppState, ShippingState>(
    ({ shippingListReducer }: AppState) => shippingListReducer,
  );
  const { hasError: hasErrorFile } = useSelector<AppState, FileState>(
    ({ file }: AppState) => file,
  );

  const loadShippingList = useCallback(() => {
    dispatch(
      loadShippingListRequest({
        page: page + 1,
        limit: rowsPerPage,
        sort,
        search: {
          $and: [...filterFields, { $or: [...genericFilterFields] }],
        },
      }),
    );
  }, [dispatch, page, rowsPerPage, sort, genericFilterFields, filterFields]);

  const loadShippingXls = useCallback(() => {
    dispatch(
      loadShippingfile(
        {
          page: page + 1,
          limit: rowsPerPage,
          sort,
          search: {
            $and: [...filterFields, { $or: [...genericFilterFields] }],
          },
        },
        ROOT_PATH.SHIPPING,
      ),
    );
  }, [dispatch, page, rowsPerPage, sort, genericFilterFields, filterFields]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Remessas'));
  }, [dispatch]);

  useEffect(() => {
    loadShippingList();
  }, [loadShippingList]);

  const handleFormatDate = useCallback(data => {
    return formatColumnValue(FormatOptions.DATE, data);
  }, []);

  const handleRedirectDetail = (shipping: Shipping) => {
    history.push({
      pathname: `/admin/shipping/${shipping.id}`,
    });
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, [
        { field: 'name', operator: CondOperator.CONTAINS_LOW },
      ]);
    }
  };

  const ShippingTable = () => (
    <Paper className={clsx(classes.paperTable, classes.mTop)} elevation={0}>
      <TableContainer>
        <Table className={classes.table} aria-label="labels table">
          <TableHead>
            <TableRow>
              <MySortableTableCell
                onClick={() => handleSort('id')}
                direction={sort.field === 'id' && sort.order}
              >
                Código
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('name')}
                direction={sort.field === 'name' && sort.order}
              >
                Nome da Remessa
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('createdAt')}
                direction={sort.field === 'createdAt' && sort.order}
              >
                Data
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('transport')}
                direction={sort.field === 'transport' && sort.order}
              >
                Transportadora
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('shippingType')}
                direction={sort.field === 'shippingType' && sort.order}
              >
                Tipo
              </MySortableTableCell>
              <TableCell>Qtd de Brindes</TableCell>
              <MySortableTableCell
                onClick={() => handleSort('status')}
                direction={sort.field === 'status' && sort.order}
              >
                Status
              </MySortableTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!hasError &&
              !isLoading &&
              shippingList.map(shipping => (
                <TableRow key={shipping.id}>
                  <MyTableCell>{shipping.id}</MyTableCell>
                  <MyTableCell
                    className={classes.tableCellBold}
                    onClick={() => handleRedirectDetail(shipping)}
                  >
                    <Grid container alignItems="center">
                      {shipping.name || '-'}
                    </Grid>
                  </MyTableCell>
                  <MyTableCell>
                    {handleFormatDate(shipping.createdAt)}
                  </MyTableCell>
                  <MyTableCell>{shipping.transport}</MyTableCell>
                  <MyTableCell>{shipping.shippingType}</MyTableCell>
                  <MyTableCell>{shipping.products.length || '0'}</MyTableCell>
                  <MyTableCell>{shipping.status || '-'}</MyTableCell>
                </TableRow>
              ))) ||
              null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid
        item
        container
        justify="space-between"
        alignItems="center"
        className={classes.gridAction}
      >
        <Grid item container xs={4} spacing={2}>
          <Grid item>
            <MyInputFilter
              onKeyUp={e => handleSearch(e)}
              placeholder={'Buscar'}
            />
          </Grid>
          <Grid item>
            <ActionsButton
              hasErrorFile={hasErrorFile}
              menuItems={[
                {
                  title: 'Exportar planilha de remessas',
                  action: loadShippingXls,
                },
              ]}
              buttonText="Ações"
            />
          </Grid>
        </Grid>
        <Grid item>
          <Button style={{ width: '100%' }} onClick={handleClearFilters}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                color: Colors.RED,
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              Limpar filtros
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        alignItems="center"
        spacing={2}
        className={clsx(classes.gridAction, classes.mTop)}
      >
        <Grid item xs={2} md={2}>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('transport')}
            menuItems={Object.values(TRANSPORT_COMPANIES).map(transport => ({
              title: transport,
              action: () =>
                handleUpdateFilters(
                  'transport',
                  {
                    filterTitle: transport,
                    value: transport,
                  },
                  CondOperator.EQUALS,
                ),
              className: classes.menuOption,
            }))}
            buttonProps={{
              style: {
                borderColor: pageFilters.transport.value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              style={{
                fontSize: '1.6rem',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                width: '100%',
                textAlign: 'left',
              }}
            >
              {pageFilters.transport.filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
        <Grid item xs={2} md={2}>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('shippingType')}
            menuItems={Object.values(SHIPPING_TYPE).map(shippingType => ({
              title: shippingType,
              action: () =>
                handleUpdateFilters(
                  'shippingType',
                  {
                    filterTitle: shippingType,
                    value: shippingType,
                  },
                  CondOperator.EQUALS,
                ),
              className: classes.menuOption,
            }))}
            buttonProps={{
              style: {
                borderColor: pageFilters.shippingType.value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              style={{
                fontSize: '1.6rem',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                width: '100%',
                textAlign: 'left',
              }}
            >
              {pageFilters.shippingType.filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
        <Grid item xs={2} md={2}>
          <MySelectFilter
            maxItems={8}
            menuItems={STATUS_OPTIONS.map(option => ({
              title: option,
              action: () =>
                handleUpdateFilters('status', {
                  filterTitle: option,
                  value: option,
                }),
              className: classes.menuOption,
            }))}
            buttonProps={{
              style: {
                borderColor: pageFilters?.status.value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              style={{
                fontSize: '1.6rem',
                fontWeight: 'bold',
                textTransform: 'capitalize',
                width: '100%',
                textAlign: 'left',
              }}
            >
              {pageFilters['status'].filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
        <Grid item xs={2} md={2}>
          <MyDateRangeFilter
            value={dateFilter}
            onChange={handleDateFilter}
            buttonProps={{
              style: {
                borderColor: pageFilters?.createdAt.value
                  ? Colors.RED
                  : Colors.WHITE,
              },
            }}
          />
        </Grid>
      </Grid>
      <ShippingTable />
      <TablePagination
        ActionsComponent={MyTablePaginationActions}
        component="div"
        count={total}
        page={page}
        labelRowsPerPage="Itens por página"
        onChangePage={(_event: unknown, newPage: number) =>
          handleSetValuePage(newPage)
        }
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
          handleSetValuePage(0);
          setRowsPerPage(parseInt(event.target.value, 10));
        }}
        rowsPerPageOptions={ROWS_PER_PAGE}
      />
    </Grid>
  );
};

export default ShippingList;
