import { call, put } from 'redux-saga/effects';
import PlanService from 'services/planService';
import { loadOnePlanFail, loadOnePlanSuccess } from './actions';
import OpenEndpointsService from 'services/openEndpointsService';

export function* loadOnePlanSaga(action: any) {
  try {
    const { data } = yield call(PlanService.getOnePlan, action.payload);
    yield put(loadOnePlanSuccess({ data }));
  } catch (error) {
    yield put(loadOnePlanFail());
  }
}

export function* loadPlanByHashSaga(action: any) {
  try {
    const response = yield call(
      OpenEndpointsService.getPlanFromSignature,
      action.payload,
    );
    yield put(loadOnePlanSuccess({ data: { ...response } }));
  } catch (error) {
    yield put(loadOnePlanFail());
  }
}
