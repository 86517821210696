import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(theme => ({
  buttonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
    padding: '16px 32px',
  },
  cancelButton: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
    padding: '16px 32px',
    marginRight: 30,
    [theme.breakpoints.only('xs')]: {
      marginRight: 0,
    },
  },
  rootCenter: {
    margin: 'auto',
    maxWidth: 600,
  },
  rootPanel: {
    padding: theme.spacing(5.5, 0),
    height: '100%',
  },
  rootPaper: {
    padding: theme.spacing(3, 4),
    borderRadius: '2em',
    height: '100%',
  },
  mTop: {
    marginTop: theme.spacing(5),
  },
  paddingButton: {
    padding: theme.spacing(2, 4),
  },
}));
