import React from 'react';
import { Drawer } from '@material-ui/core';

import List from '../../List';

import { useStyles, Header, CloseButton } from './styles';

import { MenuPropsType } from './types';
import UserWalletScore from '../../UserWalletScore';

const CustomMenu: React.FC<MenuPropsType> = ({ open, setOpen, data }) => {
  const classes = useStyles();

  const handleCloseMenu = () => setOpen(false);

  return (
    <Drawer
      anchor="top"
      open={open}
      onClose={handleCloseMenu}
      PaperProps={{
        classes: {
          root: classes.menu,
        },
      }}
    >
      <Header container justify="space-between" alignItems="center">
        Menu
        <CloseButton onClick={handleCloseMenu} />
      </Header>
      <UserWalletScore />
      <List items={data} handleCloseMenu={handleCloseMenu} />
    </Drawer>
  );
};

export default CustomMenu;
