import { ProductImage } from './image';
import { StoreProductItem } from './storeProductItem';

export enum STORE_TYPES {
  PREMIUM = 'Loja Premium',
  NORMAL = 'Loja Normal',
}

export interface ProductPayment {
  orderId: number;
  price: string;
  description: string;
}

export interface StoreProductPayload {
  productId: number;
  itens: StoreProductItem[];
  images: ProductImage[];
  name: string;
  description: string;
  weight: string;
  sku?: string;
  isGift: boolean;
}

export interface ResendProduct {
  id: number;
  name: string;
  quantity: number;
  unitPrice: string;
  sku?: string;
}

export interface ResendProductPayload {
  id: number;
  productCount: number;
}

export interface ProductTag {
  tagName: string;
  tagColor: string;
  createdAt: Date;
  textColor?: string;
}

export interface StoreProduct {
  id: number;
  name: string;
  description: string;
  weight: string;
  startDate: string;
  endDate?: string;
  price: string;
  discount?: string;
  isVisible: boolean;
  storeType: STORE_TYPES;
  images: ProductImage[];
  itens: StoreProductItem[];
  amount: number;
  sku?: string;
  stock: number;
  tagName?: string;
  tagColor?: string;
  productTag: ProductTag[];
  smallDescription: string;
  about?: string;
  specifications?: string;
  keyword?: string;
  items?: string;
  categories: StoreCategory[];
}

export interface StoreCategory {
  id: number;
  name: string;
  isActive: boolean;
  priority: number;
}
