import { Reducer } from 'redux';
import { BannerState, BannerTypes } from './types';

const INITIAL_STATE = {
  storeBanners: [] as any,
  isLoading: true,
  hasError: false,
};

const bannerReducer: Reducer<BannerState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    // case BannerTypes.SAVE_BANNERS_REQUEST:
    case BannerTypes.INIT_BANNERS_REQUEST:
    case BannerTypes.LOAD_BANNERS_REQUEST: {
      return { ...state, isLoading: true };
    }
    case BannerTypes.UPDATE_BANNERS_REQUEST: {
      return {
        ...state,
        isLoading: false,
        storeBanners: action.payload.storeBanners,
        page: action.payload.page,
      };
    }
    /* case BannerTypes.SAVE_BANNERS_REQUEST_FAILURE: */
    case BannerTypes.LOAD_BANNERS_FAILURE: {
      return { ...state, isLoading: false, hasError: true };
    }
    case BannerTypes.END_BANNERS_REQUEST: {
      return { ...state, isLoading: false, hasError: false };
    }

    default:
      return state;
  }
};

export default bannerReducer;
