import React, { useRef } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Grid, Paper, Typography } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { validationSchema } from './schema';
import { useSecurityStyles } from './styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import useStyles from 'components/generals/forms/register/styles';
import { AppState } from 'store';
import { logoutRequest } from 'store/ducks/auth/actions';
import AuthService from 'services/authService';
import { UserState } from 'store/ducks/user/types';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { CHANGE_PASS_SUCCESS } from 'utils/messages';
import { InputRef } from 'types/FormTypes';
import { handleBlockPaste } from 'utils/functions/generals';

const Security: React.FC = () => {
  const styles = useStyles();
  const passwordInput = useRef() as InputRef;
  const newPasswordInput = useRef() as InputRef;
  const repeatNewPasswordInput = useRef() as InputRef;
  const securityStyles = useSecurityStyles();
  const { data: userData } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );
  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, errors, register } = useForm({
    validationSchema,
  });

  const onSubmit = handleSubmit(async ({ password, newPassword }) => {
    const userEmail = userData?.email as string;
    const response = await AuthService.resetPassword(
      userEmail,
      password,
      newPassword,
    );

    if (response.status === StatusCodes.FORBIDDEN)
      dispatch(notifyError(response.data.message));
    else {
      dispatch(notifySuccess(CHANGE_PASS_SUCCESS));
      dispatch(logoutRequest());
      history.replace('/');
    }
  });

  return (
    <Paper
      elevation={0}
      className={`${styles.rootPaper} ${securityStyles.paperContainer}`}
    >
      <Grid container className={securityStyles.paperTitle}>
        <Typography variant="h2" color="textPrimary">
          Alterar
        </Typography>

        <Typography
          variant="h2"
          color="textPrimary"
          className={securityStyles.paperTitleBold}
        >
          senha
        </Typography>
      </Grid>

      <Grid container spacing={2} justify="center">
        <Grid item xs={12} lg={8} className={securityStyles.mb24}>
          <MyOutlinedTextField
            id="password"
            name="password"
            label="Senha atual"
            type="password"
            inputProps={{ ref: passwordInput }}
            changeFocusOnEnter={() => newPasswordInput.current.focus()}
            error={Boolean(errors.password)}
            helperText={errors.password ? errors.password.message : null}
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={8} className={securityStyles.mb24}>
          <MyOutlinedTextField
            id="newPassword"
            name="newPassword"
            label="Nova senha"
            type="password"
            inputProps={{ ref: newPasswordInput }}
            changeFocusOnEnter={() => repeatNewPasswordInput.current.focus()}
            error={Boolean(errors.newPassword)}
            helperText={errors.newPassword ? errors.newPassword.message : null}
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} lg={8}>
          <MyOutlinedTextField
            id="repeatNewPassword"
            name="repeatNewPassword"
            label="Confirmar nova senha"
            type="password"
            inputProps={{ ref: repeatNewPasswordInput }}
            error={Boolean(errors.repeatNewPassword)}
            helperText={
              errors.repeatNewPassword ? errors.repeatNewPassword.message : null
            }
            onPaste={e => handleBlockPaste(e)}
            inputRef={register}
            fullWidth
          />
        </Grid>
        <Grid
          container
          item
          justify="flex-end"
          xs={12}
          lg={8}
          className={securityStyles.mt32}
        >
          <Button
            id="save"
            type="submit"
            variant="contained"
            color="primary"
            className={styles.buttonLabel}
            onClick={onSubmit}
          >
            Salvar senha
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default Security;
