import React, { FC } from 'react';
import { Divider, Grid, IconButton, ListItem } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import MyTypography from 'components/generals/labels/MyTypography';
import MyTooltip from 'components/generals/tolltip';

import { useStyles } from './styles';
import { ItemsListProps } from './types';

const ItemsList: FC<ItemsListProps> = ({ items, deleteAction }) => {
  const styles = useStyles();

  return (
    <ul className={styles.list}>
      {items.map(item => (
        <ListItem key={item.name} className={styles.listItem}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs>
              <MyTypography className={styles.itemNameText}>
                {item.name}
              </MyTypography>
            </Grid>
            <Grid container item xs justify="flex-end">
              <MyTooltip title="Deletar item" enterDelay={800}>
                <IconButton
                  aria-label="Deletar item"
                  onClick={() => deleteAction(item.name)}
                >
                  <CloseIcon />
                </IconButton>
              </MyTooltip>
            </Grid>
          </Grid>
          <Divider />
        </ListItem>
      ))}
    </ul>
  );
};

export default ItemsList;
