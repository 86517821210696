import { call, put } from 'redux-saga/effects';
import BenefitsService from 'services/benefitsService';
import { loadBenefitsSuccess, loadBenefitsFailure } from './action';

export function* loadBenefitsSaga(action: any) {
  try {
    const { data, total } = yield call(
      BenefitsService.getAllBenefits,
      action.payload.query,
    );

    yield put(loadBenefitsSuccess({ total, data }));
  } catch (error) {
    yield put(loadBenefitsFailure());
  }
}
