import React from 'react';
import {
  Grid,
  Typography,
  IconButton,
  DialogTitle,
  DialogContent,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch } from 'react-redux';

import { closeModal } from 'store/ducks/nav/actions';

import { useStyles } from './styles';

const LoadingDotsDialog: React.FC = () => {
  const style = useStyles();
  const dispatch = useDispatch();

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container item justify="flex-end">
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>

      <Grid container className={style.root}>
        <Grid
          container
          item
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <Grid item className={style.points}></Grid>
          <Grid item className={style.points}></Grid>
          <Grid item className={style.points}></Grid>
          <Grid item className={style.points}></Grid>
        </Grid>
        <Grid
          container
          item
          alignContent="center"
          alignItems="center"
          justify="center"
        >
          <Typography className={style.text}>
            Suas informações estão sendo checadas
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default LoadingDotsDialog;
