import React, { useEffect, useState, useCallback } from 'react';
import {
  Grid,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { CondOperator } from '@nestjsx/crud-request';
import clsx from 'clsx';
import { StatusCodes } from 'http-status-codes';
import { useHistory } from 'react-router-dom';

import { AppState } from 'store';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import { RecurrenceState } from 'store/ducks/recurrences/types';
import { PlanState } from 'store/ducks/generals/plan/types';
import { loadAllPlans } from 'store/ducks/generals/plan/actions';
import {
  notifyError,
  notifySuccess,
  disableButton,
  enableButton,
} from 'store/ducks/notification/actions';

import MySelectForm from '../input/MySelectForm';
import ConfirmationDialog from './ConfirmationDialog';

import useStyles from 'components/generals/forms/register/styles';
import { ChangePlan } from './types';
import { changePlanValidationSchema } from './utils';
import { BaseDialogProps, SignatureBulkDialogProps } from './dialogTypes';
import { Plan, PAYMENT_TYPE } from 'types/generals';
import {
  CHANGE_PLAN_SIGNATURE_ERROR,
  BULK_CHANGE_PLAN_MESSAGE,
  BULK_CHANGE_PLAN_ALERT,
  BULK_CHANGE_PLAN_ERROR,
} from 'utils/messages';
import { moneyFormat } from 'pages/client/signature/utils';
import ViewsService from 'services/viewsService';

const BulkChangePlanDialog: React.FC<SignatureBulkDialogProps> = ({
  modalTitle,
  submitText,
  signatureIds,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();

  const [selectedRecurrence, setSelectedRecurrence] = useState(0);
  const [selectedPlan, setSelectedPlan] = useState<Plan>();

  const { handleSubmit, setValue, errors, control } = useForm({
    validationSchema: changePlanValidationSchema,
  });

  const { data: recurrences, isLoading } = useSelector<
    AppState,
    RecurrenceState
  >(({ recurrences }: AppState) => recurrences);

  const { data: plans } = useSelector<AppState, PlanState>(
    ({ plans }: AppState) => plans,
  );

  const handleSelectPlan = (id: number) => {
    const currentPlan = plans.find(plan => plan.id === id);
    if (currentPlan) {
      setSelectedPlan(currentPlan);
    }
  };

  const onSubmit = async (data: ChangePlan) => {
    try {
      dispatch(disableButton());
      const response: any = await ViewsService.bulkChangePlan(
        signatureIds,
        data.planId,
        data.paymentType,
      );

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(BULK_CHANGE_PLAN_MESSAGE));
        history.go(0);
      } else {
        throw new Error(BULK_CHANGE_PLAN_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(enableButton());
      dispatch(closeModal());
    }
  };

  const handleOpenConfirmDialog = (data: ChangePlan) => {
    const modalProps: BaseDialogProps = {
      title: 'Trocando de plano',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: BULK_CHANGE_PLAN_ALERT,
      actionFn: () => onSubmit(data),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleChangeRecurrences = (e: any) => {
    setSelectedRecurrence(e.target.value);
    setSelectedPlan(undefined);
    setValue('planId', '');
    setValue('paymentType', '');
  };

  const handleChangePlan = (e: any) => {
    handleSelectPlan(e.target.value);
    setValue('paymentType', '');
  };

  const loadAvailablePlans = useCallback(() => {
    dispatch(
      loadAllPlans({
        join: [{ field: 'recurrence', select: ['id', 'name'] }],
        filter: {
          field: 'recurrence.id',
          operator: CondOperator.EQUALS,
          value: selectedRecurrence,
        },
        sort: { field: 'createdAt', order: 'DESC' },
      }),
    );
  }, [dispatch, selectedRecurrence]);

  useEffect(() => {
    loadAvailablePlans();
  }, [loadAvailablePlans]);

  return (
    <>
      {!isLoading && recurrences.length ? (
        <Grid container direction="column">
          <DialogTitle>
            <Grid container justify="space-between">
              <Typography variant="h1">{modalTitle}</Typography>
              <IconButton onClick={() => dispatch(closeModal())}>
                <CloseIcon />
              </IconButton>
            </Grid>
          </DialogTitle>

          <DialogContent>
            <Grid container direction="column" spacing={1}>
              <Grid item xs>
                <Controller
                  name="recurrenceId"
                  control={control}
                  defaultValue=""
                  as={({ onChange, onBlur, value }) => (
                    <MySelectForm
                      contracted
                      title="Recorrência da cobrança"
                      itens={recurrences.map(recurrence => ({
                        title: recurrence.name,
                        value: recurrence.id,
                      }))}
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        handleChangeRecurrences(e);
                      }}
                      onBlur={onBlur}
                      value={value}
                      error={Boolean(errors.recurrenceId)}
                      helperText={
                        errors.recurrenceId ? errors.recurrenceId.message : null
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs>
                <Controller
                  name="planId"
                  control={control}
                  defaultValue=""
                  as={({ onChange, onBlur, value }) => (
                    <MySelectForm
                      contracted
                      title="Planos"
                      itens={
                        plans.length
                          ? plans.map(plan => ({
                              title: `${plan.id} - ${plan.name}`,
                              value: plan.id,
                            }))
                          : [{ title: 'Selecione uma recorrência', value: '' }]
                      }
                      onChange={(e: any) => {
                        onChange(e.target.value);
                        handleChangePlan(e);
                      }}
                      onBlur={onBlur}
                      value={value}
                      error={Boolean(errors.planId)}
                      helperText={errors.planId ? errors.planId.message : null}
                    />
                  )}
                />
              </Grid>

              <Grid item xs>
                <Controller
                  name="paymentType"
                  control={control}
                  as={({ value, onChange }) => (
                    <RadioGroup
                      onChange={(e: any) => {
                        onChange(e.target.value);
                      }}
                      value={value || ''}
                      aria-label="payment-option"
                    >
                      {selectedPlan?.paymentType === PAYMENT_TYPE.BANKSLIP ||
                      selectedPlan?.paymentType ===
                        PAYMENT_TYPE.BANKSLIP_CARD ? (
                        <>
                          <FormControlLabel
                            value="boleto"
                            control={<Radio />}
                            label={`Boleto - ${moneyFormat(
                              selectedPlan.prices.bankslipPrice,
                            )}`}
                            className={styles.radioWrapper}
                          />
                        </>
                      ) : null}

                      {selectedPlan?.paymentType === PAYMENT_TYPE.CARD ||
                      selectedPlan?.paymentType ===
                        PAYMENT_TYPE.BANKSLIP_CARD ? (
                        <FormControlLabel
                          value="cartao"
                          control={<Radio />}
                          label={`Cartão de crédito - ${moneyFormat(
                            selectedPlan.prices.cardPrice,
                          )}`}
                          className={styles.radioWrapper}
                        />
                      ) : null}
                    </RadioGroup>
                  )}
                />
                {Boolean(errors.paymentType) && selectedPlan ? (
                  <FormHelperText className={styles.paymentAlert}>
                    Selecione um metodo de pagamento
                  </FormHelperText>
                ) : null}
              </Grid>

              <Grid container item justify="flex-end">
                <Button
                  className={clsx(styles.buttonLabel, styles.buttonWrapper)}
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit(handleOpenConfirmDialog as any)}
                >
                  {submitText}
                </Button>
              </Grid>
            </Grid>
          </DialogContent>
        </Grid>
      ) : null}
    </>
  );
};

export default BulkChangePlanDialog;
