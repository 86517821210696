import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Colors from 'styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loader: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#FFFFFF88',
    },
    typography: {
      color: Colors.BLACK,
      fontWeight: 600,
    },
  }),
);
