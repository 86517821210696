import React from 'react';
import { Grid, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import {
  PaymentNotificationsProps,
  PaymentNotificationsBlockProps,
} from './types';
import NotificationRows from './NotificationRows';

const PaymentNotifications: React.FC<PaymentNotificationsProps> = ({
  storeNotifications,
  signatureNotifications,
  handleOpenEdit,
  handleChangePermissions,
}) => {
  const styles = useStyles();

  const NotificationBlock = ({
    notifications,
    title,
  }: PaymentNotificationsBlockProps) => (
    <>
      <Grid className={styles.notificationBlock}>
        <Typography variant="h2" className={styles.notificationBlockTitle}>
          {title}
        </Typography>
        <NotificationRows
          notifications={notifications}
          handleOpenEdit={handleOpenEdit}
          handleChangePermissions={handleChangePermissions}
        />
      </Grid>
    </>
  );

  return (
    <>
      <Grid className={styles.notificationBlock}>
        <Grid className={styles.notificationBlock}>
          <NotificationBlock
            notifications={storeNotifications}
            title="Notificações de pagamento da Loja"
          />
        </Grid>
        <Grid className={styles.notificationBlock}>
          <NotificationBlock
            notifications={signatureNotifications}
            title="Notificações de pagamento da Assinatura"
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PaymentNotifications;
