import React, { useState, MouseEvent, FC } from 'react';
import { Button, Menu, MenuItem, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useStyles } from './useStyles';
import { MyDropdownProps } from './types';

const ITEM_HEIGHT = 35.5;

const MyDropdown: FC<MyDropdownProps> = ({
  children,
  menuItems,
  buttonProps = {},
  menuProps = {},
  maxItems = 5,
  buttonStyle = null,
  expandMoreIconStyle = null,
  defaultOption,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const menuItemsToShow = menuItems.filter(item => !item.hidden);

  const handleOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleMenuItemAction(action: void) {
    handleClose();
    return action;
  }

  return (
    <>
      <Button
        classes={{ root: buttonStyle }}
        aria-controls="my-dropdown"
        aria-haspopup="true"
        onClick={handleOpen}
        {...buttonProps}
      >
        {children}
        <ExpandMoreIcon
          classes={{ root: expandMoreIconStyle }}
          className={classes.arrowIcon}
        />
      </Button>
      <Menu
        getContentAnchorEl={null}
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * maxItems,
            borderRadius: 13,
          },
        }}
        {...menuProps}
      >
        {defaultOption ? (
          <MenuItem onClick={() => handleMenuItemAction(defaultOption())}>
            <Typography classes={{ root: classes.menuItemText }}>
              Todos
            </Typography>
          </MenuItem>
        ) : null}
        {menuItemsToShow.map((item, index) => (
          <MenuItem
            key={index}
            disabled={item.disabled}
            onClick={() => handleMenuItemAction(item.action())}
          >
            <Typography
              className={item.className}
              classes={{ root: classes.menuItemText }}
            >
              {item.title}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default MyDropdown;
