import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from 'components/generals/forms/register/styles';

import { AppState } from 'store';
import { closeModal } from 'store/ducks/nav/actions';
import { NotificationState } from 'store/ducks/notification/types';

import { useDialogStyles } from './useStyles';
import { BannerLinksDialogProps } from './dialogTypes';
import MyOutlinedTextField from '../input/MyOutlinedTextField';

const BannerLinksDialog: React.FC<BannerLinksDialogProps> = props => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const classes = useDialogStyles();
  // fixme: use inputQuantity to create dynamic N inputs
  const {
    id,
    title,
    inputLinkGoogle,
    inputLinkApple,
    initialValueGoogle = '',
    initialValueApple = '',
    handleAction,
    cancelText,
    confirmText,
  } = props;

  const [linkGoogle, setlinkGoogle] = useState(initialValueGoogle);
  const [linkApple, setLinkApple] = useState(initialValueApple);

  const { buttonIsDisabled } = useSelector<AppState, NotificationState>(
    ({ notification }: AppState) => notification,
  );

  const handleLinkGoogle = (e: any) => {
    setlinkGoogle(e.target.value);
  };
  const handleLinkApple = (e: any) => {
    setLinkApple(e.target.value);
  };

  const handleConfirmAction = () => {
    handleAction({
      linkGoogle,
      linkApple,
    });
    dispatch(closeModal());
  };
  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ marginTop: 8 }} spacing={2} component="form">
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h3"
              color="textPrimary"
              className={classes.info_mb}
            >
              <MyOutlinedTextField
                id={id}
                name="link"
                label={inputLinkGoogle}
                fullWidth
                required
                value={linkGoogle}
                onChange={handleLinkGoogle}
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h3"
              color="textPrimary"
              className={classes.info_mb}
            >
              <MyOutlinedTextField
                id={id}
                name="link"
                label={inputLinkApple}
                fullWidth
                required
                value={linkApple}
                onChange={handleLinkApple}
              />
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={styles.buttonLabel}
          style={{ marginRight: 30 }}
          onClick={() => dispatch(closeModal())}
        >
          {cancelText ?? 'Cancelar'}
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
          onClick={handleConfirmAction}
          disabled={buttonIsDisabled}
        >
          {buttonIsDisabled ? (
            <CircularProgress className={styles.progress} size={20} />
          ) : (
            confirmText ?? 'Confirmar'
          )}
        </Button>
      </DialogActions>
    </Grid>
  );
};

export default BannerLinksDialog;
