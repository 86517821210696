import React, { FC, useCallback, useEffect } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@material-ui/core';
import MyTypography from 'components/generals/labels/MyTypography';
import { useStyles } from '../styles';
import { OrderDetailsProps } from '../types';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types/TableTypes';
import Colors from 'styles/colors';
import { AppState } from 'store';
import { useSelector, useDispatch } from 'react-redux';
import { InvoiceState } from 'store/ducks/invoice/types';
import { loadInvoiceRequest } from 'store/ducks/invoice/actions';
import { CondOperator } from '@nestjsx/crud-request';
import CircularLoading from 'components/generals/loading/CircularLoading';

const UserOrderInvoices: FC<OrderDetailsProps> = ({ order }) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { data: invoices = [], isLoading } = useSelector<
    AppState,
    InvoiceState
  >(({ invoice }: AppState) => invoice);

  const formatPrice = useCallback((amount: string) => {
    return formatColumnValue(FormatOptions.MONEY, amount);
  }, []);

  const openInvoice = (invoiceLink: string) => {
    const win = window.open(invoiceLink, '_blank');
    if (win) win.focus();
  };

  useEffect(() => {
    dispatch(
      loadInvoiceRequest({
        filter: {
          field: 'order.id',
          operator: CondOperator.EQUALS,
          value: order.id,
        },
        join: [['orders']],
      }),
    );
  }, [dispatch, order]);

  return (
    <Grid>
      <MyTypography variant="h3" className={styles.infoTitle}>
        Notas Fiscais do Pedido
      </MyTypography>

      <Paper variant="outlined" className={styles.cardItem}>
        <TableContainer>
          <Table className={styles.table} aria-label="order items table">
            <TableHead>
              <TableRow>
                <TableCell>Número</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            {isLoading ? (
              <CircularLoading
                isLoading={isLoading}
                title={'Carregando notas'}
              />
            ) : (
              <TableBody>
                {invoices &&
                  invoices.map((invoice, key) => (
                    <TableRow key={key}>
                      <TableCell>{invoice.number}</TableCell>
                      <TableCell>
                        <Button onClick={() => openInvoice(invoice.linkDanfe)}>
                          <Typography
                            variant="h4"
                            color="textPrimary"
                            style={{
                              color: Colors.RED,
                              fontWeight: 'bold',
                            }}
                          >
                            Ver nota fiscal
                          </Typography>
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default UserOrderInvoices;
