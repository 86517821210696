import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableBody,
  Button,
} from '@material-ui/core';
import { orderBy } from 'lodash';

import AddObservationSignatureDialog from 'components/generals/dialog/AddObservationSignatureDialog';
import MyTableCell from 'components/generals/table/MyTableCell';
import { SignatureTypeDialogProps } from 'components/generals/dialog/dialogTypes';

import { AppState } from 'store';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { openModal } from 'store/ducks/nav/actions';

import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { useStyles } from './styles';

const Observations: React.FC = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const { data: subscriber, isLoading, hasError } = useSelector<
    AppState,
    SingleSignatureState
  >(({ oneSignature }: AppState) => oneSignature);

  const sigantureObservations = orderBy(
    subscriber.observations,
    ['createdAt'],
    ['desc'],
  );

  const handleOpenObservationFormDialog = () => {
    const modalProps: SignatureTypeDialogProps = {
      modalTitle: 'Adicionar observação',
      submitText: 'Adicionar',
      subscriber,
    };
    dispatch(openModal(AddObservationSignatureDialog, modalProps));
  };

  return (
    <Grid container justify="space-between" direction="column">
      <Grid
        container
        justify="flex-start"
        direction="row"
        spacing={6}
        alignItems="center"
      >
        <Grid
          container
          item
          justify="space-between"
          alignItems="center"
          className={styles.gridTop}
        >
          <Typography
            variant="h1"
            color="textPrimary"
            className={styles.boldText}
          >
            Observações
          </Typography>
          <Button
            onClick={() => handleOpenObservationFormDialog()}
            color="primary"
            variant="text"
          >
            <Typography variant="h4" className={styles.buttonText}>
              Adicionar observação
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid item>
        <Paper className={styles.paperTable} elevation={0}>
          <TableContainer>
            <Table aria-label="orders list">
              <TableHead>
                <TableRow>
                  <MyTableCell>Data</MyTableCell>
                  <MyTableCell>Observação</MyTableCell>
                  <MyTableCell>Usuário</MyTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!isLoading && !hasError && sigantureObservations.length ? (
                  sigantureObservations.map((signatureObservation, key) => (
                    <TableRow key={key}>
                      <MyTableCell>
                        {formatColumnValue(
                          FormatOptions.DATETIME,
                          signatureObservation.createdAt,
                        )}
                      </MyTableCell>
                      <MyTableCell>
                        {signatureObservation.observation}
                      </MyTableCell>

                      <MyTableCell>
                        {signatureObservation.requester}
                      </MyTableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <MyTableCell colSpan={3} align="center">
                      Esta assinatura não possui observação.
                    </MyTableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      </Grid>
    </Grid>
  );
};

export default Observations;
