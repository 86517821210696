import { call, put } from 'redux-saga/effects';
import { loadAllPlpSuccess, loadOnePlpSucess, loadPlpFail } from './actions';
import PlpService from 'services/sigep/plpService';

export function* loadAllPlpSaga(action: any) {
  try {
    const { data, total, count, page, pageCount } = yield call(
      PlpService.getAll,
      action.payload.query,
    );
    yield put(
      loadAllPlpSuccess({
        total,
        data,
        count,
        page,
        pageCount,
      }),
    );
  } catch (err) {
    yield put(loadPlpFail());
  }
}

export function* loadOnePlpSaga(action: any) {
  try {
    const { id, createdAt, updatedAt, name, code, status, orders } = yield call(
      PlpService.getOnePLP,
      action.payload,
    );
    yield put(
      loadOnePlpSucess({
        id,
        createdAt,
        updatedAt,
        name,
        code,
        status,
        orders,
      }),
    );
  } catch (err) {
    yield put(loadPlpFail());
  }
}
