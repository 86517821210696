import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CondOperator } from '@nestjsx/crud-request';
import { Grid, TablePagination } from '@material-ui/core';
import { lastDayOfMonth } from 'date-fns';

import MyInputFilter from 'components/generals/input/MyInputFilter';
import ProductItem from 'components/client/ProductItem';
import { useStyles } from './styles';
import { AppState } from 'store';
import { BoxesState } from 'store/ducks/generals/boxes/types';
import { loadBoxes } from 'store/ducks/generals/boxes/actions';
import { useFilters } from 'hooks/filters';
import { LibraryBox } from './types';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';

const Library: FC = () => {
  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const searchFilter = [
    {
      field: 'name',
      operator: CondOperator.CONTAINS_LOW,
    },
  ];

  const { data: boxes, total, isLoading, hasError } = useSelector<
    AppState,
    BoxesState
  >(({ boxes }: AppState) => boxes);

  // const { data: signature } = useSelector<AppState, SingleSignatureState>(
  //   ({ oneSignature }: AppState) => oneSignature,
  // );

  // const { data: orders } = useSelector<AppState, OrderState>(
  //   ({ ordersListReducer }: AppState) => ordersListReducer,
  // );

  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[1]);
  const [libraryBoxes, setLibraryBoxes] = useState<LibraryBox[]>(boxes);

  const {
    filterFields,
    genericFilterFields,
    handleGenericSearch,
    handleSetValuePage,
    page,
  } = useFilters({});

  /**
   * Recebe todos os pedidos da assinatura, e mapeia, os status dos pedidos, e
   * se o assinante possui o box do pedido.
   *
   * @param signatureOrders Pedidos da assinatura
   */
  // const filterSignatureOrdersOnBoxes = useCallback(
  //   (signatureOrders: Order[]) => {
  //     let signatureBoxes: LibraryBox[] = boxes.map(box => {
  //       return { ...box, owned: false };
  //     });
  //     const signatureOwnedBoxes: LibraryBox[] = [];

  //     if (signatureBoxes.length) {
  //       for (const { orderDetail, status } of signatureOrders) {
  //         if (status === ORDER_STATUS.DELIVERED) {
  //           for (const product of orderDetail.products) {
  //             signatureBoxes
  //               .filter(sigBox => sigBox.sku === product.sku)
  //               .map(sigBox => {
  //                 return signatureOwnedBoxes.push({
  //                   ...sigBox,
  //                   owned: true,
  //                   status,
  //                 });
  //               });
  //           }
  //         }
  //       }

  //       for (const box of signatureBoxes) {
  //         if (!signatureOwnedBoxes.find(ownedBox => ownedBox.sku === box.sku)) {
  //           signatureOwnedBoxes.push(box);
  //         }
  //       }
  //     }
  //     setLibraryBoxes(orderBy(signatureOwnedBoxes, 'startDate', 'desc'));
  //   },
  //   [boxes],
  // );
  /**
   * Filtra todos os pedidos da assinatura
   */
  // const filterAllSignatureOrders = useCallback(() => {
  //   const signatureOrders = orders.filter(
  //     order => order.orderDetail.orderType === ORDER_TYPE.SIGNATURE,
  //   );
  //   filterSignatureOrdersOnBoxes(signatureOrders);
  // }, [filterSignatureOrdersOnBoxes, orders]);

  const loadAllBoxes = useCallback(() => {
    dispatch(
      loadBoxes({
        sort: {
          field: 'startDate',
          order: 'DESC',
        },
        fields: ['image', 'name', 'startDate', 'status'],
        page: page + 1,
        limit: rowsPerPage,
        search: {
          $and: [
            ...filterFields,
            { startDate: { $lte: lastDayOfMonth(new Date()) } },
            { $or: [...genericFilterFields] },
          ],
        },
      }),
    );
  }, [dispatch, filterFields, genericFilterFields, page, rowsPerPage]);

  // const loadAllOrder = useCallback(() => {
  //   dispatch(
  //     loadOrders({
  //       join: [['orderDetail'], ['signature'], ['signature.user']],
  //       sort: [
  //         {
  //           field: 'paymentDate',
  //           order: 'DESC',
  //         },
  //       ],
  //     }),
  //   );
  // }, [dispatch]);

  useEffect(() => {
    loadAllBoxes();
    // loadAllOrder();
  }, [loadAllBoxes]);

  // useEffect(() => {
  //   if (signature) filterAllSignatureOrders();
  // }, [filterAllSignatureOrders, signature]);

  const redirectStorePage = () => {
    history.push('/store');
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchFilter);
    }
  };

  return (
    <Grid container direction="column">
      <Grid item xs md={3} className={styles.mTop}>
        <MyInputFilter asFilter onKeyUp={handleSearch} placeholder={'Buscar'} />
      </Grid>
      <Grid container justify="flex-start" spacing={3} direction="row">
        {!isLoading && !hasError && libraryBoxes
          ? boxes.map((box: LibraryBox) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={box.id}>
                <ProductItem
                  imgSrc={box.image}
                  title={box.name}
                  releaseDate={box.startDate}
                  callbackLink={redirectStorePage}
                  checkOwned
                  owned={false}
                  productOrderStatus={box.status}
                />
              </Grid>
            ))
          : null}
      </Grid>
      <TablePagination
        ActionsComponent={MyTablePaginationActions}
        component="div"
        count={total}
        hidden={!total}
        page={page}
        labelRowsPerPage="Itens por página"
        onChangePage={(_, newPage: number) => handleSetValuePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
          handleSetValuePage(0);
          setRowsPerPage(parseInt(event.target.value, 10));
        }}
        rowsPerPageOptions={ROWS_PER_PAGE}
      />
    </Grid>
  );
};

export default Library;
