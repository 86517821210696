import { Grid } from '@material-ui/core';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import React, { FC, useCallback, useEffect, useRef, useState } from 'react';

import { Autocomplete } from '@material-ui/lab';
import StoreService from 'services/storeService';
import { CondOperator } from '@nestjsx/crud-request';
import { AutoCompleteSearchProps } from './types';

const AutoCompleteSearch: FC<AutoCompleteSearchProps> = ({
  selectAction,
  initialValue,
  errors,
  register,
}) => {
  const searchTimeout = useRef<any>(null);
  const [loaded, setLoaded] = useState(false);
  const [items, setItems] = useState([]);

  const handleSearchProduct = useCallback(
    async (text?: string) => {
      let initialQuery = {};

      if (initialValue && !loaded) {
        initialQuery = { id: { [CondOperator.EQUALS]: initialValue.id } };
        setLoaded(true);
      }
      const response = await StoreService.getStoreProducts({
        search: {
          $and: [
            initialQuery,
            { name: { [CondOperator.CONTAINS_LOW]: text } },
            // fixme: check
            // { sku: { [CondOperator.CONTAINS_LOW]: search } },
          ],
        },
        limit: 10,
      });

      setItems(response.data);
    },
    [initialValue, loaded],
  );

  const handleOnChange = (e: any) => {
    const text = e.target.value;
    clearTimeout(searchTimeout.current);
    searchTimeout.current = setTimeout(() => {
      handleSearchProduct(text);
    }, 800);
  };

  useEffect(() => {
    handleSearchProduct();
  }, [handleSearchProduct]);

  return (
    <Grid item xs>
      <Autocomplete
        id="auto-complete"
        fullWidth
        options={items}
        getOptionLabel={(option: any) => option.name}
        defaultValue={initialValue}
        renderInput={params => (
          <MyOutlinedTextField
            {...params}
            id="prod-id"
            name="product"
            /* label={title} */
            label="Produtos"
            inputRef={register}
            error={Boolean(errors.product)}
            helperText={errors.product ? errors.product.message : null}
            onChange={handleOnChange}
          />
        )}
        onChange={(e: any, newValue: any) => {
          selectAction(newValue?.id);
        }}
      />
    </Grid>
  );
};

export default AutoCompleteSearch;
