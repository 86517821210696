import { BannerImageLocation, BannerType } from 'types/generals/banner';

export enum StoreTags {
  isMaintenance = 'Em manutenção',
  isPromotionalPeriod = 'Em promoção',
}

export interface IFreightConfig {
  isActive: boolean;
  freeFreightFromThisValue: number;
}

export enum ScoreDataStauts {
  reset = 'Resetando',
  adding = 'Adicionando',
}

export interface ScoreData {
  discount: number;
  points: number;
  rankingSeasonId: number | undefined;
  status?: ScoreDataStauts;
}
export interface CartAmountData {
  subTotal: number;
  freight: number;
  discount: number;
  scoreData: ScoreData;
}

export interface StoreSettings {
  id?: string;
  banners: IStoreBanner[];
  mobileBanners: IStoreBanner[];
  seasonBanners: IStoreBanner[];
  promotionPeriods: PromotionPeriods[];
  isActive: boolean;
  tags: StoreTags[];
  freightConfig: IFreightConfig;
  rankingSeasonConfig: IRankingSeasonConfig;
}

export interface ActiveStoreSettings extends StoreSettings {
  isPremium: boolean;
}

export interface IRankingSeasonConfig {
  scoreValue: number;
  showReferralMenu: boolean;
}

export interface StoreSettingsToSave {
  id?: string;
  isActive: boolean;
  tags: StoreTags[];
  bannerDesktop: any[];
  bannerMobile: any[];
  bannerSeason: any[];
  bannersData?: {
    banners: IStoreBanner[];
    mobileBanners: IStoreBanner[];
    seasonBanners: IStoreBanner[];
  };
  promotionPeriods: PromotionPeriods[];
  freightConfig: IFreightConfig;
  rankingSeasonConfig: IRankingSeasonConfig;
}

export interface IStoreBanner {
  id?: string;
  imageLink: string;
  link?: string;
  name?: string;
  redirectLink?: string;
  isBannerActive?: boolean;
  order?: number;
  type?: BannerType;
  location?: BannerImageLocation;
}

export interface PromotionPeriods {
  createdAt?: string;
  endDate: string;
  reason: string;
  startDate: string;
  startHourDate: string;
  endHourDate: string;
}
