import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CondOperator } from '@nestjsx/crud-request';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import {
  actUpdatePageTitle,
  closeModal,
  openModal,
} from 'store/ducks/nav/actions';

import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MyTableCell from 'components/generals/table/MyTableCell';

import { useStyles } from './styles';
import { AppState } from 'store';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { FileState } from 'store/ducks/generals/file/types';
import { ROOT_PATH } from 'services/fileservice';
import { CategoriesState } from 'store/ducks/categories/types';
import { StoreCategory } from 'types/generals';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import {
  loadCategoryFile,
  loadCategoryRequest,
} from 'store/ducks/categories/actions';
import CategoryDialog from 'components/generals/dialog/CategoryDialog';
import StoreCategoriesService from 'services/storeCategoriesService';
import { StatusCodes } from 'http-status-codes';
import {
  REMOVE_CATEGORY_ERROR,
  REMOVE_CATEGORY_MSG,
  REMOVE_CATEGORY_SUCCESS,
} from 'utils/messages';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { useFilters } from 'hooks/filters';

const Categories: FC = () => {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const dispatch = useDispatch();
  const styles = useStyles();

  const { categories, total = 0, isLoading, hasError } = useSelector<
    AppState,
    CategoriesState
  >(({ category }: AppState) => category);

  const { hasError: hasErrorFile } = useSelector<AppState, FileState>(
    ({ file }: AppState) => file,
  );

  const searchObject = [
    {
      field: 'name',
      operator: CondOperator.CONTAINS_LOW,
    },
  ];

  const {
    genericFilterFields,
    handleGenericSearch,
    sort,
    filterFields,
    handleSort,
    setInitialSort,
  } = useFilters({
    isActive: { filterTitle: 'Status', value: undefined },
  });

  const loadAllCategories = useCallback(() => {
    dispatch(
      loadCategoryRequest({
        page: page + 1,
        limit: rowsPerPage,
        sort,
        search: {
          $and: [...filterFields, { $or: [...genericFilterFields] }],
        },
      }),
    );
  }, [dispatch, sort, page, rowsPerPage, filterFields, genericFilterFields]);

  const loadCategoriesXls = useCallback(() => {
    dispatch(
      loadCategoryFile(
        {
          page: page + 1,
          limit: rowsPerPage,
          sort,
        },
        ROOT_PATH.STORE_CATEGORIES,
      ),
    );
  }, [dispatch, sort, page, rowsPerPage]);

  // Effects
  useEffect(() => {
    dispatch(actUpdatePageTitle('Categoria'));
  }, [dispatch]);

  useEffect(() => {
    loadAllCategories();
  }, [loadAllCategories]);

  useEffect(() => {
    setInitialSort({ field: 'priority', order: 'ASC' });
  }, [setInitialSort]);

  const handleOpenDeleteDialog = (category: StoreCategory) => {
    const modalProps: BaseDialogProps = {
      title: 'Excluir categoria',
      message: REMOVE_CATEGORY_MSG(category?.name),
      actionFn: () => handleDelete(category),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleDelete = async (category: StoreCategory) => {
    try {
      const response = await StoreCategoriesService.deleteStoreCategory(
        category.id,
      );

      if (response.status === StatusCodes.OK) {
        dispatch(
          loadCategoryRequest({
            page: 0,
            limit: 10,
          }),
        );
        dispatch(notifySuccess(REMOVE_CATEGORY_SUCCESS));
        dispatch(closeModal());
      } else {
        throw new Error(REMOVE_CATEGORY_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  // FIXME pressing enter don't save
  const handleUpdateCategory = (category: StoreCategory) => {
    const modalProps = {
      title: 'Editar a categoria',
      ...category,
    };
    try {
      dispatch(openModal(CategoryDialog, modalProps));
    } catch (err) {
      throw new Error(err);
    }
  };
  const handleCreateCategory = () => {
    try {
      const modalProps = {
        title: 'Adicionar a categoria',
        id: null,
        name: '',
        isActive: true,
      };
      dispatch(openModal(CategoryDialog, modalProps));
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  const CategoryList = () => (
    <Paper className={styles.tableList} elevation={0}>
      <TableContainer>
        <Table className={styles.table} aria-label="orders table">
          <TableHead>
            <TableRow>
              <MySortableTableCell
                onClick={() => handleSort('name')}
                direction={sort.field === 'name' && sort.order}
              >
                Nome
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('priority')}
                direction={sort.field === 'priority' && sort.order}
              >
                Prioridade
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('isActive')}
                direction={sort.field === 'isActive' && sort.order}
              >
                Ativo
              </MySortableTableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!isLoading &&
              !hasError &&
              categories?.map(category => (
                <TableRow key={category.id}>
                  <MyTableCell
                    className={styles.tableRow}
                    onClick={() => handleUpdateCategory(category)}
                  >
                    {category.name || '-'}
                  </MyTableCell>
                  <MyTableCell>{category.priority || '-'}</MyTableCell>
                  <MyTableCell>{category.isActive ? 'Sim' : 'Não'}</MyTableCell>
                  <TableCell className={styles.tabOptions}>
                    <Grid container alignItems="center">
                      <Button
                        className={styles.circle}
                        onClick={() => handleOpenDeleteDialog(category)}
                      >
                        <DeleteOutlinedIcon className={styles.icon} />
                      </Button>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))) ||
              null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  return (
    <Grid container direction="column">
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={3}
        style={{ marginTop: 20, marginBottom: 10 }}
      >
        <Grid item xs={3} sm={3} md={3}>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
        </Grid>
        <Grid container item xs alignItems="center" justify="flex-end">
          <Grid item xs={2} xl={2}>
            <ActionsButton
              hasErrorFile={hasErrorFile}
              menuItems={[
                {
                  title: 'Adicionar',
                  action: handleCreateCategory,
                },
                {
                  title: 'Exportar planilha de categorias',
                  action: loadCategoriesXls,
                },
              ]}
              buttonText="Ações"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <CategoryList />
      </Grid>
      <TablePagination
        ActionsComponent={MyTablePaginationActions}
        component="div"
        count={total}
        page={page}
        labelRowsPerPage="Itens por página"
        onChangePage={(_event: unknown, newPage: number) => setPage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
          setPage(0);
          setRowsPerPage(parseInt(event.target.value, 10));
        }}
        rowsPerPageOptions={ROWS_PER_PAGE}
      />
    </Grid>
  );
};

export default Categories;
