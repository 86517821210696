import { call, put } from 'redux-saga/effects';
import TransportService from 'services/transportService';
import {
  loadTransportCoverageFail,
  loadTransportCoverageSuccess,
} from './actions';

export function* loadTransportCoverageSaga(action: any) {
  try {
    const { data } = yield call(
      TransportService.getTransportCoverageThroughCEP,
      action.payload.zipcode,
    );
    yield put(loadTransportCoverageSuccess(data));
  } catch (error) {
    yield put(loadTransportCoverageFail());
  }
}
