import React from 'react';
import Switch, { SwitchProps } from '@material-ui/core/Switch';
import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

const useStyles = makeStyles(theme => ({
  root: {
    width: 59,
    height: 28,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBase: {
    padding: 2,
    '&$checked': {
      transform: 'translateX(32px)',
      color: Colors.ORANGE,
      '& + $track': {
        backgroundColor: Colors.LIGHT_GRAY,
        opacity: 1,
        border: 'none',
      },
    },
    '&$focusVisible $thumb': {
      color: theme.palette.primary,
      border: '2px solid #fff',
    },
  },
  thumb: {
    width: 24,
    height: 24,
    boxShadow: 'none',
  },
  track: {
    borderRadius: 16,
    // border: `1px solid ${theme.palette.grey[400]}`,
    backgroundColor: Colors.LIGHT_GRAY,
    opacity: 1,
    // transition: theme.transitions.create(['background-color', 'border']),
  },
  checked: {},
  focusVisible: {},
}));

const MySwitch: React.FC<SwitchProps> = props => {
  const classes = useStyles();
  return (
    <Switch
      {...props}
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
    />
  );
};

export default MySwitch;
