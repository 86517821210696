import React, { useEffect, useState, FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';

import { LayoutPropType } from './types';
import LayoutStore from './LayoutStore';
import LayoutClient from './LayoutClient';
import { STORE_PATH } from 'types/generals/storePaths';
import { AppState } from 'store';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import { useSelector } from 'react-redux';

const Layout: FC<LayoutPropType> = ({ children }) => {
  const [isStore, setIsStore] = useState<boolean>();
  const { pathname } = useLocation();
  const { isMaintenance } = useSelector<AppState, StoreSettingsState>(
    ({ storeSettings }: AppState) => storeSettings,
  );
 
  useEffect(() => {
    setIsStore(pathname.includes(STORE_PATH(isMaintenance)));
  }, [pathname, isMaintenance]);

  return (
    <>
      {isStore ? (
        <LayoutStore children={children} />
      ) : (
        <LayoutClient children={children} />
      )}
    </>
  );
};

export default Layout;
