import { action } from 'typesafe-actions';
import { StoreSettingsTypes } from './types';
import { StoreSettings } from 'types/client';
import StoreSettingsService from 'services/storeSettingsService';

export const loadStoreSettings = () =>
  action(StoreSettingsTypes.REQUEST_STORE_SETTINGS);

export const loadStoreSettingsDone = (payload: StoreSettings[]) =>
  action(StoreSettingsTypes.LOAD_STORE_SETTINGS_SUCCESS, payload);

export const loadActiveStoreSettings = () =>
  action(StoreSettingsTypes.REQUEST_ACTIVE_STORE_SETTINGS);

export const loadActiveStoreSettingsDone = (payload: StoreSettings) =>
  action(StoreSettingsTypes.LOAD_ACTIVE_STORE_SETTINGS_SUCCESS, payload);

export const loadFailStoreSettings = () =>
  action(StoreSettingsTypes.FAIL_LOAD_STORE_SETTINGS);

export const setHasPromotion = (hasPromotion: boolean) =>
  action(StoreSettingsTypes.SET_HAS_PROMOTION, { hasPromotion });

export const setIsPromotionalPeriod = (isPromotionalPeriod: boolean) =>
  action(StoreSettingsTypes.SET_IS_PROMOTIONAL_PERIOD, { isPromotionalPeriod });

export const setIsMaintenance = (isMaintenance: boolean) =>
  action(StoreSettingsTypes.SET_IS_MAINTENANCE, { isMaintenance });

export const loadVerifyHasPromotion = (signatureId: number) =>
  action(StoreSettingsTypes.LOAD_VERIFY_HAS_PROMOTION, { signatureId });

export const initStoreSettingstRequest = () => {
  return action(StoreSettingsTypes.INIT_REQUEST);
};
export const endStoreSettingsRequest = () => {
  return action(StoreSettingsTypes.END_REQUEST);
};
