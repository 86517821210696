import { Reducer } from 'redux';
import { Order } from 'types/generals';
import { OrderOneState, OrderTypes, UserOrderTypes } from './types';

const INITIAL_STATE: OrderOneState = {
  data: {} as Order,
  hasError: false,
  isLoading: true,
};

const orderReducer: Reducer<OrderOneState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case OrderTypes.LOAD_ONE_ORDER_REQUEST:
    case UserOrderTypes.LOAD_USER_ORDER:
      return { ...state, isLoading: true };
    case OrderTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, hasError: true };
    case OrderTypes.LOAD_ONE_ORDER_SUCCESS:
    case UserOrderTypes.LOAD_USER_ORDER_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default orderReducer;
