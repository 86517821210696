import React, { FC } from 'react';

import TagWrapper from '..';
import { ORDER_STATUS, SIGNATURE_STATUS } from 'types/generals';
import {
  StatusTagProps,
  SignatureStatusTagProps,
  OrderCotationStatusTagProps,
} from './types';
import { DEFINED_TAGS } from '../types';
import { ORDER_STATUS_COTATION } from 'types/generals';
import Colors from 'styles/colors';

const StatusTag: FC<StatusTagProps> = ({ status }) => {
  switch (status) {
    case ORDER_STATUS.FAILED:
      return <TagWrapper config={DEFINED_TAGS.ERROR} value={status} />;
    case ORDER_STATUS.SUBMITTED:
    case ORDER_STATUS.PENDING:
      return <TagWrapper config={DEFINED_TAGS.INFO} value={status} />;
    case ORDER_STATUS.PAID:
    case ORDER_STATUS.IN_DIVISION:
      return <TagWrapper config={DEFINED_TAGS.MESSAGE} value={status} />;
    case ORDER_STATUS.PENDING_CANCELED:
    case ORDER_STATUS.CANCELED:
      return <TagWrapper config={DEFINED_TAGS.DEFAULT} value={status} />;
    default:
      return <TagWrapper config={DEFINED_TAGS.STATUS} value={status} />;
  }
};

const SignatureStatusTag: FC<SignatureStatusTagProps> = ({ status }) => {
  switch (status) {
    case SIGNATURE_STATUS.INACTIVE:
      return <TagWrapper config={DEFINED_TAGS.INFO} value={status} />;
    case SIGNATURE_STATUS.CANCELED:
      return <TagWrapper config={DEFINED_TAGS.ERROR} value={status} />;
    case SIGNATURE_STATUS.PENDING_CANCELED:
      return <TagWrapper config={DEFINED_TAGS.DEFAULT} value={status} />;
    default:
      return <TagWrapper config={DEFINED_TAGS.STATUS} value={status} />;
  }
};

const StatusCotationTag: FC<OrderCotationStatusTagProps> = ({ status }) => {
  switch (status) {
    case ORDER_STATUS_COTATION.QUOTING:
      return <TagWrapper config={DEFINED_TAGS.INFO} value={status} />;
    case ORDER_STATUS_COTATION.QUOTATION_MADE:
      return <TagWrapper config={DEFINED_TAGS.STATUS} value={status} />;
    case ORDER_STATUS_COTATION.QUOTE_ERROR:
      return <TagWrapper config={DEFINED_TAGS.ERROR} value={status} />;
    case ORDER_STATUS_COTATION.QUOTATION_MADE_IN_STORE:
      return (
        <TagWrapper
          config={{
            tagBackground: Colors.DARK_BLUE,
            tagColor: Colors.DARKER_GRAY,
          }}
          value={status}
        />
      );
    default:
      return <TagWrapper config={DEFINED_TAGS.MESSAGE} value={status} />;
  }
};

export { StatusTag, SignatureStatusTag, StatusCotationTag };
