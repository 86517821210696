import React, { FC } from 'react';
import { Drawer } from '@material-ui/core';

import { MyGenericDrawerProps } from './types';
import { useStyles } from './styles';

const MyGenericDrawer: FC<MyGenericDrawerProps> = ({
  opened,
  setOpened,
  children,
}) => {
  const styles = useStyles();

  const toggleDrawer = (event: KeyboardEvent | MouseEvent) => {
    if (
      event.type === 'keydown' &&
      ((event as KeyboardEvent).key === 'Tab' ||
        (event as KeyboardEvent).key === 'Shift')
    ) {
      return;
    }
    setOpened(false);
  };

  return (
    <Drawer
      className={styles.drawer}
      anchor="right"
      open={opened}
      onClose={toggleDrawer}
      classes={{
        paper: styles.drawerPaper,
      }}
    >
      {children}
    </Drawer>
  );
};

export default MyGenericDrawer;
