import React, { FC, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Typography } from '@material-ui/core';

import { AppState } from 'store';
import { CartState } from 'store/ducks/cart/types';
import {
  applyCartDiscount,
  applyScoreDiscount,
  updateCartAmount,
} from 'store/ducks/cart/actions';
import { UserState } from 'store/ducks/user/types';
import { notifyError } from 'store/ducks/notification/actions';
import { ScoreSlider, useStyles } from '../../styles';
import { getDiscountValue } from 'store/ducks/cart';
import { RankingState } from 'store/ducks/ranking/types';
import { INVALID_RANKING_POINTS_NUMBER } from 'utils/messages/rankingSeason';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import { ScoreDataStauts } from 'types/client';

const StoreScoreSlider: FC = () => {
  const dispatch = useDispatch();
  const styles = useStyles();

  const { subTotal, productsDiscount, cartCoupon, scoreData } = useSelector<
    AppState,
    CartState
  >(({ cartReducer }: AppState) => cartReducer);

  const [usedScore, setUsedScore] = useState(scoreData?.points || 0);

  const { activeRanking } = useSelector<AppState, RankingState>(
    ({ ranking }: AppState) => ranking,
  );

  const { wallet } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const { activeSettings } = useSelector<AppState, StoreSettingsState>(
    ({ storeSettings }: AppState) => storeSettings,
  );

  const maxScoreValue = useMemo(() => {
    if (wallet) {
      const minValue = 5;
      const couponDiscount = getDiscountValue(
        productsDiscount,
        subTotal,
        cartCoupon,
      );
      const usableSubTotal = subTotal - minValue - couponDiscount;
      const _score = activeSettings?.rankingSeasonConfig?.scoreValue;
      let maxScore = usableSubTotal / _score;
      maxScore = maxScore >= 0 ? maxScore : 0;
      const maxScoreValueReturn =
        wallet && maxScore > wallet.score ? wallet.score : maxScore;
      return Math.floor(maxScoreValueReturn);
    }
    return 0;
  }, [activeSettings, cartCoupon, productsDiscount, subTotal, wallet]);

  const showScoreWarning = useMemo(
    () =>
      wallet && usedScore === maxScoreValue && maxScoreValue < wallet?.score,
    [maxScoreValue, usedScore, wallet],
  );

  const handleChangeUsedScore = (e: any, val: any) => {
    setUsedScore(val);
  };

  const handleValidateScore = async () => {
    if (wallet && activeRanking) {
      const _points = Number(usedScore);
      const isValid = _points <= wallet?.score;
      if (isValid) {
        const scoreValue = activeSettings?.rankingSeasonConfig?.scoreValue || 0;
        const scoreDiscount = Number(_points) * scoreValue;
        const couponDiscount = getDiscountValue(
          productsDiscount,
          subTotal,
          cartCoupon,
        );
        const finalDiscount = couponDiscount + scoreDiscount;
        dispatch(
          applyCartDiscount({
            discount: finalDiscount,
          }),
        );

        dispatch(
          applyScoreDiscount({
            discount: scoreDiscount,
            points: _points,
            rankingSeasonId: activeRanking.id,
            status: ScoreDataStauts.adding,
          }),
        );

        dispatch(updateCartAmount());
      } else {
        dispatch(notifyError(INVALID_RANKING_POINTS_NUMBER));
      }
    }
  };

  useEffect(() => {
    if (
      scoreData.status === ScoreDataStauts.reset &&
      scoreData.points !== usedScore
    ) {
      setUsedScore(0);
      dispatch(
        applyScoreDiscount({
          discount: 0,
          points: 0,
          rankingSeasonId: 0,
          status: ScoreDataStauts.adding,
        }),
      );
    }
  }, [dispatch, scoreData, usedScore]);

  return (
    <>
      {wallet?.score && activeSettings?.rankingSeasonConfig?.scoreValue ? (
        <Grid container justify="space-between" direction="column" spacing={1}>
          <Grid item xs={12}>
            <Typography
              variant="h4"
              color="textSecondary"
              className={styles.keepBtnText}
            >
              Usar pontos
            </Typography>
            <ScoreSlider
              valueLabelDisplay="auto"
              aria-label="pretto slider"
              defaultValue={usedScore}
              value={usedScore}
              max={maxScoreValue}
              disabled={maxScoreValue === 0}
              onChangeCommitted={handleValidateScore}
              onChange={handleChangeUsedScore}
            />
            {showScoreWarning ? (
              <Typography
                variant="h4"
                color="primary"
                className={styles.scoreWarningText}
              >
                O valor mínimo de pagamento em produtos é R$ 5,00
              </Typography>
            ) : null}
            <Typography
              variant="h4"
              color="textSecondary"
              className={styles.keepBtnText}
            >
              Total de pontos: {wallet?.score}
            </Typography>
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </>
  );
};

export default StoreScoreSlider;
