import React from 'react';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  FormControlLabel,
  Radio,
  RadioGroup,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { StatusCodes } from 'http-status-codes';
import CloseIcon from '@material-ui/icons/Close';

import { closeModal, openModal } from 'store/ducks/nav/actions';
import useStyles from 'components/generals/forms/register/styles';
import { PAYMENT_TYPE } from 'types/generals';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { paymentValidationSchema } from './utils';
import { PaymentTypeDialogProps, BaseDialogProps } from './dialogTypes';
import {
  CHANGE_PAYMENT_TYPE_SUCCESS,
  CHANGE_PAYMENT_TYPE_CONFIRM,
  CHANGE_PAYMENT_TYPE_CLIENT_CONFIRM,
} from 'utils/messages';
import SignatureService from 'services/signatureService';
import ConfirmationDialog from './ConfirmationDialog';

const PaymentTypeDialog: React.FC<PaymentTypeDialogProps> = ({
  modalTitle,
  cancelText,
  cancelAction,
  submitText,
  subscriber,
  defaultPaymentType,
  isAdmin = true,
  confirmAction,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  const { handleSubmit, control, errors, watch } = useForm({
    validationSchema: paymentValidationSchema,
    defaultValues: {
      paymentType: defaultPaymentType,
    },
  });

  const paymentType = watch('paymentType');

  const onSubmit = async () => {
    try {
      const response = await SignatureService.changePaymentTypeSignature(
        subscriber.id,
        paymentType,
        isAdmin,
      );
      if (response.status === StatusCodes.OK) {
        dispatch(notifySuccess(CHANGE_PAYMENT_TYPE_SUCCESS));
        dispatch(closeModal());
        if (isAdmin) {
          history.push(`/admin/subscriptions/${subscriber.id}`, {
            subscriber: { ...subscriber, paymentType },
          });
        } else {
          history.go(0);
        }
      } else {
        throw new Error();
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleOpenConfirmDialog = () => {
    const messageFunction = isAdmin
      ? CHANGE_PAYMENT_TYPE_CONFIRM
      : CHANGE_PAYMENT_TYPE_CLIENT_CONFIRM;

    const modalProps: BaseDialogProps = {
      title: 'Alterar método de pagameto',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: messageFunction(subscriber.paymentType, paymentType),
      actionFn: () => onSubmit(),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {modalTitle}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <Controller
              name="paymentType"
              control={control}
              as={({ value, onChange }) => (
                <RadioGroup
                  onChange={event => onChange(event.target.value)}
                  value={value || ''}
                  aria-label="payment-options"
                >
                  <FormControlLabel
                    value={PAYMENT_TYPE.CARD}
                    control={<Radio />}
                    label="Pagar com cartão"
                    className={styles.radioWrapper}
                  />
                  <FormControlLabel
                    value={PAYMENT_TYPE.BANKSLIP}
                    control={<Radio />}
                    label="Pagar com boleto"
                    className={styles.radioWrapper}
                  />
                  {errors && errors['paymentType'] ? (
                    <Typography id="payment-type-required-error" color="error">
                      {errors['paymentType'].message}
                    </Typography>
                  ) : null}
                </RadioGroup>
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={2}
        className={styles.actionsWrapper}
      >
        <DialogActions>
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={cancelAction || (() => dispatch(closeModal()))}
          >
            {cancelText ?? 'Cancelar'}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
            onClick={handleSubmit(handleOpenConfirmDialog)}
          >
            {submitText}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default PaymentTypeDialog;
