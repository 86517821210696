import { Plan } from './plan';

export interface BoxItem {
  id?: number;
  createdAt?: string;
  updatedAt?: string;
  name: string;
}

export const MAX_PREVIOUS_BOXES_HOME = 4;

export interface Box {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  image: string;
  weight: string;
  startDate: string;
  endDate: string;
  itens: BoxItem[];
  plans: Plan[];
  quantityItems: number;
  sku: string;
  isReveal: boolean;
}
