import React, { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
  Grid,
  Typography,
  Paper,
  Button,
  FormControlLabel,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import clsx from 'clsx';

import useFormStyles from 'components/generals/forms/register/styles';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import MySelectForm from 'components/generals/input/MySelectForm';
import MyNumberFormat from 'components/generals/input/MyNumberFormat';
import ImagesZone from 'components/admin/ImagesZone/index';
import { ImageZone } from 'components/admin/ImagesZone/types';
import MySwitch from 'components/generals/input/MySwitch';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import PlanService from 'services/planService';
import { useGetWithSWR } from 'services/apiService';
import { PAYMENT_TYPES } from 'helpers/planConstants';
import { Recurrence, Plan } from 'types/generals';
import { validationSchema } from './utils';
import { useStyles } from './styles';
import {
  UPDATE_PLAN_SUCCESS,
  UPDATE_PLAN_ERROR,
  CREATE_PLAN_SUCCESS,
  CREATE_PLAN_ERROR,
  PLAN_CANT_USE_RECURRENCE,
} from 'utils/messages';

const PlanPage = (props: any) => {
  const dispatch = useDispatch();
  const formStyles = useFormStyles();
  const styles = useStyles();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.between('xs', 'sm'));
  const isPrivate = props.location.state
    ? props.location.state.plan.isPrivate
    : false;
  const [planId, setPlanId] = useState<number>(0);
  const [isPrivatePlan, setIsPrivatePlan] = useState<boolean>(isPrivate);
  const [isDonation, setIsDonation] = useState<boolean>(false);
  const [stepsDisabled, setStepsDisabled] = useState<boolean>(false);
  const [disabledButton, setDisabledButton] = useState<boolean>(false);
  const [recurrences, setRecurrences] = useState([]);
  const [files, setFiles] = useState<ImageZone[]>([]);

  const history = useHistory();
  const { data: recurrencesResources } = useGetWithSWR('/recurrences', {
    sort: { field: 'months', order: 'ASC' },
  });
  const donationRecurrences = [2, 24, 38];
  useEffect(() => {
    if (recurrencesResources) {
      const MyRecurrences = recurrencesResources.data.map(
        (rec: Recurrence) => ({
          ...rec,
          title: rec.name,
          value: rec.id,
        }),
      );
      setRecurrences(MyRecurrences);
    }
  }, [recurrencesResources]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Novo Plano'));
  }, [dispatch]);

  const { handleSubmit, setValue, register, errors, reset, control, watch } =
    useForm({
      validationSchema,
    });

  useEffect(() => {
    const { plan } = props.location.state || {};
    if (plan) {
      dispatch(actUpdatePageTitle(`Editar Plano - ${plan.name}`));
      setIsDonation(plan.isDonation);
      setPlanId(plan.id);
      const paymentType = PAYMENT_TYPES.find(
        pay => pay.value === plan.paymentType,
      )?.value;

      if (plan.image) {
        setFiles([{ image: plan.image, isCover: false }]);
      }

      setValue(
        'bankslipPrice',
        plan.prices?.bankslipPrice > 0 ? plan.prices?.bankslipPrice : null,
      );
      setValue(
        'bankslipDiscount',
        plan.discounts?.bankslipDiscount > 0
          ? plan.discounts?.bankslipDiscount
          : null,
      );

      setValue(
        'cardPrice',
        plan.prices?.cardPrice > 0 ? plan.prices?.cardPrice : null,
      );
      setValue(
        'cardDiscount',
        plan.discounts?.cardDiscount > 0 ? plan.discounts?.cardDiscount : null,
      );

      setStepsDisabled(plan.stepsDisabled);

      reset({
        ...plan,
        recurrenceId: plan.recurrence.id,
        paymentType: paymentType,
      });
    }
  }, [dispatch, reset, props.location, history, setValue]);

  const handleRedirectPlans = () => {
    reset();
    history.push('/admin/plans');
  };

  const handleOnSubmit = ({ recurrenceId, ...data }: any) => {
    const plan: Plan = {
      ...data,
      isPrivate: isPrivatePlan,
      isDonation,
      recurrence: recurrenceId,
      stepsDisabled: stepsDisabled,
    };

    if (!isDonation && recurrences.length) {
      const currentRecurrence: any = recurrences.find(
        (rec: Recurrence) => rec.id === recurrenceId,
      );
      if (donationRecurrences.includes(currentRecurrence.months)) {
        dispatch(notifyError(PLAN_CANT_USE_RECURRENCE));
        return;
      }
    }

    if (planId) {
      updatePlan(plan);
    } else {
      createPlan(plan);
    }
  };

  const updatePlan = async (data: any) => {
    try {
      setDisabledButton(true);

      const image = watch('images');
      const response = await PlanService.updatePlan(data, planId, image);

      if (response?.status === StatusCodes.OK) {
        dispatch(notifySuccess(UPDATE_PLAN_SUCCESS));
        handleRedirectPlans();
      } else {
        throw new Error(UPDATE_PLAN_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      setDisabledButton(false);
    }
  };

  const createPlan = async (plan: any) => {
    try {
      setDisabledButton(true);
      const image = watch('images');

      const data = await PlanService.createPlan(plan, image);

      if (data?.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(CREATE_PLAN_SUCCESS));
        handleRedirectPlans();
      } else {
        throw new Error(CREATE_PLAN_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      setDisabledButton(false);
    }
  };

  return (
    <Grid
      container
      direction="column"
      style={{ marginTop: 20, marginBottom: 10 }}
    >
      <form onSubmit={handleSubmit(handleOnSubmit)}>
        <Typography
          variant="h2"
          color="textPrimary"
          style={{ fontWeight: 'bold' }}
        >
          Informações principais
        </Typography>

        <Paper elevation={0} className={formStyles.rootPaper}>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4}>
              <MyOutlinedTextField
                id="plan-name"
                name="name"
                label="Nome do plano"
                fullWidth
                error={Boolean(errors.name)}
                helperText={errors.name ? errors.name.message : null}
                inputRef={register}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <MyOutlinedTextField
                id="plan-sku"
                name="sku"
                label="SKU"
                fullWidth
                error={Boolean(errors.sku)}
                helperText={errors.sku ? errors.sku.message : null}
                inputRef={register}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="paymentType"
                control={control}
                defaultValue=""
                as={({ onChange, onBlur, value }) => (
                  <MySelectForm
                    contracted
                    title="Forma de pagamento"
                    itens={PAYMENT_TYPES}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={Boolean(errors.paymentType)}
                    helperText={
                      errors.paymentType ? errors.paymentType.message : null
                    }
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={6}>
              <Controller
                name="recurrenceId"
                control={control}
                defaultValue=""
                as={({ onChange, onBlur, value }) => (
                  <MySelectForm
                    contracted
                    title="Recorrência da cobrança"
                    itens={recurrences}
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    error={Boolean(errors.recurrenceId)}
                    helperText={
                      errors.recurrenceId ? errors.recurrenceId.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="bankslipPrice"
                control={control}
                as={({ value, onChange }) => (
                  <MyOutlinedTextField
                    id="plan-bankslipPrice"
                    label="Valor no boleto (Atual)"
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      inputComponent: MyNumberFormat as any,
                    }}
                    fullWidth
                    error={Boolean(errors.bankslipPrice)}
                    helperText={
                      errors.bankslipPrice ? errors.bankslipPrice.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs={6} md={3}>
              <Controller
                name="cardPrice"
                control={control}
                as={({ value, onChange }) => (
                  <MyOutlinedTextField
                    id="plan-cardPrice"
                    label="Valor no cartão (Atual)"
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      inputComponent: MyNumberFormat as any,
                    }}
                    fullWidth
                    error={Boolean(errors.cardPrice)}
                    helperText={
                      errors.cardPrice ? errors.cardPrice.message : null
                    }
                  />
                )}
              />
            </Grid>

            <Grid container item xs={12} md={6} alignContent="flex-start">
              <Grid item xs={12}>
                <MyOutlinedTextField
                  id="plan-description"
                  name="description"
                  label="Descrição"
                  multiline
                  fullWidth
                  rows={5}
                  inputRef={register}
                  error={Boolean(errors.description)}
                  helperText={
                    errors.description ? errors.description.message : null
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <ImagesZone
                  showTitle={false}
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  multiple={false}
                  files={files}
                  maxFiles={1}
                />
              </Grid>
            </Grid>

            <Grid container item xs={12} md={6} alignContent="flex-start">
              <Grid
                item
                xs={12}
                md={6}
                className={smallSize ? styles.gridMargin : styles.rightMargin}
              >
                <Controller
                  name="bankslipDiscount"
                  control={control}
                  as={({ value, onChange }) => (
                    <MyOutlinedTextField
                      id="plan-bankslipDiscount"
                      label="Valor no boleto (Antigo)"
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        inputComponent: MyNumberFormat as any,
                      }}
                      fullWidth
                      error={Boolean(errors.bankslipDiscount)}
                      helperText={
                        errors.bankslipDiscount
                          ? errors.bankslipDiscount.message
                          : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={6}
                className={
                  smallSize
                    ? styles.gridMargin
                    : clsx(styles.leftMargin, styles.bottomMargin)
                }
              >
                <Controller
                  name="cardDiscount"
                  control={control}
                  as={({ value, onChange }) => (
                    <MyOutlinedTextField
                      id="plan-cardDiscount"
                      label="Valor no cartão (Antigo)"
                      value={value}
                      onChange={onChange}
                      InputProps={{
                        inputComponent: MyNumberFormat as any,
                      }}
                      fullWidth
                      error={Boolean(errors.cardDiscount)}
                      helperText={
                        errors.cardDiscount ? errors.cardDiscount.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} className={styles.gridMargin}>
                <MyOutlinedTextField
                  id="message"
                  name="message"
                  label="Mensagem no checkout (20 caracteres)"
                  fullWidth
                  inputRef={register}
                  error={Boolean(errors.message)}
                  helperText={errors.message ? errors.message.message : null}
                />
              </Grid>
              <Grid item xs={12} className={styles.gridMargin}>
                <MyOutlinedTextField
                  id="success-page"
                  name="successPage"
                  label="Página de sucesso"
                  fullWidth
                  inputRef={register}
                />
              </Grid>

              <Grid item xs={12} className={styles.gridMargin}>
                <MyOutlinedTextField
                  id="action-page"
                  name="actionPage"
                  label="Página de upgrade/reativação"
                  fullWidth
                  inputRef={register}
                />
              </Grid>

              {planId ? (
                <Grid item xs={12} className={styles.gridMargin}>
                  <MyOutlinedTextField
                    id="plan-hash"
                    name="hash"
                    label="Link do plano"
                    fullWidth
                    disabled={true}
                    inputRef={register}
                  />
                </Grid>
              ) : (
                ''
              )}
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <FormControlLabel
              className={styles.mTop}
              name="isPrivatePlan"
              control={
                <MySwitch
                  name="isPrivatePlan"
                  color="primary"
                  checked={isPrivatePlan}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setIsPrivatePlan(e.target.checked)
                  }
                />
              }
              label="Plano privado"
              labelPlacement="start"
            />
            <FormControlLabel
              className={styles.mTop}
              name="stepsDisabled"
              control={
                <MySwitch
                  name="stepsDisabled"
                  color="primary"
                  checked={stepsDisabled}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setStepsDisabled(e.target.checked)
                  }
                />
              }
              label="Iniciar com step desabilitado"
              labelPlacement="start"
            />

            <FormControlLabel
              className={styles.mTop}
              name="isDonation"
              control={
                <MySwitch
                  name="isDonation"
                  color="primary"
                  checked={isDonation}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setIsDonation(e.target.checked)
                  }
                />
              }
              label="Plano para doação"
              labelPlacement="start"
            />
          </Grid>
          <Grid
            container
            direction="row"
            justify="flex-end"
            alignItems="center"
            spacing={2}
            className={formStyles.actionsWrapper}
          >
            <Button
              color="primary"
              className={formStyles.buttonLabel}
              style={{ marginRight: 30 }}
              onClick={() => handleRedirectPlans()}
            >
              Cancelar
            </Button>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={clsx(formStyles.buttonLabel, formStyles.buttonWrapper)}
              disabled={disabledButton}
            >
              Salvar
            </Button>
          </Grid>
        </Paper>
      </form>
    </Grid>
  );
};

export default PlanPage;
