import { CreateQueryParams } from '@nestjsx/crud-request';
import { Address } from 'types';
import { api, getWithSaga } from './apiService';

export default class AddressService {
  static async getAllAddress(query: CreateQueryParams) {
    try {
      const data = await getWithSaga('addresses', query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async createNewAddress(address: Address) {
    try {
      const data = await api.post('addresses', address);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async lookupAddress(zipcode: string) {
    try {
      const { data } = await api.get(`addresses/zipcode/${zipcode}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updateAddress(id: number, address: Partial<Address>) {
    try {
      const { data } = await api.patch(`addresses/${id}`, address);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async deleteAddress(id: number) {
    try {
      const response = await api.delete(`addresses/${id}`);
      return response;
    } catch (error) {
      return error;
    }
  }
}
