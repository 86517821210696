import React, { FC } from 'react';
import { Tooltip, TooltipProps } from '@material-ui/core';

const MyTooltip: FC<TooltipProps> = ({ children, ...myTooltipProps }) => {
  return (
    <Tooltip arrow {...myTooltipProps}>
      {children}
    </Tooltip>
  );
};

export default MyTooltip;
