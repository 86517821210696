import React, { FC, useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import {
  CardActions,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';

// import StoreIcon from 'assets/img/svg/menu-icons/icon_store_active.svg';
import TagWrapper from 'components/generals/tag';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { useStyles } from '../styles';
import { CardsProps } from '../types';

const ContractedCard: FC<CardsProps> = ({ cardData }) => {
  const { boxTitle, deliveryDate, imgPath, tag, owned } = cardData;
  const history = useHistory();
  const classes = useStyles();

  const handleRedirectToLibrary = () => history.push('/library');

  const handleFormatDate = useCallback((date: string) => {
    return formatColumnValue(FormatOptions.START_DATE, date);
  }, []);

  return (
    <>
      <Grid item xs className={clsx(classes.selfCenter, classes.container)}>
        <CardMedia
          className={
            owned
              ? classes.smCardImg
              : clsx(classes.smCardImg, classes.ownedProductImage)
          }
          image={imgPath}
          component="img"
          alt={`Imagem do box ${boxTitle}`}
          onClick={() => handleRedirectToLibrary()}
        />
        {/* {!owned ? (
          <>
            <Grid className={classes.overlay} />
            <Grid className={classes.iconContainer}>
              <img src={StoreIcon} alt={'Ícone'} style={{ height: '22px' }} />
            </Grid>
          </>
        ) : null} */}
      </Grid>
      <Grid item xs>
        <Grid>
          {/* TODO - Change tag placement */}
          {tag ? <TagWrapper config={tag.config} value={tag.value} /> : null}
          <Typography variant="subtitle1" className={classes.boxSubtitle}>
            {handleFormatDate(deliveryDate)}
          </Typography>
        </Grid>
        <CardContent
          className={clsx(classes.smPaddingContent, classes.smBottomMargin)}
        >
          <Typography
            variant="h4"
            color="textPrimary"
            className={classes.boldText}
          >
            {boxTitle}
          </Typography>
        </CardContent>
        <CardActions
          className={classes.smPaddingContent}
          onClick={() => handleRedirectToLibrary()}
          style={{ position: 'absolute', bottom: 0 }}
        >
          <Typography className={classes.actionLabel}>Ver detalhes</Typography>
        </CardActions>
      </Grid>
    </>
  );
};

export default ContractedCard;
