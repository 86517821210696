import * as Yup from 'yup';
import { TRANSPORT_COMPANIES } from 'types/generals';
import { validateDate } from 'helpers/validateDate';

export const labelPattern = /[A-Za-z]{2}\d{8}\s[A-Z]{2}/;

export const trackingSchema = Yup.object({
  rangeStart: Yup.string()
    .trim()
    .matches(labelPattern, 'Padrão incorreto')
    .required(),
  rangeEnd: Yup.string()
    .trim()
    .matches(labelPattern, 'Padrão incorreto')
    .required(),
});

export const validationSchema = Yup.object({
  name: Yup.string().required('Campo obrigatório'),
  transport: Yup.string().required('Campo obrigatório'),
});

export const paymentValidationSchema = Yup.object({
  paymentType: Yup.string().required('Selecione uma opção de pagamento'),
});

export const renewDateValidationSchema = Yup.object({
  renewDate: Yup.string().required('Campo obrigatório'),
});

export const monthOrderValidationSchema = Yup.object({
  boxId: Yup.string().required('Campo obrigatório'),
  startDate: Yup.string().required('Campo obrigatório'),
});

export const changePlanValidationSchema = Yup.object({
  recurrenceId: Yup.string().required('Campo obrigatório'),
  planId: Yup.string().required('Campo obrigatório'),
  paymentType: Yup.string().required('Campo obrigatório'),
});

export const SignatureObservationValidationSchema = Yup.object({
  observation: Yup.string().required('Campo obrigatório.'),
});

export const rankingScoreSchema = Yup.object({
  observation: Yup.string().required('Campo obrigatório.'),
  score: Yup.number().required('Campo obrigatório.'),
  rankingSeasonId: Yup.string().required('Campo obrigatório.'),
  walletAction: Yup.string().required('Campo obrigatório.'),
});

export const benefitsValidationSchema = Yup.object({
  title: Yup.string().required('Campo obrigatório'),
  description: Yup.string().required('Campo obrigatório'),
  recurrenceIds: Yup.array()
    .min(1, 'Este campo precisa ter no mínimo uma recorrência')
    .typeError('Campo obrigatório'),
  priority: Yup.number()
    .typeError('Campo precisa ser preenchido')
    .required('Campo obrigatório'),
  images: Yup.array(),
});

export const renewDatevalidationSchema = Yup.object({
  renewDate: Yup.string()
    .required('Campo obrigatório')
    .test('invalid-date', 'Data de renovação é inválida', value => {
      const validate = validateDate(value);

      if (validate) {
        return true;
      }
      return false;
    }),
});

export const defaultValues = (renewDate: Date) => {
  return {
    renewDate: renewDate,
  };
};

export const BindInvoiceOrderSchema = Yup.object({
  number: Yup.string().required('Campo obrigatório.'),
  serie: Yup.string().required('Campo obrigatório.'),
  nature: Yup.string().required('Campo obrigatório.'),
});
