import React, { FC } from 'react';
import { Grid, IconButton, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ArrowBack from '@material-ui/icons/ArrowBack';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ReactPlayer from 'react-player';

import { useStylesVideoModal } from '../useStyles';
import { Wrapper } from './styles';

import { VideoPlayerDialogProps } from '../dialogTypes';

const VideoPlayerDialog: FC<VideoPlayerDialogProps> = ({
  videoIndex,
  title,
  videosLength = 1,
  url,
  controls = true,
  playerWidth = '640px',
  playerHeight = '360px',
  handleCloseModal,
  handlePreviousVideo,
  handleNextVideo,
}) => {
  const styles = useStylesVideoModal();
  const _handlePreviousVideo = () => {
    handlePreviousVideo(videoIndex);
  };

  const _handleNextVideo = () => {
    handleNextVideo(videoIndex);
  };

  return (
    <Wrapper container direction="column">
      <Grid
        className={styles.gridTitle}
        container
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid item xs={9}>
          <Typography className={styles.title} variant="h1">
            {title}
          </Typography>
        </Grid>
        <Grid item xs style={{ textAlign: 'right' }}>
          {videoIndex > 0 && (
            <IconButton onClick={_handlePreviousVideo}>
              <ArrowBack className={styles.white} />
            </IconButton>
          )}
          {videosLength > videoIndex + 1 && (
            <IconButton onClick={_handleNextVideo}>
              <ArrowForward className={styles.white} />
            </IconButton>
          )}
          <IconButton onClick={handleCloseModal}>
            <CloseIcon className={styles.white} />
          </IconButton>
        </Grid>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item xs={12} sm={12} md={12}>
          <ReactPlayer
            url={url}
            playing={true}
            controls={controls}
            width={playerWidth}
            height={playerHeight}
            light={false}
          />
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default VideoPlayerDialog;
