import { CreateQueryParams } from '@nestjsx/crud-request';
import { GetProps, getWithSaga } from 'services/apiService';

export default class TrackingService {
  static async getLabelsByService(query: CreateQueryParams): Promise<GetProps> {
    try {
      const data = await getWithSaga('sigep-label', query);
      return data;
    } catch (error) {
      return error;
    }
  }
}
