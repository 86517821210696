import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StatusCodes } from 'http-status-codes';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { UserDialogProps } from '../dialogTypes';
import useStyles from 'components/generals/forms/register/styles';

import { closeModal } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';

import { validationSchema } from './utils';
import UserService from 'services/userService';
import PasswordInput from 'components/generals/input/PasswordInput';
import { Roles, StaffPayload } from 'types/generals';
import MySelectForm from 'components/generals/input/MySelectForm';
import RolesService from 'services/RolesService';

const UserDialog: FC<UserDialogProps> = ({ title, revalidateFn }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, errors, register } = useForm({
    validationSchema,
  });

  const [roles, setRoles] = useState<Roles[]>([]);
  const [userRoles, setUserRoles] = useState<Roles[]>([]);

  useEffect(() => {
    const load = async () => {
      const response = await RolesService.findRoles();
      setRoles(response?.data || []);
    };
    load();
  }, []);

  const onSubmit = async (data: any) => {
    const { name, email, pwd: password } = data;
    const staff: StaffPayload = {
      name,
      email,
      password,
      roles: userRoles.map(roleId => {
        return { id: roleId };
      }) as any,
    };

    try {
      const response = await UserService.saveStaff(staff);

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess('Usuário salvo com sucesso'));
      } else {
        throw new Error('Houve uma falha ao salvar o usuário');
      }

      dispatch(closeModal());
      if (revalidateFn) revalidateFn();
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleOnChangeRole = (event: any) => {
    setUserRoles(event.target.value);
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={2}
          component="form"
          style={{ overflow: 'hidden', padding: '10px 30px' }}
        >
          <Grid item xs>
            <MyOutlinedTextField
              id="user-name"
              name="name"
              label="Nome completo"
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs>
            <MyOutlinedTextField
              id="user-name"
              name="email"
              label="E-mail"
              fullWidth
              error={Boolean(errors.email)}
              helperText={errors.email ? errors.email.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs>
            <PasswordInput
              fullWidth
              name="pwd"
              error={Boolean(errors.pwd)}
              helperText={errors.name ? errors.name.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs>
            <MySelectForm
              // classFormControl={componentStyles.formControl}
              contracted
              itens={roles.map(role => ({
                title: role.name,
                value: role.id,
              }))}
              onChange={handleOnChangeRole}
              value={userRoles}
              helperText={null}
              title="Permissões"
              error={false}
              multiple={true}
              menuProps={{
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'left',
                },
                transformOrigin: {
                  vertical: 'top',
                  horizontal: 'left',
                },
                getContentAnchorEl: null,
              }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          item
          spacing={2}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => dispatch(closeModal())}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
            onClick={handleSubmit(onSubmit)}
          >
            Confirmar
          </Button>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default UserDialog;
