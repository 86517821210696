/**
 * Action types
 */
export enum AuthTypes {
  LOGIN_REQUEST = '@auth/LOGIN_REQUEST',
  LOGIN_SUCCESS = '@auth/LOGIN_SUCCESS',
  LOGIN_FAILURE = '@auth/LOGIN_FAILURE',
  LOGOUT_REQUEST = '@auth/LOGOUT_REQUEST',
  LOGOUT_SUCCESS = '@auth/LOGOUT_SUCCESS',
  LOGOUT_FAILURE = '@auth/LOGOUT_FAILURE',
}

/**
 * Data types
 */
export interface Auth {
  accessToken: string | null;
}

export interface Login {
  email: string;
  password: string;
}

/**
 * State types
 */
export interface AuthState {
  readonly isAuthenticated: boolean;
  readonly isAdmin: boolean;
  readonly accessToken: string | null;
  readonly hasError: boolean;
  readonly isLoading: boolean;
}
