import { Reducer } from 'redux';
import { DataFreteTransportState, DataFreteTransportTypes } from './types';

const INITIAL_STATE: DataFreteTransportState = {
  data: {} as any,
  isLoading: true,
  hasError: false,
};

const reducer: Reducer<DataFreteTransportState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case DataFreteTransportTypes.LOAD_TRANSPORT_DATA_FRETE_REQUEST:
      return { ...state, isLoading: true };
    case DataFreteTransportTypes.LOAD_TRANSPORT_DATA_FRETE_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload,
      };
    case DataFreteTransportTypes.LOAD_TRANSPORT_DATA_FRETE_FAIL:
      return { ...state, hasError: true };
    default:
      return state;
  }
};

export default reducer;
