import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { openModal } from 'store/ducks/nav/actions';
import { DonationDialogProps } from 'components/generals/dialog/dialogTypes';
import CrudList from 'components/generals/lists/CrudList';
import { ColumnProps, FormatOptions } from 'types';
import { CondOperator, CreateQueryParams } from '@nestjsx/crud-request';
import DonationDialog from 'components/generals/dialog/DonationDialog';
import { useHistory } from 'react-router-dom';
import { loadAllPlans } from 'store/ducks/generals/plan/actions';
import { AppState } from 'store';
import { PlanState } from 'store/ducks/generals/plan/types';
import { formatColumnValue } from 'helpers/formatters';
import { SIGNATURE_STATUS } from 'types/generals';
import { FormControlLabel, Grid } from '@material-ui/core';
import MySwitch from 'components/generals/input/MySwitch';

const Donations = () => {
  const defaultCustomFilter = [{ isDonation: true }];
  const newCustomFilter = [
    { isDonation: true },
    { status: { $eq: SIGNATURE_STATUS.ACTIVE } },
    { 'user.currentSubscription': { $notnull: true } },
  ];
  const dispatch = useDispatch();
  const history = useHistory();
  const [refresh, setRefresh] = useState(false);
  const [customFilter, setCustomFilter] = useState<any[]>(defaultCustomFilter);
  const [displayCurrentSubscription, setDisplayCurrentSubscription] = useState(
    false,
  );

  const [queryParams, setQueryParams] = useState<CreateQueryParams>({
    join: [
      {
        field: 'users',
        select: ['id', 'currentSubscription'],
      },
    ],
    sort: {
      field: 'id',
      order: 'DESC',
    },
    page: 1,
    limit: 10,
  });
  const columns: ColumnProps[] = [
    { label: 'Código', getter: 'id', sortable: true },
    { label: 'NOME', getter: 'user.name', sortable: true },
    { label: 'E-MAIL', getter: 'user.email', sortable: true },
    { label: 'Plano', getter: 'plan.name', sortable: true },
    {
      label: 'Criado em',
      getter: 'createdAt',
      sortable: true,
      format: val => formatColumnValue(FormatOptions.DATE, val),
    },
    {
      label: 'Renovação',
      getter: 'renewDate',
      sortable: true,
      format: val =>
        formatColumnValue(FormatOptions.SIMPLE_DATE_WITH_TRACE, val),
    },
  ];

  const { data: plans = [] } = useSelector<AppState, PlanState>(
    ({ plans }: AppState) => plans,
  );

  const loadPlansList = useCallback(() => {
    dispatch(
      loadAllPlans({
        filter: {
          field: 'isDonation',
          value: true,
          operator: CondOperator.EQUALS,
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Doações'));
    loadPlansList();
  }, [dispatch, loadPlansList]);

  const handleDisplayCurrent = (checked: boolean) => {
    setDisplayCurrentSubscription(checked);
    if (checked) {
      setCustomFilter(newCustomFilter);
      setRefresh(true);
    } else {
      setCustomFilter(defaultCustomFilter);
      setRefresh(true);
    }
  };

  const handleCreateDonation = () => {
    try {
      const modalProps: DonationDialogProps = {
        title: 'Criar doação',
        plans,
      };
      dispatch(openModal(DonationDialog, modalProps, 'md'));
    } catch (err) {
      throw new Error(err);
    }
  };
  const Filters = () => (
    <Grid container item xs={12} sm={6} md={3}>
      <FormControlLabel
        control={
          <MySwitch
            name="currentSubscription"
            color="primary"
            checked={displayCurrentSubscription}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              handleDisplayCurrent(e.target.checked);
            }}
          />
        }
        label="Doação atual"
      />
    </Grid>
  );

  return (
    <CrudList
      title="Doações"
      url="/signature"
      columns={columns}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      searchField="user.name"
      searchFieldLabel="Nome"
      dateFilterLabel="Período de renovação"
      showDateFilter={true}
      dateField="renewDate"
      customFilter={customFilter}
      newButtonLabel="Nova doação"
      onNewButtonClick={handleCreateDonation}
      onRowClick={item => history.push(`/admin/subscriptions/${item.id}`)}
      refresh={refresh}
      setRefresh={setRefresh}
    >
      <Filters />
    </CrudList>
  );
};

export default Donations;
