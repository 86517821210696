import React, { useEffect, useState } from 'react';
import { FormControlLabel, Grid, Paper, Typography } from '@material-ui/core';

import { Controller } from 'react-hook-form';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { useStyles } from './styles';
import MySwitch from 'components/generals/input/MySwitch';
import { StoreSettingsProps } from './types';
import MyNumberFormat from 'components/generals/input/MyNumberFormat';

const StoreSettingsFormRankingSeason: React.FC<StoreSettingsProps> = ({
  register,
  control,
  errors,
  watch,
  storeSettings,
  getValues,
}) => {
  const styles = useStyles();

  const [showReferralMenu, setShowReferralMenu] = useState<boolean>(
    storeSettings.rankingSeasonConfig.showReferralMenu,
  );

  useEffect(() => {
    setShowReferralMenu(storeSettings.rankingSeasonConfig.showReferralMenu);
  }, [storeSettings.rankingSeasonConfig.showReferralMenu]);

  return (
    <>
      <Grid className={styles.marginTop}>
        <Typography className={styles.bannerTitle} variant="h2">
          Configurações de indicação
        </Typography>
        <Paper elevation={0} className={styles.rootPaper}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Controller
                name="scoreValue"
                control={control}
                as={({ value, onChange }) => (
                  <MyOutlinedTextField
                    id="scoreValue"
                    label="Valor em reais de cada ponto"
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      inputComponent: MyNumberFormat as any,
                    }}
                    fullWidth
                    error={Boolean(errors.scoreValue)}
                    helperText={
                      errors.scoreValue ? errors.scoreValue.message : null
                    }
                  />
                )}
              />
            </Grid>
            <Grid item xs>
              <FormControlLabel
                control={
                  <MySwitch
                    inputRef={register}
                    name="showReferralMenu"
                    color="primary"
                    checked={showReferralMenu}
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setShowReferralMenu(e.target.checked);
                    }}
                  />
                }
                label="Mostrar menu para o assinante"
                labelPlacement="start"
              />
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </>
  );
};

export default StoreSettingsFormRankingSeason;
