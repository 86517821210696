import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { Colors } from 'styles/colors';
import { Sizes } from 'styles/sizes';
import { MyDrawerItemProps } from './types';

const useStyles = makeStyles({
  root: {
    fontSize: '1.4rem',
    fontWeight: 600,
    color: Colors.GRAY,
    textTransform: 'capitalize',
    textDecoration: 'none',
    paddingLeft: Sizes.DRAWER_LEFT_PADDING,
    borderLeft: Sizes.DRAWER_LEFT_BORDER,
    borderLeftColor: 'transparent',
    borderLeftStyle: 'solid',
    marginBottom: '1.4rem',
  },
});

const MyDrawerItem: FC<MyDrawerItemProps> = ({
  to,
  externalPath,
  children,
}) => {
  const classes = useStyles();
  return (
    <NavLink
      to={{
        pathname: externalPath ? externalPath : to,
      }}
      target={externalPath ? '_blank' : '_self'}
      activeStyle={{
        color: Colors.RED,
        borderLeftColor: Colors.RED,
        fontWeight: 'bold',
      }}
      className={classes.root}
    >
      {children}
    </NavLink>
  );
};

export default MyDrawerItem;
