import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Button, IconButton } from '@material-ui/core';
import { closeModal } from 'store/ducks/nav/actions';

import { ReactComponent as CloseIcon } from 'assets/img/svg/icon_default_close.svg';
import {
  Title,
  IconWrapper,
  Message,
  Wrapper,
  Icon,
  ButtonTitle,
} from './styles';
import { FeedBackWithButtonDialogProps } from '../dialogTypes';
import { useDialogStyles } from '../useStyles';

const FeedBackWithButtonDialog: React.FC<FeedBackWithButtonDialogProps> = ({
  title,
  buttonTitle,
  message,
  link,
  externalLink,
  icon,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useDialogStyles();

  const goToLink = async () => {
    dispatch(closeModal());
    if (externalLink) {
      window.location.href = externalLink;
    } else if (link) {
      history.push(link);
    }
  };

  const handleClose = () => dispatch(closeModal());

  return (
    <Wrapper container direction="column" className={classes.wrapper}>
      <IconWrapper container justify="flex-end">
        <IconButton onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </IconWrapper>
      <Grid container direction="column" justify="center" alignItems="center">
        {icon && <Icon component={icon} />}
        <Title variant="h1" color="textPrimary">
          {title}
        </Title>
        <Grid item xs={12} sm={12} md={12}>
          <Message variant="h3" color="textPrimary" className={classes.info_mb}>
            {message}
          </Message>
        </Grid>
        <Grid item xs={12} container justify="center">
          <Button
            onClick={() => goToLink()}
            className={classes.button}
            variant="contained"
            color="inherit"
          >
            <ButtonTitle>{buttonTitle}</ButtonTitle>
          </Button>
        </Grid>
      </Grid>
    </Wrapper>
  );
};

export default FeedBackWithButtonDialog;
