import { action } from 'typesafe-actions';
import { Transport } from 'types/generals';
import { TransportTypes } from './types';

export const loadTransportCoverageRequest = (zipcode: string) => {
  return action(TransportTypes.LOAD_TRANSPORT_COVERAGE_REQUEST, { zipcode });
};

export const loadTransportCoverageSuccess = (payload: Transport) =>
  action(TransportTypes.LOAD_TRANSPORT_COVERAGE_SUCCESS, payload);

export const loadTransportCoverageFail = () =>
  action(TransportTypes.LOAD_TRANSPORT_COVERAGE_FAIL);
