import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import useStyles from 'components/generals/forms/register/styles';

import { AppState } from 'store';
import { closeModal } from 'store/ducks/nav/actions';
import { NotificationState } from 'store/ducks/notification/types';

import { useDialogStyles } from './useStyles';
import { ChangeDateDialogProps } from './dialogTypes';
import MyOutlinedTextField from '../input/MyOutlinedTextField';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import MASK from 'helpers/masks';
import { renewDateValidationSchema } from './utils';

const ChangeDateDialog: React.FC<ChangeDateDialogProps> = ({
  actionFn,
  title,
}) => {
  const dispatch = useDispatch();
  const classes = useDialogStyles();
  const styles = useStyles();

  const { buttonIsDisabled } = useSelector<AppState, NotificationState>(
    ({ notification }: AppState) => notification,
  );

  const { handleSubmit, errors, control, register } = useForm({
    validationSchema: renewDateValidationSchema,
  });

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={2}
          component="form"
          style={{ overflow: 'hidden' }}
        >
          <Grid
            container
            justify="space-between"
            spacing={3}
            item
            direction="row"
            alignItems="center"
          >
            <Grid item xs>
              <Controller
                as={ReactInputMask}
                mask={MASK.DATE}
                name="renewDate"
                control={control}
                maskChar={null}
                onChange={([event]: any) => event.target.value}
              >
                {() => (
                  <MyOutlinedTextField
                    fullWidth
                    label="Data de renovação"
                    placeholder="dd/mm/yyyy"
                    name="renewDate"
                    type="text"
                    inputRef={register}
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    error={Boolean(errors.renewDate)}
                    helperText={
                      errors.renewDate ? errors.renewDate.message : null
                    }
                  />
                )}
              </Controller>
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={styles.buttonLabel}
          style={{ marginRight: 30 }}
          onClick={() => dispatch(closeModal())}
        >
          Cancelar
        </Button>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
          onClick={handleSubmit(actionFn)}
          disabled={buttonIsDisabled}
        >
          {buttonIsDisabled ? (
            <CircularProgress className={styles.progress} size={20} />
          ) : (
            'Confirmar'
          )}
        </Button>
      </DialogActions>
    </Grid>
  );
};

export default ChangeDateDialog;
