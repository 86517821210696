import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { closeModal } from 'store/ducks/nav/actions';

import { InvoiceDialogProps } from '../dialogTypes';
import OrderInvoice from 'pages/admin/manager/orders/OrderInvoice';
import { IconWrapper } from '../FeedbackDialog/styles';

const InvoiceDialog: FC<InvoiceDialogProps> = ({ order }) => {
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(closeModal());
  };

  return (
    <Grid container direction="column">
      <IconWrapper container justify="flex-end">
        <IconButton onClick={() => handleClose()}>
          <CloseIcon />
        </IconButton>
      </IconWrapper>
      <OrderInvoice order={order} />
    </Grid>
  );
};

export default InvoiceDialog;
