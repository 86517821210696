import { makeStyles, styled } from '@material-ui/core';
import { ReactComponent as LogoSVG } from 'assets/img/svg/logo_mbc_text.svg';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  nav: {
    backgroundColor: Colors.WHITE,
    position: 'fixed',
    padding: theme.spacing(1, 2),
    height: '100px',
    zIndex: 10,
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 1.95px 2.6px',
    [theme.breakpoints.down('sm')]: {
      height: '150px',
    },
  },
  search: {
    border: 2,
    borderColor: Colors.LIGHT_GRAY,
    borderStyle: 'solid',
    borderRadius: 5,
    padding: '6px 12px 6px 12px',
    fontSize: '1.4rem',
    fontWeight: 600,
  },
  headerLogoContainer: {
    textAlign: 'right',
  },
  headerLogo: {
    width: '100px !important',
  },
  backButtonArea: {
    display: 'contents',
  },
  shopCartArea: {
    textAlign: 'end',
    margin: '0px 25px 0px 8px',
  },
  smShopCartArea: {
    display: 'inline-flex',
    margin: '0px 25px 0px 2px',
  },
  smInputMargin: {
    marginBottom: 12,
    marginTop: 8,
  },
}));

export const Logo = styled(LogoSVG)(({ theme }) => ({
  height: 'auto',
  width: '80%',
  [theme.breakpoints.up('lg')]: {
    width: '70%',
  },
  cursor: 'pointer',
  paddingTop: theme.spacing(1),
  paddingBottom: theme.spacing(1),
  fill: Colors.RED,
}));
