import { CreateQueryParams } from '@nestjsx/crud-request';
import { api, getWithSaga } from './apiService';
import { BoxDTO } from 'pages/admin/register/boxes/types';

export default class BoxService {
  static createFormData(box: BoxDTO, plans: string) {
    const formData = new FormData();

    formData.append('name', box.name);
    formData.append('description', box.description);
    formData.append('image', box.image);
    formData.append('weight', box.weight);
    formData.append('startDate', box.startDate);
    formData.append('endDate', box.endDate);
    formData.append('plans', plans);

    if (box.sku) formData.append('sku', box.sku);

    box.itens.forEach(item => formData.append('itens', item.name));
    return formData;
  }

  static async listBoxes(query?: CreateQueryParams) {
    try {
      const data = getWithSaga('/boxes', query);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async createBox(box: BoxDTO, plan: string) {
    try {
      const { data } = await api.post('boxes', this.createFormData(box, plan));
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async updateBox(box: BoxDTO, plan: string, id?: number) {
    try {
      const { data } = await api.put(
        `boxes/${id}`,
        this.createFormData(box, plan),
      );
      return data;
    } catch (error) {
      throw new Error(error.response);
    }
  }

  static async deleteBoxItem(item: number) {
    try {
      return await api.delete(`box-itens/${item}`);
    } catch (error) {
      return error.response.data;
    }
  }
}
