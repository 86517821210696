import { compareAsc, isExists } from 'date-fns';

export const cardsPatterns: { [key: string]: RegExp } = {
  Visa: /^4[0-9]{12}(?:[0-9]{3})/,
  Mastercard: /^5[1-5][0-9]{14}/,
  'American Express': /^3[47][0-9]{13}/,
  'Diners Club': /^3(?:0[0-5]|[68][0-9])[0-9]{11}/,
  Discover: /^6(?:011|5[0-9]{2})[0-9]{12}/,
  Elo: /^((((636368)|(438935)|(504175)|(451416)|(636297))\d{0,10})|((5067)|(4576)|(4011))\d{0,12})$/,
  Hipercard: /^(606282\d{10}(\d{3})?)|(3841\d{15})$/,
  JCB: /^(?:2131|1800|35\d{3})\d{11}$/,
  Maestro: /^(?:5[0678]\d\d|6304|6390|67\d\d)\d{8,15}$/,
};

/**
 * Helper method to discover a credit card brand though the card number.
 *
 * @param cardNumber Credit card number
 */
export const discoverCardBrand = (cardNumber: string): string => {
  try {
    for (const pattern in cardsPatterns) {
      if (cardNumber.match(cardsPatterns[pattern])) return pattern;
    }
    return '';
  } catch (err) {
    return err;
  }
};

export const verifyValidateCard = (date: string) => {
  const month = +date.split('/')[0];
  const year = +date.split('/')[1];
  const day = 1;
  if (isExists(year, month - 1, day)) {
    const dateValidate = new Date(year, month - 1, day);
    return compareAsc(dateValidate, new Date());
  }
  return false;
};
