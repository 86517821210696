import { action } from 'typesafe-actions';
import { ChangePlanTypes } from './types';

export const loadStepPlanPage = () => {
  return action(ChangePlanTypes.LOAD_STEP_PLAN);
};

export const nextStepPlanPage = () => {
  return action(ChangePlanTypes.NEXT_STEP_PLAN);
};

export const backStepPlanPage = () => {
  return action(ChangePlanTypes.PREVIOUS_STEP_PLAN);
};

export const resetStepPlanPage = () => {
  return action(ChangePlanTypes.RESET_STEP_PLAN);
};

export const setAddressOnPayload = (payload: any) => {
  return action(ChangePlanTypes.SET_ADDRESS_PAYLOAD, payload);
};

export const setSignatureOnPayload = (payload: any) => {
  return action(ChangePlanTypes.SET_SIGNATURE_PAYLOAD, payload);
};

export const setPlanOnPayload = (payload: any) => {
  return action(ChangePlanTypes.SET_PLAN_PAYLOAD, payload);
};

export const setPaymentType = (payload: string) => {
  return action(ChangePlanTypes.SET_PAYMENT_TYPE, payload);
};
