import React from 'react';
import Colors from 'styles/colors';

import { ActiveSignPropsType } from './types';

const ActiveSign: React.FC<ActiveSignPropsType> = ({ isCurrentLocation }) => {
  return (
    <div
      style={{
        width: '6px',
        height: '32px',
        backgroundColor: isCurrentLocation ? Colors.RED : 'transparent',
      }}
    ></div>
  );
};

export default ActiveSign;
