import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';

import { ViewBulkActionTypes, ViewPayload } from './types';

export const loadViewUsersLastOrderRequest = (query?: CreateQueryParams) =>
  action(ViewBulkActionTypes.LOAD_VIEW_USERS_LAST_ORDER_REQUEST, { query });

export const loadViewUsersLastOrderFailure = () =>
  action(ViewBulkActionTypes.LOAD_VIEW_USERS_LAST_ORDER_FAILURE);

export const loadViewUsersLastOrderSuccess = (payload: ViewPayload) =>
  action(ViewBulkActionTypes.LOAD_VIEW_USERS_LAST_ORDER_SUCCESS, payload);
