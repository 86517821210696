import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

const useStyles = makeStyles({
  filterOptionsList: {
    backgroundColor: Colors.WHITE,
    boxShadow: 'none',
  },
});

export { useStyles };
