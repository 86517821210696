import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  footer: {
    [theme.breakpoints.up('sm')]: {
      paddingTop: 44,
      backgroundColor: Colors.LIGHTER_GRAY,
    },
  },
  divider: {
    height: 2,
  },
  semiBoldText: {
    fontWeight: 700,
    paddingBottom: 10,
  },
  footerWrapper: {
    height: '100%',
    textAlign: 'center',
    alignItems: 'center',
    background: Colors.LIGHTER_GRAY,
    color: Colors.DARKER_GRAY,
  },
  addressContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'start',
      height: '110px',
    },
    textAlign: 'end',
    padding: '40px 23px',
    height: '175px',
  },
  content: {
    display: 'inline-block',
    textAlign: 'start',
  },
  termsContainer: {
    textAlign: 'start',
    padding: '40px 23px',
    height: '175px',
  },
  cnpjContainer: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'start',
      padding: '0px 23px 50px',
    },
    textAlign: 'center',
    padding: '15px 40px 50px',
  },
  footerText: {
    paddingBottom: 5,
  },
  termsLink: {
    cursor: 'pointer',
    fontWeight: 400,
    textDecoration: 'none',
    color: Colors.DARKER_GRAY,
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));
