import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { SignaturePayload, SignatureTypes } from './types';

export const loadSignatures = (query?: CreateQueryParams) => {
  return action(SignatureTypes.LOAD_SIGNATURE_LIST_REQUEST, query);
};

export const loadSignaturesSuccess = (payload: SignaturePayload) => {
  return action(SignatureTypes.LOAD_SIGNATURE_LIST_SUCCESS, payload);
};

export const loadSignaturesFail = () => {
  return action(SignatureTypes.LOAD_SIGNATURE_LIST_FAILURE);
};
