import { makeStyles } from '@material-ui/core';
import { Colors } from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  actionButton: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
  },
  orderInfoTitle: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
    margin: theme.spacing(0, 0, 0.5),
  },
  mInfo: {
    margin: theme.spacing(0, 0, 3),
  },
  mRight: {
    margin: theme.spacing(0, 3, 0, 0),
  },
  paperInfoTitle: {
    margin: theme.spacing(1, 0, 5),
    textTransform: 'capitalize',
    color: Colors.GRAY,
    fontWeight: 500,
  },
  trackingInfo: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: Colors.GRAY,
  },
  invoiceOrderInfo: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
    color: Colors.RED,
  },
  table: {
    minWidth: 650,
  },
  ordersList: {
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  paperTable: {
    padding: theme.spacing(3, 4),
    borderRadius: '2em',
  },
  gridAction: {
    margin: theme.spacing(0, 0, 2.5),
  },
  buttonXml: {
    fontSize: 16,
    fontWeight: 'bold',
    borderRadius: 13,
  },
  filterOptionsList: {
    backgroundColor: Colors.WHITE,
  },
  tableCellBold: {
    cursor: 'pointer',
    fontWeight: 'bold',
  },
  tableCellTypography: {
    fontWeight: 'bold',
    fontSize: '1.4rem',
  },
  menuOption: {
    textTransform: 'capitalize',
  },
  mTop: {
    marginTop: '1em',
  },
});
