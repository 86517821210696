import React from 'react';
import {
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
  Button,
} from '@material-ui/core';
import { CheckCircle, Cancel } from '@material-ui/icons';
import clsx from 'clsx';
import { useHistory } from 'react-router-dom';
import { Recurrence, RECURRENCES_MONTHS } from 'types/generals/plan';
import { useStyles } from './styles';
import { BenefitsCardProps } from './types';
import { FormatOptions } from 'types';
import { formatColumnValue } from 'helpers/formatters';
import imgUnavailable from 'assets/png/image_unavailable.png';

const BenefitsCard: React.FC<BenefitsCardProps> = ({
  benefits,
  plan,
  signature,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const theme = useTheme();
  const smallSize = useMediaQuery(theme.breakpoints.down(1150));

  const noIsUserYearly =
    benefits?.length &&
    plan.recurrence.months !== RECURRENCES_MONTHS.YEARLY &&
    !signature.nextSignaturePlan;

  const verifyBenefitIsAvailable = (recurrences: Recurrence[]) => {
    if (plan.recurrence) {
      return recurrences.find(r => r.id === plan.recurrence.id);
    }
  };

  const goToChangePlan = () => {
    history.push('/my-account/signature/change-plan');
  };

  return (
    <Grid item xs={12}>
      <Grid
        className={clsx(
          styles.benefitCardContainer,
          smallSize ? styles.benefitCardMobile : styles.benefitCard,
        )}
        container
        item
      >
        <Grid container item spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h2" className={styles.textBold}>
              Benefícios do seu plano atual:
            </Typography>
          </Grid>
          <Grid
            container
            item
            xs={12}
            spacing={2}
            className={styles.benefitsContainer}
          >
            {benefits &&
              benefits.map(benefit => (
                <Grid container item key={benefit.id}>
                  <img
                    alt="Benefício"
                    src={benefit.image ? benefit.image : imgUnavailable}
                    className={clsx(
                      styles.rounded,
                      smallSize
                        ? styles.benefitImageMobile
                        : styles.benefitImage,
                    )}
                  />
                  <Grid
                    item
                    xs={smallSize ? 12 : 10}
                    className={
                      smallSize ? '' : styles.benefitTextContainerMargin
                    }
                  >
                    <Grid
                      container
                      item
                      className={smallSize ? styles.benefitTop : ''}
                    >
                      {verifyBenefitIsAvailable(benefit.recurrences) ? (
                        <CheckCircle className={styles.benefitActiveColor} />
                      ) : (
                        <Cancel className={styles.benefitInactiveColor} />
                      )}
                      <Typography
                        className={clsx(
                          styles.textBold,
                          styles.benefitTitle,
                          verifyBenefitIsAvailable(benefit.recurrences)
                            ? styles.benefitActiveTextColor
                            : styles.benefitInactiveColor,
                        )}
                      >
                        {benefit.title || ''}
                      </Typography>
                    </Grid>
                    <Typography
                      className={clsx(
                        styles.benefitDescriptionText,
                        styles.boldText,
                        verifyBenefitIsAvailable(benefit.recurrences)
                          ? styles.benefitActiveTextColor
                          : styles.benefitInactiveColor,
                      )}
                    >
                      {benefit.description || ''}
                    </Typography>
                  </Grid>
                </Grid>
              ))}
          </Grid>
          {noIsUserYearly ? (
            <Grid item xs={12} style={{ marginTop: 50 }}>
              <Button
                variant="outlined"
                className={
                  smallSize
                    ? styles.moreBenefitsButtonMobile
                    : styles.moreBenefitsButton
                }
                onClick={() => goToChangePlan()}
              >
                <Typography className={styles.moreBenfitsButtonText}>
                  Garanta mais benefícios
                </Typography>
              </Button>
            </Grid>
          ) : null}

          {signature.nextSignaturePlan ? (
            <Grid item xs={12} style={{ marginTop: 50 }}>
              <Button variant="outlined" disabled>
                <Typography className={styles.moreBenfitsButtonText}>
                  Você garantiu mais beneficios, os beneficios estarão
                  disponíveis para você a partir do dia{' '}
                  {formatColumnValue(
                    FormatOptions.DAY_AND_MONTH_WITH_TRACE,
                    signature.nextSignaturePlan.startDate,
                  )}
                </Typography>
              </Button>
            </Grid>
          ) : null}
        </Grid>
      </Grid>
    </Grid>
  );
};

export default BenefitsCard;
