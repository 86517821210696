export const listFilters = {
  name: { filterTitle: 'Nome do produto', value: undefined },
  description: { filterTitle: 'Descrição', value: undefined },
  storeType: { filterTitle: 'Tipo de loja', value: undefined },
  price: { filterTitle: 'Preço', value: undefined },
  isVisible: { filterTitle: 'Status', value: undefined },
  stock: { filterTitle: 'Estoque', value: undefined },
};

export const STORE_TYPES = [
  { title: 'Loja Virtual', value: 'Loja Normal' },
  { title: 'Loja Premium', value: 'Loja Premium' },
];

export const STORE_PRODUCT_STATUS = [
  { title: 'Habilitado', value: true },
  { title: 'Desabilitado', value: false },
];

export const setLineBreak = (text: string) => {
  if (!text) {
    return null;
  }
  return text.replace(/\r?\n/g, '\n');
};
