import {
  ACTIVE_SIGNATURE_STATUS,
  INACTIVE_SIGNATURE_STATUS,
  UNMAPPED_SIGNATURE_STATUS,
} from 'types/generals';

export const pageFiltersFields = {
  createdAt: { filterTitle: 'Período', value: undefined },
  name: { filterTitle: 'Name', value: undefined },
  status: { filterTitle: 'Status', value: undefined },
  'orderDetail.planName': { filterTitle: 'Plano', value: undefined },
  'orderDetail.planPaymentType': {
    filterTitle: 'Formas de Pagamento',
    value: undefined,
  },
};

export const SIGNATURES_STATUS = [
  ...Object.values(ACTIVE_SIGNATURE_STATUS),
  ...Object.values(INACTIVE_SIGNATURE_STATUS),
  ...Object.values(UNMAPPED_SIGNATURE_STATUS),
];
