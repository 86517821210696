import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Colors from 'styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // height: 314,
      padding: theme.spacing(12, 0),
    },
    points: {
      height: 8,
      width: 8,
      backgroundColor: Colors.BLACK,
      borderRadius: '50%',
      animationName: '$jumpingDots',
      animationDuration: '1.4s',
      animationIterationCount: 'infinite',
      animationFillMode: 'both',
      '&:nth-child(2)': {
        animationDelay: '.2s',
      },
      '&:nth-child(3)': {
        animationDelay: '.4s',
      },
      '&:nth-child(4)': {
        animationDelay: '.6s',
      },
    },
    '@keyframes jumpingDots': {
      '0%': {
        opacity: '.2',
        transform: 'translateY(0px)',
      },
      '50%': {
        opacity: '1',
        transform: 'translateY(-20px)',
      },
      '100%': {
        opacity: '.2',
        transform: 'translateY(0px)',
      },
    },
    text: {
      fontSize: 18,
      fontWeight: 'bold',
      color: Colors.ORANGE,
      marginTop: 50,
    },
  }),
);
