import React, { FC } from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';

import MyTypography from 'components/generals/labels/MyTypography';
import { formatColumnValue } from 'helpers/formatters';

import { useStyles } from '../../styles';
import { FormatOptions } from 'types';
import { BoxCardProps } from './types';
import { ORDER_TYPE } from 'types/generals';
import { head } from 'lodash';

const BoxCard: FC<BoxCardProps> = ({ box, order }) => {
  const styles = useStyles();

  const formatUnitPrice = (amount: number) =>
    formatColumnValue(FormatOptions.MONEY, amount);

  const boxName = head(box.images)?.name || '-';

  return (
    <Paper variant="outlined" className={styles.cardItem}>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="center"
        style={{ textAlign: 'center' }}
      >
        <Grid item xs>
          <img
            src={boxName}
            alt={`Imagem do box ${box.name}.`}
            className={styles.imgContent}
          />
        </Grid>
        <Grid item xs>
          <MyTypography className={styles.invoiceOrderInfo}>
            {box.name}
          </MyTypography>
        </Grid>
        <Grid item xs>
          <Typography
            variant="h4"
            color="textPrimary"
            className={styles.infoTitle}
            style={{ margin: 0 }}
          >
            {box.description}
          </Typography>
        </Grid>
        <Grid item xs={2} style={{ textAlign: 'end' }}>
          <Typography variant="h3">
            {box.productCount} {box.productCount > 1 ? 'unidades' : 'unidade'}
          </Typography>
        </Grid>
        {order.orderDetail.orderType === ORDER_TYPE.STORE ? (
          <Grid item xs style={{ textAlign: 'end' }}>
            <MyTypography variant="h3" className={styles.invoiceOrderInfo}>
              {formatUnitPrice(box.productAmount)}
            </MyTypography>
          </Grid>
        ) : (
          <Grid item xs style={{ textAlign: 'end' }}>
            <MyTypography variant="h3" className={styles.invoiceOrderInfo}>
              {formatUnitPrice(+box.productUnitPrice)}
            </MyTypography>
          </Grid>
        )}
      </Grid>
    </Paper>
  );
};

export default BoxCard;
