import React, { FC } from 'react';
import { Grid, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

import { useStyles } from '../styles';
import { DetailHeaderProps } from '../types';

const DetailHeader: FC<DetailHeaderProps> = ({
  title,
  action,
  step,
  totalSteps,
}) => {
  const styles = useStyles();
  return (
    <>
      <Grid item xs>
        <Typography
          variant="h2"
          onClick={() => action()}
          style={{ fontWeight: 600, cursor: 'pointer' }}
        >
          <ArrowBackIosIcon />
          {title}
        </Typography>
      </Grid>
      <Grid container item xs justify="flex-end">
        <Typography variant="subtitle1" className={styles.greyText}>
          {`Etapa ${step} de ${totalSteps}`}
        </Typography>
      </Grid>
    </>
  );
};

export default DetailHeader;
