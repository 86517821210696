import { Reducer } from 'redux';
import { TrackingState, TrackingTypes } from './types';

const INITIAL_STATE: TrackingState = {
  isLoading: false,
  hasError: false,
  data: {
    sedex: [],
    pac: [],
    modico: [],
  },
};

const trackingReducer: Reducer<TrackingState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case TrackingTypes.LOAD_PAC_REQUEST:
    case TrackingTypes.LOAD_SEDEX_REQUEST:
    case TrackingTypes.LOAD_MODICO_REQUEST:
      return {
        ...state,
        isLoading: true,
      };
    case TrackingTypes.LOAD_SEDEX_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: {
          ...state.data,
          sedex: action.payload.data,
        },
      };
    case TrackingTypes.LOAD_PAC_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: {
          ...state.data,
          pac: action.payload.data,
        },
      };
    case TrackingTypes.LOAD_MODICO_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: {
          ...state.data,
          modico: action.payload.data,
        },
      };
    case TrackingTypes.LOAD_FAILURE:
      return {
        ...state,
        isLoading: false,
        hasError: true,
      };
    default:
      return state;
  }
};

export default trackingReducer;
