import React, { FC } from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { useStyles } from './styles';
import { Link } from 'react-router-dom';
import {
  TERMS_CONDITIONS,
  EXCHANGE_POLICIES,
  DEADLINE_POLICIES,
  PRIVATE_CONDITIONS,
} from 'utils/links/termsConditions';
import { ADDRESS_NEIGHBORHOOD, ADDRESS_STREET, CNPJ_TEXT } from './utils';

const StoreFooter: FC = () => {
  const styles = useStyles();
  return (
    <Grid className={styles.footer}>
      <Divider className={styles.divider} />
      <Grid container item className={styles.footerWrapper}>
        <Grid item xs={12} sm className={styles.addressContainer}>
          <Grid className={styles.content}>
            <Typography variant="h3" className={styles.semiBoldText}>
              Biblioteca Católica LTDA
            </Typography>
            <Typography variant="h4" className={styles.footerText}>
              {ADDRESS_STREET}
            </Typography>
            <Typography variant="h4" className={styles.footerText}>
              {ADDRESS_NEIGHBORHOOD}
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} sm className={styles.termsContainer}>
          <Grid className={styles.content}>
            <Typography variant="h3" className={styles.semiBoldText}>
              Deixe-nos te ajudar
            </Typography>
            <Link
              to={{
                pathname: EXCHANGE_POLICIES,
              }}
              target="_blank"
              className={styles.termsLink}
            >
              <Typography variant="h4" className={styles.footerText}>
                Políticas de troca e devolução
              </Typography>
            </Link>
            <Link
              to={{
                pathname: DEADLINE_POLICIES,
              }}
              target="_blank"
              className={styles.termsLink}
            >
              <Typography variant="h4" className={styles.footerText}>
                Políticas de prazos
              </Typography>
            </Link>
            <Link
              to={{
                pathname: TERMS_CONDITIONS,
              }}
              target="_blank"
              className={styles.termsLink}
            >
              <Typography variant="h4" className={styles.footerText}>
                Termos e condições
              </Typography>
            </Link>
            <Link
              to={{
                pathname: PRIVATE_CONDITIONS,
              }}
              target="_blank"
              className={styles.termsLink}
            >
              <Typography variant="h4" className={styles.footerText}>
                Política de Privacidade
              </Typography>
            </Link>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.cnpjContainer}>
          <Typography variant="h4">{CNPJ_TEXT}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default StoreFooter;
