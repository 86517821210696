import { call, put } from 'redux-saga/effects';
import { api } from 'services/apiService';
import FileService from 'services/fileservice';
import UserService from 'services/userService';
import { createAndDownloadFile } from 'utils/functions/generals';
import {
  loadFile,
  loadFileFailure,
  loadFileSuccess,
} from '../generals/file/actions';
import {
  loadUserSuccess,
  loadUserFailure,
  loadStaffFailure,
  loadStaffSuccess,
  loadUserWalletSuccess,
  loadUserWalletFailure,
} from './actions';

export function* loadUserSaga() {
  try {
    const { data } = yield call(api.get, '/me');
    yield put(loadUserSuccess(data));
  } catch (err) {
    yield put(loadUserFailure());
  }
}

export function* loadStaffSaga(action: any) {
  try {
    const { data, total } = yield call(
      UserService.getStaffs,
      action.payload.query,
    );
    yield put(loadStaffSuccess(data, total));
  } catch (error) {
    yield put(loadStaffFailure());
  }
}

export function* loadUserWalletSaga(action: any) {
  try {
    const { data } = yield call(UserService.getWallet, action.payload.query);
    if (data.length) {
      yield put(loadUserWalletSuccess(data[0]));
    }
  } catch (error) {
    yield put(loadUserWalletFailure());
  }
}

export function* loadStaffFileSaga(action: any) {
  try {
    yield put(loadFile());
    const file = yield call(
      FileService.getFile,
      action.payload.query,
      action.payload.rootPath,
    );
    if (file?.response?.status) {
      yield put(loadFileFailure());
    } else {
      yield put(loadFileSuccess());
      createAndDownloadFile(file, 'Usuários.xls');
    }
  } catch (error) {
    yield put(loadFileFailure());
  }
}
