import { call, put } from 'redux-saga/effects';
import { loadLeadsSuccess, loadLeadsFailure } from './actions';
import UsersLeadsService from 'services/usersLeadService';
import { createAndDownloadFile } from 'utils/functions/generals';
import {
  loadFile,
  loadFileFailure,
  loadFileSuccess,
} from '../generals/file/actions';
import FileService from 'services/fileservice';

export function* loadLeadsSaga(action: any) {
  try {
    const { data, total } = yield call(
      UsersLeadsService.getAllLeads,
      action.payload.query,
    );

    yield put(loadLeadsSuccess({ total, data }));
  } catch (error) {
    yield put(loadLeadsFailure());
  }
}

export function* loadLeadsFileSaga(action: any) {
  try {
    yield put(loadFile());
    const file = yield call(
      FileService.getFile,
      action.payload.query,
      action.payload.rootPath,
    );
    if (file?.response?.status) {
      yield put(loadFileFailure());
    } else {
      yield put(loadFileSuccess());
      createAndDownloadFile(file, 'Leads.xls');
    }
  } catch (error) {
    yield put(loadFileFailure());
  }
}
