import React, { useRef, useState, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import InputMask from 'react-input-mask';
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from '@material-ui/core';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';

import MASK, { UNMASK } from 'helpers/masks';

import { commonStyles } from '../../styles';
import useStyles from './styles';

import OpenEndpointsService from 'services/openEndpointsService';
import { useSignature } from 'contexts/signatureContext';
import { PAYMENT_TYPE } from 'types/generals';
import { FormController, InputRef } from 'types/FormTypes';
import MonthYearSelector from 'components/generals/MonthYearSelector';
import { useQuery } from 'routes/AuthRoutes';

const AdressAndPaymentStep = ({
  register,
  control,
  reset,
  errors,
  setValue,
}: FormController) => {
  const classes = commonStyles();
  const styles = useStyles();
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledField, setDisabledField] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState('');
  const {
    plan,
    handleSetPaymentType,
    showFinishStep,
    planStepsDisabled,
  } = useSignature();
  const query = useQuery();
  const referralCode = query.get('codigoIndicacao') || '';

  const CEPInput = useRef() as InputRef;
  const addressInput = useRef() as InputRef;
  const numberInput = useRef() as InputRef;
  const neighborhoodInput = useRef() as InputRef;
  const cityInput = useRef() as InputRef;
  const stateInput = useRef() as InputRef;
  const complementInput = useRef() as InputRef;
  const creditCardInput = useRef() as InputRef;
  const cardNameInput = useRef() as InputRef;
  const expiringDateInput = useRef() as InputRef;
  const CVVInput = useRef() as InputRef;

  const lookupAddress = async (zipcode: string) => {
    if (zipcode.length === 8) {
      setLoading(true);
      setDisabled(true);
      try {
        const data = await OpenEndpointsService.userLookupAddress(zipcode);
        if (data.cidade && data.uf) setDisabledField(true);
        reset({
          neighborhood: data.bairro,
          complement: data.complemento2,
          city: data.cidade,
          street: data.end,
          state: data.uf,
          formStep: 2,
        });
      } catch (error) {
        reset({
          neighborhood: '',
          complement: '',
          city: '',
          street: '',
          state: '',
        });
      } finally {
        setLoading(false);
        setDisabled(false);
      }
      return zipcode;
    }
  };

  const handleZipcodeChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    lookupAddress(unmasked);
    return unmasked;
  };

  const handleChangePayment = (onChange: any, event: any) => {
    if (showFinishStep || !planStepsDisabled) {
      onChange(event.target.value);
      setPaymentMethod(event.target.value);
      handleSetPaymentType(event.target.value as PAYMENT_TYPE);
    }
  };

  const verifyStepAccess = () => {
    return !showFinishStep && planStepsDisabled;
  };

  useEffect(() => {
    if (showFinishStep) {
      reset({
        formStep: 2,
        referralCode,
      });
    }
  }, [showFinishStep, reset, referralCode]);

  useEffect(() => {
    switch (plan.paymentType) {
      case PAYMENT_TYPE.CARD:
      case PAYMENT_TYPE.BANKSLIP_CARD:
        setValue('paymentType', PAYMENT_TYPE.CARD);
        setPaymentMethod(PAYMENT_TYPE.CARD);
        handleSetPaymentType(PAYMENT_TYPE.CARD);
        break;
      case PAYMENT_TYPE.BANKSLIP:
        setValue('paymentType', PAYMENT_TYPE.BANKSLIP);
        setPaymentMethod(PAYMENT_TYPE.BANKSLIP);
        handleSetPaymentType(PAYMENT_TYPE.BANKSLIP);
        break;
    }
  }, [plan, setValue, handleSetPaymentType]);

  return (
    <Grid
      container
      className={
        verifyStepAccess() ? classes.stepWrapperDisabled : classes.stepWrapper
      }
    >
      <Grid
        container
        direction="row"
        alignItems="center"
        className={classes.wrapperTitle}
      >
        <Typography className={classes.titleButton}>2</Typography>
        <Typography className={classes.title}>Entrega E Pagamento</Typography>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Controller
            as={InputMask}
            mask={MASK.ZIPCODE}
            name="zipcode"
            control={control}
            maskChar={null}
            onChange={handleZipcodeChange}
          >
            {() => (
              <MyOutlinedTextField
                fullWidth
                label="CEP"
                type="text"
                name="zipcode"
                inputProps={{
                  ref: CEPInput,
                  inputMode: 'numeric',
                  className: styles.input,
                }}
                changeFocusOnEnter={() => addressInput.current.focus()}
                isLoading={loading}
                isDisabled={disabled || verifyStepAccess()}
                error={Boolean(errors.zipcode)}
                helperText={errors.zipcode ? errors.zipcode.message : null}
                inputRef={register}
                InputLabelProps={{ className: styles.label }}
              />
            )}
          </Controller>
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="street"
            control={control}
            as={({ value, onChange }) => (
              <MyOutlinedTextField
                fullWidth
                label="Endereço"
                type="text"
                name="street"
                inputProps={{
                  ref: addressInput,
                  className: styles.input,
                }}
                changeFocusOnEnter={() => numberInput.current.focus()}
                onChange={onChange}
                isLoading={loading}
                isDisabled={disabled || verifyStepAccess()}
                error={Boolean(errors.street)}
                helperText={errors.street ? errors.street.message : null}
                value={value || ''}
                InputLabelProps={{ className: styles.label }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <MyOutlinedTextField
            fullWidth
            name="number"
            label="Número"
            type="number"
            inputProps={{
              ref: numberInput,
              inputMode: 'numeric',
              className: styles.input,
            }}
            changeFocusOnEnter={() => neighborhoodInput.current.focus()}
            error={Boolean(errors.number)}
            helperText={errors.number ? errors.number.message : null}
            inputRef={register}
            InputLabelProps={{ className: styles.label }}
            isDisabled={disabled || verifyStepAccess()}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="neighborhood"
            control={control}
            as={({ value, onChange }) => (
              <MyOutlinedTextField
                name="neighborhood"
                fullWidth
                label="Bairro"
                type="text"
                inputProps={{
                  ref: neighborhoodInput,
                  className: styles.input,
                }}
                changeFocusOnEnter={() => cityInput.current.focus()}
                onChange={onChange}
                isLoading={loading}
                isDisabled={disabled || verifyStepAccess()}
                error={Boolean(errors.neighborhood)}
                helperText={
                  errors.neighborhood ? errors.neighborhood.message : null
                }
                value={value}
                InputLabelProps={{ className: styles.label }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="city"
            control={control}
            as={({ value, onChange }) => (
              <MyOutlinedTextField
                fullWidth
                name="city"
                label="Cidade"
                type="text"
                inputProps={{
                  ref: cityInput,
                  className: styles.input,
                }}
                changeFocusOnEnter={() => stateInput.current.focus()}
                onChange={onChange}
                isLoading={loading}
                isDisabled={disabled || disabledField || verifyStepAccess()}
                error={Boolean(errors.city)}
                helperText={errors.city ? errors.city.message : null}
                value={value || ''}
                InputLabelProps={{ className: styles.label }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Controller
            name="state"
            control={control}
            as={({ value, onChange }) => (
              <MyOutlinedTextField
                fullWidth
                label="Estado"
                type="text"
                inputProps={{
                  ref: stateInput,
                  maxLength: 2,
                  className: styles.input,
                }}
                changeFocusOnEnter={() => complementInput.current.focus()}
                onChange={onChange}
                isLoading={loading}
                isDisabled={disabled || disabledField || verifyStepAccess()}
                error={Boolean(errors.state)}
                helperText={errors.state ? errors.state.message : null}
                value={value || ''}
                InputLabelProps={{ className: styles.label }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="complement"
            control={control}
            as={({ value, onChange }) => (
              <MyOutlinedTextField
                fullWidth
                label="Complemento"
                type="text"
                inputProps={{
                  ref: complementInput,
                  maxLength: 30,
                  className: styles.input,
                }}
                onChange={onChange}
                isLoading={loading}
                isDisabled={disabled || verifyStepAccess()}
                error={Boolean(errors.complement)}
                helperText={
                  errors.complement ? errors.complement.message : null
                }
                value={value || ''}
                InputLabelProps={{ className: styles.label }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12}>
          <FormControl
            component="fieldset"
            className={styles.radioGroupWrapper}
          >
            <FormLabel component="legend" className={classes.selectTitle}>
              Opções de pagamento
            </FormLabel>
            <Controller
              name="paymentType"
              control={control}
              as={({ value, onChange }) => (
                <RadioGroup
                  onChange={event => handleChangePayment(onChange, event)}
                  value={value || ''}
                  aria-label="payment-option"
                >
                  {plan.paymentType === PAYMENT_TYPE.BANKSLIP ||
                  plan.paymentType === PAYMENT_TYPE.BANKSLIP_CARD ? (
                    <>
                      <FormControlLabel
                        value="boleto"
                        control={<Radio />}
                        label="Pagar com boleto"
                        className={
                          verifyStepAccess()
                            ? styles.radioDisbaledWrapper
                            : styles.radioWrapper
                        }
                      />
                      <Grid item style={{ marginBottom: 12 }}>
                        <Typography
                          className={
                            verifyStepAccess()
                              ? styles.bankslipTextDisabled
                              : styles.bankslipText
                          }
                        >
                          O pagamento por boleto pode levar até 3 dias para ser
                          compensado.
                        </Typography>
                      </Grid>
                    </>
                  ) : null}

                  {plan.paymentType === PAYMENT_TYPE.CARD ||
                  plan.paymentType === PAYMENT_TYPE.BANKSLIP_CARD ? (
                    <FormControlLabel
                      value="cartao"
                      control={
                        <Radio
                          classes={{
                            root: verifyStepAccess()
                              ? styles.radioDisabledPayment
                              : styles.radioPayment,
                            checked: styles.checked,
                          }}
                        />
                      }
                      label="Pagar com cartão"
                      className={
                        verifyStepAccess()
                          ? styles.radioDisbaledWrapper
                          : styles.radioWrapper
                      }
                    />
                  ) : null}
                </RadioGroup>
              )}
            />
            <FormHelperText className={styles.paymentAlert}>
              {Boolean(errors.paymentType) &&
                'Selecione um metodo de pagamento'}
            </FormHelperText>
          </FormControl>
        </Grid>
        {paymentMethod === PAYMENT_TYPE.CARD && (
          <>
            <Grid item xs={12}>
              <Controller
                as={InputMask}
                mask={MASK.CREDIT_CARD}
                name="ccNumber"
                control={control}
                maskChar={null}
                onChange={([e]: any) => {
                  return UNMASK.onlyDigits(e.target.value);
                }}
              >
                {() => (
                  <MyOutlinedTextField
                    fullWidth
                    name="ccNumber"
                    label="Número do cartão de crédito"
                    type="text"
                    inputProps={{
                      ref: creditCardInput,
                      inputMode: 'numeric',
                      className: styles.input,
                    }}
                    changeFocusOnEnter={() => cardNameInput.current.focus()}
                    error={Boolean(errors.ccNumber)}
                    helperText={
                      errors.ccNumber ? errors.ccNumber.message : null
                    }
                    InputLabelProps={{ className: styles.label }}
                    isDisabled={verifyStepAccess()}
                  />
                )}
              </Controller>
            </Grid>
            <Grid item xs={12}>
              <MyOutlinedTextField
                fullWidth
                name="cardName"
                label="Nome impresso no cartão"
                type="text"
                inputProps={{
                  ref: cardNameInput,
                  maxLength: 25,
                  className: styles.input,
                }}
                changeFocusOnEnter={() => expiringDateInput.current.focus()}
                inputRef={register}
                error={Boolean(errors.cardName)}
                helperText={errors.cardName ? errors.cardName.message : null}
                InputLabelProps={{ className: styles.label }}
                isDisabled={verifyStepAccess()}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <MonthYearSelector
                register={register}
                setValue={setValue}
                disabled={verifyStepAccess()}
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <Controller
                as={InputMask}
                mask={MASK.CARD_CODE}
                control={control}
                name="cvv"
                maskChar={null}
                onChange={([event]: any) =>
                  UNMASK.onlyDigits(event.target.value)
                }
              >
                {() => (
                  <MyOutlinedTextField
                    name="cvv"
                    fullWidth
                    label="Cod. de Segurança"
                    type="text"
                    inputProps={{
                      ref: CVVInput,
                      className: styles.input,
                    }}
                    changeFocusOnEnter={() =>
                      (document.getElementById(
                        'coupon-input',
                      ) as HTMLElement).focus()
                    }
                    inputRef={register}
                    error={Boolean(errors.cvv)}
                    helperText={errors.cvv ? errors.cvv.message : null}
                    InputLabelProps={{ className: styles.label }}
                    isDisabled={verifyStepAccess()}
                  />
                )}
              </Controller>
            </Grid>
          </>
        )}
      </Grid>
    </Grid>
  );
};

export default AdressAndPaymentStep;
