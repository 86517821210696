import React, { useState, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { CircularProgress, Grid, Typography } from '@material-ui/core';

import OpenEndpointsService from 'services/openEndpointsService';
import { ReactComponent as BankslipError } from 'assets/img/svg/banskilp_error.svg';

import { RouteProps } from './types';
import { useStyles } from './styles';
import { ORDER_SECOND_COPY_BANKSLIP_ERROR } from 'utils/messages/order';
import { StatusCodes } from 'http-status-codes';

const GenerateBankslip: React.FC = () => {
  const { hash } = useParams<RouteProps>();
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean>(true);
  const [hasError, setHasError] = useState<boolean>(false);

  const getUrlBankslip = useCallback(async () => {
    try {
      const response = await OpenEndpointsService.getBankslipUrl(hash);

      if (response.status === StatusCodes.OK) {
        window.location.href = response.request.responseURL;
      } else {
        throw new Error(ORDER_SECOND_COPY_BANKSLIP_ERROR);
      }
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  }, [hash]);

  useEffect(() => {
    getUrlBankslip();
  }, [getUrlBankslip]);

  return (
    <Grid
      container
      spacing={3}
      direction="column"
      alignItems="center"
      justify="center"
      className={styles.container}
    >
      {loading ? (
        <>
          <Grid item>
            <CircularProgress size={80} className={styles.progress} />
          </Grid>
          <Grid item>
            <Typography variant="h2" className={styles.infoText}>
              Seu boleto está sendo gerado...
            </Typography>
          </Grid>
        </>
      ) : null}

      {hasError ? (
        <>
          <Grid item>
            <BankslipError className={styles.bankslipImg} />
          </Grid>
          <Grid item>
            <Typography variant="h2" className={styles.infoText}>
              Ops... parece que algo deu errado ao gerar o seu boleto.
            </Typography>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

export default GenerateBankslip;
