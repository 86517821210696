export const UPDATE_RENEW_DATE_ALERT =
  'Apenas planos semestrais e anuais serão alterados';
export const UPDATE_RENEW_DATE_SUCESS =
  'As datas de renovação estão sendo alteradas';
export const UPDATE_RENEW_DATE_ERROR =
  'Falha ao tentar atualizar data de renovação';

export const RETRY_PAYMENT_MESSAGE =
  'Deseja realizar a cobrança do pagamento de cartão de crédito?';
export const RETRY_PAYMENT_SUCESS =
  'As cobranças dos pedidos estão sendo realizadas';
export const RETRY_PAYMENT_ERROR = 'Falha ao tentar cobrar pedidos';

export const BULK_CHANGE_PLAN_MESSAGE = `Troca de planos iniciadas`;
export const BULK_CHANGE_PLAN_ALERT = `ATENÇÃO: Apenas assinaturas com algum
pedido pago nos ultimos 6 meses serão afetadas.`;
export const BULK_CHANGE_PLAN_ERROR = `Error ao alterar plano em massa`;

export const BULK_CREATE_ORDERS_MESSAGE = `Criação de pedidos iniciados`;
export const BULK_CREATE_ORDERS_ALERT = `ATENÇÃO: Apenas assinaturas com algum
pedido pago nos ultimos 6 meses serão afetadas.`;
export const BULK_CREATE_ORDERS_ERROR = `Error ao criar pedido em massa`;

export const BULK_ANTICIPE_RENEW_MESSAGE =
  'Deseja realizar a antecipação dessas assinaturas?';
export const BULK_ANTICIPE_RENEW_ERROR =
  'Error ao realizar a antecipação dessas assinaturas';
export const BULK_ANTICIPE_RENEW_SUCCESS = 'Antecipação executada';
