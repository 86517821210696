import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CondOperator, QueryJoinArr } from '@nestjsx/crud-request';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  TableCell,
  FormControlLabel,
} from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';
import { lastDayOfMonth, format, parseISO, startOfMonth } from 'date-fns';

import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MyDateRangeFilter from 'components/generals/input/MyDateRangeFilter';
import MySelectFilter from 'components/generals/input/MySelectFilter';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MyTableCell from 'components/generals/table/MyTableCell';
import MyCheckbox from 'components/generals/input/MyCheckbox';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import AutoCompleteDialog from 'components/generals/dialog/AutoCompleteDialog';
import {
  AutoCompleteDialogProps,
  AutoCompleteDialogFilter,
  OrderShippingDialogProps,
} from 'components/generals/dialog/dialogTypes';
import TagWrapper, { DEFINED_TAGS } from 'components/generals/tag';
import MySwitch from 'components/generals/input/MySwitch';
import OrderShippingDialog from 'components/generals/dialog/OrderShippingDialog';
import { UpdateInvoiceAccessKeyDialogProps } from 'components/generals/dialog/invoicesDialog/types';
import UpdateInvoiceKeyDialog from 'components/generals/dialog/invoicesDialog/UpdateInvoiceKeyDialog';
import { AppState } from 'store';
import {
  actUpdatePageTitle,
  openModal,
  closeModal,
} from 'store/ducks/nav/actions';
import { loadOrders } from 'store/ducks/order/actions';
import { OrderState } from 'store/ducks/order/types';
import { loadAllPlans } from 'store/ducks/generals/plan/actions';
import { PlanState } from 'store/ducks/generals/plan/types';
import { notifySuccess, notifyError } from 'store/ducks/notification/actions';

import { formatColumnValue } from 'helpers/formatters';
import {
  PAYMENT_TYPES_FILTER,
  CONSECUTIVE_MONTHS,
} from 'helpers/planConstants';
import { ORDER_STATUS_LIST_FILTER } from 'helpers/orderConstants';

import Colors from 'styles/colors';
import { useStyles } from './styles';
import { useFilters } from 'hooks/filters';
import { useGetWithSWR } from 'services/apiService';
import ShippingService from 'services/shippingService';
import { FormatOptions } from 'types/TableTypes';
import { ENHANCE_STATUS, Order, ORDER_TYPE } from 'types/generals/order';
import {
  ORDER_STATUS,
  PAYMENT_TYPE,
  Plan,
  TRANSPORT_COMPANIES,
  SIGNATURE_STATUS,
  SHIPPING_TYPE,
  Recurrence,
} from 'types/generals';
import { SIGNATURES_STATUS } from '../subscribers/utils';
import OrdersStoreActions from './OrdersStoreActions';

const Orders = () => {
  const { data: shippingToAutoComplete } = useGetWithSWR('shipping');
  const { data: recurrencesResources } = useGetWithSWR('/recurrences', {
    sort: { field: 'months', order: 'ASC' },
  });
  const [recurrences, setRecurrences] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const [orderIds, setOrderIds] = useState<any[]>([]);
  const [isResend, setIsResend] = useState<boolean>(false);
  const [displayHasMonthBox, setDisplayHasMonthBox] = useState<boolean>(false);
  const [displayHasPlanner, setDisplayHasPlanner] = useState<boolean>(false);
  const [displayHasGift, setDisplayHasGift] = useState<boolean>(false);
  const [displayIsFirst, setDisplayIsFirst] = useState<boolean>(false);
  const [displayIsNotResend, setDisplayIsNotResend] = useState<boolean>(false);
  const [displayIsValidAddress, setDisplayIsValidAddress] =
    useState<boolean>(false);
  const [displayEnhancedOrders, setDisplayEnhancedOrders] =
    useState<boolean>(false);
  const [isDonation, setIsDonation] = useState<boolean>(false);
  const [displayCurrentSubscription, setDisplayCurrentSubscription] =
    useState<boolean>(false);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [isFirstPaidOrder, setIsFirstPaidOrder] = useState(false);
  const [isFirstOrderGift, setIsFirstOrderGift] = useState(false);
  const [dateFilter, setDateFilter] = useState<Date[]>([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const styles = useStyles();
  const [filtersOptionsToAutoComplete, setFiltersOptionsToAutoComplete] =
    useState<AutoCompleteDialogFilter[]>([]);

  const {
    data: orders = [],
    total = 0,
    isLoading,
    hasError,
  } = useSelector<AppState, OrderState>(
    ({ ordersListReducer }: AppState) => ordersListReducer,
  );

  const { data: plans = [] } = useSelector<AppState, PlanState>(
    ({ plans }: AppState) => plans,
  );

  const searchObject = [
    {
      field: 'orderDetail.userEmail',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'orderDetail.userIdentification',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'orderDetail.zipcode',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'orderDetail.userPhone',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'orderDetail.userName',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'orderDetail.userIdentification',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'id',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
    {
      field: 'signatureId',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
  ];

  const orderIsInShipmentObject = [
    { title: 'Sim', value: true },
    { title: 'Não', value: false },
  ];

  const {
    setInitialSort,
    filterFields,
    genericFilterFields,
    sort,
    pageFilters,
    page,
    handleGenericSearch,
    handleUpdateFilters,
    handleSort,
    handleClearFilters,
    handleClearPageFilter,
    handleSetValuePage,
  } = useFilters({
    status: { filterTitle: 'Status', value: undefined },
    isResend: { filterTitle: 'Reenvio', value: undefined },
    series: { filterTitle: 'Série', value: undefined },
    paymentDate: { filterTitle: 'Período', value: undefined },
    'orderDetail.orderType': { filterTitle: 'Tipo', value: undefined },
    'orderDetail.paymentType': {
      filterTitle: 'Pagamento',
      value: undefined,
    },
    'orderDetail.addressIsValid': {
      filterTitle: 'Pedidos comendereço inválidos',
      value: undefined,
    },
    'signature.planId': {
      filterTitle: 'Plano',
      value: undefined,
    },
    'orderDetail.transport': { filterTitle: 'Transporte', value: undefined },
    'signature.user.status': {
      filterTitle: 'Status assinante',
      value: undefined,
    },
    'signature.user.countPaidMonths': {
      filterTitle: 'Meses Consec.',
      value: undefined,
    },
    hasMonthBox: {
      filterTitle: 'Prontos para enviar no mês',
      value: undefined,
    },
    'signature.user.hasPlanner': {
      filterTitle: 'Não recebeu planner',
      value: undefined,
    },
    'signature.user.hasGift': {
      filterTitle: 'Não recebeu brinde',
      value: undefined,
    },
    'signature.user.currentSubscription': {
      filterTitle: 'Assinatura atual',
      value: undefined,
    },
    'signature.status': {
      filterTitle: 'Status da assinatura',
      value: undefined,
    },
    'signature.plan.recurrenceId': {
      filterTitle: 'Recorrência',
      value: undefined,
    },
    isFirst: {
      filterTitle: 'Primeiro mês da assinatura',
      value: undefined,
    },
    orderIsInShipment: { filterTitle: 'Pedidos em remessa?', value: undefined },
    dontShowResendOrders: {
      filterTitle: 'Não mostrar pedidos reenviados',
      value: undefined,
    },
    isFirstPaidOrder: { filterTitle: 'Primeiro pedido?', value: undefined },
    isFirstOrderGift: { filterTitle: 'Contem brinde', value: undefined },
    isDonation: { filterTitle: 'Doação', value: undefined },
    enhanceStatus: {
      filterTitle: 'Pedido turbinado',
      value: undefined,
    },
  });

  const loadAllOrders = useCallback(() => {
    if (dateFilter.length && dateFilter[0] !== null && dateFilter[1] !== null) {
      const join: QueryJoinArr[] = [['orderDetail'], ['invoices']];
      const recurrenceFilter = filterFields.find(
        field => field?.['signature.plan.recurrenceId'],
      );
      const plansFilter = filterFields.find(
        field => field?.['signature.planId'],
      );
      const signatureFilter = filterFields.find(
        field =>
          field?.['signature.user.status'] ||
          field?.['signature.user.countPaidMonths'] ||
          field?.['signature.user.hasMonthBox'] ||
          field?.['signature.user.hasPlanner'] ||
          field?.['signature.user.hasGift'] ||
          field?.['signature.user.currentSubscription'],
      );

      if (plansFilter || signatureFilter) {
        join.push(['signature']);
      }
      let filterCurrentSignature: any;
      if (signatureFilter) {
        join.push(['signature.user']);
        if (displayCurrentSubscription) {
          filterCurrentSignature = {
            'signature.status': { $eq: SIGNATURE_STATUS.ACTIVE },
          };
        }
      }
      if (recurrenceFilter) {
        join.push(['signature']);
        join.push(['signature.plan']);
      }

      dispatch(
        loadOrders({
          join,
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [
              ...filterFields,
              { 'orderDetail.orderType': ORDER_TYPE.SIGNATURE },
              { isEnhanced: false },
              {
                paymentDate: {
                  $between: dateFilter,
                },
              },
              displayCurrentSubscription ? filterCurrentSignature : {},
              { $or: [...genericFilterFields] },
            ],
          },
        }),
      );
    }
  }, [
    dispatch,
    dateFilter,
    page,
    rowsPerPage,
    sort,
    genericFilterFields,
    filterFields,
    displayCurrentSubscription,
  ]);

  const formatDate = (data: string) => {
    return format(parseISO(data), 'dd/MM/yyyy');
  };

  const formatMoney = (amount: number) => {
    return formatColumnValue(FormatOptions.MONEY, amount);
  };

  const handleSelectNoteToShipping = (id: number) => {
    const findInvoiceId = orderIds.find((value: any) => value === id);
    findInvoiceId
      ? setOrderIds(orderIds.filter(value => value !== id))
      : setOrderIds([...orderIds, id]);
  };

  const isSelected = (value: any) => orderIds.includes(value);

  const handleSelectAllNotesToShipping = (orders: Order[]) => {
    const myOrders = orders.filter(
      order =>
        (order.status === ORDER_STATUS.PAID ||
          order.status === ORDER_STATUS.PAYMENT_REFUND) &&
        !order.orderIsInShipment,
    );
    const ordersIds = myOrders.map(order => order.id);
    setOrderIds(ordersIds);

    if (selectedAll) {
      setOrderIds([]);
    }
    setSelectedAll(!selectedAll);
  };

  const handleLoadFiltersOptionsToAutoComplete = useCallback(async () => {
    const shippingList = shippingToAutoComplete?.data.map((shipping: any) => ({
      id: shipping.id,
      title: shipping.name,
    }));

    setFiltersOptionsToAutoComplete(shippingList);
  }, [shippingToAutoComplete]);

  const handleCreateShipping = () => {
    const dialogProps: AutoCompleteDialogProps = {
      modalTitle: 'Adicionar à Remessa',
      modalInfo: `${orderIds.length} item(ns) selecionado(s)`,
      labelPlaceholder: 'Buscar Remessa',
      options: filtersOptionsToAutoComplete,
      submitText: 'Adicionar',
      clearInputBlur: true,
      submitAction: (shippingName?: any) =>
        confirmationSubmitShipping(shippingName),
    };

    dispatch(openModal(AutoCompleteDialog, dialogProps));
  };

  const handleCreateNewShipping = () => {
    const dialogProps: OrderShippingDialogProps = {
      modalTitle: 'Criar Remessa',
      modalInfo: `${orderIds.length} item(ns) selecionado(s)`,
      submitText: 'Confirmar',
      ordersIds: orderIds,
      shippingType: SHIPPING_TYPE.SIGNATURE,
    };

    dispatch(openModal(OrderShippingDialog, dialogProps));
  };

  const handleUpdateInvoiceAccessKey = () => {
    const modalProps: UpdateInvoiceAccessKeyDialogProps = {
      orderIds,
    };

    dispatch(openModal(UpdateInvoiceKeyDialog, modalProps));
  };

  const confirmationSubmitShipping = async (shipping: any) => {
    const shippingTitle = shipping.title ? shipping.title : shipping;
    try {
      dispatch(
        openModal(ConfirmationDialog, {
          title: 'Adicionar à Remessa',
          message: `Deseja adicionar ${orderIds.length} item(ns) à ${shippingTitle}?`,
          actionFn: () => {
            if (shipping.title) {
              submitGenerateShipping(shipping.title, shipping.id);
            } else submitGenerateShipping(shippingTitle);
            dispatch(closeModal());
          },
        }),
      );
    } catch (err) {
      throw new Error(err);
    }
  };

  const submitGenerateShipping = async (
    shippingName: string,
    shippingId?: number,
  ) => {
    const invoicesCount = orderIds.length;
    let shippingResponse;

    if (shippingId) {
      shippingResponse = await ShippingService.addOrderInShipping(
        shippingId,
        orderIds,
      );
    } else {
      shippingResponse = await ShippingService.createShiping(
        shippingName,
        orderIds,
      );
    }

    if (shippingResponse.statusCode !== StatusCodes.BAD_REQUEST) {
      dispatch(
        notifySuccess(
          `${
            invoicesCount === 1
              ? `${invoicesCount} pedido foi adicionado`
              : `${invoicesCount} pedidos foram adicionados`
          } à ${shippingName}`,
        ),
      );
      setOrderIds([]);
      loadAllOrders();
    } else {
      dispatch(notifyError(shippingResponse.message));
    }
  };

  const paymentTypeFormat = (paymentType: PAYMENT_TYPE) => {
    switch (paymentType) {
      case PAYMENT_TYPE.CARD:
        return 'Cartão';
      case PAYMENT_TYPE.BANKSLIP:
        return 'Boleto';
    }
  };

  const redirectToOrder = (order: Order) =>
    history.push(`/admin/orders/${order.id}`);

  const loadPlansList = useCallback(() => {
    dispatch(loadAllPlans());
  }, [dispatch]);

  useEffect(() => {
    if (recurrencesResources) {
      const MyRecurrences = recurrencesResources.data.map(
        (rec: Recurrence) => ({
          ...rec,
          title: rec.name,
          value: rec.id,
        }),
      );
      setRecurrences(MyRecurrences);
    }
  }, [recurrencesResources]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Pedidos De Assinatura'));
  }, [dispatch]);

  useEffect(() => {
    loadAllOrders();
  }, [loadAllOrders]);

  useEffect(() => {
    loadPlansList();
  }, [loadPlansList]);

  useEffect(() => {
    handleLoadFiltersOptionsToAutoComplete();
  }, [handleLoadFiltersOptionsToAutoComplete]);

  useEffect(() => {
    setInitialSort({ field: 'paymentDate', order: 'DESC' });
  }, [setInitialSort]);

  useEffect(() => {
    setDateFilter([startOfMonth(new Date()), lastDayOfMonth(new Date())]);
  }, []);

  const getFalseCheckboxValue = useCallback(
    (field: string) => {
      return pageFilters[field]?.value === false ? true : false;
    },
    [pageFilters],
  );

  const initLocalFilters = useCallback(() => {
    if (pageFilters) {
      setDisplayCurrentSubscription(
        pageFilters['signature.user.currentSubscription']?.value,
      );
      setDisplayHasMonthBox(pageFilters['hasMonthBox']?.value);
      setDisplayHasPlanner(getFalseCheckboxValue('signature.user.hasPlanner'));
      setDisplayHasGift(getFalseCheckboxValue('signature.user.hasGift'));
      setDisplayIsNotResend(getFalseCheckboxValue('dontShowResendOrders'));
      setDisplayIsFirst(pageFilters['isFirst']?.value);
      setIsResend(pageFilters['isResend']?.value);
      setDisplayIsValidAddress(
        getFalseCheckboxValue('orderDetail.addressIsValid'),
      );
      setIsFirstOrderGift(pageFilters['isFirstOrderGift'].value);
      setIsFirstPaidOrder(pageFilters['isFirstPaidOrder'].value);
      setIsDonation(pageFilters['isDonation'].value);
      setDisplayEnhancedOrders(pageFilters['enhanceStatus'].value);
    }
  }, [getFalseCheckboxValue, pageFilters]);

  useEffect(() => {
    initLocalFilters();
  }, [initLocalFilters]);

  const RenderGridActions = () => (
    <Grid item xs={4} sm={4} md={2} lg={2} xl={2}>
      <MyDropdown
        buttonProps={{
          color: 'primary',
          variant: 'contained',
          style: {
            width: '100%',
            borderRadius: 13,
          },
          disabled: orderIds.length === 0,
        }}
        menuProps={{
          classes: {
            list: styles.filterOptionsList,
          },
          transformOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          PaperProps: {
            style: {
              marginTop: '1rem',
              borderRadius: 13,
            },
          },
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }}
        menuItems={[
          {
            title: 'Adicionar à Remessa',
            action: () => handleCreateShipping(),
          },
          {
            title: 'Criar Remessa',
            action: () => handleCreateNewShipping(),
          },
          {
            title: 'Atualizar chaves NF',
            action: () => handleUpdateInvoiceAccessKey(),
          },
        ]}
      >
        <Typography
          variant="h3"
          style={{
            fontSize: '1.6rem',
            fontWeight: 'bold',
            textTransform: 'capitalize',
            width: '100%',
            textAlign: 'left',
          }}
        >
          Ações
        </Typography>
      </MyDropdown>
    </Grid>
  );

  const RenderFilters = () => (
    <>
      <Grid item xs={12} sm={6} md={2}>
        <MySelectFilter
          defaultOption={() => handleClearPageFilter('status')}
          menuItems={ORDER_STATUS_LIST_FILTER.map(option => ({
            title: option,
            action: () =>
              handleUpdateFilters(
                'status',
                {
                  filterTitle: option,
                  value: option,
                },
                CondOperator.EQUALS,
              ),
            className: styles.menuOption,
          }))}
          buttonProps={{
            style: {
              borderColor: pageFilters.status.value ? Colors.RED : Colors.WHITE,
            },
          }}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            style={{
              fontSize: '1.6rem',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {pageFilters.status.filterTitle}
          </Typography>
        </MySelectFilter>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MySelectFilter
          defaultOption={() => handleClearPageFilter('signature.user.status')}
          menuItems={SIGNATURES_STATUS.map(status => ({
            title: status,
            action: () =>
              handleUpdateFilters(
                'signature.user.status',
                {
                  filterTitle: status,
                  value: status,
                },
                CondOperator.EQUALS,
              ),
            className: styles.menuOption,
          }))}
          buttonProps={{
            style: {
              borderColor: pageFilters?.['signature.user.status'].value
                ? Colors.RED
                : Colors.WHITE,
            },
          }}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            style={{
              fontSize: '1.6rem',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {pageFilters['signature.user.status']?.filterTitle}
          </Typography>
        </MySelectFilter>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MySelectFilter
          defaultOption={() => handleClearPageFilter('orderDetail.paymentType')}
          menuItems={PAYMENT_TYPES_FILTER.map(option => ({
            title: option.value,
            action: () =>
              handleUpdateFilters(
                'orderDetail.paymentType',
                {
                  filterTitle: option.title,
                  value: option.value,
                },
                CondOperator.EQUALS,
              ),
            className: styles.menuOption,
          }))}
          buttonProps={{
            style: {
              borderColor: pageFilters['orderDetail.paymentType'].value
                ? Colors.RED
                : Colors.WHITE,
            },
          }}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            style={{
              fontSize: '1.6rem',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {pageFilters['orderDetail.paymentType'].filterTitle}
          </Typography>
        </MySelectFilter>
      </Grid>

      <Grid item xs={12} sm={6} md={2}>
        <MySelectFilter
          defaultOption={() =>
            handleClearPageFilter('signature.plan.recurrenceId')
          }
          menuItems={recurrences.map((recurrence: Recurrence) => ({
            title: recurrence.name,
            action: () =>
              handleUpdateFilters(
                'signature.plan.recurrenceId',
                {
                  filterTitle: recurrence.name,
                  value: recurrence.id,
                },
                CondOperator.EQUALS,
              ),
            className: styles.menuOption,
          }))}
          buttonProps={{
            style: {
              borderColor: pageFilters['signature.plan.recurrenceId'].value
                ? Colors.RED
                : Colors.WHITE,
            },
          }}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            style={{
              fontSize: '1.6rem',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {pageFilters['signature.plan.recurrenceId'].filterTitle}
          </Typography>
        </MySelectFilter>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MySelectFilter
          defaultOption={() => handleClearPageFilter('signature.planId')}
          menuItems={plans.map((plan: Plan) => ({
            title: plan.name,
            action: () =>
              handleUpdateFilters(
                'signature.planId',
                {
                  filterTitle: plan.name,
                  value: plan.id,
                },
                CondOperator.EQUALS,
              ),
            className: styles.menuOption,
          }))}
          buttonProps={{
            style: {
              borderColor: pageFilters['signature.planId'].value
                ? Colors.RED
                : Colors.WHITE,
            },
          }}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            style={{
              fontSize: '1.6rem',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {pageFilters['signature.planId'].filterTitle}
          </Typography>
        </MySelectFilter>
      </Grid>
      <Grid item xs={12} sm={6} md={1}>
        <MySelectFilter
          defaultOption={() => handleClearPageFilter('orderDetail.transport')}
          menuItems={Object.values(TRANSPORT_COMPANIES).map(transport => ({
            title: transport,
            action: () =>
              handleUpdateFilters(
                'orderDetail.transport',
                {
                  filterTitle: transport,
                  value: transport,
                },
                CondOperator.EQUALS,
              ),
            className: styles.menuOption,
          }))}
          buttonProps={{
            style: {
              borderColor: pageFilters['orderDetail.transport'].value
                ? Colors.RED
                : Colors.WHITE,
            },
          }}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            style={{
              fontSize: '1.6rem',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {pageFilters['orderDetail.transport'].filterTitle}
          </Typography>
        </MySelectFilter>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MySelectFilter
          defaultOption={() =>
            handleClearPageFilter('signature.user.countPaidMonths')
          }
          menuItems={CONSECUTIVE_MONTHS.map(value => ({
            title: value,
            action: () =>
              handleUpdateFilters(
                'signature.user.countPaidMonths',
                {
                  filterTitle: value,
                  value: value,
                },
                CondOperator.EQUALS,
              ),
            className: styles.menuOption,
          }))}
          buttonProps={{
            style: {
              borderColor: pageFilters?.['signature.user.countPaidMonths'].value
                ? Colors.RED
                : Colors.WHITE,
            },
          }}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            style={{
              fontSize: '1.6rem',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {pageFilters['signature.user.countPaidMonths'].filterTitle}
          </Typography>
        </MySelectFilter>
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <MySelectFilter
          defaultOption={() => handleClearPageFilter('orderIsInShipment')}
          menuItems={orderIsInShipmentObject.map(option => ({
            title: option.title,
            action: () =>
              handleUpdateFilters(
                'orderIsInShipment',
                {
                  filterTitle: option.title,
                  value: option.value,
                },
                CondOperator.EQUALS,
              ),
            className: styles.menuOption,
          }))}
          buttonProps={{
            style: {
              borderColor: pageFilters.orderIsInShipment.value
                ? Colors.RED
                : Colors.WHITE,
            },
          }}
        >
          <Typography
            variant="h3"
            color="textPrimary"
            style={{
              fontSize: '1.6rem',
              fontWeight: 'bold',
              textTransform: 'capitalize',
              width: '100%',
              textAlign: 'left',
            }}
          >
            {pageFilters.orderIsInShipment.filterTitle}
          </Typography>
        </MySelectFilter>
      </Grid>
      <Grid container item xs={12} sm={6} md={1}>
        <FormControlLabel
          control={
            <MySwitch
              name="currentSubscription"
              color="primary"
              checked={displayCurrentSubscription}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'signature.user.currentSubscription',
                  {
                    filterTitle: '',
                    value: e.target.checked ? true : undefined,
                  },
                  CondOperator.NOT_NULL,
                );
                setDisplayCurrentSubscription(e.target.checked);
              }}
            />
          }
          label="Assinatura atual"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="hasGiftSwitch"
              color="primary"
              checked={displayHasGift}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'signature.user.hasGift',
                  {
                    filterTitle: '',
                    value: e.target.checked ? false : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setDisplayHasGift(e.target.checked);
              }}
            />
          }
          label="Não exibir quem já recebeu brinde"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="hasPlannerSwitch"
              color="primary"
              checked={displayHasPlanner}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'signature.user.hasPlanner',
                  {
                    filterTitle: '',
                    value: e.target.checked ? false : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setDisplayHasPlanner(e.target.checked);
              }}
            />
          }
          label="Não exibir quem já recebeu planner"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="isFirst"
              color="primary"
              checked={displayIsFirst}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'isFirst',
                  {
                    filterTitle: '',
                    value: e.target.checked ? true : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setDisplayIsFirst(e.target.checked);
              }}
            />
          }
          label="Primeiro mês da assinatura"
        />
      </Grid>
      <Grid container item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="hasMonthBoxSwitch"
              color="primary"
              checked={displayHasMonthBox}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'hasMonthBox',
                  {
                    filterTitle: '',
                    value: e.target.checked ? true : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setDisplayHasMonthBox(e.target.checked);
              }}
            />
          }
          label="Mostrar pedidos prontos para enviar o box do mês"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="isFirstPaidOrder"
              color="primary"
              checked={isFirstPaidOrder}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'isFirstPaidOrder',
                  {
                    filterTitle: '',
                    value: e.target.checked ? true : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setIsFirstPaidOrder(e.target.checked);
              }}
            />
          }
          label="Primeiro pedido do assinante"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="isFirstOrderGift"
              color="primary"
              checked={isFirstOrderGift}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'isFirstOrderGift',
                  {
                    filterTitle: '',
                    value: e.target.checked ? true : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setIsFirstOrderGift(e.target.checked);
              }}
            />
          }
          label="Contem brinde"
        />
      </Grid>
    </>
  );

  const OrdersList = () => (
    <Paper className={styles.ordersList} elevation={0}>
      <TableContainer>
        <Table className={styles.table} aria-label="orders table">
          <TableHead>
            <TableRow>
              <TableCell>
                <MyCheckbox
                  disabled={false}
                  onClick={() => handleSelectAllNotesToShipping(orders)}
                  checked={selectedAll}
                />
              </TableCell>
              <MySortableTableCell
                onClick={() => handleSort('id')}
                direction={sort.field === 'id' && sort.order}
              >
                Nº Pedido
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('order.orderDetail.orderType')}
                direction={
                  sort.field === 'order.orderDetail.orderType' && sort.order
                }
              >
                Tipo
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('status')}
                direction={sort.field === 'status' && sort.order}
              >
                Status
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('orderIsInShipment')}
                direction={sort.field === 'orderIsInShipment' && sort.order}
              >
                Pedido em remessa?
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('paymentDate')}
                direction={sort.field === 'paymentDate' && sort.order}
              >
                Data Pagamento
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('orderDetail.userName')}
                direction={sort.field === 'orderDetail.userName' && sort.order}
              >
                Cliente
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('order.orderDetail.paymentType')}
                direction={
                  sort.field === 'order.orderDetail.paymentType' && sort.order
                }
              >
                Pagamento
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('orderDetail.transport')}
                direction={sort.field === 'orderDetail.transport' && sort.order}
              >
                Transportadora
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('orderDetail.freight')}
                direction={sort.field === 'orderDetail.freight' && sort.order}
              >
                Frete
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('orderDetail.discount')}
                direction={sort.field === 'orderDetail.discount' && sort.order}
              >
                Cupom
              </MySortableTableCell>
              <TableCell>Total</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!isLoading &&
              !hasError &&
              orders.map(order => (
                <TableRow key={order.id}>
                  <TableCell>
                    <MyCheckbox
                      disabled={
                        (order.status !== ORDER_STATUS.PAID &&
                          order.status !== ORDER_STATUS.PAYMENT_REFUND) ||
                        order.orderIsInShipment
                      }
                      onClick={() => handleSelectNoteToShipping(order?.id)}
                      checked={isSelected(order?.id)}
                    />
                  </TableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {order.id}
                    {order.isFirstPaidOrder ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.STATUS}
                          value={'1ª pedido'}
                        />
                      </Grid>
                    ) : null}
                    {order.isFirstOrderGift ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.INFO}
                          value={'Brinde'}
                        />
                      </Grid>
                    ) : null}
                  </MyTableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {order.orderDetail.orderType}
                    {order.isResend ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.STATUS}
                          value={'Reenvio'}
                        />
                      </Grid>
                    ) : null}
                    {order.isDonation ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.INFO}
                          value={'Doação'}
                        />
                      </Grid>
                    ) : null}
                    {order.enhanceStatus === ENHANCE_STATUS.PARENT_ENHANCED ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.MESSAGE}
                          value={'Pedido turbinado'}
                        />
                      </Grid>
                    ) : null}
                  </MyTableCell>

                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {order.status}
                    {order.shipping ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.INFO}
                          value={order.shipping.name}
                        />
                      </Grid>
                    ) : null}
                  </MyTableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {order.orderIsInShipment ? 'Sim' : 'Não'}
                  </MyTableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {formatDate(order.paymentDate) || '-'}
                  </MyTableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                    style={{
                      textTransform: 'capitalize',
                      lineHeight: 'normal',
                    }}
                  >
                    {order.orderDetail?.userName || '-'}
                  </MyTableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {paymentTypeFormat(order.orderDetail.paymentType)}
                  </MyTableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {order.orderDetail.transport}
                  </MyTableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {(order.orderDetail &&
                      formatMoney(order.orderDetail.freight)) ||
                      '-'}
                  </MyTableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {(order.orderDetail &&
                      formatMoney(order.orderDetail.discount)) ||
                      '-'}
                  </MyTableCell>
                  <MyTableCell
                    className={styles.clickableArea}
                    onClick={() => redirectToOrder(order)}
                  >
                    {(order.orderDetail &&
                      formatMoney(order.orderDetail.amount)) ||
                      '-'}
                  </MyTableCell>
                  <MyTableCell>
                    <OrdersStoreActions order={order} />
                  </MyTableCell>
                </TableRow>
              ))) ||
              null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );

  const RenderGridTablePagination = () => (
    <Grid container justify="space-between">
      <Grid item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="incorrectAddressesSwitch"
              color="primary"
              checked={isResend}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'isResend',
                  {
                    filterTitle: '',
                    value: e.target.checked ? true : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setIsResend(e.target.checked);
              }}
            />
          }
          label="Mostrar apenas pedidos reenviados"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="dontShowResendOrders"
              color="primary"
              checked={displayIsNotResend}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'dontShowResendOrders',
                  {
                    filterTitle: '',
                    value: e.target.checked ? false : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setDisplayIsNotResend(e.target.checked);
              }}
            />
          }
          label="Não mostrar pedidos reenviados"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="isValidAddressesSwitch"
              color="primary"
              checked={displayIsValidAddress}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'orderDetail.addressIsValid',
                  {
                    filterTitle: '',
                    value: e.target.checked ? false : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setDisplayIsValidAddress(e.target.checked);
              }}
            />
          }
          label="Mostrar apenas endereços incorretos"
        />
      </Grid>
      <Grid item xs={12} sm={6} md={2}>
        <FormControlLabel
          control={
            <MySwitch
              name="enhanceStatus"
              color="primary"
              checked={displayEnhancedOrders}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'enhanceStatus',
                  {
                    filterTitle: '',
                    value: e.target.checked
                      ? ENHANCE_STATUS.PARENT_ENHANCED
                      : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setDisplayEnhancedOrders(e.target.checked);
              }}
            />
          }
          label="Mostrar apenas pedidos turbinados"
        />
      </Grid>
      <Grid>
        <FormControlLabel
          control={
            <MySwitch
              name="isDonation"
              color="primary"
              checked={isDonation}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                handleUpdateFilters(
                  'isDonation',
                  {
                    filterTitle: '',
                    value: e.target.checked ? true : undefined,
                  },
                  CondOperator.EQUALS,
                );
                setIsDonation(e.target.checked);
              }}
            />
          }
          label="Mostrar apenas doações"
        />
      </Grid>
      <Grid item xs>
        <TablePagination
          ActionsComponent={MyTablePaginationActions}
          component="div"
          count={total}
          page={page}
          labelRowsPerPage="Itens por página"
          onChangePage={(_event: unknown, newPage: number) =>
            handleSetValuePage(newPage)
          }
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
            handleSetValuePage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
          }}
          rowsPerPageOptions={ROWS_PER_PAGE}
        />
      </Grid>
    </Grid>
  );

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={3}
        style={{ marginTop: 20, marginBottom: 10 }}
      >
        <Grid item>
          <Button style={{ width: '100%' }} onClick={handleClearFilters}>
            <Typography
              variant="h4"
              color="textPrimary"
              style={{
                color: Colors.RED,
                fontWeight: 'bold',
                textTransform: 'capitalize',
              }}
            >
              Limpar filtros
            </Typography>
          </Button>
        </Grid>
        <Grid item>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
        </Grid>
        <RenderGridActions />
        <Grid
          container
          justify="flex-start"
          alignItems="center"
          className={styles.gridAction}
          spacing={3}
          style={{ marginBottom: 5 }}
        >
          <Grid item xs={12} sm={6} md={2} lg={2} xl={2}>
            <MyDateRangeFilter
              value={dateFilter}
              onChange={(date: Date[]) => setDateFilter(date)}
            />
          </Grid>
          <RenderFilters />
        </Grid>
      </Grid>
      <Grid container direction="column">
        <OrdersList />
      </Grid>
      <RenderGridTablePagination />
    </Grid>
  );
};

export default Orders;
