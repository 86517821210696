import { createMuiTheme, makeStyles, createStyles } from '@material-ui/core';
import { Colors } from './colors';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: Colors.RED,
    },
    background: {
      paper: Colors.WHITE,
      default: Colors.WHITE,
    },
    grey: {
      '50': '#F2F2F2',
      '100': '#EBEBEB',
      '200': '#D8D8D8',
      '400': '#ACACAB',
      '500': '#979797',
      '600': '#716F73',
      '800': '#3C3C3B',
    },
  },
  typography: {
    fontSize: 10,
    htmlFontSize: 10,
    fontFamily: ['Raleway', 'sans-serif'].join(','),
  },
  overrides: {
    MuiAvatar: {
      rounded: {
        borderRadius: 13,
      },
      root: {
        width: '5rem',
        height: '5rem',
      },
    },
    MuiTab: {
      root: {
        fontSize: '1.1rem',
      },
      wrapper: {
        fontWeight: 700,
      },
    },
    MuiTabs: {
      flexContainer: {
        justifyContent: 'space-around',
      },
    },
    MuiButton: {
      root: {
        textTransform: 'none',
        fontWeight: 'bold',
      },
    },
    MuiDialog: {
      paper: {
        padding: '20px',
        borderRadius: 16,
      },
    },
    MuiDialogTitle: {
      root: {
        color: Colors.DARKER_GRAY,
        fontSize: '2.0rem',
        padding: '8px 16px',
      },
    },
    MuiDialogContent: {
      root: {
        padding: '8px 16px',
      },
    },
    MuiDialogActions: {
      root: {
        padding: '8px 16px',
      },
    },
    MuiInputLabel: {
      root: {
        color: Colors.DARKER_GRAY,
        fontSize: '1.4rem',
        fontWeight: 'bold',
        '&$focused:not($disabled):not($error)': {
          color: Colors.DARKER_GRAY,
        },
      },
      outlined: {
        '&$shrink': {
          transform: 'translate(12px, 10px) scale(0.75)',
        },
      },
    },
    MuiFormHelperText: {
      contained: {
        marginLeft: 0,
      },
    },
    MuiMenuItem: {
      root: {
        fontSize: '1.4rem',
        minWidth: '17.5rem',
        '&:hover': {
          backgroundColor: Colors.LIGHT_GRAY,
        },
      },
    },
    MuiList: {
      root: {
        borderRadius: '0.3rem',
        backgroundColor: Colors.LIGHTER_GRAY,
      },
    },
    MuiTooltip: {
      tooltip: {
        fontSize: '1.04em',
        fontWeight: 700,
      },
    },
    MuiBadge: {
      badge: {
        padding: 0,
        fontSize: '1.3rem',
        fontWeight: 'bold',
        lineHeight: 2,
        border: '2px',
        borderStyle: 'solid',
        borderColor: Colors.LIGHT_GRAY,
        backgroundColor: Colors.WHITE,
      },
      anchorOriginTopRightCircle: {
        backgroundColor: Colors.RED,
        cursor: 'pointer',
        top: '5%',
        right: '10%',
        transform: 'scale(1) translate(50%, -50%)',
        transformOrigin: '100% 0%',
      },
    },
    MuiInputBase: {
      input: {
        fontSize: '14px',
        fontWeight: 600,
      },
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '1.4rem',
        '& $notchedOutline': {
          borderColor: Colors.GRAY,
        },
        '&$focused $notchedOutline': {
          borderColor: Colors.DARK_GRAY,
          borderWidth: 1,
        },
        '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
          borderColor: Colors.DARK_GRAY,
        },
        '&$disabled': {
          '& fieldset.MuiOutlinedInput-notchedOutline': {
            borderColor: Colors.WHITE_GRAY,
          },
        },
      },
      input: {
        padding: '27px 12px 10px',
        fontSize: '16px',
      },
      multiline: {
        paddingTop: '27px',
        fontSize: '1.75rem',
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&$checked': {
          color: Colors.RED,
        },
      },
    },
    MuiPaper: {
      elevation8: {
        boxShadow: '0 0.2rem 5.4rem 0 rgba(0, 0, 0, 0.26)',
      },
    },
    MuiTableCell: {
      root: {
        maxWidth: '190px',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
      },
      head: {
        fontSize: '1.2rem',
        color: Colors.DARKER_GRAY,
        fontWeight: 'bold',
        textTransform: 'uppercase',
      },
      body: {
        fontSize: '1.4rem',
      },
    },
    MuiListItem: {
      root: {
        fontSize: '60px',
      },
    },
    MuiTablePagination: {
      select: {
        paddingTop: '12px',
        fontSize: '1.4rem',
      },
      selectIcon: {
        paddingTop: '6px',
      },
      caption: {
        fontSize: '1.4rem',
      },
    },
    MuiTableRow: {
      head: {
        '&:nth-of-type(odd)': {
          backgroundColor: Colors.WHITE,
        },
      },
      root: {
        '&:nth-of-type(odd)': {
          backgroundColor: Colors.LIGHTER_GRAY,
        },
      },
    },
    MuiTypography: {
      colorTextPrimary: {
        color: Colors.DARKER_GRAY,
      },
      colorTextSecondary: {
        color: Colors.DARK_GRAY,
      },
      h1: {
        fontSize: '2.5rem',
      },
      h2: {
        fontSize: '2rem',
      },
      h3: {
        fontSize: '1.6rem',
      },
      h4: {
        fontSize: '1.4rem',
      },
      h5: {
        fontSize: '1.2rem',
      },
      h6: {
        fontSize: '1rem',
      },
    },
  },
});

/*
 * Styles que define espaço default das telas, a ideia é manter um padrão de
 * espaço da tela apos o sidebar e o header
 */
export const defaultStyles = makeStyles(() =>
  createStyles({
    rootPanel: {
      padding: theme.spacing(5.5, 0),
      height: '100%',
    },
    rootPaper: {
      padding: theme.spacing(3, 4),
      borderRadius: '2em',
      height: '100%',
    },
    mTop: {
      marginTop: theme.spacing(5),
    },
    paddingButton: {
      padding: theme.spacing(2, 4),
    },
  }),
);

export default theme;
