import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  description: Yup.string().required('Descrição obrigatório'),
});

export const defaultValues = (role: any) => {
  return {
    name: role?.name || '',
    description: role?.description || '',
  };
};
