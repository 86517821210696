import { CreateQueryParams } from '@nestjsx/crud-request';
import CrudList from 'components/generals/lists/CrudList';
import { StatusCodes } from 'http-status-codes';
import React, { FC, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import RolesService from 'services/RolesService';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { ColumnProps, ColumnTypes, Sort } from 'types';
import { Roles } from 'types/generals';
import { DELETE_ROLES_ERROR, DELETE_ROLES_SUCCESS } from 'utils/messages';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';
import { closeModal, openModal } from 'store/ducks/nav/actions';

const RolesList: FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [refresh, setRefresh] = useState(false);

  const handleOpenDeleteDialog = (role: Roles) => {
    const modalProps: BaseDialogProps = {
      title: 'Excluir permissão',
      message: `Tem certeza que deseja excluir a permissão ${role.name}?`,
      actionFn: () => onDelete(role),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const onDelete = async (role: Roles) => {
    const response: any = await RolesService.deleteRole(role?.id);
    if (![StatusCodes.OK].includes(response.status)) {
      dispatch(notifyError(DELETE_ROLES_ERROR));
    } else {
      if (StatusCodes.OK === response.status) {
        dispatch(notifySuccess(DELETE_ROLES_SUCCESS));
      }
      setRefresh(true);
    }
    dispatch(closeModal());
  };

  const columns: ColumnProps[] = [
    { label: 'NOME', getter: 'name', sortable: true },
    { label: 'DESCRIÇÃO', getter: 'description', sortable: true },
    {
      label: 'AÇÕES',
      sortable: true,
      onDelete: handleOpenDeleteDialog,
      type: ColumnTypes.DELETE,
    },
  ];
  const [defaultSort] = useState<Sort>({
    field: 'id',
    order: 'DESC',
  });
  const [queryParams, setQueryParams] = useState<CreateQueryParams>({
    sort: defaultSort,
    page: 1,
    limit: 10,
  });

  return (
    <CrudList
      title="Permissões"
      url="/roles"
      columns={columns}
      queryParams={queryParams}
      setQueryParams={setQueryParams}
      searchField="name"
      searchFieldLabel="Nome"
      newButtonLabel="Novo grupo"
      newButtonLinkTo="/admin/roles-new"
      onRowClick={item =>
        history.push(`/admin/roles/${item.id}`, { role: item })
      }
      refresh={refresh}
      setRefresh={setRefresh}
    />
  );
};

export default RolesList;
