import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { LabelPayload, LabelTypes } from './types';

export const loadLabels = (query: CreateQueryParams) => {
  return action(LabelTypes.LOAD_REQUEST, { query });
};

export const loadLabelsFail = () => {
  return action(LabelTypes.LOAD_FAILURE);
};

export const loadLabelsSuccess = (payload: LabelPayload) => {
  return action(LabelTypes.LOAD_SUCCESS, payload);
};
