import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Button } from '@material-ui/core';
import { StatusCodes } from 'http-status-codes';

import { closeModal } from 'store/ducks/nav/actions';
import { notifyError } from 'store/ducks/notification/actions';

import OrdersService from 'services/ordersService';

import { ReactComponent as StoreLock } from 'assets/img/svg/store_lock.svg';
import { ReactComponent as CloseIcon } from 'assets/img/svg/icon_default_close.svg';

import { FeedBackModalNoAccessStoreProps } from './dialogTypes';
import { useStylesNoAccessModalStore } from './useStyles';
import { ORDER_SECOND_COPY_BANKSLIP_ERROR } from 'utils/messages';

const NoAccessStoreModalInfo: React.FC<FeedBackModalNoAccessStoreProps> = ({
  buttonTitle,
  message,
  link,
  isBankslipOrder,
  orderId,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const classes = useStylesNoAccessModalStore();

  const goToLink = async () => {
    if (isBankslipOrder && orderId) {
      try {
        const response = await OrdersService.getSmartLinkBankslip(orderId);
        if (response.status === StatusCodes.OK) {
          dispatch(closeModal());
          window.open(response.data, '_blank');
        } else {
          throw new Error(ORDER_SECOND_COPY_BANKSLIP_ERROR);
        }
      } catch (error) {
        dispatch(notifyError(error.message));
      }
    } else {
      dispatch(closeModal());
      history.push(link);
    }
  };

  const handleClose = () => dispatch(closeModal());

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      spacing={2}
      className={classes.container}
    >
      <Grid container item direction="row" justify="flex-end">
        <CloseIcon
          className={classes.closeIcon}
          onClick={() => handleClose()}
        />
      </Grid>
      <Grid item>
        <StoreLock className={classes.storeLockIcon} />
      </Grid>
      <Grid item>
        <Typography variant="h3" className={classes.title}>
          Acesso limitado
        </Typography>
      </Grid>
      <Grid item>
        <Typography className={classes.message} variant="subtitle1">
          {message}
        </Typography>
      </Grid>
      <Grid item>
        <Button
          onClick={() => goToLink()}
          className={classes.button}
          variant="contained"
          color="inherit"
        >
          <Typography className={classes.buttonTitle} variant="h3">
            {buttonTitle}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  );
};

export default NoAccessStoreModalInfo;
