import { CondOperator } from '@nestjsx/crud-request';
import * as Yup from 'yup';

export const searchObject = [
  {
    field: 'name',
    operator: CondOperator.CONTAINS_LOW,
  },
  {
    field: 'id',
    operator: CondOperator.EQUALS,
    format: (value: any) => Number(value),
  },
  {
    field: 'email',
    operator: CondOperator.CONTAINS_LOW,
  },
];

export const validationSchema = Yup.object().shape({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
});

export const defaultValues = (user: any) => {
  return {
    name: user?.name,
    email: user?.email,
    isLogistics: false,
    isPaymentStore: false,
    isPaymentSignature: false,
    isSchedule: false,
  };
};
