import { call, put } from 'redux-saga/effects';
import NotificationService from 'services/notificationService';
import { loadNotificationsFailure, loadNotificationsSuccess } from './actions';

export function* loadStoreNotificationsSaga(action: any) {
  try {
    const { data } = yield call(
      NotificationService.getAllNotifications,
      action.payload.query,
    );
    yield put(loadNotificationsSuccess(data));
  } catch (err) {
    yield put(loadNotificationsFailure());
  }
}
