export const INVOICE_MAIN_ADDRESS = 'Endereço de entrega diferente da cobrança';
export const UNBIND_INVOICE_ORDER = (invoice: string, orderId: number) =>
  `Deseja desvincular a nota fiscal ${invoice} do pedido ${orderId}?`;
export const UNBIND_INVOICE_ERROR = `Houve uma falha ao desvincular a nota fiscal desejada`;
export const UNBIND_INVOICE_SUCCESS = `Nota fiscal desvinculada com sucesso`;

export const BIND_INVOICE_SUCCESS = 'Nota fiscal vinculada com sucesso.';
export const BIND_INVOICE_ERROR =
  'Erro ao vincular nota fiscal, tente novamente.';
export const BIND_INVOICE_ORDER_ASK = (
  invoiceNumber: string,
  orderId: number,
) => `Deseja vincular a nota n° ${invoiceNumber} ao pedido n° ${orderId}?`;
export const INVOICE_UPDATE_ACCESS_KEY_ASK = `Deseja atualizar as chaves das notas fiscais dos pedidos?`;
export const INVOICE_UPDATE_ACCESS_KEY_ERROR = `Ocorreu um erro ao tentar atualizar as chaves, tente novamente mais tarde.`;
export const INVOICE_UPDATE_ACCESS_KEY_SUCCESS = `Notas adicionadas a fila para buscar atualização das chaves.`;
