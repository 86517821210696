import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { Order } from 'types/generals';
import { OrderPayload, OrderTypes, UserOrderTypes } from './types';

export const cleanOrdersList = () => {
  return action(OrderTypes.CLEAN_ORDERS);
};

export const loadOrders = (query: CreateQueryParams) => {
  return action(OrderTypes.LOAD_ORDERS_REQUEST, { query });
};

export const loadOneOrder = (orderId: string, query?: CreateQueryParams) => {
  return action(OrderTypes.LOAD_ONE_ORDER_REQUEST, { orderId, query });
};

export const loadUserOrder = (orderId: string) => {
  return action(UserOrderTypes.LOAD_USER_ORDER, orderId);
};

export const loadOrdersFail = () => {
  return action(OrderTypes.LOAD_FAILURE);
};

export const loadOrdersSuccess = (payload: OrderPayload) => {
  return action(OrderTypes.LOAD_ORDERS_SUCCESS, payload);
};

export const loadOneOrderSuccess = (payload: OrderPayload) => {
  return action(OrderTypes.LOAD_ONE_ORDER_SUCCESS, payload);
};

export const loadUserOrderSuccess = (payload: OrderPayload) => {
  return action(UserOrderTypes.LOAD_USER_ORDER_SUCCESS, payload);
};

export const updateOrder = (data: Order) => {
  return action(OrderTypes.UPDATE, data);
};

export const cancelOrder = (orderId: string, message?: string) => {
  return action(OrderTypes.CANCEL, { orderId, message });
};

export const reactivateOrder = (orderId: string) => {
  return action(OrderTypes.REACTIVATE, { orderId });
};

export const loadOrderMonthSignature = (signatureId: number) => {
  return action(OrderTypes.LOAD_MONTH_ORDER_SIGNATURE_REQUEST, { signatureId });
};

export const loadOrderMonthSignatureSuccess = (payload: OrderPayload) => {
  return action(OrderTypes.LOAD_MONTH_ORDER_SIGNATURE_SUCCESS, payload);
};
