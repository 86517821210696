import { Grid } from '@material-ui/core';
import { CondOperator, CreateQueryParams } from '@nestjsx/crud-request';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MySelectTableFilter from 'components/generals/input/MySelectTableFilter';
import CrudList from 'components/generals/lists/CrudList';
import CircularLoading from 'components/generals/loading/CircularLoading';
import { useFilters } from 'hooks/filters';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { AppState } from 'store';
import { loadRankingRequest } from 'store/ducks/ranking/actions';
import { RankingState } from 'store/ducks/ranking/types';
import { ColumnProps } from 'types';
import RankingActions from './RankingActions';
const RankingPage: FC = () => {
  const dispatch = useDispatch();
  const [refresh, setRefresh] = useState(false);
  const [selectedRanking, setRankings] = useState<number>();
  const [searchText, setSearchText] = useState('');
  const {
    activeRanking,
    data: rankings,
    isLoading: isLoadingRanking,
  } = useSelector<AppState, RankingState>(({ ranking }: AppState) => ranking);

  const columns: ColumnProps[] = [
    { label: 'Posição', getter: 'rankingPosition', sortable: true },
    { label: 'NOME', getter: 'name', sortable: true },
    { label: 'Pontuação', getter: 'totalScore', sortable: true },
  ];
  const [queryParams, setQueryParams] = useState<CreateQueryParams>({
    page: 1,
    limit: 10,
  });

  const { filterFields, genericFilterFields, page, sort } = useFilters({
    name: { filterTitle: 'Nome', value: undefined },
    createdAt: { filterTitle: 'Data criação', value: undefined },
  });

  const loadRankings = useCallback(() => {
    dispatch(
      loadRankingRequest({
        limit: 10,
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!rankings.length && isLoadingRanking) {
      loadRankings();
    }
    if (rankings.length) {
      setRankings(rankings[0]?.id);
    }
  }, [isLoadingRanking, loadRankings, rankings]);

  useEffect(() => {
    const id = selectedRanking || activeRanking?.id;
    if (id) {
      setQueryParams({
        filter: [
          {
            field: 'rankingSeasonId',
            operator: CondOperator.EQUALS,
            value: id,
          },
          {
            field: 'name',
            operator: CondOperator.EQUALS,
            value: searchText,
          },
        ],
        page: 1,
        limit: 10,
      });
    }
  }, [activeRanking, searchText, selectedRanking]);

  const handleChangeSelectedRanking = (event: any) => {
    const value = event?.target?.value;
    setRankings(value);
  };

  const handleSetSearchText = (event: any) => {
    const isEnter = event.key === 'Enter' || event.keyCode === '13';
    const value = event?.target?.value;
    if (isEnter) {
      setSearchText(value);
    }
  };

  return (
    <>
      {selectedRanking ? (
        <CrudList
          title="Ranking"
          url="/user-ranking-season/list-rank"
          columns={columns}
          queryParams={queryParams}
          setQueryParams={setQueryParams}
          searchField="name"
          searchFieldLabel="Nome"
          refresh={refresh}
          setRefresh={setRefresh}
          showSearch={false}
        >
          <Grid
            container
            item
            xs
            direction="row"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={5} md={3}>
              <MyInputFilter
                onKeyUp={e => handleSetSearchText(e)}
                placeholder="Nome"
              />
            </Grid>
            <Grid item xs={5} md={3}>
              <MySelectTableFilter
                contracted
                itens={rankings?.map(ranking => ({
                  title: ranking.name,
                  value: ranking.id,
                }))}
                onChange={handleChangeSelectedRanking}
                value={selectedRanking}
                helperText={null}
                title="Campanha"
                error={false}
              />
            </Grid>
            <Grid item xs={5} md={3}>
              <RankingActions
                sort={sort}
                page={page}
                filterFields={filterFields}
                genericFilterFields={genericFilterFields}
                selectedRanking={selectedRanking}
              />
            </Grid>
          </Grid>
        </CrudList>
      ) : (
        <CircularLoading
          isLoading={isLoadingRanking}
          title={'Carregando campanhas'}
        />
      )}
    </>
  );
};

export default RankingPage;
