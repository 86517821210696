import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  marginLeft: {
    marginLeft: '20px',
  },
  marginBottom10: {
    marginBottom: 10,
  },
});

export { useStyles };
