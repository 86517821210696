import React, { FC, useState } from 'react';
import SwipeableViews from 'react-swipeable-views';
import { Grid } from '@material-ui/core';

import MyGenericDrawer from 'components/client/MyGenericDrawer';
import { MyGenericDrawerProps } from 'components/client/MyGenericDrawer/types';
import CartDataControl from './StepsViews/CartDataControl';
import CartPayment from './StepsViews/CartPayment';
import ReCAPTCHA from 'react-google-recaptcha';
import OpenEndpointsService from 'services/openEndpointsService';
import { USER_UNAUTHORIZED } from 'utils/messages/user';

const ShopCartDetail: FC<MyGenericDrawerProps> = ({ opened, setOpened }) => {
  const [viewIndex, setViewIndex] = useState<number>(0);
  const recaptchaRef = React.useRef<ReCAPTCHA>(null);

  const handleChangeView = (index: number) => {
    setViewIndex(index);
  };

  const handleVerifyRecaptcha = async () => {
    try {
      const token = await recaptchaRef.current?.executeAsync();
      const response = await OpenEndpointsService.verifyUserRequest(token);
      const { success, score } = response.data;
      if (!success || (success && score < 0.8)) {
        throw new Error(USER_UNAUTHORIZED);
      }
      return token;
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <MyGenericDrawer opened={opened} setOpened={setOpened}>
      <SwipeableViews index={viewIndex} onChangeIndex={handleChangeView}>
        <Grid style={{ overflow: 'hidden' }}>
          <CartDataControl
            setOpened={setOpened}
            handleChangeStep={handleChangeView}
            handleVerifyRecaptcha={handleVerifyRecaptcha}
          />
        </Grid>
        <Grid style={{ overflow: 'hidden' }}>
          <CartPayment
            setOpened={setOpened}
            handleChangeStep={handleChangeView}
            handleVerifyRecaptcha={handleVerifyRecaptcha}
          />
        </Grid>
      </SwipeableViews>

      <ReCAPTCHA
        ref={recaptchaRef}
        sitekey={`${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`}
        size="invisible"
      />
    </MyGenericDrawer>
  );
};

export default ShopCartDetail;
