import { StoreSettingsToSave } from 'types/client';
import { MultiPartRequestConfig } from 'types/generals/banner';
import { getWithSaga } from './apiService';
import { api } from './apiService';

export default class StoreSettingsService {
  static async requestStoreSettings() {
    try {
      const data = await getWithSaga('/store_settings');
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async handleVerifyHasPromotion(signatureId: number) {
    try {
      const data = api.get('/store_settings/validate-promotion', {
        params: { signatureId },
      });

      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static createFormData(settings: StoreSettingsToSave) {
    const formData = new FormData();
    formData.append('isActive', `${settings.isActive}`);
    formData.append(
      'promotionPeriods',
      JSON.stringify(settings.promotionPeriods),
    );
    formData.append('tags', JSON.stringify(settings.tags));
    formData.append('bannersData', JSON.stringify(settings.bannersData));
    formData.append('freightConfig', JSON.stringify(settings.freightConfig));
    formData.append(
      'rankingSeasonConfig',
      JSON.stringify(settings.rankingSeasonConfig),
    );
    if (settings.bannerDesktop?.length > 0) {
      settings.bannerDesktop.forEach(file => formData.append('banners', file));
    }
    if (settings.bannerMobile?.length > 0) {
      settings.bannerMobile.forEach(file =>
        formData.append('mobileBanners', file),
      );
    }
    if (settings.bannerSeason?.length) {
      settings.bannerSeason.forEach(file =>
        formData.append('seasonBanners', file),
      );
    }
    return formData;
  }

  static async saveSettings(settings: StoreSettingsToSave) {
    try {
      const config: MultiPartRequestConfig = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const formData = this.createFormData(settings);
      if (settings.id) {
        return this.updateSettings(settings.id, formData, config);
      } else {
        return this.createSettings(formData, config);
      }
    } catch (error) {
      return error;
    }
  }

  static async createSettings(
    formData: FormData,
    config: MultiPartRequestConfig,
  ) {
    try {
      const { data } = await api.post('store_settings', formData, config);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async updateSettings(
    id: string,
    formData: FormData,
    config: MultiPartRequestConfig,
  ) {
    try {
      const { data } = await api.put(`store_settings/${id}`, formData, config);
      return data;
    } catch (error) {
      return error;
    }
  }
}
