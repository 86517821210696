import React, { useRef } from 'react';
import { Grid } from '@material-ui/core';
import InputMask from 'react-input-mask';

import MyOutlinedTextField from '../input/MyOutlinedTextField';
import { Controller } from 'react-hook-form';
import { InputRef, CardFormController } from 'types';
import MASK, { UNMASK } from 'helpers/masks';
import { discoverCardBrand } from 'helpers/creditCards';

const CreditCardForm: React.FC<CardFormController> = ({
  register,
  control,
  errors,
  setValue,
}) => {
  const creditCardInput = useRef() as InputRef;
  const cardNameInput = useRef() as InputRef;
  const expiringDateInput = useRef() as InputRef;
  const CVVInput = useRef() as InputRef;

  const handleCreditCardNumberChange = (ccNumber: string) => {
    setValue('ccBrand', discoverCardBrand(UNMASK.onlyDigits(ccNumber)));
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Controller
          as={InputMask}
          mask={MASK.CREDIT_CARD}
          name="ccNumber"
          control={control}
          maskChar={null}
          onChange={([e]: any) => {
            handleCreditCardNumberChange(e.target.value);
            return UNMASK.onlyDigits(e.target.value);
          }}
        >
          {() => (
            <MyOutlinedTextField
              fullWidth
              name="ccNumber"
              label="N° do cartão de crédito"
              type="text"
              inputProps={{ ref: creditCardInput, inputMode: 'numeric' }}
              changeFocusOnEnter={() => cardNameInput.current.focus()}
              error={Boolean(errors.ccNumber)}
              helperText={errors.ccNumber ? errors.ccNumber.message : null}
            />
          )}
        </Controller>
      </Grid>
      <Grid item xs={12}>
        <MyOutlinedTextField
          fullWidth
          name="cardName"
          label="Nome impresso no cartão"
          type="text"
          inputProps={{ ref: cardNameInput, maxLength: 25 }}
          changeFocusOnEnter={() => expiringDateInput.current.focus()}
          inputRef={register}
          error={Boolean(errors.cardName)}
          helperText={errors.cardName ? errors.cardName.message : null}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={InputMask}
          mask={MASK.CC_EXPIRATION_DATE}
          name="dueDate"
          control={control}
          maskChar={null}
          onChange={([event]: any) => event.target.value}
        >
          {() => (
            <MyOutlinedTextField
              fullWidth
              name="dueDate"
              label="Validade (01/0000)"
              type="text"
              inputProps={{
                ref: expiringDateInput,
                inputMode: 'numeric',
              }}
              changeFocusOnEnter={() => CVVInput.current.focus()}
              inputRef={register}
              error={Boolean(errors.dueDate)}
              helperText={errors.dueDate ? errors.dueDate.message : null}
            />
          )}
        </Controller>
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          as={InputMask}
          mask={MASK.CARD_CODE}
          control={control}
          name="cvv"
          maskChar={null}
          onChange={([event]: any) => UNMASK.onlyDigits(event.target.value)}
        >
          {() => (
            <MyOutlinedTextField
              name="cvv"
              fullWidth
              label="CVV"
              type="text"
              inputProps={{ ref: CVVInput, inputMode: 'numeric' }}
              changeFocusOnEnter={() =>
                (document.getElementById('coupon-input') as HTMLElement).focus()
              }
              inputRef={register}
              error={Boolean(errors.cvv)}
              helperText={errors.cvv ? errors.cvv.message : null}
            />
          )}
        </Controller>
      </Grid>
    </Grid>
  );
};

export default CreditCardForm;
