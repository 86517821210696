import { api, getWithSaga, apiQueue } from './apiService';
import {
  EnhancedProductPayload,
  EnhancedSignaturePayload,
} from 'types/generals/enhancedProduct';
import { CreateQueryParams } from '@nestjsx/crud-request';

export default class EnhancedProductService {
  static async getEnhancedProducts(query: CreateQueryParams) {
    try {
      const data = await getWithSaga('/enhanced-products', query);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async getEnhancedProductsOpenEndpoint(query: CreateQueryParams) {
    try {
      const data = await getWithSaga(
        '/open-endpoints/enhanced-products',
        query,
      );
      return data;
    } catch (err) {
      return err;
    }
  }

  static async create(body: Partial<EnhancedProductPayload>) {
    try {
      const data = api.post('/enhanced-products', body);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async update(id: number, body: Partial<EnhancedProductPayload>) {
    try {
      const data = api.patch(`/enhanced-products/${id}`, body);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async delete(id: number) {
    try {
      const data = api.delete(`/enhanced-products/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async enhanceSignature(
    signatureId: number,
    body: EnhancedSignaturePayload,
  ) {
    try {
      const data = apiQueue.post(
        `enhanced-products/enhance-signature/${signatureId}`,
        body,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  //fixme
  // add ação de cancelamento de turbinação
  // (seria interessante esperar doação/indicação por causa que as ações dos pedidos foram componetizadas )
  static async unEnhanceSignature(signatureId: number) {
    try {
      const data = api.post(
        `enhanced-products/unenhance-signature/${signatureId}`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }
}
