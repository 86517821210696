import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ToggleButton from 'components/generals/buttons/ToggleButton';

import { AppState } from 'store';
import { StoreFiltersProps, FilterData, PRODUCT_FILTER } from './types';
import { CategoriesState } from 'store/ducks/categories/types';
import { loadCategoryRequest } from 'store/ducks/categories/actions';
import { useStyles } from './styles';
import { QuerySort } from '@nestjsx/crud-request';
import MySelectFilter from 'components/generals/input/MySelectFilter';
import Colors from 'styles/colors';

const StoreFilters: FC<StoreFiltersProps> = props => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const dispatch = useDispatch();
  const styles = useStyles();
  const FILTER_TODOS = {
    text: 'Todos',
    value: 0,
    isToggled: false,
  };
  const initFilter: FilterData[] = [FILTER_TODOS];

  const [categories, setCategories] = useState<FilterData[]>(initFilter);
  const [isLoading, setIsLoading] = useState(true);
  const [productFilter, setProductFilter] = useState<string>(
    PRODUCT_FILTER.NEWEST,
  );

  const {
    categories: storeCategories,
    isLoading: isLoadingCategory,
  } = useSelector<AppState, CategoriesState>(
    ({ category }: AppState) => category,
  );

  const loadAllCategories = useCallback(() => {
    dispatch(
      loadCategoryRequest({
        page: 1,
        limit: 100,
        sort: {
          field: 'priority',
          order: 'ASC',
        },
        search: {
          $and: [{ isActive: { $eq: true } }],
        },
      }),
    );
  }, [dispatch]);

  useEffect(() => {
    if (!isLoadingCategory && isLoading) {
      loadAllCategories();
    }
    if (isLoadingCategory) {
      setIsLoading(false);
    }
  }, [isLoading, isLoadingCategory, loadAllCategories]);

  useEffect(() => {
    if (categories.length <= 1 && storeCategories.length) {
      const filters: FilterData[] = [];
      storeCategories.forEach(cat => {
        filters.push({
          text: cat.name,
          value: cat.id,
          isToggled: false,
        });
      });
      setCategories(cats => [...cats, ...filters]);
    }
  }, [categories.length, isLoadingCategory, storeCategories]);

  const handleFilter = (filterId: number, isFiltered: boolean) => {
    let newFilters: FilterData[] = [];
    if (filterId === FILTER_TODOS.value || !isFiltered) {
      newFilters = categories.map(cat => {
        return {
          ...cat,
          isToggled: false,
        };
      });
    } else {
      newFilters = categories.map(cat => {
        return {
          ...cat,
          isToggled: cat.value === filterId ? true : false,
        };
      });
    }
    setCategories(newFilters);
    if (props.handleFilter) {
      props.handleFilter(
        newFilters.filter(cat => cat.isToggled).map(filter => filter.value),
      );
    }
  };

  const handleSortProduct = (filter: string) => {
    let sort: QuerySort = { field: 'startDate', order: 'DESC' };
    switch (filter) {
      case PRODUCT_FILTER.CHEAPER:
        sort = { field: 'price', order: 'ASC' };
        break;
      case PRODUCT_FILTER.MOST_EXPENSIVE:
        sort = { field: 'price', order: 'DESC' };
        break;
      case PRODUCT_FILTER.NEWEST:
        sort = { field: 'startDate', order: 'DESC' };
        break;
      case PRODUCT_FILTER.OLDEST:
        sort = { field: 'startDate', order: 'ASC' };
        break;
    }
    setProductFilter(filter);
    props.handleSort(sort);
  };

  const ProductSelectFilter = () => {
    const styleSelectButton = isMobile
      ? styles.filterButtonMobile
      : styles.filterButton;

    const styleSelectText = isMobile
      ? styles.filterSelectTextMobile
      : styles.filterSelectText;
    return (
      <MySelectFilter
        expandMoreIconStyle={styles.expandIcon}
        buttonStyle={styleSelectButton}
        menuItems={Object.values(PRODUCT_FILTER).map(status => ({
          title: status,
          action: () => handleSortProduct(status),
        }))}
        buttonProps={{
          style: {
            borderColor: Colors.WHITE,
          },
        }}
      >
        <Typography className={styleSelectText}>
          <b>Ordenar por: </b>
          {productFilter}
        </Typography>
      </MySelectFilter>
    );
  };

  return (
    <>
      <Grid
        container
        item
        spacing={1}
        direction="row"
        justify="space-between"
        alignItems="center"
      >
        <Grid
          className={styles.gridCategories}
          justify="flex-start"
          item
          xs={12}
          sm={9}
          md={9}
          lg={9}
        >
          {categories.map((cat, index) => (
            <Grid
              item
              xs="auto"
              key={index}
              className={styles.gridCategoryItem}
            >
              <ToggleButton
                buttonTitle={cat.text}
                id={cat.value}
                isToggled={cat.isToggled}
                handleClick={handleFilter}
              />
            </Grid>
          ))}
        </Grid>
        {!isMobile ? (
          <Grid className={styles.gridSortable} item xs={3}>
            <ProductSelectFilter />
          </Grid>
        ) : (
          <></>
        )}
      </Grid>
      {isMobile ? (
        <Grid
          container
          item
          className={styles.gridSortableMobile}
          xs="auto"
          direction="row"
          justify="flex-end"
        >
          <ProductSelectFilter />
        </Grid>
      ) : (
        <></>
      )}
    </>
  );
};

export default StoreFilters;
