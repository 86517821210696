import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';
import {
  useTheme,
  useMediaQuery,
  Grid,
  Typography,
  Divider,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import AddressForm from 'components/generals/addressForm';

import { AppState } from 'store';
import { OneAddressState } from 'store/ducks/generals/address/types';
import {
  backStepPlanPage,
  nextStepPlanPage,
  setAddressOnPayload,
} from 'store/ducks/change-plan/actions';

import { InputRef } from 'types';
import { validationSchema } from './utils';
import { useStyles } from './styles';

const AddressStep: React.FC = () => {
  const style = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const smallSize = useMediaQuery(theme.breakpoints.down('xs'));
  const [defaultAddressChecked, setDefaultAddressChecked] = useState(false);
  const [newAddressChecked, setNewAddressChecked] = useState(false);

  const { data: address, isLoading } = useSelector<AppState, OneAddressState>(
    ({ addressReducer }: AppState) => addressReducer,
  );

  const { reset, errors, register, control, handleSubmit, setValue } = useForm({
    validationSchema,
  });

  const newAddressInput = useRef() as InputRef;

  const backPlanStep = () => {
    dispatch(backStepPlanPage());
  };

  const nextPlanStep = () => {
    dispatch(nextStepPlanPage());
  };

  const addressSubmit = (data: any) => {
    if (newAddressChecked) {
      const {
        zipcode,
        street,
        number,
        neighborhood,
        city,
        complement,
        state,
      } = data.address;
      dispatch(
        setAddressOnPayload({
          zipcode,
          street,
          number,
          neighborhood,
          city,
          complement,
          state,
          isDefault: true,
        }),
      );
    } else {
      dispatch(setAddressOnPayload(address));
    }
    nextPlanStep();
  };

  useEffect(() => {
    if (address?.id && !isLoading) {
      setDefaultAddressChecked(true);
      setNewAddressChecked(false);
      setValue('address.newAddress', false);
    } else {
      setDefaultAddressChecked(false);
      setNewAddressChecked(true);
      setValue('address.newAddress', true);
    }
  }, [address, setValue, isLoading]);

  return (
    <Grid container spacing={3} justify="space-evenly">
      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h3">Endereço</Typography>
      </Grid>

      <Grid container item>
        {!smallSize ? (
          <Grid item xs={12} md={5}>
            <Typography className={style.messageAddress} variant="h2">
              Selecione para qual endereço deseja enviar:
            </Typography>
          </Grid>
        ) : null}
        <Grid container item xs={12} md={7} alignItems="center">
          {address ? (
            <Grid item xs={12} className={style.addressContainer}>
              <Grid item xs>
                <RadioGroup>
                  <FormControlLabel
                    value="defaultAddress"
                    control={
                      <Radio
                        checked={defaultAddressChecked}
                        onClick={() => {
                          setNewAddressChecked(false);
                          setDefaultAddressChecked(true);
                          setValue('address.newAddress', false);
                        }}
                      />
                    }
                    label={
                      <Typography className={style.selectText}>
                        Usar endereço cadastrado
                      </Typography>
                    }
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1">
                  {address.street +
                    ', ' +
                    address.number +
                    ', ' +
                    address.neighborhood +
                    ', ' +
                    address.city}
                </Typography>
              </Grid>
            </Grid>
          ) : null}

          <Grid item xs={12} className={style.addressContainer}>
            <Grid item xs>
              <Controller
                name="address.newAddress"
                control={control}
                as={({ value, onChange }) => (
                  <FormControlLabel
                    name="address.newAddress"
                    inputRef={newAddressInput}
                    value={value}
                    control={
                      <Radio
                        checked={newAddressChecked}
                        onClick={() => {
                          setNewAddressChecked(true);
                          setDefaultAddressChecked(false);
                          setValue('address.newAddress', true);
                          reset();
                        }}
                      />
                    }
                    label={
                      <Typography className={style.selectText}>
                        Adicionar um novo endereço
                      </Typography>
                    }
                  />
                )}
              />
            </Grid>
            {newAddressChecked ? (
              <Grid item xs={12}>
                <AddressForm
                  control={control}
                  errors={errors}
                  reset={reset}
                  register={register}
                  xsValue={12}
                />
              </Grid>
            ) : null}
          </Grid>
        </Grid>

        <Grid
          container
          item
          spacing={1}
          direction={smallSize ? 'column-reverse' : 'row'}
          justify="flex-end"
        >
          <Grid item>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => backPlanStep()}
              className={smallSize ? style.backButtonMobile : style.backButton}
            >
              <Typography className={style.backButtonText}>Voltar</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              variant="contained"
              className={smallSize ? style.nextButtonMobile : style.nextButton}
              onClick={handleSubmit(addressSubmit)}
            >
              <Typography className={style.nextButtonText}>
                Continuar
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default AddressStep;
