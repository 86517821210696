import { Invoice } from 'types/generals';

/**
 * Action types
 */
export enum InvoiceTypes {
  LOAD_REQUEST = '@invoice/LOAD_REQUEST',
  LOAD_SUCCESS = '@invoice/LOAD_SUCCESS',
  LOAD_FAILURE = '@invoice/LOAD_FAILURE',
  UPDATE = '@invoice/UPDATE',
}

/**
 * State types
 */
export interface InvoiceState {
  readonly total: number;
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly data: Invoice[];
}
