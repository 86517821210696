import React, { MutableRefObject, useRef } from 'react';
import { SnackbarProvider } from 'notistack';

import Notifier from 'components/generals/notification/Notifier';

import { ReactComponent as CloseIcon } from 'assets/img/svg/icon_default_close.svg';
import { ReactComponent as ErrorIcon } from 'assets/img/svg/icon_active_error.svg';

import { useStyles } from './styles';
import { CustomSnackbarPropsType } from './types';

// FIXME: Adjust provider to not break notifications on multiple dispatch
const CustomSnackbarProvider = ({ children }: CustomSnackbarPropsType) => {
  const classes = useStyles();
  const notistackRef = useRef() as MutableRefObject<React.FC>;

  const onClickClose = (key: string | number) => () =>
    (notistackRef as any)?.current?.closeSnackbar(key);

  return (
    <SnackbarProvider
      ref={notistackRef}
      maxSnack={1}
      autoHideDuration={5000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      iconVariant={{
        error: <ErrorIcon />,
      }}
      action={key => (
        <CloseIcon
          onClick={onClickClose(key)}
          className={classes.closeButton}
        />
      )}
      classes={{
        root: classes.root,
      }}
    >
      {children}
      <Notifier />
    </SnackbarProvider>
  );
};

export default CustomSnackbarProvider;
