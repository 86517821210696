import { Breadcrumbs, Link, styled, Typography } from '@material-ui/core';

import { ReactComponent as HomeIconSVG } from 'assets/img/svg/menu-icons/icon_home.svg';

export const MUIBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  fontSize: '16px',
  color: theme.palette.grey['800'],
}));

export const HomeIcon = styled(HomeIconSVG)({
  height: '24px',
  cursor: 'pointer',
});

export const CurrentLocation = styled(Typography)({
  cursor: 'default',
});

export const PastLocation = styled(Link)(({ theme }) => ({
  cursor: 'pointer',
  color: theme.palette.grey['800'],
}));
