import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  FormHelperText,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { closeModal } from 'store/ducks/nav/actions';

import useStyles from './styles';
import { NotificationDialogProps } from './types';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';

const NotificationDialog: React.FC<NotificationDialogProps> = props => {
  const availableProperties = [
    'numero_pedido',
    'nome_usuario',
    'link_boleto',
    'motivo_recusa',
    'endereco',
  ];

  const dispatch = useDispatch();
  const styles = useStyles();
  const { notification, title, handleAction } = props;

  const [emailMessage, setEmailMessage] = useState(notification.emailMessage);
  const [smsMessage, setSmsMessage] = useState(notification.smsMessage);

  const handleEmailChange = (e: any) => {
    setEmailMessage(e.target.value);
  };

  const handleSmsChange = (e: any) => {
    setSmsMessage(e.target.value);
  };

  const handleConfirmAction = () => {
    handleAction({
      ...notification,
      emailMessage,
      smsMessage,
    });
    dispatch(closeModal());
  };

  const NotificationHelper = () => (
    <Grid item xs={12}>
      <FormHelperText>
        <Typography variant="h4">
          <b>Propriedades disponíveis:</b>
        </Typography>
        {availableProperties.map(prop => {
          return <Typography variant="h5">- {prop}</Typography>;
        })}
        <Typography variant="h4" className={styles.helperTitle}>
          Exemplo:
        </Typography>
        <Typography variant="h4">
          {` O pedido "{{numero_pedido}}" está sem separação "{{nome_usuario}}"`}
        </Typography>
      </FormHelperText>
    </Grid>
  );

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container style={{ marginTop: 8 }} spacing={2} component="form">
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h3"
              color="textPrimary"
              className={styles.info_mb}
            >
              <MyOutlinedTextField
                name="link"
                label="HTML do e-mail"
                fullWidth
                multiline
                rows={8}
                value={emailMessage}
                onChange={handleEmailChange}
                placeholder="Se o campo estiver vazio, o HTML padrão do sistema será enviado"
              />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} md={12}>
            <Typography
              variant="h3"
              color="textPrimary"
              className={styles.info_mb}
            >
              <MyOutlinedTextField
                name="link"
                label="Mensagem do SMS"
                fullWidth
                multiline
                rows={4}
                value={smsMessage}
                onChange={handleSmsChange}
                inputProps={{ maxLength: 140 }}
                placeholder="Se o campo estiver vazio, o SMS padrão do sistema será enviado"
              />
            </Typography>
          </Grid>
          <NotificationHelper />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className={styles.buttonLabel}
          style={{ marginRight: 30 }}
          onClick={() => dispatch(closeModal())}
        >
          Cancelar
        </Button>
        <Button className={styles.buttonSave} onClick={handleConfirmAction}>
          Salvar
        </Button>
      </DialogActions>
    </Grid>
  );
};

export default NotificationDialog;
