import React, { FC } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import { useStyles } from './styles';
import { IIconLabelButton } from './types';

const IconLabelButton: FC<IIconLabelButton> = ({ Icon, label, action }) => {
  const styles = useStyles();

  return (
    <>
      <Button onClick={() => action()} className={styles.btn}>
        <Paper elevation={0} className={styles.paperTable}>
          <Grid
            container
            item
            justify="space-between"
            direction="row"
            alignItems="center"
            spacing={2}
            style={{ flexFlow: 'nowrap' }}
          >
            <Grid item xs>
              <Icon color="primary" className={styles.shopIcon} />
            </Grid>
            <Grid item xs>
              <Typography variant="h4" className={styles.cartText} noWrap>
                {label}
              </Typography>
            </Grid>
          </Grid>
        </Paper>
      </Button>
    </>
  );
};

export default IconLabelButton;
