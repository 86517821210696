import React, { ChangeEvent, FC, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
  FormControlLabel,
  CircularProgress,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { Controller, useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { StatusCodes } from 'http-status-codes';
import { BaseDialogProps } from 'components/generals/dialog/dialogTypes';

import { Address, InputRef } from 'types';
import OpenEndpointsService from 'services/openEndpointsService';
import MASK, { UNMASK } from 'helpers/masks';
import useFormStyles from 'components/generals/forms/register/styles';
import MySwitch from 'components/generals/input/MySwitch';
import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import ConfirmationDialog from '../ConfirmationDialog';
import { AppState } from 'store';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { AddressDialogProps } from './types';
import { addressValidationSchema } from './utils';
import { useStyles } from './styles';
import AddressService from 'services/addressService';
import SignatureService from 'services/signatureService';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import {
  CREATE_ADDRESS_FAIL,
  CREATE_ADDRESS_SUCCESS,
  EDIT_ADDRESS_FAIL,
  EDIT_ADDRESS_SUCCESS,
  ORDER_DETAIL_ADDRESS_CHANGE_CONFIRM,
  ORDER_DETAIL_ADDRESS_CHANGED,
  ORDER_DETAIL_ADDRESS_CHANGE_ERROR,
} from 'utils/messages';
import { loadAllAddresses } from 'store/ducks/generals/address/actions';

const AddressDialog: FC<AddressDialogProps> = ({
  title,
  message,
  user,
  address,
}) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const formStyles = useFormStyles();
  const [loading, setLoading] = useState(false);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [disabledField, setDisabledField] = useState(false);
  const [isDefault, setIsDefault] = useState(() => {
    if (address) return address.isDefault;
    else return false;
  });

  const { data: signature } = useSelector<AppState, SingleSignatureState>(
    ({ oneSignature }: AppState) => oneSignature,
  );

  const { reset, errors, register, control, handleSubmit } = useForm({
    validationSchema: addressValidationSchema,
    defaultValues: {
      zipcode: address?.zipcode,
      street: address?.street,
      number: address?.number,
      neighborhood: address?.neighborhood,
      city: address?.city,
      state: address?.state,
      complement: address?.complement,
    },
  });

  const zipcodeInput = useRef() as InputRef;
  const streetInput = useRef() as InputRef;
  const numberInput = useRef() as InputRef;
  const neighborhoodInput = useRef() as InputRef;
  const cityInput = useRef() as InputRef;
  const stateInput = useRef() as InputRef;
  const complementInput = useRef() as InputRef;

  const lookupAddress = async (zipcode: string) => {
    if (zipcode.length === 8) {
      setLoading(true);
      setDisabled(true);
      try {
        const data = await OpenEndpointsService.userLookupAddress(zipcode);
        if (data.cidade && data.uf) setDisabledField(true);
        reset({
          neighborhood: data.bairro,
          complement: data.complemento2 || '',
          city: data.cidade,
          street: data.end,
          state: data.uf,
        });
        reset();
      } catch (error) {
        reset({
          neighborhood: '',
          complement: '',
          city: '',
          street: '',
          state: '',
        });
      } finally {
        setLoading(false);
        setDisabled(false);
      }
      return zipcode;
    }
  };

  const handleZipcodeChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    lookupAddress(unmasked);
    return unmasked;
  };

  const createNewUserAddress = async (address: Address) => {
    try {
      const data = await AddressService.createNewAddress({
        ...address,
        isDefault,
        user: user.id,
      });

      if (data.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(CREATE_ADDRESS_SUCCESS));
        dispatch(closeModal());

        if (isDefault) {
          handleOpenUpdateOrderAddressDialog();
        }
      } else {
        throw new Error(CREATE_ADDRESS_FAIL);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      setSubmitLoading(false);
    }
  };

  const updateUserAddress = async (updateAddress: Address) => {
    try {
      if (!address) throw new Error(EDIT_ADDRESS_FAIL);

      const data = await AddressService.updateAddress(address.id, {
        ...updateAddress,
        isDefault,
      });

      if (data) {
        dispatch(notifySuccess(EDIT_ADDRESS_SUCCESS));
        dispatch(closeModal());

        if (isDefault) {
          handleOpenUpdateOrderAddressDialog();
        }
      } else {
        throw new Error(EDIT_ADDRESS_FAIL);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      setSubmitLoading(false);
    }
  };

  const handleFormatZipcode = (value: string) => {
    return UNMASK.onlyDigits(value);
  };

  const onSubmit = async (addressSubmit: any) => {
    const submitMethod = address ? updateUserAddress : createNewUserAddress;
    addressSubmit.zipcode = handleFormatZipcode(addressSubmit.zipcode);
    setSubmitLoading(true);
    await submitMethod(addressSubmit);
    dispatch(
      loadAllAddresses({
        sort: { field: 'isDefault', order: 'DESC' },
      }),
    );
  };

  const handleOpenUpdateOrderAddressDialog = () => {
    const modalProps: BaseDialogProps = {
      title: 'Alterar endereço dos pedidos futuros cz',
      confirmText: 'Sim',
      cancelText: 'Não',
      message: ORDER_DETAIL_ADDRESS_CHANGE_CONFIRM,
      actionFn: () => handleUpdateOrderAddress(),
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleUpdateOrderAddress = async () => {
    try {
      const response = await SignatureService.changeSignatureOrderAddress(
        signature.id,
      );

      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(ORDER_DETAIL_ADDRESS_CHANGED));
        dispatch(closeModal());
      } else {
        throw new Error(ORDER_DETAIL_ADDRESS_CHANGE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
      dispatch(closeModal());
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container className={styles.dialogSpacing} component="form">
          <Grid item xs={12} sm={12} md={12}>
            <Typography variant="h3" color="textPrimary">
              {message}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item justify="space-between" spacing={2}>
          <Grid item xs={8}>
            <Controller
              as={InputMask}
              mask={MASK.ZIPCODE}
              name="zipcode"
              control={control}
              maskChar={null}
              onChange={handleZipcodeChange}
            >
              {() => (
                <MyOutlinedTextField
                  fullWidth
                  label="CEP"
                  type="text"
                  name="zipcode"
                  inputProps={{
                    ref: zipcodeInput,
                    inputMode: 'numeric',
                  }}
                  changeFocusOnEnter={() => streetInput.current.focus()}
                  isLoading={loading}
                  isDisabled={disabled}
                  error={Boolean(errors.zipcode)}
                  helperText={errors.zipcode ? errors.zipcode.message : null}
                  inputRef={register}
                />
              )}
            </Controller>
          </Grid>
          <Grid item xs>
            <FormControlLabel
              name="isDefault"
              control={
                <MySwitch
                  name="isDefault"
                  color="primary"
                  checked={isDefault}
                  onChange={(e: ChangeEvent<HTMLInputElement>) =>
                    setIsDefault(e.target.checked)
                  }
                />
              }
              label="Endereço padrão"
              labelPlacement="start"
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="street"
              control={control}
              as={({ value, onChange }) => (
                <MyOutlinedTextField
                  fullWidth
                  label="Endereço"
                  type="text"
                  inputProps={{ ref: streetInput }}
                  changeFocusOnEnter={() => numberInput.current.focus()}
                  onChange={onChange}
                  isLoading={loading}
                  isDisabled={disabled}
                  error={Boolean(errors.street)}
                  helperText={errors.street ? errors.street.message : null}
                  value={value || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <MyOutlinedTextField
              fullWidth
              label="Número"
              id="number"
              name="number"
              type="text"
              inputProps={{ ref: numberInput }}
              changeFocusOnEnter={() => neighborhoodInput.current.focus()}
              error={Boolean(errors.number)}
              helperText={errors.number ? errors.number.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="neighborhood"
              control={control}
              as={({ value, onChange }) => (
                <MyOutlinedTextField
                  fullWidth
                  label="Bairro"
                  type="text"
                  inputProps={{ ref: neighborhoodInput }}
                  changeFocusOnEnter={() => cityInput.current.focus()}
                  onChange={onChange}
                  isLoading={loading}
                  isDisabled={disabled}
                  error={Boolean(errors.neighborhood)}
                  helperText={
                    errors.neighborhood ? errors.neighborhood.message : null
                  }
                  value={value}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="city"
              control={control}
              as={({ value, onChange }) => (
                <MyOutlinedTextField
                  fullWidth
                  label="Cidade"
                  type="text"
                  inputProps={{ ref: cityInput }}
                  changeFocusOnEnter={() => stateInput.current.focus()}
                  onChange={onChange}
                  isLoading={loading}
                  isDisabled={disabled || disabledField || !!address?.city}
                  error={Boolean(errors.city)}
                  helperText={errors.city ? errors.city.message : null}
                  value={value || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="state"
              control={control}
              as={({ value, onChange }) => (
                <MyOutlinedTextField
                  fullWidth
                  label="Estado"
                  type="text"
                  inputProps={{ ref: stateInput }}
                  changeFocusOnEnter={() => complementInput.current.focus()}
                  onChange={onChange}
                  isLoading={loading}
                  isDisabled={disabled || disabledField || !!address?.street}
                  error={Boolean(errors.state)}
                  helperText={errors.state ? errors.state.message : null}
                  value={value || ''}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="complement"
              control={control}
              as={({ value, onChange }) => (
                <MyOutlinedTextField
                  fullWidth
                  label="Complemento"
                  type="text"
                  inputProps={{ ref: complementInput }}
                  onChange={onChange}
                  isLoading={loading}
                  isDisabled={disabled}
                  error={Boolean(errors.complement)}
                  helperText={
                    errors.complement ? errors.complement.message : null
                  }
                  value={value || ''}
                />
              )}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          item
          className={styles.dialogSpacing}
          justify="flex-end"
        >
          <Button
            color="primary"
            className={formStyles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => dispatch(closeModal())}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${formStyles.buttonLabel} ${formStyles.buttonWrapper}`}
            onClick={handleSubmit(onSubmit)}
            disabled={submitLoading}
          >
            {submitLoading ? (
              <>
                {'Salvando'}
                <CircularProgress className={styles.progress} size={15} />
              </>
            ) : (
              'Salvar'
            )}
          </Button>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default AddressDialog;
