import { Reducer } from 'redux';
import { CouponsState, CouponsTypes } from './types';

const INITIAL_STATE: CouponsState = {
  data: [],
  hasError: false,
  isLoading: true,
  total: 0,
};

const reducer: Reducer<CouponsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CouponsTypes.LOAD_REQUEST:
      return { ...state, isLoading: true };
    case CouponsTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, hasError: true };
    case CouponsTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
        total: action.payload.total,
      };
    default:
      return state;
  }
};

export default reducer;
