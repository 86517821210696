import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles({
  mTop: {
    marginTop: 32,
  },
  mBottom: {
    marginBottom: 12,
  },
  slider: {
    borderRadius: 5,
  },
});
