import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CondOperator } from '@nestjsx/crud-request';
import {
  Dialog,
  Grid,
  TablePagination,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import { useFilters } from 'hooks/filters';
import { AppState } from 'store';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import { loadAllVideos } from 'store/ducks/generals/video/actions';
import { VideoState } from 'store/ducks/generals/video/types';
import { useStyles } from './styles';
import VideoPlayerDialog from 'components/generals/dialog/VideoPlayerDialog';
import { Video } from 'types/generals';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import { VideoPlayerDialogProps } from 'components/generals/dialog/dialogTypes';
import VideoCard from './VideoCard';

const Study: FC = () => {
  const searchFilter = [
    {
      field: 'title',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'keyWord',
      operator: CondOperator.CONTAINS_LOW,
    },
  ];
  const { genericFilterFields, handleGenericSearch } = useFilters({});

  const { data: contents, total, isLoading, hasError } = useSelector<
    AppState,
    VideoState
  >(({ videos }: AppState) => videos);

  const paperProps = { style: { padding: 0 } };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const styles = useStyles();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState<
    VideoPlayerDialogProps | undefined
  >(undefined);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  const cardHeight = isMobile ? '340px' : '240px';
  const playerWidth = isMobile ? '450px' : '260px';
  const playerHeight = isMobile ? '250px' : '150px';
  const justify = isMobile ? 'center' : 'flex-start';

  const loadContents = useCallback(() => {
    dispatch(
      loadAllVideos({
        page: page + 1,
        limit: rowsPerPage,
        search: {
          $and: [
            { $or: [...genericFilterFields] },
            { isActive: { $eq: true } },
          ],
        },
      }),
    );
  }, [dispatch, page, rowsPerPage, genericFilterFields]);

  useEffect(() => {
    loadContents();
  }, [loadContents]);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handlePreviousVideo = (videoIndex: number) => {
    const newIndex = Math.abs(videoIndex - 1);
    handleSetVideoByIndex(newIndex);
  };

  const handleNextVideo = (videoIndex: number) => {
    const newIndex = Math.abs(videoIndex + 1);
    handleSetVideoByIndex(newIndex);
  };
  const getNewModalProps = (content: Video, index: number) => {
    return {
      ...content,
      videoIndex: index,
      videosLength: contents.length,
      playerWidth: isMobile ? '450px' : '640px',
      playerHeight: isMobile ? '250px' : '360px',
      handleCloseModal: handleCloseModal,
      handlePreviousVideo: handlePreviousVideo,
      handleNextVideo: handleNextVideo,
    };
  };
  const handleSetVideoByIndex = (index: number) => {
    // Math.abs(videoIndex - 1)
    console.log({ index });
    const content = contents[index];
    const modalProps: VideoPlayerDialogProps = getNewModalProps(content, index);
    /* const modalProps: VideoPlayerDialogProps = {
      ...content,
      videoIndex: index,
      videosLength: contents.length,
      playerWidth: isMobile ? '450px' : '640px',
      playerHeight: isMobile ? '250px' : '360px',
      handleCloseModal: handleCloseModal,
      handlePreviousVideo: handlePreviousVideo,
      handleNextVideo: handleNextVideo,
    }; */
    setSelectedVideo(modalProps);
  };

  const handlePlay = (content: Video, videoIndex: number) => {
    /* const modalProps: VideoPlayerDialogProps = {
      ...content,
      videoIndex,
      videosLength: contents.length,
      playerWidth: isMobile ? '450px' : '640px',
      playerHeight: isMobile ? '250px' : '360px',
      handleCloseModal: handleCloseModal,
      handlePreviousVideo: handlePreviousVideo,
      handleNextVideo: handleNextVideo,
    }; */
    const modalProps: VideoPlayerDialogProps = getNewModalProps(
      content,
      videoIndex,
    );

    setSelectedVideo(modalProps);
    setOpen(true);
  };

  const handleUpdatePage = (page: number) => {
    setPage(page);
  };

  const handleUpdateRowsPerPage = (rowsPerPage: number) => {
    setRowsPerPage(rowsPerPage);
  };

  const VideoDialog = ({ video }: any) => (
    <Dialog
      maxWidth="md"
      PaperProps={paperProps}
      open={open}
      onClose={handleCloseModal}
    >
      {video ? <VideoPlayerDialog {...video} /> : null}
    </Dialog>
  );

  return (
    <>
      <Grid
        container
        item
        xs={12}
        sm={6}
        direction="column"
        className={styles.marginTop}
      >
        <MyInputFilter
          asFilter
          onChange={e => handleGenericSearch(e, searchFilter)}
          placeholder="Buscar conteúdo"
        />
      </Grid>
      <Grid container direction="column">
        <Grid
          className={styles.marginTop}
          container
          spacing={2}
          direction="row"
          justify={justify}
        >
          {!isLoading && !hasError && contents
            ? contents.map((content, index) => (
                <Grid className={styles.cardPlayer} item key={index} xs="auto">
                  <VideoCard
                    videoIndex={index}
                    className={styles.videoCard}
                    url={content.url}
                    title={content.title}
                    author={content.author}
                    playerWidth={playerWidth}
                    playerHeight={playerHeight}
                    cardHeight={cardHeight}
                    handlePlay={handlePlay}
                    light={true}
                  />
                </Grid>
              ))
            : null}
        </Grid>
      </Grid>
      <TablePagination
        ActionsComponent={MyTablePaginationActions}
        component="div"
        hidden={!total}
        count={total}
        page={page}
        labelRowsPerPage="Itens por página"
        onChangePage={(_, newPage: number) => handleUpdatePage(newPage)}
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
          handleUpdatePage(0);
          handleUpdateRowsPerPage(parseInt(event.target.value, 10));
        }}
        rowsPerPageOptions={ROWS_PER_PAGE}
      />
      <VideoDialog video={selectedVideo} />
    </>
  );
};

export default Study;
