import { Banner } from 'types/generals/banner';

export enum BannerTypes {
  LOAD_BANNERS_REQUEST = '@banners/LOAD_BANNERS_REQUEST',
  UPDATE_BANNERS_REQUEST = '@banners/UPDATE_BANNERS_REQUEST',
  INIT_BANNERS_REQUEST = '@banners/INIT_BANNERS_REQUEST',
  END_BANNERS_REQUEST = '@banners/END_BANNERS_REQUEST',
  LOAD_BANNERSS_SUCCESS = '@banners/LOAD_BANNERS_SUCCESS',
  LOAD_BANNERS_FAILURE = '@banners/LOAD_BANNERS_FAILURE',
}

export interface BannerState {
  readonly storeBanners: Banner[];
  readonly isLoading: boolean;
  readonly hasError: boolean;
}
