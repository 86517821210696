import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTableCell from 'components/generals/table/MyTableCell';

import { formatColumnValue } from 'helpers/formatters';

import { useStyles } from './styles';
import { UsersListProps } from './types';
import { FormatOptions } from 'types';
import { useHistory } from 'react-router-dom';

const UsersList: React.FC<UsersListProps> = ({ sort, handleSort, users }) => {
  const classes = useStyles();
  const history = useHistory();

  const getPermission = (user: any) => {
    return user?.roles.map((role: any) => role.name).join(', ');
  };

  const onClick = (user: any) => {
    history.push(`/admin/users/${user.id}`, { user });
  };

  return (
    <Paper className={classes.ordersList} elevation={0}>
      <TableContainer>
        <Table className={classes.table} aria-label="users table">
          <TableHead>
            <TableRow>
              <MySortableTableCell
                onClick={() => handleSort('id')}
                direction={sort.field === 'id' && sort.order}
              >
                Código
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('name')}
                direction={sort.field === 'name' && sort.order}
              >
                Nome
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('email')}
                direction={sort.field === 'email' && sort.order}
              >
                E-mail
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('isStaff')}
                direction={sort.field === 'isStaff' && sort.order}
              >
                Permissionamento
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('createdAt')}
                direction={sort.field === 'createdAt' && sort.order}
              >
                Data de Criação
              </MySortableTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(users &&
              users.length &&
              users.map(user => (
                <TableRow
                  key={user.id}
                  className={classes.clickableRow}
                  onClick={() => onClick(user)}
                >
                  <MyTableCell>{user.id}</MyTableCell>
                  <MyTableCell>{user.name}</MyTableCell>
                  <MyTableCell>{user.email}</MyTableCell>
                  <MyTableCell>{getPermission(user)}</MyTableCell>
                  <MyTableCell>
                    {user.createdAt
                      ? formatColumnValue(FormatOptions.DATE, user.createdAt)
                      : ''}
                  </MyTableCell>
                </TableRow>
              ))) ||
              null}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};
export default UsersList;
