import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';
import { BannerTypes } from './types';

export const loadBanners = (query: CreateQueryParams) => {
  return action(BannerTypes.LOAD_BANNERS_REQUEST, { query });
};
export const initRequest = () => {
  return action(BannerTypes.INIT_BANNERS_REQUEST);
};
export const endRequest = () => {
  return action(BannerTypes.END_BANNERS_REQUEST);
};
export const updateBanners = (data: any) => {
  return action(BannerTypes.UPDATE_BANNERS_REQUEST, { storeBanners: data });
};
export const loadBannersFailure = () => {
  return action(BannerTypes.LOAD_BANNERS_FAILURE);
};
