import { Reducer } from 'redux';
import { Order } from 'types/generals';
import { OrderOneState, OrderTypes } from './types';

const INITIAL_STATE: OrderOneState = {
  data: {} as Order,
  hasError: false,
  isLoading: true,
};

const orderMonthReducer: Reducer<OrderOneState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case OrderTypes.LOAD_MONTH_ORDER_SIGNATURE_REQUEST:
      return { ...state, isLoading: true };
    case OrderTypes.LOAD_MONTH_ORDER_SIGNATURE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        data: action.payload.data,
      };
    default:
      return state;
  }
};

export default orderMonthReducer;
