import React, { useEffect } from 'react';
import {
  Grid,
  DialogContent,
  DialogTitle,
  DialogActions,
  Typography,
  Button,
  IconButton,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useForm, Controller } from 'react-hook-form';

import CloseIcon from '@material-ui/icons/Close';
import { closeModal } from 'store/ducks/nav/actions';
import useStyles from 'components/generals/forms/register/styles';
import MySelectForm from 'components/generals/input/MySelectForm';

import MyOutlinedTextField from '../input/MyOutlinedTextField';
import { SHIPPING_TYPE } from 'types/generals';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import ShippingService from 'services/shippingService';
import { StatusCodes } from 'http-status-codes';
import { ShippingData } from './types';
import { validationSchema } from './utils';
import { OrderShippingDialogProps } from './dialogTypes';
import {
  SHIPPING_CREATED_SUCCESS,
  SHIPPING_CREATE_ERROR,
} from 'utils/messages';

const OrderShippingDialog: React.FC<OrderShippingDialogProps> = ({
  modalInfo,
  modalTitle,
  ordersIds,
  cancelText,
  cancelAction,
  submitText,
  transport,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const { handleSubmit, getValues, control, errors, reset } = useForm({
    validationSchema,
  });
  const submitOrderShipping = async () => {
    const shippingData: ShippingData = {
      name: getValues('name'),
      shippingType: getValues('shipping'),
      transport: getValues('transport'),
      orderIds: ordersIds,
    };

    try {
      const response = await ShippingService.createNewShipping(shippingData);
      if (response.status === StatusCodes.CREATED) {
        dispatch(notifySuccess(SHIPPING_CREATED_SUCCESS));
        window.location.reload();
        dispatch(closeModal());
      } else {
        throw new Error(SHIPPING_CREATE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  useEffect(() => {
    if (transport) {
      reset({ transport });
    }
  }, [reset, transport]);

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {modalTitle}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column" spacing={1}>
          <Grid item xs>
            <Controller
              name="name"
              control={control}
              as={({ onChange, value }) => (
                <MyOutlinedTextField
                  fullWidth
                  id="name-field"
                  label="Nome"
                  name="name"
                  type="text"
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors.name)}
                  helperText={errors.name ? errors.name.message : null}
                />
              )}
            />
          </Grid>

          <Grid item xs>
            <Controller
              name="transport"
              control={control}
              as={({ onChange, value }) => (
                <MyOutlinedTextField
                  fullWidth
                  id="transport-field"
                  label="Transportadora"
                  name="transport"
                  InputProps={{ readOnly: !!transport }}
                  type="text"
                  onChange={onChange}
                  value={value}
                  error={Boolean(errors.transport)}
                  helperText={
                    errors.transport ? errors.transport.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs>
            <Controller
              name="shipping"
              control={control}
              as={({ onChange, onBlur, value }) => (
                <MySelectForm
                  contracted
                  title="Tipo"
                  itens={Object.values(SHIPPING_TYPE).map(shipping => ({
                    title: shipping,
                    value: shipping,
                  }))}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value || ''}
                  error={Boolean(errors.shipping)}
                  helperText={errors.shipping ? errors.shipping.message : null}
                />
              )}
            />
          </Grid>
          <Grid item xs>
            <Typography variant="h4" color="textPrimary">
              {modalInfo}
            </Typography>
          </Grid>
        </Grid>
      </DialogContent>
      <Grid
        container
        direction="row"
        justify="flex-end"
        alignItems="center"
        spacing={2}
        className={styles.actionsWrapper}
      >
        <DialogActions>
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={cancelAction || (() => dispatch(closeModal()))}
          >
            {cancelText ?? 'Cancelar'}
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
            onClick={handleSubmit(submitOrderShipping)}
          >
            {submitText}
          </Button>
        </DialogActions>
      </Grid>
    </Grid>
  );
};

export default OrderShippingDialog;
