import { makeStyles, createStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme =>
  createStyles({
    termsAndConditionsInfo: {
      fontWeight: 500,
      fontSize: 14,
      color: theme.palette.grey['400'],
      textAlign: 'center',
      marginBottom: 16,
    },
    termsAndConditionsLink: {
      textDecoration: 'none',
    },
    termsAndConditionsDetail: {
      color: theme.palette.grey['400'],
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  }),
);

export default useStyles;
