import { StepConnector, withStyles } from '@material-ui/core';
import Colors from 'styles/colors';

const MyStepConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-35% + 20px)',
    right: 'calc(65% + 20px)',
  },
  active: {
    '& $line': {
      borderColor: Colors.RED,
    },
  },
  completed: {
    '& $line': {
      borderColor: Colors.RED,
    },
  },
  line: {
    borderColor: Colors.LIGHTER_GRAY,
    borderTopWidth: 1,
    borderRadius: 8,
  },
})(StepConnector);

export default MyStepConnector;
