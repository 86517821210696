import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { AppState } from 'store';
import { UserState } from 'store/ducks/user/types';
import ListItem from './ListItem';
import { List } from './styles';
import { ListComponentProps } from './types';

const ListComponent: React.FC<ListComponentProps> = ({
  items,
  handleCloseMenu,
}) => {
  const { data: user } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const userIsPremium = useMemo(() => user?.isPremium, [user]);

  return (
    <List>
      {items
        .filter(i => !i?.isDisabled)
        .map(item => {
          if ((!userIsPremium && !item.onlyPremium) || userIsPremium) {
            return (
              <ListItem
                key={item.id}
                item={item}
                handleCloseMenu={handleCloseMenu}
              />
            );
          } else {
            return null;
          }
        })}
    </List>
  );
};

export default ListComponent;
