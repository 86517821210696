import React, { useState } from 'react';
import {
  Grid,
  DialogTitle,
  Typography,
  IconButton,
  DialogContent,
  Button,
} from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { StatusCodes } from 'http-status-codes';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import MyOutlinedTextField from '../input/MyOutlinedTextField';

import { closeModal } from 'store/ducks/nav/actions';
import { notifySuccess, notifyError } from 'store/ducks/notification/actions';
import SignatureService from 'services/signatureService';

import { useDialogStyles } from './useStyles';
import { SignatureTypeDialogProps } from './dialogTypes';
import { ObservationPayload } from './types';
import { SignatureObservationValidationSchema } from './utils';
import {
  ADD_OBSERVATION_SIGNATURE_ERROR,
  ADD_OBSERVATION_SIGNATURE_SUCCESS,
} from 'utils/messages';

const AddObservationSignatureDialog: React.FC<SignatureTypeDialogProps> = ({
  subscriber,
  modalTitle,
  submitText,
}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const style = useDialogStyles();
  const [disabledButton, setDisabledButton] = useState(false);

  const { handleSubmit, errors, register } = useForm({
    validationSchema: SignatureObservationValidationSchema,
  });

  const onSubmit = async (data: ObservationPayload) => {
    try {
      setDisabledButton(true);
      const response = await SignatureService.handleAddObservationSignature(
        subscriber.id,
        data.observation,
      );

      if (response.status !== StatusCodes.CREATED) {
        throw new Error(ADD_OBSERVATION_SIGNATURE_ERROR);
      } else {
        dispatch(notifySuccess(ADD_OBSERVATION_SIGNATURE_SUCCESS));
        history.go(0);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      setDisabledButton(false);
      dispatch(closeModal());
    }
  };

  return (
    <Grid>
      <DialogTitle>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1">{modalTitle}</Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon fontSize="large" />
          </IconButton>
        </Grid>
      </DialogTitle>

      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs>
            <MyOutlinedTextField
              id="observation"
              name="observation"
              label="Observação"
              multiline
              fullWidth
              rows={3}
              inputRef={register}
              error={Boolean(errors.observation)}
              helperText={
                errors.observation ? errors.observation.message : null
              }
            />
          </Grid>

          <Grid container item justify="flex-end">
            <Button
              className={clsx(style.buttonLabel, style.buttonWrapper)}
              variant="contained"
              color="primary"
              disabled={disabledButton}
              onClick={handleSubmit(onSubmit as any)}
            >
              {submitText}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Grid>
  );
};

export default AddObservationSignatureDialog;
