import {
  Order,
  ORDER_STATUS,
  ORDER_TYPE,
  RECURRENCES_MONTHS,
} from 'types/generals';

export const filtersFields = {
  orderType: { filterTitle: 'Série', value: undefined },
  createdAt: { filterTitle: 'Período', value: undefined },
  status: { filterTitle: 'Status', value: undefined },
  'orderDetail.planPaymentType': { filterTitle: 'Pagamento', value: undefined },
};

export const canDisableReactivate = (order: Order) => {
  return (
    order.status !== ORDER_STATUS.CANCELED &&
    order.status !== ORDER_STATUS.PENDING_CANCELED
  );
};

/**
 * Essa função serve para habilitar/desabilitar a opção de cobrar nela tem as seguintes
 * verificações:
 * - Primeiro verificamos o status do pedido
 * - O pedido só deve estar como pendente, efetuado ou recusado para poder aparecer o botão.
 * - Se o pedido for mensal e do tipo assinatura, o botão também fica ativo.
 * - Se o pedido for do tipo diferente de mensal e for de assinatura, é verificado se o pedido
 * é o primeiro
 * - Pedido de loja só verifica o status.
 */
export const canDisableOrEnableChargeOrder = (order: Order) => {
  const status_order = [
    ORDER_STATUS.FAILED,
    ORDER_STATUS.PENDING,
    ORDER_STATUS.SUBMITTED,
  ].includes(order.status);

  const verifyOrderSignatureNotIsMonthlyAndIsFirst =
    order.orderDetail.orderType === ORDER_TYPE.SIGNATURE &&
    order.orderDetail.signaturePlan?.recurrenceMonths !==
      RECURRENCES_MONTHS.MONTHLY &&
    order.isFirst;

  const verifyOrderSignatureMonthly =
    order.orderDetail.orderType === ORDER_TYPE.SIGNATURE &&
    order.orderDetail.signaturePlan?.recurrenceMonths ===
      RECURRENCES_MONTHS.MONTHLY;

  // habilita a opção de cobrar
  if (
    status_order &&
    (verifyOrderSignatureNotIsMonthlyAndIsFirst ||
      verifyOrderSignatureMonthly ||
      order.orderDetail.orderType === ORDER_TYPE.STORE)
  ) {
    return false;
  } else {
    return true;
  }
};

export const canDisableChangeStatusOrder = (order: Order) => {
  const status_order = [
    ORDER_STATUS.FAILED,
    ORDER_STATUS.PENDING,
    ORDER_STATUS.SUBMITTED,
  ];

  return !status_order.includes(order.status);
};
