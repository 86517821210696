import { Reducer } from 'redux';
import { ViewUserTurbineState, ViewBulkActionTurbineTypes } from './types';

const INITIAL_STATE: ViewUserTurbineState = {
  data: [],
  hasError: false,
  isLoading: false,
  total: 0,
};

const reducer: Reducer<ViewUserTurbineState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ViewBulkActionTurbineTypes.LOAD_VIEW_USERS_TURBINE_REQUEST:
      return { isLoading: true, hasError: false, data: [], total: 0 };
    case ViewBulkActionTurbineTypes.LOAD_VIEW_USERS_TURBINE_FAILURE:
      return { isLoading: false, hasError: true, data: [], total: 0 };
    case ViewBulkActionTurbineTypes.LOAD_VIEW_USERS_TURBINE_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload.data,
        total: action.payload.total,
      };

    default:
      return state;
  }
};

export default reducer;
