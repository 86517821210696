/* https://github.com/mui-org/material-ui/blob/master/packages/material-ui/src/TablePagination/TablePaginationActions.js */
import React from 'react';
import TablePaginationActions, {
  TablePaginationActionsProps,
} from '@material-ui/core/TablePagination/TablePaginationActions';

const MyTablePaginationActions: React.FC<TablePaginationActionsProps> = props => {
  return <TablePaginationActions {...props} />;
};

export default MyTablePaginationActions;
