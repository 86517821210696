import { StoreCategory } from 'types/generals';

export enum CategoriesTypes {
  LOAD_FILE_REQUEST = '@categories/LOAD_FILE_REQUEST',
  LOAD_REQUEST = '@categories/LOAD_REQUEST',
  LOAD_SUCCESS = '@categories/LOAD_SUCCESS',
  LOAD_FAILURE = '@categories/LOAD_FAILURE',
}

export interface CategoriesState {
  readonly categories: StoreCategory[];
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly total: number;
}
