import produce from 'immer';
import { Reducer } from 'redux';
import { StoreState, StoreTypes } from './types';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';

const INITIAL_STATE = {
  data: [],
  total: 0,
  isLoading: true,
  hasError: false,
  page: 0,
  rowsPerPage: ROWS_PER_PAGE[1],
};

const storeReducer: Reducer<StoreState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case StoreTypes.LIST_STORE_REQUEST:
      return produce(state, draft => {
        draft.isLoading = true;
        draft.hasError = false;
      });
    case StoreTypes.LIST_STORE_SUCCESS:
      return produce(state, draft => {
        draft.isLoading = draft.hasError = false;
        draft.data = action.payload.data;
        draft.total = action.payload.total;
      });
    case StoreTypes.LIST_STORE_FAIL:
      return produce(state, draft => {
        draft.hasError = true;
        draft.isLoading = false;
        draft.data = [];
      });
    case StoreTypes.SET_ROWS_PER_PAGE:
      return produce(state, draft => {
        draft.rowsPerPage = action.payload.rowsPerPage;
      });

    case StoreTypes.SET_STORE_PAGE:
      return produce(state, draft => {
        draft.page = action.payload.page;
      });
    default:
      return state;
  }
};

export default storeReducer;
