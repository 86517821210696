import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  containerSpacing: {
    margin: theme.spacing(4.3, 0, 1),
  },
  marginContainer: {
    margin: theme.spacing(2, 0),
  },
  paperTable: {
    padding: theme.spacing(6, 4),
    borderRadius: '2em',
  },
  grayText: {
    color: Colors.DARK_GRAY,
  },
  mButton: {
    margin: theme.spacing(0, 10),
  },
  borderDivider: {
    borderLeft: '1px',
    borderLeftStyle: 'solid',
    height: '100%',
    borderLeftColor: '#d8d8d8',
  },
  iconAligned: {
    verticalAlign: 'bottom',
    marginRight: '3px',
  },
  iconText: {
    fontSize: '1.2rem',
    margin: theme.spacing(0.5, 0),
  },
});
