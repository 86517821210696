import {
  CartProductDetails,
  MAX_CART_LENGTH,
  MAX_PRODUCT_IN_CART,
} from 'types/client';
import { PaymentInstallment, StoreProduct } from 'types/generals';
import {
  MAX_CART_LENGTH_ERROR,
  MAX_PRODUCT_IN_CART_ERROR,
} from 'utils/messages';

export const verifyCartRules = (
  cartLength: number,
  cartProducts: CartProductDetails[],
  product: StoreProduct,
) => {
  const productCountInCart = cartProducts.find(
    productInCart => productInCart.id === product.id,
  )?.productCount;

  if (cartLength === MAX_CART_LENGTH) throw new Error(MAX_CART_LENGTH_ERROR);

  if (productCountInCart === MAX_PRODUCT_IN_CART)
    throw new Error(MAX_PRODUCT_IN_CART_ERROR);

  return true;
};

export const applyPercentDiscount = (subTotal: number, discount: number) => {
  return (subTotal * (discount / 100)).toFixed(2);
};

export const calculateInstallments = (
  cartAmount: number,
): PaymentInstallment[] => {
  let installmentOptions: PaymentInstallment[] = [];
  let installments = 1;

  if (cartAmount < 30 || (cartAmount >= 30 && cartAmount < 60)) {
    installments = 1;
  } else if (cartAmount >= 60 && cartAmount < 90) {
    installments = 2;
  } else if (cartAmount >= 90 && cartAmount < 120) {
    installments = 3;
  } else if (cartAmount >= 120 && cartAmount < 150) {
    installments = 4;
  } else if (cartAmount >= 150 && cartAmount < 180) {
    installments = 5;
  } else if (cartAmount >= 180 && cartAmount < 210) {
    installments = 6;
  } else if (cartAmount >= 210 && cartAmount < 240) {
    installments = 7;
  } else if (cartAmount >= 240 && cartAmount < 270) {
    installments = 8;
  } else if (cartAmount >= 270 && cartAmount < 300) {
    installments = 9;
  } else if (cartAmount >= 300) {
    installments = 10;
  }

  for (let i = 1; i <= installments; i++) {
    installmentOptions.push({
      installments: i,
      installmentPrice: Number((cartAmount / i).toFixed(2)),
    });
  }

  return installmentOptions;
};
