import { call, put } from 'redux-saga/effects';
import TrackingService from 'services/sigep/trackingService';
import {
  loadModicoSuccess,
  loadPacSuccess,
  loadSedexSuccess,
  loadTrackingFail,
} from './actions';

/* TODO: Código a ser otimizado para pegar services de outro endpoint com seu identificador */
export function* loadSedexTrackingSaga(action: any) {
  try {
    const { data } = yield call(
      TrackingService.getLabelsByService,
      action.payload.query,
    );
    yield put(loadSedexSuccess({ data }));
  } catch (error) {
    yield put(loadTrackingFail());
  }
}

export function* loadPacTrackingSaga(action: any) {
  try {
    const { data } = yield call(
      TrackingService.getLabelsByService,
      action.payload.query,
    );
    yield put(loadPacSuccess({ data }));
  } catch (error) {
    yield put(loadTrackingFail());
  }
}

export function* loadModicoTrackingSaga(action: any) {
  try {
    const { data } = yield call(
      TrackingService.getLabelsByService,
      action.payload.query,
    );
    yield put(loadModicoSuccess({ data }));
  } catch (error) {
    yield put(loadTrackingFail());
  }
}
