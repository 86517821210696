import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

export const useStyles = makeStyles({
  paperTable: {
    padding: theme.spacing(3),
    borderRadius: '2em',
    margin: '50px 20px 20px 20px',
  },
  cardImg: {
    [theme.breakpoints.only('xs')]: {
      height: '277px',
    },
    height: '254px',
    minHeight: '220px',
    borderRadius: '16px',
    cursor: 'pointer',
  },
  selfCenter: {
    alignSelf: 'center',
  },

  content: {
    [theme.breakpoints.only('sm')]: {
      padding: theme.spacing(3, 0, 1),
    },
    [theme.breakpoints.only('xl')]: {
      padding: theme.spacing(3, 0, 2),
    },
    padding: theme.spacing(3, 0, 3),
  },

  boxDescription: {
    fontSize: '13px',
    paddingRight: '12px',
    margin: theme.spacing(1, 0, 3),
    maxHeight: '90px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  boldText: {
    fontWeight: 700,
  },
  container: {
    position: 'relative',
    width: '100%',
  },

  actiontButton: {
    backgroundColor: Colors.BLUE,
    color: Colors.WHITE,
    width: '100%',
    height: 44,
    padding: 12,
    fontSize: 16,
    fontWeight: 600,
    borderRadius: 7,
    '&:hover': {
      backgroundColor: Colors.BLUE,
    },
  },
});
