import { api } from './apiService';

export default class RecurrencesService {
  static async getAllRecurrences() {
    try {
      const response = await api.get('/recurrences');
      return response.data;
    } catch (error) {
      return error;
    }
  }
}
