import * as Yup from 'yup';
import { COUPON_LIMIT } from './types';
import { validateDate } from 'helpers/validateDate';

export const validationSchema = Yup.object({
  name: Yup.string().required(),
  value: Yup.string().when('percent', {
    is: value => !value,
    then: Yup.string().required(),
  }),
  percent: Yup.string().test('required', 'Campo obrigatório', function (value) {
    const { parent } = this;
    if (!parent.value && !value) return this.createError();
    return true;
  }),
  start_date: Yup.string()
    .required()
    .test(
      'invalid-start-date',
      'Data de início anterior ao momento presente',
      function (value) {
        if (!value) return this.createError({ message: 'Campo obrigatório' });

        const validate = validateDate(value);
        if (validate && validate < 0) return this.createError();
        return true;
      },
    ),
  end_date: Yup.string()
    .required()
    .test(
      'invalid-end-date',
      'Data de termino anterior ao momento presente',
      function (value) {
        if (!value) return this.createError({ message: 'Campo obrigatório' });

        if (!this.parent.start_date) {
          const validate = validateDate(value);
          if (validate && validate < 0) return this.createError();
        } else {
          const validate = validateDate(this.parent.start_date, value);
          if (validate && validate < 0) {
            return this.createError({
              message: 'Data de termino menor que a data de início',
            });
          }
        }

        return true;
      },
    ),
  couponLimit: Yup.string().required(),
  limit: Yup.string().when('couponLimit', {
    is: COUPON_LIMIT.Limited,
    then: Yup.string().required(),
  }),
  couponType: Yup.string().required(),
});
