import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';

import { Box } from 'types';
import { BoxesTypes } from './types';
import { ROOT_PATH } from 'services/fileservice';

/* All Boxes Actions */
export const loadBoxes = (query?: CreateQueryParams) =>
  action(BoxesTypes.LOAD_BOXES_REQUEST, query);

export const loadBoxesSuccess = (payload: { data: Box[]; total: number }) =>
  action(BoxesTypes.LOAD_BOXES_SUCCESS, payload);

export const loadBoxesFail = () => action(BoxesTypes.LOAD_BOXES_FAIL);

export const loadBoxFile = (query: CreateQueryParams, rootPath: ROOT_PATH) => {
  return action(BoxesTypes.LOAD_BOXES_FILE_REQUEST, { query, rootPath });
};
