import React from 'react';
import { Grid, Typography, Divider } from '@material-ui/core';

import { formatPaymentType, PAYMENT_TYPE, getUnitPrice } from 'types/generals';
import { moneyFormat } from 'pages/client/signature/utils';
import { useStyles } from './styles';
import { SelectedPlanCardProps } from './types';
import { Link } from 'react-router-dom';
import { TERMS_CONDITIONS } from 'utils/links/termsConditions';
import MyTerms from 'components/generals/terms';

const SelectedPlanCard: React.FC<SelectedPlanCardProps> = ({
  cardChecked,
  plan,
}) => {
  const style = useStyles();

  const FRETE = 15;
  const PLAN_CARD_PRICE_CUSTOM =
    plan.prices?.cardPrice - FRETE * plan.recurrence?.months || 0;
  const PLAN_BANKSLIP_PRICE_CUSTOM =
    plan.prices?.bankslipPrice - FRETE * plan.recurrence?.months || 0;

  const PLAN_CARD_PRICE = plan.prices?.cardPrice || 0;
  const PLAN_BANKSLIP_PRICE = plan.prices?.bankslipPrice || 0;
  const PLAN_CARD_DISCOUNT = plan.discounts?.cardDiscount || 0;
  const PLAN_BANKSLIP_DISCOUNT = plan.discounts?.bankslipDiscount || 0;

  return (
    <Grid container item xs={12} md={3} className={style.planInfoGrid}>
      <Grid item>
        <Typography variant="h5" className={style.selectedPlanText}>
          Plano escolhido:
        </Typography>
      </Grid>

      <Grid container item justify="space-between">
        <Grid item>
          <Typography variant="h2" className={style.boldText}>
            Plano {plan?.recurrence?.name}
          </Typography>
          <Typography variant="h3" className={style.boldText}>
            {cardChecked
              ? formatPaymentType(
                  plan.recurrence?.months,
                  PAYMENT_TYPE.CARD,
                  PLAN_CARD_PRICE_CUSTOM,
                )
              : formatPaymentType(
                  plan.recurrence?.months,
                  PAYMENT_TYPE.BANKSLIP,
                  PLAN_BANKSLIP_PRICE_CUSTOM,
                )}
            /mês
          </Typography>
        </Grid>
        {plan.image ? (
          <Grid item>
            <img
              alt="imagem do plano"
              className={style.planImage}
              src={plan.image}
            />
          </Grid>
        ) : null}
      </Grid>

      <Grid container item justify="space-between">
        <Grid item>
          <Typography variant="subtitle1">Frete</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">R$ 15,00</Typography>
        </Grid>
      </Grid>

      <Grid container item justify="space-between">
        <Grid item>
          <Typography variant="subtitle1">Valor unitário</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {moneyFormat(
              getUnitPrice(
                plan.recurrence?.months,
                cardChecked
                  ? PLAN_CARD_PRICE_CUSTOM
                  : PLAN_BANKSLIP_PRICE_CUSTOM,
              ),
            )}
          </Typography>
        </Grid>
      </Grid>

      {plan.message ? (
        <>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item>
            <Typography variant="subtitle1" className={style.planMessage}>
              {plan.message}
            </Typography>
          </Grid>
        </>
      ) : null}

      <Grid item xs={12}>
        <Divider />
      </Grid>

      <Grid container item justify="space-between">
        <Grid item>
          <Typography variant="h3" className={style.boldText}>
            Total:
          </Typography>
        </Grid>
        <Grid item>
          {cardChecked ? (
            <>
              {PLAN_CARD_DISCOUNT > 0 ? (
                <>
                  <Typography className={style.oldPrice} variant="h5">
                    De: {moneyFormat(PLAN_CARD_DISCOUNT)}
                  </Typography>
                  <Typography className={style.planPrice}>
                    Por:&nbsp;
                    {formatPaymentType(
                      plan.recurrence?.months,
                      PAYMENT_TYPE.CARD,
                      PLAN_CARD_PRICE,
                    )}
                  </Typography>
                </>
              ) : (
                <Typography className={style.boldText} variant="h4">
                  {formatPaymentType(
                    plan.recurrence?.months,
                    PAYMENT_TYPE.CARD,
                    PLAN_CARD_PRICE,
                  )}
                  /mês
                </Typography>
              )}
            </>
          ) : (
            <>
              {PLAN_BANKSLIP_DISCOUNT > 0 ? (
                <>
                  <Typography className={style.oldPrice} variant="h5">
                    De: {moneyFormat(PLAN_BANKSLIP_DISCOUNT)}
                  </Typography>

                  <Typography className={style.boldText} variant="h3">
                    Por:&nbsp;
                    {formatPaymentType(
                      plan.recurrence?.months,
                      PAYMENT_TYPE.BANKSLIP,
                      PLAN_BANKSLIP_PRICE,
                    )}
                  </Typography>
                </>
              ) : (
                <Typography className={style.boldText} variant="h4">
                  {formatPaymentType(
                    plan.recurrence?.months,
                    PAYMENT_TYPE.BANKSLIP,
                    PLAN_BANKSLIP_PRICE,
                  )}
                  /mês
                </Typography>
              )}
            </>
          )}
        </Grid>
      </Grid>

      <Grid
        container
        item
        justify="center"
        alignContent="center"
        alignItems="center"
      >
        <Grid item xs>
          <Typography className={style.termsText}>
            Ao clicar em confirmar reativação, você concorda com nossos{' '}
            <MyTerms />
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SelectedPlanCard;
