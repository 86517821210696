import { action } from 'typesafe-actions';
import { PreShippimentOrderTypes, PreShippimentOrderPayload } from './types';

export const setPreShippimentOrders = (payload: PreShippimentOrderPayload) => {
  return action(PreShippimentOrderTypes.UPDATE_PRE_SHIPPIMENT_ORDERS, payload);
};

export const loadPreShippmentOrders = () => {
  return action(PreShippimentOrderTypes.LOAD_PRE_SHIPPMENT_ORDERS);
};

export const resetPreShippmentOrders = () => {
  return action(PreShippimentOrderTypes.RESET_PRE_SHIPPIMENT_ORDERS);
};
