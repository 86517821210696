import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';

export const useStyles = makeStyles(theme => ({
  appBannerText: {
    fontSize: '18px',
    fontWeight: 500,
    padding: theme.spacing(3.9, 0, 3),
    zIndex: 1,
  },
  badgesStore: {
    height: '3.6rem',
    cursor: 'pointer',
    margin: theme.spacing(0, 0.9, 0, 0),
  },
  alignEnd: {
    [theme.breakpoints.up('sm')]: {
      justifyContent: 'flex-end',
    },
    justifyContent: 'end',
    minHeight: '14vw',
  },
  paperAppBg: {
    padding: theme.spacing(3, 4),
    borderRadius: '2em',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    color: Colors.WHITE,
    minHeight: '15vw',
    objectFit: 'fill',
  },
}));
