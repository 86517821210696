import { Reducer } from 'redux';
import { ViewUserLastOrderState, ViewBulkActionTypes } from './types';

const INITIAL_STATE: ViewUserLastOrderState = {
  data: [],
  hasError: false,
  isLoading: false,
  total: 0,
};

const reducer: Reducer<ViewUserLastOrderState> = (
  state = INITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case ViewBulkActionTypes.LOAD_VIEW_USERS_LAST_ORDER_REQUEST:
      return { isLoading: true, hasError: false, data: [], total: 0 };
    case ViewBulkActionTypes.LOAD_VIEW_USERS_LAST_ORDER_FAILURE:
      return { isLoading: false, hasError: true, data: [], total: 0 };
    case ViewBulkActionTypes.LOAD_VIEW_USERS_LAST_ORDER_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload.data,
        total: action.payload.total,
      };

    default:
      return state;
  }
};

export default reducer;
