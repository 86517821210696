import { QueryFilter } from '@nestjsx/crud-request';

export interface QueryFilterFull extends QueryFilter {
  filterTitle?: string;
}

export interface PageFilterData {
  rawFilters: QueryFilterFull[];
  mappedFilters: {
    [key: string]: Object[];
  };
}

export interface PageFilters {
  [key: string]: PageFilterData;
}

/**
 * Action types
 */
export enum PageFilterTypes {
  FILTERS_UPDATE = '@filters/update',
}

/**
 * State types
 */
export interface PageFilterState {
  readonly filters: PageFilters;
}
