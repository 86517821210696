import { makeStyles, createStyles } from '@material-ui/core/styles';
import Colors from 'styles/colors';

const useStyles = makeStyles(theme =>
  createStyles({
    rootPaper: {
      marginTop: '2em',
      padding: '4em',
      borderRadius: '2em',
      marginBottom: '3em',
    },
    actionButton: {
      fontSize: '1.6rem',
      fontWeight: 'bold',
      textTransform: 'capitalize',
      width: '100%',
      textAlign: 'left',
    },
    inputWrapper: {
      borderRadius: 4,
      border: `1px solid ${Colors.GRAY}`,
      padding: '0.8em 0.7em',
      '& label': {
        transform: 'translate(3em, 30px)',
        fontSize: '1.75em',
      },
      '& div': {
        '& input': {
          fontSize: '1.25em',
          height: '1.25em',
          paddingLeft: '5px',
        },
        '& fieldset': {
          border: 'none',
        },
      },
    },
    filterOptionsList: {
      backgroundColor: Colors.WHITE,
    },
    menuItemText: {
      fontSize: '1.6rem',
      fontWeight: 600,
      color: Colors.GRAY,
    },
    menuItemTextLink: {
      fontSize: '1.6rem',
      fontWeight: 600,
      color: Colors.RED,
    },
    inputLabel: {
      padding: '3em 1.5em',
    },
    actionsWrapper: {
      paddingTop: '1em',
      paddingRight: '1em',
    },
    buttonWrapper: {
      padding: '0.5em 3em',
    },
    buttonLabel: {
      fontSize: '1.5em',
      height: '3.2em',
      borderRadius: 13,
    },
    selectWrapper: {
      borderRadius: 13,
      width: '100%',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'black',
    },
    inputFileWrapper: {
      display: 'none',
    },
    paddingSpacing: {
      padding: '12px',
    },
    radioWrapper: {
      border: `1px solid ${theme.palette.grey['500']}`,
      padding: '6px',
      borderRadius: 8,
      margin: '0 0 8px 0',
      '& .MuiTypography-root': {
        fontSize: 16,
        color: theme.palette.grey['800'],
        fontWeight: 500,
      },
    },
    progress: {
      color: Colors.WHITE,
    },
    paymentAlert: {
      marginTop: 0,
      marginLeft: 4,
      fontSize: 12,
      color: theme.palette.primary.main,
    },
    gridSignatureStatus: {
      marginTop: '-42px',
      fontWeight: 'bold',
      marginBottom: '21px',
    },
    signatureStatusText: {
      opacity: '0.8',
      fontWeight: 'bold',
    },
  }),
);

export default useStyles;
