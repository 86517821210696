import { CreateQueryParams } from '@nestjsx/crud-request';
import { action } from 'typesafe-actions';

import { User } from 'types/generals/user';
import { UserTypes } from './types';
import { ROOT_PATH } from 'services/fileservice';

export const loadUserRequest = () => action(UserTypes.LOAD_REQUEST);
export const loadStaffRequest = (query?: CreateQueryParams) =>
  action(UserTypes.LOAD_STAFF_REQUEST, { query });
export const loadUserFailure = () => action(UserTypes.LOAD_FAILURE);
export const loadStaffFailure = () => action(UserTypes.LOAD_STAFF_FAILURE);
export const loadUserSuccess = (data: User) =>
  action(UserTypes.LOAD_SUCCESS, { data });
export const loadStaffSuccess = (data: User, total: number) =>
  action(UserTypes.LOAD_STAFF_SUCCESS, { data, total });
export const clearUserData = () => action(UserTypes.CLEAR_USER);

export const loadStaffFile = (
  query: CreateQueryParams,
  rootPath: ROOT_PATH,
) => {
  return action(UserTypes.LOAD_STAFF_FILE_REQUEST, { query, rootPath });
};

export const loadUserWalletRequest = (query: CreateQueryParams) => {
  return action(UserTypes.LOAD_USER_WALLET_REQUEST, { query });
};
export const loadUserWalletSuccess = (data: any) => {
  return action(UserTypes.LOAD_USER_WALLET_SUCCESS, { data });
};
export const loadUserWalletFailure = () => {
  return action(UserTypes.LOAD_USER_WALLET_FAILURE);
};
