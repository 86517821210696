import React from 'react';

// TODO: Rever os campos DATE & SIMPLE_DATE para tornar um só
export enum FormatOptions {
  MONEY = 'MONEY',
  DATE = 'DATE',
  SIMPLE_DATE = 'SIMPLEDATE',
  DATETIME = 'DATETIME',
  IN_FULL_DATE = 'INFULLDATE',
  MONTH_YEAR_DATE = 'MONTHYEARDATE',
  TIME = 'TIME',
  CREDIT_CARD = 'CREDITCARD',
  ZIPCODE = 'ZIPCODE',
  PHONE = 'PHONE',
  START_DATE = 'STARTDATE',
  SIMPLE_DATE_WITH_TRACE = 'SIMPLEDATEWITHTRACE',
  DAY_AND_MONTH_WITH_TRACE = 'DAY_AND_MONTH_WITH_TRACE',
  VIEW_DATE = 'VIEW_DATE',
}

export enum ColumnTypes {
  DELETE = 'DELETE',
}

export interface ColumnProps {
  label: string;
  sortable?: boolean;
  getter?: string;
  format?: ((v: any) => string) | FormatOptions;
  type?: ColumnTypes | null;
  onDelete?: (item: any) => void;
}

export interface Sort {
  field: string;
  order: 'ASC' | 'DESC';
}

export interface MyTableProps {
  total: number;
  page: number;
  data: any[];
  columns: ColumnProps[];
  defaultSort?: Sort;
  onSortClicked?: (sort: Sort) => void;
  onChangePage: (event: any, page: number) => void;
  rootContainerStyle?: any;
  isLoading?: boolean;
  onRowClick?: (item?: any) => void;
  selectRowsPerPage: number;
  handleChangeRowsPerPage?: (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => void;
  rowsPerPageOptions?: number[];
  title?: string;
  addButtonLabel?: string;
  onAddButton?: () => void;
}

export interface Filters {
  [key: string]: { filterTitle: string; value: any };
}
