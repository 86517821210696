import { makeStyles, createStyles, Theme } from '@material-ui/core';

import Colors from 'styles/colors';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    containerWrapper: {
      padding: '24px 30px',
      [theme.breakpoints.up('md')]: {
        padding: '112px 100px 0px 100px',
        height: '100vh',
        overflow: 'auto',
      },
      [theme.breakpoints.up('lg')]: {
        padding: '112px 170px 0px 170px',
      },
    },
    titleText: {
      fontSize: 24,
      fontWeight: 'bold',
    },
    subtitleText: {
      fontSize: 18,
      fontWeight: 500,
    },
    containerForm: {
      marginTop: 32,
    },
    inputText: {
      marginBottom: 18,
    },
    inputWrapper: {
      '& .MuiInputLabel-outlined': {
        transform: 'translate(14px, 25px) scale(1)',
      },
      '& .MuiInputLabel-outlined.MuiInputLabel-shrink': {
        transform: 'translate(12px, 10px) scale(0.75)',
      },
      '& .MuiOutlinedInput-root': {
        borderRadius: 8,
        height: 64,
      },
    },
    button: {
      width: '100%',
      marginTop: 40,
      padding: 18,
      fontSize: 14,
      fontWeight: 'bold',
      borderRadius: 8,
      height: 64,
    },
    divider: {
      marginTop: 40,
      marginBottom: 40,
    },
    observationText: {
      marginTop: 32,
      fontSize: 16,
      fontWeight: 500,
      [theme.breakpoints.up('md')]: {
        textAlign: 'center',
        marginBottom: '10px',
      },
    },
    observationLink: {
      fontWeight: 'bold',
      color: Colors.RED,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
    helperText: {
      fontSize: 16,
      fontWeight: 500,
      marginTop: 8,
      [theme.breakpoints.up('md')]: {
        marginTop: 16,
      },
    },
    socialFBButton: {
      borderColor: '#4267b2',
      color: '#4267b2',
    },
    progress: {
      color: Colors.WHITE,
    },
    termsConditionsContainer: {
      position: 'relative',
      bottom: 0,
      top: 100,
      paddingBottom: 20,
      '@media (min-height: 800px)': {
        top: 280,
      },
    },
  }),
);

export default useStyles;
