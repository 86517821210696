import { Grid, styled } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';
import { ICustomTag } from './types';

export const Tag = styled(Grid)(
  ({ background, color }: Partial<ICustomTag>) => ({
    padding: theme.spacing(0.5, 1.6),
    margin: theme.spacing(1, 1, 1, 0),
    width: 'min-content',
    minWidth: '50px',
    maxWidth: '100%',
    whiteSpace: 'nowrap',
    borderRadius: '5px',
    backgroundColor: `${background || Colors.SALMON}`,
    color: `${color || Colors.WHITE}`,
    fontSize: '13px',
    fontWeight: 600,
    justifyContent: 'space-around',
    alignItems: 'center',
  }),
);
