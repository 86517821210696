import { Coupon } from 'types/generals/coupon';

export enum CouponsTypes {
  LOAD_COUPON_FILE_REQUEST = '@coupons/LOAD_COUPON_FILE_REQUEST',
  LOAD_REQUEST = '@coupons/LOAD_REQUEST',
  LOAD_SUCCESS = '@coupons/LOAD_SUCCESS',
  LOAD_FAILURE = '@coupons/LOAD_FAILURE',
}

export interface CouponsState {
  readonly data: Coupon[];
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly total: number;
}
