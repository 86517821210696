import { ORDER_STATUS } from 'types/generals';

export const ORDER_CANCELLING_CONFIRM =
  'Deseja confirmar o cancelamento do pedido?';
export const ORDER_REACTIVATE_CONFIRM =
  'Deseja fazer o reativamento do pedido?';
export const NO_PREVIOUS_ORDERS_BOXES =
  'Até o momento, não encontramos boxes anteriores';
export const ORDER_DETAIL_ADDRESS_CHANGE_CONFIRM =
  'Deseja atualizar o endereço para seus pedidos futuros?';
export const ORDER_DETAIL_ADDRESS_CHANGED =
  'Endereço dos pedidos futuros atualizado';
export const ORDER_DETAIL_ADDRESS_CHANGE_ERROR =
  'Erro ao atualizar pedidos futuros.';

export const ORDER_DETAIL_USER_CHANGE_INFO_CONFIRM =
  'Deseja alterar as informações nos pedidos futuros?';
export const ORDER_DETAIL_USER_CHANGE_SUCCESS =
  'Informações do usuário alteradas nos pedidos com sucesso.';
export const ORDER_DETAIL_USER_CHANGE_ERROR =
  'Ocorreu um erro ao alterar as informações nos pedidos do usuário.';

// Payment
export const ORDER_SECOND_COPY_BANKSLIP_CONFIRM =
  'Deseja emitir a segunda via do boleto?';
export const ORDER_SECOND_COPY_BANKSLIP_ESSUED =
  'Segunda via do boleto emitida com sucesso.';
export const ORDER_SECOND_COPY_BANKSLIP_ERROR =
  'Ocorreu um erro ao processar o boleto.';
export const ORDER_BANKSLIP_ISSUED =
  'Aguarde, seu boleto está sendo processado. Confira em seu e-mail cadastrado.';
export const REFUND_PARCIAL_PAYMENT = (price: number) =>
  `Insira o valor desejado para o estorno parcial, se não infomado o estorno será total: R$ ${price}`;
export const REFUND_PAYMENT_CONFIRM = (id: number, amount: number) =>
  `Deseja realizar o estorno do pedido ${id} no valor de ${amount}?`;
export const ORDER_REFUND_SOLICITATION =
  'Sua solicitação de estorno do pedido foi realizada com sucesso';
export const ORDER_REFUND_ERROR = (message: string) =>
  `Houve uma falha ao solicitar o estorno: ${message}`;
export const ORDER_REFUND_LIMIT =
  'O valor de estorno deve ser no máximo o valor do pedido';
export const ORDER_REQUIRED_REFUND = 'O valor de estorno é obrigatório';
export const PAYMENT_NOT_FOUND_ORDER = 'Nenhum pagamento encontrado';
export const TRYING_ORDER_PAYMENT = `Nova tentativa de pagamento executada com
sucesso`;
export const TRYING_BANK_SLIP_ORDER_PAYMENT = `Novo boleto gerado com sucesso`;
export const TRYING_ORDER_ANTECIPATE_BILLING = `Antecipação de cobrança executada`;
export const ORDER_PAYMENT_BANKSLIP_PENDING_MESSAGE_MODAL =
  'Seu box ainda não está garantido! Pague agora mesmo seu boleto.';
export const ORDER_PAYMENT_BANKSLIP_LINK_TITLE = 'Gerar Boleto';
export const ORDER_PAYMENT_CARD_LINK_TITLE = 'Renovar agora';

// enhanced
export const ENHANCED_ORDER_BANKSLIP_WARNING =
  'Para turbinar a sua assinatura é necessário ter pago o pedido mensal. Acesse seu boleto por este link.';
export const ENHANCED_ORDER_CARD_WARNING =
  'Para turbinar a sua assinatura é necessário ter pago o pedido mensal. Deseja realizar sua renovação agora?';

export const PRODUCT_ADDED_SUCCESS =
  'O produto desejado foi adicionado ao pedido';
export const PRODUCT_ADDED_ERROR =
  'Houve uma falha ao adicionar o produto desejado no pedido';
// Resend
export const RESEND_ORDER_SUCCESS = 'O pedido foi reenviado com sucesso';
export const RESEND_ORDER_FAIL =
  'Houve uma falha inesperada ao efetuar o reenvio do pedido';
export const RESEND_EMPTY_PRODUCTS =
  'O pedido de reenvio não pode ser criado sem produtos declarados';

// Cancelling
export const CANCEL_STORE_ORDER_INFO = `Com essa ação você está solicitando a suspensão deste pedido da loja. 
Por favor, adicione o motivo do cancelamento para que possamos entrar em contato por e-mail e confirmá-lo para você!`;

// Charge
export const ORDER_CHARGE_TITLE = 'Deseja cobrar esse pedido?';
export const ORDER_CHARGE_CONFIRM =
  'O pagamento será executado no cartão principal cadastrado no usuário do pedido.';
export const ORDER_CHARGE_SUCCESS =
  'A solicitação de cobrança foi registrada, aguarde enquanto o sistema processa a cobrança.';
export const ORDER_CHARGE_ERROR =
  'Houve uma falha inesperada ao tentar efetuar a cobrança.';
export const ORDER_PAYMENT_SUCCESS = (boxName: string) =>
  `Obrigado! Será efetuada nova tentativa de cobrança com o novo cartão cadastrado referente ao box ${boxName}`;

// remove
export const ORDER_MARK_AS_REMOVED_SUCCESS = 'Pedido removido com sucesso.';
export const ORDER_MARK_AS_REMOVED_ERROR =
  'Houve uma falha ao remover o pedido.';
export const ORDER_MARK_AS_REMOVED_CONFIRM = (id: number) =>
  `Deseja remover o pedido nº ${id}`;

// Status order
export const ORDER_STATUS_CHANGE_CONFIRM = (
  oldStatus: ORDER_STATUS,
  newStatus: ORDER_STATUS,
) => `Deseja alterar o status do pedido de ${oldStatus} para ${newStatus}?`;
export const ORDER_STATUS_CHANGE_SUCCESS =
  'Status do pedido alterado com sucesso.';
export const ORDER_STATUS_CHANGE_ERROR =
  'Houve uma falha ao tentar alterar o status do pedido.';

// Create Order
export const ORDER_CREATE_SUCCESS = 'Pedido criado com sucesso.';
export const ORDER_CREATE_ERROR = 'Houve uma falha ao criar o pedido.';
export const ORDER_CREATE_CONFIRM =
  'Deseja adicionar o pedido para o assinante?';
