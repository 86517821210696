import { CreateQueryParams } from '@nestjsx/crud-request';
import FileSaver from 'file-saver';
import {
  api,
  getWithSaga,
  apiQueue,
  transformBlobResponseInJSON,
} from './apiService';
import { ShippingData } from 'components/generals/dialog/types';
import { SHIPPING_STATUS } from 'types/generals';

const URL_BASE = 'shipping';

export default class ShippingService {
  static async generateCsvFile(shippingId: number, shippingName: string) {
    try {
      const { data } = await apiQueue.get(
        `${URL_BASE}/${shippingId}/export-csv`,
        {
          responseType: 'blob',
        },
      );

      const url = window.URL.createObjectURL(new Blob([data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${shippingName}.csv`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      return error.response.data;
    }
  }

  static async setSigepLabelsOrders(shippingId: number) {
    try {
      const { data } = await apiQueue.put(
        `${URL_BASE}/${shippingId}/set-sigep-labels`,
      );
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async closeShipping(shippingId: number) {
    try {
      const shippingClosed = await apiQueue.put(
        `${URL_BASE}/${shippingId}/close`,
      );
      return shippingClosed;
    } catch (error) {
      return error.response.data;
    }
  }

  static async generateInvoicesShipping(shippingId: number) {
    try {
      const shipping = await apiQueue.put(
        `${URL_BASE}/${shippingId}/generate-invoices`,
      );
      return shipping;
    } catch (error) {
      return error.response.data;
    }
  }

  static async loadShippingList(queryParams: CreateQueryParams) {
    try {
      const data = await getWithSaga(URL_BASE, queryParams);
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async loadShippingOne(shippingId: string) {
    try {
      const { data } = await api.get(`shipping/${shippingId}`);
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async createShiping(name: string, orderIds: number[]) {
    try {
      const { data } = await apiQueue.post(URL_BASE, {
        orderIds,
        name,
      });
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async createNewShipping(shippingData: ShippingData) {
    try {
      const data = await apiQueue.post(URL_BASE, shippingData);
      return data;
    } catch (error) {
      return error.reponse.data;
    }
  }

  static async updateShipping(shippingId: number, orderIds: number[]) {
    try {
      const data = await api.patch(`${URL_BASE}/${shippingId}`, {
        orderIds,
      });
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async updateNameOrTransport(
    shippingId: number,
    name: string,
    transport: string,
    status: SHIPPING_STATUS,
  ) {
    try {
      const response = await api.put(`${URL_BASE}/${shippingId}`, {
        name,
        transport,
        status,
      });
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  static async addOrderInShipping(shippingId: number, orderIds: number[]) {
    try {
      const response = await apiQueue.post(
        `${URL_BASE}/${shippingId}/add-order`,
        {
          orderIds,
        },
      );
      return response;
    } catch (error) {
      return error.response.data;
    }
  }

  static async appendProductInShipping(shippingId: number, productId: number) {
    try {
      const response = await apiQueue.post(
        `${URL_BASE}/${shippingId}/add-product/${productId}`,
      );
      return response;
    } catch (error) {
      return error;
    }
  }

  static async deleteProductInShipping(shippingId: number, productId: number) {
    try {
      const data = await apiQueue.delete(
        `${URL_BASE}/${shippingId}/remove-product/${productId}`,
      );
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async deleteShipping(shippingId: number) {
    try {
      const { data } = await apiQueue.delete(`${URL_BASE}/${shippingId}`);
      return data;
    } catch (error) {
      return error.response.data;
    }
  }

  static async removeMultiOrderFromShipping(shippingId: number) {
    try {
      const orderDeleted = await apiQueue.delete(
        `${URL_BASE}/${shippingId}/orders`,
      );
      return orderDeleted;
    } catch (error) {
      return error.response.data;
    }
  }

  static async removeOrderFromShipping(shippingId: number, orderId: number) {
    try {
      const orderDeleted = await apiQueue.delete(
        `${URL_BASE}/${shippingId}/orders/${orderId}`,
      );
      return orderDeleted;
    } catch (error) {
      return error.response.data;
    }
  }

  static async exportXml(shippingId: number, boxWeight: number) {
    try {
      const { data } = await api.post(
        `${URL_BASE}/${shippingId}/export-xml/${boxWeight}`,
        {},
        {
          responseType: 'blob',
        },
      );
      FileSaver.saveAs(data, 'Remessa.xml');
      return data;
    } catch (error) {
      return error;
    }
  }

  static async exportPackingList(shippingId: number) {
    try {
      const { data } = await apiQueue.get(
        `${URL_BASE}/${shippingId}/export-packing-list`,
        {
          responseType: 'blob',
        },
      );
      FileSaver.saveAs(data, `Romaneio_${shippingId}.xlsx`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async exportLabels(shippingId: number) {
    try {
      const { data } = await apiQueue.post(
        `${URL_BASE}/${shippingId}/export-labels`,
        {},
      );
      return data;
    } catch (error) {
      const { data } = error.response;
      return (await transformBlobResponseInJSON(data)).message;
    }
  }

  static async updateKeyAccessInvoices(shippingId: number, serie: string) {
    try {
      const response = await apiQueue.post(
        `${URL_BASE}/${shippingId}/update-access-keys`,
        {
          serie,
        },
      );

      return response;
    } catch (error) {
      throw error;
    }
  }
}
