import { PostService } from 'store/ducks/sigep/tracking/types';

export const MIN_TRACKING_CODES = 10000;

export const POSTSERVICES: PostService[] = [
  {
    serviceName: 'Sedex',
    serviceNumber: '03220',
  },
  {
    serviceName: 'PAC',
    serviceNumber: '03298',
  },
  {
    serviceName: 'Módico',
    serviceNumber: '20125',
  },
];

export enum SIGEP_SERVICES {
  SEDEX = 'Sedex',
  PAC = 'Pac',
  MODICO = 'Módico',
}
