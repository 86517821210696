import React, { FC, memo, useEffect, useState } from 'react';
import { FileRejection, useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import clsx from 'clsx';
import { Badge, Button, Grid, Paper, Typography } from '@material-ui/core';
import ImageOutlinedIcon from '@material-ui/icons/ImageOutlined';

import Colors from 'styles/colors';
import { useStyles } from './styles';
import CloseIcon from '@material-ui/icons/Close';
import { CustomFile, ImageZoneProps, IMAGES_ZONE_ERROR } from './types';
import { notifyError } from 'store/ducks/notification/actions';
import {
  DEFAULT_FILE_ERROR,
  DELETE_PRODUCT_FAIL,
  INVALID_FILE_FORMAT_ERROR,
  TOO_LARGE_FILE_ERROR,
  TOO_MANY_FILES_ERROR,
  TOO_SMALL_FILE_ERROR,
} from 'utils/messages';
import { head } from 'lodash';

const ImageZone: FC<ImageZoneProps> = memo(
  ({
    files,
    filesData,
    deleteImageFn,
    errors,
    register,
    setValue,
    subtitle,
    title,
    handleDropFile,
    handleRemoveFile,
  }) => {
    const styles = useStyles();
    const dispatch = useDispatch();
    const [filesInZone, setFilesInZone] = useState<CustomFile[]>([]);
    const [tempFile, setTempFile] = useState(filesData);

    const { getInputProps, getRootProps, open: openDropzone } = useDropzone({
      accept: 'image/jpeg, image/png',
      multiple: false,
      maxFiles: 1,
      onDropAccepted: droppedContent => {
        const filesOnDrop = droppedContent.map(file =>
          Object.assign(file, {
            image: URL.createObjectURL(file),
            isCover: false,
          }),
        );

        setFilesInZone([...filesOnDrop]);
        if (handleDropFile) {
          handleDropFile(filesOnDrop);
        }
      },
      onDropRejected: (rejections: FileRejection[]) => {
        const errors = head(rejections)?.errors;
        const errorMessage = head(errors)?.code;

        let rejectError = DEFAULT_FILE_ERROR;

        switch (errorMessage) {
          case IMAGES_ZONE_ERROR.TOO_SMALL_FILE:
            rejectError = TOO_SMALL_FILE_ERROR;
            break;
          case IMAGES_ZONE_ERROR.TOO_LARGE_FILE:
            rejectError = TOO_LARGE_FILE_ERROR;
            break;
          case IMAGES_ZONE_ERROR.TOO_MANY_FILES:
            rejectError = TOO_MANY_FILES_ERROR(1);
            break;
          case IMAGES_ZONE_ERROR.INVALID_FILE_FORMAT:
            rejectError = INVALID_FILE_FORMAT_ERROR;
            break;
        }
        dispatch(notifyError(rejectError));
      },
      onFileDialogCancel: () => {
        setTempFile(filesData);
      },
    });

    const deleteImage = (imageId: number) => {
      try {
        if (deleteImageFn) deleteImageFn(imageId);
      } catch (err) {
        dispatch(notifyError(DELETE_PRODUCT_FAIL));
      }
    };

    const handleDeleteFile = (fileToDelete: string) => {
      if (filesInZone.length) {
        const fileIndex = filesInZone.findIndex(
          file => file.image === fileToDelete,
        );
        const updatedFiles = [...filesInZone];
        const isNewImage = !Object.keys(updatedFiles[fileIndex]).includes('id');
        if (fileIndex >= 0) {
          if (!isNewImage) {
            const imageId = updatedFiles[fileIndex].id;
            if (imageId && deleteImageFn) {
              deleteImage(imageId);
            } else {
              dispatch(notifyError(DELETE_PRODUCT_FAIL));
            }
          }
          updatedFiles.splice(fileIndex, 1);
          setFilesInZone(updatedFiles);
          if (handleRemoveFile) {
            handleRemoveFile();
            setTempFile({});
          }
        }
      } else {
        if (handleRemoveFile) {
          handleRemoveFile();
          setTempFile({});
        }
      }
    };

    useEffect(() => {
      if (files) setFilesInZone(files);
    }, [files]);

    useEffect(() => {
      setTempFile(filesData);
    }, [filesData]);

    const handleChangeImage = () => {
      setTempFile({});
      setTimeout(() => {
        openDropzone();
      }, 200);
    };

    const ImageThumbnail = () => (
      <Grid item xs className={styles.previewArea}>
        <Grid>
          <Badge
            color="secondary"
            overlap="circle"
            badgeContent={
              <CloseIcon
                onClick={() => handleDeleteFile(filesData.imageLink)}
              />
            }
          >
            <img
              src={filesData.imageLink}
              className={styles.thumb}
              alt="Prévia da imagem"
            />
          </Badge>
        </Grid>
      </Grid>
    );

    return (
      <Grid container justify="space-between" direction="column" spacing={1}>
        {title ? (
          <Grid item xs>
            <Typography variant="h4" className={styles.title}>
              {title}
            </Typography>
          </Grid>
        ) : null}
        <Grid item xs>
          <Grid item xs>
            {errors && Boolean(errors.images) ? (
              <Typography style={{ color: Colors.RED }}>
                {errors.images.message}
              </Typography>
            ) : null}
          </Grid>

          <Paper className={styles.rootPaper} elevation={0}>
            <Grid
              container
              justify="space-between"
              direction="column"
              spacing={4}
            >
              {!tempFile.imageLink ? (
                <Grid
                  {...getRootProps({ className: 'dropzone' })}
                  item
                  sm={12}
                  className={clsx(styles.sectionZone)}
                >
                  <input {...getInputProps()} />
                  <Grid
                    container
                    justify="space-between"
                    direction="column"
                    alignItems="center"
                  >
                    <Grid item xs>
                      <ImageOutlinedIcon
                        color="primary"
                        className={styles.imageIconSize}
                      />
                    </Grid>
                    <Grid item xs>
                      <Typography
                        variant="h2"
                        color="primary"
                        className={styles.boldNoWrapText}
                      >
                        Adicionar imagem
                      </Typography>
                    </Grid>
                    <Grid item xs className={styles.subTextGrid}>
                      <Typography variant="h3" className={styles.subText}>
                        Formatos .png ou .jpg.
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
              ) : (
                <ImageThumbnail />
              )}

              <Grid>
                <Button onClick={() => handleChangeImage()}>
                  <Typography
                    variant="h4"
                    color="textPrimary"
                    className={styles.btnAction}
                  >
                    {subtitle}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    );
  },
);

export default ImageZone;
