import React from 'react';
import { MuiThemeProvider, CssBaseline } from '@material-ui/core';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import theme from './styles/theme';
import Routes from './routes/routes';
import store, { persistor } from 'store';
import CustomSnackbarProvider from 'components/generals/notification/CustomSnackbarProvider';
import setLocale from 'helpers/localeValidation';
import './App.css';
import { BrowserRouter } from 'react-router-dom';

function App() {
  setLocale();
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <CustomSnackbarProvider>
            <BrowserRouter>
              <Routes />
            </BrowserRouter>
          </CustomSnackbarProvider>
        </PersistGate>
      </Provider>
    </MuiThemeProvider>
  );
}

export default App;
