import React, {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';

import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import MyNewButton from 'components/generals/buttons/MyNewButton';
import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MyTableCell from 'components/generals/table/MyTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';

import { AppState } from 'store';
import { loadAllPlans, loadPlanFile } from 'store/ducks/generals/plan/actions';

import { useFilters } from 'hooks/filters';
import { PlanState } from 'store/ducks/generals/plan/types';
import { Plan } from 'types/generals';
import { searchObject, PlansFilters } from './utils';
import { useStyles } from './styles';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { ROOT_PATH } from 'services/fileservice';
import { FileState } from 'store/ducks/generals/file/types';

const PlanList: FC = () => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const history = useHistory();
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const {
    sort,
    page,
    filterFields,
    genericFilterFields,
    handleGenericSearch,
    handleSort,
    handleSetValuePage,
  } = useFilters(PlansFilters);

  const { data: plans = [], isLoading, total, hasError } = useSelector<
    AppState,
    PlanState
  >(({ plans }: AppState) => plans);

  const { hasError: hasErrorFile } = useSelector<AppState, FileState>(
    ({ file }: AppState) => file,
  );
  const loadPlans = useCallback(() => {
    dispatch(
      loadAllPlans({
        sort,
        page: page + 1,
        limit: rowsPerPage,
        search: {
          $and: [...filterFields, { $or: [...genericFilterFields] }],
        },
      }),
    );
  }, [dispatch, sort, page, rowsPerPage, filterFields, genericFilterFields]);

  const loadPlansXls = useCallback(() => {
    dispatch(
      loadPlanFile(
        {
          join: [['recurrence'], ['boxes']],
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [...filterFields, { $or: [...genericFilterFields] }],
          },
        },
        ROOT_PATH.PLANS,
      ),
    );
  }, [dispatch, page, sort, rowsPerPage, genericFilterFields, filterFields]);

  const redirectToPlan = (plan: Plan) =>
    history.push(`/admin/plans/${plan.id}`, { plan });

  useEffect(() => {
    loadPlans();
  }, [loadPlans]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Planos'));
  }, [dispatch]);

  const handleNewPlan = () => {
    history.push(`/admin/plans-new`);
  };

  return (
    <Grid container className={styles.containerMargin}>
      <Grid
        container
        justify="space-between"
        alignItems="center"
        className={styles.headerMargin}
      >
        <Grid item xs={3} sm={3} md={3} className={styles.searchFilter}>
          <MyInputFilter
            onChange={(event: any) => handleGenericSearch(event, searchObject)}
            placeholder={'Buscar'}
          />
        </Grid>
        <Grid container item xs justify="flex-end" alignItems="flex-end">
          <Grid item xs={2} xl={2}>
            <ActionsButton
              hasErrorFile={hasErrorFile}
              menuItems={[
                {
                  title: 'Adicionar',
                  action: handleNewPlan,
                },
                {
                  title: 'Exportar planilha de planos',
                  action: loadPlansXls,
                },
              ]}
              buttonText="Ações"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column" spacing={3}>
        <Grid item xs={12}>
          <Paper className={styles.paperTable} elevation={0}>
            <TableContainer>
              <Table className={styles.table} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <MySortableTableCell
                      onClick={() => handleSort('id')}
                      direction={sort.field === 'id' && sort.order}
                    >
                      Código
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('description')}
                      direction={sort.field === 'description' && sort.order}
                    >
                      Nome
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('recurrence.name')}
                      direction={sort.field === 'recurrence.name' && sort.order}
                    >
                      Recorrência
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('paymentType')}
                      direction={sort.field === 'paymentType' && sort.order}
                    >
                      Pagamento
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('pices.bankslip')}
                      direction={sort.field === 'prices.bankslip' && sort.order}
                    >
                      Valor no boleto
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('prices.card')}
                      direction={sort.field === 'card' && sort.order}
                    >
                      Valor no cartão
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('isPrivate')}
                      direction={sort.field === 'isPrivate' && sort.order}
                    >
                      Status
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('isDonation')}
                      direction={sort.field === 'isDonation' && sort.order}
                    >
                      Doação
                    </MySortableTableCell>
                    <MySortableTableCell
                      onClick={() => handleSort('description')}
                      direction={sort.field === 'description' && sort.order}
                    >
                      Descrição
                    </MySortableTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {!isLoading && !hasError && plans
                    ? plans.map(plan => (
                        <TableRow
                          key={plan.id}
                          className={styles.clickableArea}
                          onClick={() => redirectToPlan(plan)}
                        >
                          <MyTableCell>{plan.id}</MyTableCell>
                          <MyTableCell>{plan.name}</MyTableCell>
                          <MyTableCell>{plan.recurrence.name}</MyTableCell>
                          <MyTableCell>{plan.paymentType}</MyTableCell>
                          <MyTableCell>
                            {plan.prices.bankslipPrice
                              ? formatColumnValue(
                                  FormatOptions.MONEY,
                                  plan.prices.bankslipPrice,
                                )
                              : '-'}
                          </MyTableCell>
                          <MyTableCell>
                            {plan.prices.cardPrice
                              ? formatColumnValue(
                                  FormatOptions.MONEY,
                                  plan.prices.cardPrice,
                                )
                              : '-'}
                          </MyTableCell>
                          <MyTableCell>
                            {plan.isPrivate === true ? 'Privado' : 'Publico'}
                          </MyTableCell>
                          <MyTableCell>
                            {plan.isDonation === true ? 'Sim' : 'Não'}
                          </MyTableCell>
                          <MyTableCell>{plan.description}</MyTableCell>
                        </TableRow>
                      ))
                    : null}
                </TableBody>
              </Table>
              <TablePagination
                ActionsComponent={MyTablePaginationActions}
                component="div"
                count={total}
                page={page}
                labelRowsPerPage="Itens por página"
                onChangePage={(_event: unknown, newPage: number) =>
                  handleSetValuePage(newPage)
                }
                rowsPerPage={rowsPerPage}
                onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
                  handleSetValuePage(0);
                  setRowsPerPage(parseInt(event.target.value, 10));
                }}
                rowsPerPageOptions={ROWS_PER_PAGE}
              />
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default PlanList;
