import * as Yup from 'yup';
import { PAYMENT_TYPE } from 'types/generals/payment';

export const validationSchema = Yup.object({
  paymentType: Yup.string().required('Selecione uma opção de pagamento'),
  installments: Yup.number().when('payment', {
    is: PAYMENT_TYPE.CARD,
    then: Yup.number().required('Campo obrigatório'),
  }),
  transportCompany: Yup.string().required('Selecione uma transportadora'),
});

export const defaultValues = () => {
  return {
    paymentType: '',
    installments: 1,
    transportCompany: '',
  };
};
