export const TOO_LARGE_FILE_ERROR = 'O arquivo desejado é muito grande';
export const TOO_SMALL_FILE_ERROR = 'O arquivo desejado é muito pequeno';
export const TOO_MANY_FILES_ERROR = (limit?: number) =>
  `Os arquivos selecionados ultrapassam o limite de ${
    limit || ''
  } arquivo(s) aceito(s).`;
export const INVALID_FILE_FORMAT_ERROR =
  'O arquivo desejado possui um formato não aceito';
export const DEFAULT_FILE_ERROR =
  'Não foi possível encontrar o arquivo desejado';
export const BLOB_FILE_PARSE_ERROR =
  'Houve uma falha ao converter o arquivo de resposta';
