import { Grid, Paper } from '@material-ui/core';
import React, { FC } from 'react';
import { Controller } from 'react-hook-form';

import MyDatePicker from 'components/generals/input/MyDatePicker';
import { StartEndDatePickerProps } from './types';

const StartEndDatePicker: FC<StartEndDatePickerProps> = ({
  control,
  errors,
  watch,
  startDateLabel = 'Data de início',
  endDateLabel = 'Data de termíno',
}: any) => {
  return (
    <Grid container direction="column">
      <Paper elevation={0}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="startDate"
              control={control}
              as={({ onChange, value }) => (
                <MyDatePicker
                  minDate={new Date()}
                  id="start-date"
                  name="startDate"
                  value={value}
                  onChange={onChange}
                  disablePast
                  label={startDateLabel}
                  error={Boolean(errors.startDate)}
                  helperText={
                    errors.startDate ? errors.startDate.message : null
                  }
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="endDate"
              control={control}
              as={({ onChange, value }) => (
                <MyDatePicker
                  minDate={
                    watch('startDate')
                      ? new Date(watch('startDate'))
                      : new Date()
                  }
                  id="end-date"
                  value={value}
                  onChange={onChange}
                  disablePast
                  name="endDate"
                  label={endDateLabel}
                  error={Boolean(errors.endDate)}
                  helperText={errors.endDate ? errors.endDate.message : null}
                />
              )}
            />
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default StartEndDatePicker;
