import React, { FC, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  FormControl,
  Grid,
  Paper,
  Typography,
} from '@material-ui/core';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import { useStyles } from './styles';

import { TrackingRangeProps } from 'components/generals/dialog/dialogTypes';
import TrackingRangeDialog from 'components/generals/dialog/TrackingRangeDialog';
import { openModal } from 'store/ducks/nav/actions';
import { useGetWithSWR } from 'services/apiService';
import LabelService from 'services/sigep/labelService';
import { MIN_TRACKING_CODES } from 'helpers/trackingConstants';
import { PostService } from 'store/ducks/sigep/tracking/types';

const ServiceContainer: FC<PostService> = ({ serviceName, serviceNumber }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const [trackingCodesCount, setTrackingCodesCount] = useState(0);
  const { data: serviceLabels } = useGetWithSWR(
    `sigep-label/${serviceNumber}/count`,
  );

  const handleTrackingRangeDialog = () => {
    const dialogProps: TrackingRangeProps = {
      serviceName,
      serviceNumber,
      submitAction: LabelService.setRange,
    };
    dispatch(openModal(TrackingRangeDialog, dialogProps));
  };

  useEffect(() => {
    setTrackingCodesCount(serviceLabels?.count || 0);
  }, [serviceLabels]);

  return (
    <Grid item xs>
      <Paper className={styles.paperTable} elevation={0}>
        <Grid
          container
          justify="space-between"
          direction="row"
          alignItems="center"
        >
          <Grid item xs>
            <Typography
              variant="h4"
              className={styles.grayText}
              style={{ fontWeight: 'bold' }}
            >
              {serviceName}
            </Typography>
          </Grid>
          <Grid item xs>
            <FormControl error>
              <Typography
                variant="h4"
                className={styles.grayText}
                style={{ fontWeight: 500 }}
              >
                {`${trackingCodesCount} códigos de rastreio`}
              </Typography>
              {trackingCodesCount < MIN_TRACKING_CODES && (
                <Grid container alignItems="center">
                  <Typography
                    color="error"
                    className={styles.iconText}
                    display="inline"
                  >
                    <ErrorOutlineOutlinedIcon className={styles.iconAligned} />
                    Restam poucos códigos disponíveis
                  </Typography>
                </Grid>
              )}
            </FormControl>
          </Grid>
          <Grid container justify="flex-end" item xs={6}>
            <Grid className={styles.borderDivider}>
              <Button
                className={styles.mButton}
                onClick={() => handleTrackingRangeDialog()}
              >
                <Typography
                  variant="h4"
                  style={{ fontWeight: 'bold' }}
                  color="primary"
                >
                  Adicionar faixas de códigos
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

export default ServiceContainer;
