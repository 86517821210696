import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Grid, Paper, Typography } from '@material-ui/core';

import { useStyles } from '../../../pages/admin/manager/orders/styles';
import { OrderReferencedProps } from '../../../pages/admin/manager/orders/types';
import { CondOperator } from '@nestjsx/crud-request';
import OrdersService from 'services/ordersService';
import { Order } from 'types/generals';
import { Link, useHistory } from 'react-router-dom';

// fixme: improved version of the volume componnent ( adjust when merge)
const OrderReferenced: FC<OrderReferencedProps> = ({
  order,
  isAdmin = false,
  mainOrderTitle = 'Status do pedido principal',
  refOrderTitle = 'Status dos pedidos referenciados',
  reason,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const orderPath = isAdmin ? '/admin/orders' : '/orders';
  //const title = order.isVolume
  const title = order.isEnhanced ? mainOrderTitle : refOrderTitle;
  const [isChildrenLoaded, setIsChildrenLoaded] = useState(false);
  const [referenceOrders, setReferenceOrders] = useState<Order[]>([]);

  const loadReferenceOrders = useCallback(async () => {
    const referenceOrders = reason
      ? order?.referenceOrders.filter(refer => refer.reason === reason)
      : order?.referenceOrders;
    const _filters = referenceOrders.map(_order => {
      return {
        field: 'id',
        operator: CondOperator.EQUALS,
        value: _order.id,
      };
    });

    if (_filters.length) {
      const response = await OrdersService.getAllOrders({
        or: _filters,
      });
      setIsChildrenLoaded(true);
      setReferenceOrders(response?.data);
    }
  }, [order.referenceOrders, reason]);

  useEffect(() => {
    if (order?.referenceOrders?.length && !isChildrenLoaded) {
      loadReferenceOrders();
    }
  }, [isChildrenLoaded, loadReferenceOrders, order]);

  const goToOrder = (id: Number) => {
    history.push(`${orderPath}/${id}`);
  };
  return (
    <>
      {referenceOrders?.length ? (
        <Grid item xs={12} style={{ marginTop: '25px', marginBottom: '25px' }}>
          <Paper className={styles.paperTable} elevation={0}>
            <Typography variant="h3" className={styles.paperInfoTitle}>
              {title}
            </Typography>
            <Grid item xs>
              {referenceOrders.map(order => {
                return (
                  <>
                    <Grid container alignItems="center">
                      <Grid
                        container
                        direction="row"
                        item
                        xs={6}
                        md={4}
                        alignItems="center"
                      >
                        <Grid item xs={5} md={4} lg={3}>
                          <Typography variant="h3">
                            <b>Pedido:</b>
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h3">{order.id}</Typography>
                        </Grid>
                      </Grid>

                      <Grid
                        container
                        direction="row"
                        item
                        xs={6}
                        md={4}
                        alignItems="center"
                      >
                        <Grid item xs={5} md={4} lg={3}>
                          <Typography variant="h3">
                            <b>Status:</b>
                          </Typography>
                        </Grid>
                        <Grid item xs>
                          <Typography variant="h4">{order.status}</Typography>
                        </Grid>
                      </Grid>

                      {isAdmin ? (
                        <Grid
                          container
                          direction="row"
                          item
                          xs={6}
                          md={4}
                          alignItems="center"
                        >
                          <Grid item xs>
                            <Button onClick={() => goToOrder(order.id)}>
                              <Typography variant="h4" color="primary">
                                <b>Ver detalhes</b>
                              </Typography>
                            </Button>
                          </Grid>
                        </Grid>
                      ) : (
                        ''
                      )}
                    </Grid>
                  </>
                );
              })}
            </Grid>
          </Paper>
        </Grid>
      ) : (
        ''
      )}
    </>
  );
};

export default OrderReferenced;
