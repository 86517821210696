import { PAYMENT_TYPE } from 'types/generals';
import {
  NOTIFICATION_GROUP,
  NOTIFICATION_TYPE,
  StoreNotification,
} from 'types/generals/notification';

const defaultInDivisionNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.IN_DIVISION,
  group: NOTIFICATION_GROUP.LOGISTICS,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultInShippingNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.IN_SHIPPING,
  group: NOTIFICATION_GROUP.LOGISTICS,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultDeliveryNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.DELIVERY,
  group: NOTIFICATION_GROUP.LOGISTICS,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultDeliveryFailureNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.DELIVERY_FAILURE,
  group: NOTIFICATION_GROUP.LOGISTICS,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultDeliveredNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.DELIVERED,
  group: NOTIFICATION_GROUP.LOGISTICS,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultWaitingRemoveNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.WAITING_REMOVE,
  group: NOTIFICATION_GROUP.LOGISTICS,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

/* PAYMENT STORE */
const defaultPendingNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.PENDING_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPaidNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.PAID_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPendingCanceledNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.PENDING_CANCELED_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPaymentRefundNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.PAYMENT_REFUND_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultFailedNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.FAILED_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
/* BANKSLIP */
const defaultPendingBankslipNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.PENDING_BANKSLIP_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPaidBankslipNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.PAID_BANKSLIP_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPendingCanceledBankslipNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.PENDING_CANCELED_BANKSLIP_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPaymentRefundBankslipNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.PAYMENT_REFUND_BANKSLIP_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultFailedBankslipNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.FAILED_BANKSLIP_STORE,
  group: NOTIFICATION_GROUP.PAYMENT_STORE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

/* PAYMENT SIGNATURE */
const defaultPendingNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.PENDING_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPaidNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.PAID_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPendingCanceledNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.PENDING_CANCELED_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPaymentRefundNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.PAYMENT_REFUND_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultFailedNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.FAILED_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.CARD,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
/* BANKSLIP */
const defaultPendingBankslipNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.PENDING_BANKSLIP_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPaidBankslipNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.PAID_BANKSLIP_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPendingCanceledBankslipNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.PENDING_CANCELED_BANKSLIP_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};
const defaultPaymentRefundBankslipNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.PAYMENT_REFUND_BANKSLIP_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultFailedBankslipNotificationSignature: StoreNotification = {
  type: NOTIFICATION_TYPE.FAILED_BANKSLIP_SIGNATURE,
  group: NOTIFICATION_GROUP.PAYMENT_SIGNATURE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultMonthlySignatureBankslipNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.MONTHLY_SIGNATURE_BANKSLIP,
  group: NOTIFICATION_GROUP.SCHEDULE,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

const defaultSendSignatureBankslipNotification: StoreNotification = {
  type: NOTIFICATION_TYPE.SEND_SIGNATURE_BANKSLIP,
  group: NOTIFICATION_GROUP.BULK_ACTION,
  paymentType: PAYMENT_TYPE.BANKSLIP,
  permissions: {
    sendEmail: true,
    sendSms: true,
    sendPopup: false,
  },
  emailMessage: '',
  smsMessage: '',
  popupMessage: '',
};

export const defaultNotificationsLogistic: StoreNotification[] = [
  defaultInDivisionNotification,
  defaultInShippingNotification,
  defaultDeliveryNotification,
  defaultDeliveryFailureNotification,
  defaultDeliveredNotification,
  defaultWaitingRemoveNotification,
];

export const defaultNotificationsPaymentStore: StoreNotification[] = [
  //PAYMENT STORE
  defaultPendingNotification,
  defaultPaidNotification,
  defaultPendingCanceledNotification,
  defaultPaymentRefundNotification,
  defaultFailedNotification,
  defaultPendingBankslipNotification,
  defaultPaidBankslipNotification,
  defaultPendingCanceledBankslipNotification,
  defaultPaymentRefundBankslipNotification,
  defaultFailedBankslipNotification,
];

export const defaultNotificationsPaymentSignature: StoreNotification[] = [
  //PAYMENT SIGNATURE
  defaultPendingNotificationSignature,
  defaultPaidNotificationSignature,
  defaultPendingCanceledNotificationSignature,
  defaultPaymentRefundNotificationSignature,
  defaultFailedNotificationSignature,
  defaultPendingBankslipNotificationSignature,
  defaultPaidBankslipNotificationSignature,
  defaultPendingCanceledBankslipNotificationSignature,
  defaultPaymentRefundBankslipNotificationSignature,
  defaultFailedBankslipNotificationSignature,
];

export const defaultNotificationsSchedule: StoreNotification[] = [
  defaultMonthlySignatureBankslipNotification,
];

export const defaultNotificationsBulkAction: StoreNotification[] = [
  defaultSendSignatureBankslipNotification,
];
