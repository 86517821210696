import React, { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { initial, last } from 'lodash';
import {
  Grid,
  Icon,
  Paper,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import { AppState } from 'store';
import { loadPreviousBoxes } from 'store/ducks/client/home/actions';
import { BoxHome, HomeState } from 'store/ducks/client/home/types';
import { useStyles } from './styles';
import BoxCardData from '../BoxCardData';
import { NO_PREVIOUS_ORDERS_BOXES } from 'utils/messages';
import { UserState } from 'store/ducks/user/types';

const PreviousBox: FC = () => {
  const theme = useTheme();
  const history = useHistory();
  const [boxes, setBoxes] = useState<BoxHome[]>();
  const [allboxes, setAllBoxes] = useState<BoxHome[]>();
  const [extraBox, setExtraBox] = useState<BoxHome>();
  const [extraBoxTwo, setExtraBoxTwo] = useState<BoxHome>();
  const smallMobile = useMediaQuery(theme.breakpoints.down(401));
  const mdSize = useMediaQuery(theme.breakpoints.up(800));
  const wideSize = useMediaQuery(theme.breakpoints.up(1550));
  const dispatch = useDispatch();
  const classes = useStyles();

  const { previousBoxes, isLoading } = useSelector<AppState, HomeState>(
    ({ home }: AppState) => home,
  );

  const { data: user } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const loadLastBoxesOrders = useCallback(() => {
    if (user) {
      dispatch(loadPreviousBoxes(Number(user.currentSubscription)));
    }
  }, [dispatch, user]);

  useEffect(() => {
    loadLastBoxesOrders();
  }, [loadLastBoxesOrders]);

  useEffect(() => {
    if (previousBoxes && previousBoxes.length > 3)
      setExtraBoxTwo(last(previousBoxes));
    setBoxes(initial(previousBoxes));
  }, [previousBoxes]);

  useEffect(() => {
    if (boxes && boxes.length === 3) setExtraBox(last(boxes));
    setAllBoxes(initial(boxes));
  }, [boxes]);

  const redirectToStore = () => history.push('/store');

  return (
    <>
      <Grid container justify="space-between" direction="row">
        <Typography variant="h1" className={classes.cardTitle}>
          Box anteriores
        </Typography>
        {user?.isPremium ? (
          <Typography
            variant="subtitle1"
            className={classes.actionLabel}
            onClick={() => redirectToStore()}
          >
            Ver na loja
            <Icon className={classes.iconAligned}>
              <ArrowForwardIcon />
            </Icon>
          </Typography>
        ) : null}
      </Grid>
      <Paper className={classes.paperTable} elevation={0}>
        {!isLoading ? (
          <Grid
            container
            justify="space-around"
            wrap="nowrap"
            direction={smallMobile ? 'column' : 'row'}
          >
            {allboxes?.length ? (
              allboxes.map(box => (
                <Grid item xs key={box.productId} className={classes.boxItem}>
                  <BoxCardData
                    key={box.productId}
                    boxTitle={box.name}
                    imgPath={box.image}
                    deliveryDate={box.boxDate}
                    contracted
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs className={classes.boxItem}>
                <Typography variant="h3" className={classes.disabledText}>
                  {NO_PREVIOUS_ORDERS_BOXES}
                </Typography>
              </Grid>
            )}
            {mdSize && extraBox ? (
              <Grid item xs className={classes.boxItem}>
                <BoxCardData
                  key={extraBox.productId}
                  boxTitle={extraBox.name}
                  imgPath={extraBox.image}
                  deliveryDate={extraBox.boxDate}
                  contracted
                  owned={extraBox.owned}
                />
              </Grid>
            ) : null}
            {wideSize && extraBoxTwo ? (
              <Grid item xs className={classes.boxItem}>
                <BoxCardData
                  key={extraBoxTwo.productId}
                  boxTitle={extraBoxTwo.name}
                  imgPath={extraBoxTwo.image}
                  deliveryDate={extraBoxTwo.boxDate}
                  contracted
                  owned={extraBoxTwo.owned}
                />
              </Grid>
            ) : null}
          </Grid>
        ) : null}
      </Paper>
    </>
  );
};

export default PreviousBox;
