import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import ReactInputMask from 'react-input-mask';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { StatusCodes } from 'http-status-codes';

import MyOutlinedTextField from 'components/generals/input/MyOutlinedTextField';
import { CouponProps } from '../dialogTypes';
import useStyles from 'components/generals/forms/register/styles';
import MyNumberFormat from 'components/generals/input/MyNumberFormat';

import { closeModal } from 'store/ducks/nav/actions';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { loadCouponRequest } from 'store/ducks/coupons/actions';

import CouponsService from 'services/couponsService';

import { validationSchema } from './utils';
import {
  NEW_COUPON_ERROR,
  NEW_COUPON_SUCCESS,
  NOT_FORBIDEN_VALUE_AND_PERCENT,
} from 'utils/messages';

import MASK from 'helpers/masks';
import { separateDate } from 'helpers/validateDate';

import { COUPON_LIMIT, COUPON_TYPE } from './types';
import { Coupon } from 'types/generals/coupon';

const CouponsDialog: FC<CouponProps> = ({ title }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { handleSubmit, errors, setError, control, register, reset } = useForm({
    validationSchema,
  });

  const onSubmit = async (data: Partial<Coupon>) => {
    try {
      const coupon = {
        ...data,
        start_date: data.start_date && separateDate(data.start_date.toString()),
        end_date: data.end_date && separateDate(data.end_date.toString()),
        percent: Number(data.percent) || 0,
        value: Number(data.value) || 0,
        limit: Number(data.limit) || 0,
        isPercent: (data.percent && !data.value) || false,
      };

      if (data.value && data.percent) {
        setError('value', 'Error');
        setError('percent', 'Error');
        throw NOT_FORBIDEN_VALUE_AND_PERCENT;
      }

      const response = await CouponsService.createCoupon(coupon);

      if (response.status === StatusCodes.CREATED) {
        reset();
        dispatch(notifySuccess(NEW_COUPON_SUCCESS));
        dispatch(
          loadCouponRequest({
            page: 0,
            limit: 10,
          }),
        );
        dispatch(closeModal());
      } else {
        throw NEW_COUPON_ERROR;
      }
    } catch (err) {
      dispatch(notifyError(err));
    }
  };

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between" alignItems="center">
          <Typography variant="h1" color="textPrimary">
            {title}
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={2}
          component="form"
          style={{ overflow: 'hidden' }}
        >
          <Grid item xs>
            <MyOutlinedTextField
              id="coupon-name"
              name="name"
              label="Nome do cupom"
              fullWidth
              error={Boolean(errors.name)}
              helperText={errors.name ? errors.name.message : null}
              inputRef={register}
            />
          </Grid>
          <Grid
            container
            justify="space-between"
            direction="row"
            spacing={3}
            item
            alignItems="center"
          >
            <Grid item xs>
              <Controller
                name="value"
                control={control}
                as={({ value, onChange }) => (
                  <MyOutlinedTextField
                    id="value"
                    name="value"
                    label="Valor fixo"
                    placeholder="R$"
                    value={value}
                    onChange={onChange}
                    InputProps={{
                      inputComponent: MyNumberFormat as any,
                    }}
                    fullWidth
                    error={Boolean(errors.value)}
                    helperText={errors.value ? errors.value.message : null}
                  />
                )}
              />
            </Grid>
            <Typography variant="h3" color="textPrimary">
              ou
            </Typography>
            <Grid item xs>
              <Controller
                control={control}
                name="percent"
                as={({ value, onChange }) => (
                  <MyOutlinedTextField
                    id="percent"
                    label="Valor %"
                    name="percent"
                    value={value || ''}
                    onChange={onChange}
                    inputProps={{ inputMode: 'numeric' }}
                    fullWidth
                    error={Boolean(errors.percent)}
                    helperText={errors.percent ? errors.percent.message : null}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Grid
            container
            justify="space-between"
            spacing={3}
            item
            direction="row"
            alignItems="center"
          >
            <Grid item xs>
              <Controller
                as={ReactInputMask}
                mask={MASK.DATE}
                name="start_date"
                control={control}
                maskChar={null}
                onChange={([event]: any) => event.target.value}
              >
                {() => (
                  <MyOutlinedTextField
                    fullWidth
                    label="Data de início"
                    placeholder="dd/mm/yyyy"
                    name="start_date"
                    type="text"
                    inputRef={register}
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    error={Boolean(errors.start_date)}
                    helperText={
                      errors.start_date ? errors.start_date.message : null
                    }
                  />
                )}
              </Controller>
            </Grid>
            <Grid item xs>
              <Controller
                as={ReactInputMask}
                mask={MASK.DATE}
                name="end_date"
                control={control}
                maskChar={null}
                onChange={([event]: any) => event.target.value}
              >
                {() => (
                  <MyOutlinedTextField
                    fullWidth
                    label="Data de término"
                    name="end_date"
                    placeholder="dd/mm/yyyy"
                    type="text"
                    inputRef={register}
                    inputProps={{
                      inputMode: 'numeric',
                    }}
                    error={Boolean(errors.end_date)}
                    helperText={
                      errors.end_date ? errors.end_date.message : null
                    }
                  />
                )}
              </Controller>
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            item
            justify="space-between"
            direction="row"
            alignItems="center"
          >
            <Grid item xs>
              <Typography
                variant="h4"
                color="textPrimary"
                style={{ marginBottom: 10, fontWeight: 'bold' }}
              >
                Limite de uso
              </Typography>
              <Controller
                name="couponLimit"
                control={control}
                defaultValue={COUPON_LIMIT.Unlimited}
                as={({ value, onChange }) => (
                  <RadioGroup
                    row
                    aria-label="coupon-limit"
                    value={value || ''}
                    onChange={event => {
                      onChange(event.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={COUPON_LIMIT.Unlimited}
                      control={<Radio />}
                      label={COUPON_LIMIT.Unlimited}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={COUPON_LIMIT.Limited}
                      control={<Radio />}
                      label={COUPON_LIMIT.Limited}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            <Grid item xs>
              <Typography
                variant="h4"
                color="textPrimary"
                style={{ marginBottom: 10, fontWeight: 'bold' }}
              >
                Tipo
              </Typography>
              <Controller
                name="couponType"
                control={control}
                defaultValue={COUPON_TYPE.Store}
                as={({ value, onChange }) => (
                  <RadioGroup
                    row
                    aria-label="coupon-type"
                    value={value || ''}
                    onChange={event => {
                      onChange(event.target.value);
                    }}
                  >
                    <FormControlLabel
                      value={COUPON_TYPE.Store}
                      control={<Radio />}
                      label={COUPON_TYPE.Store}
                      labelPlacement="end"
                    />
                    <FormControlLabel
                      value={COUPON_TYPE.Signature}
                      control={<Radio />}
                      label={COUPON_TYPE.Signature}
                      labelPlacement="end"
                    />
                  </RadioGroup>
                )}
              />
            </Grid>
            <Grid item xs={3}>
              <MyOutlinedTextField
                id="coupon-limit"
                name="limit"
                label="Quantidade"
                fullWidth
                error={Boolean(errors.limit)}
                helperText={errors.limit ? errors.limit.message : null}
                inputRef={register}
              />
            </Grid>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid
          container
          direction="row"
          justify="flex-end"
          alignItems="center"
          item
          spacing={2}
          style={{ marginTop: 20, marginBottom: 20 }}
        >
          <Button
            color="primary"
            className={styles.buttonLabel}
            style={{ marginRight: 30 }}
            onClick={() => dispatch(closeModal())}
          >
            Cancelar
          </Button>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className={`${styles.buttonLabel} ${styles.buttonWrapper}`}
            onClick={handleSubmit(onSubmit)}
          >
            Confirmar
          </Button>
        </Grid>
      </DialogActions>
    </Grid>
  );
};

export default CouponsDialog;
