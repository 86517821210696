import React, { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  Button,
  Card,
  CardContent,
  CardMedia,
  Grid,
  Typography,
} from '@material-ui/core';

import { loadCurrentBox } from 'store/ducks/client/home/actions';
import { useStyles } from './styles';
import { CardEnhancedProductToggleProps } from './types';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types';
import { useSignature } from 'contexts/signatureContext';
import clsx from 'clsx';

const CardEnhancedProductToggle: FC<CardEnhancedProductToggleProps> = ({
  enhancedProduct,
  removeProduct,
  addProduct,
  btnText,
  isAdded,
  addedText = 'Remover',
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const { showFinishStep, planStepsDisabled } = useSignature();

  const loadMonthBox = useCallback(() => {
    dispatch(loadCurrentBox());
  }, [dispatch]);

  const handlePrice = useCallback((price: number) => {
    return formatColumnValue(FormatOptions.MONEY, price);
  }, []);

  useEffect(() => {
    loadMonthBox();
  }, [loadMonthBox]);

  const verifyStepAccess = () => {
    return !showFinishStep && planStepsDisabled;
  };

  return (
    <Card
      elevation={0}
      className={
        verifyStepAccess() ? styles.stepWrapperDisabled : styles.stepWrapper
      }
    >
      <Grid
        container
        justify="space-between"
        direction="column"
        className={
          verifyStepAccess() ? styles.stepWrapperDisabled : styles.stepWrapper
        }
      >
        <Grid
          item
          xs
          container
          direction="row"
          alignContent="space-between"
          alignItems="center"
          className={styles.noPadding}
        >
          <Grid item xs={5} className={styles.selfCenter}>
            {enhancedProduct.product?.images ? (
              <CardMedia
                className={styles.cardImg}
                image={enhancedProduct.product?.images[0]?.image}
                component="img"
                alt={`Imagem do box ${enhancedProduct.product.name}`}
              />
            ) : null}
          </Grid>

          <CardContent>
            <Grid
              item
              xs
              container
              direction="column"
              alignContent="space-between"
            >
              <Grid item xs>
                <Typography
                  variant="h4"
                  color="textPrimary"
                  className={clsx(
                    styles.enhancedProductInfo,
                    verifyStepAccess() ? styles.disabledColor : '',
                  )}
                >
                  Kit extra
                </Typography>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  className={clsx(
                    styles.boldText,
                    verifyStepAccess() ? styles.disabledColor : '',
                  )}
                >
                  {enhancedProduct.product.name}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography
                  variant="h3"
                  color="textPrimary"
                  className={clsx(
                    styles.pricedText,
                    verifyStepAccess() ? styles.disabledColor : '',
                  )}
                >
                  {handlePrice(enhancedProduct.price)}
                </Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Grid>

        {!verifyStepAccess() ? (
          <Grid item xs={12}>
            {isAdded ? (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                className={styles.actiontButtonToggled}
                onClick={() => removeProduct(enhancedProduct)}
                disabled={verifyStepAccess()}
              >
                {addedText}
              </Button>
            ) : (
              <Button
                fullWidth
                type="submit"
                variant="contained"
                onClick={() => addProduct(enhancedProduct)}
                className={styles.actiontButton}
                disabled={verifyStepAccess()}
              >
                {btnText}
              </Button>
            )}
          </Grid>
        ) : null}

        <Grid container item xs justify="space-between">
          <Grid
            item
            xs={12}
            className={clsx(
              styles.enhancedProductInfo,
              verifyStepAccess() ? styles.disabledColor : '',
            )}
          >
            *{enhancedProduct.description}
          </Grid>
        </Grid>
      </Grid>
    </Card>
  );
};

export default CardEnhancedProductToggle;
