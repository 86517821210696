import React, { FC } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import MyTooltip from 'components/generals/tolltip';
import MyTypography from 'components/generals/labels/MyTypography';
import { useStyles } from '../styles';
import { OrderDetailsProps } from '../types';
import { orderBy } from 'lodash';
import { formatColumnValue } from 'helpers/formatters';
import { FormatOptions } from 'types/TableTypes';
import { PAYMENT_TYPE } from 'types/generals';

const UserOrderPayments: FC<OrderDetailsProps> = ({ order }) => {
  const styles = useStyles();
  const ORDER_PAYMENT_TYPE = order.orderDetail.paymentType;
  const PAYMENTS = orderBy(order.payments, ['id'], ['desc']);

  const handleFormatDateTime = (date: string) =>
    formatColumnValue(FormatOptions.DATETIME, date);

  return (
    <Grid>
      <MyTypography variant="h3" className={styles.infoTitle}>
        Status pagamento
      </MyTypography>

      <Paper variant="outlined" className={styles.cardItem}>
        <TableContainer>
          <Table className={styles.table} aria-label="order items table">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Descrição</TableCell>
                {ORDER_PAYMENT_TYPE === PAYMENT_TYPE.CARD && (
                  <TableCell>Cartão</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {PAYMENTS.map(payment => (
                <TableRow key={payment.id}>
                  <TableCell>
                    {handleFormatDateTime(payment.createdAt)}
                  </TableCell>
                  <TableCell>{payment.status}</TableCell>
                  <MyTooltip
                    title={payment.description || ''}
                    enterDelay={500}
                    placement="top"
                  >
                    <TableCell>{payment.description}</TableCell>
                  </MyTooltip>
                  {ORDER_PAYMENT_TYPE === PAYMENT_TYPE.CARD && (
                    <TableCell>
                      {payment.cardTransaction?.cardNumber || '-'}
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default UserOrderPayments;
