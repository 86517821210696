import React, { FC, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';
import { Grid } from '@material-ui/core';

import OpenEndpointsService from 'services/openEndpointsService';
import MASK, { UNMASK } from 'helpers/masks';
import { InputRef } from 'types/FormTypes';

import { TextInput } from '../styles';
import { AddressColumnProps } from './types';

const AddressColumn: FC<AddressColumnProps> = ({
  control,
  reset,
  errors,
  register,
}) => {
  const [loading, setLoading] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const zipcodeInput = useRef() as InputRef;
  const streetInput = useRef() as InputRef;
  const numberInput = useRef() as InputRef;
  const neighborhoodInput = useRef() as InputRef;
  const cityInput = useRef() as InputRef;
  const stateInput = useRef() as InputRef;
  const complementInput = useRef() as InputRef;
  const fixedTransportInput = useRef() as InputRef;

  const lookupAddress = async (zipcode: string) => {
    if (zipcode.length === 8) {
      setLoading(true);
      setDisabled(true);
      try {
        const data = await OpenEndpointsService.userLookupAddress(zipcode);
        reset({
          'address.neighborhood': data.bairro,
          'address.complement': data.complemento2 || '',
          'address.city': data.cidade,
          'address.street': data.end,
          'address.state': data.uf,
        });
      } catch (error) {
        reset({
          'address.neighborhood': '',
          'address.complement': '',
          'address.city': '',
          'address.street': '',
          'address.state': '',
        });
      } finally {
        setLoading(false);
        setDisabled(false);
      }
      return zipcode;
    }
  };

  const handleZipcodeChange = ([event]: any) => {
    const unmasked = UNMASK.onlyDigits(event.target.value);
    lookupAddress(unmasked);
    return unmasked;
  };

  return (
    <Grid container item xs={6} spacing={1}>
      <Grid item xs={12}>
        <Controller
          as={InputMask}
          mask={MASK.ZIPCODE}
          name="address.zipcode"
          control={control}
          maskChar={null}
          onChange={handleZipcodeChange}
        >
          {() => (
            <TextInput
              fullWidth
              label="CEP"
              type="text"
              name="address.zipcode"
              inputProps={{
                ref: zipcodeInput,
                inputMode: 'numeric',
              }}
              changeFocusOnEnter={() => streetInput.current.focus()}
              isLoading={loading}
              isDisabled={disabled}
              error={Boolean(errors.zipcode)}
              helperText={errors.zipcode ? errors.zipcode.message : null}
              inputRef={register}
            />
          )}
        </Controller>
      </Grid>
      <Grid item xs={12}>
        <Controller
          name="address.street"
          control={control}
          as={({ value, onChange }) => (
            <TextInput
              fullWidth
              label="Endereço"
              name="address.street"
              type="text"
              inputProps={{ ref: streetInput }}
              changeFocusOnEnter={() => numberInput.current.focus()}
              onChange={onChange}
              isLoading={loading}
              isDisabled={disabled}
              error={Boolean(errors.street)}
              helperText={errors.street ? errors.street.message : null}
              value={value || ''}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextInput
          fullWidth
          label="Número"
          id="number"
          name="address.number"
          type="text"
          inputProps={{ ref: numberInput }}
          changeFocusOnEnter={() => neighborhoodInput.current.focus()}
          error={Boolean(errors.number)}
          helperText={errors.number ? errors.number.message : null}
          inputRef={register}
          isDisabled={disabled}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="address.neighborhood"
          control={control}
          as={({ value, onChange }) => (
            <TextInput
              fullWidth
              label="Bairro"
              name="address.neighborhood"
              type="text"
              inputProps={{ ref: neighborhoodInput }}
              changeFocusOnEnter={() => cityInput.current.focus()}
              onChange={onChange}
              isLoading={loading}
              isDisabled={disabled}
              error={Boolean(errors.neighborhood)}
              helperText={
                errors.neighborhood ? errors.neighborhood.message : null
              }
              value={value}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="address.city"
          control={control}
          as={({ value, onChange }) => (
            <TextInput
              fullWidth
              name="address.city"
              label="Cidade"
              type="text"
              inputProps={{ ref: cityInput }}
              changeFocusOnEnter={() => stateInput.current.focus()}
              onChange={onChange}
              isLoading={loading}
              isDisabled={disabled}
              error={Boolean(errors.city)}
              helperText={errors.city ? errors.city.message : null}
              value={value || ''}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="address.state"
          control={control}
          as={({ value, onChange }) => (
            <TextInput
              fullWidth
              name="address.state"
              label="Estado"
              type="text"
              inputProps={{ ref: stateInput }}
              changeFocusOnEnter={() => complementInput.current.focus()}
              onChange={onChange}
              isLoading={loading}
              isDisabled={disabled}
              error={Boolean(errors.state)}
              helperText={errors.state ? errors.state.message : null}
              value={value || ''}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="address.complement"
          control={control}
          as={({ value, onChange }) => (
            <TextInput
              name="address.complement"
              fullWidth
              label="Complemento"
              type="text"
              inputProps={{ ref: complementInput }}
              onChange={onChange}
              isLoading={loading}
              isDisabled={disabled}
              value={value || ''}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <Controller
          name="fixedTransport"
          control={control}
          as={({ value, onChange }) => (
            <TextInput
              name="fixedTransport"
              fullWidth
              label="Transporte Fixo"
              type="text"
              inputProps={{ ref: fixedTransportInput }}
              onChange={onChange}
              isLoading={loading}
              isDisabled={disabled}
              value={value || ''}
            />
          )}
        />
      </Grid>
    </Grid>
  );
};

export default AddressColumn;
