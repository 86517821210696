import { NotificationState, NotificationTypes } from './types';
import { Reducer } from 'redux';

const INIITIAL_STATE: NotificationState = {
  notifications: [],
  isNotified: false,
  buttonIsDisabled: false,
  bankSlipIsProcessing: false,
};

const reducer: Reducer<NotificationState> = (
  state = INIITIAL_STATE,
  action,
) => {
  switch (action.type) {
    case NotificationTypes.ENQUEUE:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };
    case NotificationTypes.REMOVE:
      return {
        ...state,
        notifications: state.notifications.filter(
          n => n.key !== action.payload.key,
        ),
      };

    case NotificationTypes.IS_NOTIFIED:
      return {
        ...state,
        isNotified: action.payload.isNotified,
      };

    case NotificationTypes.DISABLED_BUTTON:
      return {
        ...state,
        buttonIsDisabled: true,
      };

    case NotificationTypes.ENABLE_BUTTON:
      return {
        ...state,
        buttonIsDisabled: false,
      };

    case NotificationTypes.BANKSLIP_IS_PROCESSING:
      return {
        ...state,
        bankSlipIsProcessing: true,
      };

    case NotificationTypes.BANKSLIP_IS_NOT_PROCESSING:
      return {
        ...state,
        bankSlipIsProcessing: false,
      };

    default:
      return state;
  }
};

export default reducer;
