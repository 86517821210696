import React, { FC, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams, Redirect } from 'react-router-dom';
import {
  Button,
  Grid,
  Paper,
  Typography,
  useMediaQuery,
  Tabs,
  Tab,
  Box,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { head, sortBy } from 'lodash';

import MyImageGallery from 'components/generals/imageGallery';
import { IImageGalleryItem } from 'components/generals/imageGallery/types';
import CustomTag from 'components/generals/tag/CustomTag';
import NoAccessStoreModalInfo from 'components/generals/dialog/NoAccessStoreModalInfo';

import { AppState } from 'store';
import { CartState } from 'store/ducks/cart/types';
import { verifyCartRules } from 'store/ducks/cart/utils';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { OrderOneState } from 'store/ducks/order/types';
import { notifyError } from 'store/ducks/notification/actions';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';
import { openModal } from 'store/ducks/nav/actions';

import { formatColumnValue } from 'helpers/formatters';
import { addCartProduct, handleChangeCartOpen } from 'store/ducks/cart/actions';
import { useStyles } from './styles';
import { FormatOptions } from 'types';
import theme from 'styles/theme';
import { ProductTag, ProductImage } from 'types/generals';
import { getPropsToOpenModalNoAccessStore } from 'utils/functions/generals';
import { ParamsProps } from 'types/generals/routes';
import { StoreProductState } from 'store/ducks/store/types';
import MyCircularLoading from 'components/generals/loading/CircularLoading';
import {
  loadOneStoreProductRequest,
  resetStateOneStoreProductAfterFailed,
} from 'store/ducks/store/oneProduct/actions';

const ProductPage: FC = () => {
  const productId = useParams<ParamsProps>().id;
  const [tag, setTag] = useState();
  const [productImagesSorted, setProductImagesSorted] = useState<
    ProductImage[]
  >([]);
  const { data: product, isLoading, hasError } = useSelector<
    AppState,
    StoreProductState
  >(({ storeProductReducer }: AppState) => storeProductReducer);

  const { data: signature } = useSelector<AppState, SingleSignatureState>(
    ({ oneSignature }: AppState) => oneSignature,
  );

  const { data: order } = useSelector<AppState, OrderOneState>(
    ({ orderReducer }: AppState) => orderReducer,
  );

  const { hasPromotion, activeSettings } = useSelector<
    AppState,
    StoreSettingsState
  >(({ storeSettings }: AppState) => storeSettings);

  const imagesGallery: IImageGalleryItem[] = productImagesSorted.map(
    ({ image }) => ({
      original: image,
      thumbnail: image,
    }),
  );

  const styles = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const smallSize = useMediaQuery(theme.breakpoints.only('xs'));

  const { cartLength, cartProducts } = useSelector<AppState, CartState>(
    ({ cartReducer }: AppState) => cartReducer,
  );

  const handleFormatMoney = useCallback((amount: string) => {
    return formatColumnValue(FormatOptions.MONEY, amount);
  }, []);

  const verifyIfHaveAccesOnStore = () => {
    if (hasPromotion || signature.user.isPremium) {
      addProductToCart();
    } else {
      const modalProps = getPropsToOpenModalNoAccessStore(signature, order);
      dispatch(openModal(NoAccessStoreModalInfo, modalProps));
    }
  };

  const addProductToCart = () => {
    try {
      if (verifyCartRules(cartLength, cartProducts, product)) {
        dispatch(
          addCartProduct(
            { ...product, amount: 1 },
            {
              ...activeSettings,
              isPremium: signature.user.isPremium,
            },
          ),
        );
        dispatch(handleChangeCartOpen());
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const redirectStore = () => {
    history.push('/store');
  };

  const loadOneStoreProduct = useCallback(() => {
    dispatch(
      loadOneStoreProductRequest(productId, {
        join: [['images']],
      }),
    );
  }, [dispatch, productId]);

  useEffect(() => {
    loadOneStoreProduct();
  }, [loadOneStoreProduct]);

  useEffect(() => {
    if (!isLoading) {
      setProductImagesSorted(
        sortBy(product.images, [({ fileName }) => fileName]),
      );

      setTag(head<ProductTag>(product.productTag));
    }
  }, [isLoading, product]);

  const ProductData = () => (
    <Grid
      container
      item
      justify="space-between"
      direction="column"
      alignItems="center"
    >
      <Grid
        container
        justify="flex-start"
        spacing={2}
        direction="column"
        style={{ marginBottom: '41px' }}
      >
        {tag?.tagName ? (
          <Grid item xs>
            <CustomTag
              text={tag.tagName}
              background={tag?.tagColor}
              color={tag?.textColor}
            />
          </Grid>
        ) : null}
        <Grid item xs>
          <Typography variant="h1" className={styles.productTitle}>
            {product.name}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        justify="space-between"
        direction="row"
        alignItems="center"
      >
        <Grid
          item
          xs={12}
          md={6}
          className={smallSize ? styles.priceContainer : ''}
        >
          {Number(product.discount) > 0 ? (
            <>
              <Grid container item xs>
                <Typography variant="h4" className={styles.discountOriginal}>
                  DE: {handleFormatMoney(product.price)}
                </Typography>
              </Grid>
              <Grid container item xs style={{ marginTop: 6 }}>
                <Typography variant="h2" color="textPrimary">
                  POR:
                </Typography>
                <Typography
                  variant="h2"
                  color="textPrimary"
                  className={styles.price}
                >
                  {handleFormatMoney(
                    (
                      Number(product.price) - Number(product.discount)
                    ).toString(),
                  )}
                </Typography>
              </Grid>
            </>
          ) : (
            <Grid container item xs style={{ marginTop: 6 }}>
              <Typography
                variant="h2"
                color="textPrimary"
                className={styles.semiBoldText}
              >
                {handleFormatMoney(product.price.toString())}
              </Typography>
            </Grid>
          )}
          <Grid item xs>
            <Button
              fullWidth
              className={
                product.stock > 0 ? styles.confirmButton : styles.disabledButton
              }
              onClick={() => verifyIfHaveAccesOnStore()}
              disabled={product.stock < 1}
            >
              Comprar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );

  const ProductInformations = () => {
    const [tab, setTab] = useState(0);

    const handleChangeTab = (event: any, value: number) => {
      setTab(value);
    };

    const TabPanel = (props: any) => (
      <div
        role="tabpanel"
        hidden={props.value !== props.index}
        id={`scrollable-auto-tabpanel-${props.index}`}
        aria-labelledby={`scrollable-auto-tabpanel-${props.index}`}
        {...props.other}
      >
        {props.value === props.index && (
          <Box p={2}>
            <Typography>{props.children}</Typography>
          </Box>
        )}
      </div>
    );

    function a11yProps(index: number) {
      return {
        id: `scrollable-auto-tab-${index}`,
        'aria-controls': `scrollable-auto-tabpanel-${index}`,
      };
    }

    return (
      <Grid
        container
        item
        xs
        style={{
          minHeight: '323px',
          border: '1px solid #F1F1F1',
          borderRadius: 5,
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Grid justify="space-around" style={{ width: 'inherit' }}>
          <Tabs
            value={tab}
            onChange={handleChangeTab}
            indicatorColor="primary"
            textColor="primary"
            variant="scrollable"
            scrollButtons="auto"
            aria-label="full width tabs example"
          >
            <Tab label="Sobre" {...a11yProps(0)} />
            <Tab label="Itens" {...a11yProps(1)} />
            <Tab label="Especificações" {...a11yProps(2)} />
          </Tabs>
          <TabPanel
            value={tab}
            className={styles.tabPanel}
            index={0}
            dir={theme.direction}
          >
            <Typography className={styles.tabPanel}>{product.about}</Typography>
          </TabPanel>
          <TabPanel
            value={tab}
            className={styles.tabPanel}
            index={1}
            dir={theme.direction}
          >
            <Typography className={styles.tabPanel}>{product.items}</Typography>
          </TabPanel>
          <TabPanel
            value={tab}
            className={styles.tabPanel}
            index={2}
            dir={theme.direction}
          >
            <Typography className={styles.tabPanel}>
              {product.specifications}
            </Typography>
          </TabPanel>
        </Grid>
      </Grid>
    );
  };

  const ProductPaper = () => (
    <Paper className={styles.paperTable} elevation={0}>
      <Grid
        container
        item
        justify="space-between"
        direction="row"
        alignItems="center"
        spacing={6}
      >
        <Grid
          container
          xs={12}
          md={6}
          direction="column"
          alignItems="center"
          justify="space-between"
          className={styles.galleryContainer}
        >
          <Grid style={{ width: 'inherit' }}>
            <MyImageGallery images={imagesGallery} />
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={styles.productDataContainer}>
          <ProductData />
          <ProductInformations />
        </Grid>
      </Grid>
    </Paper>
  );

  const RedirectToStore = () => {
    dispatch(resetStateOneStoreProductAfterFailed());
    return <Redirect to="/store" />;
  };

  return (
    <>
      {isLoading ? (
        <MyCircularLoading title="Carregando produto" isLoading={isLoading} />
      ) : !product || hasError ? (
        <RedirectToStore />
      ) : (
        <Grid container item direction="column">
          <Grid container item justify="space-between">
            <Grid
              container
              item
              xs
              justify="flex-end"
              direction="row"
              alignItems="center"
            >
              <Grid
                container
                item
                xs={3}
                justify="flex-end"
                alignItems="center"
                style={{ flexFlow: 'nowrap', lineHeight: 'initial' }}
              >
                <Grid
                  item
                  xs
                  onClick={() => redirectStore()}
                  style={{
                    cursor: 'pointer',
                    display: 'inherit',
                    textAlign: 'end',
                  }}
                  justify="flex-end"
                  alignItems="center"
                >
                  <Grid item>
                    <ArrowBackIosIcon
                      className={styles.arrowIcon}
                      color="primary"
                    />
                  </Grid>
                  <Grid item>
                    <Typography variant="h3" className={styles.headerLink}>
                      Voltar
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} style={{ marginTop: '14px' }}>
                <ProductPaper />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default ProductPage;
