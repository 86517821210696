import { UserScoreData } from 'types/generals/rankingSeason';

export const defaultUserData: UserScoreData = {
  referralCode: '',
  wallet: {
    score: 0,
  },
  totalScore: 0,
};

export const NO_RANKING_SEASON_MESSAGE = `No momento não temos nenhuma campanha de indicação ativa. Volte aqui mais tarde`;
export const NO_REFERRAL_MESSAGE = `Você ainda não realizou indicações para a MBC`;
export const NO_REFERRAL_SUB_MESSAGE = `Comece agora mesmo usando o link abaixo e acumule pontos, troque por prêmios e acesse outros recursos.`;
export const USE_REFERRAL_LINK = 'Use seu link de indicação exclusivo:';
export const OR_SHARE_REFERRAL_LINK = 'Ou compartilhe com';
