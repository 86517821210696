import { call, put } from 'redux-saga/effects';
import InvoiceService from 'services/invoiceService';
import { loadInvoiceSuccess, loadInvoiceFailure } from './actions';

export function* loadInvoicesSaga(action: any) {
  try {
    const { data, total } = yield call(
      InvoiceService.getAll,
      action.payload.query,
    );
    yield put(
      loadInvoiceSuccess({
        data,
        total,
      }),
    );
  } catch (err) {
    yield put(loadInvoiceFailure());
  }
}
