import { PAYMENT_TYPE } from 'types/generals';

export const SIGNATURE_REACTIVATE_CONFIRM = 'Deseja reativar da assinatura?';
export const RENEW_ANTICIPATE_CONFIRM = (signatureUserName: string) =>
  `Deseja antecipar a renovação do assinante ${signatureUserName}?`;
export const SIGNATURE_CANCELED_CONFIRM = 'Deseja cancelar a assinatura?';
export const SIGNATURE_REFUND_ORDER_NOT_FOUND =
  'Não foi possível encontrar o pedido da assinatura para o estorno';
export const SIGNATURE_CREATE_ERROR = 'Não foi possivel concluir o cadastro';
export const SIGNATURE_INACTIVE_MESSAGE_MODAL =
  'Não perca o box deste mês! Ative sua assinatura agora mesmo!';
export const SIGNATURE_INACTIVE_LINK_TITLE =
  'Clique aqui para ativar sua assinatura';

export const CREATE_REMAINING_ORDERS_MESSAGE =
  'Deseja criar os pedidos restantes da assinatura?';
export const CREATE_REMAINING_ORDERS_SUCCESS =
  'Criação dos pedidos restantes efetuada com sucesso.';
export const FORCE_NEXT_SIGNATURE_SUCCESS =
  'Ativação da próxima assinatura efetuada com sucesso.';
export const NEXT_SIGNATURE_NOT_EXISTS =
  'Este usuário não possui uma próxima assinatura.';
export const FORCE_NEXT_SIGNATURE_CONFIRM =
  'Deseja forçar a ativação da próxima assinatura hoje?';
export const FORCE_CANCEL_NEXT_SIGNATURE_FORCED_BY_QUESTION =
  'Assinatura já foi forçada, deseja cancelar a troca mesmo assim?';
export const FORCE_CANCEL_NEXT_SIGNATURE_CONFIRM =
  'Deseja cancelar a troca de assinatura?';
export const FORCE_CANCEL_NEXT_SIGNATURE_SUCCESS =
  'Troca da próxima assinatura cancelada com sucesso.';
export const REQUEST_SIGNATURE_CANCEL_RENOVATION = `Com essa ação você está solicitando o cancelamento da renovação da sua assinatura.
Por favor, adicione o motivo do cancelamento para que possamos entrar em contato por e-mail e finalizá-lo com você!`;
export const NEXT_SIGNATURE_FORCED = (username: string) =>
  `Esta assinatura ja foi forçada por: ${username}.`;
export const RENEW_ANTICIPATE = (signatureId: number) =>
  `Antecipação de assinatura ${signatureId} executada`;
export const SET_SIGNATURE_DEFAULT =
  'Deseja tornar a assinatura como principal?';
export const SET_SIGNATURE_DEFAULT_SUCCESS = 'Assinatura definida como padrão';
export const CHANGE_PAYMENT_TYPE_CONFIRM = (
  oldPaymentMethod: PAYMENT_TYPE,
  newPaymentMethod: PAYMENT_TYPE,
) =>
  `Deseja alterar o método de pagamento do assinante de ${oldPaymentMethod} para ${newPaymentMethod}?`;
export const CHANGE_PAYMENT_TYPE_CLIENT_CONFIRM = (
  oldPaymentMethod: PAYMENT_TYPE,
  newPaymentMethod: PAYMENT_TYPE,
) =>
  `Deseja alterar o método de pagamento de ${oldPaymentMethod} para ${newPaymentMethod}?`;
export const CHANGE_PAYMENT_TYPE_SUCCESS =
  'Método de pagamento alterado com sucesso!';
export const CHANGE_RENEW_DATE_SUCCESS =
  'Data de renovação da assinatura alterada com sucesso.';
export const CHANGE_RENEW_DATE_ERROR =
  'Ocorreu um erro ao alterar data de renovação';
export const CANCEL_SIGNATURE_SUPPORT_MESSAGE = `É uma pena que tenha que sair do Clube. Sentiremos sua falta!
Deseja seguir com o cancelamento de sua assinatura e benefícios?`;
export const CHANGE_PLAN_SIGNATURE_ERROR =
  'Ocorreu um erro ao alterar a assinatura';
export const CHANGE_PLAN_SIGNATURE_SUCCESS =
  'Plano de assinatura alterado com sucesso.';
export const CHANGE_PLAN_SIGNATURE_ASK = 'Deseja alterar a assinatura?';
export const CANCEL_NEXT_SIGNATURE_USER_INFO = (newPlanName: string) =>
  `Ao cancelar a troca de plano você vai estar abrindo mão dos benefícios do(a) ${newPlanName}`;

// Signature observation
export const ADD_OBSERVATION_SIGNATURE_SUCCESS =
  'Observação adicionada com sucesso.';
export const ADD_OBSERVATION_SIGNATURE_ERROR =
  'Houve uma falha ao adicionar observação.';

export const DONATION_RENEW_CONFIRM = 'Deseja renovar a doação?';

export const NEW_DONATION_SUCCESS = 'Doação criada com sucesso';
export const NEW_DONATION_ERROR = 'Houve uma falha ao salvar a doação';
export const RENEW_DONATION_SUCCESS = 'Doação renovada com sucesso';
export const RENEW_DONATION_ERROR = 'Houve uma falha ao renovar a doação';
