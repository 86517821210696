import React, { FC, memo, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CondOperator } from '@nestjsx/crud-request';
import {
  Button,
  Grid,
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from '@material-ui/core';
import { startOfMonth, lastDayOfMonth } from 'date-fns';

import { useStyles } from './styles';
import { RECURRENCES_PLAN } from './utils';
import { ROWS_PER_PAGE_BULK_ACTION } from 'components/generals/table/MyTable';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import MyTableCell from 'components/generals/table/MyTableCell';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import { ViewSubscriberUser } from 'types/generals/view';
import { formatColumnValue } from 'helpers/formatters';
import { PAYMENT_TYPES_FILTER } from 'helpers/planConstants';
import { useFilters } from 'hooks/filters';
import { AppState } from 'store';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { loadViewUsersLastOrderRequest } from 'store/ducks/bulk-actions/view-user-last-order/actions';
import { FormatOptions } from 'types';
import { ViewUserLastOrderState } from 'store/ducks/bulk-actions/view-user-last-order/types';
import {
  SIGNATURE_STATUS,
  ORDER_STATUS,
  BULK_ACTIONS_STATUS,
} from 'types/generals';
import MyDateRangeFilter from 'components/generals/input/MyDateRangeFilter';
import MySelectTableFilter from 'components/generals/input/MySelectTableFilter';
import MyCheckbox from 'components/generals/input/MyCheckbox';
import MySelectFilter from 'components/generals/input/MySelectFilter';
import BAActions from './BAActions';
import { loadAllPlans } from 'store/ducks/generals/plan/actions';
import { PlanState } from 'store/ducks/generals/plan/types';
import { first } from 'lodash';
import CircularLoading from 'components/generals/loading/CircularLoading';

const SubscriptionBulkActions: FC = memo(() => {
  const dispatch = useDispatch();
  const style = useStyles();
  const [orderIds, setOrderIds] = useState<any[]>([]);
  const [selectedAll, setSelectedAll] = useState<boolean>(false);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE_BULK_ACTION[0]);
  const {
    data: subscribers = [],
    total = 0,
    hasError,
    isLoading,
  } = useSelector<AppState, ViewUserLastOrderState>(
    ({ viewUserSignatureLastOrder }: AppState) => viewUserSignatureLastOrder,
  );

  const { data: plans = [] } = useSelector<AppState, PlanState>(
    ({ plans }: AppState) => plans,
  );

  const searchObject = [
    {
      field: 'name',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'email',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'identification',
      operator: CondOperator.CONTAINS_LOW,
    },
    {
      field: 'id',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
    {
      field: 'countPaidMonths',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
    {
      field: 'signatureId',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
    {
      field: 'orderId',
      operator: CondOperator.EQUALS,
      format: (value: any) => Number(value),
    },
  ];

  const isSelected = (value: any) => orderIds.includes(value);

  const handleSelectSingleRow = (id: number) => {
    const findId = orderIds.find((value: any) => value === id);
    findId
      ? setOrderIds(orderIds.filter(value => value !== id))
      : setOrderIds([...orderIds, id]);
  };

  const handleSelectAll = (subscribers: ViewSubscriberUser[]) => {
    const ids = subscribers.map(order => order.orderId);
    setOrderIds(ids);

    if (selectedAll) {
      setOrderIds([]);
    }
    setSelectedAll(!selectedAll);
  };

  const {
    filterFields,
    genericFilterFields,
    sort,
    page,
    pageFilters,
    dateFilter,
    handleGenericSearch,
    handleUpdateFilters,
    handleDateFilter,
    handleSort,
    handleClearFilters,
    handleSetValuePage,
    handleClearPageFilter,
  } = useFilters({
    id: { filterTitle: 'Id usuário', value: undefined },
    bulkActionsStatus: { filterTitle: 'Ultima ação', value: [] },
    signaturePlanId: { filterTitle: 'Plano', value: undefined },
    name: { filterTitle: 'Nome', value: undefined },
    email: { filterTitle: 'E-mail', value: undefined },
    signatureId: { filterTitle: 'Id Assinatura', value: undefined },
    signatureStatus: { filterTitle: 'Status Assinatura', value: [] },
    status: { filterTitle: 'Sub-Status', value: [] },
    signatureRenewDate: {
      filterTitle: 'Renovação Assinatura',
      value: undefined,
    },
    signaturePaymentType: {
      filterTitle: 'Tipo',
      value: [],
    },
    orderPaymentDate: { filterTitle: 'Data do Pedido', value: [null, null] },
    orderNextPaymentAttemptDate: {
      filterTitle: 'Próxima Tentativa',
      value: undefined,
    },
    orderNextPaymentAttemptNumber: {
      filterTitle: 'Tentativa',
      value: undefined,
    },
    orderId: { filterTitle: 'Id do Pedido', value: undefined },
    recurrenceMonths: { filterTitle: 'Recorrência', value: [] },
    orderStatus: { filterTitle: 'Status Do Pedido', value: [] },
    countPaidMonths: { filterTitle: 'Meses Consec.', value: [] },
  });

  const loadAllSignatures = useCallback(() => {
    dispatch(
      loadViewUsersLastOrderRequest({
        sort,
        page: page + 1,
        limit: rowsPerPage,
        search: {
          $and: [...filterFields, { $or: [...genericFilterFields] }],
        },
      }),
    );
  }, [dispatch, page, sort, rowsPerPage, genericFilterFields, filterFields]);

  const loadPlansList = useCallback(() => {
    dispatch(loadAllPlans());
  }, [dispatch]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Ações em massa'));
  }, [dispatch]);

  useEffect(() => {
    loadPlansList();
  }, [loadPlansList]);

  useEffect(() => {
    handleDateFilter(
      [startOfMonth(new Date()), lastDayOfMonth(new Date())],
      '',
      'signatureRenewDate',
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  const handleFilterSearch = () => {
    loadAllSignatures();
  };

  const handleChangeMultiFilter = (value: any, filterName: string) => {
    if (!value.length) {
      handleClearPageFilter(filterName);
    } else {
      handleUpdateFilters(
        filterName,
        {
          filterTitle: value,
          value: value,
        },
        CondOperator.IN,
      );
    }
  };

  const handleChangeBulkActionStatus = (event: any) => {
    const value = event?.target?.value;
    if (first(value) === BULK_ACTIONS_STATUS.noAction) {
      handleUpdateFilters(
        'bulkActionsStatus',
        {
          filterTitle: value,
          value: value,
        },
        CondOperator.IS_NULL,
      );
    } else {
      handleChangeMultiFilter(value, 'bulkActionsStatus');
    }
  };

  const handleChangeSignatureStatus = (event: any) => {
    const value = event?.target?.value;
    handleChangeMultiFilter(value, 'signatureStatus');
  };

  const handleChangeRecurrence = (event: any) => {
    const value = event?.target?.value;
    handleChangeMultiFilter(value, 'recurrenceMonths');
  };

  const handleChangePaymentType = (event: any) => {
    const value = event?.target?.value;
    handleChangeMultiFilter(value, 'signaturePaymentType');
  };

  const handleChangeOrderStatus = (event: any) => {
    const value = event?.target?.value;
    handleChangeMultiFilter(value, 'orderStatus');
  };

  return (
    <Grid container direction="column" className={style.clearFilterContainer}>
      <Grid
        item
        container
        justify="flex-start"
        alignItems="center"
        className={style.clearFilterItem}
        spacing={2}
      >
        <Grid item className={style.searchFilter}>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
          <BAActions subscribers={subscribers} orderIds={orderIds} />
        </Grid>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            className={style.buttonLabel}
            onClick={() => handleFilterSearch()}
          >
            Pesquisar
          </Button>
        </Grid>
        <Grid item>
          <Button className={style.fullWidth} onClick={handleClearFilters}>
            <Typography
              variant="h4"
              color="textPrimary"
              className={style.buttonText}
            >
              Limpar filtros
            </Typography>
          </Button>
        </Grid>
      </Grid>
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        <Grid item xs>
          <MyDateRangeFilter
            placeholder="Data da renovação"
            value={dateFilter}
            onChange={(date: Date[]) =>
              handleDateFilter(date, '', 'signatureRenewDate')
            }
          />
        </Grid>
        <Grid item xs>
          <MySelectTableFilter
            contracted
            itens={Object.values(BULK_ACTIONS_STATUS).map(value => ({
              title: value,
              value,
            }))}
            onChange={handleChangeBulkActionStatus}
            value={pageFilters['bulkActionsStatus']?.value}
            helperText={null}
            title="Ultima ação"
            error={false}
            multiple={true}
          />
        </Grid>
        <Grid item xs>
          <MySelectTableFilter
            contracted
            itens={Object.values(SIGNATURE_STATUS).map(value => ({
              title: value,
              value,
            }))}
            onChange={handleChangeSignatureStatus}
            value={pageFilters['signatureStatus']?.value}
            helperText={null}
            title="Status Assinatura"
            error={false}
            multiple={true}
          />
        </Grid>
        <Grid item xs>
          <MySelectTableFilter
            contracted
            itens={Object.values(ORDER_STATUS).map(value => ({
              title: value,
              value,
            }))}
            onChange={handleChangeOrderStatus}
            value={pageFilters['orderStatus']?.value}
            helperText={null}
            title="Status Do Pedido"
            error={false}
            multiple={true}
          />
        </Grid>

        <Grid item xs>
          <MySelectFilter
            defaultOption={() => handleClearPageFilter('signaturePlanId')}
            menuItems={plans.map(plan => ({
              title: `${plan.id} - ${plan.name}`,
              action: () =>
                handleUpdateFilters(
                  'signaturePlanId',
                  {
                    filterTitle: plan.name,
                    value: plan.id,
                  },
                  CondOperator.EQUALS,
                ),
              className: style.menuOption,
            }))}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              className={style.filterSelect}
            >
              {pageFilters['signaturePlanId']?.filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
        <Grid item xs>
          <MySelectTableFilter
            contracted
            itens={RECURRENCES_PLAN.map(plan => ({
              title: plan.name,
              value: plan.value,
            }))}
            onChange={handleChangeRecurrence}
            value={pageFilters['recurrenceMonths']?.value}
            helperText={null}
            title="Recorrência"
            error={false}
            multiple={true}
          />
        </Grid>
        <Grid item xs>
          <MySelectTableFilter
            contracted
            itens={PAYMENT_TYPES_FILTER.map(payment => ({
              title: payment.title,
              value: payment.value,
            }))}
            onChange={handleChangePaymentType}
            value={pageFilters['signaturePaymentType']?.value}
            helperText={null}
            title="Tipo pagamento"
            error={false}
            multiple={true}
          />
        </Grid>
        <Grid item xs>
          <MySelectFilter
            defaultOption={() =>
              handleClearPageFilter('orderNextPaymentAttemptNumber')
            }
            menuItems={[
              {
                title: 'Segunda tentativa',
                action: () =>
                  handleUpdateFilters(
                    'orderNextPaymentAttemptNumber',
                    {
                      filterTitle: 'Segunda tentativa',
                      value: 2,
                    },
                    CondOperator.EQUALS,
                  ),
                className: style.menuOption,
              },
              {
                title: 'Terceira tentativa',
                action: () =>
                  handleUpdateFilters(
                    'orderNextPaymentAttemptNumber',
                    {
                      filterTitle: 'Terceira tentativa',
                      value: 3,
                    },
                    CondOperator.EQUALS,
                  ),
                className: style.menuOption,
              },
            ]}
          >
            <Typography
              variant="h3"
              color="textPrimary"
              className={style.filterSelect}
            >
              {pageFilters['orderNextPaymentAttemptNumber']?.filterTitle}
            </Typography>
          </MySelectFilter>
        </Grid>
      </Grid>
      <Paper className={style.paperTable} elevation={0}>
        <TableContainer>
          <Table className={style.table} aria-label="subscriptions list">
            <TableHead>
              <TableRow>
                <MyTableCell>
                  <MyCheckbox
                    disabled={false}
                    onClick={() => handleSelectAll(subscribers)}
                    checked={selectedAll}
                  />
                </MyTableCell>
                <MyTableCell>ULTIMA AÇÃO</MyTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('id')}
                  direction={sort.field === 'id' && sort.order}
                >
                  USER ID
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('name')}
                  direction={sort.field === 'name' && sort.order}
                >
                  NOME
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('email')}
                  direction={sort.field === 'email' && sort.order}
                >
                  E-MAIL
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('signatureId')}
                  direction={sort.field === 'signatureId' && sort.order}
                >
                  ASSINATURA ID
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('signatureStatus')}
                  direction={sort.field === 'signatureStatus' && sort.order}
                >
                  STATUS ASSINATURA
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('recurrenceName')}
                  direction={sort.field === 'recurrenceName' && sort.order}
                >
                  RECORRÊNCIA
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('signaturePlanId')}
                  direction={sort.field === 'signaturePlanId' && sort.order}
                >
                  PLANO ID
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('planName')}
                  direction={sort.field === 'planName' && sort.order}
                >
                  PLANO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('signaturePaymentType')}
                  direction={
                    sort.field === 'signaturePaymentType' && sort.order
                  }
                >
                  TIPO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('signatureRenewDate')}
                  direction={sort.field === 'signatureRenewDate' && sort.order}
                >
                  RENOVAÇÃO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('orderId')}
                  direction={sort.field === 'orderStatus' && sort.order}
                >
                  ULTIMO PEDIDO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('orderStatus')}
                  direction={sort.field === 'orderStatus' && sort.order}
                >
                  STATUS PEDIDO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('orderPaymentDate')}
                  direction={sort.field === 'orderPaymentDate' && sort.order}
                >
                  PAGAMENTO
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('orderNextPaymentAttemptDate')}
                  direction={
                    sort.field === 'orderNextPaymentAttemptDate' && sort.order
                  }
                >
                  PRÓXIMA TENTATIVA
                </MySortableTableCell>
                <MySortableTableCell
                  onClick={() => handleSort('orderNextPaymentAttemptNumber')}
                  direction={
                    sort.field === 'orderNextPaymentAttemptNumber' && sort.order
                  }
                >
                  TENTATIVA
                </MySortableTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {isLoading && (
                <CircularLoading isLoading={isLoading} title={'Carregando'} />
              )}
              {!hasError && !isLoading && subscribers.length ? (
                subscribers.map(sub => (
                  <TableRow key={sub.id} className={style.clickableRow}>
                    <MyTableCell>
                      <MyCheckbox
                        onClick={() => handleSelectSingleRow(sub?.orderId)}
                        checked={isSelected(sub?.orderId)}
                      />
                    </MyTableCell>
                    <MyTableCell>{sub.bulkActionsStatus || '-'}</MyTableCell>
                    <MyTableCell>{sub.id || '-'}</MyTableCell>
                    <MyTableCell>{sub.name || '-'}</MyTableCell>
                    <MyTableCell>{sub.email || '-'}</MyTableCell>
                    <MyTableCell>{sub.signatureId || '-'}</MyTableCell>
                    <MyTableCell>{sub.signatureStatus}</MyTableCell>
                    <MyTableCell>{sub.recurrenceName || '-'}</MyTableCell>
                    <MyTableCell>{sub.signaturePlanId || '-'}</MyTableCell>
                    <MyTableCell>{sub.planName || '-'}</MyTableCell>
                    <MyTableCell>{sub.signaturePaymentType || '-'}</MyTableCell>
                    <MyTableCell>
                      {sub.signatureRenewDate
                        ? formatColumnValue(
                            FormatOptions.VIEW_DATE,
                            sub.signatureRenewDate,
                          )
                        : '-'}
                    </MyTableCell>
                    <MyTableCell>{sub.orderId || '-'}</MyTableCell>
                    <MyTableCell>{sub.orderStatus || '-'}</MyTableCell>
                    <MyTableCell>
                      {sub.orderPaymentDate
                        ? formatColumnValue(
                            FormatOptions.VIEW_DATE,
                            sub.orderPaymentDate,
                          )
                        : '-'}
                    </MyTableCell>
                    <MyTableCell>
                      {sub.orderNextPaymentAttemptDate
                        ? formatColumnValue(
                            FormatOptions.VIEW_DATE,
                            sub.orderNextPaymentAttemptDate,
                          )
                        : '-'}
                    </MyTableCell>
                    <MyTableCell>
                      {sub.orderNextPaymentAttemptNumber || '-'}
                    </MyTableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow className={style.clickableRow}>
                  <MyTableCell colSpan={18} align="center">
                    Nenhum resultado encontrado
                  </MyTableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>

      <Grid container justify="flex-end">
        <TablePagination
          ActionsComponent={MyTablePaginationActions}
          component="div"
          count={total}
          page={page}
          labelRowsPerPage="Itens por página"
          onChangePage={(_event: unknown, newPage: number) =>
            handleSetValuePage(newPage)
          }
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={(event: React.ChangeEvent<HTMLInputElement>) => {
            handleSetValuePage(0);
            setRowsPerPage(parseInt(event.target.value, 10));
          }}
          rowsPerPageOptions={ROWS_PER_PAGE_BULK_ACTION}
        />
      </Grid>
    </Grid>
  );
});

export default SubscriptionBulkActions;
