import { Reducer } from 'redux';
import { AddressState, AddressTypes } from './types';

const INITIAL_STATE: AddressState = {
  isLoading: true,
  hasError: false,
  data: [],
  total: 0,
};

const reducer: Reducer<AddressState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case AddressTypes.LOAD_ALL_ADDRESSES:
      return INITIAL_STATE;
    case AddressTypes.LOAD_ADDRESSES_SUCCESS:
      return {
        isLoading: false,
        hasError: false,
        data: action.payload.data,
        total: action.payload.total,
      };
    case AddressTypes.LOAD_ADDRESSES_FAILURE:
      return {
        isLoading: false,
        hasError: true,
        data: [],
        total: 0,
      };
    default:
      return state;
  }
};

export default reducer;
