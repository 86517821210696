import React, { FC, useCallback } from 'react';
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';

import MyTooltip from 'components/generals/tolltip';
import { formatColumnValue } from 'helpers/formatters';

import { OrderItemProps } from './types';
import { useStyles } from './styles';
import { FormatOptions } from 'types/TableTypes';

const InternalStatus: FC<OrderItemProps> = ({ order }) => {
  const INTERNAL_STATUS = order?.statusInternalHistory || [];
  const styles = useStyles();

  const handleFormatDateTime = useCallback((date: string) => {
    return formatColumnValue(FormatOptions.DATETIME, date);
  }, []);

  return (
    <Grid item xs={12} style={{ marginBottom: '25px' }}>
      <Paper className={styles.paperTable} elevation={0}>
        <Typography variant="h3" className={styles.paperInfoTitle}>
          Status internos
        </Typography>
        <TableContainer>
          <Table className={styles.table} aria-label="order items table">
            <TableHead>
              <TableRow>
                <TableCell>Data</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Detalhe</TableCell>
                <TableCell>Usuário</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {INTERNAL_STATUS.length
                ? INTERNAL_STATUS.map((status, key) => (
                    <TableRow key={key}>
                      <TableCell>
                        {handleFormatDateTime(status.createdAt)}
                      </TableCell>
                      <TableCell>{status.newState}</TableCell>
                      <MyTooltip
                        title={status.details}
                        enterDelay={500}
                        placement="top"
                      >
                        <TableCell>{status.details}</TableCell>
                      </MyTooltip>
                      <TableCell>{status.requester}</TableCell>
                    </TableRow>
                  ))
                : null}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </Grid>
  );
};

export default InternalStatus;
