import { api, GetProps, getWithSaga } from '../apiService';
import { Range } from 'store/ducks/sigep/label/types';
import { CreateQueryParams } from '@nestjsx/crud-request';

const LABEL_DOMAIN = 'sigep-label';

export default class LabelService {
  static async getAll(query: CreateQueryParams): Promise<GetProps> {
    try {
      const data = await getWithSaga(LABEL_DOMAIN, query);
      return data;
    } catch (err) {
      return err;
    }
  }

  static async setRange(range: Range) {
    try {
      const data = await api.post(`${LABEL_DOMAIN}/range`, {
        ...range,
      });
      return data;
    } catch (err) {
      return err;
    }
  }
}
