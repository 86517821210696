import { api, apiQueue } from './apiService';
import { CreateSignature, UserLead } from 'types/generals';

const OPEN_ENDPOINTS_BASE_URL = 'open-endpoints';
const BASE_URL_LEAD = 'user-lead';

export default class OpenEndpointsService {
  static async createSignature(user: CreateSignature) {
    try {
      const { data } = await apiQueue.post(
        `${OPEN_ENDPOINTS_BASE_URL}/signature`,
        user,
      );
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async getPlanFromSignature(hashPlan: string) {
    try {
      const { data } = await api.get(`open-endpoints/plan/${hashPlan}`);
      return data;
    } catch (error) {
      return error.response;
    }
  }

  static async validateEmail(email: string) {
    try {
      const { data } = await api.get(
        `${OPEN_ENDPOINTS_BASE_URL}/validate-email/${email}`,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async userLookupAddress(zipcode: string) {
    try {
      const { data } = await api.get(
        `${OPEN_ENDPOINTS_BASE_URL}/zipcode/${zipcode}`,
      );
      return data;
    } catch (error) {
      throw error;
    }
  }

  static async verifyUserRequest(token: any) {
    try {
      const response = await api.post(
        `${process.env.REACT_APP_API_URL}/open-endpoints/verify-request`,
        { token },
      );
      return response;
    } catch (error) {
      throw error;
    }
  }

  static async getBankslipUrl(hash: string) {
    try {
      const data = await api.get(
        `${OPEN_ENDPOINTS_BASE_URL}/download-bankslip/${hash}`,
      );
      return data;
    } catch (error) {
      return error;
    }
  }

  static async createLeadUser(user: Partial<UserLead>) {
    try {
      const data = await api.post(
        `${OPEN_ENDPOINTS_BASE_URL}/${BASE_URL_LEAD}`,
        user,
      );
      return data;
    } catch (error) {
      return error;
    }
  }
  static async updateLeadUser(leadId: number, user: Partial<UserLead>) {
    try {
      const data = await api.put(
        `${OPEN_ENDPOINTS_BASE_URL}/${BASE_URL_LEAD}/${leadId}`,
        user,
        {
          headers: { leadId },
        },
      );
      return data;
    } catch (error) {
      return error;
    }
  }
}
