import React, { FC, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Grid, Paper, SvgIcon, Typography } from '@material-ui/core';

import { DEFINED_TAGS } from 'components/generals/tag/types';
import TagWrapper from 'components/generals/tag';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import Colors from 'styles/colors';

import CreditCardService from 'services/creditCardService';

import { AppState } from 'store';
import { loadCreditCards } from 'store/ducks/payment/creditCard/actions';
import { closeModal, openModal } from 'store/ducks/nav/actions';
import { SingleSignatureState } from 'store/ducks/generals/signature/types';
import { notifyError } from 'store/ducks/notification/actions';

import { formatColumnValue } from 'helpers/formatters';
import { CreditCard } from 'types/CreditCard';
import { FormatOptions } from 'types/TableTypes';
import { useStyles } from './styles';
import { ReactComponent as CardIcon } from 'assets/img/svg/icon_active_card.svg';
import { REMOVE_CREDIT_CART_CONFIRM, UPDATE_CREDIT_CARD } from 'utils/messages';
import { SIGNATURE_STATUS } from 'types/generals';

interface ICardPanel {
  cardData: CreditCard;
}

const CardPanel: FC<ICardPanel> = ({ cardData: card }) => {
  const style = useStyles();
  const dispatch = useDispatch();
  const { data: signature } = useSelector<AppState, SingleSignatureState>(
    ({ oneSignature }: AppState) => oneSignature,
  );

  const showRemoveBtn = useMemo(() => {
    const isSignatureCanceled = signature.status === SIGNATURE_STATUS.CANCELED;
    return isSignatureCanceled || !card.isDefault;
  }, [card.isDefault, signature.status]);

  const cardNumber = useMemo(() => {
    return formatColumnValue(FormatOptions.CREDIT_CARD, card.number);
  }, [card.number]);

  const handleRemoveCard = async () => {
    await CreditCardService.deleteOneCreditCard(String(card.id));
    dispatch(closeModal());
    dispatch(loadCreditCards());
  };

  const handleUpdateCard = async (creditCard: CreditCard) => {
    await CreditCardService.updateCreditCard(
      { isDefault: true },
      creditCard.id,
    );
    dispatch(closeModal());
    dispatch(loadCreditCards());
  };

  const handleConfirmActionDialog = (
    title: string,
    message: string,
    action: any,
  ) => {
    try {
      dispatch(
        openModal(ConfirmationDialog, {
          title,
          message,
          actionFn: action,
        }),
      );
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  return (
    <Paper variant="outlined" className={style.cardItem}>
      <Grid
        container
        justify="space-between"
        direction={'row'}
        className={style.alignCenter}
      >
        <Grid item xs={1}>
          <SvgIcon
            viewBox="3 3 24 24"
            className={style.icon}
            fontVariant="h2"
            component={CardIcon}
          />
        </Grid>
        <Grid item xs={10} sm>
          <Typography variant="h3" color="textPrimary">
            {cardNumber}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={4} className={style.brandLabel}>
          <Typography variant="h4" color="textPrimary">
            {card.brand}
          </Typography>
        </Grid>
        <Grid item xs={6} sm={4}>
          {card.isDefault ? (
            <TagWrapper config={DEFINED_TAGS.MESSAGE} value={'Padrão'} />
          ) : (
            <Button
              variant="outlined"
              color="primary"
              className={style.button}
              onClick={() =>
                handleConfirmActionDialog(
                  'Definir cartão como padrão',
                  UPDATE_CREDIT_CARD(card.cardName),
                  () => handleUpdateCard(card),
                )
              }
            >
              Definir como padrão
            </Button>
          )}
        </Grid>
        <Grid item xs={6} sm={2} style={{ textAlign: 'end' }}>
          {showRemoveBtn ? (
            <Button
              onClick={() =>
                handleConfirmActionDialog(
                  'Remover cartão',
                  REMOVE_CREDIT_CART_CONFIRM,
                  () => handleRemoveCard(),
                )
              }
            >
              <Typography
                variant="h3"
                color="textPrimary"
                style={{
                  color: Colors.RED,
                  fontWeight: 'bold',
                }}
              >
                Excluir
              </Typography>
            </Button>
          ) : null}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default CardPanel;
