import { ViewSubscriberUser } from 'types/generals/view';

/**
 * Action types
 */
export enum ViewTypes {
  LOAD_VIEW_USERS_REQUEST = '@user/LOAD_VIEW_USERS_REQUEST',
  LOAD_VIEW_USERS_SUCCESS = '@user/LOAD_VIEW_USERS_SUCCESS ',
  LOAD_VIEW_USERS_FAILURE = '@user/LOAD_VIEW_USERS_FAILURE',
  LOAD_VIEW_USERS_FILE_REQUEST = '@user/LOAD_VIEW_USERS_FILE_REQUEST',
  LOAD_VIEW_USERS_FILE_REQUEST_SUCCESS = '@user/LOAD_VIEW_USERS_FILE_REQUEST_SUCCESS',
  LOAD_VIEW_USERS_FILE_REQUEST_FAILURE = '@user/LOAD_VIEW_USERS_FILE_REQUEST_FAILURE',
  CLEAR_USER = '@user/CLEAR_USER',
}

/**
 * State types
 */
export interface ViewState {
  readonly data: ViewSubscriberUser[];
  readonly hasError: boolean;
  readonly isLoading: boolean;
  readonly total: number;
}

export interface ViewPayload {
  data: ViewSubscriberUser[] | ViewSubscriberUser;
  total?: number;
}

export interface UpdateRetryPaymentSeveralPayload {
  subscribers: {
    signatureId: number;
    userId: number;
  }[];
}

export interface UpdateRenewDateSeveralPayload {
  renewDate: string;
  subscribers: {
    orderId: number;
    userId: number;
  }[];
}
