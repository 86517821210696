import * as Yup from 'yup';

export const validationSchema = Yup.object({
  /* name: Yup.string().required('Campo obrigatório'),
  plans: Yup.string().required('Campo obrigatório'),
  startDate: Yup.string(),
  endDate: Yup.string(),
  weight: Yup.string().required('Campo obrigatório'),
  description: Yup.string().required('Campo obrigatório'), */
  // images: Yup.array().required('Campo obrigatório'),
  images: Yup.array().required('Campo obrigatório'),
  sku: Yup.string().required('Campo obrigatório'),
});
