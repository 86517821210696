import { Plp, PlpState, PlpTypes } from './types';
import { Reducer } from 'redux';

const INITIAL_STATE: PlpState = {
  data: [],
  total: 0,
  hasError: false,
  isLoading: true,
};

const plpListReducer: Reducer<PlpState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PlpTypes.ALL_PLP_REQUEST:
      return { ...state, isLoading: true };
    case PlpTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, hasError: true, data: [], total: 0 };
    case PlpTypes.LOAD_ALL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        page: action.payload.page,
        total: action.payload.total,
        data: action.payload.data,
      };
    case PlpTypes.UPDATE:
      const data = state.data.map((item: Plp) =>
        item.name === action.payload.name ? action.payload : item,
      );
      return { ...state, data };
    default:
      return state;
  }
};

export default plpListReducer;
