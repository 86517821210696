import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Grid,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import { Link } from 'react-router-dom';
import { last } from 'lodash';

import { AppState } from 'store';
import MySortableTableCell from 'components/generals/table/MySortableTableCell';
import { useFilters } from 'hooks/filters';
import { useStyles } from './styles';
import MyTableCell from 'components/generals/table/MyTableCell';
import {
  ILogCotation,
  Order,
  ORDER_STATUS_COTATION,
  ORDER_TYPE,
  ENHANCE_STATUS,
} from 'types/generals';
import MyCheckbox from 'components/generals/input/MyCheckbox';
import {
  resetPreShippmentOrders,
  setPreShippimentOrders,
} from 'store/ducks/preShippimentOrders/action';
import { PreShippimentOrderState } from 'store/ducks/preShippimentOrders/types';
import { FormatOptions } from 'types';
import { formatColumnValue } from 'helpers/formatters';
import TagWrapper, { DEFINED_TAGS } from 'components/generals/tag';

interface PreShippmentOrdersListProps {
  orders: Order[];
}

const PreShippmentOrdersListResend: React.FC<PreShippmentOrdersListProps> = ({
  orders,
}) => {
  const styles = useStyles();
  const dispatch = useDispatch();

  const [selectedAll, setSelectedAll] = useState<boolean>(false);

  const { data: preShippimentOrders } = useSelector<
    AppState,
    PreShippimentOrderState
  >(({ preShippimentOrderReducer }: AppState) => preShippimentOrderReducer);

  const { handleSort, sort } = useFilters({});

  const handleReturnTransportCotation = (
    logCotation: ILogCotation[],
    transport: string,
  ) => {
    const lastLog = last(logCotation);
    const lastCotation = last(lastLog?.data);

    if (lastCotation) {
      return `${lastCotation.nome_transportador} - ${lastCotation.descricao}`;
    }

    if (transport) {
      return transport;
    }
    return '-';
  };

  const handleSelectNoteToShipping = (
    id: number,
    transport: string,
    statusCotation: ORDER_STATUS_COTATION | null,
  ) => {
    const findInvoiceId = preShippimentOrders.find(order => order.id === id);
    findInvoiceId
      ? dispatch(
          setPreShippimentOrders({
            data: preShippimentOrders.filter(order => order.id !== id),
          }),
        )
      : dispatch(
          setPreShippimentOrders({
            data: [...preShippimentOrders, { id, transport, statusCotation }],
          }),
        );
  };

  const handleSelectAllNotesToShipping = (orders: Order[]) => {
    const myOrders = orders.filter(order => !order.orderIsInShipment);
    const ordersIds = myOrders.map(order => ({
      id: order.id,
      transport: order.orderDetail.transport,
      statusCotation: order.statusCotation,
    }));
    dispatch(setPreShippimentOrders({ data: ordersIds }));

    if (selectedAll) {
      dispatch(resetPreShippmentOrders());
    }
    setSelectedAll(!selectedAll);
  };

  const isSelected = (orderId: number) =>
    !!preShippimentOrders.find(order => order.id === orderId);

  const formatDate = (data: string) => {
    return format(parseISO(data), 'dd/MM/yyyy');
  };

  const formatMoney = (amount: number) => {
    return formatColumnValue(FormatOptions.MONEY, amount);
  };

  useEffect(() => {
    if (!preShippimentOrders.length) {
      setSelectedAll(false);
    }
  }, [dispatch, preShippimentOrders]);

  return (
    <Paper className={styles.ordersList} elevation={0}>
      <TableContainer>
        <Table className={styles.table} aria-label="orders table">
          <TableHead>
            <TableRow>
              <TableCell>
                <MyCheckbox
                  onClick={() => handleSelectAllNotesToShipping(orders)}
                  checked={selectedAll}
                />
              </TableCell>
              <MySortableTableCell
                onClick={() => handleSort('id')}
                direction={sort.field === 'id' && sort.order}
              >
                Nº Pedido
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('order.orderDetail.orderType')}
                direction={
                  sort.field === 'order.orderDetail.orderType' && sort.order
                }
              >
                Tipo
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('paymentDate')}
                direction={sort.field === 'paymentDate' && sort.order}
              >
                Data de pagamento
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('status')}
                direction={sort.field === 'status' && sort.order}
              >
                Status pedido
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('orderDetail.transport')}
                direction={sort.field === 'orderDetail.transport' && sort.order}
              >
                Transportadora
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('state')}
                direction={sort.field === 'state' && sort.order}
              >
                Estado
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('city')}
                direction={sort.field === 'city' && sort.order}
              >
                Cidade
              </MySortableTableCell>
              <MySortableTableCell
                onClick={() => handleSort('zipcode')}
                direction={sort.field === 'zipcode' && sort.order}
              >
                Cep
              </MySortableTableCell>
              <TableCell>Total</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(orders.length &&
              orders.map(order => (
                <TableRow key={order.id}>
                  <TableCell>
                    <MyCheckbox
                      onClick={() =>
                        handleSelectNoteToShipping(
                          order?.id,
                          order.orderDetail.transport,
                          order.statusCotation,
                        )
                      }
                      checked={isSelected(order?.id)}
                      disabled={order.orderIsInShipment}
                    />
                  </TableCell>
                  <MyTableCell>
                    <>
                      <Link
                        to={
                          order.orderDetail.orderType === ORDER_TYPE.SIGNATURE
                            ? `orders/${order.id}`
                            : `orders-store/${order.id}`
                        }
                        className={styles.textLinkTable}
                      >
                        {order.id}
                      </Link>

                      {order.isFirstPaidOrder ? (
                        <Grid item xs>
                          <TagWrapper
                            config={DEFINED_TAGS.STATUS}
                            value={'1ª pedido'}
                          />
                        </Grid>
                      ) : null}
                      {order.isFirstOrderGift ? (
                        <Grid item xs>
                          <TagWrapper
                            config={DEFINED_TAGS.INFO}
                            value={'Brinde'}
                          />
                        </Grid>
                      ) : null}
                    </>
                  </MyTableCell>
                  <MyTableCell>
                    {order.orderDetail.orderType}
                    {order.isResend ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.STATUS}
                          value={'Reenvio'}
                        />
                      </Grid>
                    ) : null}
                  </MyTableCell>
                  <MyTableCell>
                    {formatDate(order.paymentDate || '')}
                  </MyTableCell>
                  <MyTableCell>
                    {order.status}
                    {order.isDonation ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.INFO}
                          value={'Doação'}
                        />
                      </Grid>
                    ) : null}
                    {order.enhanceStatus === ENHANCE_STATUS.PARENT_ENHANCED ? (
                      <Grid item xs>
                        <TagWrapper
                          config={DEFINED_TAGS.MESSAGE}
                          value={'Pedido turbinado'}
                        />
                      </Grid>
                    ) : null}
                  </MyTableCell>
                  <MyTableCell>
                    {handleReturnTransportCotation(
                      order.orderDetail.logCotation,
                      order.orderDetail.transport,
                    )}
                  </MyTableCell>
                  <MyTableCell>{order.orderDetail.state || '-'}</MyTableCell>
                  <MyTableCell>{order.orderDetail.city || '-'}</MyTableCell>
                  <MyTableCell>{order.orderDetail.zipcode || '-'}</MyTableCell>
                  <MyTableCell>
                    {(order.orderDetail &&
                      formatMoney(order.orderDetail.amount)) ||
                      '-'}
                  </MyTableCell>
                </TableRow>
              ))) || (
              <TableRow>
                <MyTableCell colSpan={16} align="center">
                  Nenhum pedido encontrado
                </MyTableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default PreShippmentOrdersListResend;
