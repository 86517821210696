import React, { FC } from 'react';
import { Grid, useMediaQuery, useTheme } from '@material-ui/core';

import Breadcrumbs from 'components/generals/breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
import { getMenuItems } from '../utils';
import { Wrapper } from '../styles';
import { LayoutPropType } from '../types';
import { AppState } from 'store';
import { RankingState } from 'store/ducks/ranking/types';
import { useSelector } from 'react-redux';
import { StoreSettingsState } from 'store/ducks/store/storeSettings/types';

const LayoutClient: FC<LayoutPropType> = ({ children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const { activeSettings } = useSelector<AppState, StoreSettingsState>(
    ({ storeSettings }: AppState) => storeSettings,
  );

  const MenuItems = getMenuItems(
    activeSettings?.rankingSeasonConfig?.showReferralMenu,
  );
  return (
    <Wrapper
      container
      direction={isMobile ? 'column' : 'row'}
      style={{
        padding: isMobile ? '80px 24px 80px 24px' : '32px 32px 90px 307px',
      }}
    >
      <Grid item>
        {isMobile ? <Header data={MenuItems} /> : <Sidebar data={MenuItems} />}
      </Grid>
      <Grid item xs>
        <Breadcrumbs />
        {children}
      </Grid>
    </Wrapper>
  );
};

export default LayoutClient;
