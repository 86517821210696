import { Recurrence } from 'types/generals';

export enum RecurrencesTypes {
  LOAD_RECURRENCES_REQUEST = '@recurrences/LOAD_RECURRENCES_REQUEST',
  LOAD_RECURRENCES_SUCCESS = '@recurrences/LOAD_RECURRENCES_SUCCESS',
  LOAD_RECURRENCES_FAILURE = '@recurrences/LOAD_RECURRENCES_FAILURE',
}

export interface RecurrenceState {
  readonly total: number;
  readonly isLoading: boolean;
  readonly hasError: boolean;
  readonly data: Recurrence[];
}
