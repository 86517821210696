import React from 'react';
import { TablePaginationProps, TablePagination } from '@material-ui/core';

const MyTablePagination: React.FC<TablePaginationProps> = props => {
  return (
    <TablePagination
      component="div"
      rowsPerPage={props.rowsPerPage}
      count={props.count}
      page={props.page}
      onChangePage={props.onChangePage}
      labelRowsPerPage={props.labelRowsPerPage}
      labelDisplayedRows={({ from, to, count }) => `${from}-${to} de ${count}`}
      rowsPerPageOptions={props.rowsPerPageOptions}
      onChangeRowsPerPage={props.onChangeRowsPerPage}
    />
  );
};

MyTablePagination.defaultProps = {
  labelRowsPerPage: 'Itens por página',
  rowsPerPageOptions: [10],
  rowsPerPage: 10,
  page: 0,
};

export default MyTablePagination;
