import { api, getWithSaga } from './apiService';
import { CreateQueryParams } from '@nestjsx/crud-request';
import {
  BaseRankingSeason,
  WalletTransactionsType,
} from 'types/generals/rankingSeason';

const USER_RANKING_SEASON = 'user-ranking-season';
const RANKING_SEASON = 'ranking-season';

export default class RankingSeasonService {
  static async create(data: BaseRankingSeason) {
    try {
      const response = api.post(`/${RANKING_SEASON}`, data);
      return response;
    } catch (error) {
      return error;
    }
  }

  static async update(id: number, data: BaseRankingSeason) {
    try {
      const response = api.put(`/${RANKING_SEASON}/${id}`, data);
      return response;
    } catch (error) {
      return error;
    }
  }
  static async duplicate(id: number) {
    try {
      const response = api.post(`/${RANKING_SEASON}/duplicate/${id}`);
      return response;
    } catch (error) {
      return error;
    }
  }

  static async delete(id: number) {
    try {
      const data = api.delete(`/${RANKING_SEASON}/${id}`);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getAllRankingSeason(query?: CreateQueryParams) {
    try {
      const data = getWithSaga(`/${RANKING_SEASON}`, query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async fetchReferralConfiguration(query?: CreateQueryParams) {
    try {
      const data = getWithSaga('/ranking-season-referral', query);
      return data;
    } catch (error) {
      return error;
    }
  }
  static async fetchReferredConfiguration(query?: CreateQueryParams) {
    try {
      const data = getWithSaga('/ranking-season-referred', query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async fetchUserRankingData(
    userId: number,
    seasonId: number,
    query?: CreateQueryParams,
  ) {
    try {
      const data = getWithSaga(
        `/${USER_RANKING_SEASON}/full-data/${userId}?seasonId=${seasonId}`,
        query,
      );
      return data;
    } catch (error) {
      return error;
    }
  }
  static async fetchUserRankingView(query?: CreateQueryParams) {
    try {
      const data = getWithSaga(`/${USER_RANKING_SEASON}/list-rank`, query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async addManualRankingPoints(
    userId: number,
    score: number,
    observation: string,
    transactionType: WalletTransactionsType,
    rankingSeasonId: number,
  ) {
    try {
      const body = {
        userId,
        score,
        observation,
        transactionType,
        rankingSeasonId,
      };
      const data = api.post(`/${USER_RANKING_SEASON}/add-manual-score`, body);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getReferredUsers(query?: CreateQueryParams) {
    try {
      const data = getWithSaga(`/${USER_RANKING_SEASON}`, query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getAllUserRanking(query?: CreateQueryParams) {
    try {
      const data = getWithSaga(`/${USER_RANKING_SEASON}`, query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async getRankingTop5(query?: CreateQueryParams) {
    try {
      const data = getWithSaga(`/${USER_RANKING_SEASON}/top-5`, query);
      return data;
    } catch (error) {
      return error;
    }
  }

  static async countReferral(userId: number, rankingSeasonId: number) {
    try {
      const data = api.get(`/${USER_RANKING_SEASON}/${userId}/count-referral`, {
        params: {
          rankingSeasonId,
        },
      });
      return data;
    } catch (error) {
      return error;
    }
  }
}
