export interface RolesPermission {
  id: number;
  name?: string;
  displayName?: string;
}

export interface Roles {
  id: number;
  createdAt: string;
  updatedAt: string;
  name: string;
  description: string;
  permissions: RolesPermission[];
}

export enum PermissionsNames {
  RECORD_PLANS = 'record_plans',
  RECORD_BOXES = 'record_boxes',
  // RECORD_BENEFITS = 'record_benefits',
  RECORD_STORE_PRODUCTS = 'record_store_products',
  RECORD_COUPONS = 'record_coupons',
  RECORD_CATEGORIES = 'record_categories',
  RECORD_VIDEOS = 'record_videos',
  RECORD_USERS = 'record_users',
  RECORD_PERMISSIONS = 'record_permissions',
  RECORD_BANNERS = 'record_banners',
  RECORD_DONATIONS = 'record_donations',
  RECORD_BENEFITS = 'record_benefits',
  RECORD_STORE_SETTINGS = 'record_store_settings',
  RECORD_RANKING_SEASON = 'record_ranking_season',
  RECORD_ENHANCED_PRODUCTS = 'record_enhanced_products',
  MANAGEMENT_SIGNATURE = 'management_signature',
  MANAGEMENT_SUBSCRIBERS = 'management_subscribers',
  MANAGEMENT_SIGNATURE_ORDER = 'management_signature_order',
  MANAGEMENT_STORE_ORDER = 'management_store_order',
  MANAGEMENT_PRE_SHIPPIMENT_ORDERS_STORE = 'management_pre_shippiment_orders_store',
  MANAGEMENT_PRE_SHIPPIMENT_ORDERS_SIGNATURE = 'management_pre_shippiment_orders_signature',
  MANAGEMENT_USERS = 'management_users',
  MANAGEMENT_SHIPPING = 'management_shipping',
  MANAGEMENT_TRACKING_CODE = 'management_tracking_code',
  MANAGEMENT_LEADS = 'management_leads',
  REPORT_FINANCIAL = 'report_financial',
  REPORT_SIGNATURES = 'report_signatures',
  MANAGEMENT_RANKING = 'management_ranking',
  REPORT_PROCESSING = 'report_processing',
  MANAGEMENT_NOTIFICATIONS = 'management_notification',
  MANAGEMENT_BULK_ACTION = 'management_bulk_action',
  MANAGEMENT_BULK_ACTION_REACTIVATION = 'management_bulk_action_reactivation',
  MANAGEMENT_BULK_ACTION_TURBINATION = 'management_bulk_action_turbination',
}
