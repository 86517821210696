import { Reducer } from 'redux';
import { InvoiceState, InvoiceTypes } from './types';
import { Invoice } from 'types/generals';

const INITIAL_STATE: InvoiceState = {
  total: 0,
  data: [],
  hasError: false,
  isLoading: true,
};

const reducer: Reducer<InvoiceState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case InvoiceTypes.LOAD_REQUEST:
      return { ...state, isLoading: true };
    case InvoiceTypes.LOAD_FAILURE:
      return { ...state, isLoading: false, hasError: true, data: [] };
    case InvoiceTypes.LOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        hasError: false,
        page: action.payload.page,
        total: action.payload.total,
        data: action.payload.data,
      };
    case InvoiceTypes.UPDATE:
      const data = state.data.map((i: Invoice) =>
        i.id === action.payload.id ? action.payload : i,
      );

      return {
        ...state,
        data,
      };
    default:
      return state;
  }
};

export default reducer;
