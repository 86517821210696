import { QuerySort } from '@nestjsx/crud-request';
import { StoreProduct } from 'types/generals';

export enum PRODUCT_FILTER {
  CHEAPER = 'Menor preço',
  MOST_EXPENSIVE = 'Maior preço',
  OLDEST = 'Mais antigos',
  NEWEST = 'Mais novos',
}

export interface StoreFiltersProps {
  handleFilter?: (filterdIds: number[]) => void;
  handleSort: (sortData: QuerySort) => void;
}

export interface FilterData {
  text: string;
  value: number;
  isToggled: boolean;
}
