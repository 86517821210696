import { Reducer } from 'redux';
import { DEFAULT_INITIAL_STATE } from '../common/types';
import { RankingState, RankingTypes } from './types';

const INITIAL_STATE = {
  ...DEFAULT_INITIAL_STATE,
  activeRanking: undefined,
  userRankings: [],
  userRankingPage: 0,
  userRankingTotal: 0,
};

const reducer: Reducer<RankingState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RankingTypes.LOAD_RANKING_REQUEST:
    case RankingTypes.LOAD_USER_RANKING_SEASON_REQUEST:
    case RankingTypes.LOAD_ACTIVE_RANKING_REQUEST: {
      return { ...state, isLoading: true };
    }
    case RankingTypes.LOAD_ACTIVE_RANKING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        activeRanking: action.payload.data,
      };
    }
    case RankingTypes.LOAD_RANKING_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        data: action.payload.data,
      };
    }
    case RankingTypes.LOAD_USER_RANKING_SEASON_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        userRankings: action.payload.data,
        userRankingPage: action.payload.page,
        userRankingTotal: action.payload.total,
      };
    }
    case RankingTypes.LOAD_ACTIVE_RANKING_FAILURE: {
      return { ...state, isLoading: false, hasError: true };
    }
    case RankingTypes.LOAD_RANKING_FAILURE: {
      return { ...state, isLoading: false, hasError: true, data: [] };
    }
    case RankingTypes.LOAD_USER_RANKING_SEASON_FAILURE: {
      return { ...state, isLoading: false, hasError: true, userRankings: [] };
    }
    default:
      return state;
  }
};

export default reducer;
