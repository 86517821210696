import { call, put } from 'redux-saga/effects';
import StoreService from 'services/storeService';
import { loadActiveStoreSettings } from './storeSettings/actions';
import { listStoreSuccess, listStoreFail } from './actions';
import {
  loadFile,
  loadFileFailure,
  loadFileSuccess,
} from '../generals/file/actions';
import FileService from 'services/fileservice';
import { createAndDownloadFile } from 'utils/functions/generals';

export function* loadStoreSaga(action: any) {
  try {
    const { data, total } = yield call(
      StoreService.getStoreProducts,
      action.payload.query,
    );
    yield put(listStoreSuccess({ data, total }));
    yield put(loadActiveStoreSettings());
  } catch (err) {
    yield put(listStoreFail());
  }
}

export function* loadStoreFileSaga(action: any) {
  try {
    yield put(loadFile());
    const file = yield call(
      FileService.getFile,
      action.payload.query,
      action.payload.rootPath,
    );
    if (file?.response?.status) {
      yield put(loadFileFailure());
    } else {
      yield put(loadFileSuccess());
      createAndDownloadFile(file, 'Produtos loja.xls');
    }
  } catch (error) {
    yield put(loadFileFailure());
  }
}
