import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, TablePagination } from '@material-ui/core';

import { ROWS_PER_PAGE } from 'components/generals/table/MyTable';
import MyInputFilter from 'components/generals/input/MyInputFilter';
import MyTablePaginationActions from 'components/generals/table/MyTablePaginationActions';
import UserDialog from 'components/generals/dialog/UserDialog';
import UsersList from './UsersList';
import { actUpdatePageTitle } from 'store/ducks/nav/actions';
import { openModal } from 'store/ducks/nav/actions';
import { loadStaffFile, loadStaffRequest } from 'store/ducks/user/actions';
import { useFilters } from 'hooks/filters';
import { searchObject } from './utils';
import { AppState } from 'store';
import { UserState } from 'store/ducks/user/types';
import { UserDialogProps } from 'components/generals/dialog/dialogTypes';
import ActionsButton from 'components/generals/buttons/actionsButton/actionsButton';
import { ROOT_PATH } from 'services/fileservice';
import { FileState } from 'store/ducks/generals/file/types';
import MyDateRangeFilter from 'components/generals/input/MyDateRangeFilter';

const Users = () => {
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);
  const dispatch = useDispatch();

  const {
    filterFields,
    genericFilterFields,
    page,
    handleGenericSearch,
    dateFilter,
    handleDateFilter,
    handleSetValuePage,
    sort,
    handleSort,
  } = useFilters({
    name: { filterTitle: 'Nome', value: undefined },
    createdAt: { filterTitle: 'Data criação', value: undefined },
  });

  const { staffs = [], total = 0 } = useSelector<AppState, UserState>(
    ({ user }: AppState) => user,
  );

  const { hasError: hasErrorFile } = useSelector<AppState, FileState>(
    ({ file }: AppState) => file,
  );

  const handleLoadStaffs = useCallback(() => {
    dispatch(
      loadStaffRequest({
        sort,
        page: page + 1,
        limit: rowsPerPage,
        search: {
          $and: [...filterFields, { $or: [...genericFilterFields] }],
        },
        join: [['roles']],
      }),
    );
  }, [dispatch, sort, page, rowsPerPage, filterFields, genericFilterFields]);

  const loadStaffXls = useCallback(() => {
    dispatch(
      loadStaffFile(
        {
          sort,
          page: page + 1,
          limit: rowsPerPage,
          search: {
            $and: [...filterFields, { $or: [...genericFilterFields] }],
          },
        },
        ROOT_PATH.STAFFS,
      ),
    );
  }, [dispatch, sort, page, rowsPerPage, filterFields, genericFilterFields]);

  useEffect(() => {
    dispatch(actUpdatePageTitle('Usuários'));
  }, [dispatch]);

  useEffect(() => {
    handleLoadStaffs();
  }, [handleLoadStaffs]);

  const handleCallDialog = (title: string) => {
    try {
      const modalProps: UserDialogProps = {
        title,
        revalidateFn: () => handleLoadStaffs(),
      };
      dispatch(openModal(UserDialog, modalProps));
    } catch (err) {
      throw new Error(err);
    }
  };

  const handleSearch = (e: any) => {
    if (e.key === 'Enter' || e.keyCode === '13') {
      handleGenericSearch(e, searchObject);
    }
  };

  return (
    <Grid container direction="column">
      <Grid
        container
        justify="flex-start"
        alignItems="center"
        spacing={3}
        style={{ marginTop: 20, marginBottom: 10 }}
      >
        <Grid item xs={3}>
          <MyDateRangeFilter
            value={dateFilter}
            onChange={(date: Date[]) => {
              handleDateFilter(date, '', 'createdAt');
            }}
          />
        </Grid>

        <Grid item xs={3}>
          <MyInputFilter
            onKeyUp={e => handleSearch(e)}
            placeholder={'Buscar'}
          />
        </Grid>
        <Grid container item xs alignItems="center" justify="flex-end">
          <Grid item xs={3} xl={3}>
            <ActionsButton
              hasErrorFile={hasErrorFile}
              menuItems={[
                {
                  title: 'Adicionar',
                  action: () => handleCallDialog('Adicionar usuário'),
                },
                {
                  title: 'Exportar planilha de usuários',
                  action: loadStaffXls,
                },
              ]}
              buttonText="Ações"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container direction="column">
        <UsersList sort={sort} handleSort={handleSort} users={staffs} />
      </Grid>
      <TablePagination
        ActionsComponent={MyTablePaginationActions}
        component="div"
        count={total}
        page={page}
        labelRowsPerPage="Itens por página"
        onChangePage={(_event: unknown, newPage: number) =>
          handleSetValuePage(newPage)
        }
        rowsPerPage={rowsPerPage}
        onChangeRowsPerPage={(event: ChangeEvent<HTMLInputElement>) => {
          handleSetValuePage(0);
          setRowsPerPage(parseInt(event.target.value, 10));
        }}
        rowsPerPageOptions={ROWS_PER_PAGE}
      />
    </Grid>
  );
};

export default Users;
