import { Signature } from 'types/generals';
import * as Yup from 'yup';
import { identification } from 'utils/formYupFields';

export const profileValidationSchema = Yup.object({
  name: Yup.string().required('Nome obrigatório'),
  email: Yup.string().email('E-mail inválido').required('E-mail obrigatório'),
  phone: Yup.string().required('Telefone obrigatório'),
  identification,
});

export const addressValidationSchema = Yup.object({
  zipcode: Yup.string().required('Cep obrigatório'),
  street: Yup.string().required('Endereço obrigatório'),
  number: Yup.string().required('Número obrigatório'),
  neighborhood: Yup.string().required('Bairro obrigatório'),
  city: Yup.string().required('Cidade obrigatório'),
  state: Yup.string()
    .max(2, 'Só é permitido 2 dígitos, ex: RS')
    .required('Estado obrigatório'),
  complement: Yup.string(),
});

export interface SubscriberPageProps {
  signature: Signature;
}
