import { PageFilterState, PageFilterTypes } from './types';
import { Reducer } from 'redux';

const INITIAL_STATE: PageFilterState = {
  filters: {},
};

const reducer: Reducer<PageFilterState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PageFilterTypes.FILTERS_UPDATE:
      let filters = state.filters;
      if (action.payload.page) {
        filters = {
          ...state.filters,
          [action.payload.page]: action.payload.data,
        };
      }
      return {
        ...state,
        filters,
      };
    default:
      return state;
  }
};

export default reducer;
