import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import {
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stepper,
  Typography,
  Step,
  StepLabel,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import MyStepperIcon from '../stepper/MyStepperIcon';
import MyStepperVerticalConnector from '../stepper/MyStepperVerticalConnector';

import { closeModal } from 'store/ducks/nav/actions';
import { useStyles } from './useStyles';
import { StepModalProps } from './types';

const StepModal: FC<StepModalProps> = ({ order }) => {
  const styles = useStyles();
  const dispatch = useDispatch();
  const steps = order.statusTrackingDetails;

  return (
    <Grid container direction="column">
      <DialogTitle disableTypography>
        <Grid container justify="space-between">
          <Typography variant="h1" color="textPrimary">
            Rastreio Detalhado
          </Typography>
          <IconButton onClick={() => dispatch(closeModal())}>
            <CloseIcon />
          </IconButton>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Stepper
          activeStep={steps.length}
          connector={<MyStepperVerticalConnector />}
          orientation="vertical"
        >
          {steps.map((step, idx) => (
            <Step key={idx}>
              <StepLabel StepIconComponent={MyStepperIcon}>
                <Typography variant="h4" style={{ fontWeight: 500 }}>
                  {step.occurence}
                </Typography>
                <Typography variant="subtitle2" className={styles.spanDate}>
                  {step.occurrenceDate}
                </Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </DialogContent>
    </Grid>
  );
};

export default StepModal;
