import React, { FC, memo } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from '@material-ui/core';

import { useStyles } from './styles';

import { closeModal, openModal } from 'store/ducks/nav/actions';
import MyDropdown from 'components/generals/dropdown/MyDropdown';
import ChangeDateDialog from 'components/generals/dialog/ChangeDateDialog';
import {
  BaseDialogProps,
  ChangeDateDialogProps,
} from 'components/generals/dialog/dialogTypes';
import ViewsService from 'services/viewsService';
import { StatusCodes } from 'http-status-codes';
import { notifyError, notifySuccess } from 'store/ducks/notification/actions';
import { separateDateToStringDate } from 'helpers/validateDate';
import {
  RETRY_PAYMENT_ERROR,
  RETRY_PAYMENT_MESSAGE,
  RETRY_PAYMENT_SUCESS,
  UPDATE_RENEW_DATE_ERROR,
  UPDATE_RENEW_DATE_SUCESS,
  BULK_ANTICIPE_RENEW_MESSAGE,
  BULK_ANTICIPE_RENEW_SUCCESS,
  BULK_ANTICIPE_RENEW_ERROR,
} from 'utils/messages/bunkActions';
import ConfirmationDialog from 'components/generals/dialog/ConfirmationDialog';
import { ViewSubscriberUser } from 'types/generals/view';
import { useHistory } from 'react-router-dom';

interface BAActionsProps {
  subscribers: ViewSubscriberUser[];
  orderIds: number[];
}
const BAActions: FC<BAActionsProps> = memo(({ subscribers, orderIds }) => {
  const dispatch = useDispatch();
  const style = useStyles();
  const history = useHistory();

  const getIds = (field: string, filter?: any) => {
    let selectedIds = subscribers.filter(sub => orderIds.includes(sub.orderId));
    if (filter) {
      selectedIds = filter(selectedIds);
    }
    return selectedIds?.map((su: any) => {
      return {
        userId: su.id,
        [field]: su[field],
      };
    });
  };

  const getSignatureIds = (filter?: any) => {
    return getIds('signatureId', filter);
  };
  const getOrderIds = (filter?: any) => {
    return getIds('orderId', filter);
  };

  const handleChangeRenewDate = async (data: any) => {
    try {
      const ids = getSignatureIds();
      const renewDate = separateDateToStringDate(data.renewDate);

      const body: any = { subscribers: ids, renewDate };
      const response: any = await ViewsService.updateRenewDateSeveral(body);

      if (response.status === StatusCodes.CREATED) {
        dispatch(closeModal());
        dispatch(notifySuccess(UPDATE_RENEW_DATE_SUCESS));
        history.go(0);
      } else {
        throw new Error(UPDATE_RENEW_DATE_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleOpenChangeRenewDateDialog = () => {
    const modalProps: ChangeDateDialogProps = {
      title: 'Alterar data de renovação',
      actionFn: handleChangeRenewDate,
    };
    dispatch(openModal(ChangeDateDialog, modalProps));
  };

  const handleRetryPayment = async () => {
    try {
      const ids: any = getOrderIds();

      const response: any = await ViewsService.retryPaymentSeveral({
        subscribers: ids,
      });

      if (response.status === StatusCodes.CREATED) {
        dispatch(closeModal());
        dispatch(notifySuccess(RETRY_PAYMENT_SUCESS));
        history.go(0);
      } else {
        throw new Error(RETRY_PAYMENT_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleGetReportBankslipOrders = async () => {
    try {
      const ids: any = getOrderIds();
      await ViewsService.generateBankslipOrdersCsv({
        subscribers: ids,
      });
      history.go(0);
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(closeModal());
    }
  };

  const handleSendBankslipNotifications = async () => {
    try {
      const ids: any = getOrderIds();
      await ViewsService.bulkSendBankslipNotifications({
        subscribers: ids,
      });
      history.go(0);
    } catch (error) {
      dispatch(notifyError(error.message));
    } finally {
      dispatch(closeModal());
    }
  };

  const handleAnticipateRenew = async () => {
    try {
      const ids: any = getSignatureIds();
      const response: any = await ViewsService.anticipateRenew({
        subscribers: ids,
      });

      if (response.status === StatusCodes.CREATED) {
        dispatch(closeModal());
        dispatch(notifySuccess(BULK_ANTICIPE_RENEW_SUCCESS));
        history.go(0);
      } else {
        throw new Error(BULK_ANTICIPE_RENEW_ERROR);
      }
    } catch (error) {
      dispatch(notifyError(error.message));
    }
  };

  const handleAnticipateRenewDialog = () => {
    const modalProps: BaseDialogProps = {
      title: 'Antecipar renovação',
      message: BULK_ANTICIPE_RENEW_MESSAGE,
      confirmText: 'Sim',
      cancelText: 'Não',
      actionFn: handleAnticipateRenew,
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleOpenRetryPaymentDialog = () => {
    const modalProps: BaseDialogProps = {
      title: 'Realizar cobrança',
      message: RETRY_PAYMENT_MESSAGE,
      confirmText: 'Sim',
      cancelText: 'Não',
      actionFn: handleRetryPayment,
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleOpenReportBankslipDialog = () => {
    const modalProps: BaseDialogProps = {
      title: 'Relatório de boletos',
      message: 'Deseja gerar link dos boletos?',
      confirmText: 'Sim',
      cancelText: 'Não',
      actionFn: handleGetReportBankslipOrders,
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  const handleOpenSendBankslipNotificationsDialog = () => {
    const modalProps: BaseDialogProps = {
      title: 'Enviar notificações de boletos',
      message: 'Deseja enviar as notificações dos boletos?',
      confirmText: 'Sim',
      cancelText: 'Não',
      actionFn: handleSendBankslipNotifications,
    };
    dispatch(openModal(ConfirmationDialog, modalProps));
  };

  return (
    <MyDropdown
      buttonProps={{
        color: 'primary',
        variant: 'contained',
        className: style.filterOptionsButton,
        disabled: orderIds.length === 0,
      }}
      menuProps={{
        classes: {
          list: style.filterOptionsList,
        },
        transformOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        PaperProps: {
          style: {
            marginTop: '1rem',
            borderRadius: 13,
          },
        },
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      }}
      menuItems={[
        {
          title: 'Alterar data de renovação',
          action: handleOpenChangeRenewDateDialog,
        },
        {
          title: 'Cobrar pedidos',
          action: handleOpenRetryPaymentDialog,
        },
        {
          title: 'Antecipar renovação',
          action: handleAnticipateRenewDialog,
        },
        {
          title: 'Gerar link de boletos',
          action: handleOpenReportBankslipDialog,
        },
        {
          title: 'Enviar notificaçãos de boleto',
          action: handleOpenSendBankslipNotificationsDialog,
        },
      ]}
    >
      <Typography variant="h3" className={style.actionButton}>
        Ações
      </Typography>
    </MyDropdown>
  );
});

export default BAActions;
