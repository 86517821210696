import { makeStyles } from '@material-ui/core';
import Colors from 'styles/colors';
import theme from 'styles/theme';

const useStyles = makeStyles({
  actionWrapper: {
    float: 'right',
    alignItems: 'flex-end',
    placeContent: 'flex-end',
    paddingRight: '1em',
    width: 'auto',
  },
  clickableRow: {
    cursor: 'pointer',
  },
  filterSelect: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
  },
  icon: {
    margin: theme.spacing(1),
    fontSize: 32,
    cursor: 'pointer',
  },
  list: {
    backgroundColor: Colors.WHITE,
  },
  rootPaper: {
    marginTop: '1em',
    padding: '4em',
    borderRadius: '2em',
    marginBottom: '3em',
  },
  table: {
    minWidth: 650,
  },
  paperTable: {
    marginTop: 18,
    padding: theme.spacing(1, 3, 3, 3),
    borderRadius: 30,
  },
  gridAction: {
    marginBottom: theme.spacing(2.5),
  },
  tableCellBold: {
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  menuOption: {
    textTransform: 'capitalize',
  },
  mTop: {
    marginTop: '1em',
  },
  circle: {
    borderRadius: '50%',
  },
  placeCenter: {
    placeSelf: 'center',
  },
  linkStyle: {
    textDecoration: 'none',
  },
  removeTableButtom: {
    color: Colors.BLACK,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  deleteIcon: {
    margin: theme.spacing(1),
    cursor: 'pointer',
  },
  buttonText: {
    color: Colors.RED,
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  buttonLabel: {
    fontSize: '1.5em',
    height: '3.2em',
    borderRadius: 13,
  },
  fullWidth: {
    width: '100%',
  },
  clearFilterContainer: {
    marginTop: 20,
  },
  searchFilter: {
    display: 'flex',
    alignItems: 'center',
  },
  searchFilterExport: {
    marginLeft: 24,
    fontSize: 12,
  },
  clearFilterItem: {
    marginBottom: 24,
  },
  borderRed: {
    borderColor: Colors.RED,
  },
  borderWhite: {
    borderColor: Colors.WHITE,
  },
  filterOptionsList: {
    backgroundColor: Colors.WHITE,
  },
  filterOptionsButton: {
    width: '100%',
    borderRadius: 13,
    marginLeft: 24,
  },
  actionButton: {
    fontSize: '1.6rem',
    fontWeight: 'bold',
    textTransform: 'capitalize',
    width: '100%',
    textAlign: 'left',
  },
});

export { useStyles };
