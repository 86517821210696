import { action } from 'typesafe-actions';
import { CreateQueryParams } from '@nestjsx/crud-request';
import { ShippingData, ShippingTypes } from './types';
import { Shipping } from 'types/generals';
import { ROOT_PATH } from 'services/fileservice';

export const loadShippingListRequest = (query: CreateQueryParams) => {
  return action(ShippingTypes.LOAD_SHIPPING_LIST_REQUEST, { query });
};

export const loadShippingListSuccess = (payload: ShippingData) => {
  return action(ShippingTypes.LOAD_SHIPPING_LIST_SUCCESS, payload);
};

export const loadShippingOneRequest = (shippingId: string) => {
  return action(ShippingTypes.LOAD_SHIPPING_ONE_REQUEST, shippingId);
};

export const loadShippingOneSuccess = (payload: Shipping) => {
  return action(ShippingTypes.LOAD_SHIPPING_ONE_SUCCESS, payload);
};

export const loadShippingFail = () => {
  return action(ShippingTypes.LOAD_FAILURE);
};

export const updateShipping = (data: Shipping) => {
  return action(ShippingTypes.UPDATE, data);
};

export const loadShippingfile = (
  query: CreateQueryParams,
  rootPath: ROOT_PATH,
) => {
  return action(ShippingTypes.LOAD_SHIPPING_FILE_REQUEST, { query, rootPath });
};
